import { fallbackCopyTextToClipboard } from "components/utils";
import React, { useState } from "react";
import { useWindupString } from "windups";
import agent from "server";
import DynamicBadge from "components/UI-lib/DynamicBadge";
import InputField from "components/UI-lib/InputField";

export interface SharableLinkCopiedProps {
  link: string;
  notebookLink: string;
}

const SharableLinkCopied: React.FC<SharableLinkCopiedProps> = (props) => {
  const [text] = useWindupString("URL copied to clipboard ✅");
  const [isaSSH, setAddSSH] = useState(false);
  const [notebookLink, setNotebookLink] = useState("");

  const handleLinkChange = (val: string) => {
    const fileParam = new URLSearchParams({
      file: val,
    });
    const urlWithFile = `${props.link}&${fileParam}`;
    fallbackCopyTextToClipboard(urlWithFile);
  };

  return (
    <div className="mt-8">
      <p className="text-sm text-gray-500 mb-2">{text}</p>
      <input
        type="text"
        name="name"
        onClick={(e) => {
          fallbackCopyTextToClipboard(props.link);
        }}
        id="name"
        disabled
        className="block w-full rounded-lg text-gray-900 dark:text-secondary placeholder-gray-500 dark:placeholder-slate-400 dark:bg-zinc-900 focus:ring-0 sm:text-sm"
        placeholder="360"
        value={props.link}
        onChange={() => {}}
      />
    </div>
  );
};

export default SharableLinkCopied;
