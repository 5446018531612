import React, { useContext, useEffect, useState } from "react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import agent, { WorkspaceGroup } from "server";
import { OrgContext } from "contexts/OrgContext";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import { NotificationContext } from "contexts/NotificationContext";
import GenericCard from "assets/img/svg/genericcard.svg?react";
import SimpleTransition from "components/SimpleTransition";
import {
  AddressElement,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { UserContext } from "contexts/UserContext";
import { FeatureToggle } from "components/DevToggle";
import { createDefaultAnalyticsObject } from "components/Analytics";
import CreditCardForm from "./CreditCardForm";
import CreditCardDetails from "./CreditCardDetails";
import CalTech from "./CalTech.png";
import instacart from "./instacart.png";
import { DarkModeContext } from "contexts/DarkModeContext";
import { BillingContext } from "contexts/BillingContext";

export interface ConnectedCard {
  id: string;
  brand: string;
  last4: string;
  expiration: string;
  latestPayment?: any;
}

const ConnectCard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [defaultCard, setDefaultCard] = useState<ConnectedCard | null>(null);
  const [connectedCards, setConnectedCards] = useState<ConnectedCard[]>([]);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [showCardModal, setShowCardModal] = useState(false);
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const notificationContext = useContext(NotificationContext);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const billingContext = useContext(BillingContext);

  const [clientSecret, setClientSecret] = useState("");
  const [instacartOfferStatus, setInstacartOfferStatus] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { darkMode } = useContext(DarkModeContext);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false);

  const getPaymentMethods = async () => {
    setLoadingPaymentMethods(true);
    const res = await agent.Billing.getPaymentMethods(orgContext.activeOrgId);
    if (res.success && res.data) {
      setDefaultCard(res.data.defaultCard || null);
      setConnectedCards(res.data.paymetMethods || []);
    }
    setLoadingPaymentMethods(false);
  };

  useEffect(() => {
    const loadClientSecret = async () => {
      const res = await agent.Billing.getClientSecret(orgContext.activeOrgId);
      if (res.success && res.data) {
        setClientSecret(res.data.clientSecret);
      }
    };
    getPaymentMethods();
    loadClientSecret();
  }, [orgContext.activeOrgId]);

  useEffect(() => {
    if (userContext.me?.onboardingData.instacart === "true") {
      setInstacartOfferStatus(true);
    } else {
      setInstacartOfferStatus(false);
    }
  }, [userContext, userContext.me, userContext.me?.onboardingData.instacart]);

  const handleSubmitCreditCard = async () => {
    setLoading(true);
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    if (!clientSecret) {
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        "Unable to connect to stripe. Try reloading, and if that doesn't work, contact support"
      );
      agent.Brevent.track({
        eventName: "Card Details Failed To Capture",
        userId: userContext.me?.id,
        properties: {
          orgId: orgContext.activeOrgId,
          error: "Client secret issue",
        },
      });
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const addressElement = elements.getElement(AddressElement);
    if (!cardElement || !addressElement) {
      setLoading(false);
      return;
    }

    const { complete, value: addressValue } = await addressElement.getValue();
    if (
      addressValue.name === "" ||
      addressValue.address.line1 === ""
      // addressValue.address.city === "" ||
      // addressValue.address.state === "" ||
      // addressValue.address.postal_code === ""
      // addressValue.address.country === ""
    ) {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("Please enter a valid address");
      setLoading(false);
      return;
    }
    const res = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        metadata: {
          userId: userContext.me?.id || "",
          orgId: orgContext.activeOrgId,
        },
        billing_details: {
          address: {
            line1: addressValue.address.line1,
            // line2: value.address.line2,
            city: addressValue.address.city,
            state: addressValue.address.state,
            postal_code: addressValue.address.postal_code,
            country: addressValue.address.country,
          },
        },
      },
    });

    if (res.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.error(res.error.message);
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        "Unable to submit credit card details. Please try again or contact support"
      );
      agent.Brevent.track({
        eventName: "Card Details Failed To Capture",
        userId: userContext.me?.id,
        properties: {
          orgId: orgContext.activeOrgId,
          error: res.error,
        },
      });
    } else {
      agent.Brevent.track({
        eventName: "Added Card",
        userId: userContext.me?.id,
        properties: {
          orgId: orgContext.activeOrgId,
        },
      });
      cardElement.clear();
      setInlineAlertSeverity("info");
      setInlineAlertMessage("Credit card details saved successfully!");
      setShowCardModal(false);
      await getPaymentMethods();
      //   if (onComplete) await onComplete();
    }
    setLoading(false);
  };

  const acceptCalTechOffer = async () => {
    setLoading(true);
    userContext.setOnboardingObjectFunc({
      caltechOffer: "true",
    });
    const res = await agent.Users.update(
      userContext.me?.id || "",
      createDefaultAnalyticsObject(userContext.me, {
        caltechOffer: "true",
      })
    );
    if (res.success) {
      setInlineAlertMessage(
        "Congrats! This org has the credits applied through June 16, 2023"
      );
      setInlineAlertSeverity("success");
    } else {
      setInlineAlertMessage(
        "The offer couldn't be redeemed. The team has been alerted"
      );
      setInlineAlertSeverity("error");
    }
    setLoading(false);
  };
  const acceptInstacartOffer = async () => {
    setLoading(true);
    await userContext.setOnboardingObjectFunc({
      instacart: "true",
    });
    const res = await agent.Users.update(
      userContext.me?.id || "",
      createDefaultAnalyticsObject(userContext.me, {
        instacart: "true",
      })
    );
    if (res.success) {
      setInlineAlertMessage(
        "Congrats! This org has the credits applied through June 01, 2023"
      );
      setInlineAlertSeverity("success");
      setInstacartOfferStatus(true);
      await userContext.getMe();
    } else {
      setInlineAlertMessage(
        "The offer couldn't be redeemed. The team has been alerted"
      );
      setInlineAlertSeverity("error");
    }
    setLoading(false);
  };

  return (
    <>
      <h1 className="text-lg font-medium dark:text-white">1. Connect Card</h1>
      {billingContext.billingProfile?.billing_type === "usage-arrears" && (
        <p className="mb-2 text-sm text-gray-700 dark:text-secondary">
          Put a card on file to access compute. A $20 authorization charge will
          be placed on your card which will be reverted within 7 days. You will
          be billed in $20 increments for compute usage.{" "}
          <a
            href="mailto:brev-support@nvidia.com"
            className="underline dark:hover:text-slate-300"
          >
            Get in touch with us if you would like to change your billing
            thresholds!
          </a>
        </p>
      )}
      {userContext.me?.onboardingData.caltechOffer === "true" && (
        <p className="text-highlight">
          You applied credits to this org. All users added will have free access
          until June 16, 2023
        </p>
      )}
      {instacartOfferStatus && (
        <p className="text-highlight">
          You applied credits to this org. All users added will have free access
          until June 01, 2023
        </p>
      )}

      <InlineNotification
        show={!!inlineAlertMessage}
        severity="info"
        text={inlineAlertMessage}
        autoClose={false}
        onClose={() => setInlineAlertMessage("")}
      />

      {!instacartOfferStatus && (
        <FeatureToggle feature="instacart">
          <hr className="mt-2" />
          <div className="mt-3 text-sm text-gray-700 dark:text-secondary">
            <span className="flex flex-col items-start justify-start">
              <img src={instacart} alt="Instacart Logo" width={80} />
              <p className="text-highlight">
                $25 per user per month, free until June 01, 2023
              </p>
              <a
                className="mt-2 underline"
                target="_blank"
                rel="noreferrer"
                href="https://brev.dev/terms"
              >
                Terms apply
              </a>
            </span>
            <Button
              label="Accept Offer"
              type="primary"
              className="mt-3"
              onClick={() => {
                acceptInstacartOffer();
              }}
            />
            <hr className="mt-3" />
          </div>
        </FeatureToggle>
      )}
      {userContext.me?.onboardingData.caltechOffer !== "true" && (
        <FeatureToggle feature="BEBi205">
          <hr className="mt-2" />
          <div className="mt-3 text-sm text-gray-700">
            <span className="flex flex-col items-start justify-start">
              <img
                src={CalTech}
                alt="Cal Tech Logo"
                width={80}
                // className="mr-3"
              />
              <p className="text-highlight">
                $25 per user per month, free for Spring Term 2023 (until June
                16th, 2023)
              </p>
              <a
                className="mt-2 underline"
                target="_blank"
                rel="noreferrer"
                href="https://brev.dev/terms"
              >
                Terms apply
              </a>
            </span>
            <Button
              label="Accept Offer"
              type="primary"
              className="mt-3"
              onClick={() => {
                acceptCalTechOffer();
              }}
            />
            <hr className="mt-3" />
          </div>
        </FeatureToggle>
      )}
      <Button
        label={defaultCard ? "Connect a new card" : "Connect a card"}
        type="secondary"
        className="mt-3"
        iconLeft={<GenericCard className="h-5 w-7 mr-3" />}
        onClick={() => {
          setShowCardModal(!showCardModal);
        }}
      />
      <hr className="mt-2 dark:border dark:border-zinc-800" />
      <SimpleTransition show={showCardModal}>
        <div className="mb-10">
          <div className="mt-6">
            <h3
              id="payment-heading"
              className="text-lg font-medium text-gray-900 dark:text-white"
            >
              Payment details
            </h3>
            <CardElement
              options={{
                style: {
                  base: {
                    color: darkMode ? "#cffafe" : "black",
                  },
                },
                classes: {
                  base: "rounded-md border-gray-300 dark:border-zinc-800 shadow-sm p-3 sm:text-sm stripe-border",
                  focus: "border-highlight ring-cyan-500",
                },
                hidePostalCode: false,
              }}
            />
            <AddressElement
              className="mt-3"
              options={{
                mode: "billing",
                fields: {},
                display: {},

                // required: true,
                // display: {
                //   name: "full",
                // },
                // name: "address",
              }}
            />

            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse col-span-4">
              <Button
                className="ml-3"
                label="Save"
                type="primary"
                disabled={loading}
                loading={loading}
                onClick={() => {
                  handleSubmitCreditCard();
                }}
              />
              <Button
                label="Cancel"
                type="secondary"
                onClick={() => {
                  setShowCardModal(false);
                }}
              />
            </div>
          </div>
        </div>
      </SimpleTransition>
      {!showCardModal && (
        <>
          {!loadingPaymentMethods ? (
            <>
              <CreditCardDetails connectedCard={defaultCard} isDefault />
              {connectedCards.length > 0 && (
                <>
                  <span className="flex text-sm mt-1">Other Cards:</span>
                  <div className="flex overflow-auto">
                    {connectedCards.map((connectedCard, index) => (
                      <div className="w-[185px]">
                        <CreditCardDetails connectedCard={connectedCard} />
                      </div>
                    ))}
                    <hr className="mt-2 dark:border dark:border-zinc-800" />
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="flex flex-col justify-center items-center w-full">
              <div className="w-full">
                <FlatCard className="" isLoading={true}>
                  <p className="text-sm text-gray-700 dark:text-secondary">
                    Fetching payment methods
                  </p>
                </FlatCard>
              </div>
            </div>
          )}
        </>
      )}
      <div className="mt-3" />
    </>
  );
};

export default ConnectCard;

export const ConnectCardWrapper: React.FC = () => {
  return (
    <div className="">
      <FlatCard>
        <ConnectCard />
      </FlatCard>
    </div>
  );
};
