import { classNames } from "components/utils";
import React from "react";

interface FilterCardProps {
  className?: string;
  icon: React.ReactNode;
  title: string;
  titleClass?: string;
  description?: string;
  descriptionClass?: string;
}

const FilterCard: React.FC<FilterCardProps> = ({
  className,
  icon,
  title,
  titleClass,
  description,
  descriptionClass,
}) => (
  <div className="flex relative bg-primaryBg animate-border rounded-md border border-zinc-800 cursor-pointer">
    <div className="flex flex-row items-center w-full h-full">
      <div className="px-6 py-2">
        <div
          className={classNames(
            "flex flex-col min-h-[30px] justify-center",
            className ? className : ""
          )}
        >
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center">
              {icon}
              <h1
                className={classNames(
                  "text-sm text-gray-900 dark:text-slate-100",
                  titleClass ? titleClass : ""
                )}
              >
                {title}
              </h1>
            </div>
            {description && (
              <p
                className={classNames(
                  "text-gray-500 dark:text-secondary text-xs ml-2",
                  descriptionClass ? descriptionClass : ""
                )}
              >
                {description}
              </p>
            )}
          </div>
        </div>
      </div>
      <span className={`btnBefore`}></span>
      <span className={`btnAfter`}></span>
    </div>
  </div>
);

export default FilterCard;
