import Chip from "components/Environment/Create/chips";
import "./style.scss";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";

export const ChipCarousel: React.FC = () => {
  const [[activeIndex, direction], setActiveIndex] = useState([0, 0]);
  const items = [
    {
      id: 1,
      name: "H100",
      manufacturer: "NVIDIA",
    },
    {
      id: 2,
      name: "A100",
      manufacturer: "NVIDIA",
    },
    {
      id: 3,
      name: "A10",
      manufacturer: "NVIDIA",
    },
    {
      id: 4,
      name: "L4",
      manufacturer: "NVIDIA",
    },
    {
      id: 5,
      name: "T4",
      manufacturer: "NVIDIA",
    },
  ];

  // we want the scope to be always to be in the scope of the array so that the carousel is endless
  const indexInArrayScope =
    ((activeIndex % items.length) + items.length) % items.length;

  // so that the carousel is endless, we need to repeat the items twice
  // then, we slice the the array so that we only have 3 items visible at the same time
  const visibleItems = [...items, ...items].slice(
    indexInArrayScope,
    indexInArrayScope + 3
  );
  const handleClick = (newDirection) => {
    setActiveIndex((prevIndex) => [prevIndex[0] + newDirection, newDirection]);
  };

  useEffect(() => {
    const interval = setInterval(() => handleClick(-1), 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="main-wrapper">
      <div className="wrapper">
        {/*AnimatePresence is necessary to show the items after they are deleted because only max. 3 are shown*/}
        <AnimatePresence mode="popLayout" initial={false}>
          {visibleItems.map((item) => {
            // The layout prop makes the elements change its position as soon as a new one is added
            // The key tells framer-motion that the elements changed its position
            return (
              <motion.div
                key={item.id}
                layout
                custom={{
                  direction,
                  position: () => {
                    if (item === visibleItems[0]) {
                      return "left";
                    } else if (item === visibleItems[1]) {
                      return "center";
                    } else {
                      return "right";
                    }
                  },
                }}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: 1 }}
              >
                <Chip
                  selected={true}
                  chipName={item.name}
                  manufacturerName={item.manufacturer}
                  onClick={() => {}}
                  className={"h-[68px]"}
                />
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
    </div>
  );
};

const variants = {
  enter: ({ direction }) => {
    return { scale: 0.2, x: direction < 1 ? 50 : -50, opacity: 0 };
  },
  center: ({ position, direction }) => {
    return {
      scale: position() === "center" ? 1 : 0.7,
      x: 0,
      zIndex: getZIndex({ position, direction }),
      opacity: 1,
    };
  },
  exit: ({ direction }) => {
    return { scale: 0.2, x: direction < 1 ? -50 : 50, opacity: 0 };
  },
};

function getZIndex({ position, direction }) {
  const indexes = {
    left: direction > 0 ? 2 : 1,
    center: 3,
    right: direction > 0 ? 1 : 2,
  };
  return indexes[position()];
}
