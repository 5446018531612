import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import FlatCard from "components/UI-lib/FlatCard";
import ModelSelector from "./ModelSelector";
import "./style.scss";
import InputField from "components/UI-lib/InputField";
import { classNames, validateName } from "components/utils";
import { CubeIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline";
import Button from "components/UI-lib/Button";
import { BillingContext } from "contexts/BillingContext";
import { DeploymentsContext } from "contexts/DeploymentsContext";
import { AddCreditsModal } from "components/ConnectCloudsOrCardModal/AddCreditsModal";
import ConnectCloudsOrCardModal from "components/ConnectCloudsOrCardModal";
import { allPublicCloudsName, handleIsPaymentSetup } from "./utils";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { useHistory } from "react-router";
import agent, {
  Deployment,
  DeploymentInstanceType,
  DeploymentModelProvider,
  getProviderString,
} from "server";
import hfLogo from "assets/img/svg/hf-logo.svg";
import BackendSelector from "./BackendSelector";
import posthog from "posthog-js";
import { UserContext } from "contexts/UserContext";
import { a } from "vitest/dist/suite-IbNSsUWN";

export interface ModelSelected {
  modelPath: string;
  modelProvider: DeploymentModelProvider;
}

export interface BackendSelected {
  instanceType: DeploymentInstanceType;
}

export interface DeploymentCreateProps {}

const DeploymentCreate: React.FC = () => {
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const billingContext = useContext(BillingContext);
  const deploymentsContext = useContext(DeploymentsContext);
  const history = useHistory();

  //deploymentState
  const [model, setModel] = useState<ModelSelected>({
    modelPath: "",
    modelProvider: DeploymentModelProvider.UNSPECIFIED,
  });
  const [backend, setBackend] = useState<BackendSelected>();
  const [name, setName] = useState<string>("awesome-deployment-name");
  const [nameErrorMessage, setErrorMessage] = useState<string>("");

  const [selectedCloudName, setSelectedCloudName] =
    useState(allPublicCloudsName);
  const [loading, setLoading] = useState(false);

  const [minInstances, setMinInstances] = useState(0);
  const [maxInstances, setMaxInstances] = useState(1);

  // Credit card modal state
  const [showConnectCloudsOrCardModal, setShowConnectCloudsOrCardModal] =
    useState<boolean>(false);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertAction, setInlineAlertAction] = useState<any>(<></>);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  const [noCardOnFile, setNoCardOnFile] = useState<boolean>(false);

  useEffect(() => {
    handleIsPaymentSetup(selectedCloudName, orgContext.activeOrgId).then(
      (isPaymentSetUp) => setNoCardOnFile(!isPaymentSetUp)
    );
  }, [orgContext.workspaceGroups, selectedCloudName]);

  useEffect(() => {
    if (orgContext.activeOrgId != "") {
      deploymentsContext.getDeploymentInstanceTypes(orgContext.activeOrgId);
    }
  }, [orgContext.activeOrgId]);

  const handleNameChange = (val: string) => {
    setName(val);
    // Set validation error messaging
    if (val === "") {
      setErrorMessage("Name required!");
      return;
    }
    const isValid = validateName(val);
    if (!isValid) {
      setErrorMessage("Name can only have letters, numbers and dashes.");
      return;
    }
    setErrorMessage("");
  };

  // need a field for model name
  const handleCreate = async () => {
    setInlineAlertMessage("");
    setLoading(true);
    const res = await agent.Deployments.createDeployments(
      orgContext.activeOrgId,
      {
        name: name,
        deployment_provider_cred_id:
          backend?.instanceType?.deployment_provider_cred_id,
        instance_type: backend?.instanceType?.type,
        location: backend?.instanceType?.location,
        min_instances: minInstances,
        max_instances: maxInstances,
        artifact: {
          model: {
            model_name: model.modelPath,
            model_provider: model.modelProvider,
          },
        },
      }
    );

    posthog.capture("Creating Deployment", {
      name: name,
      deploymentProviderCredID:
        backend?.instanceType?.deployment_provider_cred_id,
      instanceType: backend?.instanceType?.type,
      gpuName: backend?.instanceType.supported_gpus
        ? backend?.instanceType.supported_gpus[0].name
        : "Unknown",
      location: backend?.instanceType?.location,
      minInstances: minInstances,
      maxInstances: maxInstances,
      modelName: model.modelPath,
      modelProvider: getProviderString(model.modelProvider),
      orgID: orgContext.activeOrgId,
      userID: userContext.me?.id,
      wasSuccess: res.success,
      errorMessage: !res.success ? res.message || "" : "",
    });

    if (res.success && res.data) {
      // TO DO: deployment returned should populate context
      console.log("succesful deployment", res);
      history.push(
        `/org/${orgContext.activeOrgId}/deployments/${res.data.deployment_id}`
      );
    } else {
      setInlineAlertSeverity("error");
      setInlineAlertMessage(res.message || "");
    }
    setLoading(false);
  };

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-10 pt-3">
      {billingContext.billingProfile?.billing_type === "credit" ? (
        <AddCreditsModal
          show={showConnectCloudsOrCardModal}
          setShow={setShowConnectCloudsOrCardModal}
          onCancel={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setNoCardOnFile={setNoCardOnFile}
          noCardOnFile={noCardOnFile}
          onSuccess={() => {
            setShowConnectCloudsOrCardModal(false);
            setInlineAlertSeverity("success");
            setInlineAlertMessage("Credit card details saved successfully!");
          }}
        />
      ) : (
        <ConnectCloudsOrCardModal
          show={showConnectCloudsOrCardModal}
          setShow={setShowConnectCloudsOrCardModal}
          onCancel={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setNoCardOnFile={setNoCardOnFile}
          onSuccess={() => {
            setShowConnectCloudsOrCardModal(false);
            setInlineAlertSeverity("success");
            setInlineAlertMessage("Credit card details saved successfully!");
          }}
          setSelectedCloudName={setSelectedCloudName}
          selectedCloudName={selectedCloudName}
        />
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex mt-8">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-slate-100">
            Create a Deployment
          </h1>
        </div>
        <hr className="w-[100%] my-5 dark:border dark:border-zinc-800" />
        <div className="flex flex-col mt-5">
          <AnimatePresence initial={false}>
            {model.modelPath === "" && (
              <motion.section
                key="container"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{
                  duration: 0.8,
                  ease: [0.04, 0.62, 0.23, 0.98],
                }}
              >
                <motion.div
                  variants={{
                    collapsed: { scale: 0.8 },
                    open: { scale: 1 },
                  }}
                  transition={{ duration: 0.8 }}
                  className="content-placeholder"
                >
                  <ModelSelector
                    setModel={setModel}
                    instanceTypes={deploymentsContext.instanceTypes}
                  />
                </motion.div>
              </motion.section>
            )}
            {model.modelPath !== "" && (
              <>
                <div
                  onClick={() => {
                    setModel({
                      modelPath: "",
                      modelProvider: DeploymentModelProvider.UNSPECIFIED,
                    });
                  }}
                  className="mb-5"
                >
                  <ModelPreview
                    modelPath={model.modelPath}
                    modelProvider={model.modelProvider}
                  />
                </div>
                <BackendSelector
                  selectedBackend={backend}
                  setSelectedBackend={setBackend}
                  instanceTypes={deploymentsContext.instanceTypes}
                />
                {!!backend && (
                  <div className="">
                    <hr className="w-[100%] my-5 dark:border dark:border-zinc-800" />
                    <div>
                      <div className="flex flex-row items-center mb-2">
                        <Square3Stack3DIcon className="w-5 h-5 mr-1" />
                        <h2 className="col-span-full text-lg font-semibold text-gray-900 dark:text-white">
                          Specify Instances
                        </h2>
                      </div>
                      <div className="flex flex-row max-w-[500px]">
                        <InputField
                          className="flex-1 mr-3"
                          label="Min Instances"
                          type="number"
                          value={minInstances.toString()}
                          onChange={(val) => {
                            const min = parseInt(val);
                            if (min < 0) {
                              setMinInstances(0);
                            } else if (min > maxInstances) {
                              setMinInstances(maxInstances);
                            } else {
                              setMinInstances(min);
                            }
                          }}
                          placeholder="0"
                          errorMessage=""
                        />
                        <InputField
                          className="flex-1"
                          label="Max Instances"
                          type="number"
                          value={maxInstances.toString()}
                          onChange={(val) => {
                            const max = parseInt(val);
                            if (max > 10) {
                              setMaxInstances(10);
                            } else if (max < minInstances) {
                              setMaxInstances(minInstances);
                            } else {
                              setMaxInstances(max);
                            }
                          }}
                          placeholder="0"
                          errorMessage=""
                        />
                      </div>
                    </div>
                    <div>
                      <h2 className="col-span-full text-lg font-semibold text-gray-900 dark:text-white mt-4 mb-2">
                        Name your Deployment
                      </h2>

                      <InputField
                        label=""
                        value={name}
                        onChange={handleNameChange}
                        errorMessage={""}
                        className="w-[400px]"
                      />
                    </div>
                    <div className="flex flex-col mt-5">
                      <div>
                        <Button
                          className={classNames(
                            "mt-2",
                            nameErrorMessage ? "" : "pulse-ready-button"
                          )}
                          type={"primary"}
                          label={false ? "Deploying" : "Deploy"}
                          disabled={!!nameErrorMessage}
                          loading={loading}
                          onClick={handleCreate}
                          data-testid="deploy-button"
                        />
                      </div>
                      <InlineNotification
                        show={!!inlineAlertMessage}
                        severity={inlineAlertSeverity}
                        className="w-full mt-5 pb-2"
                        text={inlineAlertMessage}
                        text2={
                          <div className="mt-2 ml-[36px]">
                            {inlineAlertAction}
                          </div>
                        }
                        autoClose={false}
                        onClose={() => {
                          setInlineAlertMessage("");
                          setInlineAlertAction(<></>);
                          setInlineAlertSeverity("error");
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default DeploymentCreate;

export interface ModelPreviewProps {
  modelPath: string;
  modelProvider: DeploymentModelProvider;
}

const ModelPreview: React.FC<ModelPreviewProps> = ({
  modelPath,
  modelProvider,
}) => {
  return (
    <FlatCard className="animate-border cursor-pointer">
      <div className="flex flex-row items-center">
        <div className="flex flex-row">
          <div className="flex flex-row text-white items-center mr-2">
            <CubeIcon className={`w-5 h-5 mr-1`} />
            <div>Model Selected: </div>
          </div>
          <div className="flex flex-row items-center">
            {modelProvider === DeploymentModelProvider.HUGGING_FACE && (
              <img src={hfLogo} alt="Hugging Face" className="w-6 h-6 mr-1" />
            )}
            <div className="text-sm">{modelPath}</div>
          </div>
        </div>
      </div>
      <span className="btnBefore"></span>
      <span className="btnAfter"></span>
    </FlatCard>
  );
};
