/* This example requires Tailwind CSS v2.0+ */
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BellIcon, Bars3Icon, XCircleIcon } from "@heroicons/react/24/outline";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import {
  Capability,
  exposePublicPortsCapability,
  modifyFirewallCapability,
  OrgContext,
} from "contexts/OrgContext";
import { useHistory, useParams } from "react-router-dom";
import Workspace from "entities/Workspace.entity";
import agent from "server";
import { PRESERVED_PATH } from "constants/index";
import Toggle from "components/UI-lib/Toggle";
import FlatCard from "components/UI-lib/FlatCard";
import InputField from "components/UI-lib/InputField";
import Button from "components/UI-lib/Button";
import { classNames } from "components/utils";

// import "./styles.scss";

interface SetupScriptsSectionProps {
  workspace: Workspace;
  capabilities: Capability[];
}

const ExposeNewPortsSection: React.FC<SetupScriptsSectionProps> = (props) => {
  const wsContext = useContext(WorkspaceContext);
  const [loading, setLoading] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const [newPortUserWantsToExpose, setNewPortUserWantsToExpose] = useState("");
  const [portsUserAdded, setPortsUserAdded] = useState<string[]>([]);

  const handleAddNewPort = async () => {
    if (
      !props.workspace ||
      !newPortUserWantsToExpose ||
      newPortUserWantsToExpose <= "0"
    ) {
      console.log("invalid port");
      return;
    }
    setLoading(true);
    const res = await wsContext.addNewPortToWorkspace(
      props.workspace.id,
      newPortUserWantsToExpose
    );
    setLoading(false);
    if (!res.success) {
      console.log("error adding port");
    } else {
      setPortsUserAdded([...portsUserAdded, newPortUserWantsToExpose]);
    }
  };

  const revokePort = async (port: string) => {
    setRevokeLoading(true);
    const res = await wsContext.revokePortFromWorkspace(
      props.workspace.id,
      port
    );
    setRevokeLoading(false);
    if (!res.success) {
      console.log("error revoking port");
    } else {
      if (props.workspace.exposedPorts) {
        props.workspace.exposedPorts = props.workspace.exposedPorts.filter(
          (p) => p !== port
        );
      }
      setPortsUserAdded(portsUserAdded.filter((p) => p !== port));
    }
  };
  const canModifyFirewallCapability = useMemo(() => {
    return !!props.capabilities.find(
      (capability) => capability === modifyFirewallCapability
    );
  }, [props.capabilities]);

  return (
    <div className="flex flex-col justify-start items-start">
      <h1 className="text-xl font-medium mt-5 mb-2 dark:text-white">
        Public Ports
      </h1>
      {!canModifyFirewallCapability && (
        <span className={classNames("text-rose-500 text-sm mb-3")}>
          This cloud provider doesn't allow the modifications of ports
        </span>
      )}
      <FlatCard
        className={classNames(
          "pt-0 mb-3",
          !canModifyFirewallCapability ? "opacity-45" : ""
        )}
      >
        <>
          <div className="flex flex-row justify-between items-center w-full mb-2">
            <p className="text-md text-gray-500 dark:text-secondary mt-2">
              A HTTP URL will be generated for each public port
            </p>
            <div className="mt-3">
              <Button
                label="Expose Port"
                type="secondary"
                className="float-right"
                loading={loading}
                onClick={() => handleAddNewPort()}
                disabled={loading || !canModifyFirewallCapability}
              />
              <InputField
                label=""
                key={2}
                disabled={!canModifyFirewallCapability}
                // helperText="Enter the full URL of the Github repo"
                value={newPortUserWantsToExpose}
                onKeyDown={(e) => {
                  if (
                    !/[0-9\b]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
                errorMessage=""
                placeholder="Expose a New Port"
                onChange={(val) => setNewPortUserWantsToExpose(val)}
                className="mr-3 float-right"
                hideLabel
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center w-full mb-1">
            <div className="w-full">
              {props.workspace.exposedPorts && (
                <div className="w-full">
                  {[
                    ...props.workspace.exposedPorts.slice(
                      0,
                      Math.min(props.workspace.exposedPorts.length, 100)
                    ),
                    ...portsUserAdded,
                  ].map((port) => (
                    <PortUrlItem
                      key={port}
                      url={props.workspace.dns}
                      port={port}
                      isSSHPort={port == props.workspace.sshPort?.toString()}
                      revokePort={revokePort}
                      showRevoke
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      </FlatCard>
    </div>
  );
};

export default ExposeNewPortsSection;

interface PortUrlItemProps {
  url: string;
  port: string;
  isSSHPort: boolean;
  revokePort: (port: string) => void;
  showRevoke: boolean;
}

const PortUrlItem: React.FC<PortUrlItemProps> = ({
  url,
  port,
  isSSHPort,
  revokePort,
  showRevoke,
}) => {
  const [internalRevokeLoading, setInternalRevokeLoading] = useState(false);

  return (
    <div className="bg-white dark:bg-zinc-900 w-full rounded border-solid border dark:border-zinc-800 flex items-center justify-start mb-2">
      <button className="text-base w-24 bg-gray-50 dark:text-secondary dark:bg-zinc-900 rounded-tr-none rounded-br-none rounded-r-none border-solid border-r dark:border-zinc-800 p-2">
        {port}
      </button>
      {validUrl(isSSHPort, url) ? (
        <div className="flex w-full">
          {/* <div className="w-full text-base cursor-pointer py-1 px-3 select-none truncate text-gray-900 font-medium text-gray-900 text-cyan-700 underline ml-1"> */}
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleClick(isSSHPort, port, url);
            }}
            className="cursor-pointer py-1 px-3 select-none  text-base  truncate underline ml-1 text-highlight dark:border-zinc-800 dark:placeholder-gray-500 dark:bg-zinc-900 hover:text-highlightLighter mt-1"
          >
            {getUrlToDisplay(isSSHPort, port, url)}
          </div>
          {showRevoke && (
            <Button
              type="secondary"
              label="Revoke Port"
              className="float-right rounded-none border-l-1 border-l-gray-200 dark:border-zinc-800 border-r-0 border-t-0 border-b-0 dark:text-secondary dark:bg-zinc-900 h-10 ml-auto"
              onClick={() => {
                setInternalRevokeLoading(true);
                revokePort(port);
              }}
              loading={internalRevokeLoading}
              disabled={false}
            />
          )}
        </div>
      ) : (
        <div className="w-full text-base py-1 px-3 select-none truncate text-sm text-gray-500 dark:text-secondary">
          {getUrlToDisplay(isSSHPort, port, url)}
        </div>
      )}
    </div>
  );
};

const handleClick = (isSSHPort: boolean, port: string, url: string) => {
  if (isSSHPort || !url) return;
  window.open(
    getUrlToLink(isSSHPort, port, url),
    "_blank",
    "noopener,noreferrer"
  );
};

const getUrlToLink = (isSSHPort: boolean, port: string, url: string) => {
  if (!url) {
    if (isSSHPort) return "SSH will run on this port";
    return "URL will be available when you start the instance";
  }
  if (isSSHPort) return "SSH is running on this port";
  return `http://${url}:${port}`;
};

const getUrlToDisplay = (isSSHPort: boolean, port: string, url: string) => {
  if (!url) {
    if (isSSHPort) return "SSH will run on this port";
    return "URL will be available when you start the instance";
  }
  if (isSSHPort) return "SSH is running on this port";
  return `${url}:${port}`;
};

const validUrl = (isSSHPort: boolean, url: string) => {
  if (!url || isSSHPort) {
    return false;
  }
  return true;
};

interface SetupScriptsSectionProps {
  workspace: Workspace;
  capabilities: Capability[];
}

export const StaticPortsSection: React.FC<SetupScriptsSectionProps> = (
  props
) => {
  // so we need to somehow generate an algorithm to let us display certain ports
  const numberPorts = props.workspace.exposedPorts?.map((str) => Number(str));
  return (
    <div className="flex flex-col justify-start items-start w-4/5">
      <h1 className="text-xl font-medium mb-2 text-primary dark:text-white">
        Public Ports
      </h1>

      <FlatCard className="pt-0">
        <>
          <div className="flex flex-row justify-between items-center w-full mb-2">
            <p className="text-md text-gray-500 mt-2 dark:text-secondary">
              This instance does not support dynamically adding and revoking
              public ports.{" "}
              {numberPorts && (
                <>
                  Ports <strong>{` ${Math.min(...numberPorts)}`}</strong> to{" "}
                  <strong>{Math.max(...numberPorts)}</strong> are open publicly.
                  To access a service running on your instance go to:
                </>
              )}
            </p>
            <div className="mt-3" />
          </div>
          <div className="flex flex-row justify-between items-center w-full mb-1">
            <div className="w-full text-md text-gray-500 dark:text-secondary">
              {/* To access a service running on your instance go to: */}
              <div className="mt-1 mb-1">
                <PortUrlItem
                  url={props.workspace.dns}
                  port="{PORT}"
                  isSSHPort={false}
                  revokePort={() => {}}
                  showRevoke={false}
                />
              </div>
              and replace <strong>{`{PORT}`}</strong> with the port your service
              is running on.
            </div>
          </div>
        </>
      </FlatCard>
    </div>
  );
};
