import React, { act, useContext, useEffect, useMemo, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import FlatCard from "components/UI-lib/FlatCard";
import { classNames } from "components/utils";
import { AreaChart } from "components/Graphs/AreaGraph";

interface AreaChartWrapperProps {
  loading: boolean;
  data: any[];
  noDataLabel: string;
  categories: string[];
  placeholderData: any[];
  placeholderCategories: string[];
}

const AreaChartWrapper: React.FC<AreaChartWrapperProps> = ({
  loading,
  data,
  noDataLabel,
  categories,
  placeholderData,
  placeholderCategories,
}) => {
  return (
    <>
      {loading || data.length === 0 ? (
        <div className="flex relative w-full">
          <div
            className={classNames(
              "absolute top-[20%] z-50",
              loading ? "left-[48%]" : "left-[40%]"
            )}
          >
            <span className="text-white font-bold">
              {loading ? "Loading..." : noDataLabel}
            </span>
          </div>
          <AreaChart
            className="opacity-15 h-80"
            data={placeholderData}
            showTooltip={false}
            showLegend={false}
            disableHoverDots={true}
            index="date"
            categories={placeholderCategories}
            intervalType="preserveStartEnd"
          />
        </div>
      ) : (
        <>
          <AreaChart
            className="h-80"
            data={data}
            showLegend={false}
            index="date"
            categories={categories}
            onValueChange={(v) => console.log(v)}
            intervalType="preserveStartEnd"
          />
        </>
      )}
    </>
  );
};

export default AreaChartWrapper;
