import { dump, load } from "js-yaml";
import { Transition } from "@headlessui/react";
import axios from "axios";
import config from "config";
import User from "../entities/User.entity";
import { PermissionHierarchyType } from "models/Permission.model";
import { OrgRoleIds, RoleIds, WorkspaceRoleIds } from "models/Role.model";
import { DestType, HierarchyType } from "models/Secret.model";
import {
  HealthStatus,
  IFileObject,
  IFileType,
  NotebookFileType,
  WorkspaceClassId,
  WorkspaceStatus,
} from "models/Workspace.model";
import agent from "server";
import React from "react";
import { useLocation } from "react-router-dom";
import {
  getInstanceTypeDefaultFromQuery,
  getIsCPUDefaultFromQuery,
} from "contexts/WorkspaceCreateContext";
import { supportedCPUInstances } from "./Environment/Settings/Tabs/Compute/InstanceChanger/CPUSelectorTable";
import { GPUInstanceType } from "./Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";

export interface InterimGitUrlType {
  url: string;
  branch?: string;
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function roundPriceToTwoDigits(price: string): string {
  const floatPrice = parseFloat(price);
  return floatPrice.toFixed(2);
}

export const displayCPUs = (cid: WorkspaceClassId) => {
  switch (cid) {
    case WorkspaceClassId.TwoByTwo: {
      return 2.0;
    }
    case WorkspaceClassId.TwoByFour: {
      return 2.0;
    }
    case WorkspaceClassId.TwoByEight: {
      return 2.0;
    }
    case WorkspaceClassId.FourBySixteen: {
      return 4.0;
    }
    case WorkspaceClassId.EightByThirtyTwo: {
      return 8.0;
    }
    case WorkspaceClassId.SixteenByThirtyTwo: {
      return 16.0;
    }
  }
};

export const displayRAM = (cid: WorkspaceClassId) => {
  switch (cid) {
    case WorkspaceClassId.TwoByTwo: {
      return 2;
    }
    case WorkspaceClassId.TwoByFour: {
      return 4;
    }
    case WorkspaceClassId.TwoByEight: {
      return 8;
    }
    case WorkspaceClassId.FourBySixteen: {
      return 16;
    }
    case WorkspaceClassId.EightByThirtyTwo: {
      return 32;
    }
    case WorkspaceClassId.SixteenByThirtyTwo: {
      return 32;
    }
  }
};

export const displayWorkspaceStatus = (status: WorkspaceStatus) => {
  switch (status) {
    case WorkspaceStatus.Failure: {
      return "Error";
    }
    case WorkspaceStatus.Running: {
      return "Running";
    }
    case WorkspaceStatus.Stopping: {
      return "Stopping";
    }
    case WorkspaceStatus.Stopped: {
      return "Stopped";
    }
    case WorkspaceStatus.Starting: {
      return "Starting";
    }
    case WorkspaceStatus.Deploying: {
      return "Deploying";
    }
    case WorkspaceStatus.Deleting: {
      return "Deleting";
    }
  }
};

export const displayHealthStatus = (status: HealthStatus) => {
  switch (status) {
    case HealthStatus.Healthy: {
      return "Healthy";
    }
    case HealthStatus.Unhealthy: {
      return "Unhealthy";
    }
    case HealthStatus.Unavailable: {
      return "Unavailable";
    }
  }
};

export const displayHierarchyType = (type: HierarchyType) => {
  switch (type) {
    case HierarchyType.Organization: {
      return "Organization";
    }
    case HierarchyType.User: {
      return "User";
    }
    case HierarchyType.Workspace: {
      return "Environment";
    }
  }
};

export const displayPermissionHierarchyType = (
  type: PermissionHierarchyType
) => {
  switch (type) {
    case PermissionHierarchyType.Organization: {
      return "organization";
    }
    case PermissionHierarchyType.Workspace: {
      return "workspace";
    }
  }
};

export const displayDestType = (type: DestType) => {
  switch (type) {
    case DestType.EnvVariable: {
      return "Environment Variable";
    }
    case DestType.File: {
      return "File";
    }
  }
};

export enum ConfigType {
  File = "file",
  Text = "text",
  ExtRepo = "ext-repo",
}

export const displayConfigType = (type: ConfigType) => {
  switch (type) {
    case ConfigType.Text: {
      return "Text";
    }
    case ConfigType.File: {
      return "File in this Repository";
    }
    case ConfigType.ExtRepo: {
      return "External Repository";
    }
  }
};

export const displayRoleId = (roleId: RoleIds) => {
  switch (roleId) {
    case OrgRoleIds.Read || WorkspaceRoleIds.Read: {
      return "View Only";
    }
    case OrgRoleIds.Write || WorkspaceRoleIds.Write: {
      return "Read & Write";
    }
  }
};

export const displayGitRepo = (gitRepo: string) =>
  parseCloneFormatToGitUrl(gitRepo).slice(8);

export const destConfigKey = (type: DestType) => {
  switch (type) {
    case DestType.EnvVariable: {
      return "name";
    }
    case DestType.File: {
      return "path";
    }
  }
};

export const destConfigKeyInputValue = (type: DestType) => {
  switch (type) {
    case DestType.EnvVariable: {
      return "Name";
    }
    case DestType.File: {
      return "File Path";
    }
  }
};

export const getDefaultRoleId = (
  hierarchyType: PermissionHierarchyType
): RoleIds => {
  switch (hierarchyType) {
    case PermissionHierarchyType.Organization: {
      return OrgRoleIds.Write;
    }
    case PermissionHierarchyType.Workspace: {
      return WorkspaceRoleIds.Write;
    }
  }
};

export const getRoleIdOptions = (hierarchyType: PermissionHierarchyType) => {
  switch (hierarchyType) {
    case PermissionHierarchyType.Organization: {
      return OrgRoleIds;
    }
    case PermissionHierarchyType.Workspace: {
      return WorkspaceRoleIds;
    }
  }
};
// https://github.com/brevdev/brev-cli
// https://github.com/brevdev/brev.cli

export const getRepoProjectName = (gitRepo: string): string => {
  // const regex = /\/(?<projectName>[0-9a-zA-Z-]+)\.git$/;
  // const match = gitRepo.match(regex);
  // return match?.groups?.projectName === undefined
  //   ? ""
  //   : match.groups.projectName;
  const parts = gitRepo.split(".git")[0].split("/");
  return parts[parts.length - 1];
};

export const getRepoOrgName = (gitRepo: string): string => {
  const regex = /:(?<orgName>[0-9a-zA-Z-]+)\/([0-9a-zA-Z-]+)\.git$/;
  const match = gitRepo.match(regex);
  return match?.groups?.orgName === undefined ? "" : match.groups.orgName;
};

export const getCustomDirectoryFromAbsolutePathName = (
  path: string
): string => {
  const regex =
    /(?!\/home\/brev\/workspace\/)(?<customDirectory>[0-9a-zA-Z-]+)$/;
  const match = path.match(regex);
  return match?.groups?.customDirectory === undefined
    ? ""
    : match.groups.customDirectory;
};

export const getRootDirectoryFromPath = (path: string): string => {
  const folders = path.split("/");
  return folders.length > 1 ? folders[0] : "";
};

export const capitalizeFirstLetter = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const fallbackCopyTextToClipboard = (
  text: string,
  insideModal = false
) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  if (insideModal) {
    document.getElementsByClassName("copypasta")[0].appendChild(textArea);
  } else {
    document.body.appendChild(textArea);
  }
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
  } catch (err) {
    // showToast("unable to copy, please try manually", "error");
  }

  if (insideModal) {
    document.getElementsByClassName("copypasta")[0].removeChild(textArea);
  } else {
    document.body.removeChild(textArea);
  }
};

/*
    Takes a git url and converts it to a format like:
    gitlab.com:mygitlaborg/mycoolrepo.git
 */
export const parseGitUrlToCloneFormat = (url: string): string => {
  let cloneFormat = "";
  if (url.slice(0, 4) === "http") {
    const chunks = url.split("/");
    if (chunks.length > 4) {
      const urlRepoProvider = chunks[2];
      const urlRepoOrgName = chunks[3];
      const urlRepoName = chunks[4].split(".git")[0];
      cloneFormat = `${urlRepoProvider}:${urlRepoOrgName}/${urlRepoName}.git`;
    } else return "";
  } else if (url.slice(0, 4) === "git@") {
    cloneFormat = url.slice(4);
  }

  return cloneFormat;
};

export const getReposInDropdownFormat = (
  gitUrls: { [key: string]: InterimGitUrlType }[]
) => {
  const items: string[] = [`/home/ubuntu`];

  Object.values(gitUrls).map((obj) => {
    if (obj.url.url) {
      const folderName = getRepoProjectName(
        parseGitUrlToCloneFormat(obj.url.url)
      );

      items.push(`/home/ubuntu/${folderName}`);
      // items.push({
      //   display: `~/workspace/${folderName}`,
      //   value: `/home/ubuntu/${folderName}`,
      // });
    }
  });

  return items;
};

/*
  Takes a git url and validates that it is a repository
  Valid formats accepted by the backend:
    github.com:brevdev/backend.git
    git@github.com:brevdev/backend.git
    https://github.com/brevdev/backend
    https://github.com/brevdev/backend.git
 */

// TODO(ishan): this function is broken
export const validateGitRepo = (
  url: string
): { success: boolean; message?: string } => {
  if (url === "")
    return {
      success: false,
      message: "Must enter a git repository. Try again!",
    };
  const regex =
    /((git|http(s)?)|(git@[\w\.]+))(:(\/\/)?)([\w\.@\:/\-~]+)(\.git)?/;
  const success = regex.test(url);
  if (success) return { success };
  return { success, message: "Must be a valid git repository. Try again!" };
};

/*
  Given a git url of the type:
    1. git@github.com:brevdev/brev-cli.git
    2. https://github.com/brevdev/brev-cli
    3. https://github.com/brevdev/brev-cli.git

  This function will return either
    1. brevdev/brev-cli
    2. brevdev/brev-cli.git

  This is convenient for a quick summary view when not in focus.
  Ideally we'd remove the trailing .git but I don't feel like doing that now
 */
export const getDisplayableGitUrlContent = (gitUrl: string) => {
  try {
    let res = gitUrl.split(".com/");
    if (res.length === 2) {
      return res[1];
    }
    res = gitUrl.split(".com:");
    if (res.length === 2) {
      return res[1];
    }

    return gitUrl;
  } catch (err) {
    return gitUrl;
  }
};

/*
    Takes a git url like:
        gitlab.com:mygitlaborg/mycoolrepo.git
    or this
        https://github.com/brevdev/hello-react.git

    and formats it to:
        https://gitlab.com/mygitlaborg/mycoolrepo
 */
export const parseCloneFormatToGitUrl = (cloneFormat: string) => {
  if (cloneFormat === "") return "";

  if (
    cloneFormat.slice(0, 8) === "https://" &&
    cloneFormat.slice(-4) === ".git"
  ) {
    return cloneFormat.slice(0, -4);
  }

  const chunks = cloneFormat.split(":");
  const urlRepoProvider = chunks[0];

  if (
    urlRepoProvider !== "github.com" &&
    urlRepoProvider !== "gitlab.com" &&
    urlRepoProvider !== "bitbucket.org"
  ) {
    const repo = chunks[1].split(".git")[0];
    return `https://${urlRepoProvider}/${repo}`;
  }
  const remainder = chunks[1].split("/");
  const org = remainder[0];
  const repo = remainder[1].split(".git")[0];

  return `https://${urlRepoProvider}/${org}/${repo}`;
};

export function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export const getSetupScriptContentsByURL = async (
  url: string
): Promise<string | null> => {
  const res = await axios.get(url, { responseType: "text" });
  if (res.data) return res.data.replace(/\\r/g, "\\n");
  return null;
};

export const getGithubOauthAuthorizationURL = (
  user: User | null,
  returnTo: string
): URL => {
  const url = new URL(config.githubOauthAuthorizationEndpoint);
  url.searchParams.append("client_id", config.githubOauthClientID);
  // url.searchParams.append("redirect_uri", new URL(config.brevApiUrl + config.githubOauthRedirectRoute).href)
  const state: {
    user?: string;
    returnTo?: string;
  } = {};

  if (returnTo) {
    state.returnTo = returnTo;
  }

  if (user) {
    state.user = user.id;
  }
  const base64State = btoa(JSON.stringify(state));
  url.searchParams.append("state", base64State);
  url.searchParams.append("scope", config.githubOauthScope);
  return url;
};

export const validateName = (name: string): boolean => {
  if (name === "") return false;
  const regex = /^([0-9]|[a-z]|[A-Z])*([0-9a-zA-Z-]+)$/;
  return regex.test(name);
};

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const regionToReadable = {
  "us-east-1": "N. Virginia",
  "us-east-2": "Ohio",
  "us-west-1": "N. California",
  "us-west-2": "Oregon",
  "ap-east-1": "Hong Kong",
  "ap-south-1": "Mumbai",
  "ap-northeast-3": "Osaka-Local",
  "ap-northeast-2": "Seoul",
  "ap-southeast-1": "Singapore",
  "ap-southeast-2": "Sydney",
  "ap-northeast-1": "Tokyo",
  "ca-central-1": "Canada",
  "eu-central-1": "Frankfurt",
  "eu-west-1": "Ireland",
  "eu-west-2": "London",
  "eu-west-3": "Paris",
  "eu-north-1": "Stockholm",
  "me-south-1": "Bahrain",
  "sa-east-1": "São Paulo",
  "af-south-1": "Cape Town",
  "ap-southeast-3": "Jakarta",
  "eu-south-1": "Milan",
  "me-central-1": "Abu Dhabi",
  // GCP
  "us-west1": "Oregon",
  "us-west2": "Los Angeles",
  "us-west3": "Salt Lake City",
  "us-west4": "Las Vegas",
  "us-central1": "Iowa",
  "us-east1": "South Carolina",
  "us-east4": "N. Virginia",
  "us-east5": "Columbus",
  "us-south1": "Dallas",
  "northamerica-northeast1": "Montréal",
  "northamerica-northeast2": "Toronto",
  "southamerica-west1": "Santiago",
  "southamerica-east1": "São Paulo",
  "europe-west2": "London",
  "europe-west1": "Belgium",
  "europe-west4": "Netherlands",
  "europe-west6": "Zurich",
  "europe-west3": "Frankfurt",
  "europe-north1": "Finland",
  "europe-central2": "Warsaw",
  "europe-west8": "Milan",
  "europe-southwest1": "Madrid",
  "europe-west9": "Paris",
  "europe-west12": "Turin",
  "asia-south1": "Mumbai",
  "asia-south2": "Delhi",
  "asia-southeast1": "Singapore",
  "asia-southeast2": "Jakarta",
  "asia-east2": "Hong Kong",
  "asia-east1": "Taiwan",
  "asia-northeast1": "Tokyo",
  "asia-northeast2": "Osaka",
  "australia-southeast1": "Sydney",
  "australia-southeast2": "Melbourne",
  "asia-northeast3": "Seoul",
  // Fluidstack
  recWvDf7kynwhsvJJ: "Illinois",
  recOum4Nfi6rbGfIB: "Nevada",
  recGhvR00rN4ULzr1: "New York",
  norway_2_eu: "Norway",
  calgary_1_canada: "Canada",
  norway_3_eu: "Norway",
  norway_4_eu: "Norway",
  india_2: "India",
  nevada_1_usa: "Nevada",
  generic_1_canada: "Canada",
  iceland_1_eu: "Iceland",
  new_york_1_usa: "New York",
  illinois_1_usa: "Illinois",
};

export const workspaceGroupToReadable = {
  "devplane-brev-1": "Brev Cloud",
  "lambda-labs-test": "Lambda Labs",
};

export const getReadableWorkspaceGroup = (workspaceGroupId: string) =>
  workspaceGroupToReadable[workspaceGroupId] || workspaceGroupId;

// Tech Debt Consolidation here...

export const CPUTypes = {
  "t3a.medium": { cpu: 2, ram: 4 },
  "t3a.large": { cpu: 2, ram: 8 },
  "t3a.xlarge": { cpu: 4, ram: 16 },
  "t3a.2xlarge": { cpu: 8, ram: 32 },
  "t3.medium": { cpu: 2, ram: 4 },
  "t3.large": { cpu: 2, ram: 8 },
  "t3.xlarge": { cpu: 4, ram: 16 },
  "t3.2xlarge": { cpu: 8, ram: 32 },
};

export const NamesCPUTypes = {
  "2x4": "t3.medium",
  "2x8": "t3.large",
  "4x16": "t3.xlarge",
  "8x32": "t3.2xlarge",
};

export const isCPU_techdebt = (instance: string) => {
  try {
    if (
      !!supportedCPUInstances.find(
        (cpuInstance) => cpuInstance.type === instance
      )
    ) {
      return true;
    }
    if (getIsCPUDefaultFromQuery(instance)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

// Check if a character might be part of a multi-code-point emoji.
export function isEmoji(char) {
  const codePoint = char.codePointAt(0);
  return (
    // High Surrogates for UTF-16, often used by emojis.
    (codePoint >= 0xd800 && codePoint <= 0xdbff) ||
    // Misc symbols and Dingbats
    (codePoint >= 0x2600 && codePoint <= 0x27bf)
  );
}

export function memoryToGiB(memory: string): number {
  const units = {
    GiB: 1,
    TiB: 1024,
    MiB: 1 / 1024,
    // Add more units as needed
  };

  let totalGiB = 0;

  // Regular expression to match numbers followed by unit names
  const regex = /(\d+(?:\.\d+)?)([A-Z][iB]+)/g;
  let match;

  while ((match = regex.exec(memory)) !== null) {
    const value = parseFloat(match[1]);
    const unit = match[2];
    if (units[unit] !== undefined) {
      totalGiB += value * units[unit];
    }
  }

  return totalGiB;
}

export const getInstancesBelongingToCertainClouds = (
  instances: GPUInstanceType[],
  cloudNames: string[]
): GPUInstanceType[] => {
  const filteredInstances = filterInstancesByCloud(instances, cloudNames);
  const instancesWithFilteredWorkspaceGroups = filteredInstances.map((i) =>
    filterWorkspaceGroupsFromInstanceObject(i, cloudNames)
  );
  return instancesWithFilteredWorkspaceGroups;
};

// so we can extend this to filter the workspacegroups property of the instancetype
export const filterInstancesByCloud = (
  instances: GPUInstanceType[],
  cloudNames: string[]
): GPUInstanceType[] => {
  const filteredInstances = instances.filter((i) => {
    const clouds = i.workspace_groups;
    return clouds.some((c) => cloudNames.includes(c.name));
  });
  return filteredInstances;
};

export const filterWorkspaceGroupsFromInstanceObject = (
  instance: GPUInstanceType,
  cloudNamesWanted: string[]
): GPUInstanceType => {
  const workspaceGroups = instance.workspace_groups.filter((w) =>
    cloudNamesWanted.includes(w.name)
  );
  return { ...instance, workspace_groups: workspaceGroups };
};

export const parseStatusMessage = (message: string) => {
  return message.replaceAll("rpc error: code = Internal desc =", " ");
};

export const integerValidation = (value: string) => {
  const regex = /^\d+$/; // This regular expression matches only integer values
  return regex.test(value);
};

export const integerValidationOrEmpty = (value: string) => {
  // Allow empty strings
  if (value === "") {
    return true;
  }

  // Check if the value is an integer
  return /^-?\d+$/.test(value);
};

// is integer greater than 20
export const integerValidationGreaterThan20OrEmpty = (value: string) => {
  // Allow empty strings
  if (value === "") {
    return true;
  }
  const regex = /^\d+$/; // This regular expression matches only integer values
  return regex.test(value) && parseInt(value) > 20;
};

export function mapValuesToArray<T>(objectMap: { [key: string]: T }): T[] {
  return Object.values(objectMap);
}

export function arrayToPathMap(fileObjects: IFileObject[]): {
  [path: string]: IFileObject;
} {
  const map: { [path: string]: IFileObject } = {};

  fileObjects.forEach((fileObject) => {
    map[fileObject.path] = fileObject;
  });

  return map;
}

export function replaceBlobWithRaw(url: string): string {
  const githubDomain = "github.com";
  const gitlabDomain = "gitlab.com";

  if (url.includes(githubDomain) && url.includes("/blob/")) {
    return url.replace("/blob/", "/raw/");
  } else if (url.includes(gitlabDomain) && url.includes("/-/blob/")) {
    return url.replace("/-/blob/", "/-/raw/");
  }

  return url;
}

export function replaceRawWithBlob(url: string): string {
  const githubDomain = "github.com";
  const gitlabDomain = "gitlab.com";

  if (url.includes(githubDomain) && url.includes("/raw/")) {
    return url.replace("/raw/", "/blob/");
  } else if (url.includes(gitlabDomain) && url.includes("/raw/")) {
    return url.replace("/raw/", "/-/blob/");
  }

  return url;
}

export interface NotebookItem {
  name: string;
  url: string;
  imageUrl: string;
  queryParams: string;
}

export const notebooks: NotebookItem[] = [
  {
    name: "Fine-tune Mistral 7B on your own data",
    url: "https://github.com/brevdev/notebooks/blob/main/mistral-finetune-own-data.ipynb",
    imageUrl: "https://brev.dev/_next/static/media/mistral.e780928b.jpg",
    queryParams:
      "instance=A10G:g5.xlarge&diskStorage=256&name=mistral-finetune-own-data&python=3.10&cuda=12.1.1",
  },
  // {
  //   name: "Run ControlNet with A utomatic1111 Stable Diffusion Web UI",
  //   url: "https://github.com/brevdev/notebooks/raw/main/mistral-finetune-own-data.ipynb",
  //   imageUrl: "https://brev.dev/_next/static/media/obey.57242832.png",
  //   queryParams: "",
  // },
  {
    name: "Deploy an AI/ML model to Replicate",
    url: "https://github.com/brevdev/notebooks/raw/main/deploy-to-replicate.ipynb",
    imageUrl:
      "https://uohmivykqgnnbiouffke.supabase.co/storage/v1/object/public/landingpage/brevreplicate1.png",
    queryParams: "",
  },
  {
    name: "Fine-tune Llama 2",
    url: "https://github.com/brevdev/notebooks/raw/main/llama2-finetune.ipynb",
    imageUrl:
      "https://brev.dev/_next/static/media/llama2-finetune.9d72b21a.png",
    queryParams:
      "instance=A10G:g5.xlarge&diskStorage=256&name=llama2-finetune&python=3.10&cuda=12.1.1",
  },
  {
    name: "Run Julia Lang",
    url: "https://github.com/brevdev/notebooks/raw/main/julia-install.ipynb",
    imageUrl:
      "https://uohmivykqgnnbiouffke.supabase.co/storage/v1/object/public/landingpage/julia.png",
    queryParams: "",
  },
];

export const getPathFromUrl = (url: string, host?: boolean) => {
  // TODO(ishan): this is currently hardcoded to the rapids notebook repo we set in src/components/Verb/utils/index.tsx
  if (url.includes("rapids")) {
    return `/home/rapids/notebooks/${url.split("/").pop()}`;
  }
  if (host) {
    return `./verb-workspace/${url.split("/").pop()}`;
  } else {
    return `./verb-workspace/${url.split("/").pop()}`;
  }
};

const checkPublicity = async (
  url: string,
  fileType: IFileType
): Promise<string | null> => {
  const res = await agent.Workspaces.validateFilePublicity({
    fileUrl: url,
    fileType: fileType,
  });
  if (res.success && res.data) {
    return res.data.public ? null : "Repository is private";
  }
  return "Failed to validate publicity. Please ensure this is a public repository";
};

const gitlabNotebookRegex =
  /^https:\/\/(?:www\.)?gitlab\.com\/[\w-]+\/[\w-]+\/-\/(?:blob|raw)\/[\w-]+\/[\w\/-]+\.ipynb$/;
const githubNotebookRegex =
  /^https?:\/\/github\.com\/[^\/]+\/[^\/]+\/(?:blob|raw)\/[^\/]+(\/[^\/]+)*\/.*\.ipynb$/;
const colabRegex =
  /^https:\/\/colab\.research\.google\.com\/drive\/([a-zA-Z0-9_-]+)(\/)?(?=[?#]|$)/;
const githubRepoRegex = /^https?:\/\/github\.com\/[^\/]+\/[^\/]+$/;
const gitlabRepoRegex =
  /^https?:\/\/(?:www\.)?gitlab\.com\/[\w.-]+\/[\w.-]+(?:\/)?$/;

export const getNotebookFileTypeFromUrl = (url: string) => {
  if (githubNotebookRegex.test(url)) {
    return NotebookFileType.GithubFileType;
  } else if (gitlabNotebookRegex.test(url)) {
    return NotebookFileType.GitlabFileType;
  } else if (colabRegex.test(url)) {
    return NotebookFileType.ColabFileType;
  }
  return NotebookFileType.GithubFileType;
};

export const isValidNotebookUrl = async (
  url: string
): Promise<string | null> => {
  const validGitHubCheck = githubNotebookRegex.test(url);
  const validGitLabCheck = gitlabNotebookRegex.test(url);
  if (!validGitHubCheck && !validGitLabCheck) {
    return "Invalid Jupyter notebook URL. Make sure this is a GitHub or GitLab URL that ends in .ipynb";
  }
  const publicityCheck = await checkPublicity(url, IFileType.NotebookFileType);
  return publicityCheck ? publicityCheck : null;
};

export const isValidColabUrl = async (url: string): Promise<string | null> => {
  const validRegexCheck = colabRegex.test(url);
  if (!validRegexCheck) {
    return "Invalid Google Colab URL. Make sure this is a Google Colab URL";
  }
  const publicityCheck = await checkPublicity(url, IFileType.ColabFileType);
  return publicityCheck ? publicityCheck : null;
};

export const githubRepoRegexCheck = (url: string): boolean => {
  return githubRepoRegex.test(url);
};

export const gitlabRepoRegexCheck = (url: string): boolean => {
  return gitlabRepoRegex.test(url);
};

export const isValidGithubRepoUrl = async (
  url: string
): Promise<string | null> => {
  const validRegexCheck = githubRepoRegexCheck(url);
  if (!validRegexCheck) {
    return "Invalid GitHub repository URL. Ensure the form is https://github.com/<username>/<repo>";
  }
  const publicityCheck = await checkPublicity(
    url,
    IFileType.GithubRepoFileType
  );
  return publicityCheck ? publicityCheck : null;
};

export const isValidGitlabRepoUrl = async (
  url: string
): Promise<string | null> => {
  const validRegexCheck = gitlabRepoRegexCheck(url);
  if (!validRegexCheck) {
    return "Invalid Gitlab repository URL. Ensure the form is https://gitlab.com/<username>/<repo>";
  }
  const publicityCheck = await checkPublicity(
    url,
    IFileType.GitlabRepoFileType
  );
  return publicityCheck ? publicityCheck : null;
};

export const sanitizeRepo = (url: string): string => {
  return url.endsWith(".git") ? url.slice(0, -4) : url;
};

export const isValidFileUrl = async (url: string): Promise<string | null> => {
  let errorMessage: string | null = null;

  if (url.includes(".ipynb")) {
    errorMessage = await isValidNotebookUrl(url);
    if (errorMessage) {
      return errorMessage;
    }
    return null;
  } else if (url.includes("colab")) {
    errorMessage = await isValidColabUrl(url);
    if (errorMessage) {
      return errorMessage;
    }
    return null;
  } else if (githubRepoRegexCheck(url)) {
    url = sanitizeRepo(url);
    errorMessage = await isValidGithubRepoUrl(url);
    if (errorMessage) {
      return errorMessage;
    }
    return null;
  } else if (gitlabRepoRegexCheck(url)) {
    url = sanitizeRepo(url);
    errorMessage = await isValidGitlabRepoUrl(url);
    if (errorMessage) {
      return errorMessage;
    }
    console.log("We are herere");
    return null;
  }
  return "Invalid File Format";
};

export const validateAndGetFileType = async (url: string) => {
  let errorMessage = await isValidGithubRepoUrl(url);
  if (!errorMessage) {
    return IFileType.GithubRepoFileType;
  }

  errorMessage = await isValidNotebookUrl(url);
  if (!errorMessage) {
    return IFileType.NotebookFileType;
  }

  errorMessage = await isValidColabUrl(url);
  if (!errorMessage) {
    return IFileType.ColabFileType;
  }

  return IFileType.NotebookFileType;
};

export function yamlRawDump(config: any): string {
  return dump(config, { lineWidth: -1 });
}

export const fetchOrgProfileImage = async (
  orgUrl: string
): Promise<string | null> => {
  if (!orgUrl.includes("github.com/")) return null;

  const parts = orgUrl.split("/");
  const githubIndex = parts.findIndex((part) => part === "github.com");
  const orgName = parts[githubIndex + 1];
  if (!orgName) return null;

  try {
    const response = await fetch(`https://api.github.com/orgs/${orgName}`);
    const data = await response.json();
    return data.avatar_url;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    return null;
  }
};

export async function fetchNotebookList() {
  const response = await fetch("/Local-Notebooks/files.json");
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export async function fetchLegacyNotebookList() {
  const response = await fetch("/Local-Notebooks/legacy_files.json");
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export const getImageUrl = async (
  notebookUrl: string,
  notebooks: any[],
  defaultImageUrl: string
): Promise<string> => {
  let nbUrlRaw = replaceRawWithBlob(notebookUrl);
  const notebook = notebooks.find((n) => n.url === nbUrlRaw);
  if (notebook && notebook.imageUrl) {
    return notebook.imageUrl;
  }

  const orgImageUrl = await fetchOrgProfileImage(notebookUrl);
  return orgImageUrl || defaultImageUrl;
};
export function sanitizeNotebookUrl(url: string): string {
  if (url.includes("trtmistral1.html")) {
    return "Mistral 7B with NVIDIA TensorRT-LLM";
  }

  if (url.includes("mistral_nemo_finetune.html")) {
    return "Finetune and deploy Mistral with NVIDIA NeMo";
  }

  if (url.includes("question_answer_nemo.html")) {
    return "Train Question/Answer models using NeMo";
  }

  if (url.includes("streamingllm-tensorrt-llm.html")) {
    return "Using the StreamingLLM framework with TensorRT-LLM";
  }

  if (url.includes("diffusion_lora_inf.html")) {
    return "Stable Diffusion XL inference using LoRA adaptors";
  }
  if (url.includes("dbrx_inference.html")) {
    return "Optimizing inference on DBRX using VLLM and Gradio";
  }
  if (url.includes("llama3_finetune_inference.html")) {
    return "Finetuning Llama3 using SFT";
  }
  if (url.includes("llava-finetune.html")) {
    return "Finetune and Deploy the multimodal LLaVA model";
  }
  if (url.includes("llama3-tensorrtllm-deployment.html")) {
    return "Deploy Llama3 with TensorRT-LLM";
  }
  if (url.includes("rapids_cudf_pandas.html")) {
    return "Using cuDF Pandas to accelerate Data Science Workflows";
  }
  if (url.includes("efficientvit-segmentation.html")) {
    return "Efficient Vision Transformers for Image Classification and Segmentation";
  }
  if (url.includes("llama3dpo.html")) {
    return "Finetune Llama3 using Direct Preference Optimization";
  }

  return url
    .split(".")
    .slice(0, -1)
    .join(".")
    .split("-")
    .map((word) => {
      // Ensure specific abbreviations are capitalized and Phi2 is spelled Phi-2
      const specialWords = ["UI", "GGUF", "OCR", "PDF", "PHI2"];
      if (specialWords.includes(word.toUpperCase())) {
        if (word.toUpperCase() === "PHI2") {
          return "Phi-2";
        }
        return word.toUpperCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
export function isSharedWorkspace(
  workspace: any,
  currentUserID: string
): boolean {
  return (
    workspace &&
    workspace.additionalUsers &&
    workspace.additionalUsers?.length > 0 &&
    !!workspace.additionalUsers.find((userId) => userId === currentUserID)
  );
}

export const isCheckHealthSame = (a: any[], b: any[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  if (a.length > 0 && b.length > 0) {
    return a[0].status === b[0].status;
  }
  return true;
};

export function areStringListsEqual(
  list1: string[] | null | undefined,
  list2: string[] | null | undefined
): boolean {
  if (list1 == null && list2 == null) {
    return true;
  }
  if (list1 == null || list2 == null) {
    return false;
  }
  if (list1.length !== list2.length) {
    return false;
  }
  for (let i = 0; i < list1.length; i++) {
    if (list1[i] !== list2[i]) {
      return false;
    }
  }
  return true;
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
