/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react";
import Workspace from "../../../../../../../entities/Workspace.entity";
import Button from "components/UI-lib/Button";
import Modal from "components/Modals/Modal";
import { IApplication } from "models/Workspace.model";
import Access from "./Access";
import DevToggle from "components/DevToggle";
import CreateKeys from "./CreateKeys";
// eactComponent  CopyIcon } from "assets/img/svg/copyIcon.svg?react";
import { classNames, fallbackCopyTextToClipboard } from "components/utils";
import { get } from "lodash";

const CopyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
    />
  </svg>
);

const OpenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={classNames("w-6 h-6", props.classNames)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
    />
  </svg>
);

interface ApplicationProps {
  isLoading: boolean;
  port: number;
  url: string;
  index: string;
  status: string;
  // editModal: any;
  application: IApplication;
  workspace: Workspace;
  onDelete: () => void;
}

const UrlRowItem: React.FC<ApplicationProps> = (props) => {
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateKeysModal, setShowCreateKeysModal] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  return (
    <>
      <Modal
        setOpen={setShowEditModal}
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSuccess={async () => {
          setShowEditModal(false);
        }}
        title={`Share Access`}
        body={
          <div className="w-[450px] pb-10">
            <Access
              application={props.application}
              workspaceId={props.workspace.id}
              applicationName={props.application.name}
            />
          </div>
        }
        confirmLabel="Done"
      />
      <Modal
        setOpen={setShowCreateKeysModal}
        isOpen={showCreateKeysModal}
        onClose={() => setShowCreateKeysModal(false)}
        onSuccess={async () => {
          setShowCreateKeysModal(false);
        }}
        title={`Manage API Key`}
        body={
          <div className="w-[450px] pb-10">
            <CreateKeys
              application={props.application}
              workspaceId={props.workspace.id}
              applicationName={props.application.name}
            />
          </div>
        }
        confirmLabel="Done"
      />
      <tr key={props.index}>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-secondary">
          {props.port}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-secondary ">
          <div className="flex flex-col relative">
            <div className="flex flex-row items-center group">
              <div
                className="text-gray-600 hover:text-cyan-600 dark:text-secondary dark:group-hover:text-slate-300 cursor-pointer"
                onClick={() => {
                  fallbackCopyTextToClipboard(`https://${props.url}`);
                  setShowCopied(true);
                  setTimeout(() => setShowCopied(false), 2000);
                }}
              >
                {`${props.url}`}
              </div>
              <div
                className="text-gray-600 hover:text-cyan-600 dark:text-secondary dark:group-hover:text-slate-300 cursor-pointer ml-2"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `https://${props.url}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <OpenIcon />
              </div>
            </div>

            {showCopied && (
              <p className="absolute top-[-1.5rem] z-10 text-highlight">
                copied!
              </p>
            )}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-secondary">
          {props.isLoading ? (
            <span
              key={props.status}
              className="inline-flex rounded-full bg-cyan-100 dark:bg-highlight px-2 text-xs font-semibold leading-5 text-cyan-800 dark:text-cyan-100"
            >
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white p-[3px]"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="#0891b2"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              Loading
            </span>
          ) : (
            getWorkspaceStatusChip(props.status)
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-secondary">
          <div className="flex justify-end items-center">
            <Button
              type="secondary"
              label="API Key"
              className="border-1 border-gray-200 h-10 mr-2"
              onClick={() => {
                setShowCreateKeysModal(true);
              }}
              disabled={false}
            />
            <Button
              type="secondary"
              label="Edit Access"
              className="border-1 border-gray-200 h-10 mr-2"
              onClick={() => {
                setShowEditModal(true);
              }}
              disabled={false}
            />
            <Button
              type="danger"
              label="Delete"
              className="border-1 border-gray-200 h-10"
              onClick={async () => {
                setDeleteButtonLoading(true);
                await props.onDelete();
                setDeleteButtonLoading(false);
              }}
              loading={deleteButtonLoading}
              disabled={false}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

// HEALTHY, UNHEALTHY, UNAVAILABLE
// "healthy", "unhealthy", "unknown"
const getWorkspaceStatusChip = (status: string) => {
  status = status.toLowerCase();
  switch (status) {
    case "healthy":
      return (
        <span
          key={status}
          className="inline-flex rounded-full bg-green-100 dark:bg-green-600 px-2 text-xs font-semibold leading-5 text-green-800 dark:text-green-200"
        >
          Healthy
        </span>
      );
    case "unhealthy":
      return (
        <span
          key={status}
          className="inline-flex rounded-full bg-yellow-100 dark:bg-yellow-800 px-2 text-xs font-semibold leading-5 text-yellow-800 dark:text-yellow-200"
        >
          Unhealthy
        </span>
      );
    default:
      return (
        <span
          key={status}
          className="inline-flex rounded-full bg-gray-100 dark:bg-gray-600 px-2 text-xs font-semibold leading-5 text-gray-800 dark:text-gray-200"
        >
          {status}
        </span>
      );
  }
};

export default UrlRowItem;
