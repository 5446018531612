import CopyIcon from "assets/img/svg/copyIcon.svg?react";
import DarkModeCopyIcon from "assets/img/svg/darkModeCopyIcon.svg?react";
import React, { useState, useContext } from "react";
import { classNames, fallbackCopyTextToClipboard } from "../../utils";
import "./styles.scss";
// import server
import agent from "../../../server";
import { DarkModeContext } from "contexts/DarkModeContext";

interface CopyFieldProps {
  value: string;
  displayValue?: string;
  password?: boolean;
  onClick?: () => void;
  style?: object;
  analytics: {
    userid: string;
    [key: string]: any;
    eventLabel: string;
  };
  className?: string;
  disabled?: boolean;
}

const CopyField: React.FC<CopyFieldProps> = ({
  value,
  displayValue,
  password = false,
  style,
  onClick,
  analytics,
  className = "",
  disabled,
}) => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    if (disabled) return;
    fallbackCopyTextToClipboard(value);
    setClicked(true);
    if (onClick) onClick();
    setTimeout(() => {
      setClicked(false);
    }, 1000);
    agent.Brevent.track({
      eventName: "Copied Text",
      userId: analytics.userid,
      properties: {
        text: value,
        ...analytics,
      },
    });
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      className={`${className} CopyField-CopyPasteWrapper dark:text-secondary dark:bg-secondaryBg dark:border-zinc-800 ${
        clicked ? "CopyField-TextFieldClicked" : ""
      }`}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
        className="CopyField-IconWrapper dark:text-secondary dark:bg-secondaryBg dark:border-zinc-800"
      >
        <DarkModeCopyIcon />
      </button>
      <div className="CopyField-Value">
        {clicked ? (
          <label className="CopyField-CopiedLabel">Copied to clipboard!</label>
        ) : (
          <>
            {password ? (
              <input
                value={value}
                type="password"
                className="CopyField-Password"
                disabled
              />
            ) : (
              <>{displayValue || value}</>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CopyField;

export const CopyFieldTailwind: React.FC<CopyFieldProps> = (props) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    fallbackCopyTextToClipboard(props.value);
    setClicked(true);
    if (props.onClick) props.onClick();
    setTimeout(() => {
      setClicked(false);
    }, 1000);
  };

  return (
    <div className="">
      <label
        htmlFor="url"
        className={classNames(
          "block text-sm font-medium text-cyan-600",
          clicked ? "" : "invisible"
        )}
      >
        Copied
      </label>
      <div
        className="relative mt-2 rounded-md shadow-sm cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
      >
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className={classNames(
              "w-6 h-6",
              clicked ? "text-cyan-600" : "text-gray-800"
            )}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
        </div>

        <input
          type="url"
          name="url"
          id="url"
          className={classNames(
            "block w-full rounded-md dark:border-zinc-800 dark:bg-zinc-900 dark:text-cyan-600 focus:border-highlight focus:ring-highlightLighter sm:text-sm py-1.5 pl-10 placeholder:text-gray-400 sm:text-sm sm:leading-6 cursor-pointer",
            clicked
              ? "text-cyan-600 border-highlight"
              : "text-gray-900 dark:bg-zinc-900 dark:text-cyan-600"
          )}
          value={props.displayValue || props.value}
        />
      </div>
    </div>
  );
};
