import Modal from "components/Modals/Modal";
import CloudMultiplex from "components/OrgPages/ConnectCloud/CloudMultiplex";
import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "components/UI-lib/Button";
import agent from "server";
import { OrgContext } from "contexts/OrgContext";
import SimpleTransition from "components/SimpleTransition";
import {
  AddressElement,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { UserContext } from "contexts/UserContext";
import { DarkModeContext } from "contexts/DarkModeContext";
import { ConnectCloudsOrCardModalProps } from ".";
import { WindupChildren, useWindupString } from "windups";
import InputField from "components/UI-lib/InputField";
import {
  classNames,
  integerValidationGreaterThan20OrEmpty,
  integerValidationOrEmpty,
  isEmoji,
  timeout,
} from "components/utils";
import { BillingContext } from "contexts/BillingContext";
import { next } from "windups/dist/Windup";
import FlatCard from "components/UI-lib/FlatCard";
import { Transition } from "@headlessui/react";
import Checkbox from "components/UI-lib/Checkbox";

import LightModeLogo from "assets/img/svg/logo_light_mode.svg?react";
import DarkModeLogo from "assets/img/svg/logo_dark_mode.svg?react";
import DynamicBadge from "components/UI-lib/DynamicBadge";
import { useHistory } from "react-router";
import { BanknotesIcon } from "@heroicons/react/24/solid";

export interface AddCreditsModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
  setNoCardOnFile: (isFree: boolean) => void;
  noCardOnFile?: boolean;
  label?: string;
}

export const AddCreditsModal: React.FC<AddCreditsModalProps> = ({
  show,
  setShow,
  onCancel = () => {},
  onSuccess = () => {},
  noCardOnFile = false,
  setNoCardOnFile,
  label = "Add credits to spin up your instance",
}) => {
  const billingContext = useContext(BillingContext);
  const [clientSecret, setClientSecret] = useState("");
  const [oneTimeTopUp, setOneTimeTopUp] = useState("20");
  const [autoRechargeEnabled, setAutoRechargeEnabled] = useState(false);
  const orgContext = useContext(OrgContext);
  const [view, setView] = useState<
    "AddCreditsFrame" | "CreditCardFrame" | "FinalFrame"
  >("AddCreditsFrame");
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    switch (frame) {
      case 0:
        setView("AddCreditsFrame");
        break;
      case 1:
        setView("CreditCardFrame");
        break;
      case 2:
        setView("FinalFrame");
        break;
    }
  }, [frame]);

  useEffect(() => {
    const loadClientSecret = async () => {
      const res = await agent.Billing.getClientSecret(orgContext.activeOrgId);
      if (res.success && res.data) {
        setClientSecret(res.data.clientSecret);
      }
    };
    loadClientSecret();
  }, [orgContext.activeOrgId]);

  useEffect(() => {
    if (!show) {
      setFrame(0);
    }
  }, [show]);

  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => {
        onCancel();
        setShow(false);
      }}
      title={view === "FinalFrame" ? "" : label}
      body={
        <>
          <div className="w-[450px] ">
            <Transition
              show={
                view === "AddCreditsFrame" ||
                view === "CreditCardFrame" ||
                view === "FinalFrame"
              }
              enter="transition-opacity duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {view === "AddCreditsFrame" && (
                <AddCreditsFrame
                  setShow={setShow}
                  onCancel={onCancel}
                  onSuccess={onSuccess}
                  setNoCardOnFile={setNoCardOnFile}
                  noCardOnFile={noCardOnFile}
                  oneTimeTopUp={oneTimeTopUp}
                  setOneTimeTopUp={setOneTimeTopUp}
                  autoRecharge={autoRechargeEnabled}
                  setAutoRecharge={setAutoRechargeEnabled}
                  back={() => {
                    onCancel();
                    setShow(false);
                  }}
                  next={(frame: number = 1) => {
                    setFrame(frame);
                  }}
                />
              )}
              {view === "CreditCardFrame" && (
                <CreditCardFrame
                  clientSecret={clientSecret}
                  setShow={setShow}
                  onCancel={onCancel}
                  onSuccess={onSuccess}
                  oneTimeTopUp={oneTimeTopUp}
                  autoRecharge={autoRechargeEnabled}
                  setNoCardOnFile={setNoCardOnFile}
                  back={() => {
                    setFrame(0);
                  }}
                  next={(frame: number = 2) => {
                    setFrame(frame);
                  }}
                />
              )}
              {view === "FinalFrame" && (
                <FinalFrame setShow={setShow} onSuccess={onSuccess} />
              )}
            </Transition>
          </div>
        </>
      }
      cancelLabel="Cancel"
      hideCancel={true}
      confirmLabel={
        frame === 0 ? "Add Credits" : frame === 2 ? "Finish" : "Close"
      }
      hideSuccess={true}
    />
  );
};

const AddCreditsFrameParagraph1 = () => {
  const [text] = useWindupString("Add credits to your account to continue");
  return <p className="text-sm text-gray-500 mb-2">{text}</p>;
};

interface AddCreditsFrameProps {
  setShow: (show: boolean) => void;
  onCancel: () => void;
  onSuccess: () => void;
  setNoCardOnFile: (isFree: boolean) => void;
  noCardOnFile: boolean;
  oneTimeTopUp: string;
  setOneTimeTopUp: (value: string) => void;
  autoRecharge: boolean;
  setAutoRecharge: (value: boolean) => void;
  back: () => void;
  next: (frame?: number) => void;
}

const AddCreditsFrame: React.FC<AddCreditsFrameProps> = (props) => {
  const billingContext = useContext(BillingContext);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [showCustomField, setShowCustomField] = useState(false);
  const [loading, setLoading] = useState(false);
  const addFunds = async () => {
    const { status, message } = await billingContext.addFunds(
      props.oneTimeTopUp
    );
    setInlineAlertMessage(message);
    setInlineAlertSeverity(status);
    if (status === "success") {
      // handle success
      props.setOneTimeTopUp("");
    } else {
      // handle error
    }
    return status;
  };

  const quickBuyIfCardConnected = async () => {
    setLoading(true);
    let status = await addFunds();
    if (status === "error") {
      setLoading(false);
      return [status, "add-funds"];
    }
    if (props.autoRecharge) {
      status = await handleToggleTopUpChange();
      if (status === "error") {
        setLoading(false);
        return [status, "auto-charge"];
      }
    }
    setLoading(false);
    return [status, ""];
  };

  const handleToggleTopUpChange = async () => {
    const { status, message } = await billingContext.handleToggleTopUpChange(
      "20"
    );
    setInlineAlertMessage(message);
    setInlineAlertSeverity(status);
    return status;
  };

  const getErrorMessage = () => {
    if (parseInt(props.oneTimeTopUp) < 20) {
      return "Input must be greater or equal to $20";
    } else if (isNaN(parseInt(props.oneTimeTopUp))) {
      return "Input must be a number";
    } else {
      return "";
    }
  };

  return (
    <SimpleTransition show={true}>
      <InlineNotification
        className="mb-2"
        show={!!inlineAlertMessage}
        severity={inlineAlertSeverity || "info"}
        text={inlineAlertMessage}
        autoClose={false}
        onClose={() => setInlineAlertMessage("")}
      />
      <AddCreditsFrameParagraph1 />
      <div className="flex flex-row w-full mb-2">
        {["20", "40", "100"].map((amount) => (
          <DynamicBadge
            text={`$${amount}`}
            isActive={props.oneTimeTopUp === amount && !showCustomField}
            setIsActive={() => {
              props.setOneTimeTopUp(amount);
              setShowCustomField(false);
            }}
          />
        ))}
        <DynamicBadge
          text="Custom"
          isActive={showCustomField}
          setIsActive={() => setShowCustomField(true)}
        />
      </div>
      {showCustomField && (
        <div className="flex flex-row justify-start items-end">
          <InputField
            validation={integerValidationOrEmpty}
            label="Quantity $"
            value={props.oneTimeTopUp}
            placeholder={props.oneTimeTopUp}
            onChange={(v) => props.setOneTimeTopUp(v)}
            errorMessage={getErrorMessage()}
          />
        </div>
      )}
      <div className="flex flex-col items-start justify-start my-2">
        <Checkbox
          label="Automatically reload your account when you're low on credits"
          className="ml-1"
          labelClassName="text-sm text-gray-500 dark:text-gray-500"
          checked={props.autoRecharge}
          onChange={(v) => props.setAutoRecharge(v)}
        />
        {props.autoRecharge && <AutoTopUpDetails />}
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          className="ml-3"
          label={
            !props.noCardOnFile && billingContext.currentBalance <= 0
              ? "Add Credits"
              : "Next"
          }
          type="primary"
          onClick={async () => {
            if (!props.noCardOnFile && billingContext.currentBalance <= 0) {
              const [status, type] = await quickBuyIfCardConnected();
              if (status !== "success") {
                if (type === "add-funds") {
                  setInlineAlertMessage(
                    "Unable to add funds, go to the billing tab and check the status of your credit card"
                  );
                  setInlineAlertSeverity("error");
                  return;
                } else if (type === "auto-charge") {
                  // setInlineAlertMessage(
                  //   "Added funds but unable to set up auto-recharge"
                  // );
                  // setInlineAlertSeverity("error");
                  props.next(2);
                }
                return;
              }
              props.next(2);
              return;
            } else {
              props.next(1);
            }
          }}
          loading={loading}
        />
        <Button
          label={"Cancel"}
          type="secondary"
          onClick={() => {
            props.back();
          }}
        />
      </div>
    </SimpleTransition>
  );
};

const AutoTopUpDetails = () => {
  const [text] = useWindupString(
    "We'll charge $20 automatically when your balance gets below $10"
  );
  return <p className="text-sm text-gray-500 mb-2 mt-2">{text}</p>;
};

const CreditCardFrameParagraph1 = ({ back }) => {
  const [text] = useWindupString("Connect a credit card to your account. ");

  return (
    <div>
      <p className="text-sm text-gray-500 mb-2">{text}</p>
    </div>
  );
};

interface CreditCardFrameProps {
  clientSecret: string;
  setShow: (show: boolean) => void;
  onCancel: () => void;
  onSuccess: () => void;
  setNoCardOnFile: (isFree: boolean) => void;
  oneTimeTopUp: string;
  autoRecharge: boolean;
  back: () => void;
  next: (frame?: number) => void;
}

const CreditCardFrame: React.FC<CreditCardFrameProps> = ({
  clientSecret,
  ...props
}) => {
  const userContext = useContext(UserContext);
  const orgContext = useContext(OrgContext);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const { darkMode } = useContext(DarkModeContext);
  const [cardLoading, setCardLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [isCardBeingSaved, setIsCardBeingSaved] = useState(false);
  const [isCardSaved, setIsCardSaved] = useState(false);
  const [haveWeChargedCredits, sethaveWeChargedCredits] = useState(false);
  const billingContext = useContext(BillingContext);

  const handleSubmitCreditCard = async () => {
    setCardLoading(true);
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      setCardLoading(false);
      return false;
    }
    if (!clientSecret) {
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        "Unable to connect to stripe. Try reloading, and if that doesn't work, contact support"
      );
      setCardLoading(false);
      setIsCardBeingSaved(false);
      return false;
    }

    const cardElement = elements.getElement(CardElement);
    const addressElement = elements.getElement(AddressElement);
    if (!cardElement || !addressElement) {
      setCardLoading(false);
      return false;
    }

    const { complete, value: addressValue } = await addressElement.getValue();
    if (addressValue.name === "" || addressValue.address.line1 === "") {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("Please enter a valid address");
      setCardLoading(false);
      return false;
    }

    const res = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        metadata: {
          userId: userContext.me?.id || "",
          orgId: orgContext.activeOrgId,
        },
        billing_details: {
          address: {
            line1: addressValue.address.line1,
            // line2: value.address.line2,
            city: addressValue.address.city,
            state: addressValue.address.state,
            postal_code: addressValue.address.postal_code,
            country: addressValue.address.country,
          },
        },
      },
    });

    if (res.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.error(res.error.message);
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        "Unable to submit credit card details. Please try again or contact support"
      );
      agent.Brevent.track({
        eventName: "Card Details Failed To Capture",
        userId: userContext.me?.id,
        properties: {
          orgId: orgContext.activeOrgId,
          error: res.error.message,
        },
      });
      return false;
    }
    setIsCardSaved(true);
    agent.Brevent.track({
      eventName: "Added Card",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
      },
    });
    props.setNoCardOnFile(false);
    cardElement.clear();
    setCardLoading(false);
    setIsCardBeingSaved(false);
    return true;
  };

  const handleSubmitCreditCardAndAddFunds = async () => {
    const status = await handleSubmitCreditCard();
    if (status) {
      await timeout(200);
      await chargeCredits_MakeRecurring();
      sethaveWeChargedCredits(true);
      props.next();
    }
  };

  const addFunds = async () => {
    const { status, message } = await billingContext.addFunds(
      props.oneTimeTopUp
    );
    setInlineAlertMessage(message);
    setInlineAlertSeverity(status);
  };

  const handleToggleTopUpChange = async () => {
    const { status, message } = await billingContext.handleToggleTopUpChange(
      "20"
    );
    setInlineAlertMessage(message);
    setInlineAlertSeverity(status);
  };

  const chargeCredits_MakeRecurring = async () => {
    // One time top up
    await addFunds();
    if (props.autoRecharge) {
      // Set recurring if user wants it
      await handleToggleTopUpChange();
    }
  };

  return (
    <>
      <CreditCardFrameParagraph1 back={props.back} />

      <InlineNotification
        className="mb-2"
        show={!!inlineAlertMessage}
        severity={inlineAlertSeverity || "info"}
        text={inlineAlertMessage}
        autoClose={false}
        onClose={() => setInlineAlertMessage("")}
      />
      <SimpleTransition show={true}>
        <div className="flex flex-row w-full">
          {!isCardSaved ? (
            !isCardBeingSaved ? (
              <div className="flex flex-row w-full">
                <div className="w-[90%]">
                  <CardElement
                    options={{
                      style: {
                        base: {
                          color: darkMode ? "#cffafe" : "black",
                        },
                      },
                      classes: {
                        base: "rounded-md border-gray-300 dark:border-zinc-800 shadow-sm p-3 sm:text-sm stripe-border",
                        focus: "border-highlight ring-cyan-500",
                      },
                    }}
                  />
                  <AddressElement
                    className="mt-4"
                    options={{
                      mode: "billing",
                      fields: {},
                      display: {},
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="w-full">
                <FlatCard className="" isLoading={true}>
                  <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono">
                    Saving Card Details
                  </p>
                </FlatCard>
              </div>
            )
          ) : (
            <div className="w-full">
              <FlatCard className="" isLoading={false}>
                <div className="flex flex-row justify-start items-center">
                  <CheckmarkIcon />
                  <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono ml-2">
                    Card Details Saved
                  </p>
                </div>
              </FlatCard>
            </div>
          )}
        </div>
        <SimpleTransition show={isCardSaved}>
          <div>
            <FlatCard className="mt-5" isLoading={!haveWeChargedCredits}>
              <div className="flex flex-row justify-start items-center">
                {haveWeChargedCredits && <CheckmarkIcon />}
                <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono ml-2">
                  Adding Credits To Your Account
                </p>
              </div>
            </FlatCard>
          </div>
        </SimpleTransition>
      </SimpleTransition>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        {!isCardBeingSaved && (
          <Button
            className="ml-3"
            label="Save"
            type="primary"
            disabled={cardLoading}
            loading={cardLoading}
            onClick={() => {
              handleSubmitCreditCardAndAddFunds();
            }}
          />
        )}
        <Button
          label={"Back"}
          type="secondary"
          onClick={() => {
            props.back();
          }}
        />
      </div>
    </>
  );
};

const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16" // Keep the original viewBox
    width="16" // Keep the original width
    height="16" // Keep the original height
  >
    <rect
      width="16"
      height="16"
      rx="3" // Adjust the corner radius if needed
      fill="currentColor"
      className="text-highlight"
    />
    <path
      fillRule="evenodd"
      d="M11.78 5.22a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06 0L4.22 8.28a.75.75 0 111.06-1.06l1.75 1.75 3.72-3.72a.75.75 0 011.06 0z"
      fill="#fff" // White checkmark
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckmarkIcon;

interface FinalFrameProps {
  setShow: (show: boolean) => void;
  onSuccess: () => void;
}
const FinalFrame: React.FC<FinalFrameProps> = (props) => {
  const orgContext = useContext(OrgContext);
  const [text] = useWindupString("Credits added successfully!");
  const history = useHistory();
  return (
    <>
      <div className="flex flex-row items-center">
        <BanknotesIcon className="h-8 w-8 text-white" />
        <span className="ml-3 text-white">{text}</span>
      </div>
      <span className="text-sm text-primary mt-2">
        You can view your credits, cards on file, and more in the
        <span
          className="ml-1 mr-1 underline cursor-pointer"
          onClick={() => {
            history.push(`/org/${orgContext.activeOrgId}/billing`);
          }}
        >
          billing
        </span>
        tab
      </span>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          label={"Close"}
          type="primary"
          onClick={() => {
            props.onSuccess();
            props.setShow(false);
          }}
        />
      </div>
    </>
  );
};
