import IPermission from "./Permission.model";

export enum VerificationStatus {
  Verified = "Verified",
  Unverified = "UnVerified",
}

export enum GlobalUserType {
  Admin = "Admin",
  Standard = "Standard",
}

export interface VSCodeExtensionMetadata {
  name: string;
  displayName: string;
  version: string;
  publisher: string;
  description: string;
  repository: string;
}

export interface VsCode {
  extensions: VSCodeExtensionMetadata[];
}

export interface IdeConfig {
  defaultWorkingDir?: string; // absolute path
  vscode?: VsCode;
}

export default interface IUser {
  id: string;
  username: string;
  name: string;
  email: string;
  externalAuthId: string;
  roleAttachments: IPermission[];
  workspacePassword: string;
  publicKey?: string;
  baseWorkspaceRepo?: string;
  userSetupExecPath?: string;
  verificationStatus?: VerificationStatus;
  // billingStatus: boolean;
  globalUserType?: GlobalUserType;
  onboardingData: UserOnboardingStatus;
  apiKeys?: APIKeys;
  ideConfig?: IdeConfig;
  isBlocked?: boolean;
}

export interface UserOnboardingStatus {
  editor?: string;
  SSH?: boolean;
  usedCLI?: boolean;
  attemptedToConnectCloud?: boolean;
  whyBrev?: string;
  templateChosen?: string;
  caltechOffer?: string;
  inviteAccepted?: string;
  instacart?: string;
  visitedWelcome?: string[];
  hasBeenTypedOnboardingsTo?: boolean;
  // Onboardings are the blog post icons Onboardings.tsx
  closedOnboardings?: boolean;
  clickedOnboarding?: string[];
  isTermsAccepted?: boolean;
  showedNvidiaWelcome?: boolean;
}

export interface IUserMinimal {
  id: string;
  username: string;
  email: string;
}

export interface ILoginToken {
  access_token: string;
  refresh_token: string;
}

export interface APIKeys {
  ngc: string;
  huggingFace: string;
}
