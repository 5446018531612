import React, { Fragment, useContext, useEffect, useState } from "react";
import Spinner from "components/UI-lib/Spinner";
import agent from "server";
import IWorkspace from "models/Workspace.model";

const WorkspaceStopper: React.FC<{
  workspace: IWorkspace;
  onStatusChange: (status: "idle" | "stopping" | "stopped" | "error") => void;
}> = ({ workspace, onStatusChange }) => {
  const [status, setStatus] = useState<
    "idle" | "stopping" | "stopped" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const stopWorkspace = async () => {
    setStatus("stopping");
    try {
      const response = await agent.Workspaces.stop(workspace.id);
      if (response.success) {
        setStatus("stopped");
      } else {
        setStatus("error");
        setErrorMessage(response.message || "Failed to stop instance.");
      }
    } catch (error) {
      setStatus("error");
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("Failed to stop instance.");
      }
    }
  };

  useEffect(() => {
    stopWorkspace();
  }, []);

  useEffect(() => {
    onStatusChange(status);
  }, [status]);

  return (
    <div>
      {status === "stopping" && (
        <>
          <div className="flex flex-row items-center justify-start">
            <Spinner type={"secondary"} />
            <p className="ml-2">Stopping instance {workspace.id}</p>
          </div>
        </>
      )}{" "}
      {status === "stopped" && `✅ stopped ${workspace.id}`}
      {status === "error" && (
        <div>
          <p>❌ error: {errorMessage}</p>
          <button onClick={stopWorkspace}>Retry</button>
        </div>
      )}
    </div>
  );
};

export default WorkspaceStopper;

interface WorkspacesProps {
  workspaces: IWorkspace[];
}

export const WorkspacesGetStopped: React.FC<WorkspacesProps> = ({
  workspaces: initialWorkspaces,
}) => {
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>(initialWorkspaces);
  const [stoppingWorkspaces, setStoppingWorkspaces] = useState<IWorkspace[]>(
    []
  );
  const [workspaceStatuses, setWorkspaceStatuses] = useState<
    Record<string, "idle" | "stopping" | "stopped" | "error">
  >({});

  useEffect(() => {
    setWorkspaces(initialWorkspaces);
    setStoppingWorkspaces([]);
  }, [initialWorkspaces]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        workspaces.length > 0 &&
        stoppingWorkspaces.every(
          (workspace) =>
            workspace && workspaceStatuses[workspace.id] !== "stopping"
        )
      ) {
        const newStoppingWorkspaces = workspaces.slice(0, 5);
        setStoppingWorkspaces((prev) => [...prev, ...newStoppingWorkspaces]);
        setWorkspaces((prev) => prev.slice(newStoppingWorkspaces.length));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [workspaces, stoppingWorkspaces, workspaceStatuses]);

  const handleStatusChange = (
    id: string,
    status: "idle" | "stopping" | "stopped" | "error"
  ) => {
    setWorkspaceStatuses((prev) => ({ ...prev, [id]: status }));
  };

  return (
    <>
      {stoppingWorkspaces.map(
        (workspace, index) =>
          workspace && (
            <WorkspaceStopper
              key={index}
              workspace={workspace}
              onStatusChange={(status) =>
                handleStatusChange(workspace.id, status)
              }
            />
          )
      )}
      {workspaces.map((workspace, index) => (
        <div
          key={index}
          onClick={() => setStoppingWorkspaces((prev) => [...prev, workspace])}
        >
          Queued for stopping: {workspace.id}
        </div>
      ))}
    </>
  );
};
