import { UserUpdateReqBody } from "server";

export const createDefaultAnalyticsObject = (
  me: any,
  analyticsUpdate: any
): UserUpdateReqBody => ({
  username: me?.username || "",
  name: me?.name || "",
  email: me?.email || "",
  onboardingData: analyticsUpdate,
});
