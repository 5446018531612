import { isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";

const Config = t.type({
  stage: t.string,
  version: t.union([t.string, t.undefined]),
  workspaceGroupID: t.string,
  ubuntuTemplateID: t.string, // TODO pull from API
  webstormTemplateID: t.string, // TODO pull from API
  intellijCommunityTemplateID: t.string, // TODO pull from API
  pycharmCommunityTemplateID: t.string, // TODO pull from API
  intellijUltimateTemplateID: t.string, // TODO pull from API
  pycharmProfessionalTemplateID: t.string, // TODO pull from API
  clionTemplateID: t.string, // TODO pull from API
  dataGripTemplateID: t.string, // TODO pull from API
  mpsTemplateID: t.string, // TODO pull from API
  riderTemplateID: t.string, // TODO pull from API
  phpstormTemplateID: t.string, // TODO pull from API
  golandTemplateID: t.string, // TODO pull from API
  rubymineTemplateID: t.string, // TODO pull from API
  brevApiUrl: t.string,
  sentryDsn: t.string,
  auth0Domain: t.string,
  auth0ClientId: t.string,
  auth0Audience: t.string,
  stripePublishableKey: t.string,
  githubOauthClientID: t.string,
  githubOauthRedirectRoute: t.string,
  githubOauthScope: t.string,
  githubOauthAuthorizationEndpoint: t.string,
});

const configRes = Config.decode({
  stage: process.env.VITE_STAGE || "local",
  version: process.env.VITE_VERSION || "",
  workspaceGroupID: process.env.VITE_WORKSPACE_GROUP_ID || "",
  stripePublishableKey: process.env.VITE_STRIPE_PUBLISHABLE_KEY || "",
  ubuntuTemplateID: process.env.VITE_UBUNTU_TEMPLATE_ID || "",
  webstormTemplateID: process.env.VITE_UBUNTU_WEBSTORM_TEMPLATE_ID || "",
  intellijCommunityTemplateID:
    process.env.VITE_UBUNTU_INTELLIJCOMMUNITY_TEMPLATE_ID || "",
  pycharmCommunityTemplateID:
    process.env.VITE_UBUNTU_PYCHARMCOMMUNITY_TEMPLATE_ID || "",
  intellijUltimateTemplateID:
    process.env.VITE_UBUNTU_INTELLIJULTIMATE_TEMPLATE_ID || "",
  pycharmProfessionalTemplateID:
    process.env.VITE_UBUNTU_PYCHARMPROFESSIONAL_TEMPLATE_ID || "",
  clionTemplateID: process.env.VITE_UBUNTU_CLION_TEMPLATE_ID || "",
  dataGripTemplateID: process.env.VITE_UBUNTU_DATAGRIP_TEMPLATE_ID || "",
  mpsTemplateID: process.env.VITE_UBUNTU_MPS_TEMPLATE_ID || "",
  riderTemplateID: process.env.VITE_UBUNTU_RIDER_TEMPLATE_ID || "",
  phpstormTemplateID: process.env.VITE_UBUNTU_PHPSTORM_TEMPLATE_ID || "",
  golandTemplateID: process.env.VITE_UBUNTU_GOLAND_TEMPLATE_ID || "",
  rubymineTemplateID: process.env.VITE_UBUNTU_RUBYMINE_TEMPLATE_ID || "",
  brevApiUrl: process.env.VITE_BREV_API_DOMAIN || "",
  sentryDsn: process.env.VITE_SENTRY_DSN || "",
  auth0Domain: process.env.VITE_AUTH0_DOMAIN || "",
  auth0ClientId: process.env.VITE_AUTH0_CLIENT_ID || "",
  auth0Audience: process.env.VITE_AUTH0_AUDIENCE || "",
  githubOauthClientID: process.env.VITE_GITHUB_OAUTH_CLIENT_ID || "",
  githubOauthRedirectRoute: process.env.VITE_GITHUB_OAUTH_REDIRECT_ROUTE || "",
  githubOauthScope: process.env.VITE_GITHUB_OAUTH_SCOPE || "",
  githubOauthAuthorizationEndpoint:
    process.env.VITE_GITHUB_AUTHORIZATION_ENDPOINT || "",
});

if (!isRight(configRes)) {
  const res = PathReporter.report(configRes);
  throw Error(res.join("\n"));
}

export default configRes.right;
