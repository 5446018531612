import { useQuery } from "@tanstack/react-query";
import { prependKasUrl, KAS_HOST } from "../utils";

type UserRole = {
  orgRoles: string[];
  org: Org;
};

type Org = {
  idpId: string;
  id: number;
  alternateContact: Record<string, unknown>;
  infinityManagerSettings: Record<string, unknown>;
  type: string;
  isProto: boolean;
  repoScanSettings: Record<string, unknown>;
  description: string;
  name: string;
  displayName: string;
  isSeparateInfluxDbUsed: boolean;
};

type User = {
  hasSignedContainerPublishingEULA: boolean;
  hasSignedLlmEULA: boolean;
  hasSignedBaseCommandEULA: boolean;
  hasSignedEgxEULA: boolean;
  hasBetaAccess: boolean;
  id: number;
  isDeleted: boolean;
  hasSignedAiFoundryPartnershipsEULA: boolean;
  hasSignedEarth2EULA: boolean;
  hasSignedNvidiaEULA: boolean;
  updatedDate: string;
  firstLoginDate: string;
  email: string;
  hasSignedBioNeMoEULA: boolean;
  hasSignedPrivacyPolicy: boolean;
  hasSignedEULA: boolean;
  isSAML: boolean;
  lastLoginDate: string;
  createdDate: string;
  starfleetId: string;
  hasSignedNVQCEULA: boolean;
  isActive: boolean;
  roles: UserRole[];
  hasSubscribedToEmail: boolean;
  hasSignedCuOptEULA: boolean;
  clientId: string;
  hasSignedOmniverseEULA: boolean;
  idpType: string;
  hasProfile: boolean;
  hasSignedFleetCommandEULA: boolean;
  hasSignedNVAIEEULA: boolean;
  hasSignedThirdPartyRegistryShareEULA: boolean;
};

type UserResponse = {
  userRoles: UserRole[];
  requestStatus: {
    statusCode: string;
  };
  user: User;
};

export const useGetMe = ({ isKasAuthFlow }: { isKasAuthFlow: boolean }) => {
  const queryKey = "/v2/users/me";
  console.log("hello");
  return useQuery({
    queryFn: async (): Promise<UserResponse> =>
      fetch(prependKasUrl(queryKey), {
        headers: { host: KAS_HOST },
        credentials: "include",
        method: "GET",
      }).then(async (response) => await response.json()),
    queryKey: [queryKey],
    refetchInterval: false,
    enabled: isKasAuthFlow,
  });
};
