import React from "react";
import { VerbBuildStatus, WorkspaceStatus } from "models/Workspace.model";
import { getWorkspaceStatusChip } from "../List";
import { classNames } from "components/utils";

const InstanceStatusIndicator: React.FC<{
  workspaceStatus: WorkspaceStatus | VerbBuildStatus;
}> = ({ workspaceStatus }) => {
  const envStatusLight = GetEnvStatusLight(workspaceStatus);

  const statusStyles = {
    [VerbBuildStatus.Building]:
      "bg-cyan-100 dark:bg-cyan-600 text-cyan-800 dark:text-cyan-100",
    [VerbBuildStatus.Pending]:
      "bg-cyan-100 dark:bg-cyan-600 text-cyan-800 dark:text-cyan-100",
    [VerbBuildStatus.CreateFailed]:
      "bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-200",
    [VerbBuildStatus.Completed]:
      "bg-green-100 dark:bg-green-600 text-green-800 dark:text-green-200",
    [WorkspaceStatus.Running]:
      "bg-green-100 dark:bg-green-600 text-green-800 dark:text-green-200",
    [WorkspaceStatus.Deploying]:
      "bg-cyan-100 dark:bg-cyan-600 text-cyan-800 dark:text-cyan-100",
    [WorkspaceStatus.Stopped]:
      "bg-gray-100 dark:bg-gray-600 text-gray-800 dark:text-gray-200",
    [WorkspaceStatus.Starting]:
      "bg-cyan-100 dark:bg-cyan-600 text-cyan-800 dark:text-cyan-100",
    [WorkspaceStatus.Stopping]:
      "bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-200",
    default:
      "bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-200",
  };

  const statusText = {
    [WorkspaceStatus.Running]: "Running",
    [WorkspaceStatus.Deploying]: (
      <>
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white p-[3px]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="#0891b2"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        Deploying
      </>
    ),
    [WorkspaceStatus.Stopped]: "Stopped",
    [WorkspaceStatus.Starting]: (
      <>
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white p-[3px]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="#0891b2"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        Starting
      </>
    ),
    [WorkspaceStatus.Stopping]: "Stopping",
    [VerbBuildStatus.Building]: "Building",
    [VerbBuildStatus.Pending]: "Waiting",
    [VerbBuildStatus.CreateFailed]: "Failed",
    [VerbBuildStatus.Completed]: "Built",
    default: workspaceStatus,
  };

  const className = `inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
    statusStyles[workspaceStatus] || statusStyles.default
  }`;

  return (
    <span key={workspaceStatus} className={className}>
      <div className="flex items-center">
        <div className="mr-1">{envStatusLight}</div>
        {statusText[workspaceStatus] || statusText.default}
      </div>
    </span>
  );
};

export default InstanceStatusIndicator;

export const GetEnvStatusLight = (
  workspaceStatus: WorkspaceStatus | VerbBuildStatus
) => {
  if (
    workspaceStatus === WorkspaceStatus.Running ||
    workspaceStatus === VerbBuildStatus.Completed
  ) {
    return (
      <span
        className={classNames(
          "bg-green-100 dark:bg-green-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-green-400 dark:bg-green-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (
    workspaceStatus === VerbBuildStatus.Pending ||
    workspaceStatus === VerbBuildStatus.Building
  ) {
    return (
      <span
        className={classNames(
          "bg-cyan-100 dark:bg-cyan-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-cyan-400 dark:bg-cyan-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (
    workspaceStatus === WorkspaceStatus.Stopped ||
    workspaceStatus === VerbBuildStatus.Unset
  ) {
    return (
      <span
        className={classNames(
          "bg-slate-100 dark:bg-slate-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-slate-400 dark:bg-slate-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (
    workspaceStatus === WorkspaceStatus.Starting ||
    workspaceStatus === WorkspaceStatus.Deploying
  ) {
    return <></>;
  }
  if (
    workspaceStatus === WorkspaceStatus.Failure ||
    workspaceStatus === VerbBuildStatus.CreateFailed
  ) {
    return (
      <span
        className={classNames(
          "bg-red-100 dark:bg-red-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-red-400 dark:bg-red-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Stopping) {
    return <></>;
  }
  return (
    <span
      className={classNames(
        "bg-slate-100 dark:bg-slate-800",
        "h-4 w-4 rounded-full flex items-center justify-center"
      )}
      aria-hidden="true"
    >
      <span
        className={classNames(
          "bg-slate-400 dark:bg-slate-500",
          "h-2 w-2 rounded-full"
        )}
      />
    </span>
  );
};
