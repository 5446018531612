/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from "react";
import InputField from "components/UI-lib/InputField";
import Button from "components/UI-lib/Button";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { UserIcon } from "@heroicons/react/24/solid";
import { UserContext } from "contexts/UserContext";
import {
  AddUserToApplicationRequest,
  Applications,
  RemoveUserFromApplicationRequest,
} from "server/applications";
import agent from "server";
import { IApplication } from "models/Workspace.model";
import { set } from "lodash";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { OrgContext } from "contexts/OrgContext";
import Checkbox from "components/UI-lib/Checkbox";
import DevToggle from "components/DevToggle";
import Toggle from "components/UI-lib/Toggle";
import Spinner from "components/UI-lib/Spinner";

export interface AccessProps {
  workspaceId: string;
  applicationName: string;
  application: IApplication;
  label?: string;
}
const Access: React.FC<AccessProps> = (props) => {
  const wsContext = useContext(WorkspaceContext);
  const orgContext = useContext(OrgContext);
  const [shareLoading, setShareLoading] = useState(false);
  const [publicLoading, setPublicLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [makePublic, clickedMakePublic] = useState(
    props.application.policy.allowEveryone
  );
  const [currentDeletionIndex, setCurrentDeletionIndex] = useState(-1);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  const { me } = useContext(UserContext);
  const [authUserList, setAuthUserList] = useState<string[]>(
    props.application.policy.allowedUserAuthIDs
  );

  useEffect(() => {
    clickedMakePublic(props.application.policy.allowEveryone);
  }, [props.application.policy.allowEveryone]);

  const handleAddEmail = async () => {
    if (!email) {
      setInlineAlertMessage("  ");
      // added the timeout so if someone spams the button, the ui is still responsive
      setTimeout(() => {
        setInlineAlertMessage("Please enter an email or username.");
        setInlineAlertSeverity("error");
      }, 125);
      return;
    }

    const args: AddUserToApplicationRequest = {
      usernameOrEmail: email,
      applicationName: props.applicationName,
    };
    try {
      setShareLoading(true);
      const response = await Applications.addUser(props.workspaceId, args);
      if (response.success) {
        setInlineAlertMessage(`User ${email} added successfully.`);
        setInlineAlertSeverity("success");

        if (response.data) {
          for (let i = 0; i < response.data.applications.length; i++) {
            const app = response.data.applications[i];

            if (
              app.cloudflareApplicationID ===
              props.application.cloudflareApplicationID
            ) {
              // update the authUserList
              setAuthUserList([...app.policy.allowedUserAuthIDs]);
              break;
            }
          }
        }

        // BANANA: force a refresh
        wsContext.reloadWorkspaces(orgContext.activeOrgId);

        setShareLoading(false);
        setEmail("");
      } else {
        setInlineAlertMessage(`Error: ${response.message}`);
        setInlineAlertSeverity("error");
        setShareLoading(false);
      }
    } catch (error) {
      // error - show error notification
      setInlineAlertMessage("Failed to add user.");
      setInlineAlertSeverity("error");
      setShareLoading(false);
    }
    setShareLoading(false);
  };

  const handleRemoveUser = async (index, userAuthID) => {
    setDeleteLoading(true);
    setCurrentDeletionIndex(index);

    const args: RemoveUserFromApplicationRequest = {
      userAuthID: userAuthID, // This needs to be the authId of the user you want to remove
      applicationName: props.applicationName,
    };

    try {
      const response = await Applications.removeUser(props.workspaceId, args); // Replace "Your wsId" with the actual wsId
      if (response.success) {
        setInlineAlertMessage(
          `User removed successfully.`
          // `User ${authUserList[index]} removed successfully.`
        );
        setInlineAlertSeverity("success");
      } else {
        setInlineAlertMessage(response.message);
        setInlineAlertSeverity("error");
      }
      const temp = [...authUserList];
      temp.splice(index, 1);
      setAuthUserList(temp);

      wsContext.reloadWorkspaces(orgContext.activeOrgId);
    } catch (error) {
      setInlineAlertMessage("Failed to remove user.");
      setInlineAlertSeverity("error");
    }
    setDeleteLoading(false);
    setCurrentDeletionIndex(-1);
  };

  const modifyPublicityAsync = async (publicity: boolean) => {
    let startingPublicity = props.application.policy.allowEveryone;

    const args = {
      applicationName: props.applicationName,
      public: publicity,
    };

    try {
      setPublicLoading(true);
      const res = await agent.Applications.modifyPublicity(
        props.workspaceId,
        args
      );
      console.log(res);
      await wsContext.reloadWorkspaces(orgContext.activeOrgId);
      if (res.success) {
        clickedMakePublic(publicity);
        setInlineAlertMessage(
          `Your URL is ${
            !startingPublicity ? "now public 🤙" : "no longer public 🤙"
          }`
        );
        setInlineAlertSeverity("success");
      } else {
        setInlineAlertMessage(res.message);
        setInlineAlertSeverity("error");
      }
      setPublicLoading(false);
    } catch (error) {
      console.error(error); // It's a good idea to catch any errors that might occur.
      setInlineAlertMessage(`Error changing status of your URL`);
      setInlineAlertSeverity("error");
    }
  };

  return (
    <>
      <InlineNotification
        show={!!inlineAlertMessage}
        severity={inlineAlertSeverity}
        text={inlineAlertMessage}
        autoClose={false}
        onClose={() => setInlineAlertMessage("")}
      />
      <h3 className="">{props.label}</h3>
      <p className="text-sm text-gray-500 dark:text-secondary mb-1">
        Grant access to other users by adding their email or username.
      </p>
      <div className="flex flex-row justify-between">
        <InputField
          key={1}
          label=""
          // helperText="Enter the full URL of the Github repo"
          value={email}
          errorMessage={""}
          // errorMessage={emailRegexError}
          placeholder="Email or Username"
          className="flex-grow mr-3" // Add flex-grow here
          onChange={(val) => setEmail(val)}
        />
        <Button
          label="Share"
          type="secondary"
          loading={shareLoading}
          disabled={shareLoading}
          onClick={() => handleAddEmail()}
          className="justify-center whitespace-nowrap" // Add whitespace-nowrap here
        />
      </div>
      <div className="flex flex-row justify-between mt-3">
        <Toggle
          // if it's public, the toggle makes it private
          label={"Make Public"}
          sublabel={"Make this application URL public"}
          checked={makePublic}
          onChange={(res) => {
            modifyPublicityAsync(res);
          }}
          loading={publicLoading}
        />
      </div>

      <hr className="mt-3 mb-3" />
      <div className="flex flex-row items-center">
        {" "}
        <UserIcon width={20} className="dark:fill-cyan-100" />{" "}
        <span className="mt-1 ml-2 dark:text-secondary">
          People with Access:
        </span>
      </div>
      <div className="mt-2">
        {makePublic ? (
          <>
            <div className="flex justify-between text-sm dark:text-secondary h-10 hover:bg-gray-100 dark:hover:bg-highlight">
              <div className="flex flex-row justify-between w-full items-center p-1.5">
                <div className="flex flex-row justify-between w-full items-center p-1.5">
                  <span className="flex items-center">{"Everyone."}</span>
                </div>
                <span>
                  {/* <GlobeAltIcon className=" items-center p-0.5 h-6 w-6" /> */}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            {authUserList.map((authID, index) => {
              return (
                <>
                  <div className="flex justify-between text-sm dark:text-secondary h-10 hover:bg-gray-100 dark:hover:bg-highlight">
                    <div className="flex flex-row justify-between w-full items-center p-1.5">
                      <AuthIDDisplayField authID={authID} />
                      {me?.externalAuthId === authID ? (
                        <span>Owner</span>
                      ) : (
                        <Button
                          label="Remove"
                          loading={
                            deleteLoading && index === currentDeletionIndex
                          }
                          type="danger"
                          onClick={() => {
                            handleRemoveUser(index, authID);
                          }}
                          className="justify-center h-8"
                        />
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

const AuthIDDisplayField: React.FC<{ authID: string }> = (props) => {
  const [name, setName] = useState<string>(props.authID);

  const loadUserNameFromAuthID = async () => {
    setName(props.authID);
    const res = await agent.Users.queryByAuthID(props.authID);
    if (res.success && res.data) {
      setName(res.data.email || props.authID);
    } else {
      setName(props.authID);
    }
  };

  useEffect(() => {
    loadUserNameFromAuthID();
  }, [props.authID]);

  return (
    <div className="flex flex-row justify-between w-full items-center p-1.5">
      <span className="flex items-center">{name}</span>
    </div>
  );
};

export default Access;
