import { Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import Spinner from "components/UI-lib/Spinner";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import React, { useContext, useRef, useState } from "react";
import agent, { WorkspaceGroup } from "server";
import PaperClipIcon from "assets/img/svg/paperclipicon.svg?react";
import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import { createDefaultAnalyticsObject } from "components/Analytics";
import { useHistory } from "react-router-dom";

interface ConnectIndividualCloudProps {
  show: boolean;
}

const ConnectGCP: React.FC<ConnectIndividualCloudProps> = ({ show }) => {
  const history = useHistory();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [GCPJson, setGCPJson] = useState("");
  const orgContext = useContext(OrgContext);
  const { me } = useContext(UserContext);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertAction, setInlineAlertAction] = useState(<></>);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "info" | "error" | "success" | "warning"
  >("info");

  const intendedToConnectGCP = async () => {
    const res = await agent.Users.update(
      me?.id || "",
      createDefaultAnalyticsObject(me, {
        attemptedToConnectCloud: true,
        attemptedToConnectGCP: true,
      })
    );

    console.log(res);
  };

  const handleClick = () => {
    intendedToConnectGCP();

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const connectGCP = (jsonObject: any) => {
    const args = {
      name: me?.name || "",
      organization_id: orgContext.activeOrgId,
      credential: {
        gcp: {
          project_id: jsonObject.project_id,
          type: jsonObject.type,
          private_key_id: jsonObject.private_key_id,
          private_key: jsonObject.private_key,
          client_email: jsonObject.client_email,
          client_id: jsonObject.client_id,
          auth_uri: jsonObject.auth_uri,
          token_uri: jsonObject.token_uri,
          auth_provider_x509_cert_url: jsonObject.auth_provider_x509_cert_url,
          client_x509_cert_url: jsonObject.client_x509_cert_url,
        },
      },
    };
    return agent.Organizations.createDevplaneWorkspaceGroup(args);
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".json")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const fileContents = JSON.parse(e.target.result as string);
          setGCPJson(fileContents);

          setInlineAlertMessage(
            "Uploading your GCP credentials. Hang tight..."
          );
          setInlineAlertSeverity("info");
          setInlineAlertAction(<Spinner type="secondary" />);

          connectGCP(fileContents).then((resp) => {
            if (resp.success) {
              setInlineAlertMessage("Your GCP credentials have been uploaded.");
              setInlineAlertSeverity("success");
              setInlineAlertAction(<></>);
            } else {
              setInlineAlertMessage(resp.message);
              setInlineAlertSeverity("error");
              setInlineAlertAction(<></>);
            }
          });
        } else {
          // Log potential errors for debugging
          console.log(e);
          console.log(e.target);
          setInlineAlertMessage(
            "There seems to be something wrong with the file you uploaded. If you think this is a mistake, please contact us"
          );
          setInlineAlertAction(
            <Button
              label={"Go to help center"}
              onClick={() => {
                history.push("/help");
              }}
              type="secondary"
            />
          );
          setInlineAlertSeverity("info");
        }
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a JSON file.");
    }
  };

  return (
    <Transition
      className="w-full"
      show={show}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <FlatCard noBorder className="mt-3">
        <div className="min-w-fit-content items-center">
          <h3 className="font-medium dark:text-white">
            Connect your GCP Account
          </h3>
          <p className="my-2 text-sm text-gray-700 dark:text-secondary">
            To connect your GCP account, please create a service account and
            upload the service account's JSON file. <br />{" "}
            <a
              className="text-highlight dark:text-cyan-300 underline"
              target="_blank"
              rel="noreferrer"
              href="https://brev.dev/docs/reference/connect-your-own-cloud"
            >
              Here
            </a>{" "}
            are easy-to-follow instructions from our docs.
          </p>
          <InlineNotification
            show={!!inlineAlertMessage}
            severity={inlineAlertSeverity}
            className="w-full mb-2"
            text={inlineAlertMessage}
            text2={<div className="mt-2 ml-[36px]">{inlineAlertAction}</div>}
            autoClose={false}
            onClose={() => {
              setInlineAlertMessage("");
              setInlineAlertAction(<></>);
              setTimeout(() => {
                // avoid flashing red when the alert is closed
                setInlineAlertSeverity("error");
              }, 100);
            }}
          />
          <input
            type="file"
            accept=".json"
            ref={fileInputRef}
            onChange={handleUpload}
            style={{ display: "none" }}
          />
          <Button
            label="Upload Service Account Credentials JSON"
            type="primary"
            iconLeft={<PaperClipIcon className="h-5 w-5 mr-2" />}
            onClick={handleClick}
          />
        </div>
      </FlatCard>
    </Transition>
  );
};

export default ConnectGCP;
