import React from "react";

export const CaretDownIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={`${className}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m19.5 8.25-7.5 7.5-7.5-7.5"
    />
  </svg>
);

export const CaretUpIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={`${className}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 15.75 7.5-7.5 7.5 7.5"
    />
  </svg>
);

export const CaretRightIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={`${className}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m8.25 4.5 7.5 7.5-7.5 7.5"
    />
  </svg>
);

export const CaretLeftIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={`${className}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m15.75 19.5-7.5-7.5 7.5-7.5"
    />
  </svg>
);

export const EmptyCircleIcon = ({ className }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      d="M19.5 10C19.5 11.1819 19.2672 12.3522 18.8149 13.4442C18.3626 14.5361 17.6997 15.5282 16.864 16.364C16.0282 17.1997 15.0361 17.8626 13.9442 18.3149C12.8522 18.7672 11.6819 19 10.5 19C9.3181 19 8.14778 18.7672 7.05585 18.3149C5.96392 17.8626 4.97177 17.1997 4.13604 16.364C3.30031 15.5282 2.63738 14.5361 2.18508 13.4442C1.73279 12.3522 1.5 11.1819 1.5 10C1.5 7.61305 2.44821 5.32387 4.13604 3.63604C5.82387 1.94821 8.11305 1 10.5 1C12.8869 1 15.1761 1.94821 16.864 3.63604C18.5518 5.32387 19.5 7.61305 19.5 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DashedCircleIcon = ({ className }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path
      d="M19.5 10C19.5 11.1819 19.2672 12.3522 18.8149 13.4442C18.3626 14.5361 17.6997 15.5282 16.864 16.364C16.0282 17.1997 15.0361 17.8626 13.9442 18.3149C12.8522 18.7672 11.6819 19 10.5 19C9.3181 19 8.14778 18.7672 7.05585 18.3149C5.96392 17.8626 4.97177 17.1997 4.13604 16.364C3.30031 15.5282 2.63738 14.5361 2.18508 13.4442C1.73279 12.3522 1.5 11.1819 1.5 10C1.5 7.61305 2.44821 5.32387 4.13604 3.63604C5.82387 1.94821 8.11305 1 10.5 1C12.8869 1 15.1761 1.94821 16.864 3.63604C18.5518 5.32387 19.5 7.61305 19.5 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 2"
    />
  </svg>
);
