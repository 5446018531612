// needed for localhost dev
export const PROXY_PATH = "/api/proxy";
export const CONSOLE_2_LOCALHOST = "localhost:8080";
export const CONSOLE_1_LOCALHOST = "localhost:3000";
export const LOCALHOST_LOGIN_URL = `http://${CONSOLE_2_LOCALHOST}/signin?redirect_uri=http%3A%2F%2Flocalhost%3A3000/redirecting-you-swiftly`;

export const redirectUserToLogin = () =>
  window.location.assign(
    isLocalHost ? LOCALHOST_LOGIN_URL : CONSOLE_2_LOGIN_URL
  );

// needed for production
export const CONSOLE_2_LOGIN_URL = "https://login.brev.nvidia.com/signin";
export const CONSOLE_1_KAS_DEPLOYMENT_URL = "https://brev.nvidia.com";
export const CONSOLE_1_AUTH0_DEPLOYMENT_URL = "https://console.brev.dev";

export const isLocalHost = process.env.VITE_STAGE === "dev";
// on localhost, use KAS staging
export const KAS_HOST = isLocalHost
  ? "api.stg.ngc.nvidia.com"
  : "api.ngc.nvidia.com";
export const KAS_TOKEN_STORAGE_KEY = "kas-token";

const KAS_PATH = `https://${KAS_HOST}`;

export const defaultHost = {
  host: KAS_HOST,
};

export const prependKasUrl = (endpoint: string): string => {
  // on localhost, we assume the user has the brev console (https://github.com/brevdev/brev-console)
  // open and running on localhost:8080 - KAS requests are proxied through console2's nextjs server
  return `${
    isLocalHost ? `http://${CONSOLE_2_LOCALHOST}${PROXY_PATH}` : KAS_PATH
  }${endpoint}`;
};

export const isKasAuthFlow = !!process.env.VITE_STARFLEET;
