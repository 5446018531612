import Button from "components/UI-lib/Button";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext } from "react";

const NIMMetricsDash = () => {
  console.log("Route: /nim-metrics-dash");
  const orgContext = useContext(OrgContext);

  if (
    orgContext.activeOrgId === "ksaqlobp3" ||
    orgContext.activeOrgId === "ejmrvoj8m"
  ) {
    return (
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
        <div className="flex flex-col justify-evenly items-center">
          <div className="mt-3"></div>
          <div className="w-full">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              {`NIM Hackathon Metrics`}
            </h1>
          </div>
          <div className="mt-3"></div>

          <div className="w-full flex flex-row justify-between">
            <p className="text-gray-500 dark:text-gray-400">
              See metrics from the{" "}
              <a
                className="underline text-highlight dark:text-highlight"
                href="https://anythingbutwrappers.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://anythingbutwrappers.com
              </a>{" "}
              hackathon
            </p>
            <div className="flex flex-row">
              {/* <Button
                      label="Reset Configurations"
                      className="ml-2"
                      type="secondary"
                      onClick={() => {
                        // reset();
                      }}
                    /> */}
            </div>
          </div>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          <div className="flex flex-row w-full"></div>
        </div>
      </div>
    );
  } else {
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
      <div className="flex flex-col justify-evenly items-center">
        <div className="mt-3"></div>
        <div className="w-full">
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
            {`NIM Hackathon Metrics`}
          </h1>
          <p className="text-black dark:text-white">you shouldn't be here</p>;
        </div>
      </div>
    </div>;
  }
};

export default NIMMetricsDash;
