import { Capability } from "contexts/OrgContext";
import Organization from "../entities/Organization.entity";
import Permission from "../entities/Permission.entity";
import Project from "../entities/Project.entity";
import Secret, { SetupType } from "../entities/Secret.entity";
import User from "../entities/User.entity";
import Workspace from "../entities/Workspace.entity";
import { string } from "io-ts";
import { IExec } from "models/Exec.model";
import { PermissionHierarchyType } from "models/Permission.model";
import { IRepo } from "models/Repo.model";
import { RoleIds } from "models/Role.model";
import { HierarchyType } from "models/Secret.model";
import { UIMode } from "models/UIMode.model";
import { APIKeys, IdeConfig, UserOnboardingStatus } from "models/User.model";
import { WorkspaceClassId } from "models/Workspace.model";
import {
  CloneWorkspaceReqBody,
  CreateSecretReqBody,
  Deployment,
  DeploymentLog,
  DeploymentMetricRes,
  DeploymentMetrics,
  EnvEvent,
  EnvSaving,
  OrgRes,
  PermissionsRes,
  SecretReqDest,
  SecretReqSrc,
  UpdateWorkspaceReqBody,
  UserRes,
  UserUpdateReqBody,
  WorkspaceRes,
  WorkspacesRes,
} from "server";
import { testSecret, updatedTestSecret } from "./data";
import { AxiosRes, BaseRes } from "server/config";

export const userContextDefaultValues = {
  me: null,
  isAuthenticated: false,
  isLoading: true,
  getUser: async (userId: string) => null as User | null,
  updateUser: async (updates: UserUpdateReqBody) => ({} as UserRes),
  verifyMe: () => null,
  getMe: async () => null as User | null,
  refreshOnboardingObject: async () => {}, // Added this line
  onboardingObject: {
    SSH: false,
    usedCLI: false,
    attemptedToConnectCloud: false, // Added this line assuming you want it here as well
  },
  setOnboardingObjectFunc: (updates: UserOnboardingStatus) => null,
  updateApiKeys: async (apiKeys: APIKeys) => ({} as AxiosRes),
};

export const secretsContextDefaultValues = {
  secrets: [] as Array<Secret>,
  setup: async (setupParams: SetupType[]) => {},
  clear: () => null,
  getSecretsByHierarchy: (type: HierarchyType) => [] as Array<Secret>,
  createSecret: async (args: CreateSecretReqBody) => testSecret,
  updateSecret: async (
    id: Secret["id"],
    updateParam: {
      name?: string;
      src?: SecretReqSrc;
      dest?: SecretReqDest;
    }
  ) => updatedTestSecret,
  deleteSecret: async (id: Secret["id"]) => id,
};

export const orgContextDefaultValues = {
  orgs: [],
  activeOrgId: "",
  activeOrg: null,
  isLoading: true,
  isInstancesLoading: true,
  cpuRegions: [],
  allInstancesAvailable: [],
  GPUInstancesAvailable: [],
  CPUInstancesAvailable: [],
  updateInstanceTypes: (orgId: string) => null,
  getActiveOrg: () => ({} as Organization | null),
  setActiveOrg: (id: string) => null,
  setDefaultWorkspaceGroupForActiveOrg: (id: string) => null,
  getOrg: async (id: string) => ({} as Organization | null),
  getInviteLink: async (id: string) => ({} as any),
  inviteByEmail: async (id: string, inviteEmails: string[]) => ({} as any),
  createOrg: async (name: string) =>
    ({ success: false, message: "Error" } as OrgRes),
  deleteOrg: async (id: string) =>
    ({ success: false, message: "Error" } as OrgRes),
  workspaceGroups: [],
  activeWorkspaceGroupId: "",
  getActiveWorkspaceGroup: () => null,
  setActiveWorkspaceGroupId: (id: string) => null,
  refreshWorkspaceGroups: () => null,
  usage: [],
  usageLoading: false,
  usageOverview: [],
  usageOverviewLoading: false,
  updateStoragePricing: () => null,
  updateComputePricing: () => null,
  storagePricing: [],
  computePricing: {},
  orgcredits: 0,
  getCredits: async () => null,
  getCapabilities: async (
    workspaceGroupId: string,
    workspaceCapabilities: Capability[] | undefined
  ): Promise<Capability[]> => [],
  allRegionPricing: {},
  regionNumLoading: 0,
  updateComputePricingLocal: () => null,
  isEnterprise: (orgId: string) => false,
};

export const launchableContextDefaultValues = {
  activelaunchables: [],
  loadingActiveLaunchables: false,
  launchableMetrics: [],
  loadingMetrics: false,
  overViewData: [],
  overDeployData: [],
  userMetricsMapping: {},
  dailyOverviewMetrics: [],
  loadingDailyMetrics: false,
  userMetricsList: [],
  dailyLaunchableMetrics: [],
  mergeMetricOnLaunchable: (launchableId: string) => {
    return { views: 0, deploys: 0 };
  },
  refreshMetrics: () => null,
  refreshActiveLaunchables: () => null,
  refreshAllLaunchableData: () => null,
  deleteLaunchable: async (orgID: string, id: string) => null,
};

export const workspaceContextDefaultValues = {
  isLoading: true,
  workspaces: [],
  setWorkspaces: (ws: Workspace[]) => {},
  projects: () => [] as Project[],
  setup: async (orgId: string, cache?: boolean) => ({} as WorkspacesRes),
  reloadWorkspaces: async (activeOrgId: string) => {},
  clear: () => {},
  getWorkspace: async (id: string) => ({} as Workspace | null),
  getProject: (workspaceId: string) => ({} as Project | null),
  createWorkspace: async (
    name: string,
    orgId: string,
    activeWorkspaceGroupId: string,
    description?: string,
    repos?: { [key: string]: IRepo },
    execs?: { [key: string]: IExec },
    ideConfig?: IdeConfig,
    verbYaml?: string
  ) => ({} as WorkspaceRes),
  updateWorkspace: async (wsid: string, args: UpdateWorkspaceReqBody) =>
    ({} as WorkspaceRes),
  stopWorkspace: async (id: string) => ({} as WorkspaceRes),
  startWorkspace: async (id: string) => ({} as WorkspaceRes),
  deleteWorkspace: async (id: string) => ({} as WorkspaceRes), // return fake workspace
  resetWorkspace: async (id: string) => ({} as WorkspaceRes),
  hardResetWorkspace: async (id: string) => ({} as WorkspaceRes),
  cloneWorkspace: async (id: string, newName: string) => ({} as WorkspaceRes),
  changeIsStoppable: async (id: string, newIsStoppable: boolean) =>
    ({} as WorkspaceRes),
  updateVSCodeExtensionsToWorkspaceTemplate: async (
    id: string,
    ideConfig: IdeConfig
  ) => ({} as WorkspaceRes),
  updateRepoConfigToWorkspaceTemplate: async (
    id: string,
    repos: { [key: string]: IRepo }
  ) => ({} as WorkspaceRes),
  updateExecConfigToWorkspaceTemplate: async (
    id: string,
    execs: { [key: string]: IExec }
  ) => ({} as WorkspaceRes),
  updateStartupScriptPath: async (id: string, startupScriptPath: string) =>
    ({} as WorkspaceRes),
  addNewPortToWorkspace: async (id: string, newPortUserWantsToExpose: string) =>
    ({} as WorkspaceRes),
  revokePortFromWorkspace: async (id: string, portUserWantsToRevoke: string) =>
    ({} as WorkspaceRes),
  envSavings: async (id: string) => ({} as EnvSaving),
  shareWorkspace: async (id: string, addUsers: string[]) =>
    ({} as WorkspaceRes),
};

export const deploymentContextDefaultValuese = {
  deployments: [],
  instanceTypes: [],
  reloadDeployments: () => null,
  getDeploymentInstanceTypes: (activeOrgId: string) => null,
  setDeployments: (deployments: Deployment[]) => [],
  getDeploymentMetrics: (activeOrgId: string, deploymentId: string) =>
    ({} as Promise<DeploymentMetrics>),
  getDeploymentLogs: (activeOrgId: string, deploymentId: string) =>
    ({} as Promise<DeploymentLog[]>),
  loadingInstanceTypes: false,
  loadingDeployments: false,
};

export const permissionsContextDefaultValues = {
  permissions: [] as Array<Permission>,
  setup: async (hierarchyType: PermissionHierarchyType, hierarchyId: string) =>
    ({} as PermissionsRes), // return fake permissions
  clear: () => {},
  getPermissionsByHierarchy: (hierarchyType: PermissionHierarchyType) =>
    [] as Array<Permission>,
  createPermission: async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    role: RoleIds
  ) => ({} as PermissionsRes),
  updatePermission: async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    role: RoleIds
  ) => ({} as PermissionsRes),
  deletePermission: async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    permissionId: string
  ) => ({} as PermissionsRes),
};

// export const alertContextDefaultValues = {
//   showAlert: (m: string, s: Color) => null,
// };

// export const toastContextDefaultValues = {
//   showToast: (m: string, s: Color) => null,
// };

export const appContextDefaultValues = {
  uiMode: UIMode.Light,
  isLoading: true,
  setupUIMode: () => null,
  updateUIMode: (mode: UIMode) => null,
};
