import { BeakerIcon, StopCircleIcon } from "@heroicons/react/24/solid";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { classNames, memoryToGiB } from "components/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "./AnimatedBorderDiv.scss";
import FlatCard, { FlatCardProps } from ".";
import InputField from "../InputField";

// import React from 'react';
// import { BeakerIcon, StopCircleIcon, CircleStackIcon } from "@heroicons/react/24/solid";
// import { classNames } from "components/utils";
// import FlatCard from ".";
// import { ProviderAttribute, ProviderAttributesWrapper } from "components/Environment/Create/ComputePicker/ProviderAttributes";

interface EndpointProviderCardProps {
  isSelected?: boolean;
  value: EndpointProvider;
  onChange?: (option: EndpointProvider) => void;
  className?: string;
}

export interface EndpointProvider {
  id: string;
  name: string;
  throughput: number;
  cost: number;
  provider: string;
  providerLogo: any;
  logoFileType: "png" | "svg";
}

interface ProviderAttribute {
  icon: React.ReactNode;
  title: string;
  description: string;
  className: string;
}

export const EndpointProviderCard: React.FC<EndpointProviderCardProps> = (
  props
) => {
  const { isSelected, value: provider, onChange, className } = props;

  return (
    <div
      onClick={() => onChange && onChange(provider)}
      style={{ width: "100%" }}
    >
      <FlatCard
        noBorder={true}
        className={classNames(
          isSelected ? "border border-highlight" : "border border-zinc-800",
          className || "",
          "mt-2 cursor-pointer w-full"
        )}
      >
        <label className="flex items-center space-x-2 cursor-pointer w-full">
          <input
            type="radio"
            name="endpointProviderOption"
            value={provider.id}
            checked={isSelected}
            onChange={() => onChange && onChange(provider)}
            className="form-radio text-cyan-300"
          />
          <div className="flex flex-col">
            <div className="flex items-center justify-between space-x-4 w-full pl-2">
              <div className="flex items-center space-x-3">
                <div className="flex flex-row">
                  {provider.logoFileType === "svg" ? (
                    <ProviderLogo
                      Logo={provider.providerLogo}
                      className="w-6 h-6 mr-2"
                    />
                  ) : (
                    <img
                      src={provider.providerLogo}
                      alt={`${provider.name} logo`}
                      className="w-6 h-6 mr-2"
                    />
                  )}
                  <h2 className="text-sm font-medium flex flex-row">
                    <span>{provider.name}</span>
                  </h2>
                </div>
                <p className="flex space-x-2 text-sm text-gray-500">
                  {/* <span className="metadata-throughput">
                    {provider.throughput} req/s
                  </span> */}
                  <span className="metadata-cost">
                    • ${provider.cost.toFixed(2)}/call
                  </span>
                </p>
              </div>
            </div>
          </div>
        </label>
      </FlatCard>
    </div>
  );
};

const ProviderLogo: React.FC<{
  Logo: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  className?: string;
}> = ({ Logo, className }) => {
  return <Logo className={classNames("w-6 h-6", className || "")} />;
};
