import React from "react";
import { Star, GitFork, Eye, Github } from "lucide-react";
import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import agent, { GithubRepoRes } from "server";
import { shortenFileName } from "components/Environment/Launchables/utils";
import { get } from "lodash";
import { as } from "fp-ts/lib/Option";
import { sanitizeRepo } from "components/utils";

interface GitHubRepoRendererProps {
  repoUrl: string;
}

const GitHubRepoRenderer: React.FC<GitHubRepoRendererProps> = ({ repoUrl }) => {
  const [info, setInfo] = useState<GithubRepoRes | undefined>(undefined);
  const [repoName, setRepoName] = useState<string>("");
  const [ownerName, setOwnerName] = useState<string>("");

  useEffect(() => {
    const fetchRepoInfo = async () => {
      try {
        const res = await agent.Github.repoInfo({
          repoUrl,
        });
        if (res.success && res.data) {
          setInfo(res);
        }
      } catch (error) {
        console.error("Error fetching repository information:", error);
      }
    };
    fetchRepoInfo();
  }, []);

  useEffect(() => {
    const shortenGithubName = (fileName: string): string => {
      const slashIndex = fileName.lastIndexOf("/");
      if (slashIndex !== -1) {
        return fileName.slice(slashIndex + 1).replace(/\s/g, "-");
      }
      return "";
    };

    const name = shortenGithubName(repoUrl);
    console.log(name);
    setRepoName(name);
  }, [repoUrl]);

  useEffect(() => {
    // helper function that gets the owner from the repoUrl
    const getOwner = (url: string) => {
      const parts = url.split("/");
      setOwnerName(parts[parts.length - 2]);
    };
    getOwner(repoUrl);
  }, [info]);

  return (
    <div className="bg-primaryBg border border-primaryBorder rounded-lg p-6 mb-4 text-white">
      <div className="flex items-center gap-3 mb-4">
        <GitHubLogoIcon className="w-8 h-8 text-blue-400" />
        <h2 className="text-xl font-bold">{sanitizeRepo(repoName)}</h2>
        <span className="text-gray-400 text-lg">by {ownerName}</span>
      </div>
      {/* <p className="text-gray-300 mb-4 text-lg">{repoInfo.description}</p> */}
      <div className="flex gap-6 text-gray-300">
        <div className="flex items-center gap-2">
          <Star className="w-5 h-5 text-yellow-400" />
          <span>{info?.data?.stars} stars</span>
        </div>
        <div className="flex items-center gap-2">
          <GitFork className="w-5 h-5 text-green-400" />
          <span>{info?.data?.forks} forks</span>
        </div>
        <div className="flex items-center gap-2">
          <Eye className="w-5 h-5 text-purple-400" />
          <span>{info?.data?.watchers} watchers</span>
        </div>
      </div>
    </div>
  );
};

export default GitHubRepoRenderer;
