import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import posthog from "posthog-js"; // new
import FullScreenLoader from "components/FullScreenLoader";
import config from "config";
import AppContextProvider from "contexts/AppContext";
import OrgContextProvider from "contexts/OrgContext";
import PermissionsContextProvider from "contexts/PermissionsContext";
import SecretsContextProvider from "contexts/SecretsContext";
import UserContextProvider from "contexts/UserContext";
import WorkspaceContextProvider from "contexts/WorkspaceContext";
import EnvironmentList, {
  EnvironmentListRedirect,
} from "components/Environment/List";
import Logout from "components/Auth/Logout";
import NotificationContextProvider from "contexts/NotificationContext";
import Login from "components/Login";
import SecuredRoute from "SecuredRoute";
import Invite from "components/Invite";
import ErrorPage from "components/ErrorPages";
import Home from "Home";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import HelpPage from "components/ErrorPages/HelpPage";
import WorkspaceCreateContextProvider from "contexts/WorkspaceCreateContext";
import LoginAlt from "components/Login/LoginAlt";
import OrgPageSettings, {
  OrgPageSettingsRedirect,
} from "./components/OrgPages/OrgPageSettings";
import Profile from "./components/Profile";
import OrgPagePeople from "./components/OrgPages/Teams";
import NotebookList from "./components/NotebookList";
import High5 from "./components/DashboardContainer/High5";
import Dash from "./components/DashboardContainer/Dash";
import GetCode from "./components/GetCode/GetCode";
import InstanceCreate, {
  InstanceCreateWrapper,
} from "components/Environment/Create/LegacyCreate";
import EnvironmentSettings from "components/Environment/Settings";
import BannerNotification from "components/BannerNotification";
import TheReleaseInvite from "components/GetCode/TheRelease";
import NewAdminPage from "components/Admin";
import { BillingProvider } from "contexts/BillingContext";
import OrgBillingPage, {
  OrgBillingPageRedirect,
} from "components/OrgPages/Billing";
import NotebookRenderer from "components/Notebooks/Notebooks";
import OneClickDeployContextProvider from "contexts/OneClickDeployContext";
import { ScreenSaver } from "components/DashboardContainer/ScreenSaver";
import GiftCard from "components/GiftCard";
import Launchables, {
  LaunchablesRedirect,
} from "components/Environment/Launchables/Create";
import LaunchableDeploy from "components/Environment/Launchables/Deploy/Now";
import LaunchableDeployPublic from "components/Environment/Launchables/Deploy/Public";
import LaunchableList from "components/Environment/Launchables/List";
import NIMMetricsDash from "components/Environment/Launchables/NIMMetricDash";
import RedeemLaunchableCredits from "components/GiftCard/RedeemLaunchableCredits";
import LaunchableContextProvider from "contexts/LaunchableContext";
import LaunchableMetrics from "components/Environment/Launchables/Metrics";
import Discover, { DiscoverRedirect } from "components/Discover";
import NotebookRenderContextProvider from "contexts/NotebookRenderContext";
import WelcomeNvidians from "components/FullScreenLoader/WelcomeNvidians";
import EnvironmentCreateWrapper from "components/Environment/Create";
import RedeemableCode from "components/GiftCard/RedeemableCode";
import Endpoints, { EndpointsRedirect } from "components/Endpoints";
import DeploymentSettings from "components/Deployments/Settings";
import Deployments from "components/Deployments/List";
import DeploymentCreateWrapper from "components/Deployments/Create";
import DeploymentsContextProvider from "contexts/DeploymentsContext";
import Content from "components/Blueprints";
import Blueprints from "components/Blueprints";
import KasContextProvider from "contexts/KasContext";
import { PUBLIC_ROUTES } from "SecuredRoute/isPublicRoute";

const Callback: React.FC = () => {
  console.log("Route: /redirecting-you-swiftly");
  // we could probably do some validation too for the callback url
  const redirectUrl = localStorage.getItem("brev-redirect-url") ?? "/";
  // localStorage.removeItem("brev-redirect");
  return <Redirect to={redirectUrl} />;
};

export const SHAKA = `
Your verb container is ready.
                               _
              _        ,-.    / )
             ( \`.     // /-._/ /
              \`\\ \\   /(_/ / / /
                ; \`-\`  (_/ / /
                |       (_/ /
                \\          /
                 )        /
      Brev.Dev   /       /
`;
export const SHAKA2 = `
                               _
              _        ,-.    / )
             ( \`.     // /-._/ /
              \`\\ \\   /(_/ / / /
                ; \`-\`  (_/ / /
                |       (_/ /
                \\          /
   Let it rip,   )        /
      Brev.Dev   /       /
`;

if (window !== undefined) {
  window.ph = posthog;
}

const CreateCPUPage: React.FC = () => {
  console.log("Route: /newCPU");

  return <InstanceCreateWrapper panel="CPU" />;
};

const App: React.FC = () => {
  let location = useLocation();
  React.useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  const stripePromise = loadStripe(config.stripePublishableKey);

  const appearance = {
    theme: "night",
    variables: {
      colorTextPlaceholder: "rgb(148 163 184)",
    },
    rules: {
      ".Input": {
        border: "1px solid rgb(30 41 59)",
        backgroundColor: "rgb(24 24 27)",
        color: "rgb(148 163 184)",
        boxShadow: "none",
      },
      ".Input:focus": {
        border: "1px solid rgb(6 182 212)",
        backgroundColor: "rgb(24 24 27)",
        color: "rgb(148 163 184)",
        boxShadow: "none",
      },
    },
  };

  React.useEffect(() => {
    document.title = "Console | Brev.dev";
    if (!window.location.pathname.includes("/redirecting-you-swiftly"))
      localStorage.setItem("brev-redirect-url", window.location.pathname);
  }, []);

  console.log("Route: /");

  return (
    <Router>
      <NotebookRenderContextProvider>
        <Switch>
          {/* <Route exact path="/newCPU" component={CPUNew} /> */}
          <Route path={PUBLIC_ROUTES.NOTEBOOK} component={NotebookRenderer} />
          <Route
            path={PUBLIC_ROUTES.GIFTCARD}
            render={(props) => (
              <GiftCard
                {...props}
                stripePromise={stripePromise}
                options={{ appearance }}
              />
            )}
          />
          <Route
            path={PUBLIC_ROUTES.LAUNCHABLE_DEPLOY}
            exact
            component={LaunchableDeployPublic}
          />
          <Route
            path={PUBLIC_ROUTES.DISCOVER}
            exact
            component={DiscoverRedirect}
          />
          <Route
            path={PUBLIC_ROUTES.ENDPOINTS}
            exact
            component={EndpointsRedirect}
          />
          <UserContextProvider>
            <NotificationContextProvider>
              <OrgContextProvider>
                <BillingProvider>
                  <WorkspaceContextProvider>
                    <DeploymentsContextProvider>
                      <SecretsContextProvider>
                        <PermissionsContextProvider>
                          <AppContextProvider>
                            <Elements
                              stripe={stripePromise}
                              options={{ appearance } as any}
                            >
                              <Switch>
                                <Route
                                  exact
                                  path={PUBLIC_ROUTES.LOGIN_ALT}
                                  component={LoginAlt}
                                />
                                <Route
                                  exact
                                  path={PUBLIC_ROUTES.LOGIN}
                                  component={Login}
                                />
                                <Route
                                  exact
                                  path={PUBLIC_ROUTES.REDEEM_CODE}
                                  component={RedeemableCode}
                                />
                                <FullScreenLoader>
                                  <High5>
                                    <WorkspaceCreateContextProvider>
                                      <OneClickDeployContextProvider>
                                        <LaunchableContextProvider>
                                          <Dash>
                                            <SecuredRoute
                                              exact
                                              path="/welcome-nvidians"
                                              component={WelcomeNvidians}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/"
                                              component={Home}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/redirecting-you-swiftly"
                                              component={Callback}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/environments"
                                              component={EnvironmentList}
                                            />

                                            <SecuredRoute
                                              exact
                                              path="/org/environments"
                                              component={
                                                EnvironmentListRedirect
                                              }
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/discover"
                                              component={Discover}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/endpoints"
                                              component={Endpoints}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/deployments/new"
                                              component={
                                                DeploymentCreateWrapper
                                              }
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/deployments"
                                              component={Deployments}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/team"
                                              component={OrgPagePeople}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/settings"
                                              component={OrgPageSettings}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/settings"
                                              component={
                                                OrgPageSettingsRedirect
                                              }
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/deployments/:deploymentId/"
                                              component={DeploymentSettings}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/environments/:deploymentId/:page"
                                              component={EnvironmentSettings}
                                            />

                                            <SecuredRoute
                                              exact
                                              path="/notebooks/:notebook"
                                              component={NotebookRenderer}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/billing"
                                              component={OrgBillingPage}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/billing"
                                              component={OrgBillingPageRedirect}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/launchables/create"
                                              component={Launchables}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/nim-metrics-dash"
                                              component={NIMMetricsDash}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/launchables"
                                              component={LaunchableList}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/launchables/metrics"
                                              component={LaunchableMetrics}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/launchables/redeem"
                                              component={
                                                RedeemLaunchableCredits
                                              }
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/launchables"
                                              component={LaunchablesRedirect}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/launchable/deploy/now"
                                              component={LaunchableDeploy}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/environments/:workspaceId"
                                              component={EnvironmentSettings}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/org/:orgId/blueprints"
                                              component={Blueprints}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/newCPU"
                                              component={CreateCPUPage}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/environment/new"
                                              component={
                                                EnvironmentCreateWrapper
                                              }
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/buildspace-lootbox"
                                              component={GetCode}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/the-release-invitation"
                                              component={TheReleaseInvite}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/profile"
                                              component={Profile}
                                            />
                                            <SecuredRoute
                                              exact
                                              path="/admin"
                                              component={NewAdminPage}
                                            />
                                            <Route
                                              exact
                                              path={PUBLIC_ROUTES.LOGOUT}
                                              component={Logout}
                                            />
                                            <Route
                                              exact
                                              path={PUBLIC_ROUTES.INVITE}
                                              component={Invite}
                                            />
                                            <Route
                                              exact
                                              path={PUBLIC_ROUTES.ERROR}
                                              component={ErrorPage}
                                            />
                                            <Route
                                              exact
                                              path={PUBLIC_ROUTES.HELP}
                                              component={HelpPage}
                                            />
                                            {/* <Route component={Home} /> */}
                                            <ScreenSaver />
                                          </Dash>
                                        </LaunchableContextProvider>
                                      </OneClickDeployContextProvider>
                                    </WorkspaceCreateContextProvider>
                                  </High5>
                                </FullScreenLoader>
                              </Switch>
                            </Elements>
                          </AppContextProvider>
                        </PermissionsContextProvider>
                      </SecretsContextProvider>
                    </DeploymentsContextProvider>
                  </WorkspaceContextProvider>
                </BillingProvider>
              </OrgContextProvider>
            </NotificationContextProvider>
          </UserContextProvider>
        </Switch>
      </NotebookRenderContextProvider>
    </Router>
  );
};

export default App;
