import Secret from "../entities/Secret.entity";
import ISecret, {
  DestType,
  HierarchyType,
  SecretType,
  SrcType,
} from "models/Secret.model";

export const testISecret: ISecret = {
  id: "uid-secret-1",
  name: "My Test Secret",
  type: SecretType.Concrete,
  hierarchyType: HierarchyType.Organization,
  hierarchyId: "uid-org-1",
  src: {
    type: SrcType.KeyValue,
    config: { value: "lol1" },
  },
  dest: {
    type: DestType.EnvVariable,
    config: { name: "MY_ENV_VAR" },
  },
};

export const updatedTestISecret: ISecret = {
  id: "uid-secret-1",
  name: "My Test Secret Updated",
  type: SecretType.Concrete,
  hierarchyType: HierarchyType.Organization,
  hierarchyId: "uid-org-1",
  src: {
    type: SrcType.KeyValue,
    config: { value: "lol2" },
  },
  dest: {
    type: DestType.EnvVariable,
    config: { name: "MY_ENV_VAR2" },
  },
};

export const testSecret = new Secret(testISecret);
export const updatedTestSecret = new Secret(updatedTestISecret);
