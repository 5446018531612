import ISecret, {
  DestConfig,
  DestType,
  HierarchyType,
  SecretType,
  SrcConfig,
  SrcType,
} from "models/Secret.model";

export default class Secret implements ISecret {
  public id: string;

  public name: string;

  public type: SecretType;

  public refId?: string;

  public hierarchyType: HierarchyType;

  public hierarchyId: string;

  public get srcType() {
    return this.src.type;
  }

  public get value() {
    return this.src.config.value;
  }

  public get destType() {
    return this.dest.type;
  }

  public get destConfig() {
    const values = Object.values(this.dest.config);
    if (values.length !== 1) return null;
    return values[0];
  }

  public src: {
    type: SrcType;
    config: SrcConfig;
  };

  public dest: {
    type: DestType;
    config: DestConfig;
  };

  public constructor(s: ISecret, value?: string) {
    this.id = s.id;
    this.name = s.name;
    this.type = s.type;
    this.hierarchyType = s.hierarchyType;
    this.hierarchyId = s.hierarchyId;
    this.src = s.src;
    this.dest = s.dest;
    if (s.refId) this.refId = s.refId;
    if (value) this.setValue(value);
  }

  setValue = (value: string) => {
    this.src.config.value = value;
  };

  setName = (name: string) => {
    this.name = name;
  };

  setSrcType = (srcType: SrcType) => {
    this.src.type = srcType;
  };

  setDestType = (destType: DestType) => {
    this.dest.type = destType;
  };
}

export interface SetupType {
  type: HierarchyType;
  ID: string;
}
