import DevToggle from "components/DevToggle";
import NotebookList from "components/NotebookList";
import HtmlRenderer from "components/Notebooks/HtmlRenderer";
import FlatCard from "components/UI-lib/FlatCard";
import CustomizeVerb from "components/Verb/CustomizeVerb";
import { VerbBuildComponent } from "components/Verb/VerbBuildComponent";
import { getVerbBuildConfigFromWorkspace } from "components/Verb/utils";
import Workspace from "entities/Workspace.entity";
import React, { useEffect, useMemo, useState } from "react";
interface ContainerSettingsProps {
  workspaceId: string;
  setShowButton: (b: boolean) => void;
  workspace: Workspace;
  initialVerbLoad: boolean;
}
const ContainerSettings: React.FC<ContainerSettingsProps> = (props) => {
  const verbBuild = getVerbBuildConfigFromWorkspace(
    props.workspace?.verbYaml || ""
  );

  if (props.workspace?.vmOnlyMode) {
    return (
      <div className="flex flex-row items-end ">
        <FlatCard className="mt-3" isLoading={false}>
          <p className="text-sm text-amber-700 dark:text-amber-500 font-mono flex justify-center">
            This environment was configured in VM mode. No container was built.
          </p>
        </FlatCard>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-row items-end ">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            View Logs
          </h1>
        </div>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div>
        <VerbBuildComponent
          workspaceId={props.workspaceId}
          setShowButton={props.setShowButton}
          workspace={props.workspace}
          initialVerbLoad={props.initialVerbLoad}
        />
      </div>
      {!verbBuild?.base_image &&
        !props.workspace.customContainer &&
        !props.workspace.vmOnlyMode && (
          <>
            <div className="flex flex-row items-end mt-8">
              <div className="mr-3">
                <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
                  Change Container
                </h1>
              </div>
            </div>
            <hr className="w-[100%] my-3 dark:border dark:border-zinc-800 justify-center" />
            <div className="flex flex-col">
              <CustomizeVerb workspace={props.workspace} />
            </div>
          </>
        )}
    </>
  );
};

export default ContainerSettings;
