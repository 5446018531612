import Secret, { SetupType } from "../entities/Secret.entity";
import { HierarchyType } from "models/Secret.model";
import React, { useContext, useState } from "react";
import agent, {
  CreateSecretReqBody,
  SecretReqDest,
  SecretReqSrc,
  UpdateSecretReqBody,
} from "server";
import { secretsContextDefaultValues } from "tests/defaultValues";
// import { ToastContext } from "./ToastContext";

interface ISecretsContext {
  secrets: Array<Secret>;
  setup: (setupParams: SetupType[]) => Promise<void>;
  clear: () => void;
  getSecretsByHierarchy: (type: HierarchyType) => Array<Secret>;
  createSecret: (args: CreateSecretReqBody) => Promise<Secret | null>;
  updateSecret: (
    id: Secret["id"],
    updateParam: {
      name?: string;
      src?: SecretReqSrc;
      dest?: SecretReqDest;
    }
  ) => Promise<Secret | null>;
  deleteSecret: (id: Secret["id"]) => Promise<Secret["id"] | null>;
}

export const SecretsContext = React.createContext<ISecretsContext>(
  secretsContextDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const SecretsContextProvider: React.FC<Props> = ({ children }) => {
  const [secrets, setSecrets] = useState<Secret[]>([]);
  // const toastContext = useContext(ToastContext);

  const setup = async (setupParams: SetupType[]): Promise<void> => {
    const tempSecrets: Secret[] = [];
    let hadError = false;
    setupParams.forEach(async (sp) => {
      const res = await agent.Secrets.get(sp.type, sp.ID);
      if (res.success) {
        const secretsObjects = (res.data || []).map(
          (sRes) => new Secret(sRes.secret, sRes.value)
        );
        // setSecrets(secretsObjects);
        tempSecrets.push(...secretsObjects);
      } else {
        hadError = true;
        console.error("Error in loadSecrets: ", res.message);
        // toastContext.showToast("Unable to load secrets", "info");
      }
      if (!hadError) {
        setSecrets(tempSecrets);
      }
    });
  };

  const clear = () => setSecrets([]);

  const getSecretsByHierarchy = (type: HierarchyType): Array<Secret> =>
    secrets.filter((s) => s.hierarchyType === type);

  const createSecret = async (
    args: CreateSecretReqBody
  ): Promise<Secret | null> => {
    const res = await agent.Secrets.create(args);
    if (res.success && res.data) {
      const secretObject = new Secret(res.data);
      setSecrets([secretObject, ...secrets]);
      return secretObject;
    }
    console.error("Error in createSecret: ", res.message);
    // toastContext.showToast("Error creating secret, try again!", "error");
    return null;
  };

  const updateSecret = async (
    id: Secret["id"],
    updateParam: {
      name?: string;
      src?: SecretReqSrc;
      dest?: SecretReqDest;
    }
  ): Promise<Secret | null> => {
    const args: UpdateSecretReqBody = {
      id,
      ...(updateParam.name ? { name: updateParam.name } : {}),
      ...(updateParam.src ? { src: updateParam.src } : {}),
      ...(updateParam.dest ? { dest: updateParam.dest } : {}),
    };
    const res = await agent.Secrets.update(args);
    if (res.success && res.data) {
      const index = secrets.findIndex((s) => s.id === id);
      if (index === -1) {
        console.error(
          "Error in updateSecretName, secret did not exist in the first place."
        );
        return null;
      }
      const newSecretsList = secrets.filter((s) => s.id !== id);
      const updatedSecretObj = new Secret(res.data, res.data.src.config.value);
      newSecretsList.splice(index, 0, updatedSecretObj);
      setSecrets(newSecretsList);
      return updatedSecretObj;
    }
    console.error("Error in updateSecretName: ", res.message);
    // toastContext.showToast("Error updating secret, try again!", "error");
    return null;
  };

  const deleteSecret = async (
    id: Secret["id"]
  ): Promise<Secret["id"] | null> => {
    const res = await agent.Secrets.delete(id);
    if (res.success && res.data) {
      const newSecretsList = secrets.filter((s) => s.id !== id);
      setSecrets(newSecretsList);
      return res.data.id;
    }
    console.error("Error in updateSecretName: ", res.message);
    // toastContext.showToast("Error deleting secret, try again!", "error");
    return null;
  };

  const providerData = {
    secrets,
    setup,
    clear,
    getSecretsByHierarchy,
    createSecret,
    updateSecret,
    deleteSecret,
  };

  return (
    <SecretsContext.Provider value={providerData}>
      {children}
    </SecretsContext.Provider>
  );
};

export default SecretsContextProvider;
