import * as React from "react";
import { styled, alpha, Box } from "@mui/system";
import { Slider as BaseSlider, sliderClasses } from "@mui/base/Slider";

interface DiscreteSliderMarks {
  value: number;
  label: string;
}

interface DiscreteSliderProps {
  marks?: DiscreteSliderMarks[];
  value: number | number[];
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number | null;
}

const BrevSlider: React.FC<DiscreteSliderProps> = ({
  marks,
  value,
  onChange,
  min,
  max,
  step,
}) => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Slider
        onChange={handleChange}
        aria-label="Temperature"
        value={value}
        getAriaValueText={valuetext}
        step={step}
        marks={marks}
        min={min}
        max={max}
      />
    </Box>
  );
};

export default BrevSlider;

function valuetext(value: number) {
  return `${value}°C`;
}

const Slider = styled(BaseSlider)(
  () => `
  color: var(--color-highlight);
  height: 6px;
  width: 100%;
  padding: 16px 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  
  &.${sliderClasses.disabled} {
    pointer-events: none;
    cursor: default;
    color: #fff;
    opacity: 0.4;
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
    opacity: 0.3;
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
  }

  & .${sliderClasses.thumb} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -6px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    background-color: var(--color-highlight);
    transition-property: box-shadow, transform;
    transition-timing-function: ease;
    transition-duration: 120ms;
    transform-origin: center;
    background-color: white;

    &:hover {
      box-shadow: 0 0 0 1px white;
    }

    &.${sliderClasses.focusVisible} {
      outline: none;
      box-shadow: 0 0 0 1px white;
    }

    &.${sliderClasses.active} { 
      outline: none; 
      box-shadow: 0 0 0 1px white;
    }
  }

  & .${sliderClasses.mark} {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 99%;
    background-color: var(--color-highlight);
    transform: translateX(-50%);
  }

  & .${sliderClasses.markActive} {
    background-color: var(--color-highlight);
  }

  & .${sliderClasses.markLabel} { 
    font-size: 12px;
    position: absolute;
    top: 24px;
    transform: translateX(-50%);
    margin-top: 8px;
  }
`
);
