import React, { useState } from "react";
import InputField from "components/UI-lib/InputField";
import FlatCard from "components/UI-lib/FlatCard";
import Button from "components/UI-lib/Button";
import Checkbox from "components/UI-lib/Checkbox";
import { ContainerSelected } from "../EnvironmentCreate";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Registry } from "contexts/WorkspaceContext";

interface RecentImage {
  url: string;
  entrypoint: string;
}

interface CustomImageSelectorProps {
  onSubmit: (container: ContainerSelected) => void;
  hideCredentials?: boolean;
}

const CustomImageSelector: React.FC<CustomImageSelectorProps> = ({
  onSubmit,
  hideCredentials,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [entrypoint, setEntrypoint] = useState("");
  const [registry, setRegistry] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isCredentialsOpen, setIsCredentialsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );

  // Dummy data for recent images
  const recentImages: RecentImage[] = [
    { url: "docker.io/myapp:v1.2.3", entrypoint: "python app.py" },
    { url: "docker.io/myotherapp:latest", entrypoint: "node server.js" },
    { url: "docker.io/testapp:beta", entrypoint: "./start.sh" },
  ];

  const handleSubmit = () => {
    let registryObject: Registry | undefined;

    if (registry && username && password) {
      registryObject = {
        username,
        password,
        url: registry,
      };
    }

    onSubmit({
      container: imageUrl,
      customEntryPoint: entrypoint,
      isCustomContainer: true,
      registry: registryObject,
    });
  };

  const handleRecentImageClick = (index: number) => {
    if (selectedImageIndex === index) {
      // Deselect if already selected
      setSelectedImageIndex(null);
      setImageUrl("");
      setEntrypoint("");
    } else {
      // Select new image
      setSelectedImageIndex(index);
      setImageUrl(recentImages[index].url);
      setEntrypoint(recentImages[index].entrypoint);
    }
  };

  return (
    <div className="space-y-4">
      <FlatCard className="p-6">
        <div className="">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Custom Docker Image
          </h2>
          <div className="flex flex-row mb-6">
            <label className="block text-xs font-medium text-gray-700 dark:text-secondary">
              <span className="text-red-400 mr-1">*</span>Some custom containers
              may produce unexpected results if they interfere with the host
              system. Use with discretion.
            </label>
          </div>
          <InputField
            label="Docker Image URL"
            placeholder="nvidia/cuda"
            value={imageUrl}
            onChange={setImageUrl}
            errorMessage=""
            className="mb-4"
          />
          <InputField
            label="Custom Docker Entrypoint Command (optional)"
            placeholder=""
            value={entrypoint}
            onChange={setEntrypoint}
            errorMessage=""
          />
          {!hideCredentials && (
            <div className="pt-4">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => setIsCredentialsOpen(!isCredentialsOpen)}
              >
                {isCredentialsOpen ? (
                  <ChevronDownIcon className="h-5 w-5 text-gray-500 mr-2" />
                ) : (
                  <ChevronRightIcon className="h-5 w-5 text-gray-500 mr-2" />
                )}
                <h3 className="text-md font-medium text-gray-900 dark:text-white">
                  Docker Registry Credentials (Optional)
                </h3>
              </div>
              {isCredentialsOpen && (
                <div className="space-y-2 mt-3 ml-7">
                  <InputField
                    label="Registry"
                    placeholder=""
                    value={registry}
                    onChange={setRegistry}
                    errorMessage=""
                  />
                  <InputField
                    label="Username"
                    placeholder=""
                    value={username}
                    onChange={setUsername}
                    errorMessage=""
                  />
                  <InputField
                    label="Password"
                    placeholder=""
                    value={password}
                    onChange={setPassword}
                    errorMessage=""
                    type="password"
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex justify-center pt-4">
            <Button
              label="Use Image"
              onClick={handleSubmit}
              type="primary"
              className="px-6"
            />
          </div>
        </div>
      </FlatCard>
    </div>
  );
};

export default CustomImageSelector;
