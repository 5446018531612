import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

export interface EndpointCard {
  id: string;
  displayName: string;
  company: string;
  tags: string[];
  description: string;
  image: string;
  launchableUrl?: string;
}

export interface EndpointCardProps {
  id: string;
  displayName: string;
  company: string;
  tags: string[];
  description: string;
  image: string;
  selectedId: string;
  onClick?: () => void;
}

export const EndpointCard: React.FC<EndpointCardProps> = ({
  id,
  selectedId,
  displayName,
  company,
  tags,
  description,
  image,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 100); // Small delay to prevent flicker
  };

  const backgroundImageStyle = useMemo(() => {
    return {
      backgroundImage: `url(${image})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  }, [image]);

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ padding: "23px" }} // Add padding to prevent cut off
    >
      <div
        className={`min-w-[350px] max-w-[350px] transition-transform duration-300 ${
          isHovered ? "transform scale-110 z-50" : "transform scale-100 z-0"
        }`}
        onClick={() => {
          window.open("", "_blank", "noopener,noreferrer");
          onClick?.();
        }}
      >
        <div
          className={`relative min-h-[200px] rounded-md ${
            selectedId === id ? "border dark:border-emerald-500 border-4" : ""
          }`}
          style={backgroundImageStyle}
        >
          <div className="flex w-full flex-col text-white">
            {" "}
            <h1
              className="text-xl font-bold ml-3"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 1)",
              }}
            >
              {displayName}
            </h1>
            <p
              className="text-sm font-semibold ml-3 mt-1"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 1)",
              }}
            ></p>
            <span
              className="text-sm text-white font-semibold ml-3 mt-1"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 1)",
              }}
            >
              {company}
            </span>
          </div>
          <div className="absolute bottom-[0px] right-0 w-full">
            <div className="flex flex-row w-full justify-start backdrop-blur bg-white/25 rounded-b-md px-2 py-2">
              <div className="flex items-center">
                {tags.map((tag, index) => {
                  return (
                    <span
                      key={index}
                      className="text-sm text-gray-800 mr-1 font-semibold bg-white px-2 py-1 rounded-md"
                    >
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white dark:bg-zinc-900 dark:text-secondary rounded-md border ${
          selectedId === id
            ? "border-emerald-500"
            : "border-gray-500 dark:border-zinc-800"
        } transition-opacity duration-300 transform ${
          isHovered ? "opacity-100" : "opacity-0"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
        }}
      >
        <div className="flex flex-col p-4 h-full w-full cursor-pointer relative">
          <div className="flex flex-row">
            <div className="flex flex-col w-full">
              <h1 className="text-lg font-bold text-white">{displayName}</h1>
            </div>
          </div>
          <hr className="w-[100%] my-1 dark:border dark:border-zinc-800" />
          <div className="flex flex-col mt-1 overflow-y-auto">
            <p className="text-sm text-white">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
