import { IExecOptions, IStringExec } from "models/Exec.model";
import { IExecName } from "server";
import { v4 as uuid } from "uuid";

export default class StringExec implements IStringExec {
  id: string;

  type: "string";

  name: string;

  execStr: string;

  isDisabled: boolean;

  stage?: "start" | "build";

  execWorkDir?: string;

  logPath?: string;

  logArchivePath?: string;

  dependsOn?: IExecName[];

  public constructor(
    name: string | null,
    script: string | null,
    execOptions?: IExecOptions | null,
    existingExecValue?: IStringExec,
    existingExecKey?: string
  ) {
    this.id = uuid();
    this.type = "string";
    if (existingExecValue && existingExecKey) {
      this.name = existingExecKey;
      this.execStr = existingExecValue.execStr;
      this.stage = existingExecValue.stage;
      this.execWorkDir = existingExecValue.execWorkDir;
      this.isDisabled = existingExecValue.isDisabled || false;
      this.logPath = existingExecValue.logPath;
      this.logArchivePath = existingExecValue.logArchivePath;
      this.dependsOn = existingExecValue.dependsOn;
    } else {
      this.execStr = script || ""; // need a better way to load existing in
      this.name = name || "";
      if (execOptions?.stage) this.stage = execOptions.stage;
      if (execOptions?.execWorkDir) this.execWorkDir = execOptions.execWorkDir;
      if (execOptions?.isDisabled) {
        this.isDisabled = execOptions.isDisabled;
      } else {
        this.isDisabled = false;
      }
      if (execOptions?.logPath) this.logPath = execOptions.logPath;
      if (execOptions?.logArchivePath)
        this.logArchivePath = execOptions.logArchivePath;
      if (execOptions?.dependsOn) this.dependsOn = execOptions.dependsOn;
    }
  }

  public get execKey(): string {
    return this.name;
  }

  public get execValue(): IStringExec {
    const exec: IStringExec = {
      type: this.type,
      execStr: this.execStr,
      isDisabled: this.isDisabled,
    };
    if (this.stage) exec.stage = this.stage;
    if (this.execWorkDir) exec.execWorkDir = this.execWorkDir;
    if (this.logPath) exec.logPath = this.logPath;
    if (this.logArchivePath) exec.logArchivePath = this.logArchivePath;
    if (this.dependsOn) exec.dependsOn = this.dependsOn;
    return exec;
  }

  public get exec(): { [key: string]: IStringExec } {
    return { [this.execKey]: this.execValue };
  }
}
