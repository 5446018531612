import { VerbBuildStatus, WorkspaceStatus } from "models/Workspace.model";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Chip from "../Create/chips";
import { DarkModeContext } from "contexts/DarkModeContext";
import {
  CircleStackIcon,
  CloudIcon,
  CpuChipIcon,
} from "@heroicons/react/24/solid";
import { getWorkspaceGroupObject } from "../Settings/utils";
import InstanceStatusIndicator from "./InstanceStatusIndicator";
interface ConstanterStatusIndicatorProps {
  workspaceStatus: WorkspaceStatus;
  workspaceVerbBuildStatus: VerbBuildStatus;
}
const ConstanterStatusIndicator: React.FC<ConstanterStatusIndicatorProps> = (
  props
) => {
  return (
    <>
      {props.workspaceStatus !== WorkspaceStatus.Stopped &&
        props.workspaceStatus !== WorkspaceStatus.Stopping &&
        props.workspaceStatus !== WorkspaceStatus.Deleting &&
        props.workspaceStatus !== WorkspaceStatus.Failure && (
          <InstanceStatusIndicator
            workspaceStatus={
              props.workspaceStatus === WorkspaceStatus.Starting ||
              props.workspaceStatus === WorkspaceStatus.Deploying
                ? VerbBuildStatus.Pending
                : props.workspaceVerbBuildStatus || VerbBuildStatus.Pending
            }
          />
        )}
    </>
  );
};

export default ConstanterStatusIndicator;
