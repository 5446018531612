import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import { classNames, roundPriceToTwoDigits } from "components/utils";
import React, { useContext, useEffect } from "react";
import { ComputePricingObject, OrgContext } from "contexts/OrgContext";
import { GPUInstanceType, WSGWithLocations } from "./GPUTypes";

const awsCPU = [
  { type: "t3a.medium", cpu: 2, ram: 4, provider: "aws" },
  { type: "t3a.large", cpu: 2, ram: 8, provider: "aws" },
  { type: "t3a.xlarge", cpu: 4, ram: 16, provider: "aws" },
  { type: "t3a.2xlarge", cpu: 8, ram: 32, provider: "aws" },
  { type: "t3.medium", cpu: 2, ram: 4, provider: "aws" },
  { type: "t3.large", cpu: 2, ram: 8, provider: "aws" },
  { type: "t3.xlarge", cpu: 4, ram: 16, provider: "aws" },
  { type: "t3.2xlarge", cpu: 8, ram: 32, provider: "aws" },
];

const gcpCPU = [
  { type: "n1-standard-1", cpu: 1, ram: 3.75, provider: "gcp" },
  { type: "n1-standard-2", cpu: 2, ram: 7.5, provider: "gcp" },
  { type: "n1-standard-4", cpu: 4, ram: 15, provider: "gcp" },
  { type: "n1-standard-8", cpu: 8, ram: 30, provider: "gcp" },
  { type: "n1-standard-16", cpu: 2, ram: 60, provider: "gcp" },
  { type: "n1-standard-32", cpu: 2, ram: 120, provider: "gcp" },
  { type: "n1-standard-64", cpu: 4, ram: 240, provider: "gcp" },
  { type: "n1-standard-96", cpu: 8, ram: 360, provider: "gcp" },
];

export const supportedCPUInstances = [...awsCPU, ...gcpCPU];

interface CPUSelectorTableProps {
  selectedCloudName: string;
  selectedInstanceType: string;
  initialInstances: GPUInstanceType[];
  onChange: (instanceType: string) => void;
  spot: boolean;
  setSpot: (spot: boolean) => void;
}

const CPUSelectorTable: React.FC<CPUSelectorTableProps> = ({
  selectedCloudName,
  selectedInstanceType,
  onChange,
  spot,
  setSpot,
  initialInstances,
  // selectedInstance,
}) => {
  const [displayInstances, setDisplayInstances] = React.useState<any[]>([]);
  useEffect(() => {
    // We filter once agin by hardcoded instances here
    let filtered: any[] = [];
    filtered = initialInstances
      .filter(
        (instance) =>
          !!supportedCPUInstances.find((cpu) => cpu.type === instance.type)
      )
      .map((instance) => {
        return {
          type: instance.type,
          cpu: instance.vcpu,
          ram: instance.memory,
          provider: instance.workspace_groups[0].platformType.toUpperCase(),
          price: instance.base_price?.amount,
        };
      });
    setDisplayInstances(sortByPrice(filtered));
  }, [initialInstances]);

  const sortByCPUs = (list: any[]) => list.sort((a, b) => a.cpu - b.cpu);
  const sortByPrice = (list: any[]) =>
    list.sort((a, b) => Number(a.price) - Number(b.price));

  return (
    <div className="shadow-sm ring-1 ring-black ring-opacity-5 overflow-auto mt-3 max-h-[400px]">
      <table
        className="min-w-full border-separate overflow-y"
        style={{ borderSpacing: 0 }}
      >
        <thead className="bg-gray-50 dark:bg-zinc-00">
          <tr>
            <th
              scope="col"
              className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter"
            >
              <div className="group inline-flex">CPUs</div>
            </th>
            <th
              scope="col"
              className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter"
            >
              <div className="group inline-flex">RAM</div>
            </th>
            <th
              scope="col"
              className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter"
            >
              <div className="group inline-flex">Min. Price</div>
            </th>
            <th
              scope="col"
              className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter"
            >
              <div className="group inline-flex">Provider</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-zinc-900">
          {displayInstances &&
            displayInstances.map((instanceCPU: any, index) => (
              <TableRow
                selectedInstanceType={selectedInstanceType}
                onClick={(i) => onChange(i.type)}
                key={instanceCPU.type}
                index={index}
                isRowSelected={selectedInstanceType === instanceCPU.type}
                spot={spot}
                instanceForCPU={instanceCPU}
                length={displayInstances.length}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CPUSelectorTable;

interface TableRowProps {
  instanceForCPU: any;
  onClick: (i: any) => void;
  isRowSelected: boolean;
  selectedInstanceType: string;
  length: number;
  index: number;
  spot: boolean;
}

const TableRow: React.FC<TableRowProps> = ({
  instanceForCPU,
  onClick,
  isRowSelected,
  selectedInstanceType,
  length,
  index,
  spot,
}) => {
  const orgContext = useContext(OrgContext);

  return (
    <tr
      onClick={() => {
        onClick(instanceForCPU);
      }}
      className={classNames(
        "cursor-pointer",
        isRowSelected
          ? "bg-cyan-50 dark:bg-highlight"
          : "hover:bg-gray-50 dark:hover:bg-zinc-700"
      )}
    >
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary hidden sm:table-cell",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        <div className="w-15">{instanceForCPU.cpu}</div>
      </td>
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary hidden sm:table-cell",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        <div className="w-15">{instanceForCPU.ram}</div>
      </td>
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary hidden sm:table-cell",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        <div className="w-15">
          ${roundPriceToTwoDigits(instanceForCPU.price || 0).toString()}
        </div>
      </td>
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary hidden sm:table-cell",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        <div className="w-15">{instanceForCPU.provider.toUpperCase()}</div>
      </td>
    </tr>
  );
};
