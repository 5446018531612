import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { BellIcon, Bars3Icon, XCircleIcon } from "@heroicons/react/24/outline";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { OrgContext } from "contexts/OrgContext";
import LightModeLogo from "assets/img/svg/logo_light_mode.svg?react";
import DarkModeLogo from "assets/img/svg/logo_dark_mode.svg?react";
import { useHistory } from "react-router-dom";
import shaka from "assets/img/png/shaka.png";
import {
  NotificationContext,
  NotificationContextType,
} from "contexts/NotificationContext";
import { UserContext, UserContextType } from "contexts/UserContext";
import User from "../../entities/User.entity";
import DevToggle, { ProdToggle } from "components/DevToggle";
import OrgDropdown from "../OrgPages/OrgDropdown";
import OrgSettingsSlideOver from "./OrgSlideOver";
import Button from "components/UI-lib/Button";
import { DarkModeContext } from "../../contexts/DarkModeContext";
import { GlobalUserType } from "models/User.model";
import "./index.scss";
import axios from "axios";
import agent from "server";
import { createDefaultAnalyticsObject } from "components/Analytics";
import BannerNotification from "components/BannerNotification";
import { BillingContext } from "contexts/BillingContext";
import NVIDIArapids from "assets/img/png/rapids.png";
import NGCBanner2 from "assets/img/png/ngcbanner2.png";
import SolEngBanner from "assets/img/png/solengbanner.png";
import NVIDIAPA from "assets/img/png/nvidia-pa.png";
import NVIDIATMEDeepLearning from "assets/img/png/tme-deeplearning.png";
import NVIDIADevMarketing from "assets/img/png/nvidia-devmarketing.png";
import NVIDIAniminfluencer from "assets/img/png/nvidia-niminfluencers.png";
import { WorkspaceCreateContext } from "contexts/WorkspaceCreateContext";
import OneClickDeploy from "components/OneClickDeploy";
import {
  DeployState,
  OneClickDeployContext,
} from "contexts/OneClickDeployContext";
import Spinner from "components/UI-lib/Spinner";
import { AddCreditsModal } from "components/ConnectCloudsOrCardModal/AddCreditsModal";
import ConnectCloudsOrCardModal from "components/ConnectCloudsOrCardModal";
import BuildingInstancePreview from "./BuildingInstancePreview";
import BetaFeaturesModal from "components/UI-lib/BetaFeaturesModal";
import { useTheme } from "contexts/ThemeContext";

const userNavigation = [
  { name: "Your Profile", href: "/profile" },
  { name: "Support / Help Center", href: "/help" },
  { name: "Sign out", href: "/logout" },
];

const adminNavigation = [
  {
    name: "📆 Content Calendar",
    href: "https://docs.google.com/spreadsheets/d/1ds4n4G2YCgt-7QTndLBHNSk-IMg5Jr-WKqbC5YZ_n-4/edit?usp=sharing",
  },
  {
    name: "📈 Mixpanel Daily Board",
    href: "https://mixpanel.com/project/2604005/view/3142793/app/boards#id=1652147",
  },
  {
    name: "✒️ Figma Planning",
    href: "https://www.figma.com/file/gmECGaSCLadCJBYXWJUc4s/Planning?type=whiteboard&node-id=0%3A1&t=OA1DduRdUmQUh9P3-1",
  },
  {
    name: "🗺️ Figma Map",
    href: "https://www.figma.com/file/OMVmr6xiq7VlqwjJTlND2V/Mapping-it-out?type=whiteboard&node-id=0%3A1&t=uDngcVQaYbTNBdTb-1",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
  hidden?: boolean;
  chip?: NavigationItemChip;
}

type NavigationItemChip = {
  name: string;
  color?: string;
};

interface Props {
  children: React.ReactNode;
}
const Dash: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const workspaceContext = useContext(WorkspaceContext);
  const orgContext = useContext(OrgContext);
  const billingContext = useContext(BillingContext);
  const userContext = useContext(UserContext);
  const [me, setMe] = useState<User | null>(null);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("Instances");
  const notificationContext = useContext(NotificationContext);
  const [openPanel, setOpenPanel] = useState(false);
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [acceptedInvite, setAcceptedInvite] = useState(false);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [showBetaFeaturesModal, setShowBetaFeaturesModal] = useState(false);
  const { queryParams, notebookNotFound, setNotebookNotFound } = useContext(
    WorkspaceCreateContext
  );
  const bannerRef = useRef<HTMLDivElement>(null);
  const [bannerHeight, setBannerHeight] = useState(0);
  const oneClickDeployContext = useContext(OneClickDeployContext);

  const flags = {
    "show-launchables": useFeatureFlagEnabled("show-launchables"),
    "show-datapond": useFeatureFlagEnabled("show-datapond"),
    "show-deployments": useFeatureFlagEnabled("nvcf-deployments"),
  };

  // we need to adjust tabs when the org context loads
  const tabs = [
    {
      name: "Instances",
      href: `/org/${orgContext.activeOrgId}/environments`,
      current:
        history.location.pathname ===
        `/org/${orgContext.activeOrgId}/environments`,
    },
    {
      name: "Deployments",
      href: `/org/${orgContext.activeOrgId}/deployments`,
      current:
        history.location.pathname ===
        `/org/${orgContext.activeOrgId}/deployments`,
      chip: {
        name: "new",
      },
    },
    {
      name: "Launchables",
      href: `/org/${orgContext.activeOrgId}/launchables`,
      current:
        history.location.pathname ===
        `/org/${orgContext.activeOrgId}/launchables`,
    },
    {
      name: "Blueprints",
      href: `/org/${orgContext.activeOrgId}/blueprints`,
      current:
        history.location.pathname ===
        `/org/${orgContext.activeOrgId}/blueprints`,
    },
    {
      name: "Team",
      href: `/org/${orgContext.activeOrgId}/team`,
      current:
        history.location.pathname === `/org/${orgContext.activeOrgId}/team`,
    },

    // {
    //   name: "Rewards",
    //   href: `/org/${orgContext.activeOrgId}/rewards`,
    //   current:
    //     history.location.pathname === `/org/${orgContext.activeOrgId}/rewards`,
    // },
    {
      name: "Datapond",
      href: `/org/${orgContext.activeOrgId}/datapond`,
      current:
        history.location.pathname === `/org/${orgContext.activeOrgId}/datapond`,
      chip: {
        name: "new",
      },
      hidden: true, // !flags["show-datapond"], // hide until we're ready
    },
    {
      name: "Billing",
      href: `/org/${orgContext.activeOrgId}/billing`,
      current:
        history.location.pathname === `/org/${orgContext.activeOrgId}/billing`,
    },
    {
      name: "Docs",
      href: `https://brev.dev/docs/introduction/quick-start`,
      current: false, // cant ever be 'current' since its not in this app. 'current' adds a blue highlight underneath
    },
  ];
  const [navigation, setNavigation] = useState<NavigationItem[]>(tabs);

  useEffect(() => {
    if (bannerRef.current) {
      setBannerHeight(bannerRef.current.offsetHeight);
    }
  }, [billingContext.isBalanceLoading, billingContext.currentBalance]);

  useEffect(() => {
    setNavigation([
      {
        name: "Instances",
        href: `/org/${orgContext.activeOrgId}/environments`,
        current:
          history.location.pathname ===
          `/org/${orgContext.activeOrgId}/environments`,
      },
      {
        name: "Deployments",
        href: `/org/${orgContext.activeOrgId}/deployments`,
        chip: {
          name: "new",
        },
        current:
          history.location.pathname ===
          `/org/${orgContext.activeOrgId}/deployments`,
        hidden: !flags["show-deployments"], // hide until we're ready
      },
      {
        name: "Blueprints",
        href: `/org/${orgContext.activeOrgId}/blueprints`,
        current:
          history.location.pathname ===
          `/org/${orgContext.activeOrgId}/blueprints`,
        hidden: !orgContext.isEnterprise(orgContext.activeOrgId),
      },
      {
        name: "Launchables",
        href: `/org/${orgContext.activeOrgId}/launchables`,
        current:
          history.location.pathname ===
          `/org/${orgContext.activeOrgId}/launchables`,
      },
      {
        name: "Team",
        href: `/org/${orgContext.activeOrgId}/team`,
        current:
          history.location.pathname === `/org/${orgContext.activeOrgId}/team`,
      },
      {
        name: "Datapond",
        href: `/org/${orgContext.activeOrgId}/datapond`,
        current:
          history.location.pathname ===
          `/org/${orgContext.activeOrgId}/datapond`,
        chip: {
          name: "beta",
        },
        hidden: true, // !flags["show-datapond"], // hide until we're ready
      },
      {
        name: "Billing",
        href: `/org/${orgContext.activeOrgId}/billing`,
        current:
          history.location.pathname ===
          `/org/${orgContext.activeOrgId}/billing`,
      },
      {
        name: "Docs",
        href: `https://brev.dev/docs/introduction/quick-start`,
        current: false, // cant ever be 'current' since its not in this app. 'current' adds a blue highlight underneath
      },
    ]);
  }, [
    orgContext.activeOrgId,
    flags["show-launchables"],
    flags["show-datapond"],
    flags["show-deployments"],
  ]);

  useEffect(() => {
    const loadProjects = async () => {
      setLoading(true);
      const res = await workspaceContext.setup(orgContext.activeOrgId);
      if (!res.success) {
        console.log(res);
        notificationContext.showNotification(
          "Unable to get instances",
          res.message,
          "error"
        );
      }
      setLoading(false);
    };
    if (orgContext.activeOrgId.length > 0) {
      loadProjects();
    }
  }, [orgContext.activeOrgId]);

  useEffect(() => {
    if (userContext.me?.globalUserType === GlobalUserType.Admin) {
      setIsAdmin(true);
    }
  }, [userContext]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     notificationContext.showNotification(
  //       "Welcome to Brev",
  //       "This is a notification message.",
  //       "info"
  //     );
  //   }, 2000);
  // }, []);

  useEffect(() => {
    const path = history.location.pathname;
    const orgId = orgContext.activeOrgId;

    if (
      path === `/` ||
      path === `/org/${orgId}/environments` ||
      path.includes(`/org/${orgId}/environments`)
    ) {
      setActiveTab("Instances");
    } else if (
      path === `/` ||
      path === `/org/${orgId}/deployments` ||
      path.includes(`/org/${orgId}/deployments`)
    ) {
      setActiveTab("Deployments");
    } else if (path === `/org/${orgId}/blueprints`) {
      setActiveTab("Blueprints");
    } else if (path === `/org/discover`) {
      setActiveTab("Discover");
    } else if (path === `/org/${orgId}/team`) {
      setActiveTab("Team");
    } else if (path === `/org/${orgId}/billing`) {
      setActiveTab("Billing");
    } else if (
      path === `/org/${orgId}/launchables` ||
      path.includes(`/launchables`)
    ) {
      setActiveTab("Launchables");
    } else {
      setActiveTab("");
    }
  }, [history.location.pathname]);

  useEffect(() => {
    setMe(userContext.me);
  }, [userContext.isLoading, userContext.me]);

  const NavigationItems = () => (
    <>
      {orgContext.activeOrgId &&
        navigation.map((item, index) =>
          item.hidden ? null : (
            <div
              key={index}
              className={classNames(
                `nav-${item.name}`,
                activeTab === item.name
                  ? "border-highlight text-gray-900 dark:text-white dark:border-highlight"
                  : "border-transparent text-gray-500 dark:text-secondary hover:border-gray-300 dark:hover:border-zinc-700 hover:text-gray-700 dark:hover:text-white",
                "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer"
              )}
              onClick={() => {
                if (item.href.includes("https://brev.dev")) {
                  window.open("https://brev.dev", "_blank");
                } else {
                  setActiveTab(item.name);
                  history.push(item.href);
                }
              }}
            >
              <div className="flex flex-row items-center">
                <a aria-current={activeTab === item.name ? "page" : undefined}>
                  {item.name}
                </a>
                {item.chip && (
                  <div
                    className={`flex rounded ml-1 ${
                      item.chip.color || "bg-green-600"
                    } text-xs px-[5px] text-white`}
                  >
                    <span>{item.chip.name}</span>
                  </div>
                )}
              </div>
            </div>
          )
        )}
    </>
  );

  function getBannerForOrg(orgID: string) {
    switch (orgID) {
      // SOLUTIONS ENGINEERING
      case "sv8r02mpp":
        return (
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="h-[64px] overflow-hidden">
                <img
                  src={SolEngBanner}
                  alt="Solutions Engineering Banner"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        );
      // NGC
      case "80oh0obnz":
        return (
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="h-[64px] overflow-hidden">
                <img src={NGCBanner2} alt="NGC Banner 2" className="w-full" />
              </div>
            </div>
          </div>
        );
      // PRODUCT ARCHITECTURE
      case "ksaqlobp3":
        return (
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="h-[64px] overflow-hidden">
                <img src={NVIDIAPA} alt="NVIDIA PA Banner" className="w-full" />
              </div>
            </div>
          </div>
        );
      // DEV MARKETING
      case "ktj33l4xj":
        return (
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="h-[64px] overflow-hidden">
                <img
                  src={NVIDIADevMarketing}
                  alt="NVIDIA Dev Marketing Banner"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        );
      // RAPIDS
      case "6ntwl64u4":
        return (
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="h-[64px] overflow-hidden">
                <img
                  src={NVIDIArapids}
                  alt="NVIDIA RAPIDS Banner"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        );
      // NIM INFLUENCER (AMANDA SAUNDERS + KARI BRISKI)
      case "4jnn1p06f":
        return (
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="h-[64px] overflow-hidden">
                <img
                  src={NVIDIAniminfluencer}
                  alt="NVIDIA NIM Influencer Banner"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        );
      // TME Deep Learning
      case "zffbk8e0s":
        return (
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="h-[64px] overflow-hidden">
                <img
                  src={NVIDIATMEDeepLearning}
                  alt="NVIDIA TME Deep Learning"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-primaryBg z-30 fixed top-0 inset-x-0">
        {({ open }) => (
          <>
            {/* <BannerNotification
              ref={bannerRef}
              color="amber"
              message={
                <div>
                  <b>
                    Due to unprecedented traffic, some services might be less
                    responsive
                  </b>
                </div>
              }
              isOpen={true}
            /> */}
            {billingContext.billingProfile?.billing_type === "credit" ? (
              <AddCreditsModal
                show={oneClickDeployContext.showConnectCloudsOrCardModal}
                setShow={oneClickDeployContext.setShowConnectCloudsOrCardModal}
                onCancel={() => {
                  oneClickDeployContext.setShowConnectCloudsOrCardModal(false);
                  oneClickDeployContext.reset();
                }}
                setNoCardOnFile={oneClickDeployContext.setNoCardOnFile}
                noCardOnFile={oneClickDeployContext.noCardOnFile}
                onSuccess={() => {
                  oneClickDeployContext.setShowConnectCloudsOrCardModal(false);
                  oneClickDeployContext.reset();
                  //setInlineAlertSeverity("success");
                  //setInlineAlertMessage("Credit card details saved successfully!");
                }}
              />
            ) : (
              <ConnectCloudsOrCardModal
                show={oneClickDeployContext.showConnectCloudsOrCardModal}
                setShow={oneClickDeployContext.setShowConnectCloudsOrCardModal}
                onCancel={() => {
                  oneClickDeployContext.setShowConnectCloudsOrCardModal(false);
                  oneClickDeployContext.reset();
                }}
                setNoCardOnFile={oneClickDeployContext.setNoCardOnFile}
                onSuccess={() => {
                  oneClickDeployContext.setShowConnectCloudsOrCardModal(false);
                  oneClickDeployContext.reset();
                  //setInlineAlertSeverity("success");
                  //setInlineAlertMessage("Credit card details saved successfully!");
                }}
                setSelectedCloudName={
                  oneClickDeployContext.setSelectedCloudName
                }
                selectedCloudName={oneClickDeployContext.selectedCloudName}
              />
            )}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <GetLogo
                      onClick={() => {
                        // history.push("/");
                        setActiveTab("Instances");
                        history.push(`/`);
                      }}
                    />
                  </div>

                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {oneClickDeployContext.showDeploying &&
                    !oneClickDeployContext.isFinished ? (
                      <OneClickDeploy showCaret={true} />
                    ) : (
                      <>
                        <NavigationItems />
                        {!oneClickDeployContext.isFinished &&
                          !oneClickDeployContext.showDeploying && (
                            <BuildingInstancePreview />
                          )}
                      </>
                    )}
                  </div>
                </div>
                <div className="ml-6 flex items-center">
                  {/* <DevToggle> */} {/* </DevToggle> */}
                  {/* <ProdToggle>
									{!notebookNotFound &&
										location.href.includes(
											`${location.host}/notebooks`
										) && (
											<Button
												label={`Deploy Now 🚀`}
												onClick={() => {
													const qParams =
														Object.entries(
															queryParams
														)
															.map(
																([
																	key,
																	value,
																]) =>
																	`${encodeURIComponent(
																		key
																	)}=${encodeURIComponent(
																		value as string
																	)}`
															)
															.join("&");
													history.push(
														`/environment/new?${qParams}`
													);
												}}
												className="pulse-ready-button"
												type="bold"
											/>
										)}</ProdToggle> */}
                  <OrgSettingsSlideOver />
                  {/* Profile dropdown */}
                  <UserDropdownMenu
                    me={me}
                    history={history}
                    darkMode={darkMode}
                    toggleDarkMode={toggleDarkMode}
                    userContext={userContext}
                    notificationContext={notificationContext}
                    showBetaFeaturesModal={showBetaFeaturesModal}
                    setShowBetaFeaturesModal={setShowBetaFeaturesModal}
                  />
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white dark:bg-zinc-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 dark:text-secondary hover:text-gray-500 dark:hover:text-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlightLighter">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XCircleIcon
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-highlight border-highlight text-highlight"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200 dark:border-zinc-800">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={me?.profilePhotoUrl || shaka}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {me?.name}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {me?.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div style={{ marginTop: `${64 + bannerHeight}px` }}>
        {getBannerForOrg(orgContext.activeOrgId)}

        <main>
          <div className="relative">{props.children}</div>
        </main>
      </div>
    </div>
  );
};

export default Dash;

interface UserDropdownMenuProps {
  me: User | null;
  history: any;
  darkMode: boolean;
  toggleDarkMode: () => void;
  userContext: UserContextType;
  notificationContext: NotificationContextType;
  showBetaFeaturesModal: boolean;
  setShowBetaFeaturesModal: (show: boolean) => void;
}

const UserDropdownMenu: React.FC<UserDropdownMenuProps> = (props) => {
  const flagAllowEarlyAccessFeatureManagement = useFeatureFlagEnabled(
    "allow-early-access-feature-management"
  );
  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        {props.showBetaFeaturesModal && (
          <BetaFeaturesModal
            isOpen={props.showBetaFeaturesModal}
            onClose={() => props.setShowBetaFeaturesModal(false)}
          />
        )}
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-zinc-900 focus:ring-highlightLighter">
          <span className="sr-only">Open user menu</span>
          <img
            className="h-8 w-8 rounded-full"
            src={props.me?.profilePhotoUrl || shaka}
            alt=""
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-zinc-900 ring-1 ring-black ring-opacity-5 focus:outline-none"
          style={{ zIndex: 9999 }}
        >
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <a
                  onClick={() => {
                    props.history.push(item.href);
                  }}
                  className={classNames(
                    active
                      ? "text-white bg-highlight"
                      : "dark:bg-zinc-900 text-slate-400",
                    "block px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  {item.name}
                </a>
              )}
            </Menu.Item>
          ))}
          {/* <Menu.Item>
            <ModeToggle
              darkMode={props.darkMode}
              toggleDarkMode={props.toggleDarkMode}
            />
          </Menu.Item> */}
          {flagAllowEarlyAccessFeatureManagement && (
            <Menu.Item>
              <div
                className={classNames(
                  "dark:bg-zinc-900",
                  "cursor-pointer block px-4 py-2 text-sm text-gray-700 dark:text-secondary cursor-pointer"
                )}
                onClick={() => {
                  props.setShowBetaFeaturesModal(true);
                }}
              >
                ✨ Beta Features
              </div>
            </Menu.Item>
          )}
          <DevToggle>
            <Menu.Item>
              <div
                className={classNames(
                  "dark:bg-zinc-900",
                  "cursor-pointer block px-4 py-2 text-sm text-gray-700 dark:text-secondary cursor-pointer"
                )}
                onClick={async () => {
                  props.userContext.setOnboardingObjectFunc({
                    inviteAccepted: ``,
                  });
                  const res = await agent.Users.update(
                    props.userContext.me?.id || "",
                    createDefaultAnalyticsObject(props.userContext.me, {
                      inviteAccepted: ``,
                    })
                  );
                  if (res.success) {
                    // alert(
                    //   "reset onboarding status. please refresh "
                    // );
                    props.userContext.getMe();
                    props.notificationContext.showNotification(
                      "Reset onboarding status",
                      "Please refresh the page.",
                      "success"
                    );
                  }
                }}
              >
                Click to reset onboarding
              </div>
            </Menu.Item>
          </DevToggle>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

function SunIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      {...props}
    >
      <path d="M8 12.25A4.25 4.25 0 0 1 12.25 8v0a4.25 4.25 0 0 1 4.25 4.25v0a4.25 4.25 0 0 1-4.25 4.25v0A4.25 4.25 0 0 1 8 12.25v0Z" />
      <path
        d="M12.25 3v1.5M21.5 12.25H20M18.791 18.791l-1.06-1.06M18.791 5.709l-1.06 1.06M12.25 20v1.5M4.5 12.25H3M6.77 6.77 5.709 5.709M6.77 17.73l-1.061 1.061"
        fill="none"
      />
    </svg>
  );
}

function MoonIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M17.25 16.22a6.937 6.937 0 0 1-9.47-9.47 7.451 7.451 0 1 0 9.47 9.47ZM12.75 7C17 7 17 2.75 17 2.75S17 7 21.25 7C17 7 17 11.25 17 11.25S17 7 12.75 7Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

interface ModeToggleProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
}

const ModeToggle = React.forwardRef<HTMLAnchorElement, ModeToggleProps>(
  ({ darkMode, toggleDarkMode }, ref) => {
    document.documentElement.classList.add("[&_*]:!transition-none");
    window.setTimeout(() => {
      document.documentElement.classList.remove("[&_*]:!transition-none");
    }, 0);

    return (
      <a
        aria-label="Toggle dark mode"
        // className="group rounded-full bg-white/90 px-3 py-2 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20"
        className="block px-4 py-2 text-sm text-gray-700 dark:text-secondary cursor-pointer flex flex-row justify-start items-center  "
        onClick={toggleDarkMode}
      >
        <SunIcon className="h-6 w-6 fill-zinc-100 stroke-zinc-500 transition group-hover:fill-zinc-200 group-hover:stroke-zinc-700 dark:hidden [@media(prefers-color-scheme:dark)]:fill-teal-50 [@media(prefers-color-scheme:dark)]:stroke-teal-500 [@media(prefers-color-scheme:dark)]:group-hover:fill-teal-50 [@media(prefers-color-scheme:dark)]:group-hover:stroke-teal-600" />
        <MoonIcon className="hidden h-6 w-6 fill-zinc-900 stroke-cyan-300 transition dark:block [@media(prefers-color-scheme:dark)]:group-hover:stroke-zinc-400 [@media_not_(prefers-color-scheme:dark)]:fill-teal-400/10 [@media_not_(prefers-color-scheme:dark)]:stroke-teal-500" />
        {`Toggle ${darkMode === true ? "light" : "dark"} mode`}
      </a>
    );
  }
);

interface UIVersionProps {
  newUI: boolean;
  toggleNewUI: () => void;
}

const UIVersionToggle = React.forwardRef(
  (props: UIVersionProps, ref: React.Ref<HTMLAnchorElement>) => {
    return (
      <a
        ref={ref}
        aria-label="Toggle UI Version"
        className="block px-4 py-2 text-sm text-gray-700 dark:text-secondary cursor-pointer flex flex-row justify-start items-center  "
        onClick={props.toggleNewUI}
      >
        {`New UI: ${props.newUI ? "enabled ✅" : "disabled ❌"}`}
      </a>
    );
  }
);

interface GetLogoProps {
  onClick: () => void;
}

export const GetLogo: React.FC<GetLogoProps> = ({ onClick }) => {
  const logoRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const animateLogo = () => {
    const logo = logoRef.current;
    if (logo) {
      logo.animate(
        [
          { transform: "rotate(0deg)" },
          { transform: "rotate(68deg)" },
          { transform: "rotate(0deg)" },
        ],
        {
          duration: 500, // make the animation faster
          easing: "cubic-bezier(.5, 0, .5, 1)",
          fill: "forwards",
        }
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      animateLogo();
    }, 75);
  }, []);

  return (
    <div
      ref={logoRef}
      className="flex justify-center"
      onClick={() => {
        animateLogo();
        onClick();
      }}
    >
      <img
        src={theme.theme.logo}
        style={{
          height: theme.theme.logoHeight,
          width: theme.theme.logoWidth,
        }}
      />
    </div>
  );
};
