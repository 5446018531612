import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { BillingContext } from "contexts/BillingContext";
import useQueryParam from "useQueryParams";
import LegacyBilling from "./Legacy-usage-arrears/LegacyBilling";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import CreditsBillingPage from "./CreditBillingPage";
import EndpointsBillingPage from "./EndpointsBillingPage";

interface ParamTypes {
  orgId: string;
}

interface LocationState {
  segment?: string;
}

export const OrgBillingPageRedirect: React.FC = () => {
  console.log("Route: /org/:orgId/billing");

  const router = useHistory();
  const orgContext = useContext(OrgContext);
  const [withCoupon, setWithCoupon] = useQueryParam("coupon", "");
  useEffect(() => {
    router.push(`/org/${orgContext.activeOrgId}/billing?coupon=${withCoupon}`);
  }, [orgContext.activeOrgId]);

  return <></>;
};

const OrgBillingPage: React.FC = () => {
  const { state } = useLocation<LocationState>();
  const history = useHistory();
  const orgContext = useContext(OrgContext);
  const billingRef = React.useRef<null | HTMLDivElement>(null);
  const billingContext = useContext(BillingContext);
  const [withCoupon, setWithCoupon] = useQueryParam("coupon", "");

  useEffect(() => {
    document.title = "Organization Billing | Brev.dev";
    if (state?.segment === "billing") {
      billingRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const renderBillingContent = () => {
    return billingContext.billingProfile?.billing_type === "usage-arrears" ? (
      <LegacyBilling />
    ) : (
      <CreditsBillingPage />
    );
  };

  if (orgContext.activeOrg) {
    return (
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-end">
              <div className="flex flex-col">
                <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  Billing
                </h1>
                <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                  Manage payment methods, credits, and monitor your usage
                </p>
              </div>
              <div
                className="flex flex-row items-end cursor-pointer"
                onClick={() => {
                  window.location.href = "mailto:brev-support@nvidia.com";
                }}
              >
                <p className="mt-2 text-sm text-highlight">
                  <span className="mr-1">Contact us at</span>
                  <span className="underline">brev-support@nvidia.com</span>
                </p>
                <ArrowUpRightIcon className="ml-1 h-4 w-4 text-highlight mb-[3px]" />
              </div>
            </div>
            <div className="mt-6">{renderBillingContent()}</div>
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};

export default OrgBillingPage;
