/* eslint-disable react/no-unused-prop-types */
// they are being used idk they aren't beinf detected by eslint
import React, { useContext, useEffect, useState } from "react";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import {
  ArrowDownCircleIcon,
  ArrowDownOnSquareIcon,
  BookOpenIcon,
  ChartBarIcon,
  CommandLineIcon,
  CpuChipIcon,
  DocumentChartBarIcon,
  KeyIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import {
  ComputeItem,
  ContainerItem,
  NavItem,
  RightArrow,
} from "components/Environment/Settings/TabNavigator/NavItem";
import Workspace from "entities/Workspace.entity";
import { OrgContext } from "contexts/OrgContext";

interface TabNavigatorProps {
  currentState?: string;
  setCurrentState: (state: "Metrics" | "Logs" | "Code Snippets") => void;
}

const TabNavigator: React.FC<TabNavigatorProps> = ({
  currentState,
  setCurrentState,
}) => {
  const orgContext = useContext(OrgContext);

  return (
    <>
      <nav aria-label="Progress">
        <ol
          role="list"
          className="divide-y border-gray-200 dark:border-gray-700 md:flex md:divide-y-0 border flex justify-evenly"
        >
          <NavItem
            className="justify-center"
            label="Metrics"
            IconComponent={ChartBarIcon}
            onClick={() => {
              setCurrentState("Metrics");
            }}
            active={currentState === "Metrics"}
          />
          <RightArrow />
          <NavItem
            className="justify-center"
            label="Logs"
            IconComponent={DocumentChartBarIcon}
            onClick={() => {
              setCurrentState("Logs");
            }}
            active={currentState === "Logs"}
          />
          <RightArrow />
          <NavItem
            className="justify-center"
            label="Code Snippets"
            IconComponent={CommandLineIcon}
            onClick={() => {
              setCurrentState("Code Snippets");
            }}
            active={currentState === "Code Snippets"}
          />
        </ol>
      </nav>
    </>
  );
};

export default TabNavigator;
