import React, { useContext, useEffect, useState } from "react";
import Visa from "assets/img/svg/visa.svg?react";
import Amex from "assets/img/svg/amex.svg?react";
import Mastercard from "assets/img/svg/mastercard.svg?react";
import GenericCard from "assets/img/svg/genericcard.svg?react";
import GenericCardOutline from "assets/img/svg/genericcardoutline.svg?react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import Button from "components/UI-lib/Button";
import agent from "server";
import { OrgContext } from "contexts/OrgContext";
import { NotificationContext } from "contexts/NotificationContext";
import { ConnectedCard } from ".";

/* 
Visa: ^4[0-9]{6,}$ Visa card numbers start with a 4.

MasterCard: ^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$ 
Before 2016, MasterCard numbers start with the numbers 51 through 55, 
but this will only detect MasterCard credit cards; there are other cards 
issued using the MasterCard system that do not fall into this IIN range. 
In 2016, they will add numbers in the range (222100-272099).

American Express: ^3[47][0-9]{5,}$ American Express card numbers start with 34 or 37.
*/

interface CreditCardDetailsProps {
  connectedCard: ConnectedCard | null;
  isDefault?: boolean;
}

const CreditCardDetails: React.FC<CreditCardDetailsProps> = (props) => {
  const { activeOrgId } = useContext(OrgContext);
  const notificationContext = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const retryHold = async (orgId: string, payementMethodId: string) => {
    setIsLoading(true);
    const res = await agent.Billing.retryHold(orgId, payementMethodId);
    if (res.success) {
      notificationContext.showNotification("Retrying Hold", "", "success");
    } else {
      notificationContext.showNotification("Failed Retrying Hold", "", "error");
    }
    setIsLoading(false);
  };

  const getCreditCardIcon = (brand: string) => {
    switch (brand) {
      case "visa":
        return <Visa className="w-[36px] h-[36px]" />;
      case "amex":
        return <Amex className="w-[36px] h-[36px]" />;
      case "mastercard":
        return <Mastercard className="w-[36px] h-[36px]" />;
      default:
        return <GenericCard className="w-[36px] h-[36px]" />;
    }
  };

  return (
    <>
      {props.connectedCard ? (
        <dd className="mt-3 flex text-sm ml-1 flex-col min-h-[60px] min-w-[200px]">
          <div className="flex flex-row">
            <div>
              {getCreditCardIcon(props.connectedCard.brand)}
              <p className="sr-only">{props.connectedCard.brand}</p>
            </div>
            <div className="ml-4">
              <p className="text-white">
                Ending with {props.connectedCard.last4}
              </p>
              <p className="text-gray-500 dark:text-secondary">
                Expires {props.connectedCard.expiration}
              </p>
            </div>
            {props.isDefault && (
              <div className="flex text-highlight font-bold ml-2">
                <p>Selected</p>
              </div>
            )}
          </div>
          {props.connectedCard.latestPayment && (
            <div className="flex flex-row">
              <h3 className="mr-1">Status:</h3>
              {props.connectedCard.latestPayment.status === "succeeded" && (
                <div>
                  <CheckCircleIcon color="green" width={20} />
                </div>
              )}
              {props.connectedCard.latestPayment.status !== "succeeded" && (
                <div className="flex items-start">
                  <ExclamationCircleIcon color="red" width={20} />
                  <p className="text-color-600">
                    {props.connectedCard.latestPayment.message}
                  </p>
                </div>
              )}
            </div>
          )}
        </dd>
      ) : (
        <dd className="mt-3 flex text-sm ml-1">
          <div className="">
            <p className="text-gray-500 dark:text-secondary">
              No payment method connected
            </p>
          </div>
        </dd>
      )}
      <hr className="mt-2 dark:border dark:border-zinc-800" />
    </>
  );

  // return (
  //   <dl className="mt-3 grid grid-cols-1 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-5 lg:pl-8">
  //     {/* <div>
  //       <dt className="font-medium text-gray-900">Billing address</dt>
  //       <dd className="mt-3 text-gray-500">
  //         <span className="block">Nader Khalil</span>
  //         <span className="block">2261 Market St #4066</span>
  //         <span className="block">San Francisco, CA 94114</span>
  //       </dd>
  //     </div> */}
  //     <div>
  //       <dt className="font-medium text-gray-900">Payment information</dt>
  //       <dd className="mt-3 flex">
  //         <div>
  //           {getCreditCardIcon(props.connectedCard.brand)}
  //           <p className="sr-only">{props.connectedCard.brand}</p>
  //         </div>
  //         <div className="ml-4">
  //           <p className="text-gray-900">
  //             Ending with {props.connectedCard.last4}
  //           </p>
  //           <p className="text-gray-600">
  //             Expires {props.connectedCard.expiration}
  //           </p>
  //         </div>
  //       </dd>
  //     </div>
  //   </dl>
  // );
};

export default CreditCardDetails;
