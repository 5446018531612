import React, { useState, useRef, useEffect, useContext } from "react";
import Button from "components/UI-lib/Button";
import Chip from "components/Environment/Create/chips";
import "./styles.scss";
import FlatCard from "components/UI-lib/FlatCard";
import InputField from "components/UI-lib/InputField";
import {
  AddressElement,
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { DarkModeContext } from "contexts/DarkModeContext";
import DarkModeLogo from "assets/img/svg/logo_dark_mode.svg?react";
import agent, { PurchaseGiftCardReq } from "server";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import ReactCardFlip from "react-card-flip";
import Cards from "./Cards";
import UnauthenticatedDash from "components/DashboardContainer/UnauthenticatedDash";

interface GiftCardProps {
  stripePromise: any;
  options: any;
}

const GiftCard: React.FC<GiftCardProps> = (props: any) => {
  return (
    <Elements stripe={props.stripePromise} options={props.options}>
      <PurchaseButton />
    </Elements>
  );
};

const PurchaseButton: React.FC = () => {
  console.log("Route: /giftcard");

  const defaultHours = 20;
  const [tilt, setTilt] = useState({ x: 0, y: 0 }); // Initial tilt for X and Y
  const [rotation, setRotation] = useState(0); // Initial rotation
  const cardRef = useRef<HTMLDivElement>(null); // Ref for the draggable element
  const { darkMode } = useContext(DarkModeContext);
  //Gift Card Details
  const [recipientName, setRecipientName] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [hours, setHours] = useState(String(defaultHours));
  const [hoursError, setHoursError] = useState("");
  const [amount, setAmount] = useState(defaultHours * 2);
  const [message, setMessage] = useState("");

  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");

  const [panel, setPanel] = useState<"Configure" | "Complete">("Configure");

  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const reset = () => {
    setRecipientName("");
    setRecipientEmail("");
    setSenderName("");
    setSenderEmail("");
    setHours(String(defaultHours));
    setHoursError("");
    setAmount(defaultHours * 2);
    setMessage("");
    setPanel("Configure");
    setInlineAlertMessage("");
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const percentX = (e.clientX - centerX) / (rect.width / 2);
      const percentY = (e.clientY - centerY) / (rect.height / 2);
      const tiltX = percentX * 30; // Adjust tilt sensitivity here
      const tiltY = percentY * -30; // Adjust tilt sensitivity here
      setTilt({ x: tiltX, y: tiltY });
    }
  };

  const handleMouseLeave = () => {
    setTilt({ x: 0, y: 0 }); // Reset tilt to zero when mouse leaves
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      // Determine if click is on the left or right side of the center
      const spinDirection = e.clientX < centerX ? -1 : 1; // Spin left for left side click, right for right side click
      setRotation((prevRotation) => prevRotation + 360 * spinDirection); // Add 360 degrees to rotation in the direction determined by the mouse position
    }
  };

  const handleHourChange = (value: string) => {
    setHours(value);
    if (value === "") {
      setAmount(0);
      setHoursError("Hours can't be empty");
      return;
    }
    const amount = parseFloat(value);
    if (!amount || amount > 250) {
      setHoursError("Can't purchase above 250 hours");
    } else {
      setAmount(amount * 2);
      setHoursError("");
    }
  };

  const validateFields = () => {
    if (recipientName === "") {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("Please enter a recipient name");
      return false;
    }
    if (recipientEmail === "") {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("Please enter a recipient email");
      return false;
    }
    if (senderName === "") {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("Please enter a sender name");
      return false;
    }
    if (senderEmail === "") {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("Please enter a sender email");
      return false;
    }
    if (hours === "") {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("Please enter a valid number of hours");
      return false;
    }

    return true;
  };

  const handleSubmitCreditCard = async () => {
    setInlineAlertMessage("");
    setLoading(true);
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const addressElement = elements.getElement(AddressElement);
    if (!cardElement || !addressElement) {
      setLoading(false);
      return;
    }

    if (!validateFields()) {
      setLoading(false);
      return;
    }

    const { complete, value: addressValue } = await addressElement.getValue();
    if (addressValue.name === "" || addressValue.address.line1 === "") {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("Please enter a valid address");
      setLoading(false);
      return;
    }
    const res = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: {
          line1: addressValue.address.line1,
          // line2: value.address.line2,
          city: addressValue.address.city,
          state: addressValue.address.state,
          postal_code: addressValue.address.postal_code,
          country: addressValue.address.country,
        },
      },
    });

    if (res.error) {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("An error occured. Please try again");
      setLoading(false);
      return;
    }

    const purchaseGiftCard: PurchaseGiftCardReq = {
      amount: String(amount * 100),
      recipientName: recipientName,
      recipientEmail: recipientEmail,
      senderName: senderName,
      senderEmail: senderEmail,
      hours: hours,
      paymentMethod: res.paymentMethod.id,
      message: message || "Let it Rip!",
      description: `A100`,
    };

    const response = await agent.Billing.purchaseGiftCard(purchaseGiftCard);
    if (response.success) {
      console.log("Success");
      setPanel("Complete");
    } else {
      setInlineAlertSeverity("error");
      setInlineAlertMessage("An error occured. Contact Support: team@brev.dev");
    }
    setLoading(false);
  };

  return (
    <UnauthenticatedDash
      callbackUrl={""}
      notebookNotFound={false}
      isGiftCard={true}
    >
      <div className="flex justify-center items-center mt-8 flex-row md:flex-row xl:flex-row l:flex-row flex-col mb-20 mr-10 ml-10">
        {/* <div
        ref={cardRef}
        className="purchase-card bg-black min-w-[350px] md:min-w-[500px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px]  h-[300px] flex flex-col justify-center items-center rounded-xl dark:border dark:border-white mr-0 md:mr-10 xl:mr-10 l:mr-10"
        style={{
          transform: `rotateY(${tilt.x}deg) rotateX(${tilt.y}deg) rotateY(${rotation}deg)`,
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <span className="text-white text-4xl mb-4 mt-4">
          {hours === "" ? 0 : hours} hours
        </span>
        <div className="w-full px-8 flex justify-between">
          <span className="text-white">To: {recipientName}</span>
          <span className="text-white">From: {senderName}</span>
        </div>
        <Chip
          className="purchase-chip h-[175px] transition-transform duration-500"
          selected={true}
          onClick={() => {
            console.log("Purchase");
          }}
          chipName="A100"
          manufacturerName="NVIDIA"
        />
        <div className="w-full flex justify-between pr-6 pb-2 pl-6">
          <span className="text-white h-10 w-10 flex items-center text-xl">
            ${amount}
          </span>
          <DarkModeLogo className="block h-10 w-10 hidden dark:block" />
        </div>
      </div> */}
        <div
          ref={cardRef}
          className="purchase-card bg-black min-w-[350px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px] h-[300px] flex flex-col justify-center items-center rounded-xl dark:border dark:border-white mr-0 md:mr-10 xl:mr-10 l:mr-10"
        >
          <Cards className="w-full h-full" label={hours + " HOURS"} />
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full mt-10 flex-col">
            <ReactCardFlip
              isFlipped={panel === "Complete"}
              flipDirection="horizontal"
            >
              <>
                {panel === "Configure" && (
                  <>
                    <FlatCard className="mr-5">
                      <div className="text-white mb-3 flex items-baseline">
                        Gift Card Details:{" "}
                      </div>
                      <div>
                        <div className="flex  xl:flex-row l:flex-row flex-col mt-4">
                          <InputField
                            className="mr-4 w-1/2"
                            key={1}
                            label="To:"
                            required
                            // helperText="Enter the full URL of the Github repo"
                            value={recipientName}
                            errorMessage=""
                            placeholder="Anyone"
                            onChange={setRecipientName}
                          />
                          <InputField
                            className="w-1/2 mt-4 xl:mt-0 l:mt-0"
                            key={2}
                            label="Recipient Email:"
                            required
                            // helperText="Enter the full URL of the Github repo"
                            value={recipientEmail}
                            errorMessage=""
                            placeholder="anyone@email.com"
                            onChange={setRecipientEmail}
                          />
                        </div>
                        <div className="flex flex-row mt-4">
                          <InputField
                            className="mr-4 w-1/2"
                            key={2}
                            label="From:"
                            required
                            // helperText="Enter the full URL of the Github repo"
                            value={senderName}
                            errorMessage=""
                            placeholder="You"
                            onChange={setSenderName}
                          />
                        </div>
                        <InputField
                          className="mt-4"
                          key={3}
                          required
                          label="Hours:"
                          value={hours}
                          onChange={(e) => handleHourChange(e)}
                          errorMessage={hoursError}
                          onKeyDown={(e) => {
                            if (
                              !/[0-9\b]/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span className="text-xs font-medium text-gray-700 dark:text-secondary">
                          *1 hour = $2.00
                        </span>
                        <InputField
                          className="mt-4"
                          key={4}
                          label="Message"
                          // helperText="Enter the full URL of the Github repo"
                          value={message}
                          errorMessage=""
                          placeholder="Let it Rip!"
                          onChange={setMessage}
                        />
                      </div>
                    </FlatCard>
                    <FlatCard className="mt-3">
                      <div className="text-white mb-3">Payment: </div>
                      <CardElement
                        options={{
                          style: {
                            base: {
                              color: darkMode ? "#cffafe" : "black",
                            },
                          },
                          classes: {
                            base: "rounded-md border-gray-300 dark:border-zinc-800 shadow-sm p-3 sm:text-sm stripe-border w-1/2",
                            focus: "border-highlight ring-cyan-500",
                          },
                          hidePostalCode: false,
                        }}
                      />
                      <AddressElement
                        className="mt-4 w-1/2"
                        options={{
                          mode: "billing",
                          fields: {},
                          display: {},
                        }}
                      />
                      <InputField
                        className="mt-4 w-1/2"
                        key={5}
                        label="Billing Email:"
                        required
                        // helperText="Enter the full URL of the Github repo"
                        value={senderEmail}
                        errorMessage=""
                        placeholder="your@email.com"
                        onChange={setSenderEmail}
                      />
                    </FlatCard>
                    <div className="flex flex-col justify-center">
                      <div className="mt-5">
                        <InlineNotification
                          show={!!inlineAlertMessage}
                          severity={inlineAlertSeverity}
                          className="w-full mb-2"
                          text={inlineAlertMessage}
                          autoClose={false}
                          onClose={() => {
                            setInlineAlertMessage("");
                            setInlineAlertSeverity("error");
                          }}
                        />
                      </div>
                      <Button
                        type="primary"
                        label={`Purchase $${amount} Gift Card`}
                        className="mt-4 flex justify-center"
                        onClick={() => {
                          handleSubmitCreditCard();
                        }}
                        loading={loading}
                      />
                    </div>
                  </>
                )}
              </>
              <>
                {panel === "Complete" && (
                  <>
                    <FlatCard>
                      <div className="text-white mb-3">
                        Gift Card Purchased!
                      </div>
                      <div className="text-white mb-3">
                        You have purchased a gift card for {recipientName} for{" "}
                        {hours} hours
                      </div>
                      <div className="text-white mb-3">
                        An email has been sent to {recipientEmail} with the gift
                        card details and a receipt has been sent to{" "}
                        {senderEmail}
                      </div>
                      <Button
                        type="primary"
                        label={`Purchase Another Gift Card`}
                        className="mt-4 flex justify-center"
                        onClick={() => {
                          reset();
                          setPanel("Configure");
                        }}
                        loading={loading}
                      />
                    </FlatCard>
                  </>
                )}
              </>
            </ReactCardFlip>
          </div>
        </div>
      </div>
    </UnauthenticatedDash>
  );
};
export default GiftCard;
