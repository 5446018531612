import React, { useContext, useEffect, useMemo } from "react";
import { RegionWithWorkspaceGroup } from "server";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import { OrgContext } from "contexts/OrgContext";
import { GPUInstanceType } from "./GPUTypes";
import { CPUTypes } from "components/utils";
import { supportedCPUInstances } from "./CPUSelectorTable";

interface GPUInstanceAttributeProps {
  instanceType: string;
  isGPUInstance: boolean;
  vcpu?: number;
  memory?: string | number;
  gpuManufacturer?: string;
  gpuName?: string;
  gpuMemory?: string;
  gpuCount?: number;
}

export const InstanceAttribute: React.FC<GPUInstanceAttributeProps> = ({
  instanceType,
  isGPUInstance,
  vcpu,
  memory,
  gpuManufacturer,
  gpuName,
  gpuMemory,
  gpuCount,
}) => {
  if (!vcpu || !memory) {
    return <span className="text-sm flex mr-3">{instanceType}</span>;
  }

  return (
    <div className="w-max">
      {!isGPUInstance ? (
        <div>
          <span className="text-sm flex mr-3">
            {`${vcpu || ""} cpu x ${memory || ""} gb ram`} ({instanceType})
          </span>
        </div>
      ) : (
        <div>
          <>
            <span className="text-sm flex mr-3">
              {`${gpuManufacturer} ${gpuName} (${gpuMemory})`}
            </span>
            <span className="text-xs flex mr-3">
              {gpuCount} GPUs x {vcpu} CPUs | {memory}
            </span>
          </>
        </div>
      )}
    </div>
  );
};
