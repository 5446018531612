import ConstanterStatusIndicator from "components/Environment/shared/ContainerStatusIndicator";
import InstanceStatusIndicator from "components/Environment/shared/InstanceStatusIndicator";
import Workspace from "entities/Workspace.entity";
import { VerbBuildStatus } from "models/Workspace.model";
import React, { useState } from "react";

interface NavItemProps {
  active: boolean;
  IconComponent: any;
  label: string;
  onClick?: () => void;
  className?: string;
  workspace?: Workspace | null;
}

export const ComputeItem: React.FC<NavItemProps> = ({
  IconComponent,
  label,
  onClick,
  className,
  active,
  workspace,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <li
      className={`${className} relative flex w-[200px] cursor-pointer`}
      onClick={onClick ? onClick : () => {}}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span
        className={`flex items-center px-6 py-4 text-sm font-medium ${
          hover || active ? "text-highlight" : "text-gray-400 dark:text-white"
        }`}
      >
        <span className="flex flex-col items-center justify-center">
          <span className="flex flex-row">
            <IconComponent color={hover || active ? "" : "white"} width={20} />
            <span className="ml-2 text-sm font-medium">{label}</span>
            {workspace && (
              <div className="flex flex-row items-center justify-center ml-4">
                <InstanceStatusIndicator workspaceStatus={workspace.status} />
              </div>
            )}
          </span>
        </span>
      </span>
    </li>
  );
};

export const ContainerItem: React.FC<NavItemProps> = ({
  IconComponent,
  label,
  onClick,
  className,
  active,
  workspace,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <li
      className={`${className} relative flex w-[200px] cursor-pointer`}
      onClick={onClick ? onClick : () => {}}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span
        className={`flex items-center px-6 py-4 text-sm font-medium ${
          hover || active ? "text-highlight" : "text-white"
        }`}
      >
        <span className="flex flex-col items-center justify-center">
          <span className="flex flex-row">
            <IconComponent
              color={hover || active ? "cyan" : "dark:white"}
              width={20}
            />
            <span className="ml-2 text-sm font-medium">{label}</span>
            {workspace && (
              <div className="flex flex-row items-center justify-center ml-4">
                <ConstanterStatusIndicator
                  workspaceStatus={workspace.status}
                  workspaceVerbBuildStatus={
                    workspace.verbBuildStatus || VerbBuildStatus.Unset
                  }
                />
              </div>
            )}
          </span>
        </span>
      </span>
    </li>
  );
};

export const NavItem: React.FC<NavItemProps> = ({
  IconComponent,
  label,
  onClick,
  className,
  active,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <li
      className={`${className} relative flex w-[200px] cursor-pointer`}
      onClick={onClick ? onClick : () => {}}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span
        className={`flex items-center px-6 py-4 text-sm font-medium ${
          hover || active ? "text-highlight" : "text-white"
        }`}
      >
        <span className="flex items-center justify-center">
          <IconComponent color={hover || active ? "" : "white"} width={20} />
          <span className="ml-2 text-sm font-medium">{label}</span>
        </span>
      </span>
    </li>
  );
};

export const RightArrow: React.FC = () => {
  return (
    <li className="relative flex w-5">
      <div
        className="absolute right-0 top-0 hidden h-full w-5 md:block"
        aria-hidden="true"
      >
        <svg
          className="h-full w-full text-zinc-800"
          viewBox="0 0 22 80"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 -2L20 40L0 82"
            vectorEffect="non-scaling-stroke"
            stroke="currentcolor"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </li>
  );
};
