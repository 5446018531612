import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useWindupString } from "windups";
import { useHistory } from "react-router";
import Button from "components/UI-lib/Button";
import { timeout } from "components/utils";
import "./styles.scss";
import Cards from "./Cards";
import InputField from "components/UI-lib/InputField";

interface RedeemFlowProps {
  title: string;
  description: string;
  redeemableLabel: string;
  redeemableValue: number;
  redeemFunction: () => Promise<{ success: boolean }>;
  fetchUpdatedData: () => void;
  backPath: string;
  backLabel: string;
  successMessage: string;
  errorMessage: string;
  enterCode?: string;
  setEnterCode?: (code: string) => void;
}

const RedeemableFlow: React.FC<RedeemFlowProps> = ({
  title,
  description,
  redeemableLabel,
  redeemableValue,
  redeemFunction,
  fetchUpdatedData,
  backPath,
  backLabel,
  successMessage,
  errorMessage,
  enterCode,
  setEnterCode,
}) => {
  const [label, setLabel] = useState("");
  const history = useHistory();
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [redeemError, setRedeemError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (redeemableValue !== 0) {
        setLabel(
          redeemableValue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        );
      }
    }, 1000);
  }, [redeemableValue]);

  const handleRedeem = async () => {
    setRedeemSuccess(false);
    setRedeemError(false);
    setRedeemLoading(true);
    await timeout(3000);
    const res = await redeemFunction();
    if (res.success) {
      setRedeemSuccess(true);
      fetchUpdatedData();
    } else {
      setRedeemError(true);
    }
    setRedeemLoading(false);
  };

  return (
    <div className="bg-black h-[100vh]">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-5">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center pt-8 flex-col pb-20 pr-10 pl-10 ">
            <h1 className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-8">
              {title}
            </h1>
            <div className="purchase-card min-w-[350px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px] h-[300px] flex flex-col justify-center items-center rounded-xl mr-0 md:mr-10 xl:mr-10 l:mr-10">
              <AnimatePresence mode="popLayout">
                {!redeemLoading && (
                  <motion.div
                    initial={{ rotate: "0deg", scale: 0, y: 0 }}
                    animate={{
                      rotate: "360deg",
                      scale: 1,
                      y: [0, 150, -150, -150, 0],
                      x: [0, 0, 0, 0, 0],
                    }}
                    exit={{ rotate: "0deg", scale: 0, y: 0 }}
                    transition={{
                      duration: 1,
                      ease: "backInOut",
                      times: [0, 0.25, 0.5, 0.85, 1],
                    }}
                    style={{ display: "flex" }}
                  >
                    <Cards
                      className="min-w-[350px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px] h-[300px] flex flex-col justify-center items-center rounded-xl mr-0 md:mr-10 xl:mr-10 l:mr-10"
                      label={label}
                    />
                  </motion.div>
                )}
                {redeemLoading && (
                  <motion.div
                    initial={{ rotate: "0deg", scale: 0 }}
                    animate={{
                      scale: [1, 1.1, 1.2, 1.15, 1.05, 1],
                      rotate: [0, 15, -10, 10, -5, 0],
                    }}
                    transition={{
                      duration: 1,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.4, 0.6, 0.8, 1],
                      repeat: Infinity,
                    }}
                    style={{ display: "flex" }}
                  >
                    <Cards
                      className="min-w-[350px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px] h-[300px] flex flex-col justify-center items-center rounded-xl mr-0 md:mr-10 xl:mr-10 l:mr-10"
                      label={label}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <AnimatePresence mode="popLayout">
              {!redeemSuccess && !redeemError && (
                <InfoCard text={description} />
              )}
              {redeemSuccess && <SuccessText message={successMessage} />}
              {redeemError && <ErrorCard text={errorMessage} />}
            </AnimatePresence>
            <div className="text-sm mt-4 purchase-card min-w-[350px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px] h-[100px] flex flex-col justify-center items-center rounded-xl mr-0 md:mr-10 xl:mr-10 l:mr-10">
              {setEnterCode ? (
                <>
                  <InputField
                    label="Enter Code"
                    value={enterCode as string}
                    onChange={(n) => setEnterCode(n)}
                    errorMessage={""}
                    className="min-w-[250px]"
                  />
                  <div>
                    <Button
                      type="primary"
                      label={`Redeem ${redeemableLabel}`}
                      className="mt-4 flex justify-center"
                      onClick={() => handleRedeem()}
                      loading={redeemLoading}
                      disabled={enterCode === ""}
                    />
                    <Button
                      type="secondary"
                      label={backLabel}
                      className="mt-4 ml-4 flex justify-center"
                      onClick={() => history.push(backPath)}
                    />
                  </div>
                </>
              ) : (
                <div>
                  {!redeemSuccess && (
                    <Button
                      type="primary"
                      label={`Redeem GPU Credits`}
                      className="mt-4 flex justify-center"
                      onClick={() => handleRedeem()}
                      loading={redeemLoading}
                    />
                  )}
                  <Button
                    type="secondary"
                    label={backLabel}
                    className="mt-4 ml-4 flex justify-center"
                    onClick={() => history.push(backPath)}
                  />
                </div>
              )}
            </div>
            <p className="text-sm text-gray-700 dark:text-secondary mt-4 font-mono"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoCard: React.FC<{ text: string }> = ({ text }) => (
  <div className="text-sm text-gray-700 dark:text-white mt-4 font-mono purchase-card min-w-[350px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px] h-[75px] flex flex-col justify-center items-center rounded-xl mr-0 md:mr-10 xl:mr-10 l:mr-10">
    {text}
  </div>
);

const ErrorCard: React.FC<{ text: string }> = ({ text }) => (
  <div className="text-sm text-rose-500 dark:text-rose-500 mt-4 font-mono purchase-card min-w-[350px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px] h-[75px] flex flex-col justify-center items-center rounded-xl mr-0 md:mr-10 xl:mr-10 l:mr-10">
    {text}
  </div>
);

const SuccessText: React.FC<{ message: string }> = ({ message }) => {
  const [text] = useWindupString(message);

  return (
    <div className="text-sm text-amber-400 dark:text-amber-400 mt-4 font-mono purchase-card min-w-[350px] md:min-w-[500px] xl:min-w-[500px] l:min-w-[500px] h-[75px] flex flex-col justify-center items-center rounded-xl mr-0 md:mr-10 xl:mr-10 l:mr-10">
      {text}
    </div>
  );
};

export default RedeemableFlow;
