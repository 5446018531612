/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "contexts/UserContext";
import Workspace from "../../../../../../entities/Workspace.entity";
import agent from "server";
import CopyField from "components/UI-lib/CopyField";
import FlatCard from "components/UI-lib/FlatCard";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { useAuth0 } from "@auth0/auth0-react";
import TunnelSection from "../Tunnels/TunnelSection";
import { Capability } from "contexts/OrgContext";
import AddBadgeToReadme from "./AddBadgeToReadme";
import {
  LinuxCliInstallInstructions,
  MacCliInstallInstructions,
  WindowsCliInstallInstructions,
} from "./CLIInstallationInstructions";
import { isKasAuthFlow } from "server/kas/utils";

const getOS = (): string => {
  if (navigator.userAgent.indexOf("Win") != -1) return "windows";
  if (navigator.userAgent.indexOf("Mac") != -1) return "darwin";
  if (navigator.userAgent.indexOf("Linux") != -1) return "linux";
  if (navigator.userAgent.indexOf("Android") != -1) return "android";
  if (navigator.userAgent.indexOf("like Mac") != -1) return "ios";
  return "unknown";
};
interface OpenEnvironmentProps {
  workspace: Workspace;
  workspaceCapabilities: Capability[];
}
const enableTokenLogin = true;

const OpenEnvironment: React.FC<OpenEnvironmentProps> = (props) => {
  const userContext = useContext(UserContext);
  const [inlineAlertMessage, setInlineAlertMessage] = useState(
    "You'll need to install the Brev CLI to open your instance"
  );
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("warning");
  const [cmdCopied, setCmdCopied] = useState(false);
  const [cmd, setCmd] = useState(
    "brew install brevdev/homebrew-brev/brev && brev login"
  );
  const [visibleCmd, setVisibleCmd] = useState(
    "brew install brevdev/homebrew-brev/brev && brev login --token *****"
  );
  const [revealToken, setRevealToken] = useState(false);
  const { getAccessTokenSilently } = useAuth0?.();
  const os = getOS();
  const toggleTokenVisibility = () => {
    setRevealToken(!revealToken);
  };
  useEffect(() => {
    if (!isKasAuthFlow) {
      getAccessTokenSilently().then((t) => {
        // KAS auth flow won't support token login atm
        if (enableTokenLogin && !isKasAuthFlow) {
          setCmd(
            `brew install brevdev/homebrew-brev/brev && brev login --token ${t}`
          );
        } else {
          setCmd(`brew install brevdev/homebrew-brev/brev && brev login`);
        }
      });
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (revealToken) {
      setVisibleCmd(cmd);
    } else {
      setVisibleCmd(
        "brew install brevdev/homebrew-brev/brev && brev login --token *****"
      );
    }
  }, [revealToken]);

  return (
    <div className="flex flex-col justify-start items-start">
      <h1 className="text-xl mt-5 mb-3 font-medium text-primary dark:text-white">
        Install the CLI
      </h1>
      <FlatCard className="mb-3" noBorder={true}>
        <h4 className="mb-1 text-primary">Run this in your terminal</h4>{" "}
        <code>
          {os === "windows" && (
            <CopyField
              value={`sudo bash -c "$(curl -fsSL https://raw.githubusercontent.com/brevdev/brev-cli/main/bin/install-latest.sh)" && brev login`}
              style={{ marginBottom: "2px" }}
              analytics={{
                userid: props.workspace.createdByUserId,
                eventLabel: "clicked CLI install steps",
              }}
            />
          )}

          {os === "linux" && (
            <CopyField
              value={`sudo bash -c "$(curl -fsSL https://raw.githubusercontent.com/brevdev/brev-cli/main/bin/install-latest.sh)" && brev login`}
              style={{ marginBottom: "2px" }}
              analytics={{
                userid: props.workspace.createdByUserId,
                eventLabel: "clicked CLI install steps",
              }}
            />
          )}

          {os !== "windows" && os !== "linux" && (
            <MacCliInstallInstructions
              workspace={props.workspace}
              enableTokenLogin={enableTokenLogin}
            />
          )}
        </code>
      </FlatCard>
      <h1 className="text-xl mb-3 font-medium text-primary dark:text-white">
        Open a terminal locally
      </h1>
      <FlatCard className="mb-3" noBorder={true}>
        <h4 className="mb-1 text-primary">Run this in your terminal</h4>{" "}
        <code>
          <CopyField
            value={`brev shell ${props.workspace.name}`}
            style={{ marginBottom: "2px" }}
            analytics={{
              userid: props.workspace.createdByUserId,
              eventLabel: "brev shell",
            }}
          />
        </code>
      </FlatCard>

      <h1 className="text-xl mt-5 mb-3 font-medium text-primary dark:text-white">
        Open VSCode locally
      </h1>
      <FlatCard className="mb-3" noBorder={true}>
        <h4 className="mb-1 text-primary">Run this in your terminal</h4>{" "}
        <code>
          <CopyField
            value={`brev open ${props.workspace.name}`}
            style={{ marginBottom: "2px" }}
            analytics={{
              userid: props.workspace.createdByUserId,
              eventLabel: "brev open",
            }}
          />
        </code>
      </FlatCard>
    </div>
  );
};

export default OpenEnvironment;
