import React, { useContext, useRef, useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import Button from "components/UI-lib/Button";
import {
  CaretRightIcon,
  EmptyCircleIcon,
  DashedCircleIcon,
} from "components/Icons";
import { CheckIcon } from "@heroicons/react/24/solid";
import Spinner from "components/UI-lib/Spinner";
import { mapValuesToArray } from "components/utils";
import Workspace from "entities/Workspace.entity";
import DevToggle from "components/DevToggle";
import InfoBox from "./HoverInfoBox";
import {
  DeployState,
  OneClickDeployContext,
} from "contexts/OneClickDeployContext";
import { useHistory } from "react-router";
import { OrgContext } from "contexts/OrgContext";

export interface DeployProgressProps {
  steps: any[];
  activeStep: DeployState;
  setActiveStep?: (step: DeployState) => void;
  readyAction: () => void;
  hideViewInstance?: boolean;
  hideDeployingPreview?: boolean;
  workspace?: Workspace | undefined;
}

const DeployProgress: React.FC<DeployProgressProps> = ({
  steps,
  activeStep,
  setActiveStep,
  readyAction,
  hideViewInstance,
  hideDeployingPreview,
  workspace,
}) => {
  const [hoveredStep, setHoveredStep] = useState<number | null>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const orgContext = useContext(OrgContext);
  const history = useHistory();

  const handleMouseEnter = (index: number) => {
    // Clear any existing timeout to prevent it from hiding the InfoBox
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    // Set the hovered step immediately
    setHoveredStep(index);
  };

  // Function to start a timeout to hide the InfoBox
  const handleMouseLeave = () => {
    if (!hoverTimeoutRef.current) {
      hoverTimeoutRef.current = setTimeout(() => {
        setHoveredStep(null);
      }, 300); // 300ms delay before hiding the InfoBox
    }
  };

  // Function to clear the timeout when entering the InfoBox
  const handleInfoBoxEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
  };

  // Function to clear the timeout when entering a new list item
  const handleListItemEnter = (index: number) => {
    // Clear any existing timeout to prevent it from hiding the InfoBox
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    // Set the hovered step immediately
    setHoveredStep(index);
  };

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-gray-300 dark:divide-gray-700 rounded-md md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            onMouseEnter={() => handleListItemEnter(stepIdx)}
            onMouseLeave={handleMouseLeave}
            className="relative flex"
          >
            {activeStep === step.state ? (
              <div className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  {step.state === DeployState.Ready ? (
                    <span className="flex items-center justify-center">
                      <span className="text-sm font-medium text-gray-900 dark:text-white">
                        {`${step.name} →`} {/* Use name when active */}
                      </span>
                      <Button
                        type="primary"
                        label="Access Notebook"
                        className="ml-4"
                        onClick={() => {
                          readyAction();
                        }}
                      />
                      {!hideViewInstance && (
                        <p className="text-sm text-gray-700 dark:text-secondary font-mono ml-4">
                          <span
                            className="underline text-slate-300"
                            onClick={() => {
                              history.push(
                                `/org/${orgContext.activeOrgId}/environments/${workspace?.id}`
                              );
                            }}
                          >
                            View instance
                          </span>{" "}
                        </p>
                      )}
                    </span>
                  ) : (
                    <span className="flex items-center justify-center">
                      <Spinner type="primary" />
                      <span className="ml-4 text-sm font-medium text-gray-900 dark:text-white">
                        {step.name} {/* Use name when active */}
                      </span>
                    </span>
                  )}
                </span>
              </div>
            ) : stepIdx > steps.findIndex((s) => s.state === activeStep) ? (
              <div className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex items-center justify-center">
                    <DashedCircleIcon className="h-6 w-6 text-gray-500 dark:text-gray-500" />{" "}
                    {/* Use EmptyCircleIcon for future steps */}
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-500">
                    {step.abbreviatedName_future}
                    {/* Use abbreviatedname when not active */}
                  </span>
                </span>
              </div>
            ) : (
              <div className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex items-center justify-center">
                    <CheckIcon className="h-6 w-6 text-highlight dark:text-highlight" />{" "}
                    {/* Use CheckIcon for completed steps */}
                  </span>
                  <span className="ml-4 text-sm font-medium text-highlight dark:text-highlight">
                    {step.abbreviatedName}
                    {/* Use abbreviatedname when not active */}
                  </span>
                </span>
              </div>
            )}
            {stepIdx !== steps.length - 1 ? (
              <div
                className="absolute right-0 top-0 hidden h-full w-5 md:block"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-gray-300 dark:text-gray-700"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : null}
            {hoveredStep === stepIdx && (
              <InfoBox
                hideDeployingPreview={hideDeployingPreview}
                activeStep={activeStep}
                index={stepIdx}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleInfoBoxEnter}
                workspace={workspace}
              />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default DeployProgress;
