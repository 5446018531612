import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { classNames, memoryToGiB } from "components/utils";
import React, { useContext, useEffect, useMemo } from "react";
import Dropdown, { DropdownItem } from "./../Dropdown";
import InputField from "./../InputField";
import { StyledRangeSlider } from "./../Slider";
import DevToggle from "components/DevToggle";
import { OrgContext } from "contexts/OrgContext";
import "./AnimatedBorderDiv.scss";
import { FlatCardToggleOption } from "./FlatCardToggleOption";
import FlatCard from ".";

export type CreditOption =
  | "Purchase Credits"
  | "Redeem Code"
  | "Edit Auto-Recharge Settings"
  | "";

const options = [
  {
    name: "Purchase Credits",
  },
  {
    name: "Redeem Code",
  },
  {
    name: "Edit Auto-Recharge Settings",
  },
];

// ok so we can probably manipulate this to be like key value pairs
interface CreditToggleOptionsProps {
  selectedOption: CreditOption;
  setSelectedOption: (option: CreditOption) => void;
}

export const CreditsToggleOptions: React.FC<CreditToggleOptionsProps> = (
  props
) => {
  return (
    <>
      <div className="max-h-[600px] overflow-y-auto flex justify-between">
        <div className="py-1 flex">
          {options.map((elem) => (
            <div
              onClick={() => {
                if (props.selectedOption === elem.name) {
                  props.setSelectedOption("");
                } else {
                  props.setSelectedOption(elem.name as CreditOption);
                }
              }}
              className="mr-2 flex-grow"
            >
              <FlatCard
                noBorder={true}
                className={classNames(
                  (elem.name as CreditOption) === props.selectedOption
                    ? "border border-highlight"
                    : "border border-zinc-800",
                  "mt-2 cursor-pointer w-full text-center",
                  "px-2 py-2"
                  // "text-sm"
                )}
              >
                {elem.name}
              </FlatCard>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
