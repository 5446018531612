import React, { useContext, useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { DarkModeContext } from "contexts/DarkModeContext";
import { dump, load } from "js-yaml";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import {
  VerbConfigType,
  WorkspaceCreateContext,
} from "contexts/WorkspaceCreateContext";
import { yamlRawDump } from "components/utils";
import { validPythonVersions, validCudaVersions } from "components/Verb/utils";

interface VerbYamlWriterProps {
  verbYaml: string;
  setVerbYaml: (v: string) => void;
}

const VerbYamlWriter: React.FC<VerbYamlWriterProps> = ({
  verbYaml,
  setVerbYaml,
}) => {
  const { darkMode } = useContext(DarkModeContext);
  const [config, setConfig] = useState<VerbConfigType>(load(verbYaml));

  useEffect(() => {
    try {
      const loadedConfig = load(verbYaml);
      setConfig((prevConfig) => ({
        ...prevConfig,
        build: {
          ...prevConfig.build,
          python_version:
            loadedConfig?.build?.python_version ||
            prevConfig.build.python_version,
          cuda: loadedConfig?.build?.cuda || prevConfig.build.cuda,
        },
      }));
      // console.log()
    } catch (error) {
      // Handle the error, or do nothing to keep the initial config.
    }
  }, [verbYaml]);

  const createContext = useContext(WorkspaceCreateContext);

  const [addJupyter, setAddJupyter] = useState(true);
  const [addSSH, setAddSSH] = useState(true);
  const [showYaml, setShowYaml] = useState(false);

  function addPythonDep(version: string) {
    const updatedConfig = {
      ...config,
      build: {
        ...config.build,
        python_version: version,
      },
    };
    setConfig(updatedConfig);
    setVerbYaml(yamlRawDump(updatedConfig));
  }

  function addCudaDep(version: string) {
    const updatedConfig = {
      ...config,
      build: {
        ...config.build,
        cuda: version,
      },
    };
    setConfig(updatedConfig);
    setVerbYaml(yamlRawDump(updatedConfig));
  }

  function handleCodeMirrorChange(newYaml: string) {
    setVerbYaml(newYaml);

    try {
      const newConfig = load(newYaml);
      setConfig(newConfig);
    } catch (error) {
      // Handle invalid YAML or provide feedback to the user.
    }
  }

  // useEffect(() => {
  //   setVerbYaml(yamlRawDump(config));
  // }, [config]);

  useEffect(() => {
    console.log("this is the latest: ", config);
  }, [config]);
  useEffect(() => {
    let updatedConfig = { ...config };

    if (addJupyter || addSSH) {
      if (addSSH) {
        if (updatedConfig.user == undefined) {
          updatedConfig.user = {
            authorized_keys_path: "/home/ubuntu/.ssh/authorized_keys",
          };
        } else {
          updatedConfig.user.authorized_keys_path =
            "/home/ubuntu/.ssh/authorized_keys";
        }
        updatedConfig.user.authorized_keys_path =
          "/home/ubuntu/.ssh/authorized_keys";
        updatedConfig.ports = ["2222:22"];
      }
      if (addJupyter) {
        updatedConfig.services = [
          {
            name: "jupyter",
            entrypoint:
              "jupyter-lab --ip=0.0.0.0 --no-browser --NotebookApp.token='' --NotebookApp.password=''",
            ports: ["127.0.0.1:8888:8888"],
          },
        ];
      } else {
        if (updatedConfig.services) {
          updatedConfig.services = updatedConfig.services.filter(
            (s) => s.name !== "jupyter"
          );
        }
      }
    } else {
      // Remove the entire services object if both addJupyter and addSSH are false.
      delete updatedConfig.services;
    }

    // Check if services object is empty, and if so, remove it.
    if (updatedConfig.services && !Object.keys(updatedConfig.services).length) {
      delete updatedConfig.services;
    }

    setConfig(updatedConfig);
    setVerbYaml(yamlRawDump(updatedConfig));
  }, [addJupyter, addSSH]);

  return (
    <div>
      {/* Python Versions */}
      <p className="text-md text-grey-700 dark:text-slate-500 mb-6">
        Customize your Python, CUDA, etc. versions to install. We already
        install a recommended version in your container if you don't know.{" "}
      </p>
      <div>
        <Dropdown
          label="Python Version"
          value={config.build.python_version}
          onChange={(p) => addPythonDep(p)}
        >
          {validPythonVersions.map((p) => (
            <DropdownItem key={p} displayValue={`Python ${p}`} value={p} />
          ))}
        </Dropdown>
      </div>
      {/* CUDA Versions */}
      <div className="mt-6">
        <Dropdown
          label="CUDA Version"
          value={config.build.cuda}
          onChange={(c) => addCudaDep(c)}
        >
          {validCudaVersions.map((c) => (
            <DropdownItem key={c} displayValue={`CUDA ${c}`} value={c} />
          ))}
        </Dropdown>
      </div>
      {/* <DevToggle>
              <span
          className="text-cyan-600 hover:text-cyan-800 mt-2 font-medium cursor-pointer"
          onClick={() => {
            setShowYaml(!showYaml);
          }}
        >
          {showYaml ? "Hide" : "Show"} Yaml
        </span>
        <div className="mt-3">
          <h3 className="block text-sm font-medium text-gray-700 dark:text-secondary">
            Add Services:
          </h3>
          <div className="flex flex-row ">
            <Badge
              className={"cursor-pointer"}
              text="Jupyter Notebook"
              isActive={addJupyter}
              setIsActive={() => {
                setAddJupyter(!addJupyter);
              }}
            />
            <div className="ml-2"></div>
            <Badge
              className={"cursor-pointer"}
              text="SSH"
              isActive={addSSH}
              setIsActive={() => {
                setAddSSH(!addSSH);
              }}
            />
          </div>
        </div>
      </DevToggle> */}
      <div className="mt-[20px]">
        {/* BELOW IS COMMENTED OUT. SUPPORTING LIVE YAML EDITING IS A FEATURE AND SHOULD BE PRIORITIZED ACCORDINGLY. */}
        {/* {showYaml && (
          <>
            <p className="mt-2 mb-1 text-sm text-gray-700 dark:text-secondary">
              This is a verb.yaml file. Verb is an open source tool that creates
              a ML-optimized Docker container from a quick yaml description.
            </p>
            <CodeMirror
              theme={darkMode ? "dark" : "light"}
              value={verbYaml}
              onChange={handleCodeMirrorChange}
            />
          </>
        )} */}
        <div
          style={
            createContext.inlineAlertMessage ? { marginBottom: "10px" } : {}
          }
        >
          <InlineNotification
            show={!!createContext.inlineAlertMessage}
            severity="info"
            text={createContext.inlineAlertMessage}
            autoClose={false}
            onClose={() => createContext.setInlineAlertMessage("")}
          />
        </div>
      </div>
    </div>
  );
};

export default VerbYamlWriter;
