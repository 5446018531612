import React, { useContext, useEffect, useRef, useState } from "react";
import Spinner from "components/UI-lib/Spinner";
import Button from "components/UI-lib/Button";
import agent, { WorkspacesRes } from "server";

interface UserVerifierProps {
  userId: string;
}

const UserVerifier: React.FC<UserVerifierProps> = ({ userId }) => {
  const [verifyStatus, setVerifyStatus] = useState<
    "initial" | "verifying" | "verified" | "error"
  >("initial");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleDisableUser = () => {
    // TODO: Implement the logic for disabling the user
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        {/* <div>
        <p className="text-sm text-gray-500 dark:text-secondary font-bold mb-2">
          Disable User
        </p>
        <Button
          label="Disable User"
          type="warning"
          onClick={handleDisableUser}
          loading={false}
        />
      </div> */}
      </div>
      <div className="mt-3">
        {verifyStatus === "verifying" && <Spinner type={"secondary"} />}
        {verifyStatus === "verified" && "✅ User verified"}
        {verifyStatus === "error" && `❌ error: ${errorMessage}`}
      </div>
    </>
  );
};

export default UserVerifier;
