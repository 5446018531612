import FlatCard from "components/UI-lib/FlatCard";
import { classNames } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UsageResp, UsageTableRow } from "server";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import { BarList } from "components/Graphs/BarList";

function calc(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const EndpointsUsageTable: React.FC = () => {
  const orgContext = useContext(OrgContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const transformUsages = (usages: UsageResp[]): UsageTableRow[] => {
    const result: Record<string, UsageTableRow> = {};

    usages
      .filter((usage) => usage.UsageType === "apiInvocation")
      .forEach((usage) => {
        if (!result[usage.EnvID]) {
          result[usage.EnvID] = {
            EnvID: usage.EnvID,
            EnvName: usage.EnvName,
            ComputeCost: 0,
            StorageCost: 0,
            TotalCost: 0,
          };
        }

        result[usage.EnvID].TotalCost = parseFloat(usage.Cost || "0.00");
      });

    return Object.values(result);
  };

  const getTotalCost = () => {
    let totalCost = 0.0;
    orgContext.usage.forEach((transaction) => {
      if (transaction.Cost) {
        totalCost += parseFloat(transaction.Cost);
      }
    });
    try {
      return `${calc(totalCost).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`;
    } catch (error) {
      return totalCost;
    }
  };

  const transformedUsages = useMemo(() => {
    return transformUsages(orgContext.usage).sort((a, b) => {
      return b.TotalCost - a.TotalCost;
    });
  }, [orgContext.usage]);

  const currentUsages = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return transformedUsages.slice(startIndex, startIndex + itemsPerPage);
  }, [currentPage, transformedUsages]);

  const totalPages = Math.ceil(transformedUsages.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (orgContext.usageLoading) {
    return (
      <FlatCard
        className="w-full justify-center"
        isLoading={orgContext.usageLoading}
      >
        <div className="flex w-full justify-center">
          <span className="text-sm text-gray-700 dark:text-secondary">
            Fetching Usage Data...
          </span>
        </div>
      </FlatCard>
    );
  }

  if (currentUsages.length === 0) {
    return (
      <FlatCard className="w-full justify-center">
        <div className="flex w-full justify-center">
          <span className="text-sm text-gray-700 dark:text-secondary">
            No API Invocations With Usage Found
          </span>
        </div>
      </FlatCard>
    );
  }

  return (
    <>
      <div className="flex flex-row w-full mt-3 mb-5">
        <TopModels currentUsages={transformedUsages} />
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <TableRoot>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Model</TableHeaderCell>
              <TableHeaderCell>Total Cost</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentUsages.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary ">
                    {item.EnvID}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {item.TotalCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableRoot>
      <div className="flex justify-end mt-3 mr-5 items-center">
        <div onClick={handlePreviousPage} className="mr-3 mt-[2px]">
          <FlatCard
            noBorder={true}
            className={classNames(
              currentPage === 1 ? "hidden" : "cursor-pointer",
              "border border-gray-500 dark:border-zinc-800",
              "w-full flex items-center",
              "px-2 py-2",
              "text-xs",
              "h-7"
            )}
          >
            <span>Back</span>
          </FlatCard>
        </div>
        <span className="">{` Page ${currentPage} of ${totalPages} `}</span>
        <div onClick={handleNextPage} className="ml-3 mt-[2px]">
          <FlatCard
            noBorder={true}
            className={classNames(
              currentPage === totalPages ? "hidden" : "cursor-pointer",
              "border border-gray-500 dark:border-zinc-800",
              " w-full flex items-center",
              "px-2 py-2",
              "text-xs",
              "h-7"
            )}
          >
            <span>Next</span>
          </FlatCard>
        </div>
      </div>
    </>
  );
};

interface TopInstancesProps {
  currentUsages: UsageTableRow[];
}

const TopModels: React.FC<TopInstancesProps> = ({ currentUsages }) => {
  const getTopInstances = (costType: "TotalCost") => {
    return currentUsages
      .sort((a, b) => b[costType] - a[costType])
      .slice(0, 3)
      .map((env) => ({
        name: `${env.EnvName} (${env.EnvID})`,
        value: env[costType],
      }));
  };

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-full mr-3">
        <div className="flex flex-row text-gray-900 dark:text-white items-center mb-3">
          <h1 className="text-md font-semibold mr-1">Highest Model Cost</h1>
        </div>
        <BarList
          className="w-full"
          showAnimation={true}
          data={getTopInstances("TotalCost")}
          valueFormatter={(value) =>
            `${value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`
          }
        />
      </div>
    </div>
  );
};
