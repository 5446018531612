import React, { useState, useEffect } from "react";
import { marked, Tokens } from "marked";
import { FlatCard } from "components/UI-lib";

// Configure marked options
marked.setOptions({
  gfm: true, // GitHub Flavored Markdown
  breaks: true, // Add <br> on single line breaks
});

const renderer = {
  //Block-level renderers
  blockquote(token: Tokens.Blockquote) {
    return `<blockquote class="border-l-4 border-gray-300 pl-4 italic my-4 text-gray-200">${token.text}</blockquote>`;
  },

  code(token: Tokens.Code) {
    const className = token.lang ? `language-${token.lang}` : "";
    return `<pre class="bg-gray-800 p-4 rounded-lg overflow-x-auto my-4">
        <code class="${className} text-gray-200">${token.text}</code>
      </pre>`;
  },

  heading(token: Tokens.Heading) {
    const slug = token.text.toLowerCase().replace(/[^\w]+/g, "-");
    return `<h${token.depth} id="${slug}" class="text-2xl font-bold my-4 text-white">
        ${token.text}
      </h${token.depth}>`;
  },

  hr(token: Tokens.Hr) {
    return '<hr class="my-8 border-gray-600" />';
  },

  list(token: Tokens.List) {
    const type = token.ordered ? "ol" : "ul";
    const className = token.ordered ? "list-decimal" : "list-disc";
    const start =
      token.start && token.start !== "" ? ` start="${token.start}"` : "";
    const items = token.items.map((item) => this.listitem(item)).join("");

    return `<${type}${start} class="${className} pl-6 my-4 text-gray-200">${items}</${type}>`;
  },

  listitem(token: Tokens.ListItem) {
    const checkbox = token.task
      ? `<input type="checkbox" ${
          token.checked ? "checked" : ""
        } disabled class="mr-2" />`
      : "";
    return `<li class="mb-2">${checkbox}${token.text}</li>`;
  },

  paragraph(token: Tokens.Paragraph) {
    if (token.pre) {
      return `<pre class="my-4">${token.text}</pre>`;
    }
    return `<p class="my-4 text-gray-200">${token.text}</p>`;
  },

  table(token: Tokens.Table) {
    const createRow = (cells: Tokens.TableCell[], isHeader: boolean) => {
      const cellTag = isHeader ? "th" : "td";
      return `<tr class="border-b border-gray-700">
          ${cells
            .map((cell, i) => {
              const align = token.align[i];
              const alignClass = align ? `text-${align}` : "";
              return `<${cellTag} class="px-4 py-2 text-gray-200 ${alignClass}">${cell.text}</${cellTag}>`;
            })
            .join("")}
        </tr>`;
    };

    return `
        <div class="overflow-x-auto my-4">
          <table class="min-w-full border-collapse">
            <thead class="bg-gray-700">${createRow(token.header, true)}</thead>
            <tbody class="bg-gray-800">
              ${token.rows.map((row) => createRow(row, false)).join("")}
            </tbody>
          </table>
        </div>
      `;
  },

  space(token: Tokens.Space) {
    return token.raw;
  },

  // Inline-level renderers
  strong(token: Tokens.Strong) {
    return `<strong class="font-bold">${token.text}</strong>`;
  },

  em(token: Tokens.Em) {
    return `<em class="italic">${token.text}</em>`;
  },

  codespan(token: Tokens.Codespan) {
    return `<code class="bg-gray-700 px-1 rounded text-gray-200">${token.text}</code>`;
  },

  br(token: Tokens.Br) {
    return "<br>";
  },

  del(token: Tokens.Del) {
    return `<del class="line-through">${token.text}</del>`;
  },

  link(token: Tokens.Link) {
    const title = token.title ? ` title="${token.title}"` : "";
    return `<a href="${token.href}"${title} class="text-white hover:underline" 
        target="_blank" rel="noopener noreferrer">${token.text}</a>`;
  },

  image(token: Tokens.Image) {
    const title = token.title ? ` title="${token.title}"` : "";
    console.log("IMAGEEEEEEEEE", token);
    return `<img src="${token.href}" alt="${token.text}"${title} 
        class="max-w-full h-auto rounded-lg my-4" loading="lazy" />`;
  },

  text(token: Tokens.Text) {
    return token.escaped ? token.text : token.raw;
  },
};

marked.use({ renderer });

interface MarkdownRenderer {
  markdownUrl: string;
}

const MarkdownRenderer: React.FC<MarkdownRenderer> = ({ markdownUrl }) => {
  const [markdown, setMarkdown] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        console.log("Helllo therere");
        const response = await fetch(
          "https://us-central1-brev-testing-1.cloudfunctions.net/cors-proxy",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Add any other required headers here
            },
            body: JSON.stringify({
              url: markdownUrl,
            }),
          }
        );

        const text = await response.text();
        setMarkdown(text);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMarkdown();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">Loading...</div>
    );
  }

  if (error) {
    return <div></div>;
  }

  return (
    <FlatCard>
      <div
        className="max-w-none"
        dangerouslySetInnerHTML={{ __html: marked(markdown) }}
      />
    </FlatCard>
  );
};

export default MarkdownRenderer;
