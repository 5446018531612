import { Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import {
  classNames,
  displayPermissionHierarchyType,
  timeout,
} from "components/utils";
import { UserContext } from "contexts/UserContext";
import { PermissionHierarchyType } from "models/Permission.model";
import React, { useContext, useEffect, useState } from "react";
import { BarList } from "../../../Graphs/BarList";
import {
  EyeIcon,
  RocketLaunchIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { ArrowPathIcon, ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { FireIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router";
import LaunchableMeta from "./LaunchableMeta";
import { LaunchableContext } from "contexts/LaunchableContext";
import { OrgContext } from "contexts/OrgContext";

interface LaunchableListProps {
  viewData: { name: string; value: number }[];
  deployData: { name: string; value: number }[];
  loading: boolean;
  launchables: any[];
  leaderboardData: { name: string; value: number }[];
}

export const LaunchableOverview: React.FC<LaunchableListProps> = (props) => {
  const launchableContext = useContext(LaunchableContext);
  const orgContext = useContext(OrgContext);
  const history = useHistory();

  return (
    <>
      <FlatCard noBorder={true}>
        <div className="flex flex-col w-full min-h-[145px]">
          <div className="flex flex-row justify-between">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              Metrics
            </h1>
            <div className="flex flex-row">
              <div
                className="flex flex-row ml-3 mt-[1px] items-center cursor-pointer text-highlight"
                onClick={() => {
                  launchableContext.refreshMetrics();
                }}
              >
                <span className="text-md">Refresh</span>
                <ArrowPathIcon className="h-4 w-4 ml-1" />
              </div>
              <div className="flex flex-row ml-3">
                <Button
                  label="View All Metrics"
                  iconRight={<ArrowUpRightIcon className="ml-1 h-4 w-4" />}
                  onClick={() => {
                    history.push(
                      `/org/${orgContext.activeOrgId}/launchables/metrics`
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          <div className="flex flex-row w-full">
            <div className="w-full">
              <div className="flex flex-row text-gray-900 dark:text-white items-center">
                <h1 className="text-md font-semibold mr-1">
                  Top Viewed Launchables
                </h1>
                <EyeIcon className="h-5 w-5" />
              </div>
              {!props.loading && props.viewData.length > 0 ? (
                <div className="flex flex-row w-full mt-3">
                  <BarList
                    data={props.viewData}
                    className="w-full"
                    showAnimation={true}
                  />
                </div>
              ) : (
                <LaunchableOverviewSkeleton
                  type="view"
                  loading={props.loading}
                  launchables={props.launchables || []}
                />
              )}
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full">
              <div className="flex flex-row text-gray-900 dark:text-white items-center">
                <h1 className="text-md font-semibold mr-1">
                  Top Deployed Launchables
                </h1>
                <RocketLaunchIcon className="h-5 w-5" />
              </div>
              {!props.loading && props.deployData.length > 0 ? (
                <div className="flex flex-row w-full mt-3">
                  <BarList
                    data={props.deployData}
                    className="w-full"
                    showAnimation={true}
                  />
                </div>
              ) : (
                <LaunchableOverviewSkeleton
                  type="deploy"
                  loading={props.loading}
                  launchables={props.launchables || []}
                />
              )}
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full">
              <div className="flex flex-row text-gray-900 dark:text-white items-center">
                <h1 className="text-md font-semibold mr-1">
                  Team's Top Launchers
                </h1>
                <FireIcon className="h-5 w-5" />
              </div>
              {!props.loading && props.leaderboardData.length > 0 ? (
                <div className="flex flex-row w-full mt-3">
                  <BarList
                    data={props.leaderboardData}
                    className="w-full"
                    isUserId={true}
                    showAnimation={true}
                  />
                </div>
              ) : (
                <LaunchableOverviewSkeleton
                  type="deploy"
                  loading={props.loading}
                  launchables={props.launchables || []}
                />
              )}
            </div>
          </div>
        </div>
      </FlatCard>
    </>
  );
};

interface LaunchableOverviewSkeletonProps {
  type: "view" | "deploy";
  loading: boolean;
  launchables: any[];
}

export const LaunchableOverviewSkeleton: React.FC<
  LaunchableOverviewSkeletonProps
> = (props) => {
  const viewData1 = [
    { name: "NeMo", value: 843 },
    { name: "Triton", value: 252 },
    { name: "TensorRT", value: 25 },
  ];
  const deployData1 = [
    { name: "NeMo", value: 206 },
    { name: "Triton", value: 123 },
    { name: "TensorRT", value: 19 },
  ];

  const viewData2 = [
    { name: "Llama3", value: 439 },
    { name: "Claude", value: 234 },
    { name: "Mistral", value: 10 },
  ];
  const deployData2 = [
    { name: "Llama3", value: 206 },
    { name: "Claude", value: 113 },
    { name: "Mistral", value: 4 },
  ];

  const [shownData, setShownData] = useState(
    props.type === "view" ? viewData1 : deployData1
  );

  const animateLaunchableSkeleton = async () => {
    setShownData(props.type === "view" ? viewData2 : deployData2);
    await timeout(5000);
    setShownData(props.type === "view" ? viewData1 : deployData1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      animateLaunchableSkeleton();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="flex flex-row w-full mt-3 relative">
        <div className="absolute top-[35%] left-[35%] z-10">
          <span className="text-white font-bold">
            {props.loading ? (
              "Loading..."
            ) : (
              <>
                {props.launchables.length <= 0
                  ? "No Launchables Yet"
                  : "No Metrics Available"}
              </>
            )}
          </span>
        </div>
        <div className="opacity-25 w-full">
          <BarList data={shownData} className="w-full" showAnimation={true} />
        </div>
      </div>
    </>
  );
};
