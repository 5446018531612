import React, { useContext, useEffect, useRef, useState } from "react";
import discoverSurfboard from "assets/img/png/Discover/discover_surfboard.png";
import discoverLlama from "assets/img/png/Discover/discover_llama.png";
import Chip from "components/Environment/Create/chips";
import { classNames, fetchNotebookList } from "components/utils";
import InstanceOverview from "components/Environment/shared/InstanceOverview";
import { InstanceAttribute } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/InstanceAttribute";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { SimpleContainerOverview } from "components/Environment/shared/ContainerOverview";
import { Tooltip } from "@mui/material";
import { PreviewModal } from "./PreviewModal";
import { getLaunchableQueryParams } from "components/Environment/Launchables/Deploy/shared/utils";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import agent from "server";
import {
  getInstancePriceRate,
  getStorageCostNumber,
  parsePortMappingEntry,
} from "components/Environment/utils";
import { DiscoverCardData, PreviewData } from ".";
import { NotebookRenderContext } from "contexts/NotebookRenderContext";
import { useLaunchable } from "components/Environment/Launchables/utils";
import "./style.scss";

interface DiscoverCardProps {
  image: string;
  cardData: DiscoverCardData;
  setShowPreview: (show: boolean) => void;
  setPreviewData: (data: PreviewData) => void;
}

export const DiscoverCard: React.FC<DiscoverCardProps> = ({
  image,
  cardData,
  setShowPreview,
  setPreviewData,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [instanceObject, setInstanceObject] = useState<GPUInstanceType | null>(
    null
  );
  const [loadingInstance, setLoadingInstance] = useState(false);
  const notebookRenderContext = useContext(NotebookRenderContext);

  let queryParams = "";
  try {
    queryParams = new URL(cardData.launchable_link).search;
  } catch (error) {
    console.error("Error:", error);
  }
  const { queryLaunchableID } = getLaunchableQueryParams(queryParams);
  const { launchableData, error, loading } = useLaunchable(
    queryLaunchableID || ""
  );

  const openPreview = () => {
    setPreviewData({
      launchableData: launchableData,
      instance: instanceObject,
    });
    setShowPreview(true);
  };

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 100); // Small delay to prevent flicker
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const getInstanceFromType = async (
    instanceType: string,
    workspaceGroupID: string
  ): Promise<GPUInstanceType[] | null> => {
    const res = await agent.Instances.getInstanceByType(
      instanceType,
      workspaceGroupID
    );
    if (res.success && res.data && res.data.length > 0) {
      return res.data;
    }
    return null;
  };

  const getInstanceObject = async (
    queryInstance: string | null,
    queryWorkspaceGroupID: string | null
  ) => {
    const parsedInstance = parseInstance(queryInstance);
    if (!parsedInstance || !queryWorkspaceGroupID) {
      return null;
    }
    setLoadingInstance(true);
    const instanceTypes = await getInstanceFromType(
      parsedInstance.instanceType,
      queryWorkspaceGroupID
    );
    setLoadingInstance(false);
    if (instanceTypes) {
      setInstanceObject(instanceTypes[0]);
    }
  };

  useEffect(() => {
    getInstanceObject(
      launchableData?.instanceType || "",
      launchableData?.workspaceGroupId || ""
    );
  }, [launchableData]);

  const parseInstance = (queryInstance: string | null) => {
    if (!queryInstance) {
      return null;
    }
    const [chipTypeOrInstanceType, instanceType] = queryInstance.split("@");
    if (instanceType) {
      return {
        chipType: chipTypeOrInstanceType,
        instanceType: instanceType,
      };
    } else {
      return {
        instanceType: chipTypeOrInstanceType,
      };
    }
  };

  if (loading) {
    return (
      <div style={{ padding: "23px" }}>
        <div className={`min-w-[350px] max-w-[350px]`}>
          <div
            className="relative min-h-[200px] rounded-md shimmer opacity-60"
            style={backgroundImageStyle}
          ></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: "23px" }}>
        <div className={`min-w-[350px] max-w-[350px] relative`}>
          <span className="absolute text-red-600 top-[5px] left-[10px] font-bold">
            Error Loading
          </span>
          <div
            className="relative min-h-[200px] rounded-md opacity-60"
            style={backgroundImageStyle}
          ></div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ padding: "23px" }} // Add padding to prevent cut off
    >
      <div
        className={`min-w-[350px] max-w-[350px] transition-transform duration-300 ${
          isHovered ? "transform scale-110 z-50" : "transform scale-100 z-0"
        }`}
        onClick={() => {
          window.open(
            cardData.launchable_link || "",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      >
        <div
          className="relative min-h-[200px] rounded-md"
          style={backgroundImageStyle}
        >
          <div className="flex w-full flex-col text-white">
            {" "}
            <h1
              className="text-xl font-bold ml-3"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 1)",
              }}
            >
              {launchableData?.name || ""}
            </h1>
            <p
              className="text-sm font-semibold ml-3 mt-1"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 1)",
              }}
            >
              by Brev Team
            </p>
            {cardData.company && (
              <span
                className="text-sm text-white font-semibold ml-3 mt-1"
                style={{
                  textShadow: "0px 4px 4px rgba(0, 0, 0, 1)",
                }}
              >
                {cardData.company.toLocaleUpperCase()}
              </span>
            )}
          </div>
          <div className="absolute bottom-[0px] right-0 w-full">
            <div className="flex flex-row w-full justify-start backdrop-blur bg-white/25 rounded-b-md px-2 py-2">
              <div className="flex items-center">
                {cardData.tags.map((tag, index) => {
                  return (
                    <span
                      key={index}
                      className="text-sm text-gray-800 mr-1 font-semibold bg-white px-2 py-1 rounded-md"
                    >
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white dark:bg-zinc-900 dark:text-secondary rounded-md border border-gray-500 dark:border-zinc-800 transition-opacity duration-300 transform ${
          isHovered ? "opacity-100" : "opacity-0"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          openPreview();
        }}
      >
        <div className="flex flex-col p-4 h-full w-full cursor-pointer relative">
          <div className="flex flex-row">
            <div className="flex flex-col w-full">
              <h1 className="text-lg font-bold text-white">
                {" "}
                {launchableData?.name || ""}
              </h1>
            </div>
            <div className="flex items-center">
              <Tooltip title="View More Details" placement="top">
                <InformationCircleIcon
                  className="h-6 w-6 text-white hover:text-highlight"
                  onClick={(e) => {
                    e.stopPropagation();
                    openPreview();
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <hr className="w-[100%] my-1 dark:border dark:border-zinc-800" />
          <div className="flex flex-col mt-1 overflow-y-auto">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col">
                <h1 className="text-xs font-bold text-white mb-1">Compute</h1>
                <div className="flex flex-row items-center text-white">
                  <Chip
                    selected={true}
                    chipName={instanceObject?.supported_gpus[0].name || ""}
                    manufacturerName={
                      instanceObject?.supported_gpus[0].manufacturer || ""
                    }
                    onClick={() => {}}
                    className={classNames("h-[45px] flex-shrink-0")}
                    whiteGlow={false}
                  />
                  <div className="flex flex-col ml-2">
                    <span className="text-xs mb-1 font-semibold">
                      {instanceObject?.supported_gpus[0].manufacturer}{" "}
                      {instanceObject?.supported_gpus[0].name}
                    </span>
                    <span className="text-xs mb-1 font-semibold">
                      {"$" +
                        getInstancePriceRate(
                          Number(instanceObject?.base_price?.amount || 0),
                          Number(
                            getStorageCostNumber(
                              launchableData?.diskStorage || ""
                            ) || 0
                          ),
                          Number(
                            instanceObject?.supported_storage[0].price_per_gb_hr
                              ?.amount || 0
                          ),
                          true
                        )}
                      /hr
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="flex flex-col">
                  <h1 className="text-xs font-bold text-white mb-1">
                    Container
                  </h1>
                  <SimpleContainerOverview
                    pythonVersion={launchableData?.python || ""}
                    cudaVersion={launchableData?.cuda || ""}
                    baseImage={
                      launchableData?.containerSelected.container || ""
                    }
                    className="text-white"
                    highlight={true}
                  />
                </div>
              </div>
              {launchableData?.ports && (
                <div className="flex mt-2">
                  <div className="flex flex-col">
                    <h1 className="text-xs font-bold text-white mb-1">
                      Exposed Ports
                    </h1>
                    <div className="text-xs text-white">
                      <div className="flex flex-row">
                        <div className="flex flex-row">
                          {Object.entries(launchableData?.ports).map(
                            ([name, port], index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex flex-row items-center"
                                >
                                  <span>{name}</span>
                                  <span className="ml-[2px] mr-[2px] font-bold">
                                    :
                                  </span>
                                  <span>{port}</span>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
