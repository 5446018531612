import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import config from "config";
import { REQUEST_TIMEOUT } from "../constants";
import { isElectronApp } from "./utils";
import { useKas } from "contexts/KasContext";
import { isKasAuthFlow, KAS_TOKEN_STORAGE_KEY } from "./kas/utils";
import { rewind } from "windups/dist/Windup";

const API_ROOT = config.brevApiUrl;

const getOS = (): string => {
  if (navigator.userAgent.indexOf("Win") != -1) return "windows";
  if (navigator.userAgent.indexOf("Mac") != -1) return "darwin";
  if (navigator.userAgent.indexOf("Linux") != -1) return "linux";
  if (navigator.userAgent.indexOf("Android") != -1) return "android";
  if (navigator.userAgent.indexOf("like Mac") != -1) return "ios";
  return "unknown";
};

export const api = axios.create({
  baseURL: API_ROOT,
  timeout: REQUEST_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  params: {
    utm_source: isElectronApp() ? "electron" : "web",
    os: getOS(),
  },
});

api.interceptors.response.use(
  (response: AxiosResponse) => {
    // (200-299)
    const { data, ...resParams } = response;
    return { success: true, data, ...resParams };
  },
  (error: AxiosError) => {
    // outside of (200-299)
    const errorMessages = (error.response?.data?.errors || []).map(
      (e: Error) => e.message
    );

    console.log();
    console.log("wtf123", error);
    console.error(errorMessages);
    return { success: false, message: errorMessages.join(" ") };
  }
);

export const configureRequestInterceptors = async (
  getAccessTokenSilently: () => Promise<string | null>
) => {
  api.interceptors.request.use(async (config) => {
    const token = isKasAuthFlow
      ? localStorage.getItem(KAS_TOKEN_STORAGE_KEY)
      : await getAccessTokenSilently();
    console.log("RequestConfig", config);

    config.headers.Authorization = token ? `Bearer ${token}` : null;

    return config;
  });
};

/**
 * What we append onto the response object.
 */
export type AxiosRes =
  | {
      success: true;
    }
  | {
      success: false;
      message: string;
    };

/**
 * Base response object.
 * If status is NOT successful (200 - 299), then message appears.
 */
export type BaseRes = {
  message?: string;
};

/**
 * Base params object. Copy of core.ParamsDictionary in express.
 */
export interface BaseParams {
  [key: string]: string;
}
