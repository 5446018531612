import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import { Disclosure } from "@headlessui/react";
import { GetLogo } from "components/DashboardContainer/Dash";
import FlatCard from "components/UI-lib/FlatCard";
import Button from "components/UI-lib/Button";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { fallbackCopyTextToClipboard } from "components/utils";
import Prism from "prismjs";
import "prismjs/components/prism-bash";
import "prismjs/themes/prism-okaidia.css";
import "prismjs/components/prism-go.min.js";
import "prismjs/components/prism-rust.min.js";
import "prismjs/components/prism-python";
import { EndpointCard, EndpointCardProps } from "./Card";
import discoverSurfboard from "assets/img/png/Discover/discover_surfboard.png";
import discoverLlama from "assets/img/png/Discover/discover_llama.png";
import discoverAstro from "assets/img/png/Discover/discover_astronaut.png";
import discoverAstroLlama from "assets/img/png/Discover/discover_astro_llama.png";
import discoverLlamaLeather from "assets/img/png/Discover/discover_llama_leather.png";
import discoverLlamaVolcano from "assets/img/png/Discover/discover_llama_volcano.png";
import discoverLlamaParty from "assets/img/png/Discover/discover_llama_party.png";
import discoverBeachShack from "assets/img/png/Discover/discover_beach_shack.png";
import discoverLlamaSurf from "assets/img/png/Discover/discover_llama_surfing.png";
import discoverLlamaStars from "assets/img/png/Discover/discover_llama_stars.png";
import discoverRobotLlama from "assets/img/png/Discover/discover_robot_llama.png";
import discoverBeachBonfire from "assets/img/png/Discover/discover_beach_bonfire.png";
import discoverllamaprof from "assets/img/png/Discover/discover_llama_professor.png";
import anyscale from "assets/img/png/anyscale.png";
import togetherai from "assets/img/png/togetherai.png";
import replicate from "assets/img/png/replicate.png";
import LightModeLogo from "assets/img/svg/logo_light_mode.svg?react";
import azure from "assets/img/png/azure.png";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpLeftIcon,
  BeakerIcon,
  BoltIcon,
  CogIcon,
  CpuChipIcon,
} from "@heroicons/react/24/outline";
import agent from "server";
import { OrgContext } from "contexts/OrgContext";
import PlaygroundChat from "./PlaygroundChat";
import {
  EndpointProvider,
  EndpointProviderCard,
} from "components/UI-lib/FlatCard/FlatCardToggleOptionEndpoints";
import { InputField } from "components/UI-lib";
import { useKas } from "contexts/KasContext";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";

export const EndpointsRedirect: React.FC = () => {
  console.log("Route: /endpoints");
  const { isAuthenticated: _auth0IsAuthenticated, user: _auth0User } =
    useAuth0?.();
  const history = useHistory();

  const { isUserLoggedIn: kasIsAuthenticated, user: kasUser } = useKas();

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0User = isKasAuthFlow ? kasUser : _auth0User;

  useEffect(() => {
    if (auth0IsAuthenticated && auth0User?.email) {
      history.push(`/org/endpoints`);
    }
  }, [auth0IsAuthenticated, auth0User]);

  return (
    <div className="min-h-full">
      <Disclosure
        as="nav"
        className="bg-white dark:bg-zinc-900 border-b border-gray-200 dark:border-zinc-800"
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <GetLogo
                      onClick={() => {
                        // TODO: make this something better
                        history.push(`/login`);
                      }}
                    />
                  </div>
                </div>
                <div className="ml-6 flex items-center"></div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <div className="">
        <main>
          <div className="">
            <Endpoints />
          </div>
        </main>
      </div>
    </div>
  );
};

const Endpoints: React.FC = () => {
  console.log("Route: /org/endpoints");

  const models: EndpointCard[] = [
    {
      id: "meta/llama-3.1-405b-instruct",
      displayName: "llama3.1-405b-instruct",
      company: "meta",
      tags: ["synthetic data generation", "chat"],
      description:
        "Advanced LLM for synthetic data generation, distillation, and inference for chatbots, coding, and domain-specific tasks.",
      image: discoverLlamaSurf,
    },
    {
      id: "meta/llama-3.1-8b-instruct",
      displayName: "llama3.1-8b-instruct",
      company: "meta",
      tags: ["chat", "language generation"],
      description:
        "Advanced state-of-the-art model with language understanding, superior reasoning, and text generation.",
      image: discoverBeachBonfire,
      launchableUrl:
        "https://console.brev.dev/launchable/deploy/now?userID=p2mzt91a8&orgID=toxbnhrwu&name=llama-3_1-finetune-deploy&instance=A100%40a2-ultragpu-1g%3Anvidia-a100-80gb%3A1&diskStorage=400&cloudID=GCP&baseImage=nvcr.io%2Fnvidia%2Fnemo%3A24.05.01&file=https%3A%2F%2Fgithub.com%2Fbrevdev%2Fnotebooks%2Fblob%2Fmain%2Fllama31_law.ipynb&launchableID=env-2jeVokEK44iJZzleTF8yKjt3hh7",
    },
    {
      id: "mistralai/mistral-large-2-instruct",
      displayName: "mistral-large-2-instruct",
      company: "mistralai",
      tags: ["chat", "code generation"],
      description:
        "Advanced dense LLM with state-of-the-art reasoning, knowledge and coding capabilities.",
      image: discoverRobotLlama,
    },
    {
      id: "nvidia/nemotron-4-340b-instruct",
      displayName: "nemotron-4-340b-instruct",
      company: "nvidia",
      tags: ["synthetic data generation", "chat"],
      description:
        "Creates diverse synthetic data that mimics the characteristics of real-world data.",
      image: discoverLlamaStars,
    },
    {
      id: "mistralai/mixtral-8x22b-instruct-v0.1",
      displayName: "mixtral-8x22b-instruct-v0.1",
      company: "mistralai",
      tags: ["chat", "language generation"],
      description:
        "An MOE LLM that follows instructions, completes requests, and generates creative text.",
      image: discoverllamaprof,
    },
    {
      id: "google/gemma-2-9b-it",
      displayName: "gemma-2-9b-it",
      company: "google",
      tags: ["chat", "language generation"],
      description:
        "Cutting-edge text generation model text understanding, transformation, and code generation.",
      image: discoverLlamaVolcano,
    },
  ];
  const [selectedNim, setSelectedNim] = useState<EndpointCard>(models[0]);
  const tabs = ["Python", "Langchain", "Node", "Shell"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  const [apiKey, setApiKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const orgContext = useContext(OrgContext);
  const { isAuthenticated: _auth0IsAuthenticated, user: _auth0User } =
    useAuth0?.();

  const { isUserLoggedIn: kasIsAuthenticated, user: kasUser } = useKas();

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0User = isKasAuthFlow ? kasUser : _auth0User;

  const [isAuthed, setIsAuthed] = useState(false);
  useEffect(() => {
    if (auth0IsAuthenticated && auth0User?.email) {
      setIsAuthed(true);
    }
  }, [auth0IsAuthenticated, auth0User]);

  useEffect(() => {
    const loadApiKey = async () => {
      setIsLoading(true);
      try {
        const res = await agent.InferenceEndpoints.getApiKey({
          orgId: orgContext.activeOrgId,
        });
        if (res.success && res.data.length > 0) {
          setApiKey(res.data[0].value);
        } else {
          console.error("API key not found in response");
          setInlineAlertMessage("No API key found. Please check your account.");
          setInlineAlertSeverity("error");
        }
      } catch (error) {
        console.error("Failed to load API key:", error);
        setInlineAlertMessage("Failed to load API key. Please try again.");
        setInlineAlertSeverity("error");
      } finally {
        setIsLoading(false);
      }
    };
    if (isAuthed && orgContext.activeOrgId) {
      loadApiKey();
    }
  }, [orgContext.activeOrgId, isAuthed]);

  // Init for syntax highlighting
  useEffect(() => {
    Prism.highlightAll();
  }, [selectedNim.id, activeTab, apiKey]);

  const languageMap = {
    Shell: "python",
    Python: "python",
    Node: "python",
    Langchain: "python",
  };

  const getBashCommand = (
    id: string,
    baseUrl: string,
    apiKey: string = "[Login to Access API KEY]"
  ) => {
    if (apiKey === "") {
      apiKey = "[Login to Access API KEY]";
    }
    return `
invoke_url='${baseUrl}'
authorization_header='Authorization: Bearer ${apiKey}'
accept_header='Accept: application/json'
content_type_header='Content-Type: application/json'

data=$'{
    "messages": [
    {
        "role": "user",
        "content": "Write a limerick about the wonders of GPU computing."
    }
    ],
    "stream": true,
    "model": "${id}",
    "max_tokens": 1024,
    "presence_penalty": 0,
    "frequency_penalty": 0,
    "top_p": 0.7,
    "temperature": 0.2
}'

response=$(curl --silent -i -w "\n%{http_code}" --request POST \
    --url "$invoke_url" \
    --header "$accept_header" \
    --header "$content_type_header" \
    --data "$data"
)

echo "$response";
    `;
  };

  const getPythonCommand = (id: string, baseUrl: string, apiKey: string) => {
    if (apiKey === "") {
      apiKey = "[Login to Access API KEY]";
    }
    return `
from openai import OpenAI

client = OpenAI(
  base_url = "${baseUrl}",
  api_key = "${apiKey}"
)

completion = client.chat.completions.create(
  model="${id}",
  messages=[{"role":"user","content":"Write a limerick about the wonders of GPU computing."}],
  temperature=0.2,
  top_p=0.7,
  max_tokens=1024,
  stream=True
)

for chunk in completion:
  if chunk.choices[0].delta.content is not None:
    print(chunk.choices[0].delta.content, end="")
   `;
  };

  const getLangchainCommand = (
    id: string,
    baseUrl: string,
    apiKey: string = "[Login to Access API KEY]"
  ) => {
    if (apiKey === "") {
      apiKey = "[Login to Access API KEY]";
    }
    return `
from langchain_nvidia_ai_endpoints import ChatNVIDIA

client = ChatNVIDIA(
    model="${id}",
    api_key="${apiKey}",
    temperature=0.2,
    top_p=0.7,
    max_tokens=1024,
)

for chunk in client.stream([{"role":"user","content":"Write a limerick about the wonders of GPU computing."}]):
    print(chunk.content, end="")
    `;
  };

  const getNodeJsCommand = (
    id: string,
    baseUrl: string,
    apiKey: string = "[Login to Access API KEY]"
  ) => {
    if (apiKey === "") {
      apiKey = "[Login to Access API KEY]";
    }
    return `
import OpenAI from 'openai';
const openai = new OpenAI({
baseURL: '${baseUrl}',
apiKey: '${apiKey}'
})

async function main() {
const completion = await openai.chat.completions.create({
    model: "${id}",
    messages: [{"role":"user","content":"Write a limerick about the wonders of GPU computing."}],
    temperature: 0.2,
    top_p: 0.7,
    max_tokens: 1024,
    stream: true
})

for await (const chunk of completion) {
    process.stdout.write(chunk.choices[0]?.delta?.content || '')
}

}

main();
`;
  };

  const baseUrl = "https://api.brev.dev/v1/chat/completions";

  const getCommandByTab = useMemo(
    () =>
      (selectedNim: EndpointCard, tab: string): string => {
        if (isAuthed && isLoading) {
          return "Loading API key...";
        }
        if (isAuthed && !apiKey) {
          return "API key not available. Please try again.";
        }
        switch (tab) {
          case "Python":
            return getPythonCommand(selectedNim.id, baseUrl, apiKey);
          case "Langchain":
            return getLangchainCommand(selectedNim.id, baseUrl, apiKey);
          case "Node":
            return getNodeJsCommand(selectedNim.id, baseUrl, apiKey);
          case "Shell":
            return getBashCommand(selectedNim.id, baseUrl, apiKey);
          default:
            return "Invalid tab selected.";
        }
      },
    [apiKey, baseUrl, isLoading]
  );

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
      <div className="px-4 sm:px-6 lg:px-8">
        <FlatCard>
          <div className="flex flex-row w-full justify-between items-center">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
              Available Models
            </h1>
            <NewButton defaultOption="deployment" />
          </div>
          <h3 className="text-md text-gray-500 dark:text-secondary mt-1">
            Use leading open models built by the community, optimized and
            accelerated by NVIDIA's enterprise-ready inference runtime
          </h3>
          <section className="mt-3 relative">
            <span className="absolute right-0 top-[-10px]">
              <ArrowRightIcon className="h-5 w-5 text-white" />
            </span>
            <div className="flex flex-row w-full overflow-x-auto mt-3 relative">
              <>
                {models.map((model) => (
                  <EndpointCard
                    key={model.id}
                    id={model.id}
                    displayName={model.displayName}
                    company={model.company}
                    tags={model.tags}
                    description={model.description}
                    image={model.image}
                    selectedId={selectedNim.id}
                    onClick={() => setSelectedNim(model)}
                  />
                ))}
              </>
            </div>
          </section>
          {/* <p className="mt-4">
            Selected Model:{" "}
            <span className="text-emerald-500 font-bold">
              {selectedNim.displayName}
            </span>{" "}
          </p> */}
          <hr className="w-[100%] my-5 dark:border dark:border-zinc-800" />
          <div className="flex flex-col">
            <div className="flex flex-row mt-2 w-full">
              <div className="w-1/2 mt-2">
                <PlaygroundChat
                  apiKey={apiKey}
                  model={selectedNim}
                  isAuthed={isAuthed}
                />
              </div>
              <div className="w-[2px] dark:bg-slate-800 mr-3 ml-3"></div>
              <div className="w-1/2 mt-2">
                {!isAuthed && (
                  <p className="text-gray-500 dark:text-red-400 mb-1 text-sm">
                    Note: The API key is required to access the NVIDIA Brev API.
                    <span
                      className="underline mx-1 cursor-pointer"
                      onClick={() => {
                        window.open("https://console.brev.dev/login", "_blank");
                      }}
                    >
                      Login
                    </span>
                    to get your API key.
                  </p>
                )}
                <GetEndpoint
                  isAuthed={isAuthed}
                  inlineAlertMessage={inlineAlertMessage}
                  inlineAlertSeverity={inlineAlertSeverity}
                  setInlineAlertMessage={setInlineAlertMessage}
                  setInlineAlertSeverity={setInlineAlertSeverity}
                  selectedNim={selectedNim}
                  apiKey={apiKey}
                  isLoading={isLoading}
                  getCommandByTab={getCommandByTab}
                  fallbackCopyTextToClipboard={fallbackCopyTextToClipboard}
                />
              </div>
            </div>
          </div>
        </FlatCard>
      </div>
    </div>
  );
};

export default Endpoints;

const HostedEndpointConfigurationSelector = ({ onSelect, onNext }) => {
  const [selectedConfig, setSelectedConfig] = useState<EndpointProvider | null>(
    null
  );
  const [showApiKeyInput, setShowApiKeyInput] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const configurations: EndpointProvider[] = [
    {
      id: "1",
      name: "Replicate",
      throughput: 150,
      cost: 1.0,
      provider: "Replicate",
      providerLogo: replicate,
      logoFileType: "png",
    },
    {
      id: "3",
      name: "TogetherAI",
      throughput: 200,
      cost: 1.0,
      provider: "TogetherAI",
      providerLogo: togetherai,
      logoFileType: "png",
    },
    {
      id: "4",
      name: "Brev Endpoints",
      throughput: 400,
      cost: 1.0,
      provider: "Brev",
      providerLogo: LightModeLogo,
      logoFileType: "svg",
    },
  ];

  const handleProviderSelection = (config: EndpointProvider) => {
    setSelectedConfig(config);
    if (config.name !== "Brev Endpoints") {
      setShowApiKeyInput(true);
    } else {
      setShowApiKeyInput(false);
    }
  };

  const handleNext = () => {
    if (selectedConfig) {
      if (
        selectedConfig.name === "Brev Endpoints" ||
        (showApiKeyInput && apiKey)
      ) {
        onSelect(selectedConfig);
        onNext();
      }
    }
  };

  const handleBack = () => {
    setShowApiKeyInput(false);
    setApiKey("");
  };

  // Placeholder validation function - replace with actual validation if needed
  const apiKeyValidation = (value: string) => {
    return value.length > 0 ? null : "API Key is required";
  };

  if (showApiKeyInput) {
    return (
      <div>
        <h2 className="text-xl font-semibold">Enter API Key</h2>
        <h2 className="text-md mb-4">For {selectedConfig?.name}</h2>
        <div className="mt-4 p-4 border border-gray-300 rounded-md">
          <InputField
            // validation={apiKeyValidation}
            label="API Key"
            value={apiKey}
            placeholder="Enter your API key"
            onChange={(v) => setApiKey(v)}
            errorMessage={""}
          />
        </div>
        <div className="mt-4 flex justify-between">
          <button
            onClick={handleBack}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            disabled={!apiKey}
            className="px-4 py-2 bg-highlight text-white rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-xl font-semibold">Generate Code Snippet</h2>
      <h2 className="text-md mb-4">Select Hosted Endpoint Provider</h2>
      <div className="space-y-4">
        {configurations.map((config) => (
          <EndpointProviderCard
            key={config.id}
            isSelected={selectedConfig?.id === config.id}
            value={config}
            onChange={handleProviderSelection}
          />
        ))}
      </div>
      <button
        onClick={handleNext}
        disabled={!selectedConfig}
        className="mt-4 px-4 py-2 bg-highlight text-white rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed"
      >
        Next
      </button>
    </div>
  );
};

const GetEndpoint = ({
  isAuthed,
  inlineAlertMessage,
  inlineAlertSeverity,
  setInlineAlertMessage,
  setInlineAlertSeverity,
  selectedNim,
  apiKey,
  isLoading,
  getCommandByTab,
  fallbackCopyTextToClipboard,
}) => {
  const tabs = ["Python", "Langchain", "Node", "Shell"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [showHostedSelector, setShowHostedSelector] = useState(true);
  const [selectedHostedConfig, setSelectedHostedConfig] =
    useState<EndpointProvider | null>(null);

  const languageMap = {
    Shell: "python",
    Python: "python",
    Node: "python",
    Langchain: "python",
  };

  const handleHostedConfigSelect = (configId) => {
    setSelectedHostedConfig(configId);
  };

  useEffect(() => {
    Prism.highlightAll();
  }, [selectedNim.id, activeTab, apiKey, showHostedSelector]);

  const handleNext = () => {
    setShowHostedSelector(false);
    // Here you would typically fetch or generate the appropriate endpoint details
    // based on the selected hosted configuration. For this example, we'll just
    // show the existing GetEndpoint code.
  };

  return (
    <div className="">
      {showHostedSelector ? (
        <HostedEndpointConfigurationSelector
          onSelect={handleHostedConfigSelect}
          onNext={handleNext}
        />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center">
            <p className="font-semibold">
              Configured Code with a {selectedHostedConfig?.name} endpoint
            </p>
            <Button
              label="Back to Providers"
              onClick={() => {
                setShowHostedSelector(true);
              }}
              type="secondary"
            />
          </div>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          {selectedHostedConfig && (
            <>
              {!isAuthed && (
                <p className="text-gray-500 dark:text-red-400 mb-1 text-sm">
                  Note: The API key is required to access the NVIDIA Brev API.
                  <span
                    className="underline mx-1 cursor-pointer"
                    onClick={() => {
                      window.open(
                        `${
                          isKasAuthFlow
                            ? CONSOLE_1_KAS_DEPLOYMENT_URL
                            : CONSOLE_1_AUTH0_DEPLOYMENT_URL
                        }`,
                        "_blank"
                      );
                    }}
                  >
                    Login
                  </span>
                  to get your API key.
                </p>
              )}
              <div className={`${inlineAlertMessage ? "mb-3" : ""}`}>
                <InlineNotification
                  show={!!inlineAlertMessage}
                  severity={inlineAlertSeverity}
                  text={inlineAlertMessage}
                  autoClose={false}
                  onClose={() => setInlineAlertMessage("")}
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="flex space-x-2 overflow-y-visible scrollbar-hide">
                  {tabs.map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`px-4 py-2 font-medium text-sm rounded-md
                    ${
                      activeTab === tab
                        ? "border border-gray-300 dark:border-zinc-800 bg-highlight text-white hover:bg-highlight dark:hover:bg-highlightLighter"
                        : "bg-white border hover:bg-gray-50 dark:hover:bg-zinc-700 dark:bg-zinc-900 dark:border-zinc-800 dark:text-cyan-100"
                    }`}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
                <button
                  type="button"
                  className="bg-white dark:bg-zinc-900 rounded-md font-medium text-cyan-600 dark:text-cyan-300 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-highlightLighter mr-2"
                  onClick={() => {
                    const command = getCommandByTab(selectedNim, activeTab);
                    setInlineAlertSeverity("success");
                    setInlineAlertMessage("Command copied to clipboard");
                    fallbackCopyTextToClipboard(command);
                    setTimeout(() => setInlineAlertMessage(""), 2000);
                  }}
                >
                  Copy
                </button>
              </div>
              <pre
                className={`prism-code language-${languageMap[activeTab]}`}
                key={selectedNim.id + activeTab + apiKey}
              >
                <code className="whitespace-pre">
                  <span className="token plain">
                    {getCommandByTab(selectedNim, activeTab)}
                  </span>
                </code>
              </pre>
            </>
          )}
        </>
      )}
    </div>
  );
};
