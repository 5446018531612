import { Transition } from "@headlessui/react";
import React from "react";

interface SimpleTransitionProps {
  children: React.ReactNode;
  show: boolean;
}
export const SimpleTransition: React.FC<SimpleTransitionProps> = (props) => (
  <Transition
    show={props.show}
    // as={Fragment}
    enter="transform ease-out duration-300 transition"
    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
    leave="transition ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {props.children}
  </Transition>
);

export default SimpleTransition;
