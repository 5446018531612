import { matchPath } from "react-router-dom";

export const PUBLIC_ROUTES = {
  NOTEBOOK: "/notebook/:notebook",
  GIFTCARD: "/giftcard",
  LAUNCHABLE_DEPLOY: "/launchable/deploy",
  DISCOVER: "/discover",
  ENDPOINTS: "/endpoints",
  LOGIN_ALT: "/loginalt",
  LOGIN: "/login",
  LOGOUT: "/logout",
  REDEEM_CODE: "/redeem-code",
  INVITE: "/invite",
  ERROR: "/error/:httpStatus",
  HELP: "/help",
};

export function isPublicRoute(pathname) {
  // the root path should not be considered a public route
  if (pathname === "/") {
    return false;
  }

  return Object.values(PUBLIC_ROUTES).some((route) =>
    matchPath(route, pathname)
  );
}
