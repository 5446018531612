import React, { useState, useEffect } from "react";
import { classNames } from "components/utils";
import UsernameDisplayField from "components/UsernameDisplayField";

type Bar<T> = T & {
  key?: string;
  href?: string;
  value: number;
  name: string;
  isUserId?: boolean;
};

interface BarListProps<T = unknown>
  extends React.HTMLAttributes<HTMLDivElement> {
  data: Bar<T>[];
  valueFormatter?: (value: number) => string;
  showAnimation?: boolean;
  onValueChange?: (payload: Bar<T>) => void;
  sortOrder?: "ascending" | "descending";
  isUserId?: boolean;
}

function BarListInner<T>(
  {
    data = [],
    valueFormatter = (value) => value.toString(),
    showAnimation = false,
    onValueChange,
    sortOrder = "descending",
    className,
    isUserId = false,
    ...props
  }: BarListProps<T>,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
  const Component = onValueChange ? "button" : "div";
  const sortedData = React.useMemo(() => {
    if (sortOrder) {
      return [...data].sort((a, b) => {
        return sortOrder === "ascending"
          ? a.value - b.value
          : b.value - a.value;
      });
    }
    return data;
  }, [data, sortOrder]);

  const [widths, setWidths] = useState<number[]>([]);

  useEffect(() => {
    const maxValue = Math.max(...sortedData.map((item) => item.value), 0);
    const calculatedWidths = sortedData.map((item) =>
      item.value === 0 ? 0 : Math.max((item.value / maxValue) * 100, 2)
    );
    if (showAnimation) {
      setWidths(new Array(sortedData.length).fill(0));
      setTimeout(() => {
        setWidths(calculatedWidths);
      }, 10);
    } else {
      setWidths(calculatedWidths);
    }
  }, [sortedData, showAnimation]);

  const rowHeight = "h-8";

  return (
    <div
      ref={forwardedRef}
      className={classNames("flex justify-between space-x-6", className || "")}
      aria-sort={sortOrder}
      {...props}
    >
      <div className="relative w-full space-y-1.5">
        {sortedData.map((item, index) => (
          <Component
            key={item.key ?? item.name}
            onClick={() => {
              onValueChange?.(item);
            }}
            className={classNames(
              "group w-full rounded",
              onValueChange
                ? "!-m-0 cursor-pointer hover:bg-gray-50 hover:dark:bg-gray-900"
                : ""
            )}
          >
            <div
              className={classNames(
                // base
                "flex items-center rounded transition-all",
                rowHeight,
                // background color
                "bg-highlight dark:bg-highlight",
                onValueChange
                  ? "group-hover:bg-blue-300 group-hover:dark:bg-blue-800"
                  : "",
                // margin and duration
                index === sortedData.length - 1 ? "mb-0" : "",
                showAnimation ? "duration-1000 ease-out" : "" // Ensure the transition is applied
              )}
              style={{ width: `${widths[index]}%` }}
            >
              <div className={"absolute left-2 flex max-w-full pr-2"}>
                {isUserId ? (
                  <UsernameDisplayField
                    className="truncate whitespace-nowrap rounded text-sm text-gray-900 dark:text-gray-50"
                    userId={item.name}
                  />
                ) : item.href ? (
                  <a
                    href={item.href}
                    className="truncate whitespace-nowrap rounded text-sm text-gray-900 dark:text-gray-50 hover:underline hover:underline-offset-2"
                    target="_blank"
                    rel="noreferrer"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {item.name}
                  </a>
                ) : (
                  <p className="truncate whitespace-nowrap text-sm text-gray-900 dark:text-gray-50">
                    {item.name}
                  </p>
                )}
              </div>
            </div>
          </Component>
        ))}
      </div>
      <div>
        {sortedData.map((item, index) => (
          <div
            key={item.key ?? item.name}
            className={classNames(
              "flex items-center justify-end",
              rowHeight,
              index === sortedData.length - 1 ? "mb-0" : "mb-1.5"
            )}
          >
            <p
              className={classNames(
                // base
                "truncate whitespace-nowrap text-sm leading-none",
                // text color
                "text-gray-900 dark:text-gray-50"
              )}
            >
              {valueFormatter(item.value)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

BarListInner.displayName = "BarList";

const BarList = React.forwardRef(BarListInner) as <T>(
  p: BarListProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof BarListInner>;

export { BarList, type BarListProps };
