import IOrganization from "models/Organization.model";

export default class Organization implements IOrganization {
  public id: string;

  public name: string;

  public slug: string;

  public defaultWorkspaceGroupId: string;

  public isBlocked: boolean;

  public constructor(o: IOrganization) {
    this.id = o.id;
    this.name = o.name;
    this.slug = o.slug;
    this.defaultWorkspaceGroupId = o.defaultWorkspaceGroupId;
    this.isBlocked = o.isBlocked || false;
  }
}
