import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { DarkModeContext } from "contexts/DarkModeContext";
import UnauthenticatedDash, {
  DashWrapper,
} from "components/DashboardContainer/UnauthenticatedDash";
import HtmlRenderer from "./HtmlRenderer";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { WorkspaceCreateContext } from "contexts/WorkspaceCreateContext";
import { isKasAuthFlow } from "server/kas/utils";
import { useKas } from "contexts/KasContext";
interface RouteParams {
  notebook: string;
}

const NotebookRenderer: React.FC = () => {
  console.log("Route: /notebook/:notebook");

  const { notebook } = useParams<RouteParams>();
  const { isAuthenticated: _auth0IsAuthenticated, user: _auth0User } =
    useAuth0?.();
  const { isUserLoggedIn: kasIsAuthenticated, user: kasUser } = useKas();
  const history = useHistory();
  const [callBackUrl, setCallBackUrl] = useState("/instance/new");
  const [noteBookNotFound, setNoteBookNotFound] = useState(false);

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0User = isKasAuthFlow ? kasUser : _auth0User;

  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType(
      "navigation"
    ) as PerformanceNavigationTiming[];
    if (
      navigationEntries.length > 0 &&
      navigationEntries[0].type === "reload"
    ) {
      history.push(`/notebook/${notebook}`);
    }
  }, []);

  useEffect(() => {
    console.log("Auth0 isAuthenticated:", auth0IsAuthenticated);
    console.log("Auth0 user:", auth0User);
    if (auth0IsAuthenticated && auth0User?.email) {
      history.push(`/notebooks/${notebook}`);
    }
  }, [auth0IsAuthenticated, auth0User]);

  const handleNavigation = (url: string) => {
    history.push(url);
  };

  return (
    // This works because App.tsx has the unauthenticated route outside of the Dash, which requires auth.
    <DashWrapper callbackUrl={callBackUrl} notebookNotFound={noteBookNotFound}>
      <div className="min-h-full">
        <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <HtmlRenderer
            notebook={notebook}
            setCallbackUrl={setCallBackUrl}
            setNoteBookNotFound={setNoteBookNotFound}
            onNavigate={handleNavigation}
          />
        </header>
      </div>
    </DashWrapper>
  );
};

export default NotebookRenderer;
