import React, { useContext, useEffect, useRef, useState } from "react";
import { Menu } from "@headlessui/react";
import { OrgContext } from "contexts/OrgContext";
import Spinner from "components/UI-lib/Spinner";
import { ArrowUpIcon, ChevronDoubleDownIcon } from "@heroicons/react/24/solid";
import agent, { WorkspacesRes } from "server";
import IWorkspace from "models/Workspace.model";
import { NotificationContext } from "contexts/NotificationContext";
import IUser from "models/User.model";
import FlatCard from "components/UI-lib/FlatCard";
import CPUIcon from "assets/img/svg/cpuicon.svg?react";
import Button from "components/UI-lib/Button";
import DisplayEnvironments from "./DisplayEnvironments";
import SearchInput from "./SearchInput";
import WorkspaceStopper, { WorkspacesGetStopped } from "./WorkspaceStopper";
import WorkspaceDeleter, { WorkspacesGetDeleted } from "./WorkspaceDeleter";
import DeployButton from "components/Environment/Create/DeployButton";
import UserVerifier from "./UserVerifier";
import { forEach } from "lodash";
import UserBlockManager from "./UserBlockManager";

export const getMixpanelUrl = (userid: string) =>
  `https://mixpanel.com/project/2604005/view/3142793/app/profile#distinct_id=${userid}`;

export const getAWSUrl = (userid: string, region: string) =>
  `https://us-west-2.console.aws.amazon.com/ec2/home?region=${region}#Instances:search=:${userid};v=3;$case=tags:true%5C,client:false;$regex=tags:false%5C,client:false`;

export const getSentryUrl = (userid: string) =>
  `https://sentry.io/organizations/brev-dev/issues/?project=5820582&query=+user.id%3A${userid}&statsPeriod=14d`;

export const getDarkLaunchlyUrl = (userid: string) =>
  `https://app.launchdarkly.com/default/production/contexts?filter=q%2520equals%2520%2522${userid}%2522`;

export const getStripeUrl = (userid: string) =>
  `https://dashboard.stripe.com/search?query=${userid}`;

export const getDynamoDBUrl = (userid: string) =>
  `https://us-west-2.console.aws.amazon.com/dynamodbv2/home?region=us-west-2#item-explorer?maximize=true&operation=QUERY&pk=user%3A${userid}&table=brev-deploy-prod`;

export const getCustomerIOUrl = (email: string) =>
  `https://fly.customer.io/workspaces/96946/journeys/people?email=${email}`;

export const base64UrlEncode = (input: string): string => {
  // Convert the string to bytes
  const utf8Bytes = new TextEncoder().encode(input);

  // Convert bytes to base64
  let base64Encoded = btoa(String.fromCharCode(...utf8Bytes));

  // Make the base64 output URL-safe
  return base64Encoded.replace("+", "-").replace("/", "_").replace(/=+$/, "");
};

export const getAuth0Url = (externalAuthID: string): string => {
  const base64UrlEncodedExternalAuthId = base64UrlEncode(externalAuthID);
  return `https://manage.auth0.com/dashboard/us/brevdev/users/${base64UrlEncodedExternalAuthId}`;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const fetchAllWorkspacesForUser = async (userid: string) => {
  const orgsRes = await agent.Organizations.getAllAsAdmin(userid);
  let orgs = orgsRes.data;

  if (orgs === undefined) {
    return [];
  }

  orgs.forEach((org) => {
    console.log(`org: ${org.name} `);
  });

  // Fetch all workspaces for each organization
  const promises = orgs.map((org) => agent.Workspaces.getAll(org.id));
  const workspacesResponses: WorkspacesRes[] = await Promise.all(promises);

  const workspaces: IWorkspace[] = [];
  workspacesResponses.forEach((response) => {
    if (response.success && response.data) {
      workspaces.push(...response.data);
    }
  });

  console.log(`workspaces: ${workspaces.length} `);

  // Filter workspaces by the provided user ID
  const userSpecificWorkspaces = workspaces.filter(
    (workspace) => workspace.createdByUserId === userid
  );
  console.log(`workspaces by user : ${userSpecificWorkspaces.length} `);

  return userSpecificWorkspaces;
};

const fetchAllUsers = async () => {
  const usersRes = await agent.Users.getAllAsAdmin();
  let users = usersRes.data;
  console.log(usersRes);
  console.log(users);

  return users;
};

const NewAdminPage: React.FC = () => {
  console.log("Route: /admin");
  const notificationContext = useContext(NotificationContext);
  const [activeUser, setActiveUser] = useState<IUser>();
  const orgContext = useContext(OrgContext);
  const [users, setUsers] = useState<IUser[]>([]);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [isInstancesLoading, setIsInstancesLoading] = useState<
    Record<string, boolean>
  >({});
  const [userWorkspaces, setUserWorkspaces] = useState<
    Record<string, IWorkspace[]>
  >({});
  const [searchInput, setSearchInput] = useState<string>("");

  const [showBulkDeleteCountdown, setShowBulkDeleteCountdown] = useState<
    number | null
  >(null);
  const [shouldBulkDelete, setShouldBulkDelete] = useState<boolean>(false);
  const bulkDeleteAll = () => {
    setShowBulkDeleteCountdown(5);
    const interval = setInterval(() => {
      setShowBulkDeleteCountdown((prevCountdown) => {
        if (prevCountdown === null || prevCountdown === 1) {
          clearInterval(interval);
          if (prevCountdown === 1) {
            setShouldBulkDelete(true);
          }
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };
  ``;
  const bulkCancelDelete = () => {
    setShowBulkDeleteCountdown(null);
  };

  const [showBulkStopCountdown, setShowBulkStopCountdown] = useState<
    number | null
  >(null);
  const [shouldBulkStop, setShouldBulkStop] = useState<boolean>(false);
  const bulkStopAll = () => {
    setShowBulkStopCountdown(5);
    const interval = setInterval(() => {
      setShowBulkStopCountdown((prevCountdown) => {
        if (prevCountdown === null || prevCountdown === 1) {
          clearInterval(interval);
          if (prevCountdown === 1) {
            setShouldBulkStop(true);
          }
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };
  const bulkCancelStop = () => {
    setShowBulkStopCountdown(null);
  };

  const [stopCountdown, setStopCountdown] = useState<number | null>(null);
  const [shouldStopAll, setShouldStopAll] = useState(false);
  const stopAll = () => {
    setStopCountdown(5);
    const interval = setInterval(() => {
      setStopCountdown((prevCountdown) => {
        if (prevCountdown === null || prevCountdown === 1) {
          clearInterval(interval);
          if (prevCountdown === 1) {
            setShouldStopAll(true);
          }
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };
  const cancelStop = () => {
    setStopCountdown(null);
  };

  const [showBulkDeleteAllConfirmation, setShowBulDeleteAllConfirmation] =
    useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const startDeleteProcess = () => {
    setShowConfirmation(true);
  };
  const startBulkDeleteProcess = () => {
    setShowBulDeleteAllConfirmation(true);
  };

  const confirmBulkDeleteAll = () => {
    setShowBulDeleteAllConfirmation(false);
    bulkDeleteAll();
  };

  const confirmDeleteAll = () => {
    setShowConfirmation(false);
    deleteAll();
  };
  const [deleteCountdown, setDeleteCountdown] = useState<number | null>(null);
  const [shouldDeleteAll, setShouldDeleteAll] = useState(false);
  const deleteAll = () => {
    setDeleteCountdown(5);
    const interval = setInterval(() => {
      setDeleteCountdown((prevCountdown) => {
        if (prevCountdown === null || prevCountdown === 1) {
          clearInterval(interval);
          if (prevCountdown === 1) {
            setShouldDeleteAll(true);
          }
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };
  const cancelDelete = () => {
    setDeleteCountdown(null);
  };
  const fetchWorkspacesForUser = async (userId: string) => {
    setIsInstancesLoading((prevLoading) => ({
      ...prevLoading,
      [userId]: true,
    }));
    const workspacesForUser = await fetchAllWorkspacesForUser(userId);
    setUserWorkspaces((prevWorkspaces) => ({
      ...prevWorkspaces,
      [userId]: workspacesForUser,
    }));
    setIsInstancesLoading((prevLoading) => ({
      ...prevLoading,
      [userId]: false,
    }));
  };

  const initialize = async () => {
    setIsUsersLoading(true);
    const fetchedUsers = await fetchAllUsers();
    setIsUsersLoading(false);

    if (fetchedUsers) {
      setUsers(fetchedUsers);
      setFilteredUsers(fetchedUsers);
    }
  };

  useEffect(() => {
    initialize();
  }, [orgContext.activeOrgId]);

  const renderUser = (userObj: IUser, instances: IWorkspace[]) => (
    <div
      className="flex flex-row py-2 w-full hover:bg-gray-50 dark:hover:bg-zinc-700 hover:cursor-pointer py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0 bg-white dark:bg-zinc-900"
      onClick={() => {
        fetchWorkspacesForUser(userObj.id);
        setActiveUser(userObj);
        console.log(isInstancesLoading);
      }}
    >
      <div className="flex-1 space-y-8">
        <div className="space-y-8 sm:flex sm:items-center sm:justify-between sm:space-y-0 xl:block xl:space-y-8">
          <div className="flex items-center space-x-3 ml-2">
            <div className="h-12 w-12 flex-shrink-0">
              <img className="h-12 w-12 rounded-full" alt="" />
            </div>
            <div className="space-y-1">
              <div className="text-sm font-medium text-gray-900 dark:text-white">
                {userObj.email}
              </div>
              <div className="text-sm font-light text-gray-500 dark:text-secondary">
                id: {userObj.id}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6">
          <div className="flex items-center space-x-2">
            <CPUIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />

            <span className="text-sm font-medium text-gray-500 dark:text-secondary">
              {isInstancesLoading[userObj.id] ? (
                <div className="flex flex-row">
                  <Spinner type={"secondary"} />
                  <span className="ml-2">fetching instances...</span>
                </div>
              ) : (
                <>
                  {instances && instances.length ? (
                    <>{(instances && instances.length) || 0} instances</>
                  ) : (
                    <>{`¯\\_(ツ)_/¯ `}</>
                  )}
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const filterUsers = () => {
    if (searchInput.startsWith("@")) {
      const domain = searchInput.slice(1).toLowerCase();
      setFilteredUsers(users.filter((user) => user.email.endsWith(domain)));
    } else {
      setFilteredUsers(
        users.filter(
          (user) =>
            user.id.toLowerCase().includes(searchInput.toLowerCase()) ||
            user.email.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    }
  };

  const getTotalWorkspaces = () => {
    return filteredUsers.reduce((total, user) => {
      const userWorkspaceCount = userWorkspaces[user.id]?.length || 0;
      return total + userWorkspaceCount;
    }, 0);
  };

  return (
    <>
      <>
        <div className="caution">
          <div className="caution__text">CAUTION</div>
        </div>
        <nav className="flex mt-2 mb-2 ml-10" aria-label="Breadcrumb">
          <div className="flex flex-row items-center">
            <div className="w-[400px] max-w-[800px]">
              <SearchInput
                value={searchInput}
                onChange={setSearchInput}
                placeholder="Search by user ID, @domain, or email"
                disabled={false}
                onClick={() => {
                  filterUsers();
                }}
              />
            </div>
            <div>
              <FlatCard>
                <div className="">
                  <div>
                    <h2 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Bulk User Action
                    </h2>
                    <p className="text-sm text-gray-500 dark:text-secondary font-bold mb-2">
                      Fetch workspaces for all filtered users
                    </p>

                    {users.length !== filteredUsers.length && (
                      <UserBlockManager users={filteredUsers} />
                    )}

                    <Button
                      label="Bulk Fetch Workspaces"
                      type="primary"
                      onClick={async () => {
                        const promises = filteredUsers.map((user) =>
                          fetchWorkspacesForUser(user.id)
                        );
                        await Promise.all(promises);
                      }}
                    />

                    {showBulkStopCountdown !== null ? (
                      <div>
                        Stopping all instances in {showBulkStopCountdown}{" "}
                        seconds
                        <Button
                          label="Nevermind"
                          onClick={bulkCancelStop}
                          type="secondary"
                        />
                      </div>
                    ) : (
                      <Button
                        label={`Stop ALL ${getTotalWorkspaces()} Instances`}
                        type="warning"
                        onClick={bulkStopAll}
                        loading={false}
                      />
                    )}

                    {showBulkDeleteAllConfirmation ? (
                      <>
                        <p className="text-sm text-cyan-600 font-bold mb-2">
                          Are you sure?
                        </p>
                        <DeployButton
                          onClick={confirmBulkDeleteAll}
                          className="launchButton"
                        >
                          Nuke em
                        </DeployButton>
                      </>
                    ) : showBulkDeleteCountdown !== null ? (
                      <div>
                        Deleting all instances in {showBulkDeleteCountdown}
                        seconds
                        <Button
                          label="Nevermind"
                          onClick={bulkCancelDelete}
                          type="secondary"
                        />
                      </div>
                    ) : (
                      <Button
                        label={`Delete ALL ${getTotalWorkspaces()} Instances`}
                        type="danger"
                        onClick={startBulkDeleteProcess}
                        loading={false}
                      />
                    )}

                    {shouldBulkStop && (
                      <WorkspacesGetStopped
                        workspaces={filteredUsers.flatMap(
                          (user) => userWorkspaces[user.id]
                        )}
                      />
                    )}

                    {shouldBulkDelete && (
                      <WorkspacesGetDeleted
                        workspaces={filteredUsers.flatMap(
                          (user) => userWorkspaces[user.id]
                        )}
                      />
                    )}
                  </div>
                </div>
              </FlatCard>
            </div>
          </div>
        </nav>
      </>
      <div className="w-full flex-grow lg:flex xl:px-8 overflow-scroll max-h-[90vh] bg-white dark:bg-zinc-900 flex">
        {/* Added flex here */}
        <div className="pl-2 min-w-0">
          {/* Added flex-1 here */}
          <div className="bg-white dark:bg-zinc-900 xl:w-64 xl:flex-shrink-0 xl:border-r xl:border-gray-200 dark:xl:border-zinc-800 overflow-scroll">
            <div className="">
              <div className="flex flex-col items-center justify-between divide-y divide-gray-200 dark:divide-zinc-800 max-h-screen overflow-y-auto">
                {isUsersLoading ? (
                  <FlatCard isLoading={true}>
                    <p className="text-sm text-gray-700 dark:text-secondary font-mono">
                      Fetching all users...
                    </p>
                  </FlatCard>
                ) : (
                  <>
                    {filteredUsers.map((user) =>
                      renderUser(user, userWorkspaces[user.id])
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-zinc-900 lg:min-w-0 flex-2">
          {/* Added flex-2 here */}
          <div className="border-b border-t border-gray-200 dark:border-zinc-800 pl-4 pr-6 pt-4 pb-4 sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-6 xl:pt-6">
            <div className="flex items-center">
              <h1 className="flex-1 text-lg font-medium text-gray-900 dark:text-white">
                Instances
              </h1>
              {/* <Menu as="div" className="relative">
                <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-highlightLighter focus:ring-offset-2">
                  <ArrowUpIcon
                    className="mr-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Sort
                  <ChevronDoubleDownIcon
                    className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Name
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Date modified
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Date created
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu> */}
            </div>
          </div>
          <ul
            role="list"
            className="divide-y divide-gray-200 border-b border-gray-200"
          >
            {activeUser !== undefined && (
              <>
                {isInstancesLoading[activeUser.id] ? (
                  <FlatCard isLoading={true}>
                    <p className="text-sm text-gray-700 dark:text-secondary font-mono">
                      Fetching all instances...
                    </p>
                  </FlatCard>
                ) : (
                  <>
                    <DisplayEnvironments
                      workspaces={userWorkspaces[activeUser.id] || []}
                      isInstancesLoading={isInstancesLoading[activeUser.id]}
                    />
                  </>
                )}
              </>
            )}
          </ul>
        </div>
        {activeUser !== undefined && (
          <div className="pr-2 bg-gray-50 dark:bg-zinc-900 pr-4 sm:pr-6 lg:flex-shrink-0 lg:border-l lg:border-gray-200 dark:lg:border-zinc-800 lg:pr-8 xl:pr-0">
            <div className="px-2 lg:w-80">
              <div className="pt-6 pb-2">
                <h2 className="text-sm font-semibold text-gray-900 dark:text-white">
                  User Control Panel
                </h2>
              </div>
              <FlatCard>
                <p className="text-sm text-gray-500 dark:text-secondary font-bold">
                  Quick Links:
                </p>
                <div className="flex justify-start items-center">
                  <a
                    href={getMixpanelUrl(activeUser.id)}
                    target="_blank"
                    rel="noreferrer"
                    className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                  >
                    Mixpanel
                  </a>
                  <span className="mx-2 my-1" aria-hidden="true">
                    &middot;
                  </span>
                  <a
                    href={getSentryUrl(activeUser.id)}
                    target="_blank"
                    rel="noreferrer"
                    className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                  >
                    Sentry
                  </a>
                </div>
                <div className="flex justify-start items-center">
                  <a
                    href={getDarkLaunchlyUrl(activeUser.id)}
                    target="_blank"
                    rel="noreferrer"
                    className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                  >
                    Dark Launchly
                  </a>
                  <span className="mx-2 my-1" aria-hidden="true">
                    &middot;
                  </span>
                  <a
                    href={getStripeUrl(activeUser.id)}
                    target="_blank"
                    rel="noreferrer"
                    className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                  >
                    Stripe
                  </a>
                </div>
                <div className="flex justify-start items-center">
                  <a
                    href={getDynamoDBUrl(activeUser.id)}
                    target="_blank"
                    rel="noreferrer"
                    className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                  >
                    Dynamo DB
                  </a>
                  <span className="mx-2 my-1" aria-hidden="true">
                    &middot;
                  </span>
                  <a
                    href={getCustomerIOUrl(activeUser.email)}
                    target="_blank"
                    rel="noreferrer"
                    className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                  >
                    Customer.io
                  </a>
                  <span className="mx-2 my-1" aria-hidden="true">
                    &middot;
                  </span>
                  <a
                    href={getAuth0Url(activeUser.externalAuthId)}
                    target="_blank"
                    rel="noreferrer"
                    className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                  >
                    Auth0
                  </a>
                </div>
                <div className="flex justify-start items-center"></div>
              </FlatCard>

              <FlatCard>
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <UserVerifier userId={activeUser.id} />
                  </div>
                </div>
              </FlatCard>
              <FlatCard>
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <UserBlockManager users={[activeUser]} />
                  </div>
                </div>
              </FlatCard>
              {/* Actions on a workspace */}
              {userWorkspaces[activeUser.id] && (
                <>
                  <FlatCard>
                    <p className="text-sm text-gray-500 dark:text-secondary font-bold mb-2">
                      Delete all{" "}
                      {
                        userWorkspaces[activeUser.id].filter(
                          (e) => e.status === "RUNNING"
                        ).length
                      }{" "}
                      instances for a user
                    </p>

                    {showConfirmation ? (
                      <>
                        <p className="text-sm text-cyan-600 font-bold mb-2">
                          Are you sure?
                        </p>
                        <DeployButton
                          onClick={confirmDeleteAll}
                          className="launchButton"
                        >
                          Nuke em
                        </DeployButton>
                      </>
                    ) : deleteCountdown !== null ? (
                      <div>
                        Deleting all instances in {deleteCountdown} seconds
                        <Button
                          label="Reset"
                          onClick={cancelDelete}
                          type="secondary"
                        />
                      </div>
                    ) : (
                      <Button
                        label="Delete ALL Instances"
                        type="danger"
                        onClick={startDeleteProcess}
                        loading={false}
                      />
                    )}

                    {shouldDeleteAll && (
                      <WorkspacesGetDeleted
                        workspaces={userWorkspaces[activeUser.id]}
                      />
                    )}
                  </FlatCard>
                  <FlatCard>
                    <p className="text-sm text-gray-500 dark:text-secondary font-bold mb-2">
                      Stop all{" "}
                      {
                        userWorkspaces[activeUser.id].filter(
                          (e) => e.status === "RUNNING"
                        ).length
                      }{" "}
                      instances for a user
                    </p>
                    {stopCountdown !== null ? (
                      <div>
                        Stopping all instances in {stopCountdown} seconds
                        <Button
                          label="Nevermind"
                          onClick={cancelStop}
                          type="secondary"
                        />
                      </div>
                    ) : (
                      <Button
                        label="Stop ALL Instances"
                        type="warning"
                        onClick={stopAll}
                        loading={false}
                      />
                    )}

                    {shouldStopAll && (
                      <WorkspacesGetStopped
                        workspaces={userWorkspaces[activeUser.id].filter(
                          (workspace) => workspace.status === "RUNNING"
                        )}
                      />
                    )}
                  </FlatCard>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default NewAdminPage;
