import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "../style.scss";
import { memoryToGiB, roundPriceToTwoDigits } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/Graphs/Tabs";
import AdvancedSelector from "./Advanced";
import RecommendedSelector from "./Recommended";
import GPUFlatCard from "./ComputeFlatCard";
import InputField from "components/UI-lib/InputField";
import { Tooltip } from "@mui/material";
import { prependZeroToCost } from "utils/CreateWorkspaceUtils";
import {
  ArchiveBoxXMarkIcon,
  ArrowPathIcon,
  BeakerIcon,
  CircleStackIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  PlayIcon,
  StopCircleIcon,
} from "@heroicons/react/24/outline";
import { ContainerSelected } from "../EnvironmentCreate";
import { isStoppableInstance } from "../utils";
import NvidiaDGXDataCenter from "assets/img/png/dgxDatacenter.png";

export interface ComputeSelectorProps {
  setSelectedInstance(instanceObj: GPUInstanceType | undefined): void;
  selectedInstance: GPUInstanceType | undefined;
  setStorage(size: string): void;
  storage: string;
  containerSelected: ContainerSelected;
  setSelectedCloudName(cloudName: string): void;
  selectedCloudName: string;
  displayGPUInstances: GPUInstanceType[];
  setDisplayGPUInstances(instanceTypes: GPUInstanceType[]): void;
}

const ComputeSelector: React.FC<ComputeSelectorProps> = ({
  selectedInstance,
  setSelectedInstance,
  setStorage,
  storage,
  containerSelected,
  setSelectedCloudName,
  selectedCloudName,
  displayGPUInstances,
  setDisplayGPUInstances,
}) => {
  const [runningCost, setRunningCost] = useState<number>(0);

  useEffect(() => {
    const img = new Image();
    img.src = NvidiaDGXDataCenter;
  }, []);

  useEffect(() => {
    setRunningCost(
      prependZeroToCost(
        Number(selectedInstance?.base_price?.amount || 0) +
          Number(storage) *
            Number(
              selectedInstance?.supported_storage
                ? selectedInstance?.supported_storage[0].price_per_gb_hr?.amount
                : 0
            )
      )
    );
  }, [selectedInstance, storage]);

  useEffect(() => {
    console.log("selectedInstance", selectedInstance);
  }, [selectedInstance]);

  return (
    <>
      <div className="flex flex-col">
        <AnimatePresence initial={false}>
          {!selectedInstance && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                duration: 0.8,
                ease: [0.04, 0.62, 0.23, 0.98],
              }}
            >
              <motion.div
                variants={{
                  collapsed: { scale: 0.8 },
                  open: { scale: 1 },
                }}
                transition={{ duration: 0.8 }}
                className="content-placeholder"
              >
                <div className="flex flex-col">
                  <AdvancedSelector
                    setDisplayGPUInstances={setDisplayGPUInstances}
                    displayGPUInstances={displayGPUInstances}
                    setSelectedInstance={setSelectedInstance}
                    containerSelected={containerSelected}
                    setSelectedCloudName={setSelectedCloudName}
                    selectedCloudName={selectedCloudName}
                  />
                </div>
              </motion.div>
            </motion.section>
          )}
          {!!selectedInstance && (
            <>
              <div
                onClick={() => {
                  setSelectedInstance(undefined);
                }}
              >
                <GPUFlatCard
                  includeTitle={true}
                  withChipIcon={true}
                  key={selectedInstance.type}
                  instanceType={selectedInstance}
                  setInstanceType={setSelectedInstance}
                  chipName={selectedInstance.supported_gpus[0].name}
                  manufacturerName={
                    selectedInstance.supported_gpus[0].manufacturer
                  }
                  gpuCount={selectedInstance.supported_gpus[0].count}
                  vramSize={selectedInstance.supported_gpus[0].memory}
                  ramSize={String(selectedInstance.memory)}
                  cpuCount={selectedInstance.vcpu}
                  cloudProvider={selectedInstance.workspace_groups[0].platformType.toUpperCase()}
                  price={roundPriceToTwoDigits(
                    selectedInstance.base_price?.amount || "0.00"
                  )}
                />
              </div>
              <div className="storage-options flex flex-row min-h-[65px] mt-5">
                <div className="w-1/3 pr-1 mr-5 h-full">
                  {selectedInstance?.elastic_root_volume ? (
                    <>
                      <InputField
                        label="Disk Storage (GiB)"
                        placeholder="leave blank for default (256GiB)"
                        initialValue={storage}
                        value={storage}
                        onChange={(e) => {
                          if (
                            Number(storage) >=
                              memoryToGiB(
                                selectedInstance.supported_storage[0]
                                  .min_size || "100GiB"
                              ) ||
                            Number(storage) <=
                              memoryToGiB(
                                selectedInstance.supported_storage[0]
                                  .max_size || "16000GiB"
                              )
                          ) {
                            setStorage(e);
                          }
                        }}
                        errorMessage={
                          Number(storage) <
                            memoryToGiB(
                              selectedInstance.supported_storage[0].min_size ||
                                "100GiB"
                            ) ||
                          Number(storage) >
                            memoryToGiB(
                              selectedInstance.supported_storage[0].max_size ||
                                "16000GiB"
                            )
                            ? `Size should be between ${
                                selectedInstance.supported_storage[0]
                                  .min_size || "100GiB"
                              } and ${
                                selectedInstance.supported_storage[0]
                                  .max_size || "16000GiB"
                              }`
                            : ""
                        }
                        onKeyDown={(e) => {
                          if (
                            !/[0-9\b]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </>
                  ) : (
                    <Tooltip
                      title="Disk Storage for this instance is fixed by the Cloud Provider"
                      placement="top-start"
                    >
                      <div>
                        <InputField
                          label="Disk Storage (GiB)"
                          placeholder="leave blank for default (256GiB)"
                          value={`${
                            selectedInstance?.supported_storage &&
                            selectedInstance?.supported_storage.length &&
                            selectedInstance?.supported_storage.length > 0 &&
                            selectedInstance?.supported_storage[0].size
                              ? selectedInstance?.supported_storage[0].size
                              : "Select Instance First"
                          }`}
                          onChange={() => {}}
                          disabled
                          errorMessage=""
                          onKeyDown={(e) => {
                            if (
                              !/[0-9\b]/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className="h-full w-[2px] bg-slate-800"></div>
                <div className="flex flex-row h-full">
                  <div className="flex text-sm text-gray-700 dark:text-secondary">
                    <div className="flex flex-col">
                      <p className="font-semibold">Total Running Rate</p>
                      <div className="flex flex-row text-gray-700 dark:text-secondary mt-1">
                        <PlayIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700 dark:text-secondary"
                          aria-hidden="true"
                        />
                        {runningCost === 0 ? (
                          <div className="mr-2">Price not available</div>
                        ) : (
                          <>${runningCost.toFixed(2)} per hour &nbsp;&nbsp;</>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="h-full w-[2px] bg-slate-800 mr-2 ml-2"></div>
                  <div className="flex text-sm text-gray-700 dark:text-secondary">
                    <div className="flex flex-col">
                      <p className="font-semibold">Instance Attributes</p>
                      <div className="flex flex-col text-gray-700 dark:text-secondary mt-1">
                        {selectedInstance.workspace_groups[0].platformType ===
                          "akash" && (
                          <>
                            <div className="flex flex-row mb-1">
                              <BeakerIcon className="h-4 w-4" />
                              <span className="ml-1 text-xs">
                                Akash cloud is a decentralized compute provider,
                                so you may encounter unexpected behaviors
                              </span>
                            </div>
                            <div className="flex flex-row mb-1">
                              <CircleStackIcon className="h-4 w-4" />
                              <span className="ml-1 text-xs">
                                Disk may be reset if you exceed the disk or
                                memory limits
                              </span>
                            </div>
                          </>
                        )}
                        {(selectedInstance.workspace_groups[0].platformType ===
                          "crusoe" ||
                          selectedInstance.workspace_groups[0].platformType ===
                            "lambda-labs" ||
                          selectedInstance.workspace_groups[0].platformType ===
                            "fluidstack") && (
                          <>
                            <div className="flex flex-row items-center">
                              <ExclamationTriangleIcon className="h-4 w-4" />
                              <span className="ml-1 text-xs">
                                This is a pre-release option,{" "}
                                <Tooltip
                                  placement="top"
                                  title={
                                    "By choosing this Pre-Release Option, Customer (1) agrees that Customer will not upload any User Content that includes confidential information, controlled or sensitive data, including personal data, protected health information, or payment card industry information, or other information that is regulated or requires enhanced security or special treatment; and (2) understands that the Pre-Release Option has reduced security standards relative to other NVIDIA Brev options."
                                  }
                                >
                                  <span className="text-highlight cursor-pointer">
                                    view details
                                  </span>
                                </Tooltip>
                              </span>
                            </div>
                          </>
                        )}
                        {selectedInstance.preemptible && (
                          <>
                            <div className="flex flex-row mb-1">
                              <StopCircleIcon className="h-4 w-4" />
                              <span className="ml-1 text-xs">
                                This is a preemptible instance: a discounted but
                                interruptible computing resource
                              </span>
                            </div>
                          </>
                        )}
                        {isStoppableInstance(
                          selectedInstance.workspace_groups[0].platformType
                        ) ? (
                          <>
                            <div className="flex flex-row mb-1">
                              <ArrowPathIcon className="h-4 w-4" />
                              <span className="ml-1 text-xs">
                                This instance can be stopped and restarted
                                without losing data
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="flex flex-row mb-1">
                            <ArchiveBoxXMarkIcon className="h-4 w-4" />
                            <span className="ml-1 text-xs">
                              This instance CANNOT be stopped and restarted.
                              Only Deleted
                            </span>
                          </div>
                        )}
                        {selectedInstance.estimated_deploy_time && (
                          <div className="flex flex-row mb-1">
                            <ClockIcon className="h-4 w-4" />
                            <span className="ml-1 text-xs">
                              This instance can take up to{" "}
                              {selectedInstance.estimated_deploy_time} to deploy
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default ComputeSelector;
