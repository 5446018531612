import { BoltIcon, PlayIcon } from "@heroicons/react/24/solid";
import Button from "components/UI-lib/Button";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DeploymentCard } from "./DeploymentCard";
import { Deployment, DeploymentModelProvider, DeploymentStatus } from "server";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { useWindupString } from "windups";
import { DeploymentsContext } from "contexts/DeploymentsContext";
import "../style.scss";
import { Tooltip } from "@mui/material";
import { Spinner } from "components/UI-lib";

export const DeploymentsRedirect: React.FC = () => {
  const router = useHistory();
  const orgContext = useContext(OrgContext);
  useEffect(() => {
    router.push(`/org/${orgContext.activeOrgId}/deployments`);
  }, [orgContext.activeOrgId]);

  return <></>;
};

const Deployments: React.FC = () => {
  const orgContext = useContext(OrgContext);
  const deploymentsContext = useContext(DeploymentsContext);
  const history = useHistory();

  useEffect(() => {
    // run a function every 10 seconds for 1 minute:
    deploymentsContext.reloadDeployments(orgContext.activeOrgId);
    const interval = setInterval(() => {
      deploymentsContext.reloadDeployments(orgContext.activeOrgId);
    }, 10000);
    return () => clearInterval(interval);
  }, [orgContext.activeOrgId]);

  const [createText] = useWindupString("Create a Deployment");
  const [loadingText] = useWindupString("Loading Deployments...");

  if (orgContext.activeOrg) {
    return (
      <>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
          {/* <InitialTemplateModal /> */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex">
              <div className="sm:flex-auto">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Deployments
                  </h1>
                  <div className="flex">
                    <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                      Launch containers, models, or workloads for scalable
                      inference deployments
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4 flex flex-row">
                <Tooltip title="Coming Soon" placement="top">
                  <div>
                    <Button
                      disabled={true}
                      className="mr-4"
                      label="View Our Endpoints"
                      onClick={() => {
                        //history.push("/org/endpoints");
                      }}
                      type="secondary"
                      iconLeft={<BoltIcon className="w-4 h-4 mr-1" />}
                    />
                  </div>
                </Tooltip>
                <Button
                  label="Create a Deployment"
                  onClick={() => {
                    history.push("/deployments/new");
                  }}
                  iconLeft={<PlayIcon className="w-4 h-4 mr-1" />}
                />
              </div>
            </div>
            <hr className="w-[100%] my-5 dark:border dark:border-zinc-800" />
            <div className="flex mt-5 w-full">
              <div className="flex flex-col w-full gap-5">
                {deploymentsContext.deployments.length <= 0 && (
                  <div
                    onClick={() => {
                      history.push("/deployments/new");
                    }}
                    className="cursor-pointer"
                  >
                    <div className="bg-white dark:bg-zinc-900 relative block w-full rounded-lg border-2 border-dashed border-gray-300 dark:border-zinc-800 p-3 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-highlightLighter focus:ring-offset-2 relative">
                      <div className="block text-lg font-medium text-gray-900 dark:text-gray-100 absolute top-2 w-full z-10 cursor-pointer">
                        <span className="text-gray-700 dark:text-secondary text-xl">
                          {deploymentsContext.loadingDeployments
                            ? loadingText
                            : createText}
                        </span>
                        {!deploymentsContext.loadingDeployments && (
                          <ArrowUpRightIcon className="h-5 w-5 ml-2 inline text-gray-700 dark:text-secondary" />
                        )}
                      </div>
                      <DeploymentCard
                        deployment={mockDeployment}
                        isMock={true}
                      />
                    </div>
                  </div>
                )}
                {deploymentsContext.deployments.map((deployment) => (
                  <DeploymentCard deployment={deployment} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  // when clicking the settings button, reponse time is so fast the seeing loading bars feels off
  return <div />;
};

export default Deployments;

const mockDeployment: Deployment = {
  deployment_id: "dep-123456789",
  create_time: { seconds: 1631234567, nanos: 0 },
  update_time: { seconds: 1631234987, nanos: 0 },
  name: "your-production-endpoint",
  namespace: "my-project",
  deployment_provider_cred_id: "cred-abcdef123",
  instance_type: "H100,GCP.GPU.H100_1x",
  location: "us-west-2",
  providerDeploymentId: "aws-dep-987654321",
  min_instances: 2,
  max_instances: 10,
  maxConcurrency: 100,
  artifact: {
    model: {
      model_name: "meta-llama/Meta-Llama-3.1-8B",
      model_provider: DeploymentModelProvider.HUGGING_FACE,
    },
  },
  publicDns: "api.example.com",
  status: DeploymentStatus.CREATED,
  retireTime: { seconds: 1662770567, nanos: 0 },
  labels: {
    environment: "production",
    team: "backend",
    version: "1.2.3",
  },
};
