import { BeakerIcon, StopCircleIcon } from "@heroicons/react/24/solid";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { classNames, memoryToGiB } from "components/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "./AnimatedBorderDiv.scss";
import FlatCard, { FlatCardProps } from ".";
import {
  CircleStackIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import {
  displayPlatformType,
  formatMemory,
} from "components/Environment/utils";
import { Tooltip } from "@mui/material";
import {
  ProviderAttribute,
  ProviderAttributeProps,
  ProviderAttributesWrapper,
} from "components/Environment/Create/ComputePicker/ProviderAttributes";

interface FlatCardToggleOptionProps extends FlatCardProps {
  isSelected?: boolean;
  value: GPUInstanceType;
  onChange?: (option: GPUInstanceType) => void;
  className?: string;
  price: any;
}

export const FlatCardToggleOption: React.FC<FlatCardToggleOptionProps> = (
  props
) => {
  const instanceType = props.value;
  // Extract the relevant details from the instanceType
  const gpuMemory = instanceType.supported_gpus[0].memory;
  const gpuCount = instanceType.supported_gpus[0].count;
  const cpuCount = instanceType.vcpu;
  const memory = formatMemory(
    instanceType.memory,
    instanceType.workspace_groups[0].platformType
  );

  const preemptible = instanceType.preemptible;
  const estimatedTime = instanceType.estimated_deploy_time;
  const hourlyRate = props.price;
  const platformType =
    instanceType.workspace_groups[0].name === "Brev Private Access"
      ? "Brev Private Access"
      : displayPlatformType(
          instanceType.workspace_groups[0].platformType.toUpperCase()
        );

  const [attributes, setAttributes] = useState<ProviderAttributeProps[]>([]);

  useEffect(() => {
    const attr: ProviderAttributeProps[] = [];
    if (platformType === "AKASH") {
      attr.push(
        ...[
          {
            icon: <BeakerIcon className="h-4 w-4" />,
            title: "alpha",
            description:
              "Akash is a decentralized compute provider, so you may encounter unexpected behaviors not seen with traditional providers. Note: running custom Docker containers is not supported.",
            className: "text-purple-600 mr-1.5",
          },
          {
            icon: <CircleStackIcon className="h-4 w-4" />,
            title: "ephemeral disk",
            description:
              "Disk may be reset if you exceed the disk or memory limits",
            className: " text-orange-400 mr-1.5",
          },
        ]
      );
    }
    if (preemptible) {
      attr.push({
        icon: <StopCircleIcon className="h-4 w-4" />,
        title: "preemptible",
        description: "Discounted instance but can be stopped anytime.",
        className: "text-highlight mr-1",
      });
    }

    setAttributes(attr);
  }, [platformType, preemptible]);

  return (
    <div
      onClick={() => props.onChange && props.onChange(props.value)}
      style={{ width: "100%" }}
    >
      <FlatCard
        noBorder={true}
        className={classNames(
          props.isSelected
            ? "border border-highlight"
            : "border border-zinc-800",
          `${props.className}`,
          "mt-2 cursor-pointer w-full"
        )}
      >
        <label className="flex items-center space-x-2 cursor-pointer w-full">
          <input
            type="radio"
            name="flatCardOption"
            value={instanceType.type}
            checked={props.isSelected}
            onChange={() => props.onChange && props.onChange(props.value)}
            className="form-radio text-highlight focus:ring-highlightLighter"
          />
          <div className="flex items-center justify-between space-x-4 w-full pl-2">
            {/* Flattened layout */}
            <div className="flex items-center space-x-3">
              <h2 className="text-sm font-medium flex flex-row">
                <span>{gpuMemory} GPU Memory</span>
              </h2>
              <p className="flex space-x-2 text-sm text-gray-500">
                <span
                  className={
                    gpuCount > 1 ? "metadata-gpu" : "metadata-gpu-singular"
                  }
                >
                  {gpuCount} {gpuCount > 1 ? "GPUs" : "GPU"}
                </span>
                <span className="metadata-memory">• {memory} RAM</span>
                <span
                  className={
                    cpuCount > 1 ? "metadata-cpu" : "metadata-cpu-singular"
                  }
                >
                  • {cpuCount} {cpuCount > 1 ? "CPUs" : "CPU"}
                </span>
                {!!estimatedTime && (
                  <span className={"metadata-estimated-time"}>
                    • Deploy Time ≈{estimatedTime}
                  </span>
                )}
              </p>
            </div>
            <div className="flex items-center justify-between space-x-3">
              <p className="flex items-center justify-between space-x-2 text-sm text-gray-500">
                <div className="flex flex-row items-center">
                  <span className="mr-1.5">{platformType}</span>
                  {attributes.length > 0 && <span className="mr-1.5">•</span>}
                  <ProviderAttributesWrapper attributes={attributes} />
                  <span>•</span>
                </div>
                <span>
                  {isFinite(parseFloat(hourlyRate)) && Number(hourlyRate) !== 0
                    ? `$${hourlyRate}/hr`
                    : "Price not available"}
                </span>
              </p>
            </div>
          </div>
        </label>
      </FlatCard>
    </div>
  );
};
