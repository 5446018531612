import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { buildDefaultVerbYamlConfig } from "components/Verb/utils";
import { NotificationContext } from "contexts/NotificationContext";
import { useWindupString } from "windups";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/solid";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";
import InputField from "components/UI-lib/InputField";
import agent, { CreateWorkspaceReqBody } from "server";
import {
  classNames,
  fallbackCopyTextToClipboard,
  fetchOrgProfileImage,
  getImageUrl,
  getInstancesBelongingToCertainClouds,
  getPathFromUrl,
  isEmoji,
  mapValuesToArray,
  memoryToGiB,
  notebooks,
  replaceBlobWithRaw,
  replaceRawWithBlob,
  roundPriceToTwoDigits,
  useQuery,
  validateName,
} from "components/utils";
import Button from "components/UI-lib/Button";
import ChipToggleButtons from "./ComputePicker/ChipToggleButtons";

import { DatapondAttachment } from "../shared/DatapondAttachments";

import { useHistory, useLocation } from "react-router-dom";
import DeployButton from "./DeployButton";
import { ComputePricingObject, OrgContext, Price } from "contexts/OrgContext";
import {
  GPUInstanceType,
  WSGWithLocations,
} from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import Workspace from "../../../entities/Workspace.entity";
import Chip from "./chips";
import {
  getInstanceTypeCostProductApi,
  getLowestPriceComputeObject,
  prependZeroToCost,
  sortByLowestRegion,
} from "utils/CreateWorkspaceUtils";
import "App.css";
import ConnectCloudsOrCardModal from "components/ConnectCloudsOrCardModal";
import {
  ConfigurationTitle,
  LoadingDots,
  allPublicCloudsName,
  convertToGPUMap,
  handleIsPaymentSetup,
} from "components/Environment/Create/utils";
import Toggle from "components/UI-lib/Toggle";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import DevToggle from "components/DevToggle";
import PlayIcon from "assets/img/svg/playicon.svg?react";
import PauseIcon from "assets/img/svg/pauseicon.svg?react";
import Spinner from "components/UI-lib/Spinner";
import ReactCardFlip from "react-card-flip";
import CPUSelectorTable from "components/Environment/Settings/Tabs/Compute/InstanceChanger/CPUSelectorTable";
import { RegionWithWorkspaceGroup } from "server";
import { UserContext } from "contexts/UserContext";
import { FlatCardToggleButtons } from "components/UI-lib/FlatCard/FlatCardToggleButtons";
import { AdvancedFilters } from "./ComputePicker/AdvancedFilters";
import { IFileObject, IFileType } from "models/Workspace.model";
import FlatCard from "components/UI-lib/FlatCard";
import { AddCreditsModal } from "components/ConnectCloudsOrCardModal/AddCreditsModal";
import MacOSDock from "./macosdock/MacOsDock";
import { BillingContext } from "contexts/BillingContext";
import SharableLinkCopied from "./SharableLinkCopied";
import { BaseImageSelector } from "./BaseImageSelector";
import { Collapse } from "@mui/material";
import DynamicBadge from "components/UI-lib/DynamicBadge";
import ComputePicker from "./ComputePicker";
import { GetLogo } from "components/DashboardContainer/Dash";
import DatapondAttachments from "../shared/DatapondAttachments";

const pythonVersions = ["2.7", "3.7", "3.8", "3.9", "3.10", "3.11"];

const cudaVersions = [
  "12.1.1",
  "12.1.0",
  "12.0.1",
  "12.0.0",
  "11.8.0",
  "11.7.1",
  "11.6.2",
  "11.5.2",
  "11.4.3",
  "11.3.1",
  "11.2.2",
  "11.1.1",
  "11.0.3",
];

const baseUrl = "https://console.brev.dev/environment/new";

// Example apps array
const apps = [
  {
    id: "app1",
    path: "M0 0m185.982906 0l678.290598 0q185.982906 0 185.982906 185.982906l0 652.034188q0 185.982906-185.982906 185.982906l-678.290598 0q-185.982906 0-185.982906-185.982906l0-652.034188q0-185.982906 185.982906-185.982906Z",
  },
  {
    id: "app2",
    path: "M185.982906 0h678.290598C967.111111 0 1050.25641 83.145299 1050.25641 185.982906v652.034188c0 102.837607-83.145299 185.982906-185.982906 185.982906h-678.290598C83.145299 1024 0 940.854701 0 838.017094v-652.034188C0 83.145299 83.145299 0 185.982906 0z",
  },
  {
    id: "app3",
    path: "M0 0m185.982906 0l678.290598 0q185.982906 0 185.982906 185.982906l0 652.034188q0 185.982906-185.982906 185.982906l-678.290598 0q-185.982906 0-185.982906-185.982906l0-652.034188q0-185.982906 185.982906-185.982906Z",
  },
];

export interface GPUInstanceTypeWithPrice extends GPUInstanceType {
  lowestPrice: Price | null;
  region: string;
}

interface InstanceCreateWrapperProps {
  panel?: string;
}

export const InstanceCreateWrapper: React.FC<InstanceCreateWrapperProps> = ({
  panel,
}) => {
  const query = new URLSearchParams(useLocation().search);

  // Function to decode and parse portMappings
  const parsePortMappings = (encodedMappings) => {
    if (!encodedMappings) return undefined;

    const pairs = encodedMappings.split("%7C"); // Split by encoded '|'
    const mappings = {};
    pairs.forEach((pair) => {
      const [name, port] = pair.split("%3A").map(decodeURIComponent); // Split by encoded ':' and decode
      mappings[name] = port;
    });
    return mappings;
  };

  const portMappings = parsePortMappings(query.get("ports")); // Make sure to use the same key as set in queryParams

  return (
    <InstanceCreate
      cuda={query.get("cuda") ?? undefined}
      python={query.get("python") ?? undefined}
      panel={panel ?? query.get("panel") ?? undefined} // Use the panel prop if provided, otherwise use the query parameter
      diskStorage={query.get("diskStorage") ?? undefined}
      file={query.get("file") ?? undefined}
      name={query.get("name") ?? undefined}
      instance={query.get("instance") ?? undefined}
      baseImage={query.get("baseImage") ?? undefined}
      portMapping={portMappings}
      // Pass other query parameters as needed
    />
  );
};

interface InstanceCreateProps {
  cuda?: string;
  python?: string;
  panel?: string;
  diskStorage?: string;
  file?: string;
  name?: string;
  instance?: string;
  baseImage?: string;
  portMapping?: Record<string, string>;
}

const InstanceCreate: React.FC<InstanceCreateProps> = (props) => {
  //const [config, setConfig] = useState<VerbConfigType>(load(verbYaml));
  // Use the props directly instead of getting them from useQuery
  const [cuda, setCuda] = useState(props.cuda || "3.10");
  const [python, setPython] = useState(props.python || "12.0.1");
  const [baseImage, setBaseImage] = useState(props.baseImage || "");
  const [portMappings, setPortMapping] = useState(props.portMapping || {});
  const [openDropdown, setOpenDropdown] = useState(null);
  const [panel, setPanel] = useState<Panel>(
    getSelectedPanelTypeFromQuery(props.panel || "GPU")
  );

  type Panel = "GPU" | "CPU";
  const [storage, setStorage] = React.useState(props.diskStorage || "500");
  const [datapondAttachments, setDatapondAttachments] = useState<
    DatapondAttachment[]
  >([]);
  const [file, setFile] = React.useState(props.file || "");
  const [instanceName, setInstanceName] = useState(
    props.name || "awesome-gpu-name"
  );
  const [notebookLink, setNotebookLink] = useState("");
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const billingContext = useContext(BillingContext);

  const history = useHistory();
  const [selectedInstanceType, setSelectedInstanceType] =
    useState<GPUInstanceType>();

  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertAction, setInlineAlertAction] = useState<any>(<></>);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");
  const [errorType, setErrorType] = useState<"configuration" | "">("");
  const workspaceContext = useContext(WorkspaceContext);
  const [nameFieldErrorMessage, setNameFieldErrorMessage] = useState("");

  const [showContainerSettings, setShowContainerSettings] = useState(false);
  const [cpuInstancesToDisplay, setCpuInstancesToDisplay] = useState<
    GPUInstanceType[]
  >(orgContext.CPUInstancesAvailable);
  const [gpuInstancesToDisplay, setGpuInstancesToDisplay] = useState<
    GPUInstanceType[]
  >(orgContext.GPUInstancesAvailable);
  const flagEnvironmentsV1FlagEnabled = useFeatureFlagEnabled(
    "use-environments-v1"
  );

  const [shareableDeploymentLink, setShareableDeploymentLink] = useState("");
  const [vmOnlyMode, setVmOnlyMode] = useState(false);

  const [showSharableURL, setShowSharableURL] = useState(false);
  const [sharableURL, setSharableURL] = useState("");
  const createShareableDeploymentLink = () => {
    // Encode the instance parameter
    const instanceParam = `${selectedInstanceType?.supported_gpus[0].name}:${selectedInstanceType?.type}`;

    // Prepare the base queryParams
    const queryParams = new URLSearchParams({
      instance: instanceParam,
      name: instanceName,
      python: python,
      cuda: cuda,
      ...(notebookLink ? { file: notebookLink } : {}),
      ...(baseImage ? { baseImage: baseImage } : {}),
    });

    // Encode the namePortMapping if it exists
    if (Object.keys(portMappings).length > 0) {
      const portsEncoded = Object.entries(portMappings)
        .map(
          ([name, port]) =>
            `${encodeURIComponent(name)}%3A${encodeURIComponent(port)}`
        )
        .join("%7C"); // Using %7C which is the encoded form of |
      queryParams.set("ports", portsEncoded);
    }

    // Construct the final URL
    const finalUrl = `${baseUrl}?${queryParams.toString()}`;
    setShareableDeploymentLink(finalUrl);
    console.log(finalUrl);

    // Copy the URL to clipboard (assuming fallbackCopyTextToClipboard is implemented correctly)
    fallbackCopyTextToClipboard(finalUrl);

    // Set the shareable URL in state
    setSharableURL(finalUrl);
    setShowSharableURL(true);
  };

  const handleSelectPython = (version) => {
    setPython(version);
    setOpenDropdown(null); // Close dropdown after selection
  };

  const handleSelectCuda = (version) => {
    setCuda(version);
    setOpenDropdown(null); // Close dropdown after selection
  };

  const toggleDropdown = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null); // Close currently open dropdown
    } else {
      setOpenDropdown(dropdown); // Open the clicked dropdown
    }
  };

  const [showFinalDeployMessage, setShowFinalDeployMessage] = useState(false);
  const [noCardOnFile, setNoCardOnFile] = useState(false);
  const [selectedCloudName, setSelectedCloudName] =
    useState(allPublicCloudsName);
  const [loading, setLoading] = useState(false);

  const [showConnectCloudsOrCardModal, setShowConnectCloudsOrCardModal] =
    useState(false);
  // so the available configs will just be gpuMap[selectedChip]
  useEffect(() => {
    const mappedGPUs = convertToGPUMap(gpuInstancesToDisplay);
    getSelectedInstanceTypeFromQuery(props.instance || "", mappedGPUs);
  }, [gpuInstancesToDisplay, props.instance]);
  // const clickedDeploy = () => {};

  useEffect(() => {
    setCuda(props.cuda || "3.10");
    setPython(props.python || "12.0.1");
    setPanel(getSelectedPanelTypeFromQuery(props.panel || ""));
    setStorage(props.diskStorage || "500");
    setFile(props.file || "");
    setInstanceName(props.name || "awesome-gpu-name");
    setBaseImage(props.baseImage || "");
  }, [props]);

  // Inside your component
  const deployButtonRef = useRef<HTMLButtonElement>(null);

  const scrollToDeployButton = () => {
    if (deployButtonRef.current) {
      deployButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [imageUrl, setImageUrl] = useState<string>("");

  function getSelectedInstanceTypeFromQuery(
    queryInstanceTypeString: string,
    gpuInstances: { [gpuName: string]: GPUInstanceType[] }
  ) {
    if (panel == "CPU") {
      const instance = cpuInstancesToDisplay.find(
        (i) => i.type === queryInstanceTypeString
      );
      if (instance) {
        setSelectedInstanceType(instance);
      }
    } else {
      const splitIndex = queryInstanceTypeString.indexOf(":");
      const machineType = queryInstanceTypeString.slice(0, splitIndex);
      const instanceType = queryInstanceTypeString.slice(
        splitIndex + 1,
        queryInstanceTypeString.length
      );
      const instance = orgContext.GPUInstancesAvailable.find(
        (i) => i.type === instanceType
      );
      if (instance) {
        setSelectedInstanceType(instance);
      }
    }
  }

  function getSelectedPanelTypeFromQuery(panelTypeString: string): Panel {
    const panelTypes: Panel[] = ["CPU", "GPU"];
    if (panelTypes.includes(panelTypeString as Panel)) {
      return panelTypeString as Panel;
    } else {
      return "GPU";
    }
  }

  useEffect(() => {
    handleIsPaymentSetup(selectedCloudName, orgContext.activeOrgId).then(
      (isPaymentSetUp) => setNoCardOnFile(!isPaymentSetUp)
    );
  }, [orgContext.workspaceGroups, selectedCloudName]);

  useEffect(() => {
    if (selectedInstanceType) {
      if (errorType === "configuration") {
        // clear the inline alert and reset errorType
        setInlineAlertMessage("");
        setErrorType("");
      }
    }
  }, [selectedInstanceType]);

  const handleNameChange = (val: string) => {
    setInstanceName(val);

    // Set validation error messaging
    if (val === "") {
      setNameFieldErrorMessage("Name required!");
      return;
    }
    const isValid = validateName(val);
    if (!isValid) {
      setNameFieldErrorMessage(
        "Name can only have letters, numbers and dashes."
      );
      return;
    }
    setNameFieldErrorMessage("");
  };

  const handleNotebookLinkChange = (link: string) => {
    setNotebookLink(link);
  };

  const handleAddFile = async (
    url: string,
    path: string,
    workspaceId: string
  ) => {
    // setIsLoading(true);
    try {
      const newFile: IFileObject = {
        url: replaceBlobWithRaw(url),
        path: path,
        metadata: { type: IFileType.NotebookFileType },
      };
      const addedFileMap = await agent.Workspaces.addFile(workspaceId, newFile);
      if (addedFileMap.data) {
        console.log(addedFileMap);
        const addedFileList = mapValuesToArray(addedFileMap.data);
        console.log(addedFileList);
      } else {
        console.error(
          "API did not return an expected object for added file:",
          addedFileMap
        );
      }
    } catch (error) {
      console.error("Failed to add file:", error);
    } finally {
      // setIsLoading(false);
      console.log("added file");
    }
  };

  const handleCompletion = async () => {
    agent.Brevent.track({
      eventName: "Workspace Created From New UI",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
      },
    });

    if (
      selectedCloudName === allPublicCloudsName &&
      ((billingContext.billingProfile?.billing_type === "usage-arrears" &&
        noCardOnFile) ||
        (billingContext.billingProfile?.billing_type === "credit" &&
          billingContext.currentBalance <= 0))
    ) {
      setShowConnectCloudsOrCardModal(true);
      return;
    }

    if (!selectedInstanceType) {
      setInlineAlertMessage("Please select an instance");
      setInlineAlertSeverity("info");
      return;
    }

    if (
      !selectedInstanceType?.workspace_groups ||
      selectedInstanceType?.workspace_groups.length === 0
    ) {
      setInlineAlertMessage(
        "Please select a configuration from the options above."
      );
      setInlineAlertSeverity("error");
      setErrorType("configuration");
      console.error("No workspace groups available for this instance type");
      return;
    }

    const matchingWorkspaceGroup = selectedInstanceType?.workspace_groups.find(
      (wsg) => wsg.name === selectedCloudName
    );

    const workspaceGroup =
      matchingWorkspaceGroup || selectedInstanceType?.workspace_groups[0];

    if (
      !workspaceGroup ||
      !workspaceGroup.locations ||
      workspaceGroup.locations.length === 0
    ) {
      console.error("No locations available for this workspace group");
      return;
    }

    //REMOVED BECAUSE OF BABY PROVISIONER
    const region = selectedInstanceType?.location
      ? workspaceGroup.locations.find(
          (w) => w.name === selectedInstanceType?.location
        )
      : workspaceGroup.locations[0];
    // then now we need an instance type and region to choose
    setLoading(true);
    setShowFinalDeployMessage(true);

    const customYaml = buildDefaultVerbYamlConfig(baseImage, portMappings);

    const res = await workspaceContext.createWorkspace(
      instanceName,
      orgContext.getActiveOrg()?.id || "",
      workspaceGroup.id, //  region?.workspaceGroup.id || "",
      "description",
      {}, // repo
      {}, // execs
      undefined, // ide config
      selectedInstanceType?.type,
      "", // image
      workspaceGroup.platformType === "akash" && region?.name
        ? region.name
        : "", // region?.name || "",
      "",
      storage || "120",
      false, // Spot
      false, // isStoppable
      false, // on container false
      flagEnvironmentsV1FlagEnabled ? customYaml : "", // add verb yaml if environments workspace
      baseImage && baseImage !== "None" ? baseImage : "",
      undefined,
      vmOnlyMode, //vmOnlyMode
      portMappings,
      null,
      {
        isPublic: false,
      },
      flagEnvironmentsV1FlagEnabled ? "v1" : "v0"
    );

    if (res.success) {
      if (file !== "" && res.data && res.data.id) {
        let r = await handleAddFile(
          file,
          getPathFromUrl(file, true),
          res.data?.id || ""
        );
        console.log(r);
      }
      3;

      console.log({
        message: "Instance is attempting to start!",
        messageSeverity: "info",
        ...(cuda !== "" ? { cuda: cuda } : {}),
        ...(python !== "" ? { python: python } : {}),
      });

      history.push(
        `/org/${orgContext.activeOrgId}/environments/${res.data?.id || ""}`,
        {
          message: "Instance is attempting to start!",
          messageSeverity: "info",
          ...(cuda !== "" ? { cuda: cuda } : {}),
          ...(python !== "" ? { python: python } : {}),
        }
      );

      setLoading(false);
      setShowFinalDeployMessage(false);

      if (res.data) {
        // Here we'd go right into the provisioner component with all of the parameters and start querying the workspace.
        // Step 1 could be to just poll until the workspace is ready and then redirect - later we can add in some retries.
        const updatedWorkspace = new Workspace(res.data);
        // setWorkspace(updatedWorkspace);
      }
    } else if (res.message.includes("duplicate workspace with name")) {
      // setLoading(false);
      setShowFinalDeployMessage(false);
      setNameFieldErrorMessage(res.message);
    } else {
      console.error("Error in create instance: ", res.message);
      // errorRef.current?.scrollIntoView({ behavior: "smooth" });
      // TODO: if SSH step, show a different message
      if (
        res.message.includes("user not allowed to create workspace of type")
      ) {
        setInlineAlertMessage(
          "You need to add a payment method to access more powerful machines. You can do that from your org settings."
        );
        setInlineAlertSeverity("error");
        setInlineAlertAction(
          <Button
            label="Add payment method"
            type="secondary"
            hrefInNewTab={`/org/${orgContext.activeOrgId}/settings`}
            onClick={() => {
              history.push(`/org/${orgContext.activeOrgId}/settings`);
            }}
          />
        );
      } else if (res.message.includes("have requested more vCPU capacity")) {
        setInlineAlertMessage(
          "You have a limit on your AWS account preventing you from creating envs with this many vCPUs. Either create an env with the Brev Cloud or contact AWS support to increase your limit: https://aws.amazon.com/contact-us/ec2-request"
        );
        setInlineAlertSeverity("warning");
        setInlineAlertAction(<></>);
        // If its GCP it may take longer than browser expects
      } else if (res.message === "") {
        setInlineAlertMessage(
          "The provider has taken longer to respond than expected, the instance has likely been created. Refresh the instances page to check, if not try creating in a different region"
        );
        setInlineAlertSeverity("warning");
        setInlineAlertAction(<></>);
      } else if (
        res.message.includes(
          "There was a billing issue because there is no payment method visit the settings page to resolve this issue"
        )
      ) {
        setInlineAlertMessage(
          "There was a billing issue because there is no payment method visit the settings page to resolve this issue"
        );
        setInlineAlertSeverity("warning");
        setInlineAlertAction(
          <Button
            label="Add payment method"
            type="secondary"
            hrefInNewTab={`/org/${orgContext.activeOrgId}/settings`}
            onClick={() => {
              history.push(`/org/${orgContext.activeOrgId}/settings`);
            }}
          />
        );
      } else {
        setInlineAlertMessage(`Error creating instance: ${res.message}`);
        setInlineAlertSeverity("error");
        setInlineAlertAction(<></>);
      }

      setNameFieldErrorMessage("");
      setLoading(false);
      setShowFinalDeployMessage(false);
      return null;
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("in instance create looking for file: ", file);
    const defaultJupyterImageUrl =
      "https://uohmivykqgnnbiouffke.supabase.co/storage/v1/object/public/landingpage/jupyter.png";
    getImageUrl(file, notebooks, defaultJupyterImageUrl).then(setImageUrl);
  }, [file, notebooks]);

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-10 pt-5">
      {billingContext.billingProfile?.billing_type === "credit" ? (
        <AddCreditsModal
          show={showConnectCloudsOrCardModal}
          setShow={setShowConnectCloudsOrCardModal}
          onCancel={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setNoCardOnFile={setNoCardOnFile}
          noCardOnFile={noCardOnFile}
          onSuccess={() => {
            setShowConnectCloudsOrCardModal(false);
            setInlineAlertSeverity("success");
            setInlineAlertMessage("Credit card details saved successfully!");
          }}
          setSelectedCloudName={setSelectedCloudName}
          selectedCloudName={selectedCloudName}
        />
      ) : (
        <ConnectCloudsOrCardModal
          show={showConnectCloudsOrCardModal}
          setShow={setShowConnectCloudsOrCardModal}
          onCancel={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setNoCardOnFile={setNoCardOnFile}
          onSuccess={() => {
            setShowConnectCloudsOrCardModal(false);
            setInlineAlertSeverity("success");
            setInlineAlertMessage("Credit card details saved successfully!");
          }}
          setSelectedCloudName={setSelectedCloudName}
          selectedCloudName={selectedCloudName}
        />
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="my-3 text-left sm:mt-5">
          {!orgContext.isInstancesLoading && (
            <>
              {(file !== "" ||
                (!!props.cuda && !!props.python) ||
                !!props.baseImage) && ( //TODO: this logic needs to be changed as we select python and cuda on this page. We should check the query params
                <FlatCard className="mb-8">
                  <div className="flex flex-row justify-between items-center">
                    <div>
                      <NoteBookLoadedFromTemplateString
                        isNotebookLoaded={file !== ""}
                      />

                      {file !== "" && (
                        <button
                          type="button"
                          className={classNames(
                            "group flex w-full items-center justify-between space-x-3 rounded-full p-2 text-left shadow-sm dark:focus:ring-highlightLighter focus:ring-offset-2",
                            "max-w-[400px]",
                            "cursor-default"
                          )}
                        >
                          <span className="flex min-w-0 flex-1 items-center space-x-3">
                            <span className="block flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={imageUrl}
                                alt=""
                              />
                            </span>
                            <span className="block min-w-0 flex-1">
                              <span className="block truncate text-sm font-medium text-gray-900 dark:text-secondary">
                                {notebooks.find((n) => n.url === file)?.name ||
                                  file.split("/").pop()}
                              </span>
                              <span
                                className="block truncate text-xs font-medium text-gray-500 dark:text-slate-500 hover:underline hover:text-highlight dark:hover:text-highlight cursor-pointer"
                                onClick={() => {
                                  window.open(
                                    replaceRawWithBlob(
                                      notebooks.find((n) => n.url === file)
                                        ?.url || replaceRawWithBlob(file)
                                    ),
                                    "_blank"
                                  );
                                }}
                              >
                                {notebooks.find((n) => n.url === file)?.url ||
                                  file}
                              </span>
                            </span>
                          </span>
                        </button>
                      )}

                      <div className="flex flex-row">
                        {props.instance ? (
                          <Chip
                            key={`template-preview-${props.instance}`}
                            selected={true}
                            chipName={
                              selectedInstanceType?.supported_gpus[0].name || ""
                            }
                            manufacturerName={
                              selectedInstanceType?.supported_gpus[0]
                                .manufacturer || ""
                            }
                            onClick={() => {}}
                            className={classNames(
                              "h-[68px] mr-2 flex-shrink-0"
                            )}
                            whiteGlow={true}
                          />
                        ) : (
                          ""
                        )}

                        <div className="flex flex-col items-center justify-center">
                          {baseImage ? (
                            <>
                              <div
                                className={classNames(
                                  "flex items-center text-xs text-gray-700 dark:text-secondary mt-1"
                                )}
                              >
                                <RectangleGroupIcon className="h-6 w-6 mr-1" />
                                <span>
                                  Using Base Image:{" "}
                                  <span className="dark:text-white">
                                    {baseImage}
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              {python && (
                                <div className="flex items-center text-xs text-gray-700 dark:text-secondary mt-1">
                                  <div className="w-[24px] flex flex-row justify-center items-center mr-1">
                                    <svg
                                      width="16"
                                      height="16"
                                      className="text-gray-700 dark:text-secondary"
                                      viewBox="0 0 112 113"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M54.9188 0.000919274C50.3351 0.0222173 45.9578 0.413137 42.1063 1.09467C30.7601 3.09917 28.7 7.29477 28.7 15.0322V25.2509H55.5125V28.6572H28.7H18.6375C10.8451 28.6572 4.02178 33.3409 1.88754 42.2509C-0.574285 52.4639 -0.68348 58.8369 1.88754 69.5009C3.79346 77.4388 8.34508 83.0947 16.1375 83.0947H25.3563V70.8447C25.3563 61.9948 33.0134 54.1884 42.1063 54.1884H68.8875C76.3425 54.1884 82.2938 48.0503 82.2938 40.5634V15.0322C82.2938 7.76583 76.1638 2.30739 68.8875 1.09467C64.2815 0.327944 59.5024 -0.020379 54.9188 0.000919274ZM40.4188 8.21967C43.1883 8.21967 45.45 10.5183 45.45 13.3447C45.45 16.161 43.1883 18.4384 40.4188 18.4384C37.6393 18.4384 35.3875 16.161 35.3875 13.3447C35.3875 10.5183 37.6393 8.21967 40.4188 8.21967Z" />
                                      <path d="M85.6375 28.6572V40.5634C85.6375 49.7942 77.8116 57.5634 68.8875 57.5634H42.1063C34.7704 57.5634 28.7 63.8419 28.7 71.1884V96.7197C28.7 103.986 35.0186 108.26 42.1063 110.345C50.5936 112.84 58.7325 113.291 68.8875 110.345C75.6377 108.39 82.2938 104.457 82.2938 96.7197V86.5009H55.5125V83.0947H82.2938H95.7C103.492 83.0947 106.396 77.6593 109.106 69.5009C111.906 61.102 111.786 53.0251 109.106 42.2509C107.18 34.4935 103.502 28.6572 95.7 28.6572H85.6375ZM70.575 93.3134C73.3545 93.3134 75.6063 95.5908 75.6063 98.4072C75.6063 101.234 73.3545 103.532 70.575 103.532C67.8055 103.532 65.5438 101.234 65.5438 98.4072C65.5438 95.5908 67.8055 93.3134 70.575 93.3134Z" />
                                    </svg>
                                  </div>
                                  Python version: {python}
                                </div>
                              )}
                              {cuda && (
                                <div
                                  className={classNames(
                                    "flex items-center text-xs text-gray-700 dark:text-secondary mt-1"
                                  )}
                                >
                                  <svg
                                    width="24"
                                    height="16"
                                    viewBox="0 0 249 165"
                                    className="mr-1 text-gray-700 dark:text-secondary"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
                                  </svg>
                                  CUDA version: {cuda}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {!loading && (
                        <Button
                          className={classNames(
                            "mt-2",
                            selectedInstanceType && !loading
                              ? "pulse-ready-button"
                              : "",
                            loading ? "paused" : ""
                          )}
                          type={selectedInstanceType ? "primary" : "secondary"}
                          label={loading ? "Deploying" : "Deploy"}
                          disabled={loading}
                          onClick={() => {
                            scrollToDeployButton();
                            setTimeout(() => {
                              handleCompletion();
                            }, 301);
                          }}
                          loading={loading}
                        />
                      )}
                    </div>
                  </div>
                </FlatCard>
              )}
            </>
          )}
        </div>
        <ComputePicker
          selectedInstance={selectedInstanceType}
          setSelectedInstance={setSelectedInstanceType}
          storage={storage}
          setStorage={setStorage}
          dataPondAttachments={datapondAttachments}
          setDataPondAttachments={setDatapondAttachments}
        />
        {!orgContext.isInstancesLoading && (
          <>
            {selectedInstanceType && selectedInstanceType.supported_gpus && (
              <div>
                <div
                  className="flex flex-row cursor-pointer"
                  onClick={() =>
                    setShowContainerSettings(!showContainerSettings)
                  }
                >
                  <span className="text-sm text-highlight dark:text-cyan-300 font-semibold hover:text-cyan-700 dark:hover:text-highlight mr-1">
                    Advanced Container Settings
                  </span>
                  {showContainerSettings ? (
                    <ChevronDownIcon width={18} color="cyan" />
                  ) : (
                    <ChevronRightIcon width={18} color="cyan" />
                  )}
                </div>
                <div>
                  <Collapse
                    in={showContainerSettings}
                    sx={{
                      marginTop: "12px",
                      maxHeight: "500px",
                    }}
                  >
                    <div className="pt-2 pb-8">
                      <div className="flex flex-row items-center mt-2">
                        <BaseImageSelector
                          baseImage={baseImage}
                          setBaseImage={setBaseImage}
                          namePortMapping={portMappings || {}}
                          setNamePortMapping={setPortMapping}
                          setVmOnlyMode={setVmOnlyMode}
                          vmOnlyMode={vmOnlyMode}
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            )}
            <h3 className="mb-2 mt-4 text-xl font-medium leading-6 text-gray-900 dark:text-white">
              Provision your instance 🤙
            </h3>
            <p className="mt-6 text-sm text-gray-500 dark:text-secondary">
              Give it a name/ssh alias
            </p>
            <InputField
              // key={index}
              label="Name"
              hideLabel={true}
              value={instanceName}
              placeholder="golang-backend"
              errorMessage={nameFieldErrorMessage}
              onChange={handleNameChange}
              className="CodeCard-GitUrlInputField"
            />
            {/* <p className="mt-6 text-sm text-gray-500 dark:text-secondary">
              (Optional) Provide a base container
            </p>
            <InputField
              // key={index}
              label="Name"
              hideLabel={true}
              value={instanceName}
              placeholder="golang-backend"
              errorMessage={nameFieldErrorMessage}
              onChange={handleNameChange}
              className="CodeCard-GitUrlInputField"
            /> */}
            <DevToggle>
              <p className="mt-1 text-sm text-gray-500 dark:text-secondary mb-4">
                Your machine will be set up with{" "}
                <VersionSelector
                  label="Python"
                  options={pythonVersions}
                  onSelect={handleSelectPython}
                  isOpen={openDropdown === "Python"}
                  onToggle={() => toggleDropdown("Python")}
                  defaultValue={`3.10`}
                />{" "}
                and{" "}
                <VersionSelector
                  label="CUDA"
                  options={cudaVersions}
                  onSelect={handleSelectCuda}
                  isOpen={openDropdown === "CUDA"}
                  onToggle={() => toggleDropdown("CUDA")}
                  defaultValue={`12.0.1`}
                />
              </p>
            </DevToggle>
            <p className="mt-1 text-sm text-gray-500 dark:text-secondary mb-4">
              You can setup software (like cuda, python) on the next page, while
              it's deploying
            </p>

            <InlineNotification
              show={!!inlineAlertMessage}
              severity={inlineAlertSeverity}
              className="w-full mb-2 pb-2"
              text={inlineAlertMessage}
              text2={<div className="mt-2 ml-[36px]">{inlineAlertAction}</div>}
              autoClose={false}
              onClose={() => {
                setInlineAlertMessage("");
                setInlineAlertAction(<></>);
                setInlineAlertSeverity("error");
              }}
            />
            <Button
              ref={deployButtonRef}
              className={classNames(
                "mt-2",
                selectedInstanceType && !loading ? "pulse-ready-button" : "",
                loading ? "paused" : ""
              )}
              type={selectedInstanceType ? "primary" : "secondary"}
              label={loading ? "Deploying" : "Deploy"}
              disabled={loading || !billingContext.billingProfile?.billing_type}
              onClick={handleCompletion}
              loading={loading}
              data-testid="deploy-button"
            />
            <DevToggle>
              {selectedInstanceType && (
                <Button
                  ref={deployButtonRef}
                  className={classNames(
                    "mt-2 ml-4",
                    selectedInstanceType && !loading ? "" : "",
                    loading ? "paused" : ""
                  )}
                  type={"secondary"}
                  label={"Share configuration"}
                  disabled={
                    loading || !billingContext.billingProfile?.billing_type
                  }
                  onClick={createShareableDeploymentLink}
                  loading={loading}
                />
              )}
            </DevToggle>
            <DevToggle>
              {showSharableURL && (
                <SharableLinkCopied
                  link={sharableURL}
                  notebookLink={notebookLink}
                />
              )}
              {showFinalDeployMessage && <WaitingOnAPIString />}
            </DevToggle>
          </>
        )}
      </div>
    </div>
  );
};
export default InstanceCreate;

function findLowestPriceAndRegionByType(
  allRegionPricing: {
    [region: string]: {
      [type: string]: ComputePricingObject;
    };
  },
  targetType: string
): { region: string; price: Price } | null {
  let lowestPrice: Price | null = null;
  let lowestRegion: string | null = null;

  for (const region in allRegionPricing) {
    const pricing = allRegionPricing[region][targetType];
    if (pricing) {
      // Assuming you want to find the lowest price based on onDemandPrice. If you want to check spotPrice, you can change accordingly.
      const currentPrice = pricing.onDemandPrice;

      if (
        !lowestPrice ||
        currentPrice.pricePerUnit < lowestPrice.pricePerUnit
      ) {
        lowestPrice = currentPrice;
        lowestRegion = region;
      }
    }
  }

  if (lowestRegion && lowestPrice) {
    return { region: lowestRegion, price: lowestPrice };
  }

  return null; // No price found for the given type
}

{
  /* {roundPriceToTwoDigits(
                              getInstanceTypeCostProductApi(
                                instanceType.type,
                                prices,  // Assuming minPrices has the pricing data you need
                                spot,
                                [instanceType]
                              )?.toString()
                            )} •  */
}

interface NoteBookLoadedFromTemplateProps {
  isNotebookLoaded: boolean;
}

const NoteBookLoadedFromTemplateString: React.FC<
  NoteBookLoadedFromTemplateProps
> = (props) => {
  const [text] = useWindupString(
    props.isNotebookLoaded
      ? "Notebook loaded from template"
      : "Software loaded from template"
  );

  return <p className="text-md text-gray-700 dark:text-white">{text}</p>;
};

const WaitingOnAPIString = () => {
  const [text] = useWindupString(
    "Your instance is deploying! The cloud API calls to provision an instance sometimes takes a while. Blame Big Cloud, not us. Please hang tight..."
  );

  return (
    <p className="text-md mt-2 text-gray-700 dark:text-secondary">{text}</p>
  );
};

const JustKiddingH100 = () => {
  const [text, isFinished] = useWindupString(
    "Just kidding :) If you need H100s, please reach out directly."
  );

  return (
    <div>
      <h3 className="mt-5 mb-2 text-xl font-medium leading-6 text-gray-900 dark:text-white">
        {text}
      </h3>
      {isFinished && (
        <div className="contact-info mt-4">
          <p className="text-md mt-2 text-gray-700 dark:text-secondary">
            Discord →{" "}
            <a href="https://discord.gg/RpszWaJFRA">
              https://discord.gg/RpszWaJFRA
            </a>
          </p>
          <p className="text-md mt-2 text-gray-700 dark:text-secondary">
            Text us → (415) 237-2247
          </p>
          <p className="text-md mt-2 text-gray-700 dark:text-secondary">
            Email → <a href="mailto:nader@brev.dev">nader@brev.dev</a>
          </p>
        </div>
      )}
    </div>
  );
};

function VersionSelector({
  label,
  options,
  onSelect,
  isOpen,
  onToggle,
  defaultValue,
}) {
  const [selectedVersion, setSelectedVersion] = useState(defaultValue);

  const handleSelect = (option) => {
    setSelectedVersion(option);
    onSelect(option);
  };

  return (
    <div className="relative inline-block">
      <span
        className="text-sm text-highlight dark:text-cyan-300 font-semibold hover:text-cyan-700 dark:hover:text-cyan-100 mr-1 cursor-pointer"
        onClick={onToggle}
      >
        {label}: {selectedVersion}
      </span>
      {isOpen && (
        <div className="absolute z-10 bg-white dark:bg-slate-800 shadow-md mt-1 w-40 max-h-[9rem] overflow-y-auto mb-4">
          {options.map((option) => (
            <div
              key={option}
              className="text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-slate-600 p-2 cursor-pointer"
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
