import React from "react";
// import { classNames } from "components/utils";

const MagnifyingGlassIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-5 w-5 text-gray-400 dark:text-secondary"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
      />
    </svg>
  );
};
interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  placeholder?: string;
  disabled?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  onClick,
  placeholder,
  disabled,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="relative flex-grow focus-within:z-10 flex border dark:border-zinc-800 rounded-md">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon />
      </div>
      <input
        type="text"
        disabled={disabled}
        className="shadow-sm focus:ring-highlightLighter focus:border-highlight placeholder-gray-500 block w-full sm:text-sm border-r-0 border-gray-300 rounded-l-md dark:placeholder-slate-400 dark:text-secondary dark:bg-zinc-900 dark:border-zinc-800 py-1.5 pl-10"
        placeholder={placeholder || "Search"}
        value={value}
        onChange={handleInputChange}
      />
      <button
        onClick={onClick}
        className="py-1.5 px-4 border-l border-gray-300 dark:border-zinc-800 text-gray-700 dark:text-secondary bg-white dark:bg-zinc-900 hover:bg-gray-50 dark:hover:bg-zinc-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-zinc-900 focus:ring-highlightLighter rounded-r-md"
      >
        Search
      </button>
    </div>
  );
};

export default SearchInput;
