// react component with a header that says hi
import { classNames } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import React from "react";
import "./GetCode.scss";

const GetCode = () => {
  console.log("Route: /buildspace-lootbox");

  return (
    <div className="flex flex-col justify-start items-center mt-[30vh] Get-Code">
      <div className="flex flex-column items-center w-full justify-center box-list">
        <LootBox />
      </div>
    </div>
  );
};

export default GetCode;

// Note: LootBox is dependent on the .Get-Code class
const LootBox = () => {
  const [state, setState] = React.useState<"closed" | "open">("closed");
  const [reveal, setReveal] = React.useState(false);
  return (
    <div>
      <GiftCard visible={state === "open"} />
      <div
        className="box-container"
        onClick={() => {
          if (state === "closed") {
            // setState("open");
            setReveal(true);
            setTimeout(() => {
              setReveal(false);
              setState("open");
            }, 500);
          } else {
            // setState("closed");
          }
        }}
      >
        <button
          type="button"
          className={`box ${state} ${reveal ? "click" : ""}`}
        />
        <span className="loot-shadow" />
      </div>
    </div>
  );
};

const GiftCard = ({ visible }: { visible: boolean }) => {
  const orgContext = React.useContext(OrgContext);
  return (
    <div className={classNames("flex gift-card", visible ? "" : "hidden")}>
      <div className="gift-card__image" />
      <section className="gift-card__content">
        <div className="text-3xl">10 free GPU hours</div>
        <div className="gift-card__code">_buildspace_brev_2023</div>
        <div className="gift-card__msg">
          Use this code to redeem credits on the{" "}
          <a
            className="underline text-cyan-600"
            href={`/org/${orgContext.activeOrg?.id}/settings?credits=true`}
          >
            settings page
          </a>
        </div>
      </section>
    </div>
  );
};
