import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "components/utils";

interface InputFieldProps {
  label: string;
  placeholder?: string;
  value: string;
  initialValue?: string;
  type?: string;
  errorMessage: string;
  onChange: (v: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  trailingCharacters?: string;
  required?: boolean;
  newDarkTheme?: boolean;
  validation?: (value: string) => boolean;
}

const InputField: React.FC<InputFieldProps> = (props) => {
  const rdm_id = uuid().slice(0, 6);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    props.onChange(newValue);
  };

  const getInputContent = () => {
    if (props.multiline) {
      return (
        <textarea
          id={`${rdm_id}`}
          name="Value"
          rows={3}
          disabled={props.disabled}
          className="block w-full rounded-md border-gray-300 dark:border-zinc-800 dark:bg-secondaryBg dark:placeholder-slate-600 shadow-sm focus:border-highlight focus:ring-highlight sm:text-sm"
          placeholder={props.placeholder || "File contents"}
          value={props.value}
          onChange={handleInputChange}
        />
      );
    }
    return (
      <input
        disabled={props.disabled}
        type={props.type || "text"}
        name={`${rdm_id}`}
        id={`${rdm_id}`}
        className={classNames(
          props.errorMessage?.length > 0
            ? "block w-full pr-10 border-red-300 dark:border-red-500 text-red-900 dark:text-red-50 placeholder-red-300 focus:outline-none focus:ring-red-500 dark:bg-zinc-900 focus:border-red-500 sm:text-sm rounded-md"
            : "shadow-sm focus:ring-highlight focus:border-highlight placeholder-grey-100 block w-full sm:text-sm dark:text-secondary dark:border-zinc-800 dark:bg-secondaryBg rounded-md",
          props.newDarkTheme
            ? "p-2 mb-4 w-full border rounded dark:border-zinc-800 dark:bg-secondaryBg  dark:border-highlight dark:text-white"
            : ""
        )}
        placeholder={props.placeholder}
        value={props.value}
        onChange={handleInputChange}
        onKeyDown={props.onKeyDown}
        aria-invalid="true"
        aria-describedby={`${rdm_id}-error`}
      />
    );
  };

  return (
    <div className={props.className || ""}>
      {!props.hideLabel && (
        <div className="flex flex-row">
          <label
            htmlFor={`${rdm_id}`}
            className="mb-1 block text-sm font-medium text-gray-700 dark:text-secondary"
          >
            {props.label}
          </label>
          {props.required && (
            <span className="ml-1 text-red-400 leading-5">*</span>
          )}
        </div>
      )}
      {props.hideLabel && props.required && (
        <span className="text-red-400">*</span>
      )}
      <div
        className={`${
          props.errorMessage?.length > 0 ? "relative rounded-md shadow-sm" : ""
        }`}
      >
        {getInputContent()}
        {props.trailingCharacters && !props.errorMessage?.length && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span
              className="text-gray-500 dark:text-secondary sm:text-sm"
              id="price-currency"
            >
              {props.trailingCharacters}
            </span>
          </div>
        )}
        {props.errorMessage?.length > 0 && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500 dark:text-red-50"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {props.errorMessage?.length > 0 && (
        <p className="mt-2 text-sm text-red-500" id={`${rdm_id}-error`}>
          {props.errorMessage}
        </p>
      )}
    </div>
  );
};

export default InputField;
