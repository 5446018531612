import React, { useState, useRef, useEffect, useContext } from "react";
import Button from "components/UI-lib/Button";
import "./styles.scss";
import Cards from "./Cards";
import { motion, AnimatePresence } from "framer-motion";
import { BillingContext } from "contexts/BillingContext";
import { useHistory } from "react-router";
import { OrgContext } from "contexts/OrgContext";
import agent, { PermissionsRes } from "server";
import { timeout } from "components/utils";
import { useWindupString } from "windups";
import RedeemableFlow from "./RedeemableFlow";

const RedeemLaunchableCredits: React.FC = () => {
  console.log("Route: /org/:orgId/launchables/redeem");
  const billingContext = useContext(BillingContext);
  const orgContext = useContext(OrgContext);

  const handleRedeem = async () => {
    return await agent.Launchables.redeemLaunchablePoints(
      orgContext.activeOrgId
    );
  };

  return (
    <RedeemableFlow
      title="You earned free GPU credits!"
      description="Congratulations! Your resources are getting used to spin up GPUs which earned you credits."
      redeemableLabel="GPU Credits will be automatically applied to your account"
      redeemableValue={billingContext.launchablePoints}
      redeemFunction={handleRedeem}
      fetchUpdatedData={() => {
        billingContext.fetchBillingProfile();
      }}
      backPath={`/org/${orgContext.activeOrgId}/launchables`}
      backLabel="Go Back"
      successMessage="Credits have been successfully redeemed!"
      errorMessage="Error redeeming credits. Please try again or contact support."
    />
  );
};

export default RedeemLaunchableCredits;
