/* There's probably a better API for dropdown but this works for now */
import React, { Fragment, useEffect, useState, useContext } from "react";
import { Menu, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import Button from "components/UI-lib/Button";
import agent, { WorkspaceGroup } from "server";
import { OrgContext } from "contexts/OrgContext";

interface DropdownProps {
  children: React.ReactNode;
  label: string;
  onChange: (item: any) => void;
  value: string;
  displayFunction?: (item: any) => any;
  // tech debt: im worried about removing the mt-1 because another UI frame might need it
  // ideally, the mt-1 should be just used where it's needed
  noMargin?: boolean;
  disabled?: boolean;
  className?: string;
  required?: boolean;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const [selected, setSelected] = useState(props.value);
  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  return (
    <Listbox
      value={selected}
      onChange={(s) => {
        setSelected(s);
        props.onChange(s);
      }}
      disabled={props.disabled}
    >
      {({ open }) => (
        <div
          className={classNames(
            "flex flex-col",
            props.className ? props.className : ""
          )}
        >
          <Listbox.Label className="block text-sm font-medium text-gray-700 dark:text-slate-400">
            {props.label}{" "}
            {props.required && (
              <span className=" leading-5 text-red-400">*</span>
            )}
          </Listbox.Label>
          <div className={classNames("relative", props.noMargin ? "mt-1" : "")}>
            <Listbox.Button
              className={classNames(
                "bg-primaryBg relative w-full border border-1 border-zinc-800 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-highlight focus:border-highlight sm:text-sm text-white",
                props.disabled ? "opacity-30" : ""
              )}
            >
              <span className="block truncate">
                {props.displayFunction
                  ? props.displayFunction(selected)
                  : selected}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-primaryBg shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm text-white">
                {props.children}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default Dropdown;

interface DropdownItemProps {
  value: string;
  displayValue: string;
  depreciateButton?: boolean;
  secondaryDisplayValue?: string;
}

export const DropdownItem: React.FC<DropdownItemProps> = (props) => {
  const orgContext = useContext(OrgContext);

  return (
    <Listbox.Option
      key={props.value}
      className={({ active }) =>
        classNames(
          active ? "text-white bg-highlight" : "text-white",
          "flex cursor-default select-none relative py-2 pl-3 pr-9"
        )
      }
      value={props.value}
    >
      {({ selected, active }) => (
        <>
          <div className="flex whitespace-nowrap">
            {selected ? (
              <span
                className={classNames(
                  active ? "text-white" : "text-highlight",
                  "flex items-center mr-1"
                )}
              >
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
            <span
              className={classNames(
                selected ? "font-semibold" : "font-normal",
                "block truncate"
              )}
            >
              {props.displayValue}
            </span>
            {props.secondaryDisplayValue && (
              <span
                className={classNames(
                  active ? "text-cyan-200" : "text-gray-500",
                  "ml-2 whitespace-nowrap	"
                )}
              >
                {props.secondaryDisplayValue}
              </span>
            )}
          </div>
          {props.depreciateButton &&
          !selected &&
          props.displayValue !== "Brev Public: AWS" ? (
            <button
              type="button"
              className="h-[20px] absolute right-0 flex items-center pr-4 rounded-md border border-borderColor rounded-md shadow-sm text-sm font-medium bg-white dark:bg-zinc-900 hover:bg-gray-100 dark:hover:bg-zinc-700 text-black dark:text-slate-400 focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-highlightLighter sm:w-auto px-4 py-2  mr-1"
              onClick={async (e) => {
                e.preventDefault();
                await agent.Organizations.depracateWorkspaceGroup(props.value);
                orgContext.refreshWorkspaceGroups();
              }}
            >
              Deprecate
            </button>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};
