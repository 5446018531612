import React, { useEffect } from "react";
import useQueryParam from "useQueryParams";

interface Props {
  children: React.ReactNode;
}
const DevToggle: React.FC<Props> = (props) => {
  const [isDev, setIsDev] = useQueryParam("dev", "");

  if (isDev === "false") {
    return <></>;
  }
  if (process.env.NODE_ENV === "development" || isDev) {
    return <>{props.children}</>;
  }
  return <></>;
};

export default DevToggle;

interface FeatureToggleProps {
  children: React.ReactNode;
  feature: string;
}
export const FeatureToggle: React.FC<FeatureToggleProps> = (props) => {
  const [isEnabled, setIsEnabled] = useQueryParam(props.feature, "");

  if (isEnabled) {
    return <>{props.children}</>;
  }
  return <></>;
};

// This hides the component when the dev toggle is visible.
interface Props {
  children: React.ReactNode;
}
export const ProdToggle: React.FC<Props> = (props) => {
  const [isDev, setIsDev] = useQueryParam("dev", "");

  if (isDev === "false") {
    return <>{props.children}</>;
  }
  if (process.env.NODE_ENV === "development" || isDev) {
    return <></>;
  }
  return <>{props.children}</>;
};
