import { ChipCarousel } from "components/Environment/shared/Carousel/ChipCarousel";
import React, { useState } from "react";

interface ComputeLoaderProps {}

const ComputeLoader: React.FC<ComputeLoaderProps> = ({}) => {
  return (
    <div className="flex flex-col w-full items-center ">
      <div>
        <ChipCarousel />
      </div>
      <p className="text-white font-mono">Loading Instances...</p>
    </div>
  );
};

export default ComputeLoader;
