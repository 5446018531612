import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import { classNames, roundPriceToTwoDigits } from "components/utils";
import React, { useContext, useEffect, useState } from "react";
import { ComputePricingObject, OrgContext } from "contexts/OrgContext";
import {
  convertToBytes,
  getInstanceTypeCostProductApi,
  getLowestPriceComputeObject,
  shiftElementToFront,
  sortByLowestRegion,
} from "utils/CreateWorkspaceUtils";
import InputField from "components/UI-lib/InputField";
import { StyledRangeSlider } from "components/UI-lib/Slider";
import MultiSelectDropdown from "components/UI-lib/MultiSelectDropDown";
import { GPUInstanceType, WSGWithLocations } from "./GPUTypes";
import Toggle from "components/UI-lib/Toggle";
import { Collapse } from "@mui/material";

const DEFAULT_GPU = "p3.2xlarge";
const transformGpuInstanceTypes = (instanceTypes: GPUInstanceType[]) => {
  const output: { [type: string]: GPUInstanceType[] } = {};
  instanceTypes.forEach((instanceType) => {
    const gpuName = `${instanceType.supported_gpus[0].manufacturer.replace(
      /\s/g,
      ""
    )} ${instanceType.supported_gpus[0].name.replace(/\s/g, "")}`;
    if (output[gpuName]) {
      output[gpuName].push(instanceType);
    } else {
      output[gpuName] = [instanceType];
    }
  });
  return output;
};

const sortTransformedNewType = (
  gpuRows: GPUInstanceType[][],
  attributeName: string,
  ascend: boolean
) => {
  let sortedKeys;
  if (attributeName === "default_price") {
    sortedKeys = gpuRows.sort((a, b) => {
      if (ascend) {
        return parseFloat(a[0].default_price) - parseFloat(b[0].default_price);
      }
      return parseFloat(b[0].default_price) - parseFloat(a[0].default_price);
    });
  } else if (attributeName === "gpu_mem") {
    sortedKeys = gpuRows.sort((a, b) => {
      if (ascend) {
        return (
          parseFloat(a[0].supported_gpus[0].memory) -
          parseFloat(b[0].supported_gpus[0].memory)
        );
      }
      return (
        parseFloat(b[0].supported_gpus[0].memory) -
        parseFloat(a[0].supported_gpus[0].memory)
      );
    });
  }
  return sortedKeys;
};

const putIntoListsOfGpus = (instanceTypes: GPUInstanceType[]): {} => {
  const output: { [gpuName: string]: GPUInstanceType[] } = {};
  instanceTypes.forEach((instanceType) => {
    const gpuName = `${instanceType.supported_gpus[0].manufacturer} ${instanceType.supported_gpus[0].name}`; // TODO: `${instanceType.supported_gpus[0].manufacturer.replace(
    if (output[gpuName]) {
      output[gpuName].push(instanceType);
    } else {
      output[gpuName] = [instanceType];
    }
  });
  // return values of list of gpus
  return output;
};

const sortLowestToHigestPrice = (list: GPUInstanceType[]) => {
  return list.sort((a, b) => {
    if (isNaN(Number(a.base_price?.amount))) {
      return 1;
    } else if (isNaN(Number(a.base_price?.amount))) {
      return -1;
    } else {
      return Number(a.base_price?.amount) - Number(b.base_price?.amount);
    }
  });
};

const NonCudaReadyMap = {
  "AMD Radeon Pro V520": true,
  "Habana Gaudi HL-205": true,
  "NVIDIA K80": true,
  "NVIDIA K520": true,
  "NVIDIA L4": true,
};

interface GPUSelectorTableProps {
  selectedCloudName?: string;
  selectedInstanceType: string;
  onChange: (instanceType: string) => void;
  initialInstances: GPUInstanceType[];
  spot: boolean;
  setSpot: (spot: boolean) => void;
}

const GPUSelectorTable: React.FC<GPUSelectorTableProps> = ({
  selectedCloudName,
  selectedInstanceType,
  onChange,
  initialInstances,
  spot,
  setSpot,
  // selectedInstance,
}) => {
  const orgContext = useContext(OrgContext);
  const [instances, setInstances] = React.useState<any>({});
  const [displayInstances, setDisplayInstances] = React.useState<
    GPUInstanceType[]
  >([]);
  const [instanceNames, setInstanceNames] = React.useState<string[]>([]);
  const [sortedInstanceNames, setSortedInstanceNames] = React.useState<
    string[]
  >([]);
  const [selectedInstanceNames, setSelectedInstanceNames] = React.useState<
    string[]
  >(["Any"]);

  const [combinedInstances, setCombinedInstances] = React.useState<
    GPUInstanceType[]
  >([]);

  const [isCudaReady, setIsCudaReady] = React.useState<boolean>(true);
  const [lowestPriceMap, setLowestPriceMap] = React.useState<any>({});

  useEffect(() => {
    if (instances.Any) {
      setCombinedInstances(getGPUsFromNames(selectedInstanceNames, instances));
    }
  }, [selectedInstanceNames, instances]);

  const setGPUNames = (gpus: string[]) => {
    setInstanceNames(gpus);
  };

  const processGpus = (gpus: GPUInstanceType[]) => {
    const groupedByGpuName: any = putIntoListsOfGpus(gpus);
    groupedByGpuName.Any = gpus;
    setInstances(groupedByGpuName);
  };

  const filterNameListByCudaReady = (nameList: string[]) => {
    return nameList.filter((name) => !NonCudaReadyMap[name]);
  };

  const getGPUsFromNames = (
    instanceNames: string[],
    instances
  ): GPUInstanceType[] => {
    if (instanceNames.includes("Any")) {
      return instances.Any;
    }
    let combineInstances: GPUInstanceType[] = [];
    for (const name of instanceNames) {
      combineInstances = combineInstances.concat(instances[name]);
    }
    return combineInstances;
  };

  const createNameToPriceMap = (gpuGroupList: any) => {
    const nameToLowestPrice = {};
    for (const gpuName of Object.keys(gpuGroupList)) {
      if (gpuName === "Any") {
        nameToLowestPrice[gpuName] = "";
      } else {
        nameToLowestPrice[gpuName] =
          `${roundPriceToTwoDigits(
            gpuGroupList[gpuName][0].base_price?.amount
          )}` || "";
      }
    }
    setLowestPriceMap(nameToLowestPrice);
  };

  const createNameList = (gpuGroupList: any, isCudaReady: boolean) => {
    let gpuNameList = Object.keys(gpuGroupList);
    gpuNameList = shiftElementToFront(gpuNameList, "Any");
    if (isCudaReady) {
      setGPUNames(filterNameListByCudaReady(gpuNameList));
    } else {
      setGPUNames(gpuNameList);
    }
  };

  useEffect(() => {
    createNameToPriceMap(instances);
    createNameList(instances, isCudaReady);
  }, [instances, isCudaReady]);

  useEffect(() => {
    if (initialInstances.length > 0) {
      processGpus(initialInstances);
    }
  }, [initialInstances]);

  useEffect(() => {
    if (displayInstances.length > 0) {
      if (
        displayInstances.findIndex(
          (gpuInstance) => gpuInstance.type === selectedInstanceType
        ) === -1
      ) {
        onChange(displayInstances[0].type);
      }
    }
  }, [displayInstances]);

  useEffect(() => {
    if (instanceNames.length > 0) {
      setSortedInstanceNames(instanceNames);
    }
  }, [instanceNames]);

  // so we need a function to find out if an instance type is present in a row
  const isInstanceTypeInRow = (instanceType: string, row: GPUInstanceType[]) =>
    row.some((instance: GPUInstanceType) => instance.type === instanceType);

  return (
    <div className="flex flex-col ">
      <div className="inline-block min-w-full align-middle dark:text-secondary">
        <MultiSelectDropdown
          label="Select GPU(s)"
          value={selectedInstanceNames}
          displayFunction={(selectedNames) =>
            selectedNames.map((value) => value).join(", ")
          }
          onChange={(instanceNames) => setSelectedInstanceNames(instanceNames)}
          useExclusive="Any"
        >
          {sortedInstanceNames.map((gpu, index) => (
            <DropdownItem
              displayValue={gpu}
              value={gpu}
              key={index}
              secondaryDisplayValue={
                `${!!lowestPriceMap[gpu] ? "Min. $" : ""}${
                  lowestPriceMap[gpu]
                }` || ""
              }
            />
          ))}
        </MultiSelectDropdown>
        <FilterList
          isCudaReady={isCudaReady}
          setIsCudaReady={setIsCudaReady}
          displayInstances={displayInstances}
          setDisplayInstances={setDisplayInstances}
          initialList={combinedInstances}
          spot={spot}
          setSpot={setSpot}
        />
        <div className="shadow-sm ring-1 ring-black ring-opacity-5 overflow-auto mt-3 max-h-[400px] min-w-[808px]">
          <table
            className="min-w-full border-separate overflow-y dark:border-zinc-800"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50 dark:bg-zinc-800">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter min-w-[100px]"
                >
                  <div
                    className="group inline-flex"
                    // onClick={() => {
                    //   sortBy("gpu-mem");
                    // }}
                  >
                    Name
                  </div>
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter min-w-[100px]"
                >
                  <div
                    className="group inline-flex"
                    // onClick={() => {
                    //   sortBy("gpu-mem");
                    // }}
                  >
                    GPU Memory
                  </div>
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter min-w-[100px]"
                >
                  <div
                    className="group inline-flex"
                    // onClick={() => {
                    //   sortBy("GPU");
                    // }}
                  >
                    GPUs
                  </div>
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter min-w-[100px]"
                >
                  <div
                    className="group inline-flex"
                    // onClick={() => {
                    //   sortBy("vCPU");
                    // }}
                  >
                    CPUs
                  </div>
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter min-w-[100px]"
                >
                  <div
                    className="group inline-flex"
                    // onClick={() => {
                    //   sortBy("mem");
                    // }}
                  >
                    Memory
                  </div>
                </th>

                <th
                  scope="col"
                  className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter min-w-[100px]"
                >
                  <div
                    className="group inline-flex"
                    // onClick={() => {
                    //   sortBy("price");
                    // }}
                  >
                    Min. Price
                  </div>
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white backdrop-blur backdrop-filter min-w-[100px]"
                >
                  <div
                    className="group inline-flex"
                    // onClick={() => {
                    //   sortBy("gpu-mem");
                    // }}
                  >
                    Provider
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-zinc-900">
              {displayInstances.length > 0 ? (
                displayInstances.map(
                  (instanceForParticularGPU: GPUInstanceType, index) => (
                    <>
                      <TableRow
                        selectedCloudName={selectedCloudName || ""}
                        selectedInstanceType={selectedInstanceType}
                        onClick={(i) => onChange(i.type)}
                        key={index}
                        index={index}
                        isRowSelected={
                          selectedInstanceType === instanceForParticularGPU.type
                        }
                        spot={spot}
                        instanceForParticularGPU={instanceForParticularGPU}
                        length={displayInstances.length}
                      />
                    </>
                  )
                )
              ) : (
                <tr className="text-center">
                  <td />
                  <td />
                  <td className="pt-2 pb-2 text-sm font-md text-rose-600">
                    None Found: Try changing your filters or GPU name
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface TableRowProps {
  selectedCloudName: string;
  instanceForParticularGPU: GPUInstanceType;
  onClick: (i: GPUInstanceType) => void;
  isRowSelected: boolean;
  selectedInstanceType: string;
  length: number;
  index: number;
  spot: boolean;
}

const TableRow: React.FC<TableRowProps> = ({
  selectedCloudName,
  instanceForParticularGPU,
  onClick,
  isRowSelected,
  selectedInstanceType,
  length,
  index,
  spot,
}) => {
  return (
    <tr
      onClick={() => {
        onClick(instanceForParticularGPU);
      }}
      className={classNames(
        "cursor-pointer",
        isRowSelected
          ? "bg-highlight dark:bg-highlight"
          : "hover:bg-gray-50 dark:hover:bg-zinc-700"
      )}
    >
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-l border-gray-200 dark:border-zinc-800"
            : "border-l border-gray-200 dark:border-zinc-800",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        {`${instanceForParticularGPU.supported_gpus[0].manufacturer} ${instanceForParticularGPU.supported_gpus[0].name}`}
        <span
          className={classNames(
            "ml-1 font-medium text-highlight dark:text-secondary",
            isRowSelected ? "visible" : "invisible"
          )}
        >
          (Selected)
        </span>
      </td>
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        {instanceForParticularGPU.supported_gpus[0].memory}
      </td>
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary hidden sm:table-cell",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        <div className="w-15">
          {instanceForParticularGPU.supported_gpus[0].count}
        </div>
        {/* {selectedInstanceConfig.supported_gpus[0].count} */}
      </td>
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary hidden lg:table-cell",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        <div className="w-15">{instanceForParticularGPU.vcpu}</div>
      </td>
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        <div className="w-[120px]">{instanceForParticularGPU.memory}</div>
      </td>

      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-gray-200 dark:border-zinc-800"
            : "",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        <div className="GPUMetadata-Price">
          {instanceForParticularGPU.base_price?.amount &&
          Number(instanceForParticularGPU.base_price?.amount) > 0 ? (
            <>
              $
              {roundPriceToTwoDigits(
                instanceForParticularGPU.base_price?.amount || "0.00"
              )?.toString()}
            </>
          ) : (
            <>Not available</>
          )}
        </div>
      </td>
      <td
        className={classNames(
          index !== length - 1
            ? "border-b border-r border-gray-200 dark:border-zinc-800"
            : "border-r border-gray-200 dark:border-zinc-800",
          "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
          "px-3 py-4",
          isRowSelected ? "text-gray-800 dark:text-white font-medium" : ""
        )}
      >
        {instanceForParticularGPU.workspace_groups[0].platformType.toUpperCase()}
      </td>
    </tr>
  );
};

export default GPUSelectorTable;

const removeDuplicates = (array) =>
  array.filter((a, b) => array.indexOf(a) === b);

interface AdvFilterListProps {
  initialList: GPUInstanceType[];
  displayInstances: GPUInstanceType[];
  setDisplayInstances: (i: GPUInstanceType[]) => void;
  setIsCudaReady: (b: boolean) => void;
  isCudaReady: boolean;
  spot: boolean;
  setSpot: (spot: boolean) => void;
}

const FilterList: React.FC<AdvFilterListProps> = ({
  initialList,
  displayInstances,
  setDisplayInstances,
  spot,
  isCudaReady,
  setIsCudaReady,
  setSpot,
}) => {
  const [cloud, setCloud] = React.useState<string>("Any");
  const [cloudList, setCloudList] = React.useState<string[]>([]);
  const [gpus, setGPUs] = React.useState<string>("");
  const [cpus, setCPUs] = React.useState<string>("");
  const [memoryList, setMemoryList] = React.useState<string[]>([]);
  const [memory, setMemory] = React.useState<string>("");
  const [standardSizeList, setStandardSizeList] = React.useState<string[]>([
    "small",
    "medium",
    "large",
    "x-large",
  ]);
  const [standardSize, setStandardSize] = React.useState<string>("small");
  const standardSizeMapping = [
    { name: "small", size: "16GiB" },
    { name: "medium", size: "24GiB" },
    { name: "large", size: "40GiB" },
    { name: "x-large", size: "80GiB" },
  ];
  const [standardMemory, setStandardMemory] =
    React.useState<string>("standard");
  const [standardMemoryList, setStandardMemoryList] = React.useState<string[]>([
    "standard",
    "high",
  ]);
  const standardMemoryMapping = [
    { name: "standard", size: "13GiB" },
    { name: "high", size: "52GiB" },
  ];

  const [standardCPU, setStandardCPU] = React.useState<string>("standard");
  const [standardCPUList, setStandardCPUList] = React.useState<string[]>([
    "standard",
    "high",
  ]);
  const standardCPUMapping = [
    { name: "standard", size: "2" },
    { name: "high", size: "32" },
  ];

  const [gpuMemoryList, setGPUMemoryList] = React.useState<string[]>([]);
  const [gpuMemory, setGPUMemory] = React.useState<string>("");

  const [priceRange, setPriceRange] = React.useState<number[]>([0, 100]);
  const [maxRange, setMaxRange] = React.useState<number>(100);
  const [showAdvancedSetting, setShowAdvancedSetting] =
    React.useState<boolean>(false);

  const getRange = (list, size, standardMapping, min, max): string[] => {
    const rangeIndex = standardMapping.findIndex((obj) => obj.name === size);
    if (rangeIndex === 0) {
      return [min, standardMapping[rangeIndex + 1].size];
    } else if (rangeIndex === standardMapping.length - 1) {
      return [standardMapping[rangeIndex].size, max];
    } else {
      return [
        standardMapping[rangeIndex].size,
        standardMapping[rangeIndex + 1].size,
      ];
    }
  };

  // Filters
  const filterByStandardSize = (list, size, standardMapping) => {
    const rangeIndex = standardMapping.findIndex((obj) => obj.name === size);
    return list.filter(
      (item: GPUInstanceType) =>
        convertToBytes(String(item.supported_gpus[0].memory)) >=
        convertToBytes(standardMapping[rangeIndex].size)
    );
  };

  const filterByStandardMemory = (list, size, standardMapping) => {
    const range = getRange(list, size, standardMapping, "0B", "8TiB");
    const rangeIndex = standardMapping.findIndex((obj) => obj.name === size);
    return list.filter(
      (item: GPUInstanceType) =>
        convertToBytes(String(item.memory)) >=
        convertToBytes(standardMapping[rangeIndex].size)
    );
  };

  const filterByStandardCPU = (list, size, standardMapping) => {
    const range = getRange(list, size, standardMapping, "0", "1000");
    const rangeIndex = standardMapping.findIndex((obj) => obj.name === size);
    return list.filter(
      (item: GPUInstanceType) =>
        Number(item.vcpu) >= Number(standardMapping[rangeIndex].size)
    );
  };

  const filterGPUMemoryByMinAmount = (list, minAmount): GPUInstanceType[] => {
    const amount = minAmount ? convertToBytes(minAmount) : 0;
    return list.filter(
      (item: GPUInstanceType) =>
        convertToBytes(String(item.supported_gpus[0].memory)) >= amount
    );
  };

  const filterGPUCountByMinAmount = (list, minAmount): GPUInstanceType[] => {
    const amount = minAmount ? Number(minAmount) : 0;
    return list.filter(
      (item: GPUInstanceType) => item.supported_gpus[0].count >= amount
    );
  };
  const filterCPUCountByMinAmount = (list, minAmount): GPUInstanceType[] => {
    const amount = minAmount ? Number(minAmount) : 0;
    return list.filter((item: GPUInstanceType) => item.vcpu >= amount);
  };

  const filterMemoryByMinAmount = (list, minAmount) => {
    const amount = minAmount ? convertToBytes(minAmount) : 0;
    return list.filter(
      (item: GPUInstanceType) => convertToBytes(String(item.memory)) >= amount
    );
  };

  const filterByCloud = (list, cloud): GPUInstanceType[] => {
    if (cloud !== "Any") {
      return list.filter(
        (item: GPUInstanceType) =>
          item.workspace_groups[0].platformType.toUpperCase() === cloud
      );
    }
    return list;
  };

  const filterByCudaReady = (list, isCudaReady): GPUInstanceType[] => {
    if (!isCudaReady) {
      return list;
    }
    return list.filter(
      (item: GPUInstanceType) =>
        !NonCudaReadyMap[
          item.supported_gpus[0].manufacturer +
            " " +
            item.supported_gpus[0].name
        ]
    );
  };

  const filterOutNoPrice = (list: GPUInstanceType[], spot) =>
    list.filter((instance) => !isNaN(Number(instance.base_price?.amount)));

  const filterByPriceRange = (list, range: number[]) =>
    list.filter(
      (item: GPUInstanceType) =>
        (Number(item.base_price?.amount) || 0) >= range[0] &&
        (Number(item.base_price?.amount) || 0) <= range[1]
    );

  const getMaxRange = (list: GPUInstanceType[], spotObj) => 80;

  // DropDown Lists
  const getGPUMemoryDropdown = (list: GPUInstanceType[]): string[] => {
    const memoryNames: Set<string> = new Set();
    for (const entries of list) {
      memoryNames.add(String(entries.supported_gpus[0].memory));
    }
    const convList = Array.from(memoryNames);
    convList.sort((a, b) => convertToBytes(a) - convertToBytes(b));
    return convList;
  };

  const getMemoryDropdown = (list: GPUInstanceType[]): string[] => {
    const memoryNames: Set<string> = new Set();
    for (const entries of list) {
      memoryNames.add(String(entries.memory));
    }
    const convList = Array.from(memoryNames);
    convList.sort((a, b) => convertToBytes(a) - convertToBytes(b));
    return convList;
  };

  const getCloudDropdown = (list: GPUInstanceType[]): string[] => {
    const cloudNames: Set<string> = new Set();
    for (const entries of list) {
      cloudNames.add(
        String(entries.workspace_groups[0].platformType.toUpperCase())
      );
    }
    const convList = Array.from(cloudNames);
    if (convList.length > 1) {
      convList.unshift("Any");
    }
    return convList;
  };

  useEffect(() => {
    if (initialList.length > 0) {
      const gpuMemoryDrop = getGPUMemoryDropdown(initialList);
      setMemoryList(gpuMemoryDrop);
      setGPUMemory(gpuMemoryDrop[0]);

      const memoryDrop = getMemoryDropdown(initialList);
      setMemoryList(memoryDrop);
      setMemory(memoryDrop[0]);

      const cloudDrop = getCloudDropdown(initialList);
      setCloudList(cloudDrop);
      setCloud(cloudDrop[0]);

      const maxRange = getMaxRange(initialList, spot);
      setMaxRange(maxRange);
      setPriceRange([0, maxRange]);
    }
  }, [initialList]);

  useEffect(() => {
    let filtered = initialList;
    if (showAdvancedSetting) {
      // GPU Memory
      filtered = filterGPUMemoryByMinAmount(initialList, gpuMemory);
      setGPUMemoryList(getGPUMemoryDropdown(initialList));
      // GPUs
      filtered = filterGPUCountByMinAmount(filtered, gpus);
      // CPU
      filtered = filterCPUCountByMinAmount(filtered, cpus);
      // Memory
      filtered = filterMemoryByMinAmount(filtered, memory);
      setMemoryList(getMemoryDropdown(initialList));
      // Cloud
      filtered = filterByCloud(filtered, cloud);
      setCloudList(getCloudDropdown(initialList));
      // Price
      filtered = filterByPriceRange(filtered, priceRange);
    } else {
      filtered = filterByStandardSize(
        filtered,
        standardSize,
        standardSizeMapping
      );
      filtered = filterByStandardMemory(
        filtered,
        standardMemory,
        standardMemoryMapping
      );
      filtered = filterByStandardCPU(filtered, standardCPU, standardCPUMapping);
    }
    // IsCuddaReady
    filtered = filterByCudaReady(filtered, isCudaReady);
    filtered = filterOutNoPrice(filtered, spot);
    // Higest to Lowest Price
    filtered = sortLowestToHigestPrice(filtered);
    setDisplayInstances(filtered);
  }, [
    gpus,
    cpus,
    memory,
    cloud,
    initialList,
    gpuMemory,
    priceRange,
    isCudaReady,
    standardSize,
    standardMemory,
    standardCPU,
    showAdvancedSetting,
  ]);

  useEffect(() => {
    const stanSize = standardSizeMapping.find(
      (item) => item.name === standardSize
    );
    if (stanSize) {
      setGPUMemory(stanSize.size);
    }
  }, [showAdvancedSetting]);

  useEffect(() => {
    const stanMemory = standardMemoryMapping.find(
      (item) => item.name === standardMemory
    );
    if (stanMemory) {
      setMemory(stanMemory.size);
    }
  }, [showAdvancedSetting]);

  useEffect(() => {
    const stanCPU = standardCPUMapping.find(
      (item) => item.name === standardCPU
    );
    if (stanCPU) {
      setCPUs(stanCPU.size);
    }
  }, [showAdvancedSetting]);

  return (
    <>
      <div className="flex flex-col mt-3">
        <div className="flex flex-row">
          <div className="w-1/4 mr-5">
            <Dropdown
              disabled={showAdvancedSetting}
              label="GPU Size"
              value={standardSize}
              onChange={(r) => setStandardSize(r)}
            >
              {standardSizeList.map((name, index) => (
                <DropdownItem displayValue={name} value={name} key={index} />
              ))}
            </Dropdown>
          </div>
          <div className="w-1/4 mr-5">
            <Dropdown
              disabled={showAdvancedSetting}
              label="Memory Type"
              value={standardMemory}
              onChange={(r) => setStandardMemory(r)}
            >
              {standardMemoryList.map((name, index) => (
                <DropdownItem displayValue={name} value={name} key={index} />
              ))}
            </Dropdown>
          </div>
          <div className="w-1/4">
            <Dropdown
              disabled={showAdvancedSetting}
              label="CPU Type"
              value={standardCPU}
              onChange={(r) => setStandardCPU(r)}
            >
              {standardCPUList.map((name, index) => (
                <DropdownItem displayValue={name} value={name} key={index} />
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="advanced-filters mt-3">
          <div
            className="flex flex-row cursor-pointer"
            onClick={() => setShowAdvancedSetting(!showAdvancedSetting)}
          >
            <span className="text-sm text-highlight font-semibold hover:text-highlightDarker mr-1">
              Advanced Filters
            </span>
            {showAdvancedSetting ? (
              <ChevronDownIcon width={18} className="text-highlight" />
            ) : (
              <ChevronRightIcon width={18} />
            )}
          </div>
          <Collapse in={showAdvancedSetting}>
            <div className="flex flex-col">
              <div className="flex flex-row mt-3 mb-3">
                <div className="w-32 mr-3">
                  <Dropdown
                    label="Min. GPU Memory"
                    value={gpuMemory}
                    onChange={(r) => setGPUMemory(r)}
                  >
                    {gpuMemoryList.map((name, index) => (
                      <DropdownItem
                        displayValue={name}
                        value={name}
                        key={index}
                      />
                    ))}
                  </Dropdown>
                </div>
                <InputField
                  className="w-20 mr-3"
                  label="Min. GPUs"
                  placeholder="0"
                  value={gpus}
                  onChange={(e) => {
                    setGPUs(e);
                  }}
                  errorMessage=""
                  onKeyDown={(e) => {
                    if (
                      !/[0-9\b]/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <InputField
                  className="w-20 mr-3"
                  label="Min. CPUs"
                  placeholder="0"
                  value={cpus}
                  onChange={(e) => {
                    setCPUs(e);
                  }}
                  errorMessage=""
                  onKeyDown={(e) => {
                    if (
                      !/[0-9\b]/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <div className="w-36 mr-3">
                  <Dropdown
                    label="Min. Memory"
                    value={memory}
                    onChange={(r) => setMemory(r)}
                  >
                    {memoryList.map((name, index) => (
                      <DropdownItem
                        displayValue={name}
                        value={name}
                        key={index}
                      />
                    ))}
                  </Dropdown>
                </div>
                <div className="w-24">
                  <Dropdown
                    label="Provider"
                    value={cloud}
                    onChange={(r) => setCloud(r)}
                  >
                    {cloudList.map((name, index) => (
                      <DropdownItem
                        displayValue={name}
                        value={name}
                        key={index}
                      />
                    ))}
                  </Dropdown>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/4 mr-8">
                  <span className="text-sm font-medium text-gray-700 dark:text-secondary">
                    Price Range: {`$${priceRange[0]} - ` + `$${priceRange[1]}`}
                  </span>
                  <StyledRangeSlider
                    onChange={(newRange) => setPriceRange(newRange as number[])}
                    value={priceRange}
                    max={maxRange}
                  />
                </div>
                <div className="w-1/3 items-center">
                  <Toggle
                    label="CUDA® Ready?"
                    checked={isCudaReady}
                    onChange={() => setIsCudaReady(!isCudaReady)}
                    description="Only include instances with CUDA® installed"
                  />
                </div>
              </div>
            </div>
          </Collapse>
          <hr className="mt-3 dark:border dark:border-zinc-800"></hr>
        </div>
        <div className="flex mt-2 mr-2 ml-2 justify-end">
          <div className="flex items-end">
            <span className="text-sm font-medium text-gray-700 dark:text-secondary">
              Results: {displayInstances.length}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
