import {
  DeploymentInstanceType,
  DeploymentStatus,
  MetricDataPointInt,
} from "server";

export const mapStatusToVariant = (
  deploymentStatus: DeploymentStatus
): "default" | "neutral" | "success" | "error" | "warning" => {
  if (
    deploymentStatus === DeploymentStatus.DELETED ||
    deploymentStatus === DeploymentStatus.DELETING
  ) {
    return "warning";
  }
  if (deploymentStatus === DeploymentStatus.ERROR) {
    return "error";
  }
  return "default";
};

export const mapStatusToLabel = (
  deploymentStatus: DeploymentStatus
): string => {
  if (deploymentStatus === DeploymentStatus.DELETING) {
    return "Deleting";
  }
  if (deploymentStatus === DeploymentStatus.ERROR) {
    return "Error";
  }
  if (deploymentStatus === DeploymentStatus.DELETED) {
    return "Deleted";
  }
  if (deploymentStatus === DeploymentStatus.DEPLOYING) {
    return "Deploying";
  }
  if (deploymentStatus === DeploymentStatus.CREATED) {
    return "Created";
  }
  if (deploymentStatus === DeploymentStatus.RUNNING) {
    return "Running";
  }
  return "Waiting";
};

export const formatDeploymentTimeSeries = (
  data: MetricDataPointInt[],
  indexValue: string
): any[] => {
  return data.map((item) => {
    const date = new Date((item?.time?.seconds || 0) * 1000); // Convert seconds to milliseconds
    const formattedDate = date
      .toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
      .replace(",", "");

    return {
      date: formattedDate,
      [indexValue]: item.value ?? 0, // Use nullish coalescing to default to 0 if value is undefined
    };
  });
};

export const placeholderData = {
  invocations: [
    { date: "2023-05-01", Invocations: 2890 },
    { date: "2023-05-02", Invocations: 2756 },
    { date: "2023-05-03", Invocations: 3012 },
    { date: "2023-05-04", Invocations: 2845 },
    { date: "2023-05-05", Invocations: 3156 },
    { date: "2023-05-06", Invocations: 2678 },
    { date: "2023-05-07", Invocations: 2543 },
  ],
  inferenceTime: [
    { date: "2023-05-01", "Inference Time": 0.15 },
    { date: "2023-05-02", "Inference Time": 0.17 },
    { date: "2023-05-03", "Inference Time": 0.14 },
    { date: "2023-05-04", "Inference Time": 0.16 },
    { date: "2023-05-05", "Inference Time": 0.18 },
    { date: "2023-05-06", "Inference Time": 0.15 },
    { date: "2023-05-07", "Inference Time": 0.16 },
  ],
  queueDepth: [
    { date: "2023-05-01", "Queue Depth": 5 },
    { date: "2023-05-02", "Queue Depth": 6 },
    { date: "2023-05-03", "Queue Depth": 5 },
    { date: "2023-05-04", "Queue Depth": 7 },
    { date: "2023-05-05", "Queue Depth": 6 },
    { date: "2023-05-06", "Queue Depth": 5 },
    { date: "2023-05-07", "Queue Depth": 6 },
  ],
  instances: [
    { date: "2023-05-01", Instances: 5 },
    { date: "2023-05-02", Instances: 6 },
    { date: "2023-05-03", Instances: 5 },
    { date: "2023-05-04", Instances: 7 },
    { date: "2023-05-05", Instances: 6 },
    { date: "2023-05-06", Instances: 5 },
    { date: "2023-05-07", Instances: 6 },
  ],
  successRate: [
    { date: "2023-05-01", "Success Rate": 98.5 },
    { date: "2023-05-02", "Success Rate": 99.1 },
    { date: "2023-05-03", "Success Rate": 98.8 },
    { date: "2023-05-04", "Success Rate": 99.3 },
    { date: "2023-05-05", "Success Rate": 98.9 },
    { date: "2023-05-06", "Success Rate": 99.2 },
    { date: "2023-05-07", "Success Rate": 99.0 },
  ],
};

export function getDeploymentInstanceTypeByType(
  instanceTypes: DeploymentInstanceType[],
  type: string
): DeploymentInstanceType | undefined {
  return instanceTypes.find((instanceType) => instanceType.type === type);
}

export const mergeDeploymentSeries = (
  data1: MetricDataPointInt[],
  data2: MetricDataPointInt[],
  indexValue1: string,
  indexValue2: string
): any[] => {
  const formattedData1 = formatDeploymentTimeSeries(data1, indexValue1);
  const formattedData2 = formatDeploymentTimeSeries(data2, indexValue2);

  const mergedData = new Map();

  // Process data1
  for (const item of formattedData1) {
    mergedData.set(item.date, {
      date: item.date,
      [indexValue1]: item[indexValue1],
      [indexValue2]: 0,
    });
  }

  // Process data2 and merge with data1
  for (const item of formattedData2) {
    if (mergedData.has(item.date)) {
      mergedData.get(item.date)[indexValue2] = item[indexValue2];
    } else {
      mergedData.set(item.date, {
        date: item.date,
        [indexValue1]: 0,
        [indexValue2]: item[indexValue2],
      });
    }
  }

  // Convert Map to Array and sort by date
  return Array.from(mergedData.values()).sort((a, b) =>
    a.date.localeCompare(b.date)
  );
};
