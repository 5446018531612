import React, { useState } from "react";
import { WorkspaceGroup } from "server";
import { useWindupString } from "windups";

export const WaitingOnBuildStep = ({ onComplete }) => {
  const firstLine = "Initiating the build process.";
  const steps = [
    firstLine,
    "Installing Verb Executable",
    "Creating verb.yaml on remote machine",
    "Building container",
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [renderedSteps, setRenderedSteps] = useState<string[]>([]);
  const [text] = useWindupString(steps[currentStep], {
    onFinished: () => {
      setRenderedSteps([...renderedSteps, steps[currentStep]]);
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1); // Move to the next step
      } else {
        onComplete(); // Notify the parent when all steps are complete
      }
    },
  });

  return (
    <>
      {renderedSteps.map((stepText, index) => (
        <p
          key={index}
          className="text-sm mt-2 text-gray-700 dark:text-secondary font-mono"
        >
          {stepText !== firstLine && "✔️ "}
          {stepText}
        </p>
      ))}
      {currentStep < steps.length &&
        renderedSteps.indexOf(steps[currentStep]) === -1 && (
          <p className="text-sm mt-2 text-gray-700 dark:text-secondary font-mono">
            • {text}
          </p>
        )}
    </>
  );
};
