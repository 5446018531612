import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import yaml from "js-yaml";

import {
  classNames,
  mapValuesToArray,
  notebooks,
  replaceRawWithBlob,
  // getManufacturerName,
} from "components/utils";
import { WorkspaceRowItemProps, getWorkspaceStatusChip } from ".";
import FlatCard from "components/UI-lib/FlatCard";
import Chip from "components/Environment/Create/chips";
import Button from "components/UI-lib/Button";
import { useHistory } from "react-router-dom";
import { OrgContext } from "contexts/OrgContext";
import { GetEnvStatusLight } from "../shared/InstanceStatusIndicator";
import Badge from "components/UI-lib/Badge";
import InstanceStatusIndicator from "../shared/InstanceStatusIndicator";
import Fab from "./Fab";
import IWorkspace, {
  HealthCheckStatus,
  HealthStatus,
  VerbBuildStatus,
  WorkspaceClassId,
  WorkspaceStatus,
} from "models/Workspace.model";
import { DarkModeContext } from "contexts/DarkModeContext";
import {
  RectangleGroupIcon,
  CpuChipIcon,
  CloudIcon,
  CircleStackIcon,
  BookOpenIcon,
  ClockIcon,
  UserIcon,
  PlayCircleIcon,
  PauseCircleIcon,
} from "@heroicons/react/24/solid";
import { getWorkspaceGroupObject } from "components/Environment/Settings/utils";
import Workspace from "entities/Workspace.entity";
import { Tooltip } from "@mui/material";
import { DeployState } from "contexts/OneClickDeployContext";
import UsernameDisplayField from "components/UsernameDisplayField";
import InfoBox from "components/DashboardContainer/HoverInfoBox";
import { m } from "framer-motion";
import {
  getInstancePriceRate,
  getStorageCostNumber,
  isGPUfromInstanceType,
  useNotebookDetailsFromWorkspace,
} from "../utils";
import InstanceOverview from "../shared/InstanceOverview";
import { getVerbBuildConfigFromWorkspace } from "components/Verb/utils";
import ContainerOverview from "../shared/ContainerOverview";
import { useFeatureFlagEnabled } from "posthog-js/react";

// export interface WorkspaceRowItemProps {
//   workspace: Workspace;
//   notForSelf?: boolean;
//   viewableWorkspace: any[];
// }

const InstanceMetadata: React.FC<WorkspaceRowItemProps> = (props) => {
  const history = useHistory();
  const orgContext = useContext(OrgContext);
  const { darkMode } = useContext(DarkModeContext);
  // Flag to determine if the new logic should be used.
  // https://us.posthog.com/project/22059/feature_flags/44359
  const flagUseTunnelStatusForNotebookHealth = useFeatureFlagEnabled(
    "use-tunnel-status-for-notebook-health"
  );
  const jupyterApp = useMemo(() => {
    if (props.workspace?.tunnel?.applications) {
      return props.workspace.tunnel.applications.find(
        (app) =>
          app.name.startsWith("jupyter") &&
          app.name.endsWith("-" + props.workspace.id)
      );
    }
  }, [props.workspace.tunnel?.applications]);

  const jupyterHealthcheck =
    props.workspace &&
    props.workspace.healthCheck &&
    props.workspace.healthCheck.find((hc) => {
      return hc.health_check_id == jupyterApp?.healthCheckID;
    });
  // Existing logic that does not use the tunnel status field.
  const isHealthyPolicy1 = (workspace: Workspace): boolean => {
    return !!(
      workspace &&
      workspace.status === WorkspaceStatus.Running &&
      workspace.healthCheck &&
      workspace.healthCheck.length > 0 &&
      workspace.verbBuildStatus === VerbBuildStatus.Completed &&
      jupyterApp != undefined &&
      jupyterHealthcheck?.status === "healthy"
    );
  };
  // new logic that uses the tunnel status field in addition to the existing logic.
  const isHealthyPolicy2 = (workspace: Workspace): boolean => {
    const basicInstanceHealthyStatus = !!(
      workspace &&
      workspace.status === WorkspaceStatus.Running &&
      workspace.healthCheck &&
      workspace.healthCheck.length > 0 &&
      workspace.verbBuildStatus === VerbBuildStatus.Completed
    );
    const jupyterHealthyStatus = !!(
      jupyterApp != undefined &&
      jupyterHealthcheck?.status === "healthy" &&
      workspace &&
      workspace.tunnel &&
      workspace.tunnel.tunnelStatus === "HEALTHY"
    );
    return basicInstanceHealthyStatus && jupyterHealthyStatus;
  };

  //
  const isHealthy = (workspace: Workspace): boolean => {
    return flagUseTunnelStatusForNotebookHealth
      ? isHealthyPolicy2(workspace)
      : isHealthyPolicy1(workspace);
  };

  const openNotebook = async (workspace: Workspace) => {
    if (workspace.fileObjects) {
      let fileList = mapValuesToArray(workspace.fileObjects);
      let file = fileList[0];
      if (file && !workspace.verbYaml?.includes("workingDirectory")) {
        window.open(
          "https://" +
            jupyterApp?.hostname +
            "/lab/tree" +
            (file.path.startsWith("/") ? "" : "/") +
            file.path,
          "_blank"
        );
      } else {
        window.open("https://" + jupyterApp?.hostname, "_blank");
      }
    } else {
      window.open("https://" + jupyterApp?.hostname, "_blank");
    }
  };

  const notebookDetails = useNotebookDetailsFromWorkspace(
    props.workspace,
    props.notebookList
  );

  const instanceAttributes = useMemo(() => {
    const availableInstance = orgContext.allInstancesAvailable.find(
      (instance) => instance.type === props.workspace?.instanceType
    );
    if (availableInstance) {
      return availableInstance;
    }
    return props.workspace.instanceTypeInfo;
  }, [props.workspace, orgContext.allInstancesAvailable]);

  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [hoveredStep, setHoveredStep] = useState<number | null>(null);
  // Function to start a timeout to hide the InfoBox
  const handleMouseLeave = () => {
    if (!hoverTimeoutRef.current) {
      hoverTimeoutRef.current = setTimeout(() => {
        setHoveredStep(null);
      }, 300); // 300ms delay before hiding the InfoBox
    }
  };

  // Function to clear the timeout when entering the InfoBox
  const handleInfoBoxEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
      setHoveredStep(1);
    }
  };

  const handleListItemEnter = (index: number) => {
    // Clear any existing timeout to prevent it from hiding the InfoBox
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setHoveredStep(1);
  };

  return (
    <FlatCard className="brev-instance mb-8 hover:cursor-pointer dark:hover:border-highlight dark:hover:ring-cyan-500 dark:hover:cursor-pointer">
      <div className="flex flex-col">
        <div
          className="flex flex-row justify-between items-center"
          onClick={() => {
            history.push(
              `/org/${orgContext.activeOrgId}/environments/${props.workspace.id}`
            );
          }}
        >
          <div className="h-full">
            <div
              className={classNames(
                "group flex w-full items-center justify-between space-x-3 rounded-full p-2 text-left shadow-sm",
                "cursor-pointer"
              )}
            >
              <span className="flex min-w-0 flex-1 items-center space-x-3">
                {/* <span className="block flex-shrink-0">
                <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" />
              </span> */}
                <span className="block min-w-0 flex-1">
                  <span className="block truncate text-lg font-medium text-white">
                    {/* BANANA: make this real */}
                    {/* {notebooks.find((n) => n.url === file)?.name || */}
                    {props.workspace.name}
                  </span>
                  <div className="flex flex-row text-secondary text-xs">
                    <span className="block truncate text-xs">
                      ID: {props.workspace.id}
                    </span>
                    <span className="mr-1.5 ml-1.5 leading-[16px]">
                      &middot;
                    </span>
                    <span className="mr-1.5 leading-[16px]">User: </span>
                    <UsernameDisplayField
                      className="text-xs text-secondary"
                      userId={props.workspace.createdByUserId}
                    />
                    <span className="ml-1 leading-[16px]">
                      ({props.workspace.createdByUserId})
                    </span>
                    <span className="mr-1.5 ml-1.5 leading-[16px]">
                      &middot;
                    </span>
                    <span className="block truncate text-xs text-secondary">
                      Created:{" "}
                      {` ${new Date(
                        props.workspace?.createdAt
                      ).toLocaleString()}`}
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div className="flex flex-row">
              <div className="p-2">
                <InstanceOverview
                  workspaceId={props.workspace.id}
                  workspaceStatus={props.workspace.status}
                  workspaceInstanceType={props.workspace.instanceType}
                  isGPUInstance={isGPUfromInstanceType(
                    props.workspace.instanceType || "",
                    orgContext.allInstancesAvailable
                  )}
                  providerName={getWorkspaceGroupObject(
                    props.workspace.workspaceGroupId,
                    orgContext.workspaceGroups
                  )?.platformType.toUpperCase()}
                  diskStorage={props.workspace.diskStorage}
                  gpuName={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].name
                      : ""
                  }
                  gpuManufacturer={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].manufacturer
                      : ""
                  }
                  vcpu={instanceAttributes?.vcpu}
                  memory={instanceAttributes?.memory}
                  gpuMemory={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].memory
                      : ""
                  }
                  gpuCount={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].count
                      : 0
                  }
                  platformType={
                    instanceAttributes && instanceAttributes?.workspace_groups
                      ? instanceAttributes?.workspace_groups[0].platformType
                      : ""
                  }
                  instanceCost={
                    instanceAttributes && instanceAttributes.supported_storage
                      ? getInstancePriceRate(
                          Number(instanceAttributes.base_price?.amount || 0),
                          Number(
                            getStorageCostNumber(
                              props.workspace.diskStorage || ""
                            ) || 0
                          ),
                          Number(
                            instanceAttributes?.supported_storage[0]
                              .price_per_gb_hr?.amount || 0
                          ),
                          props.workspace.status ===
                            WorkspaceStatus.Deploying ||
                            props.workspace.status ===
                              WorkspaceStatus.Starting ||
                            props.workspace.status === WorkspaceStatus.Running
                        )
                      : ""
                  }
                />
              </div>
              {isGPUfromInstanceType(
                props.workspace?.instanceType || "",
                orgContext.allInstancesAvailable
              ) &&
                !props.workspace.vmOnlyMode && (
                  <div
                    className={classNames(
                      "flex",
                      props.workspace.status === WorkspaceStatus.Running &&
                        props.workspace.verbBuildStatus !==
                          VerbBuildStatus.Unset
                        ? "on"
                        : ""
                    )}
                  >
                    <div className="flex justify-center items-center ml-3 mr-3">
                      <span className="text-xl">→</span>
                    </div>
                    <div className="p-2">
                      <ContainerOverview
                        workspaceStatus={props.workspace.status}
                        workspaceVerbBuildStatus={
                          props.workspace.verbBuildStatus ||
                          VerbBuildStatus.Unset
                        }
                        workspaceVerbYaml={props.workspace.verbYaml || ""}
                        workspaceBaseImage={props.workspace?.baseImage}
                      />
                      {props.workspace.status === WorkspaceStatus.Running && (
                        <div className="flex flex-col justify-center items-center mt-2 relative">
                          <span
                            className="text-sm text-highlight dark:text-highlight p-1"
                            onMouseEnter={() => handleListItemEnter(1)}
                            onMouseLeave={handleMouseLeave}
                          >
                            View Logs
                            {hoveredStep === 1 && (
                              <InfoBox
                                onMouseEnter={handleInfoBoxEnter}
                                onMouseLeave={handleMouseLeave}
                                activeStep={DeployState.Dependencies}
                                index={hoveredStep}
                                workspace={props.workspace}
                              />
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              {isGPUfromInstanceType(
                props.workspace?.instanceType || "",
                orgContext.allInstancesAvailable
              ) &&
                props.workspace.fileObjects && (
                  <div
                    className={classNames(
                      "flex",
                      isHealthy(props.workspace) &&
                        props.workspace.verbBuildStatus ===
                          VerbBuildStatus.Completed
                        ? "on"
                        : ""
                    )}
                  >
                    <div className="flex justify-cente items-center ml-3 mr-3">
                      <span className="text-xl">→</span>
                    </div>
                    <div
                      className={classNames(
                        "flex flex-col p-2",
                        isHealthy(props.workspace) &&
                          props.workspace.verbBuildStatus ===
                            VerbBuildStatus.Completed
                          ? "on"
                          : ""
                      )}
                    >
                      <div className="flex flex-row mb-1 items-center">
                        <BookOpenIcon className="h-6 w-6 mr-1" />
                        <span className="text-sm">Notebook</span>
                      </div>
                      <span className="text-sm mt-1 mb-1">
                        {notebookDetails.name}
                      </span>
                      <span
                        className="block truncate text-xs font-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Preview Notebook
                      </span>
                      {isHealthy(props.workspace) &&
                        props.workspace.verbBuildStatus ===
                          VerbBuildStatus.Completed && (
                          <div className="flex">
                            <Button
                              type="primary"
                              label="Access Notebook"
                              className="mt-2"
                              onClick={() => {
                                openNotebook(props.workspace);
                              }}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </FlatCard>
  );
};
export default InstanceMetadata;

export const InstanceMetadataSkeleton: React.FC = (props) => {
  const notebookDetails = {
    name: "Your FineTune NoteBook",
    path: "random/path",
  };
  const mockWorkspaceTemplate: any = {
    id: "1234",
    name: "BrevGPT Finetune Workspace",
    createdByUserId: "1234",
    createdAt: "2024-04-17T03:54:16.947597898Z",
    status: WorkspaceStatus.Stopped,
    verbBuildStatus: VerbBuildStatus.Pending,
    instanceType: "g5.16xlarge",
    vcpu: 24,
    memory: "340GB",
    gpuName: "A100",
    gpuManufacturer: "NVIDIA",
    gpuMemory: "80GB",
    gpuCount: 2,
    diskStorage: "120GB",
    providerName: "GCP",
  };

  const [isHealthy, setIsHealthy] = useState(false);
  const [mockWorkspace, setMockWorkspace] = useState(mockWorkspaceTemplate);

  useEffect(() => {
    const controlFlow = async () => {
      while (true) {
        // Loop indefinitely
        // Wait for 2 seconds, then toggle the system health
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Deploying,
        }));
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Starting,
        }));
        // Wait for 2 seconds, then toggle the system health
        await new Promise((resolve) => setTimeout(resolve, 2000));

        setIsHealthy((prev) => !prev);
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Running,
        }));
        setMockWorkspace((prev) => ({
          ...prev,
          verbBuildStatus: VerbBuildStatus.Pending,
        }));

        // Wait for another 2 seconds, then toggle the workspace status
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          verbBuildStatus: VerbBuildStatus.Building,
        }));

        // Determine verbBuildStatus based on the status and wait for 2 seconds before updating
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          verbBuildStatus: VerbBuildStatus.Completed,
        }));

        await new Promise((resolve) => setTimeout(resolve, 2000));
        setIsHealthy(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Stopping,
          VerbBuildStatus: VerbBuildStatus.Pending,
        }));
        setIsHealthy(false);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Stopped,
        }));
        setIsHealthy(false);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    };
    controlFlow();
    // No need for cleanup as the effect only runs once and controls its own loop
  }, []);

  return (
    <FlatCard className="">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <div className="h-full opacity-35">
            <div
              className={classNames(
                "group flex w-full items-center justify-between space-x-3 rounded-full p-2 text-left shadow-sm",
                "cursor-pointer relative"
              )}
            >
              <span className="flex min-w-0 flex-1 items-center space-x-3">
                {/* <span className="block flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" />
          </span> */}
                <span className="block min-w-0 flex-1">
                  <span className="block truncate text-md font-medium text-gray-900 dark:text-slate-400">
                    {/* BANANA: make this real */}
                    {/* {notebooks.find((n) => n.url === file)?.name || */}
                    {mockWorkspace.name}
                  </span>
                  <div className="flex flex-row text-gray-900 dark:text-slate-500 text-xs items-center">
                    <span className="block truncate text-xs">
                      ID: {mockWorkspace.id}
                    </span>
                    <span className="mr-1.5 ml-1.5">&middot;</span>
                    <span className="mr-1.5">User: </span>
                    <span className="text-xs text-gray-900 dark:text-slate-500">
                      Jensen Huang
                    </span>
                    <span className="ml-1">
                      ({mockWorkspace.createdByUserId})
                    </span>
                    <span className="mr-1.5 ml-1.5">&middot;</span>
                    <span className="block truncate text-xs text-gray-900 dark:text-slate-500">
                      Created:{" "}
                      {` ${new Date(mockWorkspace.createdAt).toLocaleString()}`}
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div className="flex flex-row min-h-[145px]">
              <div className="p-2">
                <InstanceOverview
                  workspaceId={mockWorkspace.id}
                  workspaceInstanceType={mockWorkspace.instanceType}
                  workspaceStatus={mockWorkspace.status}
                  isGPUInstance={true}
                  providerName={mockWorkspace.providerName}
                  diskStorage={mockWorkspace.diskStorage}
                  vcpu={mockWorkspace.vcpu}
                  memory={mockWorkspace.memory}
                  gpuName={mockWorkspace.gpuName}
                  gpuManufacturer={mockWorkspace.gpuManufacturer}
                  gpuMemory={mockWorkspace.gpuMemory}
                  gpuCount={mockWorkspace.gpuCount}
                  instanceCost={
                    mockWorkspace.status === WorkspaceStatus.Running ||
                    mockWorkspace.status === WorkspaceStatus.Starting ||
                    mockWorkspace.status === WorkspaceStatus.Deploying
                      ? "2.48"
                      : "0.03"
                  }
                />
              </div>
              <div
                className={classNames(
                  "flex",
                  mockWorkspace.status === WorkspaceStatus.Running &&
                    mockWorkspace.verbBuildStatus !== VerbBuildStatus.Unset
                    ? "on"
                    : ""
                )}
              >
                <div className="flex justify-center items-center ml-3 mr-3">
                  <span className="text-xl">→</span>
                </div>
                <div className="p-2 min-w-[210px]">
                  <ContainerOverview
                    workspaceStatus={mockWorkspace.status}
                    workspaceVerbBuildStatus={mockWorkspace.verbBuildStatus}
                    workspaceVerbYaml=""
                  />
                  {mockWorkspace.status === WorkspaceStatus.Running && (
                    <div className="flex flex-col justify-center items-center mt-2 relative">
                      <span className="text-sm text-highlight dark:text-highlight p-1">
                        View Logs
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={classNames(
                  "flex",
                  isHealthy &&
                    mockWorkspace.verbBuildStatus === VerbBuildStatus.Completed
                    ? "on"
                    : ""
                )}
              >
                <div className="flex justify-cente items-center ml-3 mr-3">
                  <span className="text-xl">→</span>
                </div>
                <div
                  className={classNames(
                    "flex flex-col p-2",
                    isHealthy &&
                      mockWorkspace.verbBuildStatus ===
                        VerbBuildStatus.Completed
                      ? "on"
                      : ""
                  )}
                >
                  <div className="flex flex-row mb-1 items-center">
                    <BookOpenIcon className="h-6 w-6 mr-1" />
                    <span className="text-sm">Notebook</span>
                  </div>
                  <span className="text-sm mt-1 mb-1">
                    {notebookDetails.name}
                  </span>
                  <span
                    className="block truncate text-xs font-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Preview Notebook
                  </span>
                  {isHealthy &&
                    mockWorkspace.verbBuildStatus ===
                      VerbBuildStatus.Completed && (
                      <Button
                        type="primary"
                        label="Access Notebook"
                        className="mt-2"
                        onClick={() => {}}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FlatCard>
  );
};
