import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import {
  CONSOLE_2_LOGIN_URL,
  isKasAuthFlow,
  isLocalHost,
  LOCALHOST_LOGIN_URL,
  redirectUserToLogin,
} from "server/kas/utils";

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0?.();

  console.log("test");
  console.log("Route: /login");

  useEffect(() => {
    if (isKasAuthFlow) {
      redirectUserToLogin();
    } else {
      loginWithRedirect();
    }
  }, []);

  return <></>;
};

export default Login;
