import { Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import Notification from "./Notifications/Notification";

export interface NotificationContextType {
  showNotification: (
    title: string,
    message: string,
    severity: string,
    duration?: number,
    action?: any
  ) => void;
}

export const NotificationContextDefaultValues = {
  showNotification: (m: string, s: string) => null,
};

export const NotificationContext = React.createContext<NotificationContextType>(
  NotificationContextDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const NotificationContextProvider: React.FC<Props> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<string>("info");
  const [action, setAction] = useState<any>();
  const [duration, setDuration] = useState(9000);

  const showNotification = (
    title: string,
    message: string,
    severity: string,
    duration?: number,
    action?: any
  ) => {
    setTitle(title);
    setMessage(message);
    setSeverity(severity);
    setVisible(true);
    setDuration(duration || 7000);
    setAction(action);
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, duration);
  }, [visible]);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {/* TODO: fix this transition so it doesn't block the UI */}
      {/* <Transition
        className="absolute inset-0 flex items-center justify-center z-[0] h-[20%]"
        show={visible}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      > */}
      {visible && (
        <Notification
          title={title}
          message={message}
          severity={severity}
          action={action}
        />
      )}
      {/* </Transition> */}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
