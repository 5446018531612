import { Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import {
  classNames,
  displayPermissionHierarchyType,
  fallbackCopyTextToClipboard,
} from "components/utils";
import { UserContext } from "contexts/UserContext";
import { PermissionHierarchyType } from "models/Permission.model";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { BarList } from "../../../Graphs/BarList";
import {
  EyeIcon,
  RocketLaunchIcon,
  WrenchScrewdriverIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowUpRightIcon,
  TrashIcon,
  PencilSquareIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import { useHistory } from "react-router";
import CopyField from "components/UI-lib/CopyField";
import LaunchableConfigurationView from "../Deploy/shared/LaunchableConfigurationView";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import UsernameDisplayField from "components/UsernameDisplayField";
import { Collapse, Tooltip } from "@mui/material";
import { OrgContext } from "contexts/OrgContext";
import { LaunchableFromList } from "server";
import {
  getLaunchableUrl,
  processLaunchableData,
  shortenFileName,
  transformPortMapToList,
} from "../utils";
import EditLaunchableModal from "./EditModal";
import agent from "server";

interface LaunchableMetaProps {
  id: string;
  launchable: LaunchableFromList;
  views: number;
  deploys: number;
  userName?: string;
  disable?: boolean;
  loadingMetrics: boolean;
  setShowEditModal: (show: boolean) => void;
  deleteLaunchable: (orgID: string, id: string) => Promise<any>;
}

const LaunchableMeta: React.FC<LaunchableMetaProps> = (props) => {
  const userContext = useContext(UserContext);
  const orgContext = useContext(OrgContext);
  const [instance, setInstance] = useState<GPUInstanceType>();
  const [collapsible, setCollapsible] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const launchableDetails = processLaunchableData(props.launchable);

  const handleEditLaunchable = () => {
    setEditModalOpen(true);
  };

  const [instanceObject, setInstanceObject] = useState<GPUInstanceType | null>(
    null
  );
  const [loadingInstance, setLoadingInstance] = useState(false);
  const [shortFileName, setShortFileName] = useState("");

  const getInstanceFromType = async (
    instanceType: string,
    workspaceGroupID: string
  ): Promise<GPUInstanceType[] | null> => {
    const res = await agent.Instances.getInstanceByType(
      instanceType,
      workspaceGroupID
    );
    if (res.success && res.data && res.data.length > 0) {
      return res.data;
    }
    return null;
  };

  const getInstanceObject = async (
    Instance: string | null,
    WorkspaceGroupID: string | null
  ) => {
    const parsedInstance = parseInstance(Instance);
    if (!parsedInstance || !WorkspaceGroupID) {
      return null;
    }
    setLoadingInstance(true);
    const instanceTypes = await getInstanceFromType(
      parsedInstance.instanceType,
      WorkspaceGroupID
    );
    setLoadingInstance(false);
    if (instanceTypes) {
      setInstanceObject(instanceTypes[0]);
      setInstance(instanceTypes[0]);
    }
  };

  const parseInstance = (Instance: string | null) => {
    if (!Instance) {
      return null;
    }
    const [chipTypeOrInstanceType, instanceType] = Instance.split("@");
    if (instanceType) {
      return {
        chipType: chipTypeOrInstanceType,
        instanceType: instanceType,
      };
    } else {
      return {
        instanceType: chipTypeOrInstanceType,
      };
    }
  };

  useEffect(() => {
    async function updateShortFileName() {
      if (launchableDetails?.file) {
        const shortName = await shortenFileName(launchableDetails?.file || "");
        setShortFileName(shortName);
      } else {
        setShortFileName("");
      }
    }
    updateShortFileName();
  }, [launchableDetails?.file]);

  useEffect(() => {
    getInstanceObject(
      launchableDetails?.instanceType || "",
      launchableDetails?.workspaceGroupId || ""
    );
  }, [launchableDetails?.instanceType, launchableDetails?.workspaceGroupId]);

  return (
    <>
      {editModalOpen && (
        <EditLaunchableModal
          isOpen={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
          }}
          launchableId={launchableDetails?.launchableId || ""}
          initialData={{
            name: launchableDetails?.name || "",
            instance: instanceObject || undefined,
            storage: launchableDetails?.diskStorage || "",
            container: launchableDetails?.containerSelected,
            pythonVersion: launchableDetails?.python || "",
            cudaVersion: launchableDetails?.cuda || "",
            file: launchableDetails?.file || "",
            ports: transformPortMapToList(launchableDetails?.ports || {}),
          }}
        />
      )}
      <FlatCard className="mb-5">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <div className="flex flex-row items-end mb-1">
                <h1 className="text-md font-semibold text-white">
                  {launchableDetails?.name || "Unknown Name"}
                </h1>
                <span className="text-xs text-secondary mb-[3px] ml-2">
                  ID: {props.id}
                </span>
              </div>
              <div className="flex flex-row items-end mb-3">
                <span className="text-xs text-secondary mr-1">Created by:</span>
                <span className="text-xs text-secondary">
                  {props.userName ? (
                    props.userName
                  ) : (
                    <UsernameDisplayField
                      className="text-xs text-secondary"
                      userId={launchableDetails?.userId || ""}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="flex flex-row ml-auto items-start">
              <Tooltip title="Total Launchable Views" placement="top">
                <div className="flex flex-row items-center mr-3 cursor-pointer">
                  <EyeIcon className="h-4 w-4 text-gray-900 dark:text-white" />
                  <span className="text-md text-gray-900 dark:text-white ml-1">
                    {props.loadingMetrics ? "Loading..." : props.views}
                  </span>
                </div>
              </Tooltip>
              <Tooltip title="Total Launchable Deploys" placement="top">
                <div className="flex flex-row items-center mr-3 cursor-pointer">
                  <RocketLaunchIcon className="h-4 w-4 text-gray-900 dark:text-white" />
                  <span className="text-md text-gray-900 dark:text-white ml-1">
                    {props.loadingMetrics ? "Loading..." : props.deploys}
                  </span>
                </div>
              </Tooltip>
              <div className="flex items-center">
                <PencilSquareIcon
                  className={classNames(
                    "h-5 w-5 ml-3 mt-[2px] text-highlight",
                    props.launchable.rawURL
                      ? "opacity-20"
                      : "cursor-pointer  hover:text-highlightLighter"
                  )}
                  onClick={(e) => {
                    if (!!props.launchable.rawURL) {
                      return;
                    }
                    e.stopPropagation();
                    handleEditLaunchable();
                  }}
                />
                <TrashIcon
                  className="h-5 w-5 ml-3 mt-[2px] text-rose-500 hover:text-rose-400 cursor-pointer"
                  onClick={() => {
                    props.deleteLaunchable(orgContext.activeOrgId, props.id);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row">
              <CopyField
                className="max-w-[600px] min-w-[600px]"
                value={getLaunchableUrl(props.launchable)}
                analytics={{
                  userid: userContext.me?.id || "unknown",
                  eventLabel: "Copied Launchable Meta Link",
                }}
                disabled={props.disable}
              />
              <div
                className="flex flex-row items-center shimmer"
                onClick={() => {
                  if (props.disable) return;
                  window.open(
                    getLaunchableUrl(props.launchable),
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <span className="text-sm cursor-pointer ml-3 flex flex-row text-highlight">
                  View Launchable
                </span>
                <ArrowUpRightIcon className="h-4 text-highlight w-4 ml-1" />
              </div>
            </div>
          </div>
          <CopyMarkdownButton launchableUrl={launchableDetails?.url || ""} />
          <div className="flex flex-row items-end mt-3">
            <div
              className="flex flex-row items-center cursor-pointer text-highlight"
              onClick={() => {
                setCollapsible(!collapsible);
              }}
            >
              <span className="text-sm cursor-pointer flex flex-row">
                {collapsible ? "Hide" : "Show"} Configuration
              </span>
              {collapsible ? (
                <ChevronDownIcon className="h-5 w-5 ml-1" />
              ) : (
                <ChevronRightIcon className="h-5 w-5 ml-1" />
              )}
            </div>
          </div>
          <Collapse
            in={collapsible}
            sx={{
              marginTop: "",
              maxHeight: "500px",
            }}
          >
            <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
            <LaunchableConfigurationView
              baseImage={launchableDetails?.containerSelected.container || ""}
              cuda={launchableDetails?.cuda || ""}
              diskStorage={launchableDetails?.diskStorage || ""}
              file={launchableDetails?.file || ""}
              instanceObject={instanceObject || null}
              ports={launchableDetails?.ports || null}
              python={launchableDetails?.python || ""}
              disabled={props.disable}
              loadingInstance={loadingInstance}
              shortFileName={shortFileName}
            />
          </Collapse>
        </div>
      </FlatCard>
    </>
  );
};

export default LaunchableMeta;

interface CopyMarkdownButtonProps {
  launchableUrl: string;
}

const CopyMarkdownButton: React.FC<CopyMarkdownButtonProps> = ({
  launchableUrl,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const backgroundImageStyle = {
    backgroundImage: `url(https://uohmivykqgnnbiouffke.supabase.co/storage/v1/object/public/landingpage/brevdeploynavy.svg)`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "120px",
    height: "20px",
  };

  const getMarkDown = (url) => {
    return `[![ Click here to deploy.](https://uohmivykqgnnbiouffke.supabase.co/storage/v1/object/public/landingpage/brevdeploynavy.svg)](${url})`;
  };

  const handleClick = useCallback(() => {
    fallbackCopyTextToClipboard(getMarkDown(launchableUrl));
    setIsCopied(true);
  }, [launchableUrl]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <div className="flex flex-row items-center mt-3">
      <div
        className="flex flex-row items-center w-[150px] h-[20px] cursor-pointer"
        style={isHovered || isCopied ? {} : backgroundImageStyle}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {(isHovered || isCopied) && (
          <div
            className={`w-full h-full flex items-center justify-center text-xs border border-gray-500 dark:border-zinc-800 ${
              isCopied ? "text-highlight" : "text-gray-900 dark:text-slate-500"
            }`}
          >
            {isCopied ? "Copied Badge!" : "Copy markdown badge"}
          </div>
        )}
      </div>
    </div>
  );
};
