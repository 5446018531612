import FlatCard from "components/UI-lib/FlatCard";
import LaunchableConfigurationView from "../shared/LaunchableConfigurationView";
import { dummyInstanceObject } from "components/Environment/utils";
import React from "react";

export const LaunchableNotFound: React.FC = () => (
  <>
    <div className="flex flex-col justify-center mb-5">
      <h1 className="text-xl font-semibold text-rose-500">
        Launchable Not Found
      </h1>
      <span className="text-sm text-gray-900 dark:text-slate-500 font-semibold">
        Either the launchable id does not exist or was deleted.
      </span>
    </div>
    <div className="opacity-45 shimmer relative">
      <FlatCard className="min-h-[150px]">
        <LaunchableConfigurationView
          shortFileName="Launchable File Name"
          loadingInstance={false}
          forceJupyterInstall={false}
          baseImage={"your/base-image"}
          cuda={""}
          diskStorage={"256Gi"}
          file={"file name"}
          instanceObject={dummyInstanceObject}
          ports={null}
          python={""}
        />
      </FlatCard>
    </div>
  </>
);
