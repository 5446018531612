/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { OrgContext } from "contexts/OrgContext";
import IPermission, { PermissionHierarchyType } from "models/Permission.model";
import { PermissionsContext } from "contexts/PermissionsContext";
import InputField from "components/UI-lib/InputField";
import {
  EllipsisHorizontalIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { classNames, timeout } from "components/utils";
import { UserContext } from "contexts/UserContext";
import { BillingContext } from "contexts/BillingContext";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Spinner from "components/UI-lib/Spinner";

interface TeamMember {
  name: string;
  email: string;
  id: string;
  username: string;
  level?: string;
  permission: IPermission;
}

const TeamList: React.FC = (props) => {
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const billingContext = useContext(BillingContext);
  const [isLoading, setIsLoading] = useState(false);
  const permissionsContext = useContext(PermissionsContext);
  const [searchValue, setSearchValue] = useState("");
  const [allUsers, setAllUsers] = useState<TeamMember[]>([]);
  const [permissions, setPermissions] = useState<IPermission[]>([]);

  useEffect(() => {
    if (orgContext.activeOrgId) {
      loadPermissions(
        PermissionHierarchyType.Organization,
        orgContext.activeOrgId
      );
    }
    return () => permissionsContext.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgContext.activeOrgId]);

  const loadPermissions = async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string
  ) => {
    setIsLoading(true);
    const res = await permissionsContext.setup(hierarchyType, hierarchyId);
    if (!res.success) {
      console.log("Unable to load users");
      setPermissions([]);
    } else {
      console.log("Loaded users", res.data);
      setPermissions(res.data || []);
    }
    setIsLoading(false);
  };

  const moveOwnertoFront = (
    array: TeamMember[],
    targetEmail: string
  ): TeamMember[] => {
    const index = array.findIndex((item) => item.email === targetEmail);
    if (index > 0) {
      const target = array[index];
      array.splice(index, 1);
      array.unshift(target);
    }
    return array;
  };

  const getAllUsers = async (userPromises, permissions) => {
    let allUsers: TeamMember[] = [];
    try {
      allUsers = await Promise.all(userPromises);
    } catch (error) {
      setAllUsers([]);
    }
    allUsers = allUsers.map((user, index) => {
      return {
        name: user.name,
        email: user.email,
        id: user.id,
        username: user.username,
        permission: permissions[index],
      };
    });
    setAllUsers(allUsers);
  };

  useEffect(() => {
    const userPromises = permissions.map((permission) => {
      return userContext.getUser(permission.subject);
    });
    getAllUsers(userPromises, permissions);
  }, [permissions]);

  const filterUsers = (users: TeamMember[], searchValue) =>
    users.filter((item) => {
      if (!searchValue) return true;
      return (
        item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.email.toLowerCase().includes(searchValue.toLowerCase())
      );
    });

  const displayedUsers = useMemo(() => {
    let formattedUsers = moveOwnertoFront(
      allUsers,
      billingContext.billingProfile?.billing_profile_meta?.account_email || ""
    );

    //Temporary mapping for member status
    formattedUsers = formattedUsers.map((user) => {
      user.level = "Member";
      return user;
    });
    if (formattedUsers.length > 0) {
      formattedUsers[0].level = "Owner";
    }

    return filterUsers(formattedUsers, searchValue);
  }, [allUsers, searchValue, billingContext.billingProfile]);

  return (
    <div className="flex flex-col bg-primaryBg dark:text-secondary rounded-md px-4 py-4 w-full">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <UserGroupIcon className="w-5 h-5 mr-2" />
          <p className="text-md text-gray-700 dark:text-secondary">
            Team Members
          </p>
        </div>
        <div className="flex flex-row mt-5">
          <InputField
            className="min-w-[300px]"
            label=""
            placeholder="Search team members"
            value={searchValue}
            onChange={(val) => {
              setSearchValue(val);
            }}
            errorMessage=""
          />
        </div>
      </div>
      <div className="shadow-sm ring-1 ring-black ring-opacity-5 mt-3 overflow-x-auto pb-7">
        <table className="min-w-full border border-gray-300 dark:border-zinc-800">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-700 dark:text-secondary"
              >
                <div className="group inline-flex"></div>
              </th>
              <th
                scope="col"
                className="border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-700 dark:text-secondary"
              >
                <div className="group inline-flex">Name</div>
              </th>
              <th
                scope="col"
                className="border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-700 dark:text-secondary"
              >
                <div className="group inline-flex">Email</div>
              </th>
              <th
                scope="col"
                className="border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-700 dark:text-secondary"
              >
                <div className="group inline-flex">Level</div>
              </th>
              <th
                scope="col"
                className="border-b border-gray-300 dark:border-zinc-800 bg-gray-50 dark:bg-zinc-800 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-700 dark:text-secondary"
              >
                <div className="group inline-flex"></div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-zinc-900">
            {displayedUsers.map((item, index, list) => (
              <TeamListRow
                key={index}
                item={item}
                index={index}
                displaylist={list}
                allUsers={allUsers}
                setAllUsers={setAllUsers}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamList;

interface TeamListRowsProps {
  item: TeamMember;
  index: number;
  displaylist: TeamMember[];
  setAllUsers: (users: TeamMember[]) => void;
  allUsers: TeamMember[];
}

const TeamListRow: React.FC<TeamListRowsProps> = ({
  item,
  index,
  displaylist,
  allUsers,
  setAllUsers,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const tooltipRef = useRef(null);
  const userContext = useContext(UserContext);
  const [erroMessage, setErrorMessage] = useState("");
  const permissionsContext = useContext(PermissionsContext);
  const [isLoading, setIsLoading] = useState(false);

  const deletePermission = async (member: TeamMember) => {
    setIsLoading(true);
    if (member.id === userContext.me?.id || isLoading) return;
    const permissionRes = await permissionsContext.deletePermission(
      PermissionHierarchyType.Organization,
      member.permission.object,
      member.permission.subject,
      member.permission.role.id
    );
    if (permissionRes.success) {
      const index = allUsers.findIndex((user) => user.id === member.id);
      if (index > -1) {
        const newUsers = [...allUsers];
        newUsers.splice(index, 1);
        setAllUsers(newUsers);
      }
    } else {
      setShowOptions(true);
      setErrorMessage(
        permissionRes.message || "Unable to delete member. Contact Support."
      );
    }
    setIsLoading(false);
  };

  const reset = () => {
    setErrorMessage("");
    setIsLoading(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipRef.current &&
        !(tooltipRef.current as any).contains(event.target)
      ) {
        setShowOptions(false);
        reset();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    <>
      <tr>
        <td
          className={classNames(
            index !== displaylist.length - 1
              ? "border-b border-gray-200 dark:border-zinc-800"
              : "",
            "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
            "px-3 py-4"
          )}
        >
          <div className="w-15">
            <div className="w-10 h-10 rounded-full bg-highlight flex items-center justify-center">
              <span className="text-white font-semibold">
                {item.name ? item.name[0].toLocaleUpperCase() : "?"}
              </span>
            </div>
          </div>
        </td>
        <td
          className={classNames(
            index !== displaylist.length - 1
              ? "border-b border-gray-200 dark:border-zinc-800"
              : "",
            "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
            "px-3 py-4"
          )}
        >
          <div className="w-15">{item.name}</div>
        </td>
        <td
          className={classNames(
            index !== displaylist.length - 1
              ? "border-b border-gray-200 dark:border-zinc-800"
              : "",
            "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
            "px-3 py-4"
          )}
        >
          <div className="w-15">{item.email}</div>
        </td>
        <td
          className={classNames(
            index !== displaylist.length - 1
              ? "border-b border-gray-200 dark:border-zinc-800"
              : "",
            "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary ",
            "px-3 py-4"
          )}
        >
          <div className="w-15">{item.level || "Unknown"}</div>
        </td>
        <td
          className={classNames(
            index !== displaylist.length - 1
              ? "border-b border-gray-200 dark:border-zinc-800"
              : "",
            "whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-secondary",
            "px-3 py-4"
          )}
        >
          <div className="w-15 relative">
            {item.level !== "Owner" && (
              <div className="w-8 h-8" ref={tooltipRef}>
                {showOptions && (
                  <div className="position absolute bg-gray-50 dark:bg-zinc-800 top-8 right-[5px] sm:right-[20px] md:right-[25px] lg:right-[25px] xl:right-[50px] p-3 sm:z-10 rounded border border-gray-500 dark:border-zinc-800">
                    <div
                      className="flex flex-row text-red-500"
                      onClick={() => {}}
                    >
                      <ExclamationTriangleIcon className="w-5 h-5 mr-1" />
                      <span
                        className="cursor-pointer"
                        onClick={() => deletePermission(item)}
                      >
                        Remove Member
                      </span>
                      {isLoading && (
                        <span className="ml-3">
                          <Spinner type="primary" />
                        </span>
                      )}
                    </div>
                    {erroMessage && (
                      <span className="text-red-500 text-xs">
                        {erroMessage}
                      </span>
                    )}
                  </div>
                )}
                <EllipsisHorizontalIcon
                  className="w-8 h-8 hover:bg-gray-50 dark:hover:bg-zinc-700 rounded"
                  onClick={() => {
                    if (showOptions) {
                      reset();
                    }
                    setShowOptions(!showOptions);
                  }}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};
