import { useAuth0 } from "@auth0/auth0-react";
import { useKas } from "contexts/KasContext";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  CONSOLE_1_LOCALHOST,
  CONSOLE_2_LOGIN_URL,
  isKasAuthFlow,
  isLocalHost,
  prependKasUrl,
  redirectUserToLogin,
} from "server/kas/utils";

const Logout: React.FC = () => {
  const {
    isAuthenticated: auth0IsAuthenticated,
    isLoading: auth0IsLoading,
    logout,
  } = useAuth0?.();
  const { isUserLoggedIn, isLoading: isKasLoading, setIsLoggingOut } = useKas();

  const isAuthenticated = isKasAuthFlow ? isUserLoggedIn : auth0IsAuthenticated;
  const isLoading = isKasAuthFlow ? isKasLoading : auth0IsLoading;

  const history = useHistory();

  console.log("Route: /logout");

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      console.log("logout1");
      if (!isKasAuthFlow) {
        return history.push("/login");
      }
      redirectUserToLogin();
    } else {
      console.log("logout2");
      isKasAuthFlow
        ? fetch(prependKasUrl(`/v2/users/logout`), {
            method: "POST",
            credentials: "include",
          }).then(() => setIsLoggingOut(true))
        : logout({
            returnTo: isLocalHost
              ? CONSOLE_1_LOCALHOST
              : CONSOLE_1_AUTH0_DEPLOYMENT_URL,
          });
    }
  }, []);

  return <></>;
};

export default Logout;
