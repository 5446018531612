import IRole from "./Role.model";

export enum PermissionHierarchyType {
  Organization = "organizations",
  Workspace = "workspace",
}

export default interface IPermission {
  subject: string;
  object: string;
  role: IRole;
}
