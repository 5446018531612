import React from "react";
import BrevSlider from "../Slider";

interface PriceRangeFilterProps {
  priceRange: number[];
  setPriceRange: (number: number[]) => void;
}

const marks = [
  {
    value: 0,
    label: "$0",
  },
  {
    value: 100,
    label: "$100",
  },
];

const PriceRangeFilter: React.FC<PriceRangeFilterProps> = ({
  priceRange,
  setPriceRange,
}) => {
  const onChange = (value: number | number[]) => {
    setPriceRange(value as number[]);
  };

  return (
    <>
      <div className="flex flex-col px-3">
        <BrevSlider
          step={1}
          value={priceRange}
          onChange={onChange}
          max={100}
          marks={marks}
        />
      </div>
    </>
  );
};

export default PriceRangeFilter;
