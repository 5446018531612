import React, { useContext, useEffect, useState } from "react";

import {
  BookOpenIcon,
  //   BookmarkIcon,
  ChevronRightIcon,
  RssIcon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { UserContext } from "contexts/UserContext";

interface LocationState {
  title?: string;
  description?: string;
}

interface ParamTypes {
  httpStatus: string;
}

const links = [
  {
    title: "Documentation",
    description: "Reference docs for Brev.dev",
    icon: BookOpenIcon,
    link: "https://docs.brev.dev",
  },
  {
    title: "Get Support",
    description: "Reach out to us by email at brev-support@nvidia.com",
    icon: ChatBubbleLeftEllipsisIcon,
    link: "mailto:brev-support@nvidia.com",
  },
  {
    title: "Blog",
    description: "Read our latest news and articles",
    icon: RssIcon,
    link: "https://brev.dev/blog",
  },
];

const HelpPage: React.FC = () => {
  console.log("Route: /help");

  const { httpStatus } = useParams<ParamTypes>();
  const { state } = useLocation<LocationState>();
  const userContext = useContext(UserContext);

  const [statusCode, setStatusCode] = useState(httpStatus || "500");
  const [title, setTitle] = useState(state?.title || "Error");
  const [description, setDescription] = useState(
    state?.description || "Error. Try again!"
  );

  const history = useHistory();

  useEffect(() => {
    if (statusCode === "404") {
      setTitle("Page Not Found");
      setDescription("");
    }
  }, [statusCode]);

  useEffect(() => {
    if (statusCode) document.title = `${statusCode} | Brev.dev`;
    else document.title = "Error | Brev.dev";
  }, [statusCode]);

  useEffect(() => {
    if (description.includes("duplicate username")) {
      setStatusCode("500");
      setTitle("Cannot Create Account");
      setDescription(
        "You already have another account registered with this username. Try another login option!"
      );
    }
  }, [description]);

  return (
    <div className="">
      <main className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-xl py-16">
          <div className="text-center">
            <p className="text-base font-semibold text-cyan-600">
              {httpStatus}
            </p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
              Help Center
            </h1>
            <p className="mt-2 text-lg text-gray-500 dark:text-secondary">{`Hey ${
              userContext?.me?.username || "there"
            } 👋, these are some resources`}</p>
          </div>
          <div className="mt-12">
            <h2 className="text-base font-semibold text-gray-500 dark:text-secondary">
              Popular pages
            </h2>
            <ul
              role="list"
              className="mt-4 divide-y divide-gray-200 dark:divide-zinc-800 border-t border-b border-gray-200 dark:border-zinc-800"
            >
              {links.map((link, linkIdx) => (
                <li
                  key={linkIdx}
                  className="relative flex items-start space-x-4 py-6"
                >
                  <div className="flex-shrink-0">
                    <span className="flex h-12 w-12 items-center justify-center rounded-lg bg-cyan-50 dark:bg-highlight">
                      <link.icon
                        className="h-6 w-6 text-highlight"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900 dark:text-secondary">
                      <span className="rounded-sm focus-within:ring-2 focus-within:ring-cyan-500 dark:focus-within:ring-cyan-100 focus-within:ring-offset-2 dark:focus-within:ring-offset-0">
                        <a
                          href={link.link}
                          target="_blank"
                          rel="noreferrer"
                          className="focus:outline-none"
                        >
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          {link.title}
                        </a>
                      </span>
                    </h3>
                    <p className="text-base text-gray-500 dark:text-secondary">
                      {link.description}
                    </p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <a
                href="/"
                className="text-base font-medium text-cyan-600 dark:text-cyan-300 hover:text-highlight dark:hover:text-cyan-100"
              >
                Or go back home
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HelpPage;
