import React, { act, useContext, useEffect, useMemo, useState } from "react";
import { RiVipCrown2Fill } from "@remixicon/react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import { LaunchableContext } from "contexts/LaunchableContext";
import UsernameDisplayField from "components/UsernameDisplayField";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { useHistory } from "react-router";

const LaunchableLeaderboard: React.FC = () => {
  const launchableContext = useContext(LaunchableContext);
  const history = useHistory();

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col justify-start mb-8">
          <h1 className="text-xl font-medium dark:text-white">
            Launcher Leaderboard
          </h1>
          <p className="text-sm text-gray-700 dark:text-secondary">
            Last 30 days
          </p>
        </div>
      </div>
      {launchableContext.loadingDailyMetrics ? (
        <>
          <div className="flex relative w-full justify-center">
            <div className="flex flex-row items-center">
              <span className="text-white font-bold">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          {launchableContext.userMetricsList.length === 0 ? (
            <>
              <div className="flex relative w-full justify-center mb-5">
                <div
                  className="flex flex-row items-center cursor-pointer text-white hover:text-highlight"
                  onClick={() => {
                    history.push(`/launchables/create`);
                  }}
                >
                  <span className="text-sm font-bold">
                    No leaderboard metrics found, try creating and sharing a
                    Launchable
                  </span>
                  <ArrowUpRightIcon className="h-4 w-4 ml-1" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-row justify-center items-end">
                <div className="w-1/5 h-32 bg-slate-800 rounded-t-3xl">
                  {launchableContext.userMetricsList.length >= 2 && (
                    <div className="flex flex-col justify-center items-center text-blue-400">
                      <p className="flex flex-row text-lg text-white mt-3">
                        #2{" "}
                        <UsernameDisplayField
                          className="ml-1"
                          userId={launchableContext.userMetricsList[1].userId}
                        />
                      </p>
                      <div className="flex flex-col items-center mt-2">
                        <p className="text-md text-bold">
                          {launchableContext.userMetricsList[1].totalDeploys}{" "}
                          Deploys
                        </p>
                        <p className="text-sm text-bold">
                          {launchableContext.userMetricsList[1].totalViews}{" "}
                          Views
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-1/5 h-52 bg-slate-600 rounded-t-3xl">
                  {launchableContext.userMetricsList.length >= 1 && (
                    <div className="text-amber-400 ">
                      <div className="flex flex-col justify-center items-center">
                        <RiVipCrown2Fill className="text-4xl mt-3" />
                        <p className="flex flex-row text-lg text-white">
                          #1{" "}
                          <UsernameDisplayField
                            className="ml-1"
                            userId={launchableContext.userMetricsList[0].userId}
                          />
                        </p>
                        <div className="flex flex-col items-center mt-2">
                          <p className="text-lg text-bold">
                            {launchableContext.userMetricsList[0].totalDeploys}{" "}
                            Deploys
                          </p>
                          <p className="text-md text-bold">
                            {launchableContext.userMetricsList[0].totalViews}{" "}
                            Views
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-1/5 h-32 bg-slate-800 rounded-t-3xl">
                  {launchableContext.userMetricsList.length >= 3 && (
                    <div className="flex flex-col justify-center items-center text-green-400">
                      <p className="flex flex-row text-lg text-white mt-3">
                        #3{" "}
                        <UsernameDisplayField
                          className="ml-1"
                          userId={launchableContext.userMetricsList[2].userId}
                        />
                      </p>
                      <div className="flex flex-col items-center mt-2">
                        <p className="text-md text-bold">
                          {launchableContext.userMetricsList[2].totalDeploys}{" "}
                          Deploys
                        </p>
                        <p className="text-sm text-bold">
                          {launchableContext.userMetricsList[2].totalViews}{" "}
                          Views
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
              <TableRoot>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeaderCell>Rank</TableHeaderCell>
                      <TableHeaderCell>Name</TableHeaderCell>
                      <TableHeaderCell>Total Deploys</TableHeaderCell>
                      <TableHeaderCell>Total Views</TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {launchableContext.userMetricsList.map(
                      (userMetrics, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <span className="text-sm text-gray-500 dark:text-secondary ">
                              #{index + 1}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="text-sm text-gray-500 dark:text-secondary ">
                              <UsernameDisplayField
                                className="truncate whitespace-nowrap"
                                userId={userMetrics.userId}
                              />
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="text-sm text-gray-500 dark:text-secondary">
                              {userMetrics.totalDeploys}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="text-sm text-gray-500 dark:text-secondary">
                              {userMetrics.totalViews}
                            </span>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableRoot>
            </>
          )}
        </>
      )}
    </>
  );
};

export default LaunchableLeaderboard;
