/* eslint-disable react/no-unused-prop-types */
// they are being used idk they aren't beinf detected by eslint
import React, { useContext, useEffect, useState } from "react";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import {
  ArrowDownCircleIcon,
  ArrowDownOnSquareIcon,
  BookOpenIcon,
  CommandLineIcon,
  CpuChipIcon,
  KeyIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import { ComputeItem, ContainerItem, NavItem, RightArrow } from "./NavItem";
import Workspace from "entities/Workspace.entity";
import { OrgContext } from "contexts/OrgContext";
import { isGPUfromInstanceType } from "components/Environment/utils";
import ConstanterStatusIndicator from "components/Environment/shared/ContainerStatusIndicator";
import InstanceStatusIndicator from "components/Environment/shared/InstanceStatusIndicator";

interface TabNavigatorProps {
  currentState?: string;
  setCurrentState: (
    state: "Compute" | "Container" | "Notebook" | "Access" | "None"
  ) => void;
  workspace: Workspace | null;
}

const TabNavigator: React.FC<TabNavigatorProps> = ({
  currentState,
  setCurrentState,
  workspace,
}) => {
  const orgContext = useContext(OrgContext);

  return (
    <>
      <nav aria-label="Progress">
        <ol
          role="list"
          className="divide-y border-zinc-800 md:flex md:divide-y-0 border border-2 flex justify-evenly bg-baseBg rounded-md"
        >
          <NavItem
            className="justify-center"
            label="Compute"
            IconComponent={CpuChipIcon}
            onClick={() => {
              setCurrentState("Compute");
            }}
            active={currentState === "Compute"}
          />
          <RightArrow />
          {workspace &&
            isGPUfromInstanceType(
              workspace.instanceType || "",
              orgContext.allInstancesAvailable
            ) && (
              <>
                <NavItem
                  className="justify-center"
                  label="Container"
                  IconComponent={RectangleGroupIcon}
                  onClick={() => {
                    setCurrentState("Container");
                  }}
                  active={currentState === "Container"}
                />
                <RightArrow />
              </>
            )}
          {workspace &&
            isGPUfromInstanceType(
              workspace.instanceType || "",
              orgContext.allInstancesAvailable
            ) &&
            workspace.fileObjects &&
            Object.entries(workspace.fileObjects).length > 0 && (
              <>
                <NavItem
                  className="justify-center"
                  label="Notebook"
                  IconComponent={BookOpenIcon}
                  onClick={() => {
                    setCurrentState("Notebook");
                  }}
                  active={currentState === "Notebook"}
                />
                <RightArrow />
              </>
            )}
          <NavItem
            className="justify-center"
            label="Access"
            IconComponent={ArrowDownOnSquareIcon}
            onClick={() => {
              setCurrentState("Access");
            }}
            active={currentState === "Access"}
          />
        </ol>
      </nav>
    </>
  );
};

export default TabNavigator;
