import {
  displayPlatformType,
  formatMemory,
  isGPUfromInstanceType,
} from "components/Environment/utils";
import NotebookList from "components/NotebookList";
import HtmlRenderer from "components/Notebooks/HtmlRenderer";
import { classNames } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import Workspace from "entities/Workspace.entity";
import { WorkspaceStatus } from "models/Workspace.model";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Chip from "../Create/chips";
import { DarkModeContext } from "contexts/DarkModeContext";
import { InstanceAttribute } from "../Settings/Tabs/Compute/InstanceChanger/InstanceAttribute";
import {
  CircleStackIcon,
  CloudIcon,
  CpuChipIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/solid";
import { getWorkspaceGroupObject } from "../Settings/utils";
import InstanceStatusIndicator from "./InstanceStatusIndicator";
interface InstanceOverviewProps {
  workspaceId: string;
  workspaceInstanceType?: string;
  workspaceStatus: WorkspaceStatus;
  instanceCost: string;
  isGPUInstance: boolean;
  providerName?: string;
  diskStorage?: string;
  vcpu?: number;
  memory?: string | number;
  gpuName?: string;
  gpuManufacturer?: string;
  gpuMemory?: string;
  gpuCount?: number;
  platformType?: string;
}
const InstanceOverview: React.FC<InstanceOverviewProps> = (props) => {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <>
      <div
        className={classNames(
          "flex flex-col",
          props.workspaceStatus === WorkspaceStatus.Starting ||
            props.workspaceStatus === WorkspaceStatus.Deploying ||
            props.workspaceStatus === WorkspaceStatus.Running
            ? "on"
            : ""
        )}
      >
        <div className="flex flex-row mb-2 items-center">
          <div className="flex flex-row items-center mr-3">
            <CpuChipIcon className="h-6 w-6 mr-1" />
            <span className="text-sm">Compute</span>
          </div>
          <InstanceStatusIndicator workspaceStatus={props.workspaceStatus} />
        </div>
        <div className="flex flex-row justify-center items-center">
          {props.isGPUInstance && (
            <Chip
              key={`instance-meta-${props.workspaceId}`}
              selected={
                !darkMode ||
                props.workspaceStatus === WorkspaceStatus.Running ||
                props.workspaceStatus === WorkspaceStatus.Deploying ||
                props.workspaceStatus === WorkspaceStatus.Starting
              }
              chipName={props.gpuName || ""}
              manufacturerName={props.gpuManufacturer || ""}
              onClick={() => {}}
              className={classNames("h-[68px] mr-2 flex-shrink-0")}
              whiteGlow={
                props.workspaceStatus === WorkspaceStatus.Running ||
                props.workspaceStatus === WorkspaceStatus.Deploying ||
                props.workspaceStatus === WorkspaceStatus.Starting
                  ? false
                  : true
              }
            />
          )}
          <div>
            {props.workspaceInstanceType && (
              <>
                <InstanceAttribute
                  instanceType={props.workspaceInstanceType}
                  isGPUInstance={props.isGPUInstance}
                  vcpu={props.vcpu}
                  memory={formatMemory(
                    props.memory || "",
                    props.platformType || ""
                  )}
                  gpuManufacturer={props.gpuManufacturer}
                  gpuName={props.gpuName}
                  gpuMemory={props.gpuMemory}
                  gpuCount={props.gpuCount}
                ></InstanceAttribute>
              </>
            )}
            <div className="flex flex-col mt-2">
              <div className="flex flex-row">
                <div className="flex flex-row mb-1 items-center mr-2">
                  <CircleStackIcon className="h-6 w-6 mr-1" />
                  <span className="text-sm">{props.diskStorage || "0"}</span>
                </div>
                <div className="flex flex-row mb-1 items-center">
                  <CloudIcon className="h-6 w-6 mr-1" />
                  <span className="text-sm">
                    {displayPlatformType(props.providerName || "Unknown")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex ml-[2px]">
          <div className="flex items-center text-xs">
            {props.workspaceStatus === WorkspaceStatus.Starting ||
            props.workspaceStatus === WorkspaceStatus.Deploying ||
            props.workspaceStatus === WorkspaceStatus.Running ? (
              <PlayCircleIcon className="h-4 w-4 mr-1" />
            ) : (
              <PauseCircleIcon className="h-4 w-4 mr-1" />
            )}
            {"$" + props.instanceCost + "/hr"}
          </div>
        </div>
      </div>
    </>
  );
};

export default InstanceOverview;
