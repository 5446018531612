import { Tooltip } from "@mui/material";
import { classNames } from "components/utils";
import React, { useState } from "react";
import { useWindupString } from "windups";

export interface ProviderAttributeProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  className?: string;
}

export const ProviderAttribute: React.FC<ProviderAttributeProps> = (props) => {
  return (
    <>
      <Tooltip title={props.description} placement="top">
        <div
          className={classNames(
            "flex flex-row items-center",
            props.className ? props.className : ""
          )}
        >
          {props.icon}
          {props.title && <ShowWindUp text={props.title} className="ml-1.5" />}
        </div>
      </Tooltip>
    </>
  );
};

interface ShowWindUpProps {
  text: string;
  className?: string;
}
const ShowWindUp: React.FC<ShowWindUpProps> = (props) => {
  const [text] = useWindupString(props.text);

  return <span className={props.className}>{text}</span>;
};

interface ProviderAttributesWrapperProps {
  attributes: ProviderAttributeProps[];
}

export const ProviderAttributesWrapper: React.FC<
  ProviderAttributesWrapperProps
> = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="flex flex-row items-center"
    >
      {props.attributes.map((attribute, index) => (
        <ProviderAttribute
          key={index}
          icon={attribute.icon}
          title={isHovered ? attribute.title : ""}
          description={attribute.description}
          className={attribute.className}
        />
      ))}
    </div>
  );
};
