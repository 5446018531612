export enum SecretType {
  Reference = "ref",
  Concrete = "concrete",
}

export enum HierarchyType {
  User = "user",
  Organization = "org",
  Workspace = "workspace",
}

export enum SrcType {
  KeyValue = "kv2",
}

export enum DestType {
  File = "file",
  EnvVariable = "env",
}

type DestConfigFile = {
  path?: string;
};

type DestConfigEnv = {
  name?: string;
};

export type SrcConfig = { value?: string };

export type DestConfig = DestConfigFile | DestConfigEnv;

export default interface ISecret {
  id: string;
  name: string;
  type: SecretType;
  refId?: string;
  hierarchyType: HierarchyType;
  hierarchyId: string;
  src: {
    type: SrcType;
    config: SrcConfig;
  };
  dest: {
    type: DestType;
    config: DestConfig;
  };
}

export type CreateSecretReq = Omit<ISecret, "id" | "refId" | "type">;

export type PendingSecret = Omit<CreateSecretReq, "hierarchyId">;
