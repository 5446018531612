// they are being used idk they aren't beinf detected by eslint
import React, { useContext, useEffect, useMemo, useState } from "react";
import Modal from "components/Modals/Modal";
import Workspace from "../../../../../../entities/Workspace.entity";
import Button from "components/UI-lib/Button";
import { WorkspaceStatus } from "models/Workspace.model";
import { PermissionsContext } from "contexts/PermissionsContext";
import { PermissionHierarchyType } from "models/Permission.model";
import { OrgContext } from "contexts/OrgContext";
import UsernameDisplayField from "components/UsernameDisplayField";
import Toggle from "components/UI-lib/Toggle";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import FlatCard from "components/UI-lib/FlatCard";
import { set } from "lodash";
import { NotificationContext } from "contexts/NotificationContext";
import { UserContext } from "contexts/UserContext";

interface ShareSettingsProps {
  workspace: Workspace;
}
const ShareSettings: React.FC<ShareSettingsProps> = (
  props: ShareSettingsProps
) => {
  const [loadingShare, setLoadingShare] = useState(false);
  const permissionsContext = useContext(PermissionsContext);
  const orgContext = useContext(OrgContext);
  const workspaceContext = useContext(WorkspaceContext);
  const notificationContext = useContext(NotificationContext);
  const userContext = useContext(UserContext);

  const otherUsers = useMemo(() => {
    return permissionsContext.permissions.filter(
      (permission) => permission.subjectId !== userContext.me?.id
    );
  }, [permissionsContext.permissions]);

  useEffect(() => {
    if (orgContext.activeOrgId && PermissionHierarchyType.Organization) {
      loadPermissions(
        PermissionHierarchyType.Organization,
        orgContext.activeOrgId
      );
    }
    return () => permissionsContext.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgContext.activeOrgId, PermissionHierarchyType.Organization]);

  const loadPermissions = async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string
  ) => {
    // setInlineAlertMessage("");
    setLoadingShare(true);
    const res = await permissionsContext.setup(hierarchyType, hierarchyId);
    if (!res.success) {
      console.log(res);
    } else {
      console.log(res);
    }
    setLoadingShare(false);
  };

  const [checkedPermissions, setCheckedPermissions] = useState({});

  const handleCheckboxChange = (subjectId) => {
    // Toggle the checked state for the permission
    setCheckedPermissions((prev) => ({
      ...prev,
      [subjectId]: !prev[subjectId],
    }));
  };

  const handleShare = async () => {
    setLoadingShare(true);
    const checkedUsers = Object.entries(checkedPermissions)
      .filter(([_, checked]) => checked)
      .map(([userId, _]) => userId);
    const res = await workspaceContext.shareWorkspace(
      props.workspace.id,
      checkedUsers,
      orgContext.activeOrgId
    );
    setLoadingShare(false);
    console.log(res);
    if (res.success) {
      notificationContext.showNotification(
        "Successfully Added Users to the Instance",
        "",
        "success"
      );
    } else {
      notificationContext.showNotification(
        "Failed to add Users to the Instance",
        res.message,
        "error"
      );
    }
  };

  return (
    <>
      <div className="flex flex-col justify-start items-start w-full mt-8">
        <h1 className="text-xl mb-3 font-medium text-primary dark:text-white">
          Add Users
        </h1>
        <FlatCard className="mb-3">
          {props.workspace.status === WorkspaceStatus.Running ? (
            <>
              <div className="flex flex-row justify-between items-center mb-2">
                <div>
                  <p className="text-sm text-gray-900 dark:text-white mb-1">
                    Allow other people in your organization to easily SSH into
                    your machine
                  </p>
                  <p className="text-sm text-gray-900 dark:text-white mb-3">
                    (This is a beta feature and users CAN NOT be removed once
                    they are added)
                  </p>
                </div>
                <div>
                  <Button
                    onClick={() => handleShare()}
                    iconLeft={<UserPlusIcon className="h-5 w-5" />}
                    label="Share"
                    loading={loadingShare}
                    disabled={
                      loadingShare ||
                      Object.entries(checkedPermissions).filter(
                        ([_, checked]) => checked
                      ).length === 0
                    }
                  />
                </div>
              </div>
              <hr />
              {otherUsers.length > 0 ? (
                otherUsers.map((permission) => (
                  <div
                    key={permission.subjectId}
                    className="flex justify-between mt-3 mb-3 h-2/3"
                    data-attribute={permission.subjectId}
                  >
                    {/* Assuming UsernameDisplayField is a component you have for displaying usernames */}
                    <UsernameDisplayField
                      userId={permission.subjectId}
                      className="text-gray-900 dark:text-white"
                    />
                    <div className="flex flex-row">
                      {!!props.workspace.additionalUsers &&
                        props.workspace.additionalUsers.includes(
                          permission.subjectId
                        ) && (
                          <p className="text-xs mr-3 justify-center">
                            Already added
                          </p>
                        )}
                      <Toggle
                        label=""
                        checked={
                          !!checkedPermissions[permission.subjectId] ||
                          (!!props.workspace.additionalUsers &&
                            props.workspace.additionalUsers.includes(
                              permission.subjectId
                            ))
                        }
                        onChange={() =>
                          handleCheckboxChange(permission.subjectId)
                        }
                        description=""
                        disabled={
                          props.workspace.additionalUsers &&
                          props.workspace.additionalUsers.includes(
                            permission.subjectId
                          )
                        }
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-5">
                  No one else is in the organization to share
                </div>
              )}
            </>
          ) : (
            <>Workspace needs to be running in order to share</>
          )}
        </FlatCard>
        <div />
      </div>
    </>
  );
};

export default ShareSettings;
