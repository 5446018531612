import React, { useContext, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import FlatCard from "components/UI-lib/FlatCard";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/Graphs/Tabs";
import { allowedImageList } from "components/Environment/Create/utils";
import CustomImageSelector from "./CustomImageSelector";
import AllowedImageListSelector from "./AllowedImageListSelector";
import { ContainerSelected } from "../EnvironmentCreate";
import { Toggle } from "components/UI-lib";

export interface ContainerSelectorProps {
  containerSelected: ContainerSelected;
  onSelectContainer: (container: ContainerSelected) => void;
  pythonVersion: string;
  setPythonVersion: (pythonVersion: string) => void;
  cudaVersion: string;
  setCudaVersion: (cudaVersion: string) => void;
  hideTitle?: boolean;
  hideCustomCredentials?: boolean;
  hideForceInstallJupyterToggle?: boolean;
}

const ContainerSelector: React.FC<ContainerSelectorProps> = ({
  containerSelected,
  onSelectContainer,
  pythonVersion,
  setPythonVersion,
  cudaVersion,
  setCudaVersion,
  hideTitle,
  hideCustomCredentials,
  hideForceInstallJupyterToggle,
}) => {
  const orgContext = useContext(OrgContext);
  const [containerPickerOpen, setContainerPickerOpen] = useState(false);
  const [tab, setTab] = useState<"Recommended" | "Custom Container">(
    "Recommended"
  );

  const [forceJupyterInstall, setForceJupyterInstall] = useState<boolean>(
    !!containerSelected.forceJupyterInstall
  );

  return (
    <>
      <div className="flex flex-col">
        {!hideTitle && (
          <div className="flex mb-5">
            <h1 className="text-xl font-bold text-gray-900 dark:text-slate-100">
              Select your Container
            </h1>
          </div>
        )}
        <div className={"flex flex-col"}>
          <div className="mt-4 grid grid-cols-1 gap-2 sm:grid-cols-2">
            <div
              onClick={() => {
                setContainerPickerOpen(true);
              }}
            >
              <FlatCard
                className={`bg-secondaryBg p-4 w-full flex flex-row rounded-md border-2 border-dashed 
                 min-h-[90px] cursor-pointer dark:text-highlight dark:hover:text-highlightLighter hover:text-cyan-300 hover:dark:border-highlight`}
                key="Default"
              >
                <div className="flex flex-col justify-center h-full">
                  <div className="text-md">Container Mode</div>
                  <div className="text-xs">
                    Select a docker container to bootstrap your instance
                  </div>
                </div>
              </FlatCard>
            </div>
            <div
              onClick={() => {
                onSelectContainer({
                  isCustomContainer: false,
                  container: "VM Mode",
                  forceJupyterInstall: forceJupyterInstall,
                });
              }}
            >
              <FlatCard
                className="bg-secondaryBg p-4 w-full flex flex-row rounded-md border-2 border-dashed border-gray-300 dark:border-zinc-800 min-h-[90px] cursor-pointer text-cyan-400 dark:text-highlight dark:hover:text-highlightLighter hover:text-cyan-300 hover:dark:border-highlight"
                key="None"
              >
                <div className="flex flex-col justify-center h-full">
                  <div className="flex flex-row">
                    <div className="text-md">VM Mode</div>
                    {!hideForceInstallJupyterToggle && (
                      <div className="ml-3 mb-1">
                        <Toggle
                          checked={forceJupyterInstall}
                          onChange={(forceJupyter) => {
                            setForceJupyterInstall(forceJupyter);
                          }}
                          label="Preinstall Jupyter"
                        />
                      </div>
                    )}
                  </div>
                  <div className="text-xs">
                    Start an instance without a container. Access services will
                    be installed on the host machine
                  </div>
                </div>
              </FlatCard>
            </div>
          </div>
          <hr className="w-[100%] my-5 dark:border dark:border-zinc-800" />
          <div className="w-full">
            {containerPickerOpen && (
              <Tabs
                value={tab}
                onValueChange={(val) => {
                  setTab(val as "Recommended" | "Custom Container");
                }}
              >
                <TabsList className="grid w-full grid-cols-2" variant="line">
                  <TabsTrigger value="Recommended">Recommended</TabsTrigger>
                  <TabsTrigger value="Custom Container">
                    Custom Container
                  </TabsTrigger>
                </TabsList>
                <div className="mt-4">
                  <TabsContent value="Recommended">
                    <AllowedImageListSelector
                      allowedImageList={allowedImageList}
                      setContainer={onSelectContainer}
                      setCudaVersion={setCudaVersion}
                      setPythonVersion={setPythonVersion}
                      pythonVersion={pythonVersion}
                      cudaVersion={cudaVersion}
                    />
                  </TabsContent>
                  <TabsContent value="Custom Container" className="mb-5">
                    <CustomImageSelector
                      onSubmit={onSelectContainer}
                      hideCredentials={hideCustomCredentials}
                    />
                  </TabsContent>
                </div>
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContainerSelector;
