import FlatCard from "components/UI-lib/FlatCard";
import { classNames } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UsageResp, UsageTableRow } from "server";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import { BarList } from "components/Graphs/BarList";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { EndpointsUsageTable } from "./EndpointsUsageSummary";
import UsernameDisplayField from "components/UsernameDisplayField";

interface UsageSummaryProps {}

const UsageSummary: React.FC<UsageSummaryProps> = () => {
  const orgContext = useContext(OrgContext);
  const [selectedTab, setSelectedTab] = useState("compute");
  const [visibleBillingTabs, setVisibleBillingTabs] = useState([
    "compute",
    "endpoints",
  ]);

  const flagShowBrevEndpoints = useFeatureFlagEnabled("brev-endpoints");

  useEffect(() => {
    if (!flagShowBrevEndpoints) {
      setVisibleBillingTabs(
        visibleBillingTabs.filter((tab) => tab !== "endpoints")
      );
    } else {
      setVisibleBillingTabs((prevTabs) => {
        if (!prevTabs.includes("endpoints")) {
          return [...prevTabs, "endpoints"];
        }
        return prevTabs;
      });
    }
  }, [flagShowBrevEndpoints]);
  const getTotalCost = (selectedTab: string) => {
    let totalCost = 0.0;
    orgContext.usage
      .filter((usage) => {
        if (selectedTab === "compute") {
          return usage.UsageType === "compute" || usage.UsageType === "storage";
        } else {
          return usage.UsageType === "apiInvocation";
        }
      })
      .forEach((transaction) => {
        if (transaction.Cost) {
          totalCost += parseFloat(transaction.Cost);
        }
      });
    try {
      return `${calc(totalCost).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`;
    } catch (error) {
      return totalCost;
    }
  };
  return (
    <>
      <div className="mt-6 border-b border-gray-200 dark:border-gray-700 mb-6">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {visibleBillingTabs.map((tab) => (
            <button
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`
                      whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                      ${
                        selectedTab === tab
                          ? "border-highlight text-cyan-600 dark:text-highlight"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-600"
                      }
                    `}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
      </div>
      <FlatCard className="" noBorder={true}>
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-xl font-medium dark:text-white">
            {selectedTab === "compute" ? "Usage" : "Inference"} Summary for{" "}
            {getCurrentMonth()} {new Date().getFullYear()}
          </h1>
          <h1 className="text-xl font-medium dark:text-white">
            Total Cost: {getTotalCost(selectedTab)}
          </h1>
        </div>
        <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
        {selectedTab === "compute" && <UsageTable />}
        {selectedTab === "endpoints" && <EndpointsUsageTable />}
      </FlatCard>
    </>
  );
};

function calc(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

function getCurrentMonth() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();
  const currentMonth = monthNames[currentDate.getMonth()];

  return currentMonth;
}

const UsageTable: React.FC<UsageSummaryProps> = () => {
  const orgContext = useContext(OrgContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const transformUsages = (usages: UsageResp[]): UsageTableRow[] => {
    const result: Record<string, UsageTableRow> = {};

    usages.forEach((usage) => {
      if (!result[usage.EnvID]) {
        result[usage.EnvID] = {
          EnvID: usage.EnvID,
          EnvName: usage.EnvName,
          CreatedByUserID: usage.CreatedByUserID,
          ComputeCost: 0,
          StorageCost: 0,
          TotalCost: 0,
        };
      }
      if (usage.UsageType === "compute") {
        result[usage.EnvID].ComputeCost = parseFloat(usage.Cost);
      } else if (usage.UsageType === "storage") {
        result[usage.EnvID].StorageCost = parseFloat(usage.Cost);
      }
      result[usage.EnvID].TotalCost =
        result[usage.EnvID].ComputeCost + result[usage.EnvID].StorageCost;
    });

    return Object.values(result);
  };

  const getTotalCost = () => {
    let totalCost = 0.0;
    orgContext.usage.forEach((transaction) => {
      if (transaction.Cost) {
        totalCost += parseFloat(transaction.Cost);
      }
    });
    try {
      return `${calc(totalCost).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`;
    } catch (error) {
      return totalCost;
    }
  };

  const transformedUsages = useMemo(() => {
    return transformUsages(orgContext.usage).sort((a, b) => {
      return b.TotalCost - a.TotalCost;
    });
  }, [orgContext.usage]);

  const currentUsages = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return transformedUsages.slice(startIndex, startIndex + itemsPerPage);
  }, [currentPage, transformedUsages]);

  const totalPages = Math.ceil(transformedUsages.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (orgContext.usageLoading) {
    return (
      <FlatCard
        className="w-full justify-center"
        isLoading={orgContext.usageLoading}
      >
        <div className="flex w-full justify-center">
          <span className="text-sm text-gray-700 dark:text-secondary">
            Fetching Usage Data...
          </span>
        </div>
      </FlatCard>
    );
  }

  if (currentUsages.length === 0) {
    return (
      <FlatCard className="w-full justify-center">
        <div className="flex w-full justify-center">
          <span className="text-sm text-gray-700 dark:text-secondary">
            No Instances With Usage Found
          </span>
        </div>
      </FlatCard>
    );
  }

  return (
    <>
      <div className="flex flex-row w-full mt-3 mb-5">
        <TopInstances currentUsages={transformedUsages} />
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <TableRoot>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Instance Id</TableHeaderCell>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Owner</TableHeaderCell>
              <TableHeaderCell>Compute Cost</TableHeaderCell>
              <TableHeaderCell>Storage Cost</TableHeaderCell>
              <TableHeaderCell>Total Cost</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentUsages.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary ">
                    {item.EnvID}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {item.EnvName}
                  </span>
                </TableCell>
                <TableCell>
                  <UsernameDisplayField
                    userId={item.CreatedByUserID}
                    className="text-secondary"
                  />
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {item.ComputeCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {item.StorageCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {item.TotalCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableRoot>
      <div className="flex justify-end mt-3 mr-5 items-center">
        <div onClick={handlePreviousPage} className="mr-3 mt-[2px]">
          <FlatCard
            noBorder={true}
            className={classNames(
              currentPage === 1 ? "hidden" : "cursor-pointer",
              "border border-gray-500 dark:border-zinc-800",
              "w-full flex items-center",
              "px-2 py-2",
              "text-xs",
              "h-7"
            )}
          >
            <span>Back</span>
          </FlatCard>
        </div>
        <span className="">{` Page ${currentPage} of ${totalPages} `}</span>
        <div onClick={handleNextPage} className="ml-3 mt-[2px]">
          <FlatCard
            noBorder={true}
            className={classNames(
              currentPage === totalPages ? "hidden" : "cursor-pointer",
              "border border-gray-500 dark:border-zinc-800",
              " w-full flex items-center",
              "px-2 py-2",
              "text-xs",
              "h-7"
            )}
          >
            <span>Next</span>
          </FlatCard>
        </div>
      </div>
    </>
  );
};

export default UsageSummary;

interface TopInstancesProps {
  currentUsages: UsageTableRow[];
}

const TopInstances: React.FC<TopInstancesProps> = ({ currentUsages }) => {
  const getTopInstances = (
    costType: "TotalCost" | "ComputeCost" | "StorageCost"
  ) => {
    return currentUsages
      .sort((a, b) => b[costType] - a[costType])
      .slice(0, 3)
      .map((env) => ({
        name: `${env.EnvName} (${env.EnvID})`,
        value: env[costType],
      }));
  };

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-full mr-3">
        <div className="flex flex-row text-gray-900 dark:text-white items-center mb-3">
          <h1 className="text-md font-semibold mr-1">Highest Total Cost</h1>
        </div>
        <BarList
          className="w-full"
          showAnimation={true}
          data={getTopInstances("TotalCost")}
          valueFormatter={(value) =>
            `${value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`
          }
        />
      </div>

      <div className="flex flex-col w-full mr-3">
        <div className="flex flex-row text-gray-900 dark:text-white items-center mb-3">
          <h1 className="text-md font-semibold mr-1">Highest Compute Cost</h1>
        </div>
        <BarList
          className="w-full"
          showAnimation={true}
          data={getTopInstances("ComputeCost")}
          valueFormatter={(value) =>
            `${value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`
          }
        />
      </div>

      <div className="flex flex-col w-full">
        <div className="flex flex-row text-gray-900 dark:text-white items-center mb-3">
          <h1 className="text-md font-semibold mr-1">Highest Storage Cost</h1>
        </div>
        <BarList
          className="w-full"
          showAnimation={true}
          data={getTopInstances("StorageCost")}
          valueFormatter={(value) =>
            `${value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`
          }
        />
      </div>
    </div>
  );
};
