import { datadogRum } from "@datadog/browser-rum";

const appIdMap = {
  prod: "761b42e3-b656-4d0b-b04c-41e3276139b5",
  stage: "761b42e3-b656-4d0b-b04c-41e3276139b5",
  local: "761b42e3-b656-4d0b-b04c-41e3276139b5",
};

export default function initDataDogRUM(config) {
  const appId = appIdMap[config.stage];
  if (!appId) {
    console.info("skipping dd rum for", config.stage);
    return;
  }
  datadogRum.init({
    applicationId: appId,
    clientToken: "pub45efa64c5ae9e6ade90cee0a3556f3a4",
    site: "datadoghq.com",
    service: "brev-web-console",
    version: config.version || "0.0.0",
    env: config.stage,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      { match: "http://localhost:8080", propagatorTypes: ["tracecontext"]},
      { match: /https:\/\/brevapi.*.control-plane\.brev\.dev/, propagatorTypes: ["tracecontext"]}
    ]
  });
}
