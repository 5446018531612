import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import agent, { WorkspaceGroup } from "server";
import { OrgContext } from "contexts/OrgContext";
import { Menu, Listbox, Transition } from "@headlessui/react";
import {
  ArrowUpIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/solid";
import { NotificationContext } from "contexts/NotificationContext";
import { UserContext } from "contexts/UserContext";
import DevToggle, { FeatureToggle } from "components/DevToggle";
import PaperClipIcon from "assets/img/svg/paperclipicon.svg?react";
import LockIcon from "assets/img/svg/lockicon.svg?react";
import InputField from "components/UI-lib/InputField";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import Spinner from "components/UI-lib/Spinner";
import lambda from "assets/img/png/lambda.svg";
import lambdaWhite from "assets/img/png/lambdawhite.svg";
import gcp from "assets/img/png/gcp.svg";
import digitalOcean from "assets/img/png/digitalOcean.png";
import aws from "assets/img/png/aws.svg";
import awsWhite from "assets/img/png/awswhite.svg";
import ConnectGCP from "./ConnectGCP";
import ConnectAWS from "./ConnectAWS";
import { DarkModeContext } from "contexts/DarkModeContext";

const CloudMultiplex: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showGCPSteps, setShowGCPSteps] = useState(false);
  const [showAWSSteps, setShowAWSSteps] = useState(false);
  const [showLambdaSteps, setShowLambdaSteps] = useState(false);
  const [showDigitalOceanSteps, setShowDigitalOceanSteps] = useState(false);
  const [lambdaCloudAPIKey, setLambdaCloudAPIKey] = useState("");
  const [digitalOceanAPIKey, setDigitalOceanAPIKey] = useState("");
  const orgContext = useContext(OrgContext);
  const notificaitonContext = useContext(NotificationContext);
  const { me } = useContext(UserContext);
  const { darkMode } = useContext(DarkModeContext);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "info" | "error" | "success" | "warning"
  >("info");
  const [inlineAlertAction, setInlineAlertAction] = useState(<></>);

  const toggleConnectionSteps = (
    type: "lambda" | "gcp" | "aws" | "digitalOcean"
  ) => {
    setInlineAlertMessage("");
    closeAllSteps();
    if (type === "gcp") {
      setShowGCPSteps(!showGCPSteps);
    } else if (type === "lambda") {
      setShowLambdaSteps(!showLambdaSteps);
    } else if (type === "aws") {
      setShowAWSSteps(!showAWSSteps);
    } else if (type === "digitalOcean") {
      setShowDigitalOceanSteps(!showDigitalOceanSteps);
    }
  };

  const connectLambda = async (apiKey: string) => {
    const args = {
      name: me?.name || "",
      organization_id: orgContext.activeOrgId,
      credential: {
        lambda_labs: {
          api_key: apiKey,
        },
      },
    };
    return agent.Organizations.createDevplaneWorkspaceGroup(args);
  };

  const closeAllSteps = () => {
    setShowAWSSteps(false);
    setShowGCPSteps(false);
    setShowLambdaSteps(false);
    setShowDigitalOceanSteps(false);
  };

  return (
    <>
      <Button
        label="Connect AWS"
        type="secondary"
        iconLeft={
          <img
            src={darkMode ? awsWhite : aws}
            alt="AWS"
            className="h-5 w-7 mr-3"
          />
        }
        onClick={() => {
          toggleConnectionSteps("aws");
        }}
      />
      <Button
        label="Connect GCP"
        type="secondary"
        iconLeft={<img src={gcp} alt="GCP" className="h-5 w-7 mr-3" />}
        onClick={() => {
          toggleConnectionSteps("gcp");
        }}
      />
      <FeatureToggle feature="hiddenClouds">
        <Button
          label="Connect Lambda"
          type="secondary"
          iconLeft={
            <img
              src={darkMode ? lambdaWhite : lambda}
              alt="AWS"
              className="h-5 w-7 mr-3"
            />
          }
          onClick={() => {
            toggleConnectionSteps("lambda");
          }}
        />
        <Button
          label="Connect Digital Ocean"
          type="secondary"
          iconLeft={
            <img
              src={digitalOcean}
              alt="digitalOcean"
              className="h-5 w-5 mr-3"
            />
          }
          onClick={() => {
            toggleConnectionSteps("digitalOcean");
          }}
        />
      </FeatureToggle>

      <ConnectAWS show={showAWSSteps} />

      <ConnectGCP show={showGCPSteps} />

      <FeatureToggle feature="hiddenClouds">
        <Transition
          className="w-full"
          show={showLambdaSteps}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <FlatCard noBorder className="mt-3">
            <div className="min-w-fit-content items-center">
              <h3 className="font-mediu dark:text-white">
                Connect your Lambda Cloud Account
              </h3>
              <p className="my-2 text-sm text-gray-700 dark:text-secondary">
                To deploy instances in your Lambda account, please upload your
                API key
              </p>
              <div>
                <InlineNotification
                  show={!!inlineAlertMessage}
                  severity={inlineAlertSeverity}
                  className="w-full"
                  text={inlineAlertMessage}
                  text2={
                    <div className="mt-2 ml-[36px]">{inlineAlertAction}</div>
                  }
                  autoClose={false}
                  onClose={() => {
                    setInlineAlertMessage("");
                    setInlineAlertAction(<></>);
                    setTimeout(() => {
                      // avoid flashing red when the alert is closed
                      setInlineAlertSeverity("error");
                    }, 100);
                  }}
                />
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-secondary"
                >
                  Lambda Cloud API Key
                </label>

                <div className="flex flex-row justify-center items-start w-full">
                  {/* TODO: expand the UILib InputField to support leading icons */}
                  <div className="relative mt-2 rounded-md shadow-sm w-[85%]">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="lambdaAPIKey"
                      name="lambdaAPIKey"
                      id="lambdaAPIKey"
                      className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 dark:text-secondary dark:bg-zinc-900 ring-1 ring-inset ring-gray-300 dark:ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                      placeholder="your API key"
                      value={lambdaCloudAPIKey}
                      onChange={(e) => {
                        setLambdaCloudAPIKey(e.target.value);
                      }}
                    />
                  </div>

                  {/* TODO: make this button secondary & disabled when an api key isnt there... */}
                  <Button
                    label="Connect"
                    disabled={lambdaCloudAPIKey === ""}
                    className="mt-2 ml-2"
                    onClick={async () => {
                      const resp = await connectLambda(lambdaCloudAPIKey);
                      if (resp.success) {
                        setInlineAlertMessage(
                          "Your Lambda Key has been uploaded."
                        );
                        setInlineAlertSeverity("success");
                        setInlineAlertAction(<></>);
                      } else {
                        setInlineAlertMessage(resp.message);
                        setInlineAlertSeverity("error");
                        setInlineAlertAction(<></>);
                      }
                    }}
                    type={lambdaCloudAPIKey === "" ? "secondary" : "primary"}
                  />
                </div>
              </div>
            </div>
          </FlatCard>
        </Transition>
        <Transition
          className="w-full"
          show={showDigitalOceanSteps}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <FlatCard noBorder className="mt-3">
            <div className="min-w-fit-content items-center">
              <h3 className="font-medium dark:text-white">
                Connect your Digital Ocean Account
              </h3>
              <p className="my-2 text-sm text-gray-700 dark:text-secondary">
                To deploy instances in your Digital Ocean account, please upload
                your API key
              </p>
              <div>
                <InlineNotification
                  show={!!inlineAlertMessage}
                  severity={inlineAlertSeverity}
                  className="w-full"
                  text={inlineAlertMessage}
                  text2={
                    <div className="mt-2 ml-[36px]">{inlineAlertAction}</div>
                  }
                  autoClose={false}
                  onClose={() => {
                    setInlineAlertMessage("");
                    setInlineAlertAction(<></>);
                    setTimeout(() => {
                      // avoid flashing red when the alert is closed
                      setInlineAlertSeverity("error");
                    }, 100);
                  }}
                />
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-secondary"
                >
                  Digital Ocean API Key
                </label>

                <div className="flex flex-row justify-center items-start w-full">
                  {/* TODO: expand the UILib InputField to support leading icons */}
                  <div className="relative mt-2 rounded-md shadow-sm w-[85%]">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="digitalOceanAPIKey"
                      name="digitalOceanAPIKey"
                      id="digitalOceanAPIKey"
                      className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 dark:text-secondary dark:bg-zinc-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                      placeholder="your API key"
                      value={digitalOceanAPIKey}
                      onChange={(e) => {
                        setDigitalOceanAPIKey(e.target.value);
                      }}
                    />
                  </div>

                  {/* TODO: make this button secondary & disabled when an api key isnt there... */}
                  <Button
                    label="Connect"
                    disabled={lambdaCloudAPIKey === ""}
                    className="mt-2 ml-2"
                    onClick={() => {
                      console.log(`lambdaCloudAPIKey: ${lambdaCloudAPIKey}`);
                      // TODO: this feature is not yet implemented
                      setLambdaCloudAPIKey("");
                    }}
                    type={lambdaCloudAPIKey === "" ? "secondary" : "primary"}
                  />
                </div>
              </div>
            </div>
          </FlatCard>
        </Transition>
      </FeatureToggle>
    </>
  );
};
export default CloudMultiplex;
