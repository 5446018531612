import React, { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { OrgContext } from "contexts/OrgContext";
import Spinner from "components/UI-lib/Spinner";
import {
  // BarsArrowUpIcon,
  ArrowUpIcon,
  ChevronDoubleDownIcon,
  ChevronRightIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import RectangleStackIcon from "assets/img/svg/rectanglestack.svg?react";
import { UserContext } from "contexts/UserContext";
import agent, {
  AdminOrgWithPM,
  EnvEvent,
  EnvSaving,
  Timeframe,
  WorkspacesRes,
} from "server";
import IWorkspace, { WorkspaceStatus } from "models/Workspace.model";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { NotificationContext } from "contexts/NotificationContext";
import IUser from "models/User.model";
import UsernameDisplayField from "components/UsernameDisplayField";
import {
  CPUTypes,
  classNames,
  getReadableWorkspaceGroup,
  isCPU_techdebt,
} from "components/utils";
import FlatCard from "components/UI-lib/FlatCard";
import CPUIcon from "assets/img/svg/cpuicon.svg?react";
import Button from "components/UI-lib/Button";
import { getAWSUrl, getMixpanelUrl, getSentryUrl } from ".";

const GetEnvStatusLight = (workspaceStatus: WorkspaceStatus) => {
  if (workspaceStatus === WorkspaceStatus.Running) {
    return (
      <span
        className={classNames(
          "bg-green-100 dark:bg-green-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-green-400 dark:bg-green-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Deploying) {
    return (
      <span
        className={classNames(
          "bg-cyan-100 dark:bg-cyan-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-cyan-400 dark:bg-highlight",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Stopped) {
    return (
      <span
        className={classNames(
          "bg-slate-100 dark:bg-slate-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-slate-400 dark:bg-slate-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Starting) {
    return (
      <span
        className={classNames(
          "bg-cyan-100 dark:bg-cyan-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-cyan-400 dark:bg-highlight",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Failure) {
    return (
      <span
        className={classNames(
          "bg-red-100 dark:bg-red-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-red-400 dark:bg-red-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Stopping) {
    return (
      <span
        className={classNames(
          "bg-yellow-100 dark:bg-yellow-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-yellow-400 dark:bg-yellow-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  }
  return (
    <span
      className={classNames(
        "bg-slate-100 dark:bg-slate-800",
        "h-4 w-4 rounded-full flex items-center justify-center"
      )}
      aria-hidden="true"
    >
      <span
        className={classNames(
          "bg-slate-400 dark:bg-slate-500",
          "h-2 w-2 rounded-full"
        )}
      />
    </span>
  );
};

interface DisplayEnvironmentsProps {
  workspaces?: IWorkspace[];
  isInstancesLoading: boolean;
}

const DisplayEnvironments: React.FC<DisplayEnvironmentsProps> = ({
  workspaces = [],
  isInstancesLoading,
}) => {
  const rankStatus = (status: WorkspaceStatus) => {
    switch (status) {
      case WorkspaceStatus.Running:
        return 1;
      case WorkspaceStatus.Deploying:
        return 2;
      case WorkspaceStatus.Failure:
        return 3;
      case WorkspaceStatus.Stopped:
        return 4;
      default:
        return 5;
    }
  };

  // Rank the workspaces by their status
  const rankedWorkspaces = [...workspaces].sort((a, b) => {
    return rankStatus(a.status) - rankStatus(b.status);
  });

  if (isInstancesLoading) {
    return (
      <FlatCard isLoading={true}>
        <p className="text-sm text-gray-700 dark:text-secondary font-mono">
          Fetching all instances...
        </p>
      </FlatCard>
    );
  }

  return (
    <>
      <ul
        role="list"
        className="divide-y divide-gray-200 dark:divide-zinc-800 border-b border-gray-200 dark:border-zinc-800"
      >
        {!isInstancesLoading &&
          workspaces &&
          workspaces.length > 0 &&
          rankedWorkspaces.map((env) => (
            <li
              key={env.id}
              className={classNames(
                "relative py-5 pl-4 pr-6 hover:bg-gray-50 dark:hover:bg-zinc-700 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6",
                "bg-white dark:bg-zinc-900"
              )}
              onClick={() => {
                console.log(env);
              }}
            >
              <div className="flex items-center justify-between space-x-4">
                {/* Repo name and link */}
                <div className="min-w-0 space-y-3">
                  <div className="flex items-center space-x-3">
                    {GetEnvStatusLight(env.status)}

                    <h2 className="text-sm font-medium flex flex-row dark:text-white">
                      <span className="">{env.name} </span>
                      <p className="flex space-x-2 text-sm text-gray-500 dark:text-secondary ml-2">
                        <span className="metadata-envid">{env.id}</span>
                      </p>
                      <span className="sr-only">{env.status}</span>
                    </h2>
                  </div>
                  <p className="flex space-x-2 text-sm text-gray-500 dark:text-secondary">
                    <span>
                      <UsernameDisplayField userId={env.createdByUserId} />
                    </span>
                    <span aria-hidden="true">&middot;</span>
                    <span className="metadata-userid">
                      {env.createdByUserId}
                    </span>
                    <span aria-hidden="true">&middot;</span>
                    <span className="metadata-orgid">{env.organizationId}</span>
                  </p>
                </div>
                <div className="sm:hidden">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                {/* Repo meta info */}
                <div className=" flex-shrink-0 flex-col items-end space-y-3 sm:flex">
                  <p className="flex items-center space-x-4 text-sm font-medium text-gray-500 dark:text-secondary">
                    <a
                      href={getMixpanelUrl(env.createdByUserId)}
                      className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Mixpanel
                    </a>
                    <span aria-hidden="true">&middot;</span>
                    <a
                      href={getAWSUrl(
                        env.createdByUserId,
                        env.region || "us-west-2"
                      )}
                      target="_blank"
                      rel="noreferrer"
                      className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                    >
                      AWS
                    </a>
                    <span aria-hidden="true">&middot;</span>
                    <a
                      href={getSentryUrl(env.createdByUserId)}
                      className="relative text-sm font-medium text-gray-500 hover:text-gray-900 dark:hover:text-highlightLighter underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sentry
                    </a>
                  </p>
                  <p className="flex space-x-2 text-sm text-gray-500 dark:text-secondary">
                    <span
                      className={
                        isCPU_techdebt(env.instanceType || "")
                          ? "text-gray-500 dark:text-cyan-100"
                          : "text-cyan-600 dark:text-cyan-300"
                      }
                    >
                      {env.instanceType && (
                        <>
                          {isCPU_techdebt(env.instanceType)
                            ? `${CPUTypes[env.instanceType]?.cpu} cpu x ${
                                CPUTypes[env.instanceType]?.ram
                              } gb ram`
                            : `${env.instanceType} (gpu)`}
                        </>
                      )}
                    </span>
                    <span aria-hidden="true">&middot;</span>
                    <span>
                      {getReadableWorkspaceGroup(env.workspaceGroupId)}
                    </span>
                    <span aria-hidden="true">&middot;</span>
                    <span>{env.region}</span>
                    <span aria-hidden="true">&middot;</span>
                    <span>{` ${new Date(
                      env.createdAt
                    ).toLocaleString()}`}</span>
                  </p>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

export default DisplayEnvironments;

// Usage example:
// <DisplayEnvironments workspaces={someWorkspaces} isInstancesLoading={isInstancesLoading} />
