import Modal from "components/Modals/Modal";
import React, { useMemo } from "react";
import {
  EnvelopeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

export interface FailedStartModalProps {
  logFileContent: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const FailedStartModal: React.FC<FailedStartModalProps> = ({
  logFileContent,
  show,
  setShow,
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const allLines = useMemo(() => {
    const lines = logFileContent.split("\n");
    // Slice the last 1000 entries. Note: No reverse since you want them in original order
    return lines.slice(Math.max(lines.length - 1000, 0));
  }, [logFileContent]);

  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => {
        setShow(false);
      }}
      title={"Previous Start Attempt Failed"}
      body={
        <>
          <div className="w-[450px] ">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center mt-2 text-rose-500">
                  <ExclamationTriangleIcon className="h-5 w-5" />
                  <p className="text-sm ml-2">View Error Logs</p>
                </div>
                <div className="flex flex-row items-center mt-2 text-white">
                  <p className="text-sm ml-2">
                    Contact: brev-support@nvidia.com
                  </p>
                </div>
              </div>
              <div
                className="mt-4 bg-cyan-100 text-cyan-800 border-2 border-cyan-600 dark:bg-cyan-900 dark:text-cyan-200 dark:border-cyan-400 font-sans
              p-2 rounded-lg w-full relative pt-1
              "
              >
                <div
                  className={"bg-transparent h-64 p-1 rounded overflow-y-auto"}
                >
                  {allLines.map((log, idx) => (
                    <div key={idx} className="flex items-center">
                      <div
                        className={
                          "text-sm whitespace-pre flex-1 ml-4 break-words"
                        }
                      >
                        {log}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      }
      confirmLabel={"Close"}
      onSuccess={() => onSuccess()}
    />
  );
};
