import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { OrgContext } from "contexts/OrgContext";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  classNames,
  memoryToGiB,
  roundPriceToTwoDigits,
} from "components/utils";
import DevToggle from "components/DevToggle";
import {
  allPublicCloudsName,
  handleIsPaymentSetup,
} from "components/Environment/Create/utils";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import InputField from "components/UI-lib/InputField";
import { StyledRangeSlider } from "components/UI-lib/Slider";
import {
  ComputePricingObject,
  getInstanceTypeCostProductApi,
} from "utils/CreateWorkspaceUtils";
import Button from "components/UI-lib/Button";
import DynamicBadge from "components/UI-lib/DynamicBadge";
import { Listbox } from "@headlessui/react";

interface AdvancedFiltersProps {
  options: {
    value: GPUInstanceType;
    price: any;
  }[];
  setFilteredOptions: (
    options: {
      value: GPUInstanceType;
      price: any;
    }[]
  ) => void;
  //   ANTIPATTERN BUT ITS OK:
  // SELECTED CLOUD IS THE ONLY ONE THAT GOES INSIDE BECAUSE IT MIGHT GO OUTSIDE FIRST
  selectedCloudName: string;
  setSelectedCloudName: (cloud: string) => void;
  isGPU: boolean;
}

const CPU_FILTERS_READY = false;

export const AdvancedFilters: React.FC<AdvancedFiltersProps> = (props) => {
  const orgContext = useContext(OrgContext);
  const [showAdvancedSetting, setShowAdvancedSetting] = useState(false);
  const [standardMemory, setStandardMemory] = useState("");
  const [gpuMemory, setGPUMemory] = useState("4GiB");
  const [gpus, setGPUs] = useState("");
  const [cpus, setCPUs] = useState("2");
  const [memory, setMemory] = useState("13GiB");
  // Convert the memory and gpuMemory lists into stateful variables
  const [gpuMemoryList, setGpuMemoryList] = useState<string[]>([]);
  const [memoryList, setMemoryList] = useState<string[]>([]);
  const previousMaxPriceRef = useRef<number | null>(null);
  const previousFilteredOptionsRef = useRef<
    { value: GPUInstanceType; price: any }[]
  >([]);

  // Compute the max price from the provided options, excluding invalid prices
  const maxPrice = Math.max(
    ...props.options
      .map((option) => parseFloat(option.price))
      .filter((price) => !isNaN(price) && isFinite(price))
  );
  const [priceRange, setPriceRange] = React.useState<number[]>([0, maxPrice]);
  const [maxRange, setMaxRange] = React.useState<number>(maxPrice);

  useEffect(() => {
    if (
      JSON.stringify(previousFilteredOptionsRef.current) !==
      JSON.stringify(filteredOptions)
    ) {
      props.setFilteredOptions(filteredOptions);
      previousFilteredOptionsRef.current = filteredOptions;
    }
  }, [
    standardMemory,
    gpuMemory,
    gpus,
    cpus,
    memory,
    priceRange,
    props.options,
  ]);

  const filteredOptions = useMemo(() => {
    return props.options.filter((option) => {
      if (props.isGPU) {
        if (
          gpuMemory &&
          memoryToGiB(option.value.supported_gpus[0].memory.toString()) <
            memoryToGiB(gpuMemory)
        )
          return false;
        if (gpus && option.value.supported_gpus[0].count < parseInt(gpus))
          return false;
      }
      if (
        standardMemory &&
        memoryToGiB(option.value.memory.toString()) <
          memoryToGiB(standardMemory)
      )
        return false;
      if (cpus && option.value.vcpu < parseInt(cpus)) return false;
      if (
        memory &&
        memoryToGiB(option.value.memory.toString()) < memoryToGiB(memory)
      )
        return false;
      const price = parseFloat(option.price);
      if (price < priceRange[0] || price > priceRange[1]) return false;
      return true;
    });
  }, [
    props.options,
    standardMemory,
    gpuMemory,
    gpus,
    cpus,
    memory,
    priceRange,
    props.isGPU,
  ]);

  // Recompute these lists whenever props.options changes
  useEffect(() => {
    let gpuMemorySet = new Set();
    if (props.isGPU) {
      gpuMemorySet = new Set(
        props.options.map((option) => option.value.supported_gpus[0].memory)
      );
    }

    const memorySet = new Set(
      props.options.map((option) => option.value.memory.toString())
    );

    // Update gpu mem and mem filters
    setGpuMemoryList(
      Array.from(gpuMemorySet as Set<string>).sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      )
    );

    setMemoryList(
      Array.from(memorySet).sort((a, b) => memoryToGiB(a) - memoryToGiB(b))
    );

    // Update price filters
    const newMaxPrice = Math.max(
      ...props.options
        .map((option) => parseFloat(option.price))
        .filter((price) => !isNaN(price) && isFinite(price))
    );

    setMaxRange(isFinite(newMaxPrice) ? newMaxPrice : 100);
    // Only update the priceRange if the maxPrice has changed
    if (previousMaxPriceRef.current !== newMaxPrice) {
      setPriceRange([0, isFinite(newMaxPrice) ? newMaxPrice : 100]);
      previousMaxPriceRef.current = newMaxPrice;
    }
  }, [props.options]);

  const resetFilters = () => {
    setStandardMemory("");
    setGPUMemory("16GiB");
    setGPUs("");
    setCPUs("2");
    setMemory("13GiB");
    setPriceRange([0, maxRange]);
  };

  return (
    <>
      {/* Add the advanced filters UI */}
      <div className="advanced-filters mt-3">
        <div
          className="flex flex-row cursor-pointer text-highlight "
          onClick={() => setShowAdvancedSetting(!showAdvancedSetting)}
        >
          <span className="text-sm text-highlight font-semibold hover:text-highlightDarker mr-1">
            Advanced Filters
          </span>
          {showAdvancedSetting ? (
            <ChevronDownIcon width={18} />
          ) : (
            <ChevronRightIcon width={18} />
          )}
        </div>
        {/* Advanced Filters UI */}
        {showAdvancedSetting && (
          <>
            <div className="flex flex-wrap mt-3">
              {props.isGPU && (
                <>
                  {/* Min GPU Memory */}
                  <div className="w-1/4 mr-5">
                    <Dropdown
                      label="Min. GPU Memory"
                      value={gpuMemory}
                      onChange={(r) => setGPUMemory(r)}
                    >
                      {gpuMemoryList.map((name, index) => (
                        <DropdownItem
                          displayValue={name}
                          value={name}
                          key={index}
                        />
                      ))}
                    </Dropdown>
                  </div>
                  {/* Min GPUs */}
                  <InputField
                    className="w-1/4 mr-5"
                    label="Min. GPUs"
                    placeholder="0"
                    value={gpus}
                    onChange={(e) => setGPUs(e)}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9\b]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    errorMessage={""}
                  />
                </>
              )}
              {(props.isGPU || CPU_FILTERS_READY) && (
                <>
                  {/* Min CPUs */}
                  <InputField
                    className="w-1/4 mr-5"
                    label="Min. CPUs"
                    placeholder="0"
                    value={cpus}
                    onChange={(e) => setCPUs(e)}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9\b]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    errorMessage={""}
                  />
                  {/* Min Memory */}
                  <div className="w-1/4 mr-5">
                    <Dropdown
                      label="Min. Memory"
                      value={memory}
                      onChange={(r) => setMemory(r)}
                    >
                      {memoryList.map((name, index) => (
                        <DropdownItem
                          displayValue={name.toString()}
                          value={name.toString()}
                          key={index}
                        />
                      ))}
                    </Dropdown>
                  </div>
                </>
              )}
              <Dropdown
                label="Cloud"
                value={props.selectedCloudName}
                onChange={(cloud) => props.setSelectedCloudName(cloud)}
              >
                {[
                  ...orgContext.workspaceGroups
                    .filter((wsg) => wsg.tenantType === "isolated")
                    .map((wsg) => wsg.name),
                  allPublicCloudsName,
                ].map((ws) => (
                  <DropdownItem displayValue={ws} value={ws} />
                ))}
              </Dropdown>
              <DevToggle>
                <div>
                  <div className="block text-sm font-medium text-gray-700 dark:text-secondary mb-1">
                    Cloud [only visible in dev mode]
                  </div>
                  {[
                    ...orgContext.workspaceGroups
                      .filter((wsg) => wsg.tenantType === "isolated")
                      .map((wsg) => wsg.name),
                    allPublicCloudsName,
                  ].map((cloud) => (
                    <DynamicBadge
                      text={cloud}
                      isActive={props.selectedCloudName === cloud}
                      setIsActive={() => props.setSelectedCloudName(cloud)}
                      // className={props.className}
                    />
                  ))}
                </div>
              </DevToggle>
              {(props.isGPU || CPU_FILTERS_READY) && (
                <div className="w-1/4 ml-8 mr-8">
                  <span className="text-sm font-medium text-gray-700 dark:text-secondary">
                    Price Range: {`$${priceRange[0]} - ` + `$${priceRange[1]}`}
                  </span>
                  <StyledRangeSlider
                    onChange={(newRange) => setPriceRange(newRange as number[])}
                    value={priceRange}
                    max={maxRange}
                  />
                </div>
              )}
            </div>
            <div className="mt-2">
              <Button
                label="Reset Filters"
                onClick={resetFilters}
                type="secondary"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
