import IPermission from "models/Permission.model";
import IUser, {
  GlobalUserType,
  UserOnboardingStatus,
  VerificationStatus,
  IdeConfig,
  APIKeys,
} from "models/User.model";

export default class User implements IUser {
  public id: string;

  public username: string;

  public name: string;

  public email: string;

  public externalAuthId: string;

  public roleAttachments: IPermission[];

  public workspacePassword: string;

  public publicKey?: string;

  public baseWorkspaceRepo?: string;

  public userSetupExecPath?: string;

  public verificationStatus: VerificationStatus; // this is ability to create workspaces

  // public billingStatus: boolean; // this is whether we have their credit card information
  public globalUserType: GlobalUserType;

  public onboardingData: UserOnboardingStatus;

  public apiKeys?: APIKeys;

  public ideConfig?: IdeConfig;

  public profilePhotoUrl?: string;

  public isBlocked?: boolean;

  public constructor(u: IUser) {
    this.id = u.id;
    this.username = u.username;
    this.name = u.name;
    this.email = u.email;
    this.externalAuthId = u.externalAuthId;
    this.roleAttachments = u.roleAttachments;
    this.workspacePassword = u.workspacePassword;
    this.verificationStatus =
      u.verificationStatus || VerificationStatus.Unverified;
    // this.billingStatus = u.billingStatus
    this.globalUserType = u.globalUserType || GlobalUserType.Standard;
    if (u.publicKey) this.publicKey = u.publicKey;
    if (u.baseWorkspaceRepo) this.baseWorkspaceRepo = u.baseWorkspaceRepo;
    if (u.userSetupExecPath) this.userSetupExecPath = u.userSetupExecPath;
    this.onboardingData = {
      SSH: u.onboardingData?.SSH || false,
      usedCLI: u.onboardingData?.usedCLI || false,
      attemptedToConnectCloud:
        u.onboardingData?.attemptedToConnectCloud || false,
      whyBrev: u.onboardingData?.whyBrev || "",
      templateChosen: u.onboardingData?.templateChosen || "",
      caltechOffer: u.onboardingData?.caltechOffer || "",
      inviteAccepted: u.onboardingData?.inviteAccepted || "",
      instacart: u.onboardingData?.instacart || "",
      closedOnboardings: u.onboardingData?.closedOnboardings || false,
      clickedOnboarding: u.onboardingData?.clickedOnboarding || [],
      isTermsAccepted: u.onboardingData?.isTermsAccepted || false,
      showedNvidiaWelcome: u.onboardingData?.showedNvidiaWelcome || false,
    };

    this.apiKeys = u.apiKeys || ({} as APIKeys);

    this.ideConfig = u.ideConfig || ({} as IdeConfig);

    this.isBlocked = u.isBlocked || false;
  }

  get isUnverified() {
    return this.verificationStatus === VerificationStatus.Unverified;
  }

  setVerificationStatus = (status: VerificationStatus) => {
    this.verificationStatus = status;
  };
}
