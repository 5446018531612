import { useNotebookDetailsFromWorkspace } from "components/Environment/utils";
import HtmlRenderer from "components/Notebooks/HtmlRenderer";
import Workspace from "entities/Workspace.entity";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
interface NotebookSettingsProps {
  workspace: Workspace;
  notebookList: any[];
}
const NotebookSettings: React.FC<NotebookSettingsProps> = (props) => {
  const history = useHistory();
  const noteBookDetails = useNotebookDetailsFromWorkspace(
    props.workspace,
    props.notebookList
  );

  const [loading, setLoading] = useState(false);

  const [callBackUrl, setCallBackUrl] = useState("/instance/new");
  const [noteBookNotFound, setNoteBookNotFound] = useState(false);
  const handleNavigation = (url: string) => {
    history.push(url);
  };

  return (
    <>
      <div className="flex flex-row items-end ">
        <div className="mr-3">
          {/* <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Notebook Preview
          </h1> */}
        </div>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div>
        <HtmlRenderer
          file={!noteBookDetails.found ? noteBookDetails.path : null}
          notebook={noteBookDetails.found ? noteBookDetails.path : null}
          setCallbackUrl={setCallBackUrl}
          setNoteBookNotFound={setNoteBookNotFound}
          onNavigate={handleNavigation}
          setLoading={setLoading}
        />
        {loading && (
          <span className="text-sm font-mono text-gray-900 dark:text-white">
            Loading...
          </span>
        )}
      </div>
    </>
  );
};

export default NotebookSettings;
