import {
  LOCALSTORAGE_ACTIVE_ORG_ID_KEY,
  LOCALSTORAGE_USER_CACHE_PREFIX,
  TEMPLATE_ID,
} from "constants/index";
import { UserContext } from "contexts/UserContext";
import Organization from "../entities/Organization.entity";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";
import agent, {
  OrgRes,
  OrgsRes,
  WorkspaceGroup,
  UsageResp,
  RegionWithWorkspaceGroup,
  UsageOverview,
} from "server";
import { orgContextDefaultValues } from "tests/defaultValues";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { CPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/CPUSlider";
import {
  parseComputePricing,
  joinOldAndNewPricings,
} from "utils/CreateWorkspaceUtils";
import config from "../config";
import { CreateWorkspaceArgs } from "../models/LocalStorage.model";
import _, { set } from "lodash";
import { useTheme } from "./ThemeContext";
import { useKas } from "./KasContext";
import { isKasAuthFlow } from "server/kas/utils";
import IOrganization from "models/Organization.model";

export interface StoragePricingObject {
  currency: string;
  pricePerUnit: number;
  unit: string;
}
export interface Price {
  currency: string;
  pricePerUnit: number;
  unit: string;
}

export type Capability =
  | "create-instance"
  | "terminate-instance"
  | "reboot-instance"
  | "resize-instance-volume"
  | "change-instance-type"
  | "change-spot-instance-type"
  | "stop-start-instance"
  | "machine-image"
  | "instance-userdata"
  | "billing-usage"
  | "expose-public-ports"
  | "modify-firewall";

export const createCapability: Capability = "create-instance";
export const terminateCapability: Capability = "terminate-instance";
export const rebootCapability: Capability = "reboot-instance";
export const resizeCapability: Capability = "resize-instance-volume";
export const changeInstanceTypeCapability: Capability = "change-instance-type";
export const changeSpotInstanceTypeCapability: Capability =
  "change-spot-instance-type";
export const stopStartCapability: Capability = "stop-start-instance";
export const machineImageCapability: Capability = "machine-image";
export const instanceUserdataCapability: Capability = "instance-userdata";
export const billingUsageCapability: Capability = "billing-usage";
export const exposePublicPortsCapability: Capability = "expose-public-ports";
export const modifyFirewallCapability: Capability = "modify-firewall";

export interface ComputePricingObject {
  instance_type: string;
  operating_system: string;
  location: string;
  onDemandPrice: Price;
  spotPrice: Price;
}

export interface IOrgContext {
  orgcredits: number;
  getCredits: () => Promise<any>;
  orgs: Organization[];
  activeOrgId: string;
  activeOrg: Organization | null;
  isLoading: boolean;
  isInstancesLoading: boolean;
  getActiveOrg: () => Organization | null;
  setActiveOrg: (id: string) => void;
  setDefaultWorkspaceGroupForActiveOrg: (id: string) => void;
  getOrg: (id: string) => Promise<Organization | null>;
  getInviteLink: (id: string) => Promise<any>;
  inviteByEmail: (id: string, inviteEmails: string[]) => Promise<any>;
  createOrSyncOrg: (name: string) => Promise<OrgRes>;
  deleteOrg: (id: string) => Promise<OrgRes>;
  workspaceGroups: WorkspaceGroup[];
  activeWorkspaceGroupId: string;
  setActiveWorkspaceGroupId: (id: string) => void;
  refreshWorkspaceGroups: () => void;
  allInstancesAvailable: GPUInstanceType[];
  GPUInstancesAvailable: GPUInstanceType[];
  CPUInstancesAvailable: GPUInstanceType[];
  cpuRegions: RegionWithWorkspaceGroup[];
  updateInstanceTypes: (orgId: string, imageID?: string) => any;
  usageLoading: boolean;
  usage: UsageResp[];
  usageOverview: UsageOverview[];
  usageOverviewLoading: boolean;
  getCapabilities: (
    workspaceGroupId: string,
    workspaceCapabilities?: Capability[] | undefined
  ) => Promise<Capability[]>;
  regionNumLoading: number;
  isEnterprise(orgId: string): boolean;
}

export const OrgContext = React.createContext<IOrgContext>(
  orgContextDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const OrgContextProvider: React.FC<Props> = ({ children }) => {
  const themeContext = useTheme();
  const { currentOrgNgcName } = useKas();
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInstancesLoading, setIsInstancesLoading] = useState(false);
  const [activeOrgId, setActiveOrgId] = useState("");
  const [activeOrg, _setActiveOrg] = useState<Organization | null>(null);
  const [workspaceGroups, setWorkspaceGroups] = useState<WorkspaceGroup[]>([]);
  const [cpuRegions, setCPURegions] = useState<RegionWithWorkspaceGroup[]>([]);
  const [activeWorkspaceGroupId, _setActiveWorkspaceGroupId] = useState("");
  const [GPUInstancesAvailable, setGPUInstancesAvailable] = useState<
    GPUInstanceType[]
  >([]);
  const [CPUInstancesAvailable, setCPUInstancesAvailable] = useState<
    GPUInstanceType[]
  >([]);
  const [allInstancesAvailable, setAllInstancesAvailable] = useState<
    GPUInstanceType[]
  >([]);
  const [allRegions, setAllRegions] = useState<string[]>([]);
  const [regionNumLoading, setRegionNumLoading] = useState(0);

  const [allRegionPricing, setAllRegionPricing] = useState<{
    [region: string]: {
      [type: string]: ComputePricingObject;
    };
  }>({});

  const [storagePricing, setStoragePricing] = useState<StoragePricingObject[]>(
    []
  );
  const [computePricing, setComputePricing] = useState<{
    [type: string]: ComputePricingObject;
  }>({});
  const [usage, setUsage] = useState<UsageResp[]>([]);
  const [usageOverview, setUsageOverview] = useState<UsageOverview[]>([]);
  const [usageLoading, setUsageLoading] = useState(false);
  const [usageOverviewLoading, setUsageOverviewLoading] = useState(false);
  const { isAuthenticated, me } = useContext(UserContext);
  const [orgcredits, setCredits] = useState(0);
  const flagEnvironmentsV1FlagEnabled = useFeatureFlagEnabled(
    "use-environments-v1"
  );

  const history = useHistory();

  const getActiveOrg = (): Organization | null => activeOrg;
  const setDefaultWorkspaceGroupForActiveOrg = (
    newDefaultWorkspaceGroupId: string
  ) => {
    if (activeOrg) {
      activeOrg.defaultWorkspaceGroupId = newDefaultWorkspaceGroupId;
      _setActiveOrg(activeOrg);
    }
  };
  // orgs.find((o) => o.id === activeOrgId) || null;
  const setActiveOrg = (id: string): void => setActiveOrgId(id);
  const setActiveWorkspaceGroupId = (id: string): void => {
    _setActiveWorkspaceGroupId(id);
  };
  const getOrg = async (id: string): Promise<Organization | null> => {
    const org = orgs.find((o) => o.id === id);
    if (org) return org;
    const res = await agent.Organizations.get(id);
    if (res.success && res.data) {
      return new Organization(res.data);
    }
    return null;
  };

  const getInviteLink = async (id: string): Promise<any> => {
    const res = await agent.Organizations.createInviteLink(id);
    if (res?.success && res?.data) {
      return res.data;
    }
    return null;
  };

  const inviteByEmail = async (
    id: string,
    inviteEmails: string[]
  ): Promise<any> => {
    const res = await agent.Organizations.inviteByEmail(id, inviteEmails);
    if (res.success) {
      return res;
    } else {
      return res.message;
    }
  };

  const createOrSyncOrg = async (
    name: string,
    newOrgs?: Organization[], // this param will only be passed in during the KAS flow, see comment below as to why
    isInitialCall?: boolean // this function is called once anytime a user loads the app now, if it is the initial call we want to pass the x-ngc-org-name header
  ): Promise<OrgRes> => {
    const res = await agent.Organizations.createOrSyncOrg(
      name,
      currentOrgNgcName ?? "",
      isInitialCall
    );
    if (res.success && res.data) {
      const org = new Organization(res.data);
      // in the KAS flow, createOrSyncOrg is called every time the app is initialized
      // now as part of the loadOrgsAndCreateIfNone call - however, when this call happens
      // the existing orgs in the "orgs" state will not have been set yet (due to the OrgContext
      // not being re-rendered after setup() is called before this function is executed). This logic makes sure
      // all of the user's existing orgs are set as expected without changing the
      // underlying logic of this component too much to prevent breaking existing functionality.
      const existingOrgs =
        orgs?.length === 0
          ? (newOrgs as Organization[]).filter((newOrg) => newOrg.id !== org.id)
          : orgs;
      setOrgs([org, ...existingOrgs]);
      setActiveOrgId(org.id);
    }
    return res;
  };

  const deleteOrg = async (id: string): Promise<OrgRes> => {
    if (orgs.length <= 1)
      return {
        success: false,
        message: "Must have at least one organization.",
      };
    const res = await agent.Organizations.delete(id);
    if (res.success && res.data) {
      const newOrgList = orgs.filter((o) => o.id !== id);
      setOrgs(newOrgList);
      const newActiveOrgId = orgs[0].id;
      setActiveOrgId(newActiveOrgId);
    }
    return res;
  };

  const clear = () => {
    setOrgs([]);
    setActiveOrgId("");
  };

  const updateWorkspaceGroups = async (id: string) => {
    const res = await agent.Organizations.getWorkspaceGroups(id);
    if (res.success && res.data) {
      setWorkspaceGroups(
        res.data.filter((wsg: WorkspaceGroup) => wsg.status !== "DEPRECATED")
      );
    }
  };

  const refreshWorkspaceGroups = async () => {
    const res = await agent.Organizations.getWorkspaceGroups(activeOrgId);
    if (res.success && res.data) {
      setWorkspaceGroups(
        res.data.filter((wsg: WorkspaceGroup) => wsg.status !== "DEPRECATED")
      );
    }
  };

  const parseGPUData = (gpu) => {
    const gpuData = gpu;
    Object.keys(gpuData).forEach((key, index) => {
      if (key === "memory") {
        gpuData[key] = gpuData[key].replaceAll("i", "");
      }
      if (key === "supported_gpus") {
        gpuData[key][0].memory = gpuData[key][0].memory.replaceAll("i", "");
      }
    });
    return gpuData;
  };
  const parseStoragePricing = (pricing: any): StoragePricingObject[] => {
    const pricingItems = pricing.items;
    if (pricingItems) {
      return pricingItems.map((item: any) => ({
        currency: item.storage_product.prices.on_demand.price_per_unit.currency,
        pricePerUnit:
          item.storage_product.prices.on_demand.price_per_unit.amount,
        unit: item.storage_product.prices.on_demand.unit,
      }));
    }
    return [];
  };

  const updateInstanceTypes = async (
    orgId: string,
    imageID?: string
  ): Promise<any> => {
    setIsInstancesLoading(true);
    const res = await agent.Instances.getAllInstanceTypesAvailable(
      orgId,
      imageID
    );

    if (
      res.success &&
      res.data &&
      Object.keys(res.data).length !== 0 &&
      res.data.allInstanceTypes &&
      res.data.allInstanceTypes.length > 0
    ) {
      const instanceTypes = res.data.allInstanceTypes as GPUInstanceType[];
      setAllInstancesAvailable(instanceTypes);
      setGPUInstancesAvailable(
        instanceTypes
          .filter(
            (gpu) => gpu.supported_gpus
            // (gpu) => gpu.supported_gpus && gpu?.prices !== undefined
          )
          .filter((gpu) => gpu.supported_gpus[0].manufacturer !== "Habana")
          .filter((gpu) => gpu.supported_gpus[0].manufacturer !== "AMD")
      );
      setCPUInstancesAvailable(
        instanceTypes.filter((gpu) => !gpu.supported_gpus)
      );
      setIsInstancesLoading(false);
      return res.data;
    }
    setIsInstancesLoading(false);
    return null;
  };

  const getUsage = async () => {
    setUsageLoading(true);
    const res = await agent.Organizations.getUsage(activeOrgId);
    if (res.success && res.data) {
      setUsage(res.data.Usage);
    } else {
      setUsage([]);
    }
    setUsageLoading(false);
  };

  const getUsageOverview = async () => {
    setUsageOverviewLoading(true);
    const res = await agent.Organizations.getUsageOverview(activeOrgId);
    if (res.success && res.data) {
      setUsageOverview(res.data.Usage || []);
    } else {
      setUsage([]);
    }
    setUsageOverviewLoading(false);
  };

  const getCapabilities = async (
    workspaceGroupId: string,
    workspaceCapabilities: Capability[] | undefined
  ): Promise<Capability[]> => {
    const res = await agent.Organizations.getCapabilities(workspaceGroupId);
    if (res.success && res.data) {
      const instanceCapabilities = res.data.capabilities as Capability[];
      if (workspaceCapabilities) {
        // join workspaceCapabilities and instanceCapabilities
        const outputCapabilities = instanceCapabilities.concat(
          workspaceCapabilities
        );
        return outputCapabilities;
      }
      return instanceCapabilities;
    }
    if (workspaceCapabilities) {
      return workspaceCapabilities;
    }
    return [];
  };

  const getCredits = async () => {
    const res = await agent.Organizations.getCredits(activeOrgId);
    if (res.success && res.data) {
      setCredits(Number(res.data.balance_usd));
    }
    return res;
  };

  useEffect(() => {
    const object = {};
    for (const instanceType of allInstancesAvailable) {
      for (const wsg of instanceType.workspace_groups) {
        for (const location of wsg.locations) {
          object[wsg.id + "~" + location.name] = {};
        }
      }
    }
    if (!_.isEqual(_.sortBy(Object.keys(object)), _.sortBy(allRegions))) {
      setAllRegions(Object.keys(object));
    }
  }, [allInstancesAvailable]);

  useEffect(() => {
    if (activeOrgId) {
      // if (window.location.pathname !== "/") {
      //   let url_parts = window.location.pathname.split("/");
      //   console.log(url_parts);
      //   console.log(url_parts[0].length === 0);
      //   console.log(url_parts[1] === "org");
      //   console.log(url_parts[2] !== activeOrgId);
      //   if (
      //     url_parts[0].length === 0 &&
      //     url_parts[1] === "org" &&
      //     url_parts[2] !== activeOrgId
      //   ) {
      //     console.log("HERE");
      //     console.log(url_parts.join("/"));
      //     url_parts[2] = activeOrgId;
      //     console.log(url_parts.join("/"));
      //     history.push(url_parts.join("/"));
      //   }
      // }
      localStorage.setItem(LOCALSTORAGE_ACTIVE_ORG_ID_KEY, activeOrgId);
      updateWorkspaceGroups(activeOrgId);
      // TODO: update the actual active org
      const o = orgs.find((foundOrg) => foundOrg.id === activeOrgId) || null;
      if (
        o?.id === "bqfgisga2" ||
        o?.id === "n69izot7v" ||
        o?.id === "organization-2pBiGTsi1rNusFbCovo7Lux2E2M"
      ) {
        themeContext.setTheme("accenture");
      } else if (o?.id === "o4x5cvlyx") {
        themeContext.setTheme("deloitte");
      } else {
        themeContext.setTheme("nvidia");
      }
      _setActiveOrg(o);
    }
  }, [activeOrgId]);

  const isEnterprise = (orgId: string) => {
    const enterpriseOrgs = [
      "bqfgisga2",
      "o4x5cvlyx",
      "n69izot7v",
      "org-2q8OcIDLPXz8Tkj6aZEwVQLLwdg",
    ];
    return !!enterpriseOrgs.find((currOrgs) => orgId === currOrgs);
  };

  useEffect(() => {
    if (activeOrg && activeOrg?.defaultWorkspaceGroupId !== "") {
      _setActiveWorkspaceGroupId(activeOrg.defaultWorkspaceGroupId);
      // getGpuInstanceTypes(activeOrg.defaultWorkspaceGroupId);
    } else if (workspaceGroups.length > 0) {
      _setActiveWorkspaceGroupId(workspaceGroups[0].id);
      // getGpuInstanceTypes(workspaceGroups[0].id);
    }
  }, [activeOrg, workspaceGroups]);

  // useEffect(() => {
  //   if (activeWorkspaceGroupId !== "") {
  //     updateInstanceTypes();
  //   }
  // }, [workspaceGroups]);

  useEffect(() => {
    if (activeOrgId !== "") {
      getUsage();
      getUsageOverview();
    }
  }, [activeOrgId]);

  useEffect(() => {
    if (activeOrgId !== "" && isAuthenticated && me?.id) {
      getCredits();
      updateInstanceTypes(activeOrgId);
    }
  }, [activeOrgId, isAuthenticated, me, workspaceGroups]);

  const setup = async (): Promise<[OrgsRes, Organization[], string]> => {
    const res = await agent.Organizations.getAll();
    let newOrgs: Organization[] = [];
    let activeOrgIdToReturn = "";
    if (res.success && res.data) {
      newOrgs = res.data.map((o) => new Organization(o));
      setOrgs(newOrgs);
      const cachedActiveOrgId = localStorage.getItem(
        LOCALSTORAGE_ACTIVE_ORG_ID_KEY
      );
      const validOrgIds = newOrgs.map((o) => o.id);
      if (cachedActiveOrgId && validOrgIds.includes(cachedActiveOrgId)) {
        setActiveOrgId(cachedActiveOrgId);
        activeOrgIdToReturn = cachedActiveOrgId;
      } else if (newOrgs.length > 0) {
        const newActiveOrgId = newOrgs[0].id;
        setActiveOrgId(newActiveOrgId);
        activeOrgIdToReturn = newActiveOrgId;
      }
    }
    return [res, newOrgs, activeOrgIdToReturn];
  };

  useEffect(() => {
    const loadOrgsAndCreateIfNone = async () => {
      setIsLoading(true);
      const [res, newOrgs, _activeOrgId] = await setup();
      if (res.success) {
        const orgName = isKasAuthFlow
          ? `NCA-${currentOrgNgcName.slice(-5)}` // last 5 digits of NCA account number (caveat: old NGC orgs will have random character names)
          : `${me?.username}-hq`;

        // in the KAS auth flow, we always want call createOrSyncOrg no matter
        // if the user already has an org or not - this way we can add users who are already part
        // of an NGC Org/NCA but not the corresponding Brev org to the corresponding Brev org whenever they log in
        if (isKasAuthFlow) {
          await createOrSyncOrg(me ? orgName : "Personal", newOrgs, true);
        } else if (res.data?.length === 0) {
          const createOrgRes = await createOrSyncOrg(me ? orgName : "Personal");
          if (!createOrgRes.success) return;
        }

        const createWorkspaceBody = window.localStorage.getItem(
          `${LOCALSTORAGE_USER_CACHE_PREFIX}-create-workspace`
        );
        window.localStorage.removeItem(
          `${LOCALSTORAGE_USER_CACHE_PREFIX}-create-workspace`
        );
        if (createWorkspaceBody) {
          const { name, gitRepo, orgId, workspaceClassId, setupScript, ref } =
            JSON.parse(createWorkspaceBody) as CreateWorkspaceArgs;

          const workspaceTemplateId = TEMPLATE_ID;
          const workspaceVersion: "v0" | "v1" = flagEnvironmentsV1FlagEnabled
            ? "v1"
            : "v0";
          const args = {
            name,
            workspaceGroupId: config.workspaceGroupID,
            workspaceTemplateId,
            workspaceClassId,
            gitRepo,
            workspaceVersion,
            isStoppable: false,
            ...(setupScript ? { setupScript } : {}),
          };
          // this needs updating with the latest model. should use workspace context functions perhaps
          const newRes = await agent.Workspaces.create(
            orgId || _activeOrgId,
            args
          );
          history.push(`/org/${orgId || _activeOrgId}/environments`, {
            highlightedWorkspaceId: newRes.data?.id,
          });
        }
      }

      setIsLoading(false);
    };
    if (isAuthenticated && me) loadOrgsAndCreateIfNone();
    return () => clear();
  }, [isAuthenticated, me]);

  /** ***********************
   ******** HELPERS ********
   ************************ */

  const providerData = {
    orgs,
    activeOrgId,
    activeOrg,
    isLoading,
    isInstancesLoading,
    getActiveOrg,
    setActiveOrg,
    setDefaultWorkspaceGroupForActiveOrg,
    getOrg,
    getInviteLink,
    inviteByEmail,
    createOrSyncOrg,
    deleteOrg,
    workspaceGroups,
    activeWorkspaceGroupId,
    setActiveWorkspaceGroupId,
    refreshWorkspaceGroups,
    usage,
    usageLoading,
    usageOverview,
    usageOverviewLoading,
    cpuRegions,
    allInstancesAvailable,
    GPUInstancesAvailable,
    CPUInstancesAvailable,
    updateInstanceTypes,
    orgcredits,
    getCredits,
    getCapabilities,
    regionNumLoading,
    isEnterprise,
  };

  return (
    <OrgContext.Provider value={providerData}>{children}</OrgContext.Provider>
  );
};

export default OrgContextProvider;
