import { NotificationContext } from "contexts/NotificationContext";
import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface LocationState {
  message?: string;
  messageSeverity?: any;
}

const Home: React.FC = () => {
  console.log("Route: /home");
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const userContext = useContext(UserContext);
  const orgContext = useContext(OrgContext);
  const notificationContext = useContext(NotificationContext);

  useEffect(() => {
    document.title = "Console | Brev.dev";
  }, []);

  useEffect(() => {
    if (state && state.message && state.messageSeverity) {
      notificationContext.showNotification(
        state.message,
        "",
        state.messageSeverity
      );
    }
  }, []);

  useEffect(() => {
    const { activeOrgId } = orgContext;
    if (activeOrgId) {
      history.push(`/org/${activeOrgId}/environments`, state);
    } else {
      console.log("Active Org not set. Redirecting to welcome page.");
      history.push("/environment/new", state);
    }
  }, [orgContext.activeOrgId]);

  return <></>;
};

export default Home;
