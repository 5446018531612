import Workspace from "../entities/Workspace.entity";
import IProject from "models/Project.model";
import { v4 as uuid } from "uuid";

export default class Project implements IProject {
  public id: string;

  public organizationId: string;

  public workspaces: Array<Workspace>;

  public gitRepo?: string;

  public get numDeployments() {
    return this.workspaces.length;
  }

  public constructor(
    workspaces: Array<Workspace>,
    orgId: string,
    gitRepo?: string
  ) {
    this.id = uuid();
    this.workspaces = workspaces;
    this.organizationId = orgId;
    if (gitRepo) this.gitRepo = gitRepo;
  }

  hasWorkspace = (workspaceId: string): boolean =>
    this.workspaces.some((w) => w.id === workspaceId);
}
