import { Slider } from "@mui/material";
import { withStyles } from "tss-react/mui";
import React, { useEffect } from "react";

const BrevSlider = withStyles(Slider, {
  root: {
    color: "#06B6D4",
    // color: "#14ACB6",
    height: 7,
  },
  thumb: {
    height: 13,
    width: 13,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -4,
    marginLeft: -8,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% - 5px)",
    fontSize: 10,
  },
  track: {
    height: 7,
    borderRadius: 4,
  },
  rail: {
    height: 7,
    borderRadius: 4,
  },
  markLabel: {
    fontSize: 14,
  },
  mark: {
    width: 2,
    height: 11,
    borderRadius: 2,
    backgroundColor: "#06B6D4",
    // backgroundColor: "#14acb6",
    marginLeft: -2,
  },
});

interface Mark {
  value: number;
  label: string;
}
interface SliderProps {
  marks?: Mark[];
  valueText?: (value: number) => string;
  valueLabelFormat?: (value: number) => string;
  value: number | number[];
  onChange: (n: number) => void;
  disabled?: boolean;
  style?: any;
}
// Example of valueText and valueLabelFormat:
/*
  function valuetext(value: number) {
    return `${value}°C`;
  }

  function valueLabelFormat(value: number) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }
 */

export const StyledSlider: React.FC<SliderProps> = (props) => {
  const handleChange = (event: any, newValue: number | number[]) => {
    props.onChange(newValue as number);
  };

  return (
    <BrevSlider
      valueLabelDisplay="auto"
      aria-label="pretto slider"
      style={props.style}
      onChange={handleChange}
      value={props.value}
      valueLabelFormat={
        props.valueLabelFormat ? props.valueLabelFormat : undefined
      }
      getAriaValueText={props.valueText ? props.valueText : undefined}
      aria-labelledby="discrete-slider-restrict"
      step={null}
      marks={props.marks ? props.marks : undefined}
      max={props.marks ? props.marks[props.marks.length - 1].value : undefined}
      disabled={props.disabled ? props.disabled : false}
    />
  );
};

interface SliderRangeProps {}

function valuetext(value: number) {
  return `${value}°C`;
}

export const StyledRangeSlider: React.FC<any> = (props) => {
  const handleChange = (event: any, newValue: number | number[]) => {
    props.onChange(newValue as number);
  };

  return (
    <BrevSlider
      getAriaLabel={() => "Range"}
      valueLabelFormat={(value) => `$${value}`}
      value={props.value}
      onChange={handleChange}
      valueLabelDisplay="auto"
      max={props.max}
    />
  );
};
