/* eslint-disable react/no-unused-prop-types */
// they are being used idk they aren't beinf detected by eslint
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, FlatCard } from "components/UI-lib";
import { Badge } from "components/Graphs/Badge";
import { EndpointProvider } from "components/UI-lib/FlatCard/FlatCardToggleOptionEndpoints";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import Prism from "prismjs";
import "prismjs/components/prism-bash";
import "prismjs/themes/prism-okaidia.css";
import "prismjs/components/prism-go.min.js";
import "prismjs/components/prism-rust.min.js";
import "prismjs/components/prism-python";
import { OrgContext } from "contexts/OrgContext";
import agent, { Deployment } from "server";
import { fallbackCopyTextToClipboard } from "components/utils";

export interface CodeSnippets {
  deployment: Deployment | undefined;
}

const CodeSnippets: React.FC<CodeSnippets> = ({ deployment }) => {
  const tabs = ["Python", "Langchain", "Node", "Shell"];
  deployment?.deployment_id;
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [showHostedSelector, setShowHostedSelector] = useState(true);
  const [selectedHostedConfig, setSelectedHostedConfig] =
    useState<EndpointProvider | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const orgContext = useContext(OrgContext);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  const languageMap = {
    Shell: "python",
    Python: "python",
    Node: "python",
    Langchain: "python",
  };

  useEffect(() => {
    Prism.highlightAll();
  }, [apiKey, activeTab, deployment]);

  useEffect(() => {
    const loadApiKey = async () => {
      setIsLoading(true);
      try {
        const res = await agent.InferenceEndpoints.getApiKey({
          orgId: orgContext.activeOrgId,
        });
        if (res.success && res.data.length > 0) {
          setApiKey(res.data[0].value);
        } else {
          console.error("API key not found in response");
        }
      } catch (error) {
        console.error("Failed to load API key:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (orgContext.activeOrgId) {
      loadApiKey();
    }
  }, [orgContext.activeOrgId]);

  const getBashCommand = (
    id: string,
    baseUrl: string,
    apiKey: string = "[Login to Access API KEY]"
  ) => {
    if (apiKey === "") {
      apiKey = "[Login to Access API KEY]";
    }
    return `
invoke_url='${baseUrl}'
authorization_header='Authorization: Bearer ${apiKey}'
accept_header='Accept: application/json'
content_type_header='Content-Type: application/json'

data=$'{
    "messages": [
    {
        "role": "user",
        "content": "Write a limerick about the wonders of GPU computing."
    }
    ],
    "stream": true,
    "model": "${id}",
    "max_tokens": 1024,
    "presence_penalty": 0,
    "frequency_penalty": 0,
    "top_p": 0.7,
    "temperature": 0.2
}'

response=$(curl --silent -i -w "\n%{http_code}" --request POST \
    --url "$invoke_url" \
    --header "$accept_header" \
    --header "$content_type_header" \
    --data "$data"
)

echo "$response";
    `;
  };

  const getPythonCommand = (id: string, baseUrl: string, apiKey: string) => {
    if (apiKey === "") {
      apiKey = "[Login to Access API KEY]";
    }
    return `
from openai import OpenAI

client = OpenAI(
  base_url = "${baseUrl}",
  api_key = "${apiKey}"
)

completion = client.chat.completions.create(
  model="${id}",
  messages=[{"role":"user","content":"Write a limerick about the wonders of GPU computing."}],
  temperature=0.2,
  top_p=0.7,
  max_tokens=1024,
  stream=True
)

for chunk in completion:
  if chunk.choices[0].delta.content is not None:
    print(chunk.choices[0].delta.content, end="")
   `;
  };

  const getLangchainCommand = (
    id: string,
    baseUrl: string,
    apiKey: string = "[Login to Access API KEY]"
  ) => {
    if (apiKey === "") {
      apiKey = "[Login to Access API KEY]";
    }
    return `
from langchain_nvidia_ai_endpoints import ChatNVIDIA

client = ChatNVIDIA(
    model="${id}", 
    api_key="${apiKey}",
    temperature=0.2,
    top_p=0.7,
    max_tokens=1024,
)

for chunk in client.stream([{"role":"user","content":"Write a limerick about the wonders of GPU computing."}]): 
    print(chunk.content, end="")
    `;
  };

  const getNodeJsCommand = (
    id: string,
    baseUrl: string,
    apiKey: string = "[Login to Access API KEY]"
  ) => {
    if (apiKey === "") {
      apiKey = "[Login to Access API KEY]";
    }
    return ` 
import OpenAI from 'openai';
const openai = new OpenAI({
baseURL: '${baseUrl}',
apiKey: '${apiKey}'
})

async function main() {
const completion = await openai.chat.completions.create({
    model: "${id}",
    messages: [{"role":"user","content":"Write a limerick about the wonders of GPU computing."}],
    temperature: 0.2,
    top_p: 0.7,
    max_tokens: 1024,
    stream: true
})

for await (const chunk of completion) {
    process.stdout.write(chunk.choices[0]?.delta?.content || '')
}

}

main();
`;
  };

  const baseUrl = "https://api.brev.dev/v1/chat/completions";

  const getCommandByTab = useMemo(
    () =>
      (deployment: Deployment | undefined, tab: string): string => {
        if (isLoading) {
          return "Loading API key...";
        }
        if (!apiKey) {
          return "API key not available. Please try again.";
        }
        switch (tab) {
          case "Python":
            return getPythonCommand(
              deployment?.model_id || "",
              baseUrl,
              apiKey
            );
          case "Langchain":
            return getLangchainCommand(
              deployment?.model_id || "",
              baseUrl,
              apiKey
            );
          case "Node":
            return getNodeJsCommand(
              deployment?.model_id || "",
              baseUrl,
              apiKey
            );
          case "Shell":
            return getBashCommand(deployment?.model_id || "", baseUrl, apiKey);
          default:
            return "Invalid tab selected.";
        }
      },
    [apiKey, baseUrl, isLoading, deployment]
  );

  return (
    <div className="">
      <>
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-white">Code Snippets</h2>
        </div>
        <div className={`${inlineAlertMessage ? "mb-3" : ""}`}>
          <InlineNotification
            show={!!inlineAlertMessage}
            severity={inlineAlertSeverity}
            text={inlineAlertMessage}
            autoClose={false}
            onClose={() => setInlineAlertMessage("")}
          />
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex space-x-2 overflow-y-visible scrollbar-hide">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-4 py-2 font-medium text-sm rounded-md
                      ${
                        activeTab === tab
                          ? "border border-gray-300 dark:border-zinc-800 bg-highlight text-white hover:bg-highlight dark:hover:bg-highlightLighter"
                          : "bg-white border hover:bg-gray-50 dark:hover:bg-zinc-700 dark:bg-zinc-900 dark:border-zinc-800 dark:text-cyan-100"
                      }`}
              >
                {tab}
              </button>
            ))}
          </div>
          <button
            type="button"
            className="bg-white dark:bg-zinc-900 rounded-md font-medium text-cyan-600 dark:text-cyan-300 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-highlightLighter mr-2"
            onClick={() => {
              const command = getCommandByTab(deployment, activeTab);
              setInlineAlertSeverity("success");
              setInlineAlertMessage("Command copied to clipboard");
              fallbackCopyTextToClipboard(command);
              setTimeout(() => setInlineAlertMessage(""), 2000);
            }}
          >
            Copy
          </button>
        </div>
        <pre
          className={`prism-code language-${languageMap[activeTab]}`}
          key={activeTab + apiKey}
        >
          <code className="whitespace-pre">
            <span className="token plain">
              {getCommandByTab(deployment, activeTab)}
            </span>
          </code>
        </pre>
      </>
    </div>
  );
};

export default CodeSnippets;
