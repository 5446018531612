import React, { useState, useContext, useEffect, useRef } from "react";
import DevToggle from "components/DevToggle";
import FlatCard from "components/UI-lib/FlatCard";
import { classNames, integerValidationOrEmpty } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { useHistory } from "react-router-dom";
import Button from "components/UI-lib/Button";
import Modal from "components/Modals/Modal";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import InvoiceComponent from "./Legacy-usage-arrears/InvoiceComponent";
import InputField from "components/UI-lib/InputField";
import { set } from "lodash";
import Spinner from "components/UI-lib/Spinner";
import { BillingContext } from "contexts/BillingContext";
import {
  CreditOption,
  CreditsToggleOptions,
} from "components/UI-lib/FlatCard/CreditsToggleOptions";
import { Transition } from "@headlessui/react";
import { sleep } from "server/utils";
import { useWindupString } from "windups";
import poof from "confetti";
import posthog from "posthog-js";

interface CreditsProps {
  withCoupon?: string;
}

const Credits: React.FC<CreditsProps> = (props) => {
  const orgContext = useContext(OrgContext);
  const billingContext = useContext(BillingContext);
  const [showPanel, setShowPanel] = useState<CreditOption>("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [autoRechargeEnabled, setAutoRechargeEnabled] = useState(false);
  const [balanceThreshold, setBalanceThreshold] = useState(
    billingContext.balanceThreshold
  );
  const [rechargeAmount, setRechargeAmount] = useState(
    billingContext.rechargeAmount
  );
  const [oneTimeTopUp, setOneTimeTopUp] = useState("10");

  const [couponCode, setCouponCode] = useState("");

  const [balanceThresholdError, setBalanceThresholdError] = useState("");
  const [rechargeAmountError, setRechargeAmountError] = useState("");

  const couponRef = useRef(null);

  useEffect(() => {
    if (
      billingContext.billingProfile?.billing_type === "credit" &&
      !!props.withCoupon
    ) {
      openCouponFlow(props.withCoupon);
    }
  }, [props.withCoupon, billingContext.billingProfile?.billing_type]);

  const openCouponFlow = async (couponCode: string) => {
    await sleep(500);
    setShowPanel("Redeem Code");
    await sleep(500);
    scrollToElement();
    setCouponCode(couponCode);
  };

  // Function to execute scrolling
  const scrollToElement = () => {
    // Scrolls to the element referenced by `myRef`
    if (couponRef.current) {
      (couponRef.current as any).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleBalanceThresholdChange = (value: string, reset = false) => {
    setBalanceThreshold(value);
    if (reset) {
      setBalanceThresholdError("");
      return;
    }
    const amount = parseFloat(value);
    if (!amount || amount < 10) {
      setBalanceThresholdError("Minimum balance threshold is $10");
    } else {
      setBalanceThresholdError("");
    }
  };

  useEffect(() => {
    setRechargeAmount(billingContext.rechargeAmount);
    setAutoRechargeEnabled(billingContext.rechargeAmount !== "0");
  }, [billingContext.rechargeAmount]);

  useEffect(() => {
    setBalanceThreshold(billingContext.balanceThreshold);
  }, [billingContext.balanceThreshold]);

  const handleRechargeAmountChange = async (value: string, reset = false) => {
    setRechargeAmount(value);
    if (reset) {
      setRechargeAmountError("");
      return;
    }
    const amount = parseFloat(value);
    if (!amount || amount < 10 || amount > 2000) {
      setRechargeAmountError(
        "Recharge amount should be between $10 and $2,000"
      );
    } else {
      setRechargeAmountError("");
    }
  };

  const handleToggleTopUpChange = async () => {
    const { status, message } = await billingContext.handleToggleTopUpChange(
      rechargeAmount
    );
    setInlineAlertMessage(message);
    setInlineAlertSeverity(status);
    if (status === "success") {
      // handle success
      setRechargeAmount("0");
    } else {
      // handle error
    }
  };

  const changeThreshold = async () => {
    const { status, message } = await billingContext.changeThreshold(
      rechargeAmount,
      balanceThreshold
    );
    setInlineAlertMessage(message);
    setInlineAlertSeverity(status);
    if (status === "success") {
      // handle success
      setOneTimeTopUp("");
    } else {
      // handle error
    }
  };

  const ValidateAmount = (amount: string) => {
    if (parseFloat(amount) < 10) {
      return "Input must be greater than $10";
    } else if (isNaN(parseFloat(amount))) {
      return "Input must be a number";
    } else {
      return "";
    }
  };

  const addFunds = async () => {
    if (ValidateAmount(oneTimeTopUp) !== "") {
      setInlineAlertMessage(ValidateAmount(oneTimeTopUp));
      setInlineAlertSeverity("error");
      return;
    }

    const { status, message } = await billingContext.addFunds(oneTimeTopUp);
    setInlineAlertMessage(message);
    setInlineAlertSeverity(status);
    if (status === "success") {
      // handle success
      setOneTimeTopUp("10");
    } else {
      // handle error
    }
  };

  const redeeemCode = async () => {
    const { status, message } = await billingContext.redeeemCode(couponCode);
    if (status === "success") {
      posthog.capture("Redeemed Code", {
        $set: { lastRedeemedCode: couponCode },
        redeemedCode: couponCode,
      });
      // handle success
      await billingContext.fetchBillingProfile();
    }
    setInlineAlertMessage(message);
    setInlineAlertSeverity(status);
  };

  const GetStatusIndicatorLight = (status: "Enabled" | "Disabled") => {
    if (billingContext.isTopUpLoading) {
      return <Spinner type={"primary"} />;
    }

    if (status === "Enabled") {
      return (
        <span
          className={classNames(
            "bg-green-100 dark:bg-green-800",
            "h-4 w-4 rounded-full flex items-center justify-center"
          )}
          aria-hidden="true"
        >
          <span
            className={classNames(
              "bg-green-400 dark:bg-green-500",
              "h-2 w-2 rounded-full"
            )}
          />
        </span>
      );
    }
    if (status === "Disabled") {
      return (
        <span
          className={classNames(
            "bg-slate-100 dark:bg-slate-800",
            "h-4 w-4 rounded-full flex items-center justify-center"
          )}
          aria-hidden="true"
        >
          <span
            className={classNames(
              "bg-slate-400 dark:bg-slate-500",
              "h-2 w-2 rounded-full"
            )}
          />
        </span>
      );
    }
    return (
      <span
        className={classNames(
          "bg-slate-100 dark:bg-slate-800",
          "h-4 w-4 rounded-full flex items-center justify-center"
        )}
        aria-hidden="true"
      >
        <span
          className={classNames(
            "bg-slate-400 dark:bg-slate-500",
            "h-2 w-2 rounded-full"
          )}
        />
      </span>
    );
  };

  const RechargeToggle: React.FC<{
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
  }> = ({ enabled, setEnabled }) => {
    return (
      <div className="flex items-center justify-between">
        <span
          className={classNames(
            `${enabled ? "text-green-500" : "text-red-500"}`,
            "mr-1"
          )}
        >
          {enabled ? "ON" : "OFF"}
        </span>
        <div
          onClick={handleToggleTopUpChange}
          className={classNames(
            enabled ? "bg-green-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5 bg-white" : "translate-x-0 bg-gray-400",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <h1 className="text-lg font-medium dark:text-white">2. Credits</h1>
        <div className="flex justify-between items-center ">
          <h2 className="text-lg font-medium dark:text-white">
            Current Balance:
          </h2>
          <p className="text-xl text-gray-700 dark:text-secondary pl-2">
            {billingContext.isBalanceLoading ? (
              <Spinner type={"primary"} />
            ) : (
              `${billingContext.currentBalance.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            )}
          </p>
        </div>
      </div>
      <div className="flex w-full items-center justify-between" ref={couponRef}>
        <div>
          {/* <p className="text-sm text-gray-500 dark:text-secondary ">
            Instances will be stopped when your credits drop below $10, and then
            deleted after 96 hours if no credits have been added.{" "}
          </p> */}
          <p className="text-sm text-gray-500 dark:text-secondary mb-2 mt-1">
            Enable auto recharge to ensure uninterrupted service.
          </p>
        </div>
        <div className="flex items-center flex-col">
          <div className="flex items-center">
            <>
              <span className="mr-2">Auto Recharge:</span>
              {GetStatusIndicatorLight(
                autoRechargeEnabled ? "Enabled" : "Disabled"
              )}
            </>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <InlineNotification
          show={!!inlineAlertMessage}
          severity={inlineAlertSeverity}
          className="w-full mb-2"
          text={inlineAlertMessage}
          autoClose={false}
          onClose={() => {
            setInlineAlertMessage("");
            setInlineAlertSeverity("error");
          }}
        />
      </div>

      <hr className="mt-1 mb-1 dark:border dark:border-zinc-800" />
      <CreditsToggleOptions
        setSelectedOption={setShowPanel}
        selectedOption={showPanel}
      />
      <Transition
        show={showPanel !== ""}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {showPanel === "Purchase Credits" && (
          <>
            <div className="mt-2">
              <h2 className="text-lg font-medium dark:text-white mb-2">
                Add funds
              </h2>
              <div className="flex flex-row justify-start items-end mb-3">
                <InputField
                  validation={integerValidationOrEmpty}
                  label="Quantity $"
                  value={oneTimeTopUp}
                  placeholder={oneTimeTopUp}
                  onChange={(v) => setOneTimeTopUp(v)}
                  className="w-2/6"
                  errorMessage={ValidateAmount(oneTimeTopUp)}
                  // hideLabel
                />
                <Button
                  label={`Add Funds`}
                  className="mt-2 ml-2"
                  type={parseInt(oneTimeTopUp) >= 0 ? "primary" : "secondary"}
                  loading={billingContext.addFundsLoading}
                  disabled={
                    billingContext.isBalanceLoading ||
                    billingContext.addFundsLoading ||
                    ValidateAmount(oneTimeTopUp) !== ""
                  }
                  onClick={addFunds}
                />
                {parseInt(oneTimeTopUp) >= 0 && (
                  <Button
                    label="Reset"
                    className="ml-1 mt-2"
                    type="secondary"
                    // disabled={loading}
                    onClick={() => {
                      setOneTimeTopUp("");
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {showPanel === "Redeem Code" && (
          <div className="mt-2">
            <h2 className="text-lg font-medium dark:text-white mb-2">
              Redeem Code
            </h2>
            <div className="flex flex-row justify-start items-end mb-3">
              <InputField
                label="Enter Code"
                value={couponCode}
                onChange={(v) => setCouponCode(v)}
                className="w-2/6"
                errorMessage={""}
                // hideLabel
              />
              <div className="flex flex-row justify-content-center">
                <Button
                  label={`${
                    !!props.withCoupon ? "Click Me to Redeem" : "Redeem"
                  }`}
                  className={classNames(
                    "mt-2 ml-2",
                    !!props.withCoupon ? "pulse-ready-button" : ""
                  )}
                  type={couponCode != "" ? "primary" : "secondary"}
                  loading={billingContext.addFundsLoading}
                  disabled={
                    couponCode === "" ||
                    billingContext.addFundsLoading ||
                    billingContext.isBalanceLoading
                  }
                  onClick={redeeemCode}
                />
              </div>
            </div>
          </div>
        )}
        {showPanel === "Edit Auto-Recharge Settings" && (
          <div className="mt-2">
            <h2 className="text-lg font-medium dark:text-white mb-2">
              Auto Recharge Settings
            </h2>
            <p className="text-sm text-gray-500 dark:text-secondary mb-2">
              Minimums apply for thresholds and recharge amounts.{" "}
              <span className="text-gray-500 dark:text-slate-500 font-bold">
                You will be notified via email and your instances will be
                stopped when you run out of credits
              </span>
            </p>
            <InputField
              validation={integerValidationOrEmpty}
              label="When balance goes below $"
              value={balanceThreshold}
              onChange={handleBalanceThresholdChange}
              className=" mb-2"
              errorMessage={balanceThresholdError}
            />
            <InputField
              validation={integerValidationOrEmpty}
              label="Add $"
              value={rechargeAmount}
              onChange={handleRechargeAmountChange}
              className=" mb-2"
              errorMessage={rechargeAmountError}
            />
            <div className="flex flex-row justify-between">
              <div>
                <Button
                  label={`Save`}
                  className="mt-2"
                  type={
                    balanceThreshold !== billingContext.balanceThreshold ||
                    rechargeAmount !== billingContext.rechargeAmount
                      ? "primary"
                      : "secondary"
                  }
                  loading={billingContext.changeThresholdLoading}
                  disabled={
                    (balanceThreshold === billingContext.balanceThreshold &&
                      rechargeAmount === billingContext.rechargeAmount) ||
                    balanceThresholdError !== "" ||
                    rechargeAmountError !== ""
                  }
                  onClick={changeThreshold}
                />
                {(balanceThreshold !== billingContext.balanceThreshold ||
                  rechargeAmount !== billingContext.rechargeAmount) && (
                  <Button
                    label="Reset"
                    className="ml-1 mt-2"
                    type="secondary"
                    onClick={() => {
                      handleRechargeAmountChange(
                        billingContext.rechargeAmount,
                        true
                      );
                      handleBalanceThresholdChange(
                        billingContext.balanceThreshold,
                        true
                      );
                    }}
                  />
                )}
              </div>
              {autoRechargeEnabled && (
                <Button
                  className="mt-2"
                  label="Stop Auto Recharge"
                  type="danger"
                  onClick={() => handleToggleTopUpChange()}
                  loading={billingContext.isTopUpLoading}
                />
              )}
            </div>
          </div>
        )}
      </Transition>
    </>
  );
};

export default Credits;

export const CreditsWrapper: React.FC<CreditsProps> = (props) => {
  return (
    <div className={classNames("w-full")}>
      <FlatCard>
        <Credits withCoupon={props.withCoupon} />
      </FlatCard>
    </div>
  );
};
