import React, { useContext, useEffect, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import {
  ChevronRightIcon,
  XCircleIcon,
  PlusIcon,
  CpuChipIcon,
  ArrowUpRightIcon,
} from "@heroicons/react/24/solid";
import OtherGitRepoIcon from "assets/img/svg/other-git-repo.svg?react";

import { WorkspaceStatus } from "models/Workspace.model";
import { IRepo } from "models/Repo.model";
import { useHistory } from "react-router-dom";
import Workspace from "../../../entities/Workspace.entity";
import { PermissionsContext } from "contexts/PermissionsContext";
import {
  classNames,
  fetchLegacyNotebookList,
  isCPU_techdebt,
} from "components/utils";
import DevToggle, { FeatureToggle } from "components/DevToggle";
import UsernameDisplayField from "components/UsernameDisplayField";
import { Listbox } from "@headlessui/react";
import { supportedCPUInstances } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/CPUSelectorTable";
import Onboardings from "Onboardings";
import NewInstanceMetadata, {
  InstanceMetadataSkeleton,
} from "./InstanceMetadata";
import InstanceMetadata from "./InstanceMetadata";
import { useWindupString } from "windups";
import GettingStarted from "./GettingStarted";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@kui-react/spinner";

export const getWorkspaceStatusChip = (workspaceStatus: WorkspaceStatus) => {
  if (workspaceStatus === WorkspaceStatus.Running) {
    return (
      <span
        key={workspaceStatus}
        className="inline-flex rounded-full bg-green-100 dark:bg-green-600 px-2 text-xs font-semibold leading-5 text-green-800 dark:text-green-200"
      >
        Running
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Deploying) {
    return (
      <span
        key={workspaceStatus}
        className="inline-flex rounded-full bg-cyan-100 dark:bg-highlight px-2 text-xs font-semibold leading-5 text-cyan-800 dark:text-cyan-100"
      >
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white p-[3px]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="#0891b2"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        Deploying
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Stopped) {
    return (
      <span
        key={workspaceStatus}
        className="inline-flex rounded-full bg-gray-100 dark:bg-gray-600 px-2 text-xs font-semibold leading-5 text-gray-800 dark:text-gray-200"
      >
        Stopped
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Starting) {
    return (
      <span
        key={workspaceStatus}
        className="inline-flex rounded-full bg-cyan-100 dark:bg-highlight px-2 text-xs font-semibold leading-5 text-cyan-800 dark:text-cyan-100"
      >
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white p-[3px]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="#0891b2"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        Starting
      </span>
    );
  }
  if (workspaceStatus === WorkspaceStatus.Stopping) {
    return (
      <span
        key={workspaceStatus}
        className="inline-flex rounded-full bg-yellow-100 dark:bg-yellow-800 px-2 text-xs font-semibold leading-5 text-yellow-800 dark:text-yellow-200"
      >
        Stopping
      </span>
    );
  }
  return (
    <span
      key={workspaceStatus}
      className="inline-flex rounded-full bg-yellow-100 dark:bg-yellow-800 px-2 text-xs font-semibold leading-5 text-yellow-800 dark:text-yellow-200"
    >
      {workspaceStatus}
    </span>
  );
};

export const EnvironmentListRedirect: React.FC = () => {
  console.log("Route: /org/environments");
  const router = useHistory();
  const orgContext = useContext(OrgContext);
  useEffect(() => {
    router.push(`/org/${orgContext.activeOrgId}/environments`);
  }, [orgContext.activeOrgId]);

  return <></>;
};

const EnvironmentList: React.FC = (props) => {
  console.log("Route: /org/:orgId/environments");

  const orgContext = useContext(OrgContext);
  const workspaceContext = useContext(WorkspaceContext);
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [dataView, setDataView] = useState<"My" | "My Team's">("My");
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [teamWorkspaces, setTeamWorkspaces] = useState<Workspace[]>([]);
  const [viewableWorkspaces, setViewableWorkspaces] = useState<Workspace[]>([]);
  const [showGettingStarted, setShowGettingStarted] = useState(false);
  const [text] = useWindupString("Create your first instance");
  const flagNimsHackathonEnabled = useFeatureFlagEnabled("nims-hackathon");

  React.useEffect(() => {
    document.title = "Instances | Brev.dev";
  }, []);

  useEffect(() => {
    getMyWorkspaces();
  }, [workspaceContext.workspaces, orgContext.activeOrgId]);

  useEffect(() => {
    // run a function every 10 seconds for 1 minute:
    workspaceContext.reloadWorkspaces(orgContext.activeOrgId);
    const interval = setInterval(() => {
      workspaceContext.reloadWorkspaces(orgContext.activeOrgId);
    }, 5000);
    return () => clearInterval(interval);
  }, [orgContext.activeOrgId]);

  useEffect(() => {
    setTeamWorkspaces(
      workspaceContext.workspaces.filter(
        (p) => p.createdByUserId !== userContext.me?.id
      )
    );
  }, [workspaceContext.workspaces]);

  useEffect(() => {
    if (dataView === "My Team's") {
      setViewableWorkspaces(teamWorkspaces);
    } else {
      setViewableWorkspaces(workspaces);
    }
  }, [workspaces, teamWorkspaces, dataView]);

  useEffect(() => {
    if (viewableWorkspaces.length === 0) {
      setTimeout(() => {
        setShowGettingStarted(true);
      }, 3000);
    }
  }, [viewableWorkspaces]);

  const getMyWorkspaces = () => {
    const myWorkspaces = workspaceContext.workspaces
      .filter(
        (p) =>
          p.createdByUserId === userContext.me?.id ||
          p.additionalUsers?.includes(userContext.me?.id || "")
      )
      .sort((ws1, ws2) => {
        if (ws2.lastOnlineAt > ws1.lastOnlineAt) {
          return 1;
        }
        return -1;
      });
    const myRunningWorkspaces = myWorkspaces.filter(
      (p) => p.status === WorkspaceStatus.Running
    );
    const myDeployingWorkspaces = myWorkspaces.filter(
      (p) => p.status === WorkspaceStatus.Deploying
    );
    const myStartingWorkspaces = myWorkspaces.filter(
      (p) => p.status === WorkspaceStatus.Starting
    );
    const myRemainingWorkspaces = myWorkspaces.filter(
      (p) =>
        p.status !== WorkspaceStatus.Running &&
        p.status !== WorkspaceStatus.Deploying &&
        p.status !== WorkspaceStatus.Starting
    );

    setWorkspaces([
      ...myDeployingWorkspaces,
      ...myStartingWorkspaces,
      ...myRunningWorkspaces,
      ...myRemainingWorkspaces,
    ]);
  };

  const [notebookList, setNotebookList] = useState<any[]>([]);

  useEffect(() => {
    fetchLegacyNotebookList()
      .then((data) => setNotebookList(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  if (workspaceContext.isLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
      {/* <InitialTemplateModal /> */}
      <div className="px-4 sm:px-6 lg:px-8">
        {/* {workspaces.length === 0 && <Onboardings />} */}
        <div className="flex">
          <div className="sm:flex-auto">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                Instances
              </h1>
              <div className="flex">
                <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                  Provision new instances, adjust configurations, monitor logs,
                  and more
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <NewButton />
          </div>
        </div>
        <div className="flex flex-row w-full justify-between mt-5">
          <div className="flex-col">
            <h1 className="flex justify-start items-center text-xl font-semibold text-white">
              <Dropdown
                className="min-w-[200px]"
                label=""
                displayFunction={(dv) => `${dv} Instances`}
                value={dataView}
                onChange={(dv) => {
                  setDataView(dv);
                }}
              >
                <DropdownItem displayValue="My Instances" value="My" key={0} />
                <DropdownItem
                  displayValue={"My Team's Instances"}
                  value={"My Team's"}
                  key={0}
                />
              </Dropdown>{" "}
              {/* <span className="ml-3">Instances</span> */}
            </h1>
            <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
              {dataView === "My Team's"
                ? " These are all of the instances your team is using in this organization"
                : "These are all of your instances in this organization"}
            </p>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          {/* <AltEnvListView workspaces={getMyWorkspaces()} /> */}
          <div className="-my-2 -mx-4 overflow-y-visible sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="">
                {/* <div className="overflow-hidden  md:rounded-lg"> */}
                <ul
                  role="list"
                  // className="divide-y divide-gray-200 dark:divide-zinc-800 border-b border-gray-200 dark:border-zinc-800"
                >
                  {viewableWorkspaces.map((workspace) => (
                    // <p className="text-white">{workspace.name}</p>
                    <InstanceMetadata
                      viewableWorkspace={viewableWorkspaces}
                      workspace={workspace}
                      key={workspace.id}
                      notForSelf={dataView === "My Team's"}
                      notebookList={notebookList}
                    />
                  ))}
                </ul>
                {viewableWorkspaces.length === 0 && (
                  <>
                    <div
                      onClick={() => {
                        history.push("/environment/new");
                      }}
                      className="cursor-pointer"
                    >
                      <div className="bg-primaryBg relative block w-full rounded-lg border-2 border-dashed border-gray-300 dark:border-zinc-800 p-3 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-highlightLighter focus:ring-offset-2 relative">
                        <div className="block text-lg font-medium text-gray-900 dark:text-gray-100 absolute top-8 w-full z-10 cursor-pointer">
                          <span className="text-gray-700 dark:text-secondary text-xl">
                            {text}
                          </span>
                          <ArrowUpRightIcon className="h-5 w-5 ml-2 inline text-gray-700 dark:text-secondary" />
                        </div>
                        <InstanceMetadataSkeleton />
                      </div>
                    </div>
                    {showGettingStarted && (
                      <div>
                        <GettingStarted />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentList;

export interface WorkspaceRowItemProps {
  workspace: Workspace;
  notForSelf?: boolean;
  viewableWorkspace: any[];
  notebookList: any[];
}

interface NewButtonProps {
  defaultOption?: "gpu" | "deployment";
}

export const NewButton: React.FC<NewButtonProps> = ({
  defaultOption = "gpu",
}) => {
  const history = useHistory();
  const [newOptions] = useState([
    {
      title: "New GPU",
      description: "Create a new GPU instance.",
      current: false,
      onClick: () => {
        history.push("/environment/new");
      },
      icon: CpuChipIcon,
    },
    {
      title: "New Deployment",
      description: "Set up a new deployment.",
      current: false,
      onClick: () => {
        history.push("/deployment/new");
      },
      icon: RocketLaunchIcon,
    },
  ]);

  const [selected, setSelected] = useState(
    newOptions[defaultOption === "deployment" ? 1 : 0]
  );

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            Create new instance, model, GPU, or deployment
          </Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-cyan-700 rounded-md shadow-sm">
              <div className="inline-flex justify-center w-full rounded-md shadow-sm">
                <Listbox.Button
                  className="inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold bg-highlight text-white shadow-sm hover:bg-highlight dark:hover:bg-highlightLighter focus:outline-none focus:ring-2 focus:ring-highlightLighter focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-zinc-900 w-full"
                  onClick={() => {
                    selected.onClick();
                  }}
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  <span className="text-sm font-semibold">New</span>
                </Listbox.Button>
              </div>
              <Listbox.Options
                className={classNames(
                  "absolute z-10 mt-2 w-56 origin-top-right rounded-md",
                  "bg-white dark:bg-zinc-900 dark:text-secondary",
                  "shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                  "border border-gray-500 dark:border-zinc-800"
                )}
              >
                {newOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      `${active ? "text-white bg-highlight" : "text-gray-900"}
                          cursor-pointer select-none relative py-2 pl-3 pr-9`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div
                        className="flex items-center"
                        onClick={option.onClick}
                      >
                        <option.icon
                          className="h-6 w-6 mr-3 text-gray-400 dark:text-slate-500"
                          aria-hidden="true"
                        />
                        <div className="flex flex-col">
                          {/* <div className="font-medium text-gray-900 dark:text-slate-300">
                            {option.title}
                          </div> */}
                          <div className="text-sm text-gray-500 dark:text-secondary">
                            {option.description}
                          </div>
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </div>
        </>
      )}
    </Listbox>
  );
};
