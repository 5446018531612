import agent from "server";
import React, { useContext, useEffect, useState } from "react";
import { useWindupString } from "windups";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { GetLogo } from "components/DashboardContainer/Dash";
import { ContainerSelected } from "../EnvironmentCreate";

export const allPublicCloudsName = "Brev Cloud";

export const allowedImageList = [
  { DisplayName: "None", Image: "None" },
  {
    DisplayName: "Pytorch Devel",
    Image: "docker.io/pytorch/pytorch:2.2.0-cuda12.1-cudnn8-devel",
    Description: "Pytorch 2.2.0 with CUDA 12.1 and cuDNN 8 with nvcc",
  },
  {
    DisplayName: "Pytorch Runtime",
    Image: "docker.io/pytorch/pytorch:2.2.0-cuda12.1-cudnn8-runtime",
    Description: "Pytorch 2.2.0 with CUDA 12.1 and cuDNN 8 runtime",
  },
  {
    DisplayName: "NVIDIA NeMo 24.05.01",
    Image: "nvcr.io/nvidia/nemo:24.05.01",
    Description: "NVIDIA NeMo 24.05.01",
  },
  {
    DisplayName: "NVIDIA TensorRT",
    Image: "nvcr.io/nvidia/tensorrt:24.05-py3",
    Description: "NVIDIA TensorRT 24.05 with Python 3",
  },
  {
    DisplayName: "NVIDIA RAPIDS",
    Image: "nvcr.io/nvidia/rapidsai/notebooks:24.04-cuda12.0-py3.10",
    Description:
      "NVIDIA RAPIDS Notebooks 24.04 with CUDA 12.0.1 & Python 3.10",
  },
  {
    DisplayName: "NVIDIA BioNeMo 2.0",
    Image: "nvcr.io/nvidia/clara/bionemo-framework:nightly",
    Description: "BioNeMo Framework 2.0",
  },
  {
    DisplayName: "TensorFlow 2",
    Image: "nvcr.io/nvidia/tensorflow:24.05-tf2-py3",
    Description: "TensorFlow 2 with TensorRT built in",
  },
  {
    DisplayName: "Triton Server",
    Image: "nvcr.io/nvidia/tritonserver:24.06-trtllm-python-py3",
    Description: "Triton Server with TensorRT-LLM built in",
  },
  // {
  //   DisplayName: "NIM-Day0-24.04",
  //   Image: "nvcr.io/ohlfw0olaadg/ea-participants/nim_llm:24.04-day0.rc3",
  //   Description: "24.04-Day0 VLLM and TRT container",
  // },
  // {
  //   DisplayName: "NIM-24.04",
  //   Image: "nvcr.io/ohlfw0olaadg/ea-participants/nim_llm:24.04.rc3",
  //   Description: "24.04 TRT only container",

  // }
];

export const handleIsPaymentSetup = async (
  cloud: string,
  activeOrgId: string
) => {
  if (cloud === allPublicCloudsName) {
    const res = await agent.Billing.getSavedCreditInfo(activeOrgId);
    if (res.success && res.data) {
      if (res.data.last4 && res.data.expiration) {
        // this means payment is setup
        return true;
      }
    }
    return false;
  } else {
    // We assume the other clouds have the payment set up
    return true;
  }
};

export const LoadingDots: React.FC = () => {
  const dots = new Array(3).fill(".");
  return (
    <div className="flex justify-between w-4">
      {dots.map((dot, i) => (
        <span
          key={i}
          style={{ animation: `dot 1.4s infinite ${i * 0.2}s` }}
          className="opacity-0"
        >
          {dot}
        </span>
      ))}
    </div>
  );
};

export const ConfigurationTitle = ({ isCompressed }) => {
  const [text] = useWindupString(
    isCompressed ? "Configuration selected!" : "Select a configuration:"
  );
  return (
    <h3 className=" text-xl font-medium leading-6 text-gray-900 dark:text-white">
      {text}
    </h3>
  );
};

export function convertToGPUMap(gpuInstances: GPUInstanceType[]): {
  [gpuName: string]: GPUInstanceType[];
} {
  const gpuMap: { [gpuName: string]: GPUInstanceType[] } = {};

  gpuInstances.forEach((instance) => {
    instance.supported_gpus.forEach((gpu) => {
      if (gpuMap[gpu.name]) {
        gpuMap[gpu.name].push(instance);
      } else {
        gpuMap[gpu.name] = [instance];
      }
    });
  });

  return gpuMap;
}

export const NimsHackathon = () => {
  return (
    <div className="flex flex-row items-center">
      <div>
        <svg
          width="24"
          height="16"
          viewBox="0 0 249 165"
          className="mr-1"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
        </svg>
      </div>
    </div>
  );
};

export const hasBaseContainer = (container: string) => {
  return (
    container &&
    container !== "Default" &&
    container !== "VM Mode" &&
    container !== "None"
  );
};

export const usesVerbYaml = (
  containerUrl: string,
  isCustomContainer: boolean
) => {
  return !isCustomContainer && containerUrl !== "VM Mode";
};

export const isStoppableInstance = (platformType: string) => {
  return platformType !== "akash" && platformType !== "lambda-labs";
};
