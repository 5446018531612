import React from "react";
import {
  RadioCardGroup,
  RadioCardIndicator,
  RadioCardItem,
} from "components/Graphs/RadioCardGroup";
import ChipStack from "components/Environment/Create/chips/ChipStack";

interface NumberOfGPUsFilterProps {
  selectedNumberOfGPUs: string;
  setSelectedNumberOfGPUs: (gpuNum: string) => void;
}

const NumberOfGPUsFilter: React.FC<NumberOfGPUsFilterProps> = ({
  selectedNumberOfGPUs,
  setSelectedNumberOfGPUs,
}) => {
  return (
    <div>
      <RadioCardGroup
        className="flex flex-row w-full"
        value={selectedNumberOfGPUs}
        onValueChange={(value) => {
          setSelectedNumberOfGPUs(value);
        }}
      >
        <RadioCardItem value="any">
          <div className="flex items-start gap-3">
            <RadioCardIndicator className="mt-1" />
            <div className="flex flex-row relative">
              <span className="sm:text-sm text-white">Any</span>
            </div>
          </div>
        </RadioCardItem>
        <RadioCardItem value="1">
          <div className="flex items-start gap-3">
            <RadioCardIndicator className="mt-1" />
            <div className="flex flex-row relative">
              <span className="sm:text-sm text-white">1</span>
            </div>
          </div>
        </RadioCardItem>
        <RadioCardItem value="2">
          <div className="flex items-start gap-3">
            <RadioCardIndicator className="mt-1" />
            <div className="flex flex-row relative">
              <span className="sm:text-sm text-white">2</span>
            </div>
          </div>
        </RadioCardItem>
        <RadioCardItem value="4">
          <div className="flex items-start gap-3">
            <RadioCardIndicator className="mt-1" />
            <div className="flex flex-row relative">
              <span className="sm:text-sm text-white">4</span>
            </div>
          </div>
        </RadioCardItem>
        <RadioCardItem value="8+">
          <div className="flex items-start gap-3">
            <RadioCardIndicator className="mt-1" />
            <div className="flex flex-row relative">
              <span className="sm:text-sm text-white">8+</span>
            </div>
          </div>
        </RadioCardItem>
      </RadioCardGroup>
    </div>
  );
};

export default NumberOfGPUsFilter;
