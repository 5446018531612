import { classNames } from "components/utils";
import React, { useContext, useEffect, useState } from "react";

export function Checkboxes() {
  const [checked, setChecked] = useState(false);

  return (
    <fieldset className="space-y-5">
      <legend className="sr-only">Notifications</legend>
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-highlightLighter"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            Comments
          </label>
          <p id="comments-description" className="text-gray-500">
            Get notified when someones posts a comment on a posting.
          </p>
        </div>
      </div>
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id="candidates"
            aria-describedby="candidates-description"
            name="candidates"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-highlightLighter"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="candidates" className="font-medium text-gray-700">
            Candidates
          </label>
          <p id="candidates-description" className="text-gray-500">
            Get notified when a candidate applies for a job.
          </p>
        </div>
      </div>
      <Checkbox
        label="Offers"
        description="Get notified when a candidate accepts or rejects an offer."
        checked={checked}
        onChange={setChecked}
      />
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id="offers"
            aria-describedby="offers-description"
            name="offers"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-highlightLighter"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="offers" className="font-medium text-gray-700">
            Offers
          </label>
          <p id="offers-description" className="text-gray-500">
            Get notified when a candidate accepts or rejects an offer.
          </p>
        </div>
      </div>
    </fieldset>
  );
}

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (res: boolean) => void;
  id?: string;
  className?: string;
  description?: string;
  labelClassName?: string;
}

const Checkbox: React.FC<CheckboxProps> = (props) => (
  <div className="relative flex items-start">
    <div className="flex h-5 items-center">
      <input
        id={props.id ? props.id : props.label}
        aria-describedby="offers-description"
        name={props.id ? props.id : props.label}
        type="checkbox"
        checked={props.checked}
        onChange={(e) => props.onChange(e.target.checked)}
        className={`h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-highlightLighter ${props.className}`}
      />
    </div>
    <div className="ml-3 text-sm">
      <label
        htmlFor={props.id ? props.id : props.label}
        className={classNames(
          `font-medium text-gray-700 dark:text-cyan-100`,
          props.labelClassName ? props.labelClassName : ""
        )}
      >
        {props.label}
      </label>
      {props.description && (
        <p
          id={`${props.id ? props.id : props.label}-description`}
          className="text-gray-500 dark:text-cyan-100"
        >
          {props.description}
        </p>
      )}
    </div>
  </div>
);

export default Checkbox;
