import { getRootDirectoryFromPath } from "components/utils";
import { IExecOptions, IPathExec } from "models/Exec.model";
import { IExecName } from "server";
import { v4 as uuid } from "uuid";

export default class PathExec implements IPathExec {
  id: string;

  type: "path";

  name: string;

  execPath: string;

  stage?: "start" | "build";

  execWorkDir?: string;

  isDisabled: boolean;

  logPath?: string;

  logArchivePath?: string;

  dependsOn?: IExecName[];

  public constructor(
    pathInRepo: string | null,
    repoName: string | null,
    execOptions?: IExecOptions | null,
    existingExecValue?: IPathExec,
    existingExecKey?: string
  ) {
    this.id = uuid();
    this.type = "path";
    if (existingExecValue && existingExecKey) {
      this.name = existingExecKey;
      this.execPath = existingExecValue.execPath;
      this.stage = existingExecValue.stage;
      this.execWorkDir =
        existingExecValue.execWorkDir ||
        getRootDirectoryFromPath(existingExecValue.execPath);
      this.isDisabled = existingExecValue.isDisabled || false;
      this.logPath = existingExecValue.logPath;
      this.logArchivePath = existingExecValue.logArchivePath;
      this.dependsOn = existingExecValue.dependsOn;
    } else {
      this.name = `${repoName}-exec`;
      this.execPath = `${repoName}/${pathInRepo}`;
      if (execOptions?.execWorkDir) {
        this.execWorkDir = execOptions.execWorkDir;
      } else {
        this.execWorkDir = repoName || ""; // need better way to create this entity
      }
      if (execOptions?.logPath) {
        this.logPath = execOptions.logPath;
      } else {
        this.logPath = `${repoName}/.brev/logs`;
      }
      if (execOptions?.logArchivePath) {
        this.logArchivePath = execOptions.logArchivePath;
      } else {
        this.logArchivePath = `${repoName}/.brev/logs/archive`;
      }
      if (execOptions?.isDisabled) {
        this.isDisabled = execOptions.isDisabled;
      } else {
        this.isDisabled = false;
      }
      if (execOptions?.logArchivePath)
        this.logArchivePath = execOptions.logArchivePath;
      if (execOptions?.stage) this.stage = execOptions.stage;
      if (execOptions?.dependsOn) this.dependsOn = execOptions.dependsOn;
      if (execOptions?.execWorkDir) this.execWorkDir = execOptions.execWorkDir;
    }
  }

  public get isDefault(): boolean {
    return this.name.includes("default-");
  }

  public get execKey(): string {
    return this.name;
  }

  public get execValue(): IPathExec {
    const exec: IPathExec = {
      type: this.type,
      execPath: this.execPath,
      isDisabled: this.isDisabled,
    };
    if (this.stage) exec.stage = this.stage;
    if (this.execWorkDir) exec.execWorkDir = this.execWorkDir;
    if (this.logPath) exec.logPath = this.logPath;
    if (this.logArchivePath) exec.logArchivePath = this.logArchivePath;
    if (this.dependsOn) exec.dependsOn = this.dependsOn;
    return exec;
  }

  public get exec(): { [key: string]: IPathExec } {
    return { [this.execKey]: this.execValue };
  }
}
