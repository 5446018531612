import React, { useContext, useEffect, useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import agent from "server";
import { OrgContext } from "contexts/OrgContext";

function OrgResourceLimits() {
  const [resourceLimits, setResourceLimits] = useState("");
  const [currentResourceUsage, setCurrentResourceUsage] = useState("");
  const [resourcesRemaining, setResourcesRemaining] = useState("");
  const orgContext = useContext(OrgContext);

  const fetchResourceLimits = async () => {
    const resourcesRes = await agent.ResourceLimits.getResourceLimits(
      orgContext.activeOrgId,
      ""
    );
    if (resourcesRes.success) {
      //console.log(resourcesRes.data);
      const json = JSON.stringify(resourcesRes.data);
      const data = JSON.parse(json);
      //const { data } = resourcesRes;
      //console.log("test - max resources are: " + data.maxCostUtilizationPerHour);
      //console.log("test - current resource use is: " + data.totalCostOfWorkspaces);
      const limit = data.maxCostUtilizationPerHour;
      const usage = data.totalCostOfWorkspaces;
      setResourceLimits(limit);
      setCurrentResourceUsage(usage);
      setResourcesRemaining((parseFloat(limit) - parseFloat(usage)).toFixed(3));
    } else {
      console.log("Failed to fetch resource limits or empty data");
    }
  };

  useEffect(() => {
    fetchResourceLimits();
  }, [orgContext.activeOrgId]);

  return (
    <FlatCard>
      <h3 className="text-xl mb-3 font-medium dark:text-white">
        Resource Limit
      </h3>
      <p className="mb-2 text-sm text-gray-700 dark:text-secondary">
        Your organization has a resource usage limit of ${resourceLimits} per
        hour
      </p>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="bg-white dark:bg-zinc-900 dark:border-zinc-800 w-full h-5 rounded border-solid border flex items-center justify-start"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            width: `${
              (parseFloat(currentResourceUsage) / parseFloat(resourceLimits)) *
              100
            }%`,
          }}
          className={`text-base h-5 bg-highlight rounded dark:border-zinc-800 border-solid border-r `}
        ></div>
      </div>
      <div className="flex text-sm ml-1 mt-1">
        <p className="mb-2 text-sm text-gray-500 dark:text-secondary">
          Current usage: ${parseFloat(currentResourceUsage).toFixed(3)} per hour
        </p>
        <p className="mb-2 text-sm text-gray-500 dark:text-secondary ml-auto">
          Available usage: ${resourcesRemaining} per hour
        </p>
      </div>
      <div className="mb-1 mt-1 max-w-2xl text-sm text-gray-700 dark:text-secondary">
        Need more resources? Reach out to us at
        <a href="mailto:brev-support@nvidia.com">brev-support@nvidia.com</a>
      </div>
    </FlatCard>
  );
}

export default OrgResourceLimits;
