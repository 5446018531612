import React, { act, useContext, useEffect, useMemo, useState } from "react";
import {
  LaunchableContext,
  formatDailyLaunchableMetrics,
} from "contexts/LaunchableContext";
import MultiSelectDropdown from "components/UI-lib/MultiSelectDropDown";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import { AreaChart } from "components/Graphs/AreaGraph";
import { OneClickDeployContext } from "contexts/OneClickDeployContext";
import { classNames } from "components/utils";

const placeholdeData = [
  {
    date: "May 1",
    "TensorRT Launchable (...123)": 2890,
    "Llama3 NeMo Launchable (...abc)": 2338,
  },
  {
    date: "May 2",
    "TensorRT Launchable (...123)": 2756,
    "Llama3 NeMo Launchable (...abc)": 2103,
  },
  {
    date: "May 3",
    "TensorRT Launchable (...123)": 3322,
    "Llama3 NeMo Launchable (...abc)": 2194,
  },
  {
    date: "May 4",
    "TensorRT Launchable (...123)": 3470,
    "Llama3 NeMo Launchable (...abc)": 2108,
  },
  {
    date: "May 5",
    "TensorRT Launchable (...123)": 3475,
    "Llama3 NeMo Launchable (...abc)": 1812,
  },
  {
    date: "May 6",
    "TensorRT Launchable (...123)": 3129,
    "Llama3 NeMo Launchable (...abc)": 1726,
  },
  {
    date: "May 7",
    "TensorRT Launchable (...123)": 3490,
    "Llama3 NeMo Launchable (...abc)": 1982,
  },
  {
    date: "May 8",
    "TensorRT Launchable (...123)": 2903,
    "Llama3 NeMo Launchable (...abc)": 2012,
  },
  {
    date: "May 9",
    "TensorRT Launchable (...123)": 2643,
    "Llama3 NeMo Launchable (...abc)": 2342,
  },
  {
    date: "May 10",
    "TensorRT Launchable (...123)": 2837,
    "Llama3 NeMo Launchable (...abc)": 2473,
  },
  {
    date: "May 11",
    "TensorRT Launchable (...123)": 2954,
    "Llama3 NeMo Launchable (...abc)": 3848,
  },
  {
    date: "May 12",
    "TensorRT Launchable (...123)": 3239,
    "Llama3 NeMo Launchable (...abc)": 3736,
  },
  {
    date: "May 13",
    "TensorRT Launchable (...123)": 2324,
    "Llama3 NeMo Launchable (...abc)": 1800,
  },
  {
    date: "May 14",
    "TensorRT Launchable (...123)": 4343,
    "Llama3 NeMo Launchable (...abc)": 1920,
  },
  {
    date: "May 15",
    "TensorRT Launchable (...123)": 3200,
    "Llama3 NeMo Launchable (...abc)": 1234,
  },
  {
    date: "May 16",
    "TensorRT Launchable (...123)": 2303,
    "Llama3 NeMo Launchable (...abc)": 2100,
  },
  {
    date: "May 17",
    "TensorRT Launchable (...123)": 3300,
    "Llama3 NeMo Launchable (...abc)": 2500,
  },
  {
    date: "May 18",
    "TensorRT Launchable (...123)": 3230,
    "Llama3 NeMo Launchable (...abc)": 1800,
  },
  {
    date: "May 19",
    "TensorRT Launchable (...123)": 3004,
    "Llama3 NeMo Launchable (...abc)": 1750,
  },
  {
    date: "May 20",
    "TensorRT Launchable (...123)": 3600,
    "Llama3 NeMo Launchable (...abc)": 2200,
  },
  {
    date: "May 21",
    "TensorRT Launchable (...123)": 2543,
    "Llama3 NeMo Launchable (...abc)": 2100,
  },
  {
    date: "May 22",
    "TensorRT Launchable (...123)": 3232,
    "Llama3 NeMo Launchable (...abc)": 2000,
  },
  {
    date: "May 23",
    "TensorRT Launchable (...123)": 2000,
    "Llama3 NeMo Launchable (...abc)": 1400,
  },
  {
    date: "May 24",
    "TensorRT Launchable (...123)": 3234,
    "Llama3 NeMo Launchable (...abc)": 1234,
  },
  {
    date: "May 25",
    "TensorRT Launchable (...123)": 2400,
    "Llama3 NeMo Launchable (...abc)": 1700,
  },
  {
    date: "May 26",
    "TensorRT Launchable (...123)": 2405,
    "Llama3 NeMo Launchable (...abc)": 1600,
  },
  {
    date: "May 27",
    "TensorRT Launchable (...123)": 4300,
    "Llama3 NeMo Launchable (...abc)": 1800,
  },
  {
    date: "May 28",
    "TensorRT Launchable (...123)": 4400,
    "Llama3 NeMo Launchable (...abc)": 1400,
  },
  {
    date: "May 29",
    "TensorRT Launchable (...123)": 2345,
    "Llama3 NeMo Launchable (...abc)": 1324,
  },
  {
    date: "May 30",
    "TensorRT Launchable (...123)": 3245,
    "Llama3 NeMo Launchable (...abc)": 1200,
  },
];

const LaunchableGraph: React.FC = () => {
  const launchableContext = useContext(LaunchableContext);

  const [selectedValues, setSelectedValues] = useState<string[]>(["None"]);
  const [allLaunchableMappingList, setAllLaunchableMappingList] = useState<
    { Views: any; Deploys: any }[]
  >([]);
  const [displayLaunchableGraphMapping, setDisplayLaunchableGraphMapping] =
    useState<{}[]>([]);

  const metricOptions = ["Views", "Deploys"];
  const [selectedMetric, setSelectedMetric] = useState<string>("Views");

  const [launchableKeys, setLaunchableKeys] = useState<string[]>([]);
  const [displayLaunchableKeys, setDisplayLaunchableKeys] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (launchableKeys.length === 0) {
      return;
    }
    setDisplayLaunchableKeys(launchableKeys);
    const defaultKeys = launchableKeys.slice(0, 3);
    setSelectedValues(defaultKeys);
    const restOfLaunchhables = launchableContext.activelaunchables.filter(
      (launchable) => {
        return !launchableKeys.includes(
          `${launchable.name} (...${launchable.id.slice(-5)})`
        );
      }
    );
    setDisplayLaunchableKeys([
      ...launchableKeys,
      ...restOfLaunchhables.map(
        (launchable) => `${launchable.name} (...${launchable.id.slice(-5)})`
      ),
    ]);
  }, [launchableKeys, launchableContext.activelaunchables]);

  useEffect(() => {
    if (
      launchableContext.dailyLaunchableMetrics.length === 0 ||
      launchableContext.activelaunchables.length === 0
    ) {
      return;
    }

    const allGraphValues = launchableContext.dailyLaunchableMetrics.map(
      (dailyLaunchable) => {
        const transformedLaunchableWithName = dailyLaunchable.launchables.map(
          (metricLaunchable) => {
            const currentLaunchable = launchableContext.activelaunchables.find(
              (active) => metricLaunchable.id === active.id
            );
            return {
              ...metricLaunchable,
              name: currentLaunchable?.name || "Unkown",
            };
          }
        );

        const viewsByIdAndName = {};
        const deploymentsByIdAndName = {};

        const launchableKeys: string[] = [];

        transformedLaunchableWithName.forEach((item) => {
          const key = `${item.name} (...${item.id.slice(-5)})`;
          // Ensure that launchable with views are displayed first
          if (item.views > 0) {
            launchableKeys.unshift(key);
          } else {
            launchableKeys.push(key);
          }
          viewsByIdAndName[key] = item.views;
          deploymentsByIdAndName[key] = item.deployments;
        });

        setLaunchableKeys(launchableKeys);

        launchableContext.activelaunchables.forEach((launchable) => {
          if (
            !transformedLaunchableWithName.find(
              (item) => item.id === launchable.id
            )
          ) {
            const key = `${launchable.name} (...${launchable.id.slice(-5)})`;
            viewsByIdAndName[key] = 0;
            deploymentsByIdAndName[key] = 0;
          }
        });
        viewsByIdAndName["date"] = formatDailyLaunchableMetrics(
          dailyLaunchable.date
        );
        deploymentsByIdAndName["date"] = formatDailyLaunchableMetrics(
          dailyLaunchable.date
        );

        return {
          Views: viewsByIdAndName,
          Deploys: deploymentsByIdAndName,
        };
      }
    );
    setAllLaunchableMappingList(allGraphValues);
  }, [
    launchableContext.activelaunchables,
    launchableContext.dailyLaunchableMetrics,
  ]);

  useEffect(() => {
    if (allLaunchableMappingList.length === 0) {
      return;
    }

    const displayLaunchableGraphMapping: {}[] = [];
    for (const day of allLaunchableMappingList) {
      const metricObject = day[selectedMetric];
      const displayObject = {};
      for (const key in metricObject) {
        if (selectedValues.includes(key) || key === "date") {
          displayObject[key] = metricObject[key];
        }
      }
      displayLaunchableGraphMapping.push(displayObject);
    }
    setDisplayLaunchableGraphMapping(displayLaunchableGraphMapping);
  }, [selectedMetric, selectedValues, allLaunchableMappingList]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col justify-start mb-3">
          <h1 className="text-xl font-medium dark:text-white">Launchable</h1>
          <p className="text-sm text-gray-700 dark:text-secondary">
            Last 30 days
          </p>
        </div>
        <div className="flex flex-row dark:text-secondary justify-end">
          <div className="flex flex-row justify-end">
            <div className="w-full mr-3 min-w-[350px] max-w-[350px]">
              <MultiSelectDropdown
                label="Select Launchable/s"
                value={selectedValues}
                displayFunction={(selectedNames) =>
                  selectedNames.map((value) => value).join(", ")
                }
                onChange={(instanceNames) => setSelectedValues(instanceNames)}
                useExclusive="None"
              >
                {displayLaunchableKeys.map((launchable, index) => (
                  <DropdownItem
                    displayValue={launchable}
                    value={launchable}
                    key={index}
                  />
                ))}
              </MultiSelectDropdown>
            </div>
            <div className="w-full min-w-[150px]">
              <Dropdown
                label="Select Metric"
                value={selectedMetric}
                onChange={(metric) => setSelectedMetric(metric)}
              >
                {metricOptions.map((metric) => (
                  <DropdownItem displayValue={metric} value={metric} />
                ))}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-3">
        {launchableContext.loadingDailyMetrics ||
        launchableContext.dailyLaunchableMetrics.length === 0 ? (
          <div className="flex relative w-full">
            <div
              className={classNames(
                "absolute top-[20%] z-50",
                launchableContext.loadingDailyMetrics
                  ? "left-[48%]"
                  : "left-[45%]"
              )}
            >
              <span className="text-white font-bold">
                {launchableContext.loadingDailyMetrics
                  ? "Loading..."
                  : "No Data Available"}
              </span>
            </div>
            <AreaChart
              className="opacity-15 h-80"
              data={placeholdeData}
              showTooltip={false}
              showLegend={false}
              disableHoverDots={true}
              index="date"
              categories={[
                "TensorRT Launchable (...123)",
                "Llama3 NeMo Launchable (...abc)",
              ]}
              intervalType="preserveStartEnd"
            />
          </div>
        ) : (
          <>
            <AreaChart
              className="h-80"
              data={displayLaunchableGraphMapping}
              index="date"
              showLegend={
                selectedValues.length > 0 && selectedValues[0] !== "None"
              }
              minValue={
                selectedValues.length > 0 && selectedValues[0] !== "None"
                  ? undefined
                  : 0
              }
              maxValue={
                selectedValues.length > 0 && selectedValues[0] !== "None"
                  ? undefined
                  : 200
              }
              categories={selectedValues}
              onValueChange={(v) => console.log(v)}
              intervalType="preserveStartEnd"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LaunchableGraph;
