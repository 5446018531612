import config from "config";

export const REQUEST_TIMEOUT = 25000;
export const POLL_INTERVAL = 3000;
export const DEFAULT_ERROR_MESSAGE =
  "Incorrect username or password. Try again!";
export const PRESERVED_PATH = "/home/ubuntu";

export const CONFIRMATION_TEXT_BEFORE = "Please type";
export const CONFIRMATION_TEXT_AFTER = "to confirm.";

export const RESET_WORKSPACE_HELPER_TEXT_PT1 =
  "Reseting your instance clears your development instance. Setup scripts are re-run and processes are stopped. Only files in";
export const RESET_WORKSPACE_HELPER_TEXT_PT2 = "will be preserved.";
export const RESET_WORKSPACE_CONFIRMATION_TEXT = "Reset Instance";

export const DELETE_ENVIRONMENT_HELPER_TEXT =
  "Deleting your instance deletes this machine. All uncommitted changes will be permanently lost.";
export const DELETE_ENVIRONMENT_CONFIRMATION_TEXT = "Delete Instance";

export const DELETE_ORGANIZATION_HELPER_TEXT =
  "Deleting your organization removes the organization for all users. If you would like to remove yourself from the organization instead, remove yourself from the Manage Access panel.";
export const DELETE_ORGANIZATION_CONFIRMATION_TEXT = "Delete Organization";
export const DELETE_ORGANIZATION_CONFIRMATION_TEXT_ADDITIONAL =
  "In addition, ensure all workspaces in the organization are deleted.";

export const LOCALSTORAGE_USER_CACHE_PREFIX = "brev-user-cache";
export const LOCALSTORAGE_UI_MODE_KEY = "brev-ui-mode-new";
export const LOCALSTORAGE_ACTIVE_ORG_ID_KEY = "brev-active-org-id";

/** ***************************************
 **** SHOULD RETRIEVE THROUGH APIs 👇 ****
 **************************************** */

/**
 * Used to translate from operating system + application to template.
 * Since the only operating system we support right now is Ubuntu, we only look at applicationId
 */
export const TEMPLATE_ID = config.ubuntuTemplateID;

export const DEFAULT_OPERATING_SYSTEM_ID = "92f59a4yf";
export const DEFAULT_OPERATING_SYSTEM = {
  id: "92f59a4yf",
  name: "Ubuntu",
};
export const OPERATING_SYSTEMS: { id: string; name: string }[] = [
  {
    id: "92f59a4yf",
    name: "Ubuntu",
  },
  {
    id: "2",
    name: "Microsoft Windows",
  },
  {
    id: "3",
    name: "MacOS",
  },
  {
    id: "4",
    name: "CentOS",
  },
  {
    id: "5",
    name: "Debian",
  },
  {
    id: "6",
    name: "Fedora",
  },
];

// Used in workspace entity
export const JUPYTER_TEMPLATE_URL =
  "https://github.com/brevdev/jupyter_template";
export const RSTUDIO_TEMPLATE_URL =
  "https://github.com/brevdev/rstudio_template";

export interface GPUInstanceType {
  name: string;
  GPU: number;
  vCPU: number;
  mem: number;
  GPUMem: number;
}

export const GPU_INSTANCE_TYPES: { [name: string]: GPUInstanceType } = {
  "p4d.24xlarge": {
    name: "p4d.24xlarge",
    GPU: 8,
    vCPU: 96,
    mem: 1152,
    GPUMem: 320,
  },
  "p3.2xlarge": {
    name: "p3.2xlarge",
    GPU: 1,
    vCPU: 8,
    mem: 61,
    GPUMem: 16,
  },
  "p3.8xlarge": {
    name: "p3.8xlarge",
    GPU: 4,
    vCPU: 32,
    mem: 244,
    GPUMem: 64,
  },
  "p3.16xlarge": {
    name: "p3.16xlarge",
    GPU: 8,
    vCPU: 64,
    mem: 488,
    GPUMem: 128,
  },
  "p3dn.24xlarge": {
    name: "p3dn.24xlarge",
    GPU: 8,
    vCPU: 96,
    mem: 768,
    GPUMem: 256,
  },
  "p2.xlarge": {
    name: "p2.xlarge",
    GPU: 1,
    vCPU: 4,
    mem: 61,
    GPUMem: 12,
  },
  "p2.8xlarge": {
    name: "p2.8xlarge",
    GPU: 8,
    vCPU: 32,
    mem: 488,
    GPUMem: 96,
  },
  "p2.16xlarge": {
    name: "p2.16xlarge",
    GPU: 16,
    vCPU: 64,
    mem: 732,
    GPUMem: 192,
  },
  "g5.xlarge": {
    name: "g5.xlarge",
    GPU: 1,
    vCPU: 4,
    mem: 16,
    GPUMem: 24,
  },
  "g5.2xlarge": {
    name: "g5.2xlarge",
    GPU: 1,
    vCPU: 8,
    mem: 32,
    GPUMem: 24,
  },
  "g5.4xlarge": {
    name: "g5.4xlarge",
    GPU: 1,
    vCPU: 16,
    mem: 64,
    GPUMem: 24,
  },
  "g5.8xlarge": {
    name: "g5.8xlarge",
    GPU: 1,
    vCPU: 32,
    mem: 128,
    GPUMem: 24,
  },
  "g5.16xlarge": {
    name: "g5.16xlarge",
    GPU: 1,
    vCPU: 64,
    mem: 256,
    GPUMem: 24,
  },
  "g5.12xlarge": {
    name: "g5.12xlarge",
    GPU: 4,
    vCPU: 48,
    mem: 192,
    GPUMem: 96,
  },
  "g5.24xlarge": {
    name: "g5.24xlarge",
    GPU: 4,
    vCPU: 96,
    mem: 384,
    GPUMem: 96,
  },
  "g5.48xlarge": {
    name: "g5.48xlarge",
    GPU: 8,
    vCPU: 192,
    mem: 768,
    GPUMem: 192,
  },
  "g5g.xlarge": {
    name: "g5g.xlarge",
    GPU: 1,
    vCPU: 8,
    mem: 4,
    GPUMem: 16,
  },
  "g5g.2xlarge": {
    name: "g5g.2xlarge",
    GPU: 1,
    vCPU: 16,
    mem: 8,
    GPUMem: 16,
  },
  "g5g.4xlarge": {
    name: "g5g.4xlarge",
    GPU: 1,
    vCPU: 32,
    mem: 16,
    GPUMem: 16,
  },
  "g5g.8xlarge": {
    name: "g5g.8xlarge",
    GPU: 1,
    vCPU: 64,
    mem: 32,
    GPUMem: 16,
  },
  "g5g.16xlarge": {
    name: "g5g.16xlarge",
    GPU: 2,
    vCPU: 128,
    mem: 64,
    GPUMem: 32,
  },
  "g5g.metal": {
    name: "g5g.metal",
    GPU: 2,
    vCPU: 128,
    mem: 64,
    GPUMem: 32,
  },
  "g4dn.xlarge": {
    name: "g4dn.xlarge",
    GPU: 1,
    vCPU: 4,
    mem: 16,
    GPUMem: 16,
  },
  "g4dn.2xlarge": {
    name: "g4dn.2xlarge",
    GPU: 1,
    vCPU: 8,
    mem: 32,
    GPUMem: 16,
  },
  "g4dn.4xlarge": {
    name: "g4dn.4xlarge",
    GPU: 1,
    vCPU: 16,
    mem: 64,
    GPUMem: 16,
  },
  "g4dn.8xlarge": {
    name: "g4dn.8xlarge",
    GPU: 1,
    vCPU: 32,
    mem: 128,
    GPUMem: 16,
  },
  "g4dn.16xlarge": {
    name: "g4dn.16xlarge",
    GPU: 1,
    vCPU: 64,
    mem: 256,
    GPUMem: 16,
  },
  "g4dn.12xlarge": {
    name: "g4dn.12xlarge",
    GPU: 4,
    vCPU: 48,
    mem: 192,
    GPUMem: 64,
  },
  "g4dn.metal": {
    name: "g4dn.metal",
    GPU: 8,
    vCPU: 96,
    mem: 384,
    GPUMem: 128,
  },
  "g4ad.xlarge": {
    name: "g4ad.xlarge",
    GPU: 1,
    vCPU: 4,
    mem: 16,
    GPUMem: 8,
  },
  "g4ad.2xlarge": {
    name: "g4ad.2xlarge",
    GPU: 1,
    vCPU: 8,
    mem: 32,
    GPUMem: 8,
  },
  "g4ad.4xlarge": {
    name: "g4ad.4xlarge",
    GPU: 1,
    vCPU: 16,
    mem: 64,
    GPUMem: 8,
  },
  "g4ad.8xlarge": {
    name: "g4ad.8xlarge",
    GPU: 2,
    vCPU: 32,
    mem: 128,
    GPUMem: 16,
  },
  "g4ad.16xlarge": {
    name: "g4ad.16xlarge",
    GPU: 4,
    vCPU: 64,
    mem: 256,
    GPUMem: 32,
  },
  "g3s.xlarge": {
    name: "g3s.xlarge",
    GPU: 1,
    vCPU: 4,
    mem: 30.5,
    GPUMem: 8,
  },
  "g3.4xlarge": {
    name: "g3.4xlarge",
    GPU: 1,
    vCPU: 16,
    mem: 122,
    GPUMem: 8,
  },
  "g3.8xlarge": {
    name: "g3.8xlarge",
    GPU: 2,
    vCPU: 32,
    mem: 244,
    GPUMem: 16,
  },
  "g3.16xlarge": {
    name: "g3.16xlarge",
    GPU: 4,
    vCPU: 64,
    mem: 488,
    GPUMem: 32,
  },
};

export const CPU_INSTANCE_TYPES = [
  "2x2",
  "2x4",
  "2x8",
  "4x16",
  "8x32",
  "16x32",
];
