import { Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import Spinner from "components/UI-lib/Spinner";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import React, { useContext, useRef, useState } from "react";
import agent, { WorkspaceGroup } from "server";
import PaperClipIcon from "assets/img/svg/paperclipicon.svg?react";
import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import ArrowTopRightIcon from "assets/img/svg/arrowtopright.svg?react";
import { createDefaultAnalyticsObject } from "components/Analytics";

interface ConnectIndividualCloudProps {
  show: boolean;
}

const ConnectAWS: React.FC<ConnectIndividualCloudProps> = ({ show }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [GCPJson, setGCPJson] = useState("");
  const orgContext = useContext(OrgContext);
  const { me } = useContext(UserContext);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertAction, setInlineAlertAction] = useState(<></>);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "info" | "error" | "success" | "warning"
  >("info");

  const connectAWS = async (id: string) => {
    const res = await agent.Organizations.connectCloud(id);
    if (res.success) {
      // open a new tab to connect to AWS
      window.open(res.data, "_blank");
      console.log(res);
    } else {
      console.error(res.message);
    }
  };

  const intendedToConnectAWS = async () => {
    const res = await agent.Users.update(
      me?.id || "",
      createDefaultAnalyticsObject(me, {
        attemptedToConnectCloud: true,
        attemptedToConnectAWS: true,
      })
    );

    console.log(res);
  };

  return (
    <Transition
      className="w-full"
      show={show}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <FlatCard noBorder className="mt-3">
        <div className="min-w-fit-content items-center">
          <h3 className="font-medium dark:text-white">
            Connect your AWS Account
          </h3>
          <p className="my-2 text-sm text-gray-700 dark:text-secondary">
            Click the button below to deploy a Cloudformation stack in your
            account. It gives Brev limited permissions to create and modifying
            EC2 instances, which you'll be able to confirm before deploying.
          </p>
          <Button
            label="Deploy CloudFormation Stack"
            type="primary"
            iconLeft={<ArrowTopRightIcon className="h-5 w-5 mr-2" />}
            onClick={() => {
              intendedToConnectAWS();
              connectAWS(orgContext.activeOrgId);
            }}
          />
        </div>
      </FlatCard>
    </Transition>
  );
};

export default ConnectAWS;
