import React from "react";
import {
  ArrowUpRightIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import Button from "components/UI-lib/Button";
import { FlatCard } from "components/UI-lib";
import { Nim } from ".";

interface NimProps {
  data: Nim;
  onViewPlayground: () => void;
  onDeployNIM: () => void;
}

const NimCard: React.FC<NimProps> = ({
  data,
  onViewPlayground,
  onDeployNIM,
}) => {
  return (
    <FlatCard>
      <div className="flex flex-col">
        <div className="mb-1">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="flex flex-row items-end">
                <h1 className="text-md font-semibold text-gray-900 dark:text-white">
                  {data.title}
                </h1>
              </div>
              <div className="flex flex-row">
                <span className="text-xs text-gray-900 dark:text-secondary mr-2">
                  {data.description}
                </span>
              </div>
            </div>
            <div className="flex flex-row">
              <Button
                onClick={onViewPlayground}
                type="primary"
                className=""
                label="View in Playground"
              />
            </div>
          </div>
        </div>
      </div>
    </FlatCard>
  );
};

export default NimCard;
