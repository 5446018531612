import React from "react";
import {
  ArrowUpRightIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import Button from "components/UI-lib/Button";
import { FlatCard } from "components/UI-lib";
import { Blueprint } from ".";

interface FlatCardProps {
  data: Blueprint;
  onViewArchitecture: () => void;
  onDeployBlueprint: () => void;
}

const AgentBlueprintCard: React.FC<FlatCardProps> = ({
  data,
  onViewArchitecture,
  onDeployBlueprint,
}) => {
  return (
    <FlatCard>
      <div className="flex flex-col">
        <div className="mb-1">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="flex flex-row items-end">
                <h1 className="text-md font-semibold text-gray-900 dark:text-white">
                  {data.title}
                </h1>
              </div>
              <div className="flex flex-row">
                <span className="text-xs text-gray-900 dark:text-secondary mr-2">
                  {data.description}
                </span>
                {/*
                <div
                  className="flex text-highlight cursor-pointer"
                  onClick={() => {
                    window.open(data.learnMoreRedirect, "_blank");
                  }}
                >
                  <span className="text-xs">Learn About Use Cases</span>
                  <ArrowUpRightIcon className="h-4 w-4" />
                </div>
                */}
              </div>
            </div>
            <div className="flex flex-row">
              <Button
                onClick={onViewArchitecture}
                type="secondary"
                className="mr-3"
                label="View Architecture"
                iconRight={
                  <DocumentMagnifyingGlassIcon className="w-5 h-5 ml-1" />
                }
              />
              <Button
                onClick={onDeployBlueprint}
                type="primary"
                className="mr-3"
                label="Deploy Blueprint"
                iconRight={<ArrowUpRightIcon className="ml-1 w-4 h-4" />}
              />
            </div>
          </div>
        </div>
        <hr className="w-full my-3 dark:border dark:border-zinc-800" />
        <div className="flex flex-col mb-3 gap-2">
          <div className="flex flex-row gap-3">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <span className="text-xs font-bold text-gray-900 dark:text-secondary mr-1">
                  Hardware Requirements:
                </span>
                <span className="text-xs text-gray-900 dark:text-secondary">
                  {data.hardwareRequirements}
                </span>
              </div>
              <div className="flex">
                <span className="text-xs font-bold text-gray-900 dark:text-secondary mr-1">
                  OS Requirements:
                </span>
                <span className="text-xs text-gray-900 dark:text-secondary">
                  {data.osRequirements}
                </span>
              </div>
              <div className="flex">
                <span className="text-xs font-bold text-gray-900 dark:text-secondary mr-1">
                  Included NIM:
                </span>
                {data.includedSoftware.map((software, index) => (
                  <span
                    key={index}
                    className="text-xs text-gray-900 dark:text-secondary mx-1"
                  >
                    {software}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex"></div>
          </div>
        </div>
      </div>
    </FlatCard>
  );
};

export default AgentBlueprintCard;
