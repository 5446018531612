import React from "react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import PortEntries from "components/Environment/Create/PortEntries";
import DevToggle from "components/DevToggle";
import { allowedImageList } from "components/Environment/Create/utils";
import ToggleButtons from "components/UI-lib/ToggleButtons";
import Toggle from "components/UI-lib/Toggle";

interface BaseImageSelectorProps {
  baseImage: string;
  setBaseImage: (baseImage: string) => void;
  namePortMapping: Record<string, string>;
  setNamePortMapping: (
    value:
      | Record<string, string>
      | ((prev: Record<string, string>) => Record<string, string>)
  ) => void;
  vmOnlyMode: boolean;
  setVmOnlyMode: (vmOnlyMode: boolean) => void;
}

export const BaseImageSelector: React.FC<BaseImageSelectorProps> = ({
  baseImage,
  setBaseImage,
  namePortMapping,
  setNamePortMapping,
  vmOnlyMode,
  setVmOnlyMode,
}) => {
  return (
    <div className="flex flex-col w-1/2 ml-1">
      <div className="mb-5">
        <Toggle
          label="VM Only Mode"
          checked={vmOnlyMode}
          onChange={() => setVmOnlyMode(!vmOnlyMode)}
          description="Prevents an auto-build of a container for this environment"
        />
      </div>

      <Dropdown
        label="Custom Base Image"
        value={baseImage ? baseImage : "None"}
        onChange={(image) => setBaseImage(image)}
        disabled={vmOnlyMode}
      >
        {allowedImageList.map((image) => (
          <DropdownItem
            displayValue={
              image.DisplayName +
              (image.Image && image.Image !== "None" ? ` (${image.Image})` : "")
            }
            value={image.Image}
            key={image.Image}
          />
        ))}
      </Dropdown>
      {/* <div className="flex flex-row items-center mt-2">
        <div className="block text-sm font-medium text-gray-700 dark:text-secondary mb-1 mt-4">
          Expose a service
        </div>
      </div> */}
      <DevToggle>
        <PortEntries
          namePortMapping={namePortMapping}
          onAddEntry={(name, port) => {
            setNamePortMapping((prev) => ({ ...prev, [name]: port }));
          }}
          onRemoveEntry={(name) => {
            setNamePortMapping((prev) => {
              const newState = { ...prev };
              delete newState[name];
              return newState;
            });
          }}
        />
      </DevToggle>
    </div>
  );
};
