import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "components/UI-lib/Button";
import poof from "../../confetti";

interface Props {
  children: React.ReactNode;
}
const High5: React.FC<Props> = ({ children }) => {
  const [numClicks, setNumClicks] = React.useState(0);

  React.useEffect(() => {
    const addClick = (e: any) => {
      if (e.keyCode === 53) {
        setNumClicks(numClicks + 1);
      }
    };

    document.addEventListener("keydown", addClick, false);
    return () => {
      document.removeEventListener("keydown", addClick, false);
    };
  }, [numClicks]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (numClicks > 0) {
        setNumClicks(numClicks - 0.1);
      }
    }, 50);
    return () => clearTimeout(timer);
  }, [numClicks]);

  const showConfetti = () => {
    setNumClicks(0);
    poof(window.innerWidth, window.innerHeight);
  };

  return (
    <>
      <div>
        <div
          className="absolute left-[50%] duration-300"
          style={{ top: `${-6 + numClicks}%` }}
        >
          <Button
            label="High 5 👋"
            onClick={showConfetti}
            type="secondary"
            size-="medium"
            className="WorkspaceCreate-Button"
            style={{ marginRight: "10px" }}
          />
        </div>
      </div>
      {children}
    </>
  );
};

export default High5;
