import { classNames } from "components/utils";
import React, { useEffect, forwardRef, Ref } from "react";

interface ButtonAsATagProps {
  href: string;
}
export interface ButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  innerStyle?: object;
  loading?: boolean;
  // for 'hrefInNewTab' instead of button, load <a> tag and open in new tab
  hrefInNewTab?: string;
  onMouseOut?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => Promise<void> | void;
  onMouseOver?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => Promise<void> | void;
  size?: "large" | "medium" | "small";
  style?: object;
  type?: "primary" | "secondary" | "danger" | "warning" | "bold" | "nvidia";
  "data-testid"?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      disabled = false,
      iconLeft,
      iconRight,
      label,
      loading,
      onClick,
      hrefInNewTab,
      className = "",
      onMouseOut = () => {},
      onMouseOver = () => {},
      size = undefined,
      style = {},
      type = "primary",
      innerStyle = {},
    },
    ref
  ) => {
    // const handleClick = async (
    //   e: React.MouseEvent<HTMLDivElement, MouseEvent>
    // ) => {
    //   await onClick(e);
    // };

    const getClasses = (extraClasses: string) => {
      const primary =
        "inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-highlight hover:bg-highlighter dark:hover:bg-highlightLighter focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-zinc-900 focus:ring-highlight";
      const secondary =
        "inline-flex items-center px-4 border border-gray-300 dark:border-zinc-800 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-secondary bg-white dark:bg-zinc-900 hover:bg-gray-50 dark:hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-zinc-900 focus:ring-highlight";
      const danger =
        "inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-500 dark:bg-rose-600 hover:bg-rose-600 dark:hover:bg-rose-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-zinc-900 focus:ring-rose-500";
      const warning =
        "inline-flex items-center px-4  border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-zinc-900 focus:ring-yellow-500";
      const bold =
        "inline-flex items-center px-4 x border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-900 dark:bg-white hover:bg-slate-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-zinc-900 focus:ring-slate-700 dark:text-black";
      const nvidia =
        "inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#76B900] hover:bg-[#76B900] dark:hover:bg-[#76B900] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-zinc-900 focus:ring-[#76B900]";

      if (type === "warning") {
        return classNames(
          warning,
          extraClasses,
          disabled ? "opacity-50 pointer-events-none" : "",
          size === "small" ? "" : "py-2"
        );
      }
      if (type === "secondary") {
        return classNames(
          secondary,
          extraClasses,
          disabled ? "opacity-50 pointer-events-none" : "",
          size === "small" ? "" : "py-2"
        );
      }
      if (type === "danger") {
        return classNames(
          danger,
          extraClasses,
          disabled ? "opacity-50 pointer-events-none" : "",
          size === "small" ? "" : "py-2"
        );
      }
      if (type === "bold") {
        return classNames(
          bold,
          extraClasses,
          disabled ? "opacity-50 pointer-events-none" : "",
          size === "small" ? "" : "py-2"
        );
      }
      if (type === "nvidia") {
        return classNames(
          nvidia,
          extraClasses,
          disabled ? "opacity-50 pointer-events-none" : "",
          size === "small" ? "" : "py-2"
        );
      }

      return classNames(
        primary,
        extraClasses,
        disabled ? "opacity-50 pointer-events-none" : "",
        size === "small" ? "" : "py-2"
      );
    };

    if (hrefInNewTab) {
      return (
        <a
          className={getClasses(className)}
          href={hrefInNewTab}
          target="_blank"
          rel="noopener noreferrer"
          style={style}
        >
          {iconLeft && !loading && (
            <div className="Button__IconLeft">{iconLeft}</div>
          )}
          {label}
          {iconRight && <div className="Button__IconRight">{iconRight}</div>}
        </a>
      );
    }

    return (
      <button
        ref={ref}
        className={getClasses(className)}
        disabled={disabled}
        onClick={onClick}
        style={style}
      >
        {loading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill={type === "primary" ? "white" : "#0891b2"}
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}
        {iconLeft && !loading && (
          <div className="Button__IconLeft">{iconLeft}</div>
        )}
        {label}
        {iconRight && <div className="Button__IconRight">{iconRight}</div>}
      </button>
    );
  }
);

export default Button;
