import React, { useEffect, useState } from "react";
import BrevSlider from "../Slider";

interface MinimumRamFilterProps {
  selectedMinimumRam: string;
  setSelectedMinimumRam: (ram: string) => void;
}

const marks = [
  {
    value: 0,
    label: "Any",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 24,
    label: "24",
  },
  {
    value: 32,
    label: "32",
  },
  {
    value: 40,
    label: "64",
  },
  {
    value: 50,
    label: "120",
  },
  {
    value: 70,
    label: "250",
  },
  {
    value: 80,
    label: "300",
  },
  {
    value: 88,
    label: "500",
  },
  {
    value: 100,
    label: "1TiB",
  },
];

const convertSelectedRamToValue = (selectedRam: string) => {
  if (selectedRam === "any") {
    return 0;
  }
  if (selectedRam === "1TiB") {
    return 100;
  }
  const trueValue = marks.find((mark) => mark.label + "GiB" === selectedRam);
  return trueValue?.value || 0;
};

const MinimumRamFilter: React.FC<MinimumRamFilterProps> = ({
  selectedMinimumRam,
  setSelectedMinimumRam,
}) => {
  const [selectedSliderValue, setSelectedSliderValue] = useState(
    convertSelectedRamToValue(selectedMinimumRam)
  );

  useEffect(() => {
    setSelectedSliderValue(convertSelectedRamToValue(selectedMinimumRam));
  }, [selectedMinimumRam]);

  const onChange = (value: number) => {
    if (value === 0) {
      setSelectedMinimumRam("any");
    } else {
      const trueValue = marks.find((mark) => mark.value === value);
      if (trueValue && trueValue.label !== "1TiB") {
        setSelectedMinimumRam(trueValue?.label + "GiB");
      } else {
        setSelectedMinimumRam("1TiB");
      }
    }
    setSelectedSliderValue(value);
  };

  return (
    <>
      <div className="flex flex-col px-3">
        <BrevSlider
          value={selectedSliderValue}
          onChange={onChange}
          step={null}
          marks={marks}
          max={100}
        />
      </div>
    </>
  );
};

export default MinimumRamFilter;
