import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "components/utils";

export interface InlineNotificationProps {
  show: boolean;
  severity: "error" | "warning" | "info" | "success";
  text: string;
  text2?: any; // another line of text
  autoClose?: boolean;
  autoCloseTime?: number;
  onClose?: () => void;
  className?: string;
  hideCloseButton?: boolean;
  // USE THIS CAREFULLY. CHECK OTHER USAGE AND ENSURE IT LOOKS GOOD.
  children?: React.ReactNode;
}

const InlineNotification: React.FC<InlineNotificationProps> = ({
  show,
  severity,
  text,
  text2,
  onClose,
  autoClose = true,
  autoCloseTime = 3000,
  className = "",
  hideCloseButton = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(show);

  /**
   * If prop changes, reveal inline notification
   * */
  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  /**
   * If autoClose is enabled, automatically close toast after specified time
   * */
  useEffect(() => {
    if (isOpen && autoClose) {
      setTimeout(() => {
        setIsOpen(false);
      }, autoCloseTime);
    }
  }, [isOpen, autoClose, autoCloseTime]);

  const getIcon = (severity: string) => {
    const iconClass = "h-8 w-8"; // Increased size
    switch (severity) {
      case "error":
        return (
          <ExclamationCircleIcon
            className={`${iconClass} text-white`}
            aria-hidden="true"
          />
        );
      case "warning":
        return (
          <ExclamationTriangleIcon
            className={`${iconClass} text-white`}
            aria-hidden="true"
          />
        );
      case "info":
        return (
          <InformationCircleIcon
            className={`${iconClass} text-white`}
            aria-hidden="true"
          />
        );
      case "success":
        return (
          <CheckCircleIcon
            className={`${iconClass} text-white`}
            aria-hidden="true"
          />
        );
      default:
        return (
          <InformationCircleIcon
            className={`${iconClass} text-white`}
            aria-hidden="true"
          />
        );
    }
  };

  const getText = (weight: number) => {
    const textStyle = "text-lg";
    // const textStyle = "font-semibold text-lg";
    switch (severity) {
      case "error":
        return `${textStyle} text-white`; // Explicitly set to rose-700 for light mode
      case "warning":
        return `${textStyle} text-white`; // Working as expected
      case "info":
        return `${textStyle} text-white`; // Explicitly set to blue-700 for light mode
      case "success":
        return `${textStyle} text-white`; // Explicitly set to green-700 for light mode
      default:
        return `${textStyle} text-white`; // Default to blue-700 for light mode
    }
  };

  //   Note: templated strings dont work w/ tailwindcss
  //      `text-${}-50`
  const getBackground = (weight: number) => {
    if (severity === "error") {
      return `bg-rose-${weight} dark:bg-rose-400`;
    }
    if (severity === "warning") {
      return `bg-amber-${weight} dark:bg-amber-400`;
    }
    if (severity === "info") {
      return `bg-blue-${weight} dark:bg-blue-400`;
    }
    if (severity === "success") {
      return `bg-green-${weight} dark:bg-green-400`;
    }
    return `bg-blue-${weight} dark:bg-blue-400`;
  };

  const getButton = () => {
    if (severity === "error") {
      return "inline-flex rounded-md bg-rose-400 p-1.5 text-white hover:bg-rose-300 focus:outline-none focus:ring-2 focus:ring-rose-600 focus:ring-offset-2 focus:ring-offset-rose-50";
    }
    if (severity === "warning") {
      return "inline-flex rounded-md bg-amber-400 p-1.5 text-white hover:bg-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2 focus:ring-offset-amber-50";
    }
    if (severity === "info") {
      return "inline-flex rounded-md bg-blue-400 p-1.5 text-white hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50";
    }
    if (severity === "success") {
      return "inline-flex rounded-md bg-green-400 p-1.5 text-white hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50";
    }
    return "inline-flex rounded-md bg-blue-400 p-1.5 text-white hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50";
  };

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classNames("rounded-md p-4", className, getBackground(400))}
      >
        <div className="flex items-center">
          <div className="flex-shrink-0">{getIcon(severity)}</div>
          <div className="ml-3">
            <p className={`text-sm font-medium ${getText(800)}`}>{text}</p>
          </div>

          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              {hideCloseButton ? null : (
                <button type="button" className={getButton()} onClick={onClose}>
                  <span className="sr-only">Dismiss</span>
                  <XCircleIcon className="h-8 w-8" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
        </div>
        {text2 && text2}
        {children && (
          <div className="flex">
            <div className="invisible">{getIcon(severity)}</div>
            <div className="ml-3">
              <div className={getText(800)}>{children}</div>
            </div>
          </div>
        )}
      </div>
    </Transition>
  );
};

export default InlineNotification;
