import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import Spinner from "../UI-lib/Spinner";
import Workspace from "../../entities/Workspace.entity";
import { VerbBuildStatus } from "models/Workspace.model";
import { VERB_FINISHED_PHRASE } from "../Environment/Settings/utils";
import { classNames, timeout } from "../utils";

interface TerminalProps {
  logs: string[];
}

export const Terminal: React.FC<TerminalProps> = ({ logs }) => {
  const [displayedLogs, setDisplayedLogs] = useState<string[]>([]);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < logs.length) {
        setDisplayedLogs((prevLogs) => [...prevLogs, logs[index]]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 500); // 500ms delay between logs

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, [logs]);

  return (
    <div className="bg-black text-white w-96 h-64 rounded-md overflow-hidden">
      <div className="bg-gray-800 p-2 flex items-center justify-between">
        <div className="text-gray-400">Terminal</div>
        <div className="space-x-2">
          <span className="bg-red-500 w-3 h-3 block rounded-full"></span>
          <span className="bg-yellow-400 w-3 h-3 block rounded-full"></span>
          <span className="bg-green-400 w-3 h-3 block rounded-full"></span>
        </div>
      </div>
      <div className="p-4 overflow-y-auto h-52">
        {displayedLogs.map((log, index) => (
          <div key={index}>{log}</div>
        ))}
      </div>
    </div>
  );
};

interface LogDisplayProps {
  retroMode?: boolean;
  logFileContent: string;
  workspace: Workspace | null;
  initialIsMinimized?: boolean;
}

const LogDisplay: React.FC<LogDisplayProps> = ({
  retroMode = false,
  logFileContent,
  workspace,
  initialIsMinimized = false,
}) => {
  const logContainerRef = useRef<HTMLDivElement>(null);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const [isMinimized, setIsMinimized] = useState(initialIsMinimized);
  const toggleMinimize = (e) => {
    e.stopPropagation();
    setIsMinimized(!isMinimized);
  };

  const allLines = useMemo(() => {
    const lines = logFileContent.split("\n");
    // Slice the last 1000 entries. Note: No reverse since you want them in original order
    return lines.slice(Math.max(lines.length - 1000, 0));
  }, [logFileContent]);

  useEffect(() => {
    if (!isMinimized) {
      scrollToBottom(userHasScrolled);
    }
  }, [userHasScrolled, isMinimized]);

  const scrollToBottom = async (userHasScrolled: boolean) => {
    if (logContainerRef.current && !userHasScrolled) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  };

  const handleScroll = () => {
    if (logContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = logContainerRef.current;
      if (scrollTop + clientHeight !== scrollHeight) {
        setUserHasScrolled(true);
      } else {
        setUserHasScrolled(false);
      }
    }
  };

  const baseClasses = retroMode
    ? "bg-black text-green-500 font-mono"
    : "bg-cyan-100 text-cyan-800 border-2 border-cyan-600 dark:bg-cyan-900 dark:text-cyan-200 dark:border-cyan-400 font-sans";
  const textSize = retroMode ? "text-base" : "text-sm";

  if (isMinimized) {
    return (
      <div
        className={
          baseClasses +
          " flex items-center justify-between p-2 rounded-lg w-full h-8"
        }
      >
        <div className="flex items-center text-cyan-600 text-md">
          {workspace && workspace.verbBuildStatus && (
            <>
              {workspace.verbBuildStatus === VerbBuildStatus.Completed ||
              logFileContent.includes(VERB_FINISHED_PHRASE) ? (
                <>
                  <span className="mr-2">Your instance is configured 🤙</span>
                </>
              ) : (
                <>
                  {workspace.verbBuildStatus ===
                  VerbBuildStatus.CreateFailed ? (
                    <>
                      {" "}
                      <span className="mr-2">
                        Your instance Failed to Build
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="mr-2">Your instance is building</span>
                      <Spinner type="secondary" />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <button
          onClick={toggleMinimize}
          className="text-cyan-600 hover:text-cyan-600"
        >
          View Logs
        </button>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        !isMinimized
          ? baseClasses + " p-2 rounded-lg w-full relative pt-1"
          : baseClasses +
              " flex items-center justify-between p-2 rounded-lg w-full h-8"
      )}
    >
      {isMinimized ? (
        <>
          <div className="flex items-center text-cyan-600 text-md">
            {workspace && workspace.verbBuildStatus && (
              <>
                {(workspace.verbBuildStatus === VerbBuildStatus.Completed &&
                  logFileContent.includes(VERB_FINISHED_PHRASE)) ||
                (workspace.verbBuildStatus === VerbBuildStatus.Completed &&
                  workspace.customContainer?.containerUrl !== "") ? (
                  <>
                    <span className="mr-2">Your instance is configured 🤙</span>
                  </>
                ) : (
                  <>
                    {workspace.verbBuildStatus ===
                    VerbBuildStatus.CreateFailed ? (
                      <>
                        {" "}
                        <span className="mr-2">
                          Your instance Failed to Build
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="mr-2">Your instance is building</span>
                        <Spinner type="secondary" />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <button
            onClick={toggleMinimize}
            className="text-cyan-600 hover:text-cyan-600"
          >
            View Logs
          </button>
        </>
      ) : (
        <div className="flex flex-row justify-between items-center mb-1">
          <div className="flex items-center text-cyan-600 text-md">
            {workspace && workspace.verbBuildStatus && (
              <>
                {(workspace.verbBuildStatus === VerbBuildStatus.Completed &&
                  logFileContent.includes(VERB_FINISHED_PHRASE)) ||
                (workspace.verbBuildStatus === VerbBuildStatus.Completed &&
                  workspace.customContainer?.containerUrl !== "") ? (
                  <>
                    <span className="mr-2">Your instance is configured 🤙</span>
                  </>
                ) : (
                  <>
                    {workspace.verbBuildStatus ===
                    VerbBuildStatus.CreateFailed ? (
                      <>
                        {" "}
                        <span className="mr-2">
                          Your instance Failed to Build
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="mr-2">Your instance is building</span>
                        <Spinner type="secondary" />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <button
            onClick={toggleMinimize}
            className="text-cyan-600 hover:text-cyan-800"
          >
            Hide Logs
          </button>
        </div>
      )}
      <div
        className={classNames(
          "bg-transparent h-64 p-1 rounded overflow-y-auto",
          isMinimized ? "hidden" : ""
        )}
        ref={logContainerRef}
        onScroll={handleScroll}
      >
        {allLines.map((log, idx) => (
          <div key={idx} className="flex items-center">
            {/* <span className="text-cyan-400 w-8 text-right pr-2 block font-mono">
              {idx + 1}
            </span> */}

            <div
              className={textSize + " whitespace-pre flex-1 ml-4 break-words"}
            >
              {log}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogDisplay;
