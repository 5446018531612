import React, { useState } from "react";
import AgentBlueprintCard from "./AgentBluePrintCard";
import { ArchitectureModal } from "./ArchitectureModal";
import pdfDiagram from "assets/img/png/blueprint/pdf-diagram.png";
import NimCard from "./NimCard";
import NemoCard from "./NemoCard";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";

export type Blueprint = {
  title: string;
  description: string;
  //learnMoreText: string;
  viewArchitectureText: string;
  deployBlueprintText: string;
  hardwareRequirements: string;
  osRequirements: string;
  includedSoftware: string[];
  diagramUrl: string;
  learnMoreRedirect: string;
  launchableLink: string;
};

export type Nim = {
  title: string;
  description: string;
  playgroundRedirect: string;
};

export type Nemo = {
  title: string;
  description: string;
  launchableLink: string;
};

const Blueprints: React.FC = () => {
  const [showArchitectureModal, setShowArchiectureModal] =
    useState<boolean>(false);
  const [currentBluePrintData, setCurrentBluePrintData] = useState<Blueprint>();

  const blueprintData: Blueprint[] = [
    {
      title: "Multimodal PDF Data Extraction for Enterprise RAG",
      description:
        "Ingest & extract highly accurate insights from text, graphs, charts, and tables within massive volumes of PDF documents.",
      //learnMoreText: "Learn About Use Cases",
      viewArchitectureText: "View Architecture",
      deployBlueprintText: "Deploy Blueprint",
      hardwareRequirements: "2x A100 80GB",
      osRequirements: "Ubuntu 20.04/22.04",
      includedSoftware: [
        "google-deplot, nv-rerankqa-mistral-4b-v3, nv-embedqa-e5-v5, parakeet-ctc-1.1b-asr, audio2face",
      ],
      diagramUrl: pdfDiagram,
      learnMoreRedirect:
        "https://build.nvidia.com/nvidia/digital-humans-for-customer-service/blueprintcard",
      launchableLink: `${
        isKasAuthFlow
          ? CONSOLE_1_KAS_DEPLOYMENT_URL
          : CONSOLE_1_AUTH0_DEPLOYMENT_URL
      }/launchable/deploy?launchableID=env-2nTyhfMGFMPlRGqL9Ehp71TVI83`,
    },
    {
      title: "Vulnerability Analysis for Container Security",
      description:
        "Rapidly identify and mitigate container security vulnerabilities with generative AI.",
      //learnMoreText: "Learn About Use Cases",
      viewArchitectureText: "View Architecture",
      deployBlueprintText: "Deploy Blueprint",
      hardwareRequirements: "4x A100 80GB",
      osRequirements: "Ubuntu 20.04/22.04",
      includedSoftware: ["llama3-70b-instruct"],
      diagramUrl: pdfDiagram,
      learnMoreRedirect:
        "https://build.nvidia.com/nvidia/vulnerability-analysis-for-container-security",
      launchableLink: `${
        isKasAuthFlow
          ? CONSOLE_1_KAS_DEPLOYMENT_URL
          : CONSOLE_1_AUTH0_DEPLOYMENT_URL
      }/launchable/deploy?launchableID=env-2nTyhfMGFMPlRGqL9Ehp71TVI83`,
    },
  ];

  const NimData: Nim[] = [
    {
      title: "llama-3.1-405b-instruct",
      description: "meta",
      playgroundRedirect:
        "https://build.nvidia.com/meta/llama-3_1-405b-instruct",
    },
    {
      title: "nemotron-4-340b-reward",
      description: "nvidia",
      playgroundRedirect:
        "https://build.nvidia.com/nvidia/nemotron-4-340b-reward",
    },
    {
      title: "mistral-nemo-12b-instruct",
      description: "nv-mistralai",
      playgroundRedirect:
        "https://build.nvidia.com/nv-mistralai/mistral-nemo-12b-instruct",
    },
    {
      title: "llama-3.2-3b-instruct",
      description: "meta",
      playgroundRedirect: "https://build.nvidia.com/meta/llama-3.2-3b-instruct",
    },
  ];

  const NemoData: Nemo[] = [
    {
      title: "Exploring NeMo Fundamentals",
      description: "Learn how to get started with the NeMo framework",
      launchableLink: `${
        isKasAuthFlow
          ? CONSOLE_1_KAS_DEPLOYMENT_URL
          : CONSOLE_1_AUTH0_DEPLOYMENT_URL
      }/launchable/deploy/now?userID=p2mzt91a8&orgID=toxbnhrwu&name=llama-3_1-finetune-deploy&instance=A100%40a2-ultragpu-1g%3Anvidia-a100-80gb%3A1&diskStorage=400&cloudID=GCP&baseImage=nvcr.io%2Fnvidia%2Fnemo%3A24.05.01&file=https%3A%2F%2Fgithub.com%2Fbrevdev%2Fnotebooks%2Fblob%2Fmain%2Fllama31_law.ipynb&launchableID=env-2jeVokEK44iJZzleTF8yKjt3hh7`,
    },
    {
      title: "Fine-tune Llama-3.1 with NeMo",
      description: "Create your own custom LoRA adapter on your data",
      launchableLink: `${
        isKasAuthFlow
          ? CONSOLE_1_KAS_DEPLOYMENT_URL
          : CONSOLE_1_AUTH0_DEPLOYMENT_URL
      }/launchable/deploy/now?userID=p2mzt91a8&orgID=toxbnhrwu&name=llama-3_1-finetune-deploy&instance=A100%40a2-ultragpu-1g%3Anvidia-a100-80gb%3A1&diskStorage=400&cloudID=GCP&baseImage=nvcr.io%2Fnvidia%2Fnemo%3A24.05.01&file=https%3A%2F%2Fgithub.com%2Fbrevdev%2Fnotebooks%2Fblob%2Fmain%2Fllama31_law.ipynb&launchableID=env-2jeVokEK44iJZzleTF8yKjt3hh7`,
    },
  ];

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
      <div className="px-4 sm:px-6 lg:px-8">
        <ArchitectureModal
          show={showArchitectureModal}
          setShow={setShowArchiectureModal}
          previewData={currentBluePrintData}
        />
        <div className="flex flex-col mb-6">
          <div className="flex mb-6">
            <div className="sm:flex-auto">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-white">
                  Showcase NVIDIA AI Blueprints
                </h1>
                <div className="flex">
                  <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                    NVIDIA AI Blueprints are reference workflows for canonical
                    generative AI use cases. Enterprises can modify Blueprints
                    using their business data and run their generative AI
                    applications across accelerated data centers and clouds.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
          </div>
          <div className="flex flex-col w-full gap-6">
            {blueprintData.map((blueprint) => (
              <AgentBlueprintCard
                data={blueprint}
                onViewArchitecture={() => {
                  setShowArchiectureModal(true);
                  setCurrentBluePrintData(blueprint);
                }}
                onDeployBlueprint={() => {
                  window.open(blueprint.launchableLink, "_blank");
                }}
              />
            ))}
          </div>
        </div>
        <hr className="w-[100%] mb-8 mt-8 dark:border dark:border-zinc-800" />
        <div className="flex flex-col">
          <div className="flex mb-6">
            <div className="sm:flex-auto">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Get Started with NVIDIA NIM™
                </h1>
                <div className="flex">
                  <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                    NVIDIA NIM™ provides containers to self-host GPU-accelerated
                    inferencing microservices for AI models across clouds, data
                    centers, & workstations.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
          </div>
          <div className="flex flex-col w-full  grid grid-cols-2 gap-2 sm:grid-cols-2">
            {NimData.map((nim) => (
              <NimCard
                data={nim}
                onViewPlayground={() => {
                  window.open(nim.playgroundRedirect, "_blank");
                }}
                onDeployNIM={() => {}}
              />
            ))}
          </div>
        </div>
        <hr className="w-[100%] mb-8 mt-8 dark:border dark:border-zinc-800" />
        <div className="flex flex-col ">
          <div className="flex mb-6">
            <div className="sm:flex-auto">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Customize with NVIDIA NeMo™
                </h1>
                <div className="flex">
                  <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                    NVIDIA NeMo™ is an end-to-end framework for developing
                    custom generative AI models, including LLMs, multimodal,
                    vision, and speech AI at scale.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
          </div>
          <div className="flex flex-col w-full grid grid-cols-2">
            {NemoData.map((nemo) => (
              <NemoCard
                data={nemo}
                onDeployLaunchable={() => {
                  window.open(nemo.launchableLink, "_blank");
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blueprints;
