import { ITunnel } from "models/Workspace.model";
import { api, AxiosRes, BaseRes } from "./config";

export enum SupportedServiceType {
  HTTP = "http",
}
export enum ServiceType {
  // ...(<any>SupportedServiceType), // Convert SupportedServiceType to an object
  // Note, we could've done the above but it requires "any" which circumvents type safety
  HTTP = "http",
  HTTPS = "https",
  SSH = "ssh",
  TCP = "tcp",
  RDP = "rdp",
}

export interface CreateApplicationRequest {
  name: string;
  serviceType: string;
  port: number;
}

export interface RemoveApplicationRequest {
  applicationName: string;
}

export interface AddUserToApplicationRequest {
  usernameOrEmail: string;
  applicationName: string;
}

export interface RemoveUserFromApplicationRequest {
  userAuthID: string;
  applicationName: string;
}

export interface ModifyApplicationRequest {
  applicationName: string;
}

export interface ModifyApplicationPublicityRequest {
  applicationName: string;
  public: boolean;
}

export type ApplicationRes = {
  data?: ITunnel;
} & AxiosRes &
  BaseRes;

export const Applications = {
  create: (wsId: string, args: CreateApplicationRequest) =>
    api.post(
      `/api/applications/create/${wsId}`,
      args
    ) as Promise<ApplicationRes>,
  remove: (wsId: string, args: RemoveApplicationRequest) =>
    api.post(
      `/api/applications/remove/${wsId}`,
      args
    ) as Promise<ApplicationRes>,
  addUser: (wsId: string, args: AddUserToApplicationRequest) =>
    api.post(
      `/api/applications/adduser/${wsId}`,
      args
    ) as Promise<ApplicationRes>,
  removeUser: (wsId: string, args: RemoveUserFromApplicationRequest) =>
    api.post(
      `/api/applications/removeuser/${wsId}`,
      args
    ) as Promise<ApplicationRes>,
  addApiKey: (wsId: string, args: ModifyApplicationRequest) =>
    api.post(
      `/api/applications/addapikey/${wsId}`,
      args
    ) as Promise<ApplicationRes>,
  revokeApiKey: (wsId: string, args: ModifyApplicationRequest) =>
    api.post(
      `/api/applications/revokeapikey/${wsId}`,
      args
    ) as Promise<ApplicationRes>,
  modifyPublicity: (wsId: string, args: ModifyApplicationPublicityRequest) =>
    api.post(
      `/api/applications/modifypublicity/${wsId}`,
      args
    ) as Promise<ApplicationRes>,
};
