import FlatCard from "components/UI-lib/FlatCard";
import React, { useContext, useRef, useState } from "react";
import InfoBox from "./HoverInfoBox";
import {
  DeployState,
  OneClickDeployContext,
} from "contexts/OneClickDeployContext";
import { useHistory } from "react-router-dom";
import { DarkModeContext } from "contexts/DarkModeContext";
import { classNames } from "components/utils";
import OneClickDeploy from "components/OneClickDeploy";
import { XCircleIcon } from "@heroicons/react/24/solid";

const BuildingInstancePreview: React.FC = () => {
  const [hoveredStep, setHoveredStep] = useState<number | null>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const oneClickDeployContext = useContext(OneClickDeployContext);
  //   const orgContext = useContext(OrgContext);
  const history = useHistory();
  const { darkMode } = useContext(DarkModeContext);

  const handleMouseEnter = (index: number) => {
    // Clear any existing timeout to prevent it from hiding the InfoBox
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    // Set the hovered step immediately
    setHoveredStep(index);
  };

  // Function to start a timeout to hide the InfoBox
  const handleMouseLeave = () => {
    if (!hoverTimeoutRef.current) {
      hoverTimeoutRef.current = setTimeout(() => {
        setHoveredStep(null);
      }, 300); // 300ms delay before hiding the InfoBox
    }
  };

  // Function to clear the timeout when entering the InfoBox
  const handleInfoBoxEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
  };

  // Function to clear the timeout when entering a new list item
  const handleListItemEnter = (index: number) => {
    // Clear any existing timeout to prevent it from hiding the InfoBox
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    // Set the hovered step immediately
    setHoveredStep(index);
  };

  const getStateName = (deployState: DeployState) => {
    switch (deployState) {
      case DeployState.Deploying:
        return "Deploying";
      case DeployState.Dependencies:
        return "Building";
      case DeployState.Ready:
        return "Ready";
      case DeployState.UNSET:
        return "";
      default:
        return "";
        break;
    }
  };

  return (
    <div className="mx-auto my-auto">
      <div
        onMouseEnter={() => handleListItemEnter(-1)}
        onMouseLeave={handleMouseLeave}
      >
        <FlatCard
          className={classNames(
            "cursor-pointer",
            oneClickDeployContext.activeStep === DeployState.Ready
              ? "border dark:border-cyan-700 border-cyan-700"
              : ""
          )}
          isLoading={oneClickDeployContext.activeStep !== DeployState.Ready}
        >
          <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono flex">
            1 Instance {getStateName(oneClickDeployContext.activeStep)}
            {oneClickDeployContext.activeStep === DeployState.Ready && (
              <span
                className="ml-2"
                onClick={() => oneClickDeployContext.reset()}
              >
                {" "}
                <XCircleIcon width={20} />{" "}
              </span>
            )}
          </p>
        </FlatCard>
      </div>

      {hoveredStep === -1 && (
        <div
          className={classNames(
            "absolute z-10  p-2 bg-white dark:bg-zinc-900 shadow-xl rounded-md text-sm text-gray-800 dark:text-secondary top-full  mt-2 border border-gray-300 dark:border-slate-700",
            darkMode ? "dark-background-texture" : "light-background-texture"
          )}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={() => handleMouseEnter(-1)}
        >
          <div>
            <OneClickDeploy showCaret={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildingInstancePreview;

const BuildingInstances = () => {
  return <>heyyooo</>;
};
