import React, { useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import { useFeatureFlagEnabled } from "posthog-js/react";
import DeploymentCreate from "./DeploymentCreate";

const DeploymentCreateWrapper: React.FC = () => {
  console.log("Route: /deployments/new");

  return (
    <>
      <DeploymentCreate />
    </>
  );
};

export default DeploymentCreateWrapper;
