import Modal from "components/Modals/Modal";
import CloudMultiplex from "components/OrgPages/ConnectCloud/CloudMultiplex";
import React, { useContext, useEffect, useState } from "react";
import Button from "components/UI-lib/Button";
import agent from "server";
import { OrgContext } from "contexts/OrgContext";
import SimpleTransition from "components/SimpleTransition";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { UserContext } from "contexts/UserContext";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import { allPublicCloudsName } from "components/Environment/Create/utils";
import { DarkModeContext } from "contexts/DarkModeContext";

export interface ConnectCloudsOrCardModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
  setSelectedCloudName: (cloud: string) => void;
  selectedCloudName: string;
  setNoCardOnFile: (isFree: boolean) => void;
  noCardOnFile?: boolean;
}

const ConnectCloudsOrCardModal: React.FC<ConnectCloudsOrCardModalProps> = ({
  show,
  setShow,
  onCancel = () => {},
  onSuccess = () => {},
  setSelectedCloudName,
  selectedCloudName,
  setNoCardOnFile,
}) => {
  const [cardLoading, setCardLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    const loadClientSecret = async () => {
      const res = await agent.Billing.getClientSecret(orgContext.activeOrgId);
      if (res.success && res.data) {
        setClientSecret(res.data.clientSecret);
      }
    };
    // getPaymentMethods();
    loadClientSecret();
  }, [orgContext.activeOrgId]);

  const handleSubmitCreditCard = async () => {
    setCardLoading(true);
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      setCardLoading(false);
      return;
    }
    if (!clientSecret) {
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        "Unable to connect to stripe. Try reloading, and if that doesn't work, contact support"
      );
      setCardLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setCardLoading(false);
      return;
    }

    const res = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        metadata: {
          userId: userContext.me?.id || "",
          orgId: orgContext.activeOrgId,
        },
      },
    });

    if (res.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.error(res.error.message);
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        "Unable to submit credit card details. Please try again or contact support"
      );
      agent.Brevent.track({
        eventName: "Card Details Failed To Capture",
        userId: userContext.me?.id,
        properties: {
          orgId: orgContext.activeOrgId,
          error: res.error.message,
        },
      });
    } else {
      agent.Brevent.track({
        eventName: "Added Card",
        userId: userContext.me?.id,
        properties: {
          orgId: orgContext.activeOrgId,
        },
      });
      setNoCardOnFile(false);
      cardElement.clear();
      onSuccess();
      // await getPaymentMethods();
      //   if (onComplete) await onComplete();
    }
    setCardLoading(false);
  };

  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => {
        setShow(false);
      }}
      // onSuccess={async () => {
      //   await onSuccess();
      //   setShow(false);
      // }}
      title={
        !!orgContext.workspaceGroups.find(
          (wsg) => wsg.tenantType === "isolated"
        )
          ? "Change Clouds or Connect credit card"
          : "Connect a cloud or credit card"
      }
      body={
        <div className="w-[450px] pb-10">
          {!!orgContext.workspaceGroups.find(
            (wsg) => wsg.tenantType === "isolated"
          ) ? (
            <>
              <p className="text-sm text-gray-500 mb-1">
                Select a different cloud instead of Brev Cloud ™️
              </p>
              <div className="w-1/2">
                <Dropdown
                  label="Cloud"
                  value={selectedCloudName}
                  onChange={(cloud) => setSelectedCloudName(cloud)}
                >
                  {[
                    ...orgContext.workspaceGroups
                      .filter((wsg) => wsg.tenantType === "isolated")
                      .map((wsg) => wsg.name),
                    allPublicCloudsName,
                  ].map((ws) => (
                    <DropdownItem displayValue={ws} value={ws} />
                  ))}
                </Dropdown>
              </div>
              <p className="text-xs text-gray-500 mt-1">
                * Brev.dev is free if you use your own cloud
              </p>
            </>
          ) : (
            <>
              <p className="text-sm text-gray-500 mb-2">
                Brev.dev is free if you connect your own cloud
              </p>
              <CloudMultiplex />
              <p className="text-xs text-gray-500 mt-2">
                *Already connected? Make sure it's selected in your hardware
              </p>
            </>
          )}
          {/* <hr className="mt-2"></hr> */}
          <div className="text-sm text-gray-500 mt-2 mb-2 ml-2 flex flex-row items-center justify-start">
            <hr className="w-[11px] mr-1"></hr>
            or
            <hr className="w-[11px] ml-1"></hr>
          </div>
          <p className="text-sm text-gray-500 mb-2">
            Connect a credit card and use Brev Cloud ™️
          </p>
          <InlineNotification
            className="mb-2"
            show={!!inlineAlertMessage}
            severity={inlineAlertSeverity || "info"}
            text={inlineAlertMessage}
            autoClose={false}
            onClose={() => setInlineAlertMessage("")}
          />
          <SimpleTransition show={true}>
            <div className="flex flex-row w-full">
              <div className="w-[90%]">
                <CardElement
                  options={{
                    style: {
                      base: {
                        color: darkMode ? "#cffafe" : "black",
                      },
                    },
                    classes: {
                      base: "rounded-md border-gray-300 dark:border-zinc-800 shadow-sm p-3 sm:text-sm stripe-border",
                      focus: "border-highlight ring-cyan-500",
                    },
                  }}
                />
              </div>
              {/* <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse col-span-4"> */}
              <Button
                className="ml-3"
                label="Save"
                type="primary"
                disabled={cardLoading}
                loading={cardLoading}
                onClick={() => {
                  handleSubmitCreditCard();
                }}
              />
              {/* </div> */}
            </div>
          </SimpleTransition>
        </div>
      }
      //   disableSuccess={name === "" || port === ""}
      confirmLabel="Create"
    />
  );
};

export default ConnectCloudsOrCardModal;
