import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";

export interface Price {
  currency: string;
  pricePerUnit: number;
  unit: string;
}

export interface ComputePricingObject {
  instance_type: string;
  operating_system: string;
  location: string;
  onDemandPrice: Price;
  spotPrice: Price;
}

export const parseComputePricing = (
  pricing: any
): { [type: string]: ComputePricingObject } => {
  const pricingItems = pricing.items;
  const outputDict = {};
  if (pricingItems) {
    pricingItems.forEach((item: any) => {
      const computePricingObject = parsePricingItem(item);
      outputDict[computePricingObject.instance_type] = computePricingObject;
    });
  }
  return outputDict;
};

export const parsePricingItem = (item: any): any => {
  const onDemandPrice = {
    currency:
      item.compute_instance_product.prices.on_demand.price_per_unit.currency,
    pricePerUnit:
      item.compute_instance_product.prices.on_demand.price_per_unit.amount,
    unit: item.compute_instance_product.prices.on_demand.unit,
  };
  // console.log("On Demand Price: ", onDemandPrice)
  const spotPricesList = item.compute_instance_product.prices.spot;
  let spotPrice;
  if (spotPricesList && spotPricesList.length > 0) {
    spotPrice = {
      currency: spotPricesList[0].latest_price.price_per_unit.currency,
      pricePerUnit: spotPricesList[0].latest_price.price_per_unit.amount,
      unit: spotPricesList[0].latest_price.unit,
    };
  }
  const computePricingObject = {
    instance_type: item.compute_instance_product.attrs.instance_type,
    location: item.compute_instance_product.attrs.location,
    onDemandPrice,
    spotPrice,
  };
  return computePricingObject;
};

export const getLowestPriceComputeObject = (
  instanceType: string,
  location: string,
  cloudId: string,
  allRegionsPricing: any
): any => {
  const computeObject =
    allRegionsPricing[cloudId + "~" + location]?.[instanceType];
  if (!!computeObject) {
    return computeObject;
  }
  return null;
};

export const sortByLowestRegion = (
  instanceType: string,
  locations: any[],
  cloudId: string,
  allRegionsPricing: any,
  spot: boolean,
  allInstances: GPUInstanceType[]
): any[] => {
  let lowestRegion: any[] = [];
  for (const location of locations) {
    const instanceObject =
      allRegionsPricing[cloudId + "~" + location.name]?.[instanceType];
    if (instanceObject) {
      if (spot && instanceObject.spotPrice) {
        lowestRegion.push({
          price: instanceObject.spotPrice.pricePerUnit,
          location: location,
        });
      } else {
        lowestRegion.push({
          price: instanceObject.onDemandPrice.pricePerUnit,
          location: location,
        });
      }
    } else {
      const instanceObject = allInstances.find((i) => i.type === instanceType);
      if (instanceObject) {
        lowestRegion.push({
          price: instanceObject.default_price,
          location: location,
        });
      } else {
        lowestRegion.push({ price: 0, location: location });
      }
    }
  }
  lowestRegion = lowestRegion
    .sort((a, b) => a.price - b.price)
    .map((region) => region.location);
  return lowestRegion || [];
};

export const joinOldAndNewPricings = (
  oldPricing: {
    [type: string]: ComputePricingObject;
  },
  newPricing: { [type: string]: ComputePricingObject }
): { [type: string]: ComputePricingObject } => {
  const result = { ...oldPricing };
  for (const [type, pricingObject] of Object.entries(newPricing)) {
    if (result[type]) {
      result[type] = { ...result[type], ...pricingObject };
    } else {
      result[type] = pricingObject;
    }
  }
  return result;
};

export const getInstanceTypeCostProductApi = (
  instanceType: any,
  prices: {
    [type: string]: ComputePricingObject;
  },
  spot: boolean,
  allInstances: GPUInstanceType[]
): number => {
  const instanceObject = prices[instanceType];
  if (instanceObject) {
    if (spot && instanceObject.spotPrice) {
      return instanceObject.spotPrice.pricePerUnit;
    }
    return instanceObject.onDemandPrice.pricePerUnit;
  } else {
    const instanceObject = allInstances.find((i) => i.type === instanceType);
    if (instanceObject) {
      return Number(instanceObject.default_price);
    }
  }
  return 0;
};

export const prependZeroToCost = (cost: number): number => {
  if (!cost) return 0;
  const stringed = cost.toString();
  if (stringed.length > 0) {
    const firstChar = stringed[0];
    if (firstChar === ".") {
      const outputString = `0${stringed}`;
      return parseFloat(outputString);
    }
  }
  return parseFloat(stringed);
};

type SizeUnit = "B" | "KiB" | "MiB" | "GiB" | "TiB" | "PiB";

export function convertToBytes(sizeStr: string): number {
  const multipliers: { [unit in SizeUnit]: number } = {
    B: 1,
    KiB: 2 ** 10,
    MiB: 2 ** 20,
    GiB: 2 ** 30,
    TiB: 2 ** 40,
    PiB: 2 ** 50,
  };

  const regex = /(\d+)([a-zA-Z]+)/g;

  let totalBytes = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(sizeStr)) !== null) {
    const num = parseInt(match[1]);
    const unit = match[2];

    if (unit in multipliers) {
      totalBytes += num * multipliers[unit as SizeUnit];
    } else {
      throw new Error(`Invalid unit: ${unit}`);
    }
  }

  return totalBytes;
}

export function shiftElementToFront(array, element): any[] {
  const index = array.indexOf(element);
  if (index !== -1) {
    array.splice(index, 1); // remove element
    array.unshift(element); // add it to the front
  }
  return array;
}
