import React, { useContext, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import { allowedModelList } from "components/Deployments/Create/utils";
import AllowedModelListSelector from "./AllowedModelListSelector";
import { ModelSelected } from "../DeploymentCreate";
import { Button, InputField, FlatCard } from "components/UI-lib";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import agent, {
  Deployment,
  DeploymentInstanceType,
  DeploymentModelProvider,
} from "server";
import hfLogo from "assets/img/svg/hf-logo.svg";

export interface ModelSelectorProps {
  instanceTypes: DeploymentInstanceType[];
  setModel: (modelSelected: ModelSelected) => void;
}

const ModelSelector: React.FC<ModelSelectorProps> = ({
  setModel,
  instanceTypes,
}) => {
  const orgContext = useContext(OrgContext);
  const [selectionType, setSelectionType] = useState<"Huggingface" | "Nim">(
    "Huggingface"
  );
  const [customModelPath, setCustomModelPath] = useState<string>("");

  return (
    <>
      <div className="flex flex-col">
        <div className="flex">
          <h1 className="text-xl font-bold text-gray-900 dark:text-slate-100">
            Select a Model
          </h1>
        </div>
        <div className="flex flex-col mt-5">
          <div className="mt-4 grid grid-cols-1 gap-2 sm:grid-cols-2">
            <div
              onClick={() => {
                setSelectionType("Huggingface");
              }}
            >
              <div
                className={`bg-white dark:bg-zinc-900 rounded-md px-4 py-4 border border-2 border-dashed border-gray-500 ${
                  selectionType === "Huggingface"
                    ? "dark:border-cyan-400 border-cyan-400 text-cyan-400"
                    : "dark:border-zinc-800 border-gray-500 dark:text-secondary"
                } w-full min-h-[90px] cursor-pointer dark:hover:text-cyan-400 hover:text-cyan-400 hover:dark:border-highlight`}
                key="Default"
              >
                <div className="flex flex-col justify-center h-full">
                  <div className="flex flex-row items-center">
                    <img src={hfLogo} alt="Hugging Face" className="w-6 h-6" />
                    <div className="ml-1 text-md">HuggingFace Model</div>
                  </div>
                  <div className="text-xs">
                    Deploy any public model directly from HuggingFace
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                //setSelectionType("Nim");
              }}
            >
              <div
                className={`bg-white dark:bg-zinc-900 rounded-md px-4 py-4 border border-2 border-dashed border-gray-500 ${
                  selectionType === "Nim"
                    ? "dark:border-cyan-400 border-cyan-400 text-cyan-400"
                    : "dark:border-zinc-800 dark:text-secondary border-gray-500"
                } w-full min-h-[90px] cursor-pointer dark:hover:text-cyan-400 hover:text-cyan-400 hover:dark:border-highlight`}
                key="none"
              >
                <div className="flex flex-col justify-center h-full">
                  <div className="flex flex-row items-center">
                    <svg
                      width="24"
                      height="16"
                      viewBox="0 0 249 165"
                      className="mr-1"
                      fill="#76B900"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
                    </svg>
                    <div className="text-md">NVIDIA NIM</div>
                  </div>
                  <div className="text-xs">Coming soon!</div>
                </div>
              </div>
            </div>
          </div>
          <hr className="w-[100%] my-5 dark:border dark:border-zinc-800" />
          <div className="w-full">
            {selectionType === "Huggingface" && (
              <>
                <div className="flex flex-col w-full">
                  <h2 className="col-span-full text-lg font-semibold text-gray-900 dark:text-white mt-4 mb-2">
                    Specify a Model
                  </h2>
                  <div className="flex flex-row w-full">
                    <InputField
                      className="min-w-[400px]"
                      label=""
                      value={customModelPath}
                      onChange={(val) => {
                        setCustomModelPath(val);
                      }}
                      placeholder="nvidia/nemotron-3-8b-chat-4k-sft"
                      errorMessage=""
                    />
                  </div>
                </div>
                <>
                  <Button
                    label="Save"
                    className="mt-5"
                    disabled={customModelPath === ""}
                    onClick={() =>
                      setModel({
                        modelPath: customModelPath,
                        modelProvider:
                          selectionType == "Huggingface"
                            ? DeploymentModelProvider.HUGGING_FACE
                            : DeploymentModelProvider.UNSPECIFIED,
                      })
                    }
                  />
                </>
                {/* <div className="mt-5">
                  <AllowedModelListSelector
                    allowedModelList={allowedModelList}
                    setModel={setModel}
                  />
                </div> */}
              </>
            )}
            {selectionType === "Nim" && <></>}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelSelector;
