import Permission from "../entities/Permission.entity";
import { PermissionHierarchyType } from "models/Permission.model";
import { RoleIds } from "models/Role.model";
import React, { useEffect, useState } from "react";
import agent, { PermissionsRes } from "server";
import { permissionsContextDefaultValues } from "tests/defaultValues";
import { displayPermissionHierarchyType } from "../components/utils";

interface IPermissionsContext {
  permissions: Array<Permission>;
  setup: (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string
  ) => Promise<PermissionsRes>;
  clear: () => void;
  getPermissionsByHierarchy: (
    type: PermissionHierarchyType
  ) => Array<Permission>;
  createPermission: (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    role: RoleIds
  ) => Promise<PermissionsRes>;
  updatePermission: (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    role: RoleIds
  ) => Promise<PermissionsRes>;
  deletePermission: (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    permissionId: string
  ) => Promise<PermissionsRes>;
}

export const PermissionsContext = React.createContext<IPermissionsContext>(
  permissionsContextDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const PermissionsContextProvider: React.FC<Props> = ({ children }) => {
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const setup = async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string
  ): Promise<PermissionsRes> => {
    const res = await agent.Permissions.get(hierarchyType, hierarchyId);
    if (res.success) {
      const permissionsObjects = (res.data || []).map(
        (p) => new Permission(p, hierarchyType)
      );
      setPermissions(permissionsObjects);
    } else {
      console.error("Error in loadPermissions: ", res.message);
    }
    return res;
  };

  const clear = () => setPermissions([]);

  const getPermissionsByHierarchy = (
    hierarchyType: PermissionHierarchyType
  ): Array<Permission> =>
    permissions.filter((s) => s.hierarchyType === hierarchyType);

  const createPermission = async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    role: RoleIds
  ): Promise<PermissionsRes> => {
    if (permissions.find((p) => p.subjectId === subjectId)) {
      return {
        success: false,
        message: `User already has access to ${displayPermissionHierarchyType(
          hierarchyType
        )}.`,
      };
    }
    return await putPermission(hierarchyType, hierarchyId, subjectId, role);
  };

  const updatePermission = async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    role: RoleIds
  ): Promise<PermissionsRes> =>
    await putPermission(hierarchyType, hierarchyId, subjectId, role);

  const deletePermission = async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    permissionId: string
  ): Promise<PermissionsRes> => {
    const res = await agent.Permissions.delete(
      hierarchyType,
      hierarchyId,
      subjectId
    );
    if (res.success && res.data) {
      const newPermissionsList = permissions.filter(
        (s) => s.id !== permissionId
      );
      setPermissions(newPermissionsList);
    } else {
      console.error("Error in updateSecretName: ", res.message);
    }
    return res;
  };

  /** ***********************
   ******** HELPERS ********
   ************************ */

  const putPermission = async (
    hierarchyType: PermissionHierarchyType,
    hierarchyId: string,
    subjectId: string,
    role: RoleIds
  ): Promise<PermissionsRes> => {
    const res = await agent.Permissions.put(
      hierarchyType,
      hierarchyId,
      subjectId,
      role
    );
    if (res.success && res.data) {
      const permissionObjects = (res.data || []).map(
        (p) => new Permission(p, hierarchyType)
      );
      setPermissions(permissionObjects);
    } else {
      console.error("Error in putPermission: ", res.message);
    }
    return res;
  };

  const providerData = {
    permissions,
    setup,
    clear,
    getPermissionsByHierarchy,
    createPermission,
    updatePermission,
    deletePermission,
  };

  return (
    <PermissionsContext.Provider value={providerData}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsContextProvider;
