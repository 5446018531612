import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import config from "./config";
import { PostHogProvider } from "posthog-js/react";
import { DarkModeProvider } from "./contexts/DarkModeContext"; // Assuming you have exported the DarkModeProvider from DarkModeContext.tsx
import { BrowserRouter as Router } from "react-router-dom";
import initDataDogRUM from "./instrumentation/ddrum";
import FrontendTracer from "./instrumentation/otel";
import { ThemeProvider } from "contexts/ThemeContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { isKasAuthFlow } from "server/kas/utils";
import KasContextProvider from "contexts/KasContext";

const postHogOptions = {
  api_host: "https://app.posthog.com",
  ui_host: "https://us.posthog.com",
};

if (config.stage === "prod") {
  window.console.log = (): void => {};
  Sentry.init({
    dsn: "https://6d07dc60d21247b49f798d30d8963774@o410659.ingest.sentry.io/5284832",
  });
  postHogOptions.api_host = `${window.location.origin}/i`;
  initDataDogRUM(config);
} else {
  // TODO: make this more opt-in or disable after some failures.
  if (typeof window !== "undefined") FrontendTracer();
}

console.log("-🤙-", process.env.VITE_STARFLEET);

console.log("🤙🤙");

console.log("env", !!process.env.VITE_STARFLEET);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey="phc_PWWXIQgQ31lXWMGI2dnTY3FyjBh7gPcMhlno1RLapLm"
      options={postHogOptions}
    >
      {!isKasAuthFlow ? (
        <Auth0Provider
          domain={config.auth0Domain}
          clientId={config.auth0ClientId}
          audience={config.auth0Audience}
          redirectUri={`${window.location.origin}/redirecting-you-swiftly`}
          useRefreshTokens
          cacheLocation="localstorage"
        >
          <ThemeProvider>
            <DarkModeProvider>
              <Router>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </Router>
            </DarkModeProvider>
          </ThemeProvider>
        </Auth0Provider>
      ) : (
        <ThemeProvider>
          <DarkModeProvider>
            <Router>
              <QueryClientProvider client={queryClient}>
                <KasContextProvider>
                  <App />
                </KasContextProvider>
              </QueryClientProvider>
            </Router>
          </DarkModeProvider>
        </ThemeProvider>
      )}
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
