import { OrgContext } from "contexts/OrgContext";
// import { ToastContext } from "contexts/ToastContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { classNames } from "components/utils";
import Credits, { CreditsWrapper } from "./Credits";
import { BillingContext } from "contexts/BillingContext";
import { Coupons } from "components/OrgPages/Billing/Legacy-usage-arrears/Coupons";
import useQueryParam from "useQueryParams";
import { ConnectCardWrapper } from "components/OrgPages/Billing/ConnectCard";
import BillingOverview from "./Overview";
import PaymentAndCredits from "./PaymentAndCredits";
import Payments from "./Payments";
import UsageSummary from "./UsageSummary";

interface LocationState {
  segment?: string;
}

const CreditsBillingPage: React.FC = () => {
  // const [organization, setOrganization] = useState<Organization | null>(null);
  // const [loading, setLoading] = useState(true);
  const { state } = useLocation<LocationState>();

  const orgContext = useContext(OrgContext);
  const billingRef = React.useRef<null | HTMLDivElement>(null);

  const [withCoupon, setWithCoupon] = useQueryParam("coupon", "");

  useEffect(() => {
    document.title = "Organization Billing | Brev.dev";
    if (state?.segment === "billing") {
      billingRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  if (orgContext.activeOrg) {
    return (
      <>
        <div className="flex flex-col mt-8">
          <BillingOverview />
        </div>
        <div className="flex flex-row mt-8">
          <div className="flex flex-col w-full">
            <PaymentAndCredits withCoupon={withCoupon} />
          </div>
        </div>
        <div className="mt-8">
          <Payments />
        </div>
        <div className="mt-8">
          <UsageSummary />
        </div>
      </>
    );
  }
  // when clicking the settings button, reponse time is so fast the seeing loading bars feels off
  return <div />;
};

export default CreditsBillingPage;
