import { useQuery } from "@tanstack/react-query";
import { prependKasUrl, KAS_HOST } from "../utils";

type TokenResponse = {
  token: string;
  requestStatus: {
    statusCode: string;
  };
};

export const useGetStarfleetToken = ({
  isKasAuthFlow,
}: {
  isKasAuthFlow: boolean;
}) => {
  const queryKey = "/token";
  return useQuery({
    queryFn: async (): Promise<TokenResponse> =>
      fetch(prependKasUrl(queryKey), {
        headers: { host: KAS_HOST },
        credentials: "include",
        method: "GET",
      }).then(async (response) => await response.json()),
    queryKey: [queryKey],
    refetchInterval: 14.5 * 60 * 1000, // 14.5 minutes in milliseconds (ID token is expired at 15 mins)
    enabled: isKasAuthFlow,
  });
};
