import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { Theme, themes } from "../themes";

interface ThemeContextType {
  theme: Theme;
  setTheme: (
    theme: "base" | "nvidia" | "accenture" | "superMicro" | "deloitte"
  ) => void;
  currentTheme: string;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<
    "base" | "nvidia" | "accenture" | "superMicro" | "deloitte"
  >("nvidia");

  useEffect(() => {
    document.documentElement.className = currentTheme + " dark";
  }, [currentTheme]);

  const value: ThemeContextType = {
    theme: themes[currentTheme],
    setTheme: setCurrentTheme,
    currentTheme,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};
