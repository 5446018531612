import { WorkspaceGroup } from "server";

export const getWorkspaceGroupObject = (
  workspaceGroupId: string,
  workspaceGroups: WorkspaceGroup[]
) => {
  const workspaceGroup = workspaceGroups.find(
    (workspaceGroup) => workspaceGroup.id === workspaceGroupId
  );
  return workspaceGroup;
};

export const VERB_FINISHED_PHRASE = "Your verb container is ready.";
export const VERB_FAILED_PHRASE = "Verb failed to start.";
