import React, { useContext, useEffect, useState } from "react";
import Cloud from "assets/img/svg/cloud.svg?react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import Button from "components/UI-lib/Button";
import agent, { WorkspaceGroup } from "server";
import { OrgContext } from "contexts/OrgContext";
import { NotificationContext } from "contexts/NotificationContext";
import { DarkModeContext } from "contexts/DarkModeContext";
import aws from "assets/img/png/aws.svg";
import awsWhite from "assets/img/png/awswhite.svg";
import gcp from "assets/img/png/gcp.svg";
import Spinner from "components/UI-lib/Spinner";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { error } from "console";
import LightModeLogo from "assets/img/svg/logo_light_mode.svg?react";
import DarkModeLogo from "assets/img/svg/logo_dark_mode.svg?react";

interface ConnectedCloudDetailsProps {
  //   connectedCard: ConnectedCard | null;
  //   isDefault?: boolean;
  wsg: WorkspaceGroup;
}

const ConnectedCloudDetails: React.FC<ConnectedCloudDetailsProps> = (props) => {
  const { activeOrgId } = useContext(OrgContext);
  const notificationContext = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(true);
  const { darkMode } = useContext(DarkModeContext);
  const [cloud, setCloud] = useState<"aws" | "gcp">("aws");
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [isDeprecating, setIsDeprecating] = useState(false);
  const orgContext = useContext(OrgContext);
  const [showErrorDetails, setShowErrorDetails] = useState(false);
  const [errorDetails, setErrorDetails] = useState("");
  // setup inline notification
  const notificaitonContext = useContext(NotificationContext);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");

  const getReadableTime = (timestamp: string) => {
    const readableTime = new Date(timestamp).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return readableTime;
  };

  const checkStatus = async () => {
    setCheckingStatus(true);
    try {
      const res = await agent.Organizations.getWorkspaceGroupRegions(
        props.wsg.id
      );
      console.log(res);
      if (res && res.success) {
        setStatus(true);
      } else {
        setErrorDetails(res.message);
        setStatus(false);
      }
      setCheckingStatus(false);
    } catch (error) {
      setStatus(false);
      setCheckingStatus(false);
    }
  };

  const getCloudIcon = (platformType: string, name: string) => {
    if (name === "Brev Cloud") {
      return (
        <>
          <LightModeLogo
            className="block h-8 w-8 dark:hidden"
            style={{ cursor: "pointer" }}
            // onClick={props.onClick}
          />
          <DarkModeLogo
            className="block h-8 w-8 hidden dark:block"
            style={{ cursor: "pointer" }}
            // onClick={props.onClick}
          />
        </>
      );
    }

    switch (platformType) {
      case "aws":
        return (
          <img
            src={darkMode ? awsWhite : aws}
            alt="AWS"
            className="h-5 w-7 mr-3 mt-1"
          />
        );

      case "gcp":
        return <img src={gcp} alt="GCP" className="h-5 w-7 mr-3 mt-1" />;
      default:
        return <Cloud className="w-[36px] h-[36px]" />;
    }
  };

  const getStatus = (isWorking: boolean) => {
    if (isWorking) {
      return (
        <div className="flex items-start">
          <CheckCircleIcon color="green" width={20} />
          <p className="text-gray-900 dark:text-secondary">Connected</p>
        </div>
      );
    } else {
      return (
        <div>
          <div className="flex items-start">
            <ExclamationCircleIcon color="red" width={20} />
            <p
              className="text-gray-900 dark:text-secondary underline"
              onClick={() => {
                setShowErrorDetails(!showErrorDetails);
              }}
            >
              {`Error. Click to ${showErrorDetails ? "hide" : "show"} details`}
            </p>
          </div>{" "}
          {showErrorDetails && (
            <p className="text-gray-900 dark:text-secondary">{errorDetails}</p>
          )}
        </div>
      );
    }
  };

  const deprecateWsg = async () => {
    setIsDeprecating(true);
    const res = await agent.Organizations.depracateWorkspaceGroup(props.wsg.id);
    if (res && res.success) {
      orgContext.refreshWorkspaceGroups();
      setInlineAlertMessage("Successfully deprecated cloud");
      setInlineAlertSeverity("success");
    } else {
      setInlineAlertMessage("Failed to deprecate cloud");
      setInlineAlertSeverity("error");
    }
    setIsDeprecating(false);
  };

  useEffect(() => {
    checkStatus();
  }, [props.wsg]);

  return (
    <div className="mt-3">
      <InlineNotification
        show={!!inlineAlertMessage}
        severity={inlineAlertSeverity}
        text={inlineAlertMessage}
        autoClose={false}
        onClose={() => setInlineAlertMessage("")}
      />
      <div className="mt-3 w-full flex flex-row justify-between items-center">
        <dd className="flex text-sm ml-1 flex-col">
          <div className="flex flex-row">
            <div>
              {getCloudIcon(props.wsg.platformType, props.wsg.name)}
              <p className="sr-only">{"aws"}</p>
            </div>
            <div className="ml-4">
              <p className="text-gray-900 dark:text-secondary">
                {props.wsg.name}
                {` `}
                {`${
                  props.wsg.tenantType !== "shared" ? `(${props.wsg.id})` : ""
                }`}
              </p>
              <p className="text-gray-500 dark:text-secondary">
                {`Added ${props.wsg.createdAt.split("T")[0]}, ${getReadableTime(
                  props.wsg.createdAt
                )}`}
              </p>
              <div className="flex flex-row">
                <p className="text-gray-900 dark:text-secondary mr-2">
                  Status:{" "}
                </p>{" "}
                {checkingStatus ? (
                  <>
                    <Spinner type="secondary" />
                  </>
                ) : (
                  <>{getStatus(status)}</>
                )}
              </div>
            </div>
          </div>
        </dd>

        {props.wsg.name !== "Brev Cloud" && (
          <div className="flex flex-col justify-center items-center">
            <Button
              label={"Deprecate"}
              type="secondary"
              onClick={deprecateWsg}
              loading={isDeprecating}
            />
          </div>
        )}
      </div>
      <hr className="mt-2 dark:border dark:border-zinc-800" />
    </div>
  );
};

export default ConnectedCloudDetails;
