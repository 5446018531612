import { Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import {
  classNames,
  displayPermissionHierarchyType,
  timeout,
} from "components/utils";
import { UserContext } from "contexts/UserContext";
import { PermissionHierarchyType } from "models/Permission.model";
import React, { act, useContext, useEffect, useMemo, useState } from "react";
import { BarList } from "../../../Graphs/BarList";
import {
  ArrowPathIcon,
  BanknotesIcon,
  EyeIcon,
  RocketLaunchIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { useHistory } from "react-router";
import LaunchableMeta from "./LaunchableMeta";
import { LaunchableOverview, LaunchableOverviewSkeleton } from "./Overview";
import agent, { LaunchableCloudPoints, LaunchableFromList } from "server";
import { OrgContext } from "contexts/OrgContext";
import InputField from "components/UI-lib/InputField";
import { LaunchableContext } from "contexts/LaunchableContext";
import { BillingContext } from "contexts/BillingContext";
import { motion } from "framer-motion";
import ShimmerButton from "components/UI-lib/Shimmer/ShimmerButton";
import EditModal from "./EditModal";

export interface UserMetrics {
  [userId: string]: {
    totalViews: number;
    totalDeploys: number;
  };
}

export interface LaunchablePoints {
  id: string;
  points: number;
}
export interface LaunchableData {
  id: string;
  name: string;
  rawURL?: string;
}

export function convertMetricsToList(
  userMetrics: UserMetrics,
  metricType: "views" | "deploys"
): {
  name: string;
  value: number;
}[] {
  return Object.entries(userMetrics)
    .map(([userId, metrics]) => ({
      name: userId,
      value: metricType === "views" ? metrics.totalViews : metrics.totalDeploys,
    }))
    .sort((a, b) => b.value - a.value);
}

const LaunchableList: React.FC = () => {
  console.log("Route: /org/:orgId/launchables");
  const history = useHistory();
  const orgContext = useContext(OrgContext);
  const launchableContext = useContext(LaunchableContext);
  const [searchValue, setSearchValue] = useState<string>("");
  const [launchablePoints, setLaunchablePoints] = useState<
    LaunchableCloudPoints[]
  >([]);

  const [editModal, setEditModal] = useState(false);
  const [currentLaunchableData, setCurrentLaunchableData] =
    useState<LaunchableData | null>(null);

  const handleEditLaunchableClick = (launchable: LaunchableData | null) => {
    setCurrentLaunchableData(launchable);
    setEditModal(true);
  };

  const displayLaunchables = useMemo(() => {
    return launchableContext.activelaunchables
      .filter((launchable) => {
        if (launchable.rawURL === "") return true;
        try {
          new URL(launchable.rawURL || "");
          return true;
        } catch (e) {
          console.log(e);
          return false;
        }
      })
      .filter((launchable) => {
        if (searchValue === "") return true;
        return launchable.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
  }, [launchableContext.activelaunchables, searchValue]);

  if (orgContext.activeOrg) {
    return (
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
        {/* <InitialTemplateModal /> */}
        <div className="px-4 sm:px-6 lg:px-8">
          {/* {workspaces.length === 0 && <Onboardings />} */}
          <div className="flex">
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-col">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Launchables
                </h1>
                <div className="flex">
                  <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                    Create seamless 1-click deploy environments, covering
                    hardware and software, with embedded metric capabilities
                  </p>
                </div>
              </div>
              {/* {billingContext.launchablePoints >= 500 && ( */}

              <FreeCreditsButton />
            </div>
          </div>
          <div className="flex mt-5">
            <div
              className="bg-primaryBg p-4 w-full flex flex-row rounded-md border-2 border-dashed border-gray-300 dark:border-zinc-800 min-h-[90px] cursor-pointer text-cyan-400 dark:text-highlight dark:hover:text-highlightLighter hover:text-cyan-300"
              onClick={() => {
                history.push("/launchables/create");
              }}
            >
              <div className="flex flex-row w-full justify-center items-center">
                <WrenchScrewdriverIcon className="h-6 w-6 mr-1" />
                <span>Create Launchable</span>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <LaunchableOverview
              launchables={launchableContext.activelaunchables}
              loading={launchableContext.loadingMetrics}
              deployData={launchableContext.overDeployData}
              viewData={launchableContext.overViewData}
              leaderboardData={convertMetricsToList(
                launchableContext.userMetricsMapping,
                "views"
              ).slice(0, 3)}
            />
          </div>
          <div className="flex flex-col mt-8">
            {launchableContext.activelaunchables.length === 0 ? (
              <>
                <div
                  className="flex flex-col relative cursor-pointer mt-8"
                  onClick={(e) => {
                    history.push("/launchables/create");
                    e.stopPropagation();
                  }}
                >
                  <div className="flex w-full justify-center justify-center">
                    <div className="flex flex-row items-center">
                      <span className="text-white font-bold">
                        No Launchables, Create Your First
                      </span>
                      <ArrowUpRightIcon className="h-5 w-5 text-white ml-1" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row items-end justify-between w-full">
                  <div className="sm:flex-auto">
                    <h1 className="text-lg font-semibold text-gray-900 dark:text-white">
                      Active Launchables
                    </h1>
                  </div>
                  <div>
                    <InputField
                      className="min-w-[300px] ml-5"
                      label=""
                      placeholder="Search Launchables"
                      value={searchValue}
                      onChange={(val) => {
                        setSearchValue(val);
                      }}
                      errorMessage=""
                    />
                  </div>
                </div>
                <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
                <div className="mt-3 flex flex-col">
                  <>
                    {displayLaunchables.length === 0 ? (
                      <FlatCard className="w-full">
                        <div className="flex flex-col items-center justify-center">
                          <h1 className="text-lg font-semibold text-gray-900 dark:text-white">
                            No Launchables Found
                          </h1>
                          <p className="text-sm text-gray-700 dark:text-secondary">
                            Try searching for a different launchable
                          </p>
                        </div>
                      </FlatCard>
                    ) : (
                      <>
                        {displayLaunchables.map((launchable, index) => (
                          <LaunchableMeta
                            id={launchable.id}
                            launchable={launchable}
                            views={
                              launchableContext.mergeMetricOnLaunchable(
                                launchable.id
                              ).views
                            }
                            deploys={
                              launchableContext.mergeMetricOnLaunchable(
                                launchable.id
                              ).deploys
                            }
                            loadingMetrics={launchableContext.loadingMetrics}
                            deleteLaunchable={
                              launchableContext.deleteLaunchable
                            }
                            setShowEditModal={() =>
                              handleEditLaunchableClick(launchable)
                            }
                          />
                        ))}
                      </>
                    )}
                  </>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};

export default LaunchableList;

export const FreeCreditsButton: React.FC = () => {
  const history = useHistory();
  const orgContext = useContext(OrgContext);
  const billingContext = useContext(BillingContext);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return billingContext.launchablePoints >= 5 && showButton ? (
    <ShimmerButton>
      <div
        className="flex h-full items-end cursor-pointer shimmer"
        onClick={() => {
          history.push(`/org/${orgContext.activeOrgId}/launchables/redeem`);
        }}
      >
        <div className="flex flex-row text-sm">
          <BanknotesIcon className="h-6 w-6 mr-1 text-white" />
          <h1 className="text-white font-semibold">You Earned Free Credits!</h1>
        </div>
      </div>
    </ShimmerButton>
  ) : null;
};
