import React, { Fragment, useContext, useEffect, useState } from "react";
import Spinner from "components/UI-lib/Spinner";
import agent from "server";
import IWorkspace from "models/Workspace.model";

const WorkspaceDeleter: React.FC<{
  workspace: IWorkspace;
  onStatusChange: (status: "idle" | "deleting" | "deleted" | "error") => void;
}> = ({ workspace, onStatusChange }) => {
  const [status, setStatus] = useState<
    "idle" | "deleting" | "deleted" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const deleteWorkspace = async () => {
    setStatus("deleting");
    try {
      const response = await agent.Workspaces.delete(workspace.id);
      if (response.success) {
        setStatus("deleted");
      } else {
        setStatus("error");
        setErrorMessage(response.message || "Failed to delete instance.");
      }
    } catch (error) {
      setStatus("error");
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("Failed to delete instance.");
      }
    }
  };

  useEffect(() => {
    deleteWorkspace();
  }, []);

  useEffect(() => {
    onStatusChange(status);
  }, [status]);

  return (
    <div>
      {status === "deleting" && (
        <>
          <div className="flex flex-row items-center justify-start">
            <Spinner type={"secondary"} />
            <p className="ml-2">Deleting instance {workspace.id}</p>
          </div>
        </>
      )}
      {status === "deleted" && `✅ deleted ${workspace.id}`}
      {status === "error" && (
        <div onClick={deleteWorkspace}>
          ❌ error: {errorMessage}. Click to retry.
        </div>
      )}
    </div>
  );
};

export default WorkspaceDeleter;

interface WorkspacesProps {
  workspaces: IWorkspace[];
}

export const WorkspacesGetDeleted: React.FC<WorkspacesProps> = ({
  workspaces: initialWorkspaces,
}) => {
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>(initialWorkspaces);
  const [deletingWorkspaces, setDeletingWorkspaces] = useState<IWorkspace[]>(
    []
  );
  const [workspaceStatuses, setWorkspaceStatuses] = useState<
    Record<string, "idle" | "deleting" | "deleted" | "error">
  >({});

  useEffect(() => {
    setWorkspaces(initialWorkspaces);
    setDeletingWorkspaces([]);
  }, [initialWorkspaces]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        workspaces.length > 0 &&
        deletingWorkspaces.every(
          (workspace) =>
            workspace && workspaceStatuses[workspace.id] !== "deleting"
        )
      ) {
        const newDeletingWorkspaces = workspaces.slice(0, 5);
        setDeletingWorkspaces((prev) => [...prev, ...newDeletingWorkspaces]);
        setWorkspaces((prev) => prev.slice(newDeletingWorkspaces.length));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [workspaces, deletingWorkspaces, workspaceStatuses]);

  const handleStatusChange = (
    id: string,
    status: "idle" | "deleting" | "deleted" | "error"
  ) => {
    setWorkspaceStatuses((prev) => ({ ...prev, [id]: status }));
  };

  return (
    <>
      {deletingWorkspaces.map(
        (workspace, index) =>
          workspace && (
            <WorkspaceDeleter
              key={index}
              workspace={workspace}
              onStatusChange={(status) =>
                handleStatusChange(workspace.id, status)
              }
            />
          )
      )}
      {workspaces.map((workspace, index) => (
        <div
          key={index}
          onClick={() => setDeletingWorkspaces((prev) => [...prev, workspace])}
        >
          Queued for deletion: {workspace.id}
        </div>
      ))}
    </>
  );
};
