import { PlayCircleIcon, CloudIcon } from "@heroicons/react/24/outline";
import Chip from "components/Environment/Create/chips";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { FlatCard } from "components/UI-lib";
import { classNames, roundPriceToTwoDigits } from "components/utils";
import React, { useState } from "react";

const DGXComputeCard: React.FC<{
  instance: GPUInstanceType;
  onClick: () => void;
  disabled?: boolean;
}> = ({ instance, onClick, disabled = false }) => (
  <div
    onClick={() => {
      onClick();
    }}
    className="w-full relative"
  >
    <FlatCard
      className={`${
        disabled ? "opacity-20" : "animate-border animate-border-once animate"
      } cursor-pointer`}
    >
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <Chip
              dgx={true}
              selected={true}
              chipName={instance.supported_gpus[0].name}
              manufacturerName={instance.supported_gpus[0].manufacturer}
              onClick={() => {}}
              className={classNames("h-[80px] mr-3 flex-shrink-0")}
            />
            <div className="flex flex-col ml-3">
              <div className="flex flex-row">
                <div className="flex items-center">
                  <div
                    className="text-md"
                    style={{ color: "hsl(46.67deg 90% 68.63%)" }}
                  >
                    {instance.supported_gpus[0].count}x{" "}
                    {instance.supported_gpus[0].manufacturer}{" "}
                    {instance.supported_gpus[0].name}
                  </div>
                  <div
                    className="text-md ml-3"
                    style={{ color: "hsl(46.67deg 30% 68.63%)" }}
                  >
                    {instance.supported_gpus[0].memory} VRAM
                  </div>
                </div>
                <div className="flex items-center ml-3">
                  <div
                    className="text-xs mt-1"
                    style={{ color: "hsl(46.67deg 30% 68.63%)" }}
                  >
                    {instance.memory} RAM x {instance.vcpu} CPUS
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center text-gray-900 dark:text-secondary mt-1">
                <div
                  className="flex flex-row items-center"
                  style={{ color: "hsl(46.67deg 30% 68.63%)" }}
                >
                  <CloudIcon className="h-6 w-6 mr-1" />
                  <h1 className="text-sm">NVIDIA DGX CLOUD</h1>
                </div>
                <div
                  className="flex flex-row items-center ml-2"
                  style={{ color: "hsl(46.67deg 30% 68.63%)" }}
                >
                  <PlayCircleIcon className="h-6 w-6 mr-1" />
                  <h1 className="text-sm">
                    $
                    {roundPriceToTwoDigits(
                      instance.base_price?.amount || "0.00"
                    )}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="btnBefore gold"></span>
      <span className="btnAfter gold"></span>
    </FlatCard>
    <span
      className={`absolute bottom-2 right-2 text-sm ${
        !instance.quota.reserved.current || instance.quota.reserved.current <= 0
          ? "text-red-400"
          : ""
      }`}
      style={
        instance.quota.reserved.current > 0
          ? { color: "hsl(46.67deg 30% 68.63%)" }
          : {}
      }
    >
      {instance.quota.reserved.current || 0} instances available
    </span>
  </div>
);

export default DGXComputeCard;
