import { useEffect, useRef } from "react";
import { POLL_INTERVAL } from "../constants/index";

/**
 *
 * This is used instead of a useInterval custom hook. Two main differences.
 * 1 - The polling effect can execute as soon as it is called (contrary to an interval which would trigger the first call only after the specified time has passed. We have an optional arg that enables a delay).
 * 2 - We can’t trigger a new call if the previous call hasn’t finalized, the polling hook must wait until the previous call was finalized while the interval shouldn’t care.
 *
 * Ref: https://pgarciacamou.medium.com/react-simple-polling-custom-hook-usepollingeffect-1e9b6b8c9c71
 * Modified for Typescript + optional delay on first execution
 *
 * @param {Function} asyncCallback
 * @param {React.DependencyList} dependencies
 * @param [optionalArgs] optionalArgs
 * Currently includes { interval: number, executeImmediately: boolean, initialDelay: number, onCleanup: Function }
 *
 * Example Usage:
 *
 * usePollingEffect(async () => {
 *   await agent.Workspaces.get(workspace.id)
 * }, [workspace.id]);
 *
 */
export const usePollingEffect = (
  asyncCallback: Function,
  dependencies: React.DependencyList = [],
  {
    interval = POLL_INTERVAL,
    executeImmediately = true,
    initialDelay = POLL_INTERVAL,
    onCleanUp = () => {},
  } = {}
) => {
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    let _stopped = false;

    const pollingCallback = async () => {
      try {
        await asyncCallback();
      } finally {
        // Set timeout after it finished, unless stopped
        if (!_stopped) {
          timeoutIdRef.current = setTimeout(pollingCallback, interval);
        }
      }
    };

    if (executeImmediately) {
      pollingCallback();
    } else {
      setTimeout(pollingCallback, initialDelay);
    }

    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent racing conditions
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      onCleanUp();
    };
    // For the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, interval, asyncCallback, onCleanUp]);
};

export function useQueryParams() {
  const params = new URLSearchParams(window ? window.location.search : {});

  return new Proxy(params, {
    get(target, prop) {
      return target.get(String(prop));
    },
  });
}
