import React, { createContext, useState, useEffect } from "react";

interface DarkModeContextProps {
  children?: React.ReactNode;
  darkMode: boolean;
  toggleDarkMode: () => void;
  turnOnDarkMode: () => void;
  turnOffDarkMode: () => void;
}

export const DarkModeContext = createContext<DarkModeContextProps>({
  darkMode: true,
  toggleDarkMode: () => {},
  turnOnDarkMode: () => {},
  turnOffDarkMode: () => {},
});

interface Props {
  children: React.ReactNode;
}
export const DarkModeProvider: React.FC<Props> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(true);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const turnOnDarkMode = () => {
    setDarkMode(true);
  };

  const turnOffDarkMode = () => {
    setDarkMode(false);
  };

  useEffect(() => {
    document.documentElement.classList.add("dark");
    console.log();
  }, []);

  return (
    <DarkModeContext.Provider
      value={{ darkMode, toggleDarkMode, turnOnDarkMode, turnOffDarkMode }}
    >
      <div>{children}</div>
    </DarkModeContext.Provider>
  );
};
