import React, { Fragment, useEffect, useState, useContext } from "react";
import { Menu, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import Button from "components/UI-lib/Button";
import agent, { WorkspaceGroup } from "server";
import { OrgContext } from "contexts/OrgContext";

interface MultiSelectDropdownProps {}

const people = [
  { id: 1, name: "Durward Reynolds" },
  { id: 2, name: "Kenton Towne" },
  { id: 3, name: "Therese Wunsch" },
  { id: 4, name: "Benedict Kessler" },
  { id: 5, name: "Katelyn Rohan" },
];

// export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = (props) => {
//   const [selectedPeople, setSelectedPeople] = useState([people[0], people[1]])

//   return (
//     <Listbox value={selectedPeople} onChange={setSelectedPeople} multiple>
//       <Listbox.Button>
//         {selectedPeople.map((person) => person.name).join(', ')}
//       </Listbox.Button>
//       <Listbox.Options>
//         {people.map((person) => (
//           <Listbox.Option key={person.id} value={person}>
//             {person.name}
//           </Listbox.Option>
//         ))}
//       </Listbox.Options>
//     </Listbox>
//   )
// }

interface DropdownProps {
  children: React.ReactNode;
  label: string;
  onChange: (items: any[]) => void;
  value: string[];
  displayFunction?: (item: any[]) => any;
  useExclusive?: string;
  // tech debt: im worried about removing the mt-1 because another UI frame might need it
  // ideally, the mt-1 should be just used where it's needed
  noMargin?: boolean;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MultiSelectDropdown: React.FC<DropdownProps> = (props) => {
  const [selected, setSelected] = useState(props.value);

  useEffect(() => {
    setSelected(props.value);
  }, [selected, props.value]);

  return (
    <Listbox
      value={selected}
      onChange={(s) => {
        if (
          !!props.useExclusive &&
          s.length > 1 &&
          s.findIndex((value) => value === props.useExclusive) === s.length - 1
        ) {
          s = [props.useExclusive];
        } else if (
          !!props.useExclusive &&
          s.length > 1 &&
          s.findIndex((value) => value === props.useExclusive) !== -1
        ) {
          s.splice(
            s.findIndex((value) => value === props.useExclusive),
            1
          );
        } else if (!!props.useExclusive && s.length === 0) {
          s.push(props.useExclusive);
        }
        setSelected(s);
        props.onChange(s);
      }}
      multiple
    >
      {({ open }) => (
        <div className="flex flex-col">
          <Listbox.Label className="block text-sm font-medium text-gray-700 dark:text-secondary">
            {props.label}
          </Listbox.Label>
          <div className={classNames("relative", props.noMargin ? "mt-1" : "")}>
            <Listbox.Button className="bg-white dark:bg-zinc-900 relative w-full border border-gray-300 dark:border-zinc-800 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-highlightLighter focus:border-highlight sm:text-sm">
              <span className="block truncate">
                {props.displayFunction
                  ? props.displayFunction(selected)
                  : selected}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white dark:bg-zinc-900 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {props.children}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default MultiSelectDropdown;

interface DropdownItemProps {
  value: string;
  displayValue: string;
  depreciateButton?: boolean;
  secondaryDisplayValue?: string;
}

export const DropdownItem: React.FC<DropdownItemProps> = (props) => (
  <Listbox.Option
    key={props.value}
    className={({ active }) =>
      classNames(
        active ? "text-white bg-highlight" : "text-gray-900",
        "flex cursor-default select-none relative py-2 pl-3 pr-9"
      )
    }
    value={props.value}
  >
    {({ selected, active }) => (
      <>
        <div className="flex whitespace-nowrap">
          <span
            className={classNames(
              selected ? "font-semibold" : "font-normal",
              "block truncate"
            )}
          >
            {props.displayValue}
          </span>
          {props.secondaryDisplayValue && (
            <span
              className={classNames(
                active ? "text-cyan-200" : "text-gray-500",
                "ml-2 whitespace-nowrap	"
              )}
            >
              {props.secondaryDisplayValue}
            </span>
          )}
        </div>
        {selected ? (
          <span
            className={classNames(
              active ? "text-white" : "text-cyan-600",
              "absolute inset-y-0 right-0 flex items-center pr-4"
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
);
