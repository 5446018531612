// make a basic react component that just says hello world
import React, { useContext, useState, useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/24/solid";
import {
  fallbackCopyTextToClipboard,
  parseCloneFormatToGitUrl,
} from "components/utils";
import { UserContext } from "contexts/UserContext";
import FlatCard from "components/UI-lib/FlatCard";
import { useAuth0 } from "@auth0/auth0-react";
import PersonalTerminalSettings from "./PersonalTerminalSettings";
import { useHistory, useLocation } from "react-router-dom";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { isKasAuthFlow } from "server/kas/utils";

const CLI_INSTALL_CMD = "brew install brevdev/homebrew-brev/brev";

const enableTokenLogin = true;

function ProfileUserMetadata() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const login = query.get("login");
  const userContext = useContext(UserContext);
  const [revealToken, setRevealToken] = useState(false);
  const [CLI_LOGIN_CMD, setCLI_LOGIN_CMD] = useState("brev login");
  const history = useHistory();

  const { getAccessTokenSilently } = useAuth0?.();

  useEffect(() => {
    if (!isKasAuthFlow) {
      getAccessTokenSilently().then((t) => {
        // KAS auth flow won't support token login atm
        if (enableTokenLogin && !isKasAuthFlow) {
          setCLI_LOGIN_CMD(`brev login --token ${t}`);
        } else {
          setCLI_LOGIN_CMD(`brev login`);
        }
      });
    }
  }, [getAccessTokenSilently]);

  return (
    <>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-2">
          Your Profile
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-secondary">
          Your personal details and setup steps
        </p>
      </div>
      <div className="mt-5 border-t border-gray-200 dark:border-zinc-800">
        <dl className="divide-y divide-gray-200 dark:divide-zinc-800">
          {/* ITEM */}
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500 dark:text-secondary">
              Username
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 dark:text-secondary sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{userContext.me?.username}</span>
              <span className="ml-4 flex-shrink-0" />
            </dd>
          </div>

          {/* ITEM */}
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500 dark:text-secondary">
              Brev User ID
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 dark:text-secondary sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{userContext.me?.id}</span>
              <span className="ml-4 flex-shrink-0" />
            </dd>
          </div>

          {/* ITEM */}
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500 dark:text-secondary">
              Install CLI
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 dark:text-secondary sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{CLI_INSTALL_CMD}</span>
              <span className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="bg-white dark:bg-zinc-900 rounded-md font-medium text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-highlightLighter"
                  onClick={() => {
                    fallbackCopyTextToClipboard(CLI_INSTALL_CMD);
                  }}
                >
                  Copy
                </button>
              </span>
            </dd>
          </div>

          {/* ITEM */}
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt
              className={`text-sm ${
                login === "cli"
                  ? "text-cyan-600 dark:text-cyan-300 font-bold"
                  : "text-gray-500 dark:text-secondary font-medium"
              }`}
            >
              Login via CLI (paste this in your terminal)
            </dt>
            <dd
              className={`mt-1 flex text-sm sm:mt-0 sm:col-span-2 ${
                login === "cli"
                  ? "text-cyan-600 dark:text-cyan-300 font-bold"
                  : "text-gray-900 dark:text-secondary"
              }`}
            >
              <span className="flex-grow">brev login --token *****</span>
              <span className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="bg-white dark:bg-zinc-900 rounded-md font-medium text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-highlightLighter"
                  onClick={() => {
                    fallbackCopyTextToClipboard(CLI_LOGIN_CMD);
                  }}
                >
                  Copy
                </button>
              </span>
            </dd>
          </div>

          {/* ITEM */}
          {/* <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul
                role="list"
                className="border border-gray-200 rounded-md divide-y divide-gray-200"
              >
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      resume_back_end_developer.pdf
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex space-x-4">
                    <button
                      type="button"
                      className="bg-white rounded-md font-medium text-cyan-600 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlightLighter"
                    >
                      Update
                    </button>
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      className="bg-white rounded-md font-medium text-cyan-600 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlightLighter"
                    >
                      Remove
                    </button>
                  </div>
                </li>
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      coverletter_back_end_developer.pdf
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex space-x-4">
                    <button
                      type="button"
                      className="bg-white rounded-md font-medium text-cyan-600 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlightLighter"
                    >
                      Update
                    </button>
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      className="bg-white rounded-md font-medium text-cyan-600 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlightLighter"
                    >
                      Remove
                    </button>
                  </div>
                </li>
              </ul>
            </dd>
          </div> */}
        </dl>
      </div>
    </>
  );
}

export default ProfileUserMetadata;
