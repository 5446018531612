import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";
import dvdImage_ from "./dvd-png.png"; // Importing the DVD image
import dvdImage from "./dvd.gif"; // Importing the DVD image

interface Props {
  children: React.ReactNode;
}
const Background: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(true);
  const cancelButtonRef = React.useRef(null);
  const wrapperRef = React.useRef(null);
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[2147483647]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="copypasta flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              ref={wrapperRef}
            >
              {props.children}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const HDIRECTIONS = ["left", "right"];
const VDIRECTIONS = ["up", "down"];

export const ScreenSaver: React.FC = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [direction, setDirection] = useState({ h: "right", v: "down" });
  const [SPEED, setSPEED] = useState(5);
  const [isInactive, setIsInactive] = useState(false);

  useEffect(() => {
    const setRandomPosition = () => {
      const x = Math.floor(Math.random() * (window.innerWidth - 200));
      const y = Math.floor(Math.random() * (window.innerHeight - 175));
      setPosition({ x, y });
    };

    // setRandomPosition();
    // document.body.style = "overflow: hidden;";

    const updatePosition = () => {
      let newX = position.x;
      let newY = position.y;

      if (direction.h === "right") {
        newX += SPEED;
      } else if (direction.h === "left") {
        newX -= SPEED;
      }

      if (direction.v === "down") {
        newY += SPEED;
      } else if (direction.v === "up") {
        newY -= SPEED;
      }

      // Check bounds
      const isOverlapRight = newX + 200 > window.innerWidth;
      const isOverlapLeft = newX < 0;
      const isOverlapUp = newY < 0;
      const isOverlapDown = newY + 175 > window.innerHeight;

      if (isOverlapRight || isOverlapLeft) {
        setDirection((prev) => ({
          ...prev,
          h: HDIRECTIONS.filter((dir) => dir !== prev.h)[0],
        }));
      }
      if (isOverlapUp || isOverlapDown) {
        setDirection((prev) => ({
          ...prev,
          v: VDIRECTIONS.filter((dir) => dir !== prev.v)[0],
        }));
      }

      setPosition({ x: newX, y: newY });
    };

    const timer = setInterval(updatePosition, 30);
    return () => clearInterval(timer);
  }, [position, direction]);

  useEffect(() => {
    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      setIsInactive(false);
      inactivityTimer = setTimeout(() => setIsInactive(true), 300000); // 5 minutes
    };

    // Set initial timer
    resetTimer();

    // Event listeners for mouse and keyboard activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);

    // Cleanup function to remove event listeners and clear the timer
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);
      clearTimeout(inactivityTimer);
    };
  }, []);

  if (isInactive) {
    return (
      <Background>
        <div
          className="test"
          style={{
            position: "absolute",
            left: `${position.x}px`,
            top: `${position.y}px`,
            transition: "left 30ms, top 30ms", // Smoothens the movement
          }}
        >
          <img
            src={dvdImage} // Using the imported DVD image
            alt="DVD item"
            width="200"
            height="175"
          />
        </div>
      </Background>
    );
  } else {
    return null;
  }
};
