import { classNames } from "components/utils";
import React, { useContext, useEffect, useState } from "react";
import agent, { BillingProfile, Invoice } from "server";
import Button from "components/UI-lib/Button";
import { OrgContext } from "contexts/OrgContext";
import { NotificationContext } from "contexts/NotificationContext";
import {
  CurrencyDollarIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ReceiptRefundIcon,
} from "@heroicons/react/24/solid";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { BillingContext } from "contexts/BillingContext";
import { convertUnixEpochToDate } from "../utils";

interface InvoiceComponentProps {
  upcomingInvoice?: Invoice;
  invoices?: Invoice[];
  refreshInvoices: (id: string) => void;
}

const InvoiceComponent: React.FC<InvoiceComponentProps> = (props) => {
  const { activeOrgId } = useContext(OrgContext);
  const notificationContext = useContext(NotificationContext);
  const billingProfile = useContext(BillingContext);
  const [isLoading, setIsLoading] = useState(false);
  const sendRetryInvoice = async (invoiceId: string) => {
    setIsLoading(true);
    const res = await agent.Organizations.retryInvoice(activeOrgId, {
      invoiceId,
    });
    if (res.data) {
      notificationContext.showNotification(
        "Successfully payed invoice",
        "",
        "success"
      );
    } else {
      notificationContext.showNotification(
        "Unable to pay invoice",
        "",
        "error"
      );
    }
    props.refreshInvoices(activeOrgId);
    setIsLoading(false);
  };

  const renderUpcomingInvoice = () => {
    if (props.upcomingInvoice && props.upcomingInvoice?.amount_due) {
      return (
        <li className="relative flex gap-x-4">
          <div className="absolute left-0 top-0 flex justify-center" />
          <>
            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:bg-zinc-900">
              <ClockIcon width={18} color="#eab308" />
            </div>
            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500 dark:text-secondary">
              <span>Upcoming </span>
              <span>invoice for </span>
              <span className="mr-1">
                {`$${((props.upcomingInvoice?.amount_due || 0) / 100).toFixed(
                  2
                )}`}
              </span>
            </p>
            <div className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-secondary">
              {convertUnixEpochToDate(props.upcomingInvoice?.created || 0)}
            </div>
          </>
        </li>
      );
    }
  };

  return (
    <>
      <>
        <ul role="list" className="">
          {renderUpcomingInvoice()}
          {props.invoices &&
            props.invoices
              .filter((i) => {
                if (i.status !== "void") {
                  if (i.amount_due !== undefined && i.amount_due > 0) {
                    return true;
                  }
                }
                return false;
              })
              .map((invoice, index) => (
                <>
                  <li key={index} className="relative flex gap-x-4">
                    <div className="absolute left-0 top-0 flex justify-center" />
                    <>
                      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:bg-zinc-900">
                        {getInvoiceIcon(invoice)}
                      </div>
                      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500 dark:text-secondary">
                        {billingProfile.billingProfile &&
                          getInvoiceDetails(
                            invoice,
                            billingProfile.billingProfile
                          )}
                        {((invoice.status === "paid" &&
                          billingProfile.billingProfile?.billing_type ===
                            "credit" &&
                          !!invoice.invoice_url) ||
                          (billingProfile.billingProfile?.billing_type ===
                            "usage-arrears" &&
                            !!invoice.invoice_url)) && (
                          <a href={invoice.invoice_url} className="mr-2">
                            <span className="text-cyan-600 dark:text-cyan-300 underline">
                              View
                            </span>
                          </a>
                        )}
                        {invoice.status === "open" &&
                          billingProfile.billingProfile?.billing_type ===
                            "usage-arrears" &&
                          (invoice.attempt_count || 0) > 0 && (
                            <Button
                              label="Retry Invoice"
                              loading={isLoading}
                              onClick={() =>
                                sendRetryInvoice(invoice?.invoice_id || "")
                              }
                              className="inline-flex text-xs items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-amber-500 h-5"
                            />
                          )}
                        {invoice.refund?.refund_url !== undefined && (
                          <a href={invoice.refund.refund_url} className="mr-2">
                            <span className="text-cyan-600 dark:text-cyan-300 underline">
                              Download Refund
                            </span>
                          </a>
                        )}
                      </p>
                      <div className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-secondary">
                        {convertUnixEpochToDate(invoice.created || 0)}
                      </div>
                    </>
                  </li>
                </>
              ))}
        </ul>
      </>
    </>
  );
};

const getInvoiceIcon = (inv: Invoice) => {
  if (inv.refund !== undefined) {
    return <ReceiptRefundIcon width={18} color="#65a30d" />;
  }
  if (inv.status === "paid") {
    return <CurrencyDollarIcon width={18} color="#65a30d" />;
  }
  if (inv.status === "open") {
    return <ExclamationCircleIcon width={18} color="#dc2626" />;
  }
  if (inv.status === "draft") {
    return <DocumentTextIcon width={18} color="#0284c7" />;
  }
  return (
    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
  );
};

const getInvoiceStatus = (inv: Invoice) => {
  if (inv.refund !== undefined) {
    return "Refunded";
  }
  if (inv.status === "paid") {
    return "Paid";
  }
  if (inv.status === "open") {
    return "Open";
  }
  if (inv.status === "draft") {
    return "Draft";
  }
  return inv.status;
};

const getInvoiceDetails = (
  invoice: Invoice,
  billingProfile: BillingProfile
) => {
  let invoiceStatus = getInvoiceStatus(invoice);
  if (billingProfile.billing_type === "credit") {
    let detailText = "invoice for";
    if (invoiceStatus === "Open") {
      invoiceStatus = "Failed";
      detailText = "to purchase";
    } else if (invoiceStatus === "Paid") {
      detailText = "";
    }
    return (
      <>
        <span className="">{invoiceStatus}</span>
        <span className=""> {detailText} </span>
        <span className="mr-1">
          {`$${((invoice?.amount_due || 0) / 100).toFixed(2)}` || 0}
        </span>
        <span className="">worth of credits </span>
      </>
    );
  } else {
    return (
      <>
        <span className="">{invoiceStatus}</span>
        <span className=""> invoice for </span>
        <span className="mr-1">
          {`$${((invoice?.amount_due || 0) / 100).toFixed(2)}` || 0}
        </span>
      </>
    );
  }
};

export default InvoiceComponent;
