import React, { useContext, useEffect, useState } from "react";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import Workspace from "../../../../../entities/Workspace.entity";
import FlatCard from "components/UI-lib/FlatCard";
import InputField from "components/UI-lib/InputField";
import Button from "components/UI-lib/Button";
import agent, { WorkspaceRes } from "server";
import { Capability, resizeCapability } from "contexts/OrgContext";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { NotificationContext } from "contexts/NotificationContext";

interface VolumeChangerProps {
  workspace: Workspace;
  capabilities: Capability[];
}

const VolumeChanger: React.FC<VolumeChangerProps> = (props) => {
  const wsContext = useContext(WorkspaceContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const diskStorage = (diskStorage: string | undefined) => {
    if (diskStorage) {
      return diskStorage.split("Gi")[0];
    }
    return "";
  };
  const [volume, setVolume] = useState(
    diskStorage(props.workspace.diskStorage)
  );
  const notificaitonContext = useContext(NotificationContext);

  const validateVolume = (input: string) => {
    if (/^\d*$/.test(input)) {
      setVolume(input);
    }
  };

  const modifyWorkspaceVolume = async (
    id: string
  ): Promise<WorkspaceRes | null> => {
    setErrorMessage("");
    setLoading(true);
    if (Number(volume) <= Number(diskStorage(props.workspace.diskStorage))) {
      setErrorMessage(
        `Must be greater than ${diskStorage(props.workspace.diskStorage)}`
      );
      setLoading(false);
      return null;
    }

    const res = await agent.Workspaces.modifyVolume(id, {
      diskSize: `${volume}Gi`,
    });
    if (res.success && res.data) {
      notificaitonContext.showNotification(
        "Success!",
        `Scaled Storage by ${volume}Gi`,
        "success"
      );
    } else {
      setErrorMessage(res.message || "");
    }
    setLoading(false);
    return null;
  };

  useEffect(() => {
    if (Number(volume) < Number(diskStorage(props.workspace.diskStorage))) {
      setErrorMessage(
        `Must be greater than ${diskStorage(props.workspace.diskStorage)}`
      );
    } else {
      setErrorMessage("");
    }
  }, [volume]);

  return (
    <div className="flex flex-col justify-start items-start">
      <FlatCard className="mb-3">
        {props.capabilities.includes(resizeCapability) ? (
          <>
            {/* <h3 className="text-sm font-medium text-gray-900">
              Modify Disk Size
            </h3> */}
            <p className="text-md text-gray-700 dark:text-secondary">
              Increase the Disk Size of your workspace
            </p>
            <p className="text-sm text-gray-500 dark:text-secondary">
              Note: You may need to delete some files or restart the instance
              for this to run without error.
            </p>
            {/* <p className="text-md text-gray-500 mb-2"></p> */}

            <div className="flex flex-row justify-start items-start">
              <InputField
                label="Disk Size"
                value={volume}
                placeholder={volume}
                onChange={validateVolume}
                className="w-2/6"
                errorMessage={errorMessage}
                trailingCharacters="Gi"
                hideLabel
              />
              <Button
                label={`Increase size
                  `}
                className="mt-2 ml-2"
                type={
                  diskStorage(props.workspace.diskStorage) >= volume
                    ? "secondary"
                    : "primary"
                }
                loading={loading}
                disabled={loading}
                onClick={() => modifyWorkspaceVolume(props.workspace.id)}
              />
              {diskStorage(props.workspace.diskStorage) !== volume && (
                <Button
                  label="Cancel"
                  className="ml-1 mt-2"
                  type="secondary"
                  disabled={loading}
                  onClick={() => {
                    setVolume(diskStorage(props.workspace.diskStorage));
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <InlineNotification
              show
              severity="info"
              text={
                "Scaling volumes isn't supported on this infrastructure yet."
              }
              autoClose={false}
              onClose={() => {}}
              className=""
              hideCloseButton
            />
          </>
        )}
      </FlatCard>
    </div>
  );
};
export default VolumeChanger;
