import React, { useState } from "react";
import { classNames } from "components/utils";
import Button from "components/UI-lib/Button";
import Modal from "components/Modals/Modal";
import Dropdown from "components/UI-lib/Dropdown";
import { DropdownItem } from "components/UI-lib/Dropdown";
import InputField from "components/UI-lib/InputField";

export interface DatapondAttachment {
  datapondId?: string;
  mountPath: string;
  attachmentMode: "read" | "write";
}

interface DatapondAttachmentsProps {
  attachments: DatapondAttachment[];
  onChange: (attachments: DatapondAttachment[]) => void;
}

const availableDataponds = [
  { id: 'id1', label: 'Datapond 1' },
  { id: 'id2', label: 'Datapond 2' },
  { id: 'id3', label: 'Datapond 3' },
];

export const DatapondAttachments: React.FC<DatapondAttachmentsProps> = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCreatingDatapond, setIsCreatingDatapond] = useState(true);
  const [newAttachment, setNewAttachment] = useState<DatapondAttachment>({
    datapondId: "",
    mountPath: "/",
    attachmentMode: "read",
  });
  const [newDatapond, setNewDatapond] = useState({ label: "", description: "" });

  const handleCreateDatapond = () => {
    // Logic to create a new Datapond and add it to availableDataponds
    const newId = `id${availableDataponds.length + 1}`;
    availableDataponds.push({ id: newId, label: newDatapond.label });
    setNewDatapond({ label: "", description: "" });
    setModalIsOpen(false);
  };

  const handleCreateAttachment = () => {
    props.onChange([...props.attachments, newAttachment]);
    setNewAttachment({ datapondId: "", mountPath: "/", attachmentMode: "read" });
    setModalIsOpen(false);
  };

  return (
    <div className="flex flex-col">
      <div>
        <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
        <div className="datapond-options">
          <h1 className="text-lg font-bold mb-4">Datapond Attachments</h1>
          {props.attachments.map((attachment, index) => (
            <div
              key={index}
              className="flex flex-row items-center justify-between mb-2"
            >
              <div className="w-1/4 pr-1">
                <span>
                  {availableDataponds.find(
                    (d) => d.id === attachment.datapondId
                  )?.label || "Unknown"}
                </span>
              </div>
              <div className="w-1/4 pr-1">
                <InputField
                  label="Mount Path"
                  errorMessage=""
                  value={attachment.mountPath}
                  onChange={(e) => {}}
                />
              </div>
              <div className="w-1/4 pr-1">
                <Dropdown
                  label="Select Mode"
                  value={attachment.attachmentMode}
                  onChange={(selectedMode) => {
                    const newAttachments = [...props.attachments];
                    newAttachments[index].attachmentMode = selectedMode;
                    props.onChange(newAttachments);
                  }}
                >
                  <DropdownItem displayValue="Read" value="read" key="read" />
                  <DropdownItem
                    displayValue="Write"
                    value="write"
                    key="write"
                  />
                </Dropdown>
              </div>
              <div className="w-1/4 pr-1">
                <Button
                  size="small"
                  type="danger"
                  onClick={() => {
                    const newAttachments = [...props.attachments];
                    newAttachments.splice(index, 1);
                    props.onChange(newAttachments);
                  }}
                  label="Remove"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="flex space-x-4">
          <Button
            label="Create Datapond"
            className="mt-4 text-xs px-2 py-1"
            type="primary"
            onClick={() => {
              setIsCreatingDatapond(true);
              setModalIsOpen(true);
            }}
            size="small"
          />
          <Button
            label="Connect Datapond"
            className="mt-4 text-xs px-2 py-1"
            type="primary"
            onClick={() => {
              setIsCreatingDatapond(false);
              setModalIsOpen(true);
            }}
            size="small"
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        setOpen={setModalIsOpen}
        title={
          isCreatingDatapond ? "Create a New Datapond" : "Connect a Datapond"
        }
        onClose={() => setModalIsOpen(false)}
        body={
          isCreatingDatapond ? (
            <>
              <div className="flex flex-col mb-4">
                <label>Datapond Name</label>
                <InputField
                  label="Datapond Name"
                  errorMessage=""
                  value={newDatapond.label}
                  onChange={(e) => {
                    setNewDatapond({ ...newDatapond, label: e });
                  }}
                />
              </div>
              <div className="flex flex-col mb-4">
                <label>Description</label>
                <InputField
                  label="Description"
                  errorMessage=""
                  value={newDatapond.description}
                  onChange={(e) => {
                    setNewDatapond({
                      ...newDatapond,
                      description: e,
                    })
                  }}
                />
              </div>
              <Button
                label="Create Datapond"
                className="mt-4 text-xs px-2 py-1"
                type="primary"
                onClick={handleCreateDatapond}
                size="small"
              />
            </>
          ) : (
            <>
              <div className="flex flex-col mb-4">
                <label>Datapond</label>
                <Dropdown
                  label="Select Datapond"
                  value={newAttachment.datapondId!}
                  onChange={(selectedId) =>
                    setNewAttachment({
                      ...newAttachment,
                      datapondId: selectedId,
                    })
                  }
                >
                  {availableDataponds.map((option) => (
                    <DropdownItem
                      displayValue={option.label}
                      value={option.id}
                      key={option.id}
                    />
                  ))}
                </Dropdown>
              </div>
              <div className="flex flex-col mb-4">
                <label>Mount Path</label>
                <InputField
                  label="Mount Path"
                  errorMessage=""
                  value={newAttachment.mountPath}
                  onChange={(e) => {
                    setNewAttachment({
                      ...newAttachment,
                      mountPath: e,
                    })
                  }}
                />
              </div>
              <div className="flex flex-col mb-4">
                <label>Attachment Mode</label>
                <Dropdown
                  label="Select Mode"
                  value={newAttachment.attachmentMode}
                  onChange={(selectedMode) =>
                    setNewAttachment({
                      ...newAttachment,
                      attachmentMode: selectedMode,
                    })
                  }
                >
                  <DropdownItem displayValue="Read" value="read" key="read" />
                  <DropdownItem
                    displayValue="Write"
                    value="write"
                    key="write"
                  />
                </Dropdown>
              </div>
              <Button
                label="Connect Datapond"
                className="mt-4 text-xs px-2 py-1"
                type="primary"
                onClick={handleCreateAttachment}
                size="small"
              />
            </>
          )
        }
      />
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
    </div>
  );
};

export default DatapondAttachments;
