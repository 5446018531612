import { classNames } from "components/utils";
import React from "react";

interface DynamicBadgeProps {
  text: string;
  isActive: boolean;
  setIsActive: (b: boolean) => void;
  className?: string;
  hideIcon?: boolean;
  color?: string;
  pending?: boolean;
}

const DynamicBadge: React.FC<DynamicBadgeProps> = ({
  text,
  isActive,
  setIsActive,
  className,
  hideIcon,
  color,
  pending = false,
}) => {
  const handleClick = () => {
    setIsActive(!isActive);
  };

  const baseStyle =
    "cursor-pointer inline-flex items-center gap-x-1 rounded-md px-3 py-1.5 text-sm font-bold ring-2 ring-inset";
  const activeStyle = "text-white ring-highlightLighter";
  const inactiveStyle = "bg-primaryBg text-secondary ring-zinc-800";
  const pendingStyle = "text-white ring-highlight";

  const dynamicStyles = classNames(
    baseStyle,
    pending ? pendingStyle : isActive ? activeStyle : inactiveStyle,
    className || "",
    color || (isActive ? "bg-highlightDarker" : "bg-secondaryBg")
  );

  return (
    <span className={dynamicStyles} onClick={handleClick}>
      {!hideIcon && (
        <button
          type="button"
          className="group relative -mr-1 h-4 w-4 rounded-sm hover:bg-highlight/20"
        >
          <span className="sr-only">{isActive ? "Remove" : "Add"}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-4 w-4 stroke-current"
          >
            {pending ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
              />
            ) : isActive ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            )}
          </svg>
          <span className="absolute -inset-1" />
        </button>
      )}
      {text}
    </span>
  );
};

export default DynamicBadge;
