import React, { useContext, useEffect, useMemo, useState } from "react";
import FilterCard from "./FilterCard";
import RamMemoryIcon from "assets/img/svg/ram-memory.svg?react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Graphs/Popover";
import { OrgContext } from "contexts/OrgContext";
import { memoryToGiB } from "components/utils";
import BrevSlider from "./Slider";

const marks = [
  {
    value: 0,
    label: "Any",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 24,
    label: "24",
  },
  {
    value: 32,
    label: "32",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 48,
    label: "48",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "100",
  },
  {
    value: 100,
    label: "128",
  },
];

interface VRamFilterProps {
  selectedVRam: string;
  setSelectedVRam: (vram: string) => void;
}

const VRamFilter: React.FC<VRamFilterProps> = ({
  selectedVRam,
  setSelectedVRam,
}) => {
  const [selectedSliderValue, setSelectedSliderValue] = useState(0);

  const onChange = (value: number) => {
    if (value === 0) {
      setSelectedVRam("any");
    } else {
      const trueValue = marks.find((mark) => mark.value === value);
      setSelectedVRam(trueValue?.label + "GiB");
    }
    setSelectedSliderValue(value);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="mr-2 ml-2 flex">
          <FilterCard
            icon={<RamMemoryIcon className="w-7 h-7 text-white mr-1" />}
            title="VRAM (GPU Memory)"
            description={
              selectedVRam === "any" ? "Any Size" : "At least " + selectedVRam
            }
            descriptionClass="mt-[3px]"
            className="min-w-[275px]"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent className="p-4">
        <div className="flex flex-col min-w-[500px] mb-4">
          <div className="flex flex-row text-sm font-medium text-gray-700 dark:text-white mb-3">
            <h1>Filtering for GPUs</h1>
            {selectedVRam !== "any" ? (
              <>
                <h1 className="ml-1">with at least</h1>
                <h1 className="ml-1 font-bold">{selectedVRam}</h1>
                <h1 className="ml-1">of VRAM</h1>
              </>
            ) : (
              <h1 className="ml-1">with any amount of VRAM</h1>
            )}
          </div>
          <div className="flex px-4">
            <BrevSlider
              step={null}
              marks={marks}
              value={selectedSliderValue}
              onChange={onChange}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default VRamFilter;
