import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import discoverCover from "assets/img/png/discover_cover.png";
import discoverCard from "assets/img/png/discover_card.png";
import Chip from "components/Environment/Create/chips";
import { classNames, fetchNotebookList } from "components/utils";
import { PreviewModal } from "./PreviewModal";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { DiscoverCard } from "./Card";
import ReactPlayer from "react-player";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import discoverSurfboard from "assets/img/png/Discover/discover_surfboard.png";
import discoverLlama from "assets/img/png/Discover/discover_llama.png";
import discoverAstro from "assets/img/png/Discover/discover_astronaut.png";
import discoverAstroLlama from "assets/img/png/Discover/discover_astro_llama.png";
import discoverLlamaLeather from "assets/img/png/Discover/discover_llama_leather.png";
import discoverLlamaVolcano from "assets/img/png/Discover/discover_llama_volcano.png";
import discoverLlamaParty from "assets/img/png/Discover/discover_llama_party.png";
import discoverBeachShack from "assets/img/png/Discover/discover_beach_shack.png";
import discoverLlamaSurf from "assets/img/png/Discover/discover_llama_surfing.png";
import discoverLlamaStars from "assets/img/png/Discover/discover_llama_stars.png";
import discoverRobotLlama from "assets/img/png/Discover/discover_robot_llama.png";
import discoverBeachBonfire from "assets/img/png/Discover/discover_beach_bonfire.png";
import discoverllamaprof from "assets/img/png/Discover/discover_llama_professor.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import { Disclosure } from "@headlessui/react";
import { GetLogo } from "components/DashboardContainer/Dash";
import { ProcessedLaunchableData } from "components/Environment/Launchables/utils";
import { useKas } from "contexts/KasContext";
import { isKasAuthFlow } from "server/kas/utils";

export interface DiscoverCardData {
  company: string;
  internal_name: string;
  launchable_link: string;
  media: string;
  tags: string[];
  type: string;
}

export interface PreviewData {
  launchableData: ProcessedLaunchableData | null;
  instance?: GPUInstanceType | null;
}

export const DiscoverRedirect: React.FC = () => {
  console.log("Route: /discover");
  const { isAuthenticated: _auth0IsAuthenticated, user: _auth0User } =
    useAuth0?.();

  const { isUserLoggedIn: kasIsAuthenticated, user: kasUser } = useKas();

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0User = isKasAuthFlow ? kasUser : _auth0User;

  const history = useHistory();
  useEffect(() => {
    if (auth0IsAuthenticated && auth0User) {
      // history.push(`/org/discover`);
    }
  }, [auth0IsAuthenticated, auth0User]);

  return (
    <div className="min-h-full">
      <Disclosure
        as="nav"
        className="bg-white dark:bg-zinc-900 border-b border-gray-200 dark:border-zinc-800"
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <GetLogo
                      onClick={() => {
                        // TODO: make this something better
                        history.push(`/login`);
                      }}
                    />
                  </div>
                </div>
                <div className="ml-6 flex items-center"></div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <div className="">
        <main>
          <div className="">
            <Discover />
          </div>
        </main>
      </div>
    </div>
  );
};

const Discover: React.FC = () => {
  console.log("Route: /org/discover");

  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState<PreviewData | null>(null);
  const [images, setImages] = useState([
    discoverSurfboard,
    discoverLlama,
    discoverAstro,
    discoverAstroLlama,
    discoverLlamaLeather,
    discoverLlamaVolcano,
    discoverLlamaParty,
    discoverBeachShack,
    discoverLlamaSurf,
    discoverLlamaStars,
    discoverRobotLlama,
    discoverBeachBonfire,
    discoverllamaprof,
  ]);
  const [allDiscoverData, setAllDiscoverData] = useState<DiscoverCardData[]>(
    []
  );

  const backgroundImageStyle = {
    backgroundImage: `url(${discoverCover})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const fetchAndTransformDiscoverData = async () => {
    try {
      const data: DiscoverCardData[] = await fetchNotebookList();
      setAllDiscoverData(data);
    } catch (error) {
      console.error("Error:", error);
      setAllDiscoverData([]);
    }
  };

  useEffect(() => {
    fetchAndTransformDiscoverData();
  }, []);
  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
      <div className="px-4 sm:px-6 lg:px-8 text-white">
        <div className="h-[500px] w-full relative" style={backgroundImageStyle}>
          <div className="p-5 w-full">
            <h1
              className="text-4xl text-white font-bold"
              style={{
                textShadow: "4px 4px 4px rgba(0, 0, 0, 1)",
              }}
            >
              Explore and Deploy
            </h1>
            <h3
              className="text-2xl text-white font-bold"
              style={{
                textShadow: "4px 4px 4px rgba(0, 0, 0, 1)",
              }}
            >
              Browse our catalog of content and deploy them instantly
            </h3>
            <div className="w-full flex flex-row items-center mt-8">
              <div className="w-full bg-black justify-center items-center">
                <ReactPlayer
                  className="w-full"
                  controls={true}
                  playing={false}
                  width={"100%"}
                  height={"350px"}
                  url="http://youtu.be/XTCPiK9fFCo"
                />
              </div>
              <div className="ml-5 items-center flex flex-row">
                <div className="h-full">
                  <ArrowRightIcon className="h-20 w-20 text-white" />
                </div>
                {allDiscoverData
                  .filter((data) => data.internal_name === "llama3.1-finetune")
                  .map((data, index) => {
                    return (
                      <DiscoverCard
                        key={index}
                        setShowPreview={setShowPreview}
                        setPreviewData={setPreviewData}
                        cardData={data}
                        image={
                          images[Math.floor(Math.random() * images.length)]
                        }
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <PreviewModal
          show={showPreview}
          setShow={setShowPreview}
          previewData={previewData}
        />
        <section className="mt-10 relative">
          <h1 className="text-2xl font-bold">Trending</h1>
          <div className="flex flex-row w-full overflow-x-auto mt-3">
            {allDiscoverData.filter(
              (data) =>
                data.internal_name === "llama3dpo" ||
                data.internal_name === "llava-finetune" ||
                data.internal_name === "llama3-tensorrtllm-deployment"
            ).length > 3 && (
              <span className="absolute right-0 top-[20px]">
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            )}
            {allDiscoverData
              .filter(
                (data) =>
                  data.internal_name === "llama3dpo" ||
                  data.internal_name === "llava-finetune" ||
                  data.internal_name === "llama3-tensorrtllm-deployment"
              )
              .map((data, index) => {
                return (
                  <DiscoverCard
                    key={index}
                    setShowPreview={setShowPreview}
                    setPreviewData={setPreviewData}
                    cardData={data}
                    image={images[Math.floor(Math.random() * images.length)]}
                  />
                );
              })}
          </div>
        </section>
        <section className="mt-5 relative">
          <h1 className="text-2xl font-bold">Finetune LLMs on Your Data</h1>
          <div className="flex flex-row w-full overflow-x-auto mt-3">
            {allDiscoverData.filter(
              (data) => data.type === "llm-finetune-training"
            ).length > 3 && (
              <span className="absolute right-0 top-[20px]">
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            )}
            {allDiscoverData
              .filter((data) => data.type === "llm-finetune-training")
              .map((data, index) => {
                return (
                  <DiscoverCard
                    key={index}
                    setShowPreview={setShowPreview}
                    setPreviewData={setPreviewData}
                    cardData={data}
                    image={images[Math.floor(Math.random() * images.length)]}
                  />
                );
              })}
          </div>
        </section>
        <section className="mt-5 relative">
          <h1 className="text-2xl font-bold">Deploying LLMs for Inference</h1>
          <div className="flex flex-row w-full overflow-x-auto mt-3">
            {allDiscoverData.filter(
              (data) => data.type === "llm-deployment-inference"
            ).length > 3 && (
              <span className="absolute right-0 top-[20px]">
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            )}
            {allDiscoverData
              .filter((data) => data.type === "llm-deployment-inference")
              .map((data, index) => {
                return (
                  <DiscoverCard
                    key={index}
                    setShowPreview={setShowPreview}
                    setPreviewData={setPreviewData}
                    cardData={data}
                    image={images[Math.floor(Math.random() * images.length)]}
                  />
                );
              })}
          </div>
        </section>
        <section className="mt-5">
          <h1 className="text-2xl font-bold">Multimodal + Computer Vision</h1>
          <div className="flex flex-row w-full overflow-x-auto mt-3 relative">
            {allDiscoverData.filter(
              (data) => data.type === "multimodal-computervision"
            ).length > 3 && (
              <span className="absolute right-0 top-[20px]">
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            )}
            {allDiscoverData
              .filter((data) => data.type === "multimodal-computervision")
              .map((data, index) => {
                return (
                  <DiscoverCard
                    key={index}
                    setShowPreview={setShowPreview}
                    setPreviewData={setPreviewData}
                    cardData={data}
                    image={images[Math.floor(Math.random() * images.length)]}
                  />
                );
              })}
          </div>
        </section>
        <section className="mt-5 relative">
          <h1 className="text-2xl font-bold">Diffusion Image Generation</h1>
          <div className="flex flex-row w-full overflow-x-auto mt-3">
            {allDiscoverData.filter(
              (data) => data.type === "diffusion-image-generation"
            ).length > 3 && (
              <span className="absolute right-0 top-[20px]">
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            )}
            {allDiscoverData
              .filter((data) => data.type === "diffusion-image-generation")
              .map((data, index) => {
                return (
                  <DiscoverCard
                    key={index}
                    setShowPreview={setShowPreview}
                    setPreviewData={setPreviewData}
                    cardData={data}
                    image={images[Math.floor(Math.random() * images.length)]}
                  />
                );
              })}
          </div>
        </section>
        <section className="mt-5 relative">
          <h1 className="text-2xl font-bold">LLM Courses</h1>
          <div className="flex flex-row w-full overflow-x-auto mt-3">
            {allDiscoverData.filter((data) => data.type === "llm-course")
              .length > 3 && (
              <span className="absolute right-0 top-[1px]">
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            )}
            {allDiscoverData
              .filter((data) => data.type === "llm-course")
              .map((data, index) => {
                return (
                  <DiscoverCard
                    key={index}
                    setShowPreview={setShowPreview}
                    setPreviewData={setPreviewData}
                    cardData={data}
                    image={images[Math.floor(Math.random() * images.length)]}
                  />
                );
              })}
          </div>
        </section>
        <section className="mt-5 relative">
          <h1 className="text-2xl font-bold">Tool and Technologies</h1>
          <div className="flex flex-row w-full overflow-x-auto mt-3">
            {allDiscoverData.filter(
              (data) => data.type === "tools-and-technologies"
            ).length > 3 && (
              <span className="absolute right-0 top-[1px]">
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            )}
            {allDiscoverData
              .filter((data) => data.type === "tools-and-technologies")
              .map((data, index) => {
                return (
                  <DiscoverCard
                    key={index}
                    setShowPreview={setShowPreview}
                    setPreviewData={setPreviewData}
                    cardData={data}
                    image={images[Math.floor(Math.random() * images.length)]}
                  />
                );
              })}
          </div>
        </section>
        <section className="mt-5 relative">
          <h1 className="text-2xl font-bold">Containers</h1>
          <div className="flex flex-row w-full overflow-x-auto mt-3">
            {allDiscoverData.filter((data) => data.type === "containers")
              .length > 3 && (
              <span className="absolute right-0 top-[1px]">
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            )}
            {allDiscoverData
              .filter((data) => data.type === "containers")
              .map((data, index) => {
                return (
                  <DiscoverCard
                    key={index}
                    setShowPreview={setShowPreview}
                    setPreviewData={setPreviewData}
                    cardData={data}
                    image={images[Math.floor(Math.random() * images.length)]}
                  />
                );
              })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Discover;
