import Modal from "components/Modals/Modal";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { Blueprint } from ".";

export interface ArchitectureProps {
  show: boolean;
  setShow: (show: boolean) => void;
  previewData?: Blueprint;
}

export const ArchitectureModal: React.FC<ArchitectureProps> = ({
  show,
  setShow,
  previewData,
}) => {
  const history = useHistory();

  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => setShow(false)}
      title={""}
      width={"max-w-7xl"}
      body={
        <div className="relative">
          <h1 className="text-xl font-semibold text-white mb-6">
            {previewData?.title}
          </h1>
          <img src={previewData?.diagramUrl || ""} className="w-full" />
        </div>
      }
      cancelLabel={"Close"}
    />
  );
};
