import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "components/utils";

interface ModalProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  onSuccess?: () => void;
  onClose?: () => void;
  title: string;
  body: any;
  hideIcon?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  severity?: "error" | "warning" | "info" | "success";
  disableSuccess?: boolean;
  hideCancel?: boolean;
  hideSuccess?: boolean;
  width?: string;
  disableClickOutside?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const [open, setOpen] = useState(props.isOpen);
  const cancelButtonRef = useRef(null);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (props.disableClickOutside) return;
          props.setOpen(false);
          if (props.onClose) {
            props.onClose();
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const getIcon = (severity: string) => {
    if (severity === "error") {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-red-100">
          <XCircleIcon className="h-6 w-6 text-rose-400" aria-hidden="true" />
        </div>
      );
    }
    if (severity === "warning") {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-amber-100">
          <ExclamationCircleIcon
            className="h-6 w-6 text-amber-400"
            aria-hidden="true"
          />
        </div>
      );
    }
    if (severity === "info") {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-blue-100">
          <InformationCircleIcon
            className="h-6 w-6 text-blue-400"
            aria-hidden="true"
          />
        </div>
      );
    }
    if (severity === "success") {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-green-100">
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        </div>
      );
    }
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-blue-100">
        <InformationCircleIcon
          className="h-6 w-6 text-blue-400"
          aria-hidden="true"
        />
      </div>
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[2147483646]"
        initialFocus={cancelButtonRef}
        onClose={() => {
          if (props.disableClickOutside) {
            return;
          }
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="copypasta flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              ref={wrapperRef}
            >
              <Dialog.Panel
                className={classNames(
                  "relative transform overflow-hidden rounded-lg bg-white dark:bg-zinc-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6",
                  props.width ? props.width : "sm:max-w-lg"
                )}
              >
                <div className="sm:flex sm:items-start">
                  {!props.hideIcon && props.severity && (
                    // <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    //   <ExclamationCircleIcon
                    //     className="h-6 w-6 text-red-600"
                    //     aria-hidden="true"
                    //   />
                    // </div>
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      {getIcon(props.severity)}
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-white dark:bg-zinc-900"
                    >
                      {props.title}
                    </Dialog.Title>
                    <div className="mt-2">{props.body}</div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {props.onSuccess && !props.hideSuccess && (
                    <Button
                      className="ml-3"
                      label={props.confirmLabel || "Yes"}
                      type="primary"
                      onClick={() => {
                        if (props.onSuccess) {
                          props.onSuccess();
                        }
                        setOpen(false);
                      }}
                      disabled={props.disableSuccess}
                    />
                  )}
                  {props.onClose && !props.hideCancel && (
                    <Button
                      label={props.cancelLabel || "Cancel"}
                      type="secondary"
                      onClick={() => {
                        if (props.onClose) {
                          props.onClose();
                        }
                        setOpen(false);
                      }}
                      // ref={cancelButtonRef}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
