import React, { useContext, useEffect, useMemo, useState } from "react";
import { launchableContextDefaultValues } from "tests/defaultValues";
import agent, {
  LaunchableAnalyticsForDate,
  LaunchableDailyAnalytics,
  LaunchableFromAnalytics,
  LaunchableFromList,
} from "server";
import { monthNames, timeout } from "components/utils";
import { OrgContext } from "./OrgContext";
import { NotificationContext } from "./NotificationContext";
import { AxiosRes, BaseRes } from "server/config";
import { processLaunchableData } from "components/Environment/Launchables/utils";

export interface UserMetrics {
  [userId: string]: {
    totalViews: number;
    totalDeploys: number;
  };
}

export interface ILaunchableContext {
  activelaunchables: LaunchableFromList[];
  loadingActiveLaunchables: boolean;
  launchableMetrics: LaunchableFromAnalytics[];
  loadingMetrics: boolean;
  overViewData: { name: string; value: number }[];
  overDeployData: { name: string; value: number }[];
  dailyLaunchableMetrics: {
    launchables: LaunchableAnalyticsForDate[];
    date: string;
  }[];
  userMetricsMapping: UserMetrics;
  dailyOverviewMetrics: DailyOverviewLaunchableMetrics[];
  loadingDailyMetrics: boolean;
  userMetricsList: {
    userId: string;
    totalViews: number;
    totalDeploys: number;
  }[];
  mergeMetricOnLaunchable: (id: string) => { views: number; deploys: number };
  refreshMetrics: () => any;
  refreshActiveLaunchables: () => any;
  refreshAllLaunchableData: () => any;
  deleteLaunchable: (orgID: string, id: string) => any;
}

export const LaunchableContext = React.createContext<ILaunchableContext>(
  launchableContextDefaultValues
);

export interface DailyLaunchableMetrics {
  date: string;
  [key: string]: string | number;
}

export interface DailyOverviewLaunchableMetrics {
  date: string;
  Views: number;
  Deploys: number;
}

export const formatDailyLaunchableMetrics = (date: string) => {
  const transformDate = date.split("-");
  return `${monthNames[Number(transformDate[1]) - 1]} ${Number(
    transformDate[2]
  )}`;
};

interface Props {
  children: React.ReactNode;
}
const LaunchableContextProvider: React.FC<Props> = ({ children }) => {
  const orgContext = useContext(OrgContext);
  const notificationContext = useContext(NotificationContext);
  // Calls API to get Launchables for the active Org
  const [activelaunchables, setActiveLaunchables] = useState<
    LaunchableFromList[]
  >([]);
  const [loadingActiveLaunchables, setLoadingActiveLaunchables] =
    useState<boolean>(false);

  // Calls API to get Metrics for Launchables
  const [launchableMetrics, setLaunchableMetrics] = useState<
    LaunchableFromAnalytics[]
  >([]);
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(false);
  const [overViewData, setOverViewData] = useState<
    { name: string; value: number }[]
  >([]);
  const [overDeployData, setOverDeployData] = useState<
    { name: string; value: number }[]
  >([]);
  const [userMetricsMapping, setUserMetricsMapping] = useState<UserMetrics>({});
  const [userMetricsList, setUserMetricsList] = useState<
    { userId: string; totalViews: number; totalDeploys: number }[]
  >([]);

  //Calls API to get Daily Metrics for Launchables
  const [rawDailyMetrics, setRawDailyMetrics] = useState<
    LaunchableDailyAnalytics[]
  >([]);
  const [dailyLaunchableMetrics, setDailyLaunchableMetrics] = useState<
    {
      launchables: LaunchableAnalyticsForDate[];
      date: string;
    }[]
  >([]);
  const [dailyOverviewMetrics, setDailyOverviewMetrics] = useState<
    DailyOverviewLaunchableMetrics[]
  >([]);

  const [loadingDailyMetrics, setLoadingDailyMetrics] =
    useState<boolean>(false);

  const listLaunchables = async (activeOrgId: string) => {
    setLoadingActiveLaunchables(true);
    const list = await agent.Launchables.listLaunchables(activeOrgId);
    if (list.data) {
      setActiveLaunchables(list.data);
    } else {
      setActiveLaunchables([]);
    }
    setLoadingActiveLaunchables(false);
  };

  const listLaunchableDailyMetrics = async (activeOrgId: string) => {
    setLoadingDailyMetrics(true);
    const metrics = await agent.Launchables.getLaunchableDailyMetrics(
      activeOrgId
    );
    if (metrics.data) {
      setRawDailyMetrics(metrics.data);
    } else {
      setRawDailyMetrics([]);
    }
    setLoadingDailyMetrics(false);
  };

  const listLaunchableMetrics = async (activeOrgId: string) => {
    setLoadingMetrics(true);
    const metrics = await agent.Launchables.getLaunchableMetrics(activeOrgId);
    if (metrics.data) {
      setLaunchableMetrics(metrics.data.Launchables);
    } else {
      setLaunchableMetrics([]);
    }
    setLoadingMetrics(false);
  };

  useEffect(() => {
    if (orgContext.activeOrgId !== "") {
      listLaunchables(orgContext.activeOrgId);
      listLaunchableMetrics(orgContext.activeOrgId);
      listLaunchableDailyMetrics(orgContext.activeOrgId);
    }
  }, [orgContext.activeOrgId]);

  const refreshAllLaunchableData = async () => {
    listLaunchables(orgContext.activeOrgId);
    listLaunchableMetrics(orgContext.activeOrgId);
    listLaunchableDailyMetrics(orgContext.activeOrgId);
  };

  const refreshMetrics = async () => {
    listLaunchableMetrics(orgContext.activeOrgId);
  };

  const refreshActiveLaunchables = async () => {
    await listLaunchables(orgContext.activeOrgId);
  };

  useEffect(() => {
    if (launchableMetrics.length === 0) {
      setOverViewData([]);
      setOverDeployData([]);
      setUserMetricsMapping({});
      setUserMetricsList([]);
      return;
    }
    if (activelaunchables.length === 0) {
      setOverViewData([]);
      setOverDeployData([]);
      setUserMetricsMapping({});
      setUserMetricsList([]);
      return;
    }

    const overViewLaunchables = activelaunchables.filter((l) =>
      launchableMetrics.find((m) => m.id === l.id)
    );

    const overViewData = overViewLaunchables
      .map((l) => ({
        name: l.name,
        value: mergeMetricOnLaunchable(l.id).views,
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 3);

    const overDeployData = activelaunchables
      .map((l) => ({
        name: l.name,
        value: mergeMetricOnLaunchable(l.id).deploys,
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 3);

    const metricMapping = getUserMetricsMapping(activelaunchables);
    const sortedUserMetrics = getSortedUserMetrics(metricMapping);
    setOverViewData(overViewData);
    setOverDeployData(overDeployData);
    setUserMetricsMapping(metricMapping);
    setUserMetricsList(sortedUserMetrics);
  }, [activelaunchables, launchableMetrics]);

  useEffect(() => {
    if (rawDailyMetrics.length === 0) {
      setDailyLaunchableMetrics([]);
      setDailyOverviewMetrics([]);
      return;
    }
    if (activelaunchables.length === 0) {
      setDailyOverviewMetrics([]);
      setDailyLaunchableMetrics([]);
      return;
    }

    const filteredDailyLaunchables = rawDailyMetrics.map((metric) => {
      const filterForActiveLaunchables = metric.launchables.filter((l) =>
        activelaunchables.find((a) => a.id === l.id)
      );
      return { ...metric, launchables: filterForActiveLaunchables };
    });

    const dailyOverviewMetrics = filteredDailyLaunchables.map((metric) => {
      const deploys = metric.launchables.reduce((acc, l) => {
        return acc + l.deployments;
      }, 0);

      const views = metric.launchables.reduce((acc, l) => {
        return acc + l.views;
      }, 0);
      const formattedDate = formatDailyLaunchableMetrics(metric.date);

      return {
        date: formattedDate,
        Deploys: deploys,
        Views: views,
      };
    });
    setDailyOverviewMetrics(dailyOverviewMetrics);
    setDailyLaunchableMetrics(filteredDailyLaunchables);
  }, [activelaunchables, rawDailyMetrics]);

  const mergeMetricOnLaunchable = (id: string) => {
    const metric = launchableMetrics.find((m) => id === m.id);
    if (metric) {
      return {
        deploys: metric.analytics.deployments,
        views: metric.analytics.views,
      };
    }
    return {
      deploys: 0,
      views: 0,
    };
  };

  const deleteLaunchable = async (
    orgID: string,
    id: string
  ): Promise<AxiosRes & BaseRes> => {
    const res = await agent.Launchables.deleteLaunchable(orgID, id);
    if (res.success) {
      const newLaunchable = activelaunchables.filter((l) => l.id !== id);
      setActiveLaunchables(newLaunchable);
    } else {
      notificationContext.showNotification(
        "Error Deleting Launchable",
        res.message,
        "error"
      );
    }
    return res;
  };

  const getUserMetricsMapping = (launchables: LaunchableFromList[]) => {
    const metrics = launchables.reduce((acc, launchable) => {
      const launchableData = processLaunchableData(launchable);
      // Only proceed if queryUserID is not null
      if (launchableData?.userId) {
        const views = mergeMetricOnLaunchable(launchable.id).views;
        const deploys = mergeMetricOnLaunchable(launchable.id).deploys;

        if (!acc[launchableData.userId]) {
          acc[launchableData.userId] = { totalViews: 0, totalDeploys: 0 };
        }

        acc[launchableData.userId].totalViews += views;
        acc[launchableData.userId].totalDeploys += deploys;
      }

      return acc;
    }, {});

    return metrics;
  };

  const getSortedUserMetrics = (userMetrics) => {
    const userMetricsList = Object.keys(userMetrics).map((userId) => ({
      userId,
      totalViews: userMetrics[userId].totalViews,
      totalDeploys: userMetrics[userId].totalDeploys,
    }));

    userMetricsList.sort((a, b) => b.totalDeploys - a.totalDeploys);

    return userMetricsList;
  };

  const providerData = {
    activelaunchables,
    loadingActiveLaunchables,
    launchableMetrics,
    loadingMetrics,
    overViewData,
    overDeployData,
    userMetricsMapping,
    dailyOverviewMetrics,
    loadingDailyMetrics,
    userMetricsList,
    dailyLaunchableMetrics,
    mergeMetricOnLaunchable,
    refreshMetrics,
    refreshActiveLaunchables,
    refreshAllLaunchableData,
    deleteLaunchable,
  };

  return (
    <LaunchableContext.Provider value={providerData}>
      {children}
    </LaunchableContext.Provider>
  );
};

export default LaunchableContextProvider;
