import { Capability } from "contexts/OrgContext";
import { IExec, IExecV0 } from "./Exec.model";
import { IRepo, IRepoV0 } from "./Repo.model";
import { IdeConfig } from "./User.model";
import IWorkspaceTemplate from "./WorkspaceTemplate.model";
import { CustomContainer } from "contexts/WorkspaceContext";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";

export enum WorkspaceStatus {
  Deploying = "DEPLOYING",
  Running = "RUNNING",
  Stopping = "STOPPING",
  Stopped = "STOPPED",
  Starting = "STARTING",
  Deleting = "DELETING",
  Failure = "FAILURE",
}

export enum WorkspaceStartStatus {
  Starting = "STARTING",
  Failure = "FAILURE",
  Started = "STARTED",
}

export enum HealthStatus {
  Healthy = "HEALTHY",
  Unhealthy = "UNHEALTHY",
  Unavailable = "UNAVAILABLE",
}

export enum HealthCheckStatus {
  Healthy = "healthy",
  Unhealthy = "unhealthy",
  Unavailable = "unavailable",
}

export enum VerbBuildStatus {
  Unset = "",
  CreateFailed = "CREATE_FAILED",
  Pending = "PENDING",
  Building = "BUILDING",
  Completed = "COMPLETED",
}

// Also do flatcard confirmations for reset, and danger zone.

export enum WorkspaceClassId {
  TwoByTwo = "2x2",
  TwoByFour = "2x4",
  TwoByEight = "2x8",
  FourBySixteen = "4x16",
  EightByThirtyTwo = "8x32",
  SixteenByThirtyTwo = "16x32",
}

export interface IUserAppApiKey {
  enabled: boolean;
  id: string;
  clientID: string;
  clientSecret: string;
}

export interface IUserAuthPolicy {
  allowedUserAuthIDs: string[];
  allowEveryone: boolean;
  apiKey: IUserAppApiKey;
}

export interface IApplication {
  cloudflareApplicationID: string;
  cloudflareDnsRecordID: string;
  hostname: string;
  name: string;
  serviceType: string;
  port: number;
  userApplicationSetupBash: string;
  policy: IUserAuthPolicy;
  healthCheckID: string;
}

export interface ITunnel {
  tunnelID: string;
  applications: IApplication[];
  tunnelSetupBash: string;
  tunnelStatus: string; // HEALTHY, UNHEALTHY, UNAVAILABLE
  tunnelHealthCheckBash: string;
}

export interface IFileObject {
  url: string;
  path: string;
  metadata: IFileMetadata;
}

export interface IFileMetadata {
  type: IFileType;
}

export enum IFileType {
  ColabFileType = "colab",
  GithubRepoFileType = "github",
  GitlabRepoFileType = "gitlab",
  NotebookFileType = "notebook",
}

export enum NotebookFileType {
  ColabFileType = "colab",
  GithubFileType = "github",
  GitlabFileType = "gitlab",
}

export default interface IWorkspace {
  id: string;
  workspaceGroupId: string;
  workspaceTemplate: IWorkspaceTemplate;
  organizationId: string;
  workspaceClassId: WorkspaceClassId;
  statusMessage: string;
  name: string;
  createdByUserId: string;
  status: WorkspaceStatus;
  dns: string;
  password: string;
  gitRepo?: string;
  instanceType?: string;
  instanceTypeInfo?: GPUInstanceType;
  startupScriptPath?: string;
  version: string;
  region?: string;
  architecture?: string;
  image?: string;
  diskStorage?: string;
  isStoppable: boolean;
  healthStatus: HealthStatus;
  createdAt: string;
  updatedAt: string;
  lastOnlineAt: string;
  ideConfig?: IdeConfig;
  repos?: { [key: string]: IRepoV0 };
  execs?: { [key: string]: IExecV0 };
  reposV1?: { [key: string]: IRepo };
  execsV1?: { [key: string]: IExec };
  verbYaml?: string;
  verbBuildStatus?: VerbBuildStatus;
  exposedPorts?: string[];
  spot?: boolean;
  workspaceCapabilities?: Capability[];
  onContainer?: boolean;
  sshPort?: number;
  healthCheck?: HealthCheck[];
  tunnel?: ITunnel;
  fileObjects?: { [key: string]: IFileObject };
  additionalUsers?: string[];
  baseImage?: string;
  customContainer?: CustomContainer;
  vmOnlyMode?: boolean;
  portMappings: Record<string, string> | null;
  lastStartStatus?: string;
  lastStartStatusMessage?: string;
}

type Timestamp = {
  seconds: number;
  nanos: number;
};

type Labels = {
  environmentId: string;
  organizationId: string;
  userId: string;
  workspaceGroupId: string;
};

type Thresholds = {
  failure_threshold: number;
  success_threshold: number;
};

type ProbeArgs = {
  cmd: string;
  hostLookupId: string;
  port: string;
  user: string;
};

type ProbeHealthCheck = {
  config: {
    initial_delay_seconds: number;
    period_seconds: number;
    timeout_seconds: number;
    should_pause_on_first_success: boolean;
  };
  probe: {
    type: string;
    args: ProbeArgs;
  };
  probe_status: string;
};

type HealthCheckTypes = {
  ProbeHealthCheck: ProbeHealthCheck;
};

export type HealthCheck = {
  health_check_id: string;
  create_time: Timestamp;
  update_time: Timestamp;
  labels: Labels;
  status: string; // "healthy", "unhealthy", "unknown"
  thresholds: Thresholds;
  HealthCheck: HealthCheckTypes;
};
