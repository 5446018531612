import Modal from "components/Modals/Modal";
import CloudMultiplex from "components/OrgPages/ConnectCloud/CloudMultiplex";
import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "components/UI-lib/Button";
import agent from "server";
import { OrgContext } from "contexts/OrgContext";
import { BillingContext } from "contexts/BillingContext";
import { next } from "windups/dist/Windup";
import FlatCard from "components/UI-lib/FlatCard";
import { Transition } from "@headlessui/react";
import { CloudUploadIcon } from "@heroicons/react/outline";
import {
  ShieldCheckIcon,
  TrashIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

export interface StopModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const StopModal: React.FC<StopModalProps> = ({
  show,
  setShow,
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => {
        setShow(false);
      }}
      title={"Stop Instance"}
      body={
        <>
          <div className="w-[450px] ">
            <div className="flex flex-col">
              <div className="flex flex-row items-start justify-center">
                <ShieldCheckIcon className="h-6 w-6 dark:text-white mx-auto" />
                <p className="text-sm dark:text-white ml-1">
                  Files on your instance will be saved! This incurs a small
                  storage fee.
                </p>
              </div>
              <div className="flex flex-row items-start justify-center mt-2">
                <TrashIcon className="h-7 w-7 dark:text-white mx-auto" />
                <p className="text-sm dark:text-white ml-1">
                  If you don't need your files anymore, delete this instance to
                  prevent any future charges.
                </p>
              </div>
              <div className="flex flex-row items-start justify-center mt-2">
                <ExclamationTriangleIcon className="h-7 w-7 dark:text-white mx-auto" />
                <p className="text-sm dark:text-white ml-1">
                  You may not be able to start this instance again if
                  availability for the current instance is low.
                </p>
              </div>
            </div>
          </div>
        </>
      }
      cancelLabel="Cancel"
      confirmLabel={"Stop Instance"}
      onSuccess={() => onSuccess()}
    />
  );
};
