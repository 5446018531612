/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from "react";
import InputField from "components/UI-lib/InputField";
import Button from "components/UI-lib/Button";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { UserIcon } from "@heroicons/react/24/solid";
import { UserContext } from "contexts/UserContext";
import {
  AddUserToApplicationRequest,
  Applications,
  ModifyApplicationRequest,
  RemoveUserFromApplicationRequest,
} from "server/applications";
import agent from "server";
import { IApplication } from "models/Workspace.model";
import { get, set } from "lodash";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { OrgContext } from "contexts/OrgContext";
import { AccessProps } from "./Access";
import { fallbackCopyTextToClipboard } from "components/utils";
import Spinner from "components/UI-lib/Spinner";
import CopyField from "components/UI-lib/CopyField";
import Prism from "prismjs";
import "prismjs/components/prism-bash";
import "prismjs/themes/prism-okaidia.css";
import "prismjs/components/prism-go.min.js";
import "prismjs/components/prism-rust.min.js";
import "prismjs/components/prism-python";

const CreateKeys: React.FC<AccessProps> = (props) => {
  const wsContext = useContext(WorkspaceContext);
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const [shareLoading, setShareLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [makePublic, clickedMakePublic] = useState(false);
  const [currentDeletionIndex, setCurrentDeletionIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  const { me } = useContext(UserContext);
  const [authUserList, setAuthUserList] = useState<string[]>(
    props.application.policy.allowedUserAuthIDs
  );
  const tabs = ["Bash", "Python", "Node.js", "Go", "Rust"];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  // Init
  useEffect(() => {
    Prism.highlightAll();
  }, [activeTab]);

  const handleGenerateAPIKey = async () => {
    const args: ModifyApplicationRequest = {
      applicationName: props.applicationName,
    };
    try {
      setShareLoading(true);
      const response = await Applications.addApiKey(props.workspaceId, args);
      if (response.success) {
        setInlineAlertMessage(`Api key generated successfully`);
        setInlineAlertSeverity("success");

        // if (response.data) {
        //   for (let i = 0; i < response.data.applications.length; i++) {
        //     const app = response.data.applications[i];

        //     if (
        //       app.cloudflareApplicationID ===
        //       props.application.cloudflareApplicationID
        //     ) {
        //       // update the authUserList
        //       setAuthUserList([...app.policy.allowedUserAuthIDs]);
        //       break;
        //     }
        //   }
        // }

        setLoading(true);
        // BANANA: force a refresh
        await wsContext.reloadWorkspaces(orgContext.activeOrgId);
        setLoading(false);

        setShareLoading(false);
        setEmail("");
      } else {
        setInlineAlertMessage(`Error: ${response.message}`);
        setInlineAlertSeverity("error");
        setShareLoading(false);
      }
    } catch (error) {
      // error - show error notification
      setInlineAlertMessage("Failed to add user.");
      setInlineAlertSeverity("error");
      setShareLoading(false);
    }
    setShareLoading(false);
  };

  const handleRevokeAPIKey = async () => {
    const args: ModifyApplicationRequest = {
      applicationName: props.applicationName,
    };
    try {
      setShareLoading(true);
      const response = await Applications.revokeApiKey(props.workspaceId, args);
      if (response.success) {
        setInlineAlertMessage(`Api key revoked successfully`);
        setInlineAlertSeverity("success");

        // BANANA: force a refresh
        wsContext.reloadWorkspaces(orgContext.activeOrgId);

        setShareLoading(false);
        setEmail("");
      } else {
        setInlineAlertMessage(`Error: ${response.message}`);
        setInlineAlertSeverity("error");
        setShareLoading(false);
      }
    } catch (error) {
      // error - show error notification
      setInlineAlertMessage("Failed to add user.");
      setInlineAlertSeverity("error");
      setShareLoading(false);
    }
    setShareLoading(false);
  };

  const getBashCommand = (
    clientID: string,
    clientSecret: string,
    hostname: string
  ) =>
    `curl \\\n` +
    `-H "CF-Access-Client-Id: ${clientID}" \\\n` +
    `-H "CF-Access-Client-Secret: ${clientSecret}" \\\n` +
    `${hostname}`;

  const getPythonCommand = (
    clientID: string,
    clientSecret: string,
    hostname: string
  ) =>
    "import requests \n" +
    "headers = { \n" +
    `'CF-Access-Client-Id': '${clientID}', \n` +
    `'CF-Access-Client-Secret': '${clientSecret}', \n` +
    "} \n" +
    `response = requests.get('${hostname}', headers=headers)`;

  const getNodeJsCommand = (
    clientID: string,
    clientSecret: string,
    hostname: string
  ) =>
    `var axios = require('axios'); \n` +
    `var config = { \n` +
    `method: 'get', \n` +
    `url: '${hostname}', \n` +
    `headers: { \n` +
    `'CF-Access-Client-Id': '${clientID}', \n` +
    `'CF-Access-Client-Secret': '${clientSecret}' \n` +
    `} \n` +
    `}; \n` +
    `axios(config) \n` +
    `.then(function (response) { \n` +
    `console.log(JSON.stringify(response.data)); \n` +
    `}) \n` +
    `.catch(function (error) { \n` +
    `  console.log(error); \n` +
    `});`;

  const getGoCommand = (
    clientID: string,
    clientSecret: string,
    hostname: string
  ) =>
    `url := "${hostname}" \n` +
    `method := "GET" \n` +
    `client := &http.Client { \n` +
    `} \n` +
    `req, err := http.NewRequest(method, url, nil) \n` +
    `if err != nil { \n` +
    `fmt.Println(err) \n` +
    `} \n` +
    `req.Header.Add("CF-Access-Client-Id", "${clientID}") \n` +
    `req.Header.Add("CF-Access-Client-Secret", "${clientSecret}") \n` +
    `res, err := client.Do(req) \n` +
    `defer res.Body.Close() \n` +
    `body, err := ioutil.ReadAll(res.Body) \n` +
    `fmt.Println(string(body))`;

  const getRustCommand = (
    clientID: string,
    clientSecret: string,
    hostname: string
  ) =>
    `let mut headers = HashMap::new(); \n` +
    `headers.insert("CF-Access-Client-Id", "${clientID}"); \n` +
    `headers.insert("CF-Access-Client-Secret", "${clientSecret}"); \n` +
    `let client = reqwest::Client::new(); \n` +
    `let res = client.get("${hostname}") \n` +
    `.headers(headers) \n` +
    `.send() \n` +
    `.await?; \n` +
    `println!("{}", res.text().await?); \n` +
    `Ok(())`;

  const getCommandByTab = (tab) => {
    switch (tab) {
      case "Bash":
        return getBashCommand(
          props.application.policy.apiKey.clientID,
          props.application.policy.apiKey.clientSecret,
          props.application.hostname
        );
      case "Python":
        return getPythonCommand(
          props.application.policy.apiKey.clientID,
          props.application.policy.apiKey.clientSecret,
          props.application.hostname
        );
      case "Node.js":
        return getNodeJsCommand(
          props.application.policy.apiKey.clientID,
          props.application.policy.apiKey.clientSecret,
          props.application.hostname
        );
      case "Go":
        return getGoCommand(
          props.application.policy.apiKey.clientID,
          props.application.policy.apiKey.clientSecret,
          props.application.hostname
        );
      case "Rust":
        return getRustCommand(
          props.application.policy.apiKey.clientID,
          props.application.policy.apiKey.clientSecret,
          props.application.hostname
        );

      default:
        return "";
    }
  };
  const languageMap = {
    Bash: "bash",
    Python: "python",
    "Node.js": "javascript",
    Go: "go",
    Rust: "rust",
  };

  return (
    <>
      <div className="w-full flex flex-row justify-between items-clientSecret mb-2">
        <p className="text-xs text-gray-500 dark:text-secondary mb-1">
          Create or manage an API Key for your application.
        </p>

        {!props.application.policy.apiKey.enabled && (
          <Button
            type="secondary"
            label="Generate Key"
            className="border-1 border-gray-200 h-10 mr-2"
            onClick={() => {
              handleGenerateAPIKey();
              //   setShowEditModal(true);
            }}
            disabled={false}
          />
        )}
      </div>
      <InlineNotification
        show={!!inlineAlertMessage}
        severity={inlineAlertSeverity}
        text={inlineAlertMessage}
        autoClose={false}
        onClose={() => setInlineAlertMessage("")}
      />

      <div className="">
        {/* <div className="flex flex-row justify-between"> */}

        {props.application.policy.apiKey.enabled && (
          <>
            <div className="mt-5 border-t border-gray-200 dark:border-zinc-800">
              <dl className="divide-y divide-gray-200 dark:divide-zinc-800">
                {/* ITEM */}
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500 dark:text-secondary">
                    CF-Access-Client-Id
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 dark:text-cyan-100 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow truncate">
                      {props.application.policy.apiKey.clientID}
                    </span>
                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="bg-white dark:bg-zinc-900 rounded-md font-medium text-cyan-600 dark:text-cyan-300 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-highlightLighter"
                        onClick={() => {
                          setInlineAlertMessage(
                            "Access Client ID copied to clipboard"
                          );
                          setInlineAlertSeverity("success");
                          fallbackCopyTextToClipboard(
                            props.application.policy.apiKey.clientID,
                            true
                          );
                          setTimeout(() => setInlineAlertMessage(""), 2000);
                        }}
                      >
                        Copy
                      </button>
                    </span>
                  </dd>
                </div>
                {/* ITEM */}
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500 dark:text-secondary">
                    CF-Access-Client-Secret
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 dark:text-cyan-100 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow truncate">
                      {props.application.policy.apiKey.clientSecret}
                    </span>
                    <span className="ml-4 flex-shrink-0">
                      <button
                        type="button"
                        className="bg-white dark:bg-zinc-900 rounded-md font-medium text-cyan-600 dark:text-cyan-300 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-highlightLighter"
                        onClick={() => {
                          setInlineAlertMessage(
                            "Access Client Secret copied to clipboard"
                          );
                          setInlineAlertSeverity("success");
                          fallbackCopyTextToClipboard(
                            props.application.policy.apiKey.clientSecret,
                            true
                          );
                          setTimeout(() => setInlineAlertMessage(""), 2000);
                        }}
                      >
                        Copy
                      </button>
                    </span>
                  </dd>
                </div>
                {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"> */}
                <dl className="divide-y divide-gray-200 dark:divide-zinc-800 flex flex-row justify-end items-center">
                  <Button
                    type="danger"
                    label="Revoke Key"
                    className="border-1 border-gray-200 h-6 px-2 py-1 text-xs mt-2"
                    onClick={async () => {
                      handleRevokeAPIKey();
                      // setDeleteButtonLoading(true);
                      // await props.onDelete();
                      // setDeleteButtonLoading(false);
                    }}
                    // loading={deleteButtonLoading}
                    disabled={false}
                  />
                </dl>
                {/* </div> */}
              </dl>
            </div>
          </>
        )}
      </div>
      {!props.application.policy.apiKey.enabled && loading && (
        <>
          <div className="mt-5 border-t border-gray-200 dark:border-zinc-800">
            <dl className="divide-y divide-gray-200 dark:divide-zinc-800">
              {/* ITEM */}
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-secondary">
                  Access Client ID
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 dark:text-cyan-100 sm:mt-0 sm:col-span-2 flex flex-row items-center justify-end">
                  <Spinner type="secondary" />
                </dd>
              </div>
              {/* ITEM */}
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-secondary">
                  Access Client Secret
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 dark:text-cyan-100 sm:mt-0 sm:col-span-2 flex flex-row items-center justify-end">
                  <Spinner type="secondary" />
                </dd>
              </div>
            </dl>
          </div>
        </>
      )}

      {props.application.policy.apiKey.clientID !== "" &&
        props.application.policy.apiKey.clientSecret !== "" && (
          <>
            <p className="text-xs text-gray-500 dark:text-secondary mt-3 mb-1">
              Here's an example of how to use the key in a few languages:
            </p>
            <div className="flex space-x-2 overflow-y-visible scrollbar-hide">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 font-medium text-sm rounded-md 
                  ${
                    activeTab === tab
                      ? "border border-gray-300 dark:border-zinc-800 bg-highlight text-white hover:bg-highlight dark:hover:bg-highlightLighter"
                      : "bg-white border hover:bg-gray-50 dark:hover:bg-zinc-700 dark:bg-zinc-900 dark:border-zinc-800 dark:text-cyan-100"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </div>
            <pre
              className={`prism-code language-${languageMap[activeTab]}`}
              key={activeTab}
            >
              <code className="">
                <span className="token plain">
                  {getCommandByTab(activeTab)}
                </span>
              </code>
            </pre>
            <div className="w-full flex flex-row justify-end items-center">
              <button
                type="button"
                className="bg-white dark:bg-zinc-900 rounded-md font-medium text-cyan-600 dark:text-cyan-300 hover:text-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-highlightLighter"
                onClick={() => {
                  const command = getCommandByTab(activeTab);
                  setInlineAlertMessage(
                    `${
                      activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
                    } command copied to clipboard`
                  );
                  setInlineAlertSeverity("success");
                  fallbackCopyTextToClipboard(command, true);
                  setTimeout(() => setInlineAlertMessage(""), 2000);
                }}
              >
                Copy
              </button>
            </div>
          </>
        )}
    </>
  );
};

export default CreateKeys;
