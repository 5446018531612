import React, { useContext, useEffect, useMemo, useState } from "react";
import { launchableContextDefaultValues } from "tests/defaultValues";
import agent, {
  LaunchableAnalyticsForDate,
  LaunchableDailyAnalytics,
  LaunchableFromAnalytics,
  LaunchableFromList,
} from "server";
import {
  fetchNotebookList,
  getNotebookFileTypeFromUrl,
  monthNames,
  timeout,
} from "components/utils";
import { OrgContext } from "./OrgContext";
import { NotificationContext } from "./NotificationContext";
import { AxiosRes, BaseRes } from "server/config";
import { getLaunchableQueryParams } from "components/Environment/Launchables/Deploy/shared/utils";
import { DiscoverCardData } from "components/Discover";
import { get } from "lodash";

export interface INotebookRenderContext {
  getNotebookRenderHtml: (file: string) => Promise<string>;
}

export const NotebookRenderContext =
  React.createContext<INotebookRenderContext>({
    getNotebookRenderHtml: async () => "",
  });

interface Props {
  children: React.ReactNode;
}
const NotebookRenderContextProvider: React.FC<Props> = ({ children }) => {
  const [notebookRenderMap, setNotebookRenderMap] =
    useState<Record<string, string>>();

  const fetchAndSaveNotebookHTML = async () => {
    try {
      const data: DiscoverCardData[] = await fetchNotebookList();

      // Function to process a batch of cards
      const processBatch = async (batch) => {
        const promises = batch.map((card) => {
          if (card.launchable_link) {
            let queryParams = "";
            try {
              queryParams = new URL(card.launchable_link).search;
            } catch (error) {
              console.error("Error parsing URL:", error);
              return Promise.resolve(); // Skip this card if there's an error
            }
            const { queryFile } = getLaunchableQueryParams(queryParams);
            if (queryFile) {
              return getNotebookRenderHtml(queryFile);
            }
            return Promise.resolve();
          }
          return Promise.resolve();
        });
        return Promise.all(promises);
      };

      // Split data into chunks of 3
      const chunkSize = 3;
      const chunks: any = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        chunks.push(data.slice(i, i + chunkSize));
      }

      // Process each chunk sequentially, with up to 3 at a time
      for (const chunk of chunks) {
        await processBatch(chunk);
        await timeout(3000);
      }
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  };

  // useEffect(() => {
  //   fetchAndSaveNotebookHTML();
  // }, []);

  const getNotebookRenderHtml = async (file: string): Promise<string> => {
    if (notebookRenderMap && notebookRenderMap[file]) {
      return notebookRenderMap[file];
    }

    const res = await agent.Notebooks.preview({
      sourceVal: {
        url: file,
        type: getNotebookFileTypeFromUrl(file),
      },
    });
    if (res && res.data) {
      setNotebookRenderMap((prev) => {
        return {
          ...prev,
          [file]: res.data,
        };
      });
      return res.data;
    } else {
      return "";
    }
  };

  const providerData = {
    getNotebookRenderHtml,
  };

  return (
    <NotebookRenderContext.Provider value={providerData}>
      {children}
    </NotebookRenderContext.Provider>
  );
};

export default NotebookRenderContextProvider;
