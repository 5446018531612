import { LOCALSTORAGE_USER_CACHE_PREFIX } from "constants/index";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import agent from "server";

const Invite: React.FC = () => {
  console.log("Route: /invite");

  const history = useHistory();
  const { search } = useLocation<{ search: string }>();
  const { token, orgID } = queryString.parse(search);

  useEffect(() => {
    const setupInviteFlow = async (token: string) => {
      const res = await agent.Organizations.validateInvite(token);
      if (!res.success || !res.data?.valid) {
        console.log("errorPage1");
        history.push("/error/500", {
          title: "Invite Link Error",
          description:
            `${res.message}. Your token is either expired or malformed. Ask for another and try again!` ||
            "Your token is either expired or malformed. Ask for another and try again!",
        });
        return;
      }
      // set token as we authenticate
      window.localStorage.setItem(
        `${LOCALSTORAGE_USER_CACHE_PREFIX}-invite-token`,
        token
      );

      // redirect to home
      history.push(`/org/${orgID}/environments`);
      window.location.reload();
    };
    if (token) setupInviteFlow(token as string);
  }, [search, token, history]);

  return <></>;
};

export default Invite;
