import React from "react";
import { Switch } from "@headlessui/react";
import Spinner from "./Spinner";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface ToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  labelBefore?: string;
  label: string;
  sublabel?: string;
  extras?: any;
  description?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Toggle: React.FC<ToggleProps> = (props) => {
  return (
    <div className="flex items-center justify-between">
      {props.labelBefore && (
        <span className="text-sm font-medium text-gray-900 dark:text-secondary mr-4">
          {props.labelBefore}
        </span>
      )}
      <div className="flex items-center">
        {props.loading ? (
          <div className="min-w-[64px]">
            <Spinner type="secondary" />
          </div>
        ) : (
          <>
            <Switch
              onClick={(e) => {
                e.stopPropagation();
              }}
              checked={props.checked}
              onChange={props.onChange}
              disabled={props.disabled}
              className={classNames(
                props.checked ? "bg-highlight" : "bg-gray-200",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlightLighter"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  props.checked ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
            <div className="ml-4">
              <span className="text-sm font-medium text-gray-900 dark:text-secondary">
                {props.label}
              </span>
              {props.description && (
                <span className="block text-sm text-gray-500 dark:text-secondary">
                  {props.description}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Toggle;
