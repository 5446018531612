import {
  BookOpenIcon,
  CircleStackIcon,
  CloudIcon,
  CpuChipIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/solid";
import { InstanceAttribute } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/InstanceAttribute";
import { ChipCarousel } from "components/Environment/shared/Carousel/ChipCarousel";
import { SimpleContainerOverview } from "components/Environment/shared/ContainerOverview";
import {
  displayPlatformType,
  formatMemory,
} from "components/Environment/utils";
import React from "react";
import PortsIcon from "assets/img/svg/ports.svg?react";
import WireIcon from "assets/img/svg/wire.svg?react";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { classNames, replaceRawWithBlob } from "components/utils";
import { InboxStackIcon } from "@heroicons/react/24/outline";
import Chip from "components/Environment/Create/chips";
import { Badge } from "components/Graphs/Badge";

interface LaunchableConfigurationViewProps {
  instanceObject: GPUInstanceType | null;
  loadingInstance: boolean;
  diskStorage: string | null;
  file: string | null;
  shortFileName: string;
  python: string | null;
  cuda: string | null;
  baseImage: string | null;
  forceJupyterInstall: boolean | null;
  ports: Record<string, string> | null;
  disabled?: boolean;
}

const LaunchableConfigurationView: React.FC<
  LaunchableConfigurationViewProps
> = ({
  instanceObject,
  loadingInstance,
  diskStorage,
  file,
  shortFileName,
  python,
  cuda,
  baseImage,
  forceJupyterInstall,
  ports,
  disabled,
}) => (
  <div className="flex flex text-gray-900 dark:text-white overflow-x-auto">
    <div className="flex flex-row w-full justify-start">
      <div>
        <div className="flex flex-row items-center mb-1">
          <CpuChipIcon className="h-6 w-6 mr-1" />
          <span>Compute</span>
        </div>
        {!loadingInstance ? (
          <>
            {instanceObject ? (
              <div className="flex flex-row items-center">
                {instanceObject.supported_gpus && (
                  <Chip
                    selected={true}
                    chipName={instanceObject.supported_gpus[0].name || ""}
                    manufacturerName={
                      instanceObject.supported_gpus[0].manufacturer || ""
                    }
                    onClick={() => {}}
                    className={classNames("h-[68px] mr-2 flex-shrink-0")}
                  />
                )}
                <div>
                  <InstanceAttribute
                    instanceType={instanceObject.type}
                    isGPUInstance={!!instanceObject.supported_gpus}
                    vcpu={instanceObject.vcpu}
                    memory={formatMemory(
                      instanceObject.memory,
                      instanceObject.workspace_groups[0].platformType
                    )}
                    gpuManufacturer={
                      instanceObject.supported_gpus
                        ? instanceObject.supported_gpus[0].manufacturer
                        : ""
                    }
                    gpuName={
                      instanceObject.supported_gpus
                        ? instanceObject.supported_gpus[0].name
                        : ""
                    }
                    gpuMemory={
                      instanceObject.supported_gpus
                        ? instanceObject.supported_gpus[0].memory
                        : ""
                    }
                    gpuCount={
                      instanceObject.supported_gpus
                        ? instanceObject.supported_gpus[0].count
                        : 0
                    }
                  />
                  <div className="flex flex-col mt-2">
                    <div className="flex flex-row">
                      <div className="flex flex-row mb-1 items-center mr-2">
                        <CircleStackIcon className="h-6 w-6 mr-1" />
                        <span className="text-sm">
                          {instanceObject.elastic_root_volume
                            ? diskStorage + "GiB"
                            : instanceObject.supported_storage
                            ? instanceObject.supported_storage[0].size
                            : "120GiB"}
                        </span>
                      </div>
                      <div className="flex flex-row mb-1 items-center">
                        <CloudIcon className="h-6 w-6 mr-1" />
                        <span className="text-sm">
                          {displayPlatformType(
                            (instanceObject.workspace_groups &&
                              instanceObject.workspace_groups[0].platformType.toUpperCase()) ||
                              "Unknown"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col">
                  <div className="flex flex-row items-center text-rose-500 mr-3">
                    <ChipCarousel />
                  </div>
                  <div className="flex flex-row items-center text-rose-500 mr-3">
                    <span className="text-sm">
                      (An error occured fetching instance details)
                    </span>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="flex flex-row">
            <ChipCarousel />
          </div>
        )}
      </div>
      <div className="text-white mr-3 items-center flex ml-3 mr-5">
        <WireIcon width={50} height={50} />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row items-center mb-2">
          <RectangleGroupIcon className="h-6 w-6 mr-1" />
          <span>Container</span>
        </div>
        <div>
          <SimpleContainerOverview
            pythonVersion={python || ""}
            cudaVersion={cuda || ""}
            baseImage={baseImage || ""}
          />
        </div>
        {forceJupyterInstall && (
          <span className="mt-2 text-xs">- Preinstall Jupyter</span>
        )}
      </div>
      {file && (
        <>
          <div className="text-white mr-3 items-center flex ml-5 mr-5">
            <WireIcon width={50} height={50} />
          </div>
          <div className="flex flex-col text-sm">
            <div className="flex flex-row items-center">
              <InboxStackIcon className="h-6 w-6 mr-1" />
              <span>Files</span>
            </div>
            <div className="flex flex-row mt-1 w-max">
              <span className="text-sm">{shortFileName}</span>
            </div>
            <div className="flex flex-row mt-1">
              <span
                className="text-sm hover:underline text-highlight cursor-pointer"
                onClick={() => {
                  if (disabled) return;
                  window.open(
                    replaceRawWithBlob(file) || "",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                View Source
              </span>
            </div>
          </div>
        </>
      )}
      {ports && (
        <>
          <div className="text-white mr-3 items-center flex ml-5 mr-5">
            <WireIcon width={50} height={50} />
          </div>
          <div className="flex flex-col items-center">
            <div className="flex flex-row mt-[2px] items-center w-max">
              <PortsIcon height={"14px"} width={"32px"} />
              <div className="flex flex-row">
                <span className="text-sm ml-1">Exposed Ports</span>
              </div>
            </div>
            <div className="w-full">
              <span className="text-xs max-w-[250px] mt-1">
                {Object.entries(ports)
                  .map(([name, port]) => `${name}:${port}`)
                  .join(" | ")}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);

export default LaunchableConfigurationView;
