/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Button from "components/UI-lib/Button";

import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import { PermissionHierarchyType } from "models/Permission.model";
import {
  classNames,
  displayPermissionHierarchyType,
  timeout,
} from "components/utils";
import { PermissionsContext } from "contexts/PermissionsContext";
import AccessCard from "components/AccessPanel/AccessCard";
import AccessLinkCard from "components/AccessPanel/AccessCard/AccessLinkCard";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { ReactMultiEmail } from "react-multi-email";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PaperAirplaneIcon,
  UserGroupIcon,
  UserPlusIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import CopyField from "components/UI-lib/CopyField";
import "./airplane.scss";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";

const TeamInvite: React.FC = (props) => {
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const [showInviteLink, setShowInviteLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("success");
  const [focused, setFocused] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [didSendEmail, setDidSendEmail] = useState(false);
  const [fly, setFly] = useState(false);

  const handleEmailSend = async () => {
    setDidSendEmail(false);
    setFly(false);
    setInlineAlertMessage("");
    setLoading(true);
    const res = await orgContext.inviteByEmail(
      orgContext.activeOrgId,
      emailList
    );
    if (res.success) {
      setLoading(false);
      setDidSendEmail(true);
      setFly(true);
      setEmailList([]);
      await timeout(2000);
      setFly(false);
    } else {
      setInlineAlertSeverity("error");
      setInlineAlertMessage(
        "Unable to send email invite. Try again or Contact Support."
      );
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col bg-primaryBg dark:text-secondary rounded-md px-4 py-4 w-full">
      <div className="flex flex-row items-center">
        <UserPlusIcon className="w-5 h-5 mr-2" />
        <p className="text-md text-gray-700 dark:text-secondary">
          Invite new members by email address
        </p>
      </div>
      {orgContext.activeOrg?.isBlocked !== true &&
        userContext.me?.isBlocked !== true && (
          <>
            <div className="flex flex-col w-full mb-2 mt-5 relative">
              {didSendEmail && (
                <div className="flex flex-row text-highlight items-center mb-1">
                  <div
                    className="text-sm mr-1 ml-1 cursor-pointer"
                    onClick={() => {
                      setDidSendEmail(false);
                    }}
                  >
                    Email Sent!
                  </div>
                </div>
              )}
              {fly && (
                <>
                  <div className={classNames("absolute z-50 top-[-5px] fly")}>
                    <PaperAirplaneIcon className="w-6 h-6 text-highlight" />
                  </div>
                </>
              )}
              <ReactMultiEmail
                placeholder="Input emails"
                emails={emailList}
                onChange={(_emails: string[]) => {
                  setEmailList(_emails);
                  setDidSendEmail(false);
                }}
                autoFocus={true}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      <div data-tag-item>{email}</div>
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        {didSendEmail ? (
                          <CheckCircleIcon className="w-5 h-5" color="green" />
                        ) : (
                          "×"
                        )}
                      </span>
                    </div>
                  );
                }}
              />
              <p className="text-xs text-gray-500 mt-1 mb-5">
                Press spacebar after each email
              </p>
            </div>
            <InlineNotification
              className="mb-5"
              show={!!inlineAlertMessage}
              severity={inlineAlertSeverity || "info"}
              text={inlineAlertMessage}
              autoClose={false}
              onClose={() => setInlineAlertMessage("")}
            />
            <div className="flex flex-col">
              <div className="flex flex-row">
                <Button
                  label="Send Emails"
                  onClick={() => {
                    handleEmailSend();
                  }}
                  type="primary"
                  loading={loading}
                  disabled={emailList.length <= 0 || loading}
                  style={{ marginRight: "10px" }}
                />

                <Button
                  label="Generate Invite Link"
                  onClick={() => {
                    setShowInviteLink(!showInviteLink);
                  }}
                  type="secondary"
                  style={{ marginRight: "10px" }}
                  iconRight={
                    showInviteLink ? (
                      <ChevronDownIcon className="w-5 h-5 ml-1" />
                    ) : (
                      <ChevronRightIcon className="w-5 h-5 ml-1" />
                    )
                  }
                />
              </div>
              <div className="mt-5">
                <Transition
                  show={showInviteLink}
                  // as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <GenerateInviteLink />
                </Transition>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default TeamInvite;

const GenerateInviteLink: React.FC = (props) => {
  const [inviteToken, setInviteToken] = useState("");
  const orgContext = useContext(OrgContext);

  const getLink = async (id: string) => {
    const res = await orgContext.getInviteLink(id);
    setInviteToken(res);
  };

  useEffect(() => {
    if (!orgContext.isLoading && orgContext.activeOrgId !== undefined) {
      getLink(orgContext.activeOrgId);
    }
  }, [orgContext.isLoading, orgContext.activeOrgId]);

  return (
    <div className="flex flex-col justify-between">
      {inviteToken.length > 0 && (
        <>
          <h4 className="text-sm text-gray-900 dark:text-secondary">
            Copy the link below to invite someone to your organization:
          </h4>
          <h6 className="text-xs text-gray-900 dark:text-secondary">
            (it expires in 7 days)
          </h6>
        </>
      )}
      <div className="mt-3 items-center">
        {inviteToken.length > 0 ? (
          <CopyField
            value={`${
              isKasAuthFlow
                ? CONSOLE_1_KAS_DEPLOYMENT_URL
                : CONSOLE_1_AUTH0_DEPLOYMENT_URL
            }/invite?token=${inviteToken}&orgID=${orgContext.activeOrgId}`}
            style={{ width: "90%" }}
            analytics={{
              userid: "unused",
              eventLabel: "unused",
            }}
          />
        ) : (
          <h3>Generating link</h3>
        )}
      </div>
    </div>
  );
};
