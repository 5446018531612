import React, { act, useContext, useEffect, useMemo, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import FlatCard from "components/UI-lib/FlatCard";
import { classNames } from "components/utils";
import { AreaChart } from "components/Graphs/AreaGraph";
import AreaChartWrapper from "./AreaChartWrapper";
import {
  LaunchableContext,
  formatDailyLaunchableMetrics,
} from "contexts/LaunchableContext";
import LaunchableLeaderboard from "./Leaderboard";
import { useHistory } from "react-router";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import MultiSelectDropdown from "components/UI-lib/MultiSelectDropDown";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import { xorWith } from "lodash";
import LaunchableGraph from "./LaunchableGraph";

const chartdata = [
  {
    date: "May 1",
    Views: 2890,
    Deploys: 2338,
  },
  {
    date: "May 2",
    Views: 2756,
    Deploys: 2103,
  },
  {
    date: "May 3",
    Views: 3322,
    Deploys: 2194,
  },
  {
    date: "May 4",
    Views: 3470,
    Deploys: 2108,
  },
  {
    date: "May 5",
    Views: 3475,
    Deploys: 1812,
  },
  {
    date: "May 6",
    Views: 3129,
    Deploys: 1726,
  },
  {
    date: "May 7",
    Views: 3490,
    Deploys: 1982,
  },
  {
    date: "May 8",
    Views: 2903,
    Deploys: 2012,
  },
  {
    date: "May 9",
    Views: 2643,
    Deploys: 2342,
  },
  {
    date: "May 10",
    Views: 2837,
    Deploys: 2473,
  },
  {
    date: "May 11",
    Views: 2954,
    Deploys: 3848,
  },
  {
    date: "May 12",
    Views: 3239,
    Deploys: 3736,
  },
  {
    date: "May 13",
    Views: 2324,
    Deploys: 1800,
  },
  {
    date: "May 14",
    Views: 4343,
    Deploys: 1920,
  },
  {
    date: "May 15",
    Views: 3200,
    Deploys: 1234,
  },
  {
    date: "May 16",
    Views: 2303,
    Deploys: 2100,
  },
  {
    date: "May 17",
    Views: 3300,
    Deploys: 2500,
  },
  {
    date: "May 18",
    Views: 3230,
    Deploys: 1800,
  },
  {
    date: "May 19",
    Views: 3004,
    Deploys: 1750,
  },
  {
    date: "May 20",
    Views: 3600,
    Deploys: 2200,
  },
  {
    date: "May 21",
    Views: 2543,
    Deploys: 2100,
  },
  {
    date: "May 22",
    Views: 3232,
    Deploys: 2000,
  },
  {
    date: "May 23",
    Views: 2000,
    Deploys: 1400,
  },
  {
    date: "May 24",
    Views: 3234,
    Deploys: 1234,
  },
  {
    date: "May 25",
    Views: 2400,
    Deploys: 1700,
  },
  {
    date: "May 26",
    Views: 2405,
    Deploys: 1600,
  },
  {
    date: "May 27",
    Views: 4300,
    Deploys: 1800,
  },
  {
    date: "May 28",
    Views: 4400,
    Deploys: 1400,
  },
  {
    date: "May 29",
    Views: 2345,
    Deploys: 1324,
  },
  {
    date: "May 30",
    Views: 3245,
    Deploys: 1200,
  },
];

const LaunchableMetrics: React.FC = () => {
  console.log("Route: /org/:orgId/launchables/metrics");
  const orgContext = useContext(OrgContext);
  const launchableContext = useContext(LaunchableContext);
  const history = useHistory();
  if (orgContext.activeOrg) {
    return (
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-5">
        <div className="px-4 sm:px-6 lg:px-8">
          <nav className="flex mb-5" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <a
                    onClick={() => {
                      history.push(
                        `/org/${orgContext.activeOrgId}/launchables`
                      );
                    }}
                    className="text-sm font-medium text-gray-500 dark:text-secondary hover:text-gray-700 dark:hover:text-gray-300 cursor-pointer"
                  >
                    Launchables
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <a className="ml-4 text-sm font-medium text-gray-500 dark:text-secondary">
                    Metrics
                  </a>
                </div>
              </li>
            </ol>
          </nav>
          <div className="flex">
            <div className="sm:flex-auto">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Launchable Metrics
                </h1>
                <div className="flex">
                  <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                    Monitor your launchable metrics
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <FlatCard>
              <LaunchableGraph />
            </FlatCard>
            <div className="flex flex-row w-full mt-8">
              <FlatCard>
                <div className="flex flex-col">
                  <div className="flex flex-col justify-start mb-8">
                    <h1 className="text-xl font-medium dark:text-white">
                      Total Views
                    </h1>
                    <p className="text-sm text-gray-700 dark:text-secondary">
                      Last 30 days
                    </p>
                  </div>
                  <div className="flex w-full">
                    <AreaChartWrapper
                      loading={launchableContext.loadingDailyMetrics}
                      data={launchableContext.dailyOverviewMetrics}
                      noDataLabel="No Data Available"
                      categories={["Views"]}
                      placeholderData={chartdata}
                      placeholderCategories={["Views"]}
                    />
                  </div>
                </div>
              </FlatCard>
              <div className="mr-3 ml-3"></div>
              <FlatCard>
                <div className="flex flex-col">
                  <div className="flex flex-col justify-start mb-8">
                    <h1 className="text-xl font-medium dark:text-white">
                      Total Deploys
                    </h1>
                    <p className="text-sm text-gray-700 dark:text-secondary">
                      Last 30 days
                    </p>
                  </div>
                  <div className="flex w-full">
                    <AreaChartWrapper
                      loading={launchableContext.loadingDailyMetrics}
                      data={launchableContext.dailyOverviewMetrics}
                      noDataLabel="No Data Available"
                      categories={["Deploys"]}
                      placeholderData={chartdata}
                      placeholderCategories={["Deploys"]}
                    />
                  </div>
                </div>
              </FlatCard>
            </div>
            <FlatCard className="mt-8">
              <LaunchableLeaderboard />
            </FlatCard>
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};

export default LaunchableMetrics;
