import React, { createContext, useContext, useEffect, useState } from "react";
import { OrgContext } from "./OrgContext";
import agent, { BillingProfile } from "server";
import { set } from "lodash";
import server from "server";

interface BillingContextProps {
  billingProfile: BillingProfile | null;
  billingProfileLoading: boolean;
  isBalanceLoading: boolean;
  isTopUpLoading: boolean;
  autoRechargeEnabled: boolean;
  balanceThreshold: string;
  currentBalance: number;
  rechargeAmount: string;
  launchablePoints: number;
  changeThreshold: (
    newRechargeAmount: string,
    newBalanceThreshold: string
  ) => Promise<{ status: "success" | "error" | "warning"; message: string }>;
  changeThresholdLoading: boolean;
  addFunds: (
    amount: string
  ) => Promise<{ status: "success" | "error" | "warning"; message: string }>;
  addFundsLoading: boolean;
  handleToggleTopUpChange: (
    rechargeAmount: string
  ) => Promise<{ status: "success" | "error"; message: string }>;
  redeeemCode: (
    code: string
  ) => Promise<{ status: "success" | "error"; message: string }>;
  fetchBillingProfile: () => Promise<BillingProfile | undefined>;
}

export const BillingContext = createContext<BillingContextProps>({
  billingProfile: null,
  billingProfileLoading: false,
  isBalanceLoading: false,
  isTopUpLoading: false,
  autoRechargeEnabled: false,
  balanceThreshold: "0",
  currentBalance: 0,
  rechargeAmount: "0",
  launchablePoints: 0,
  changeThreshold: async () => ({ status: "success", message: "pending" }),
  changeThresholdLoading: false,
  addFunds: async () => ({ status: "success", message: "pending" }),
  addFundsLoading: false,
  handleToggleTopUpChange: async () => ({
    status: "success",
    message: "pending",
  }),
  redeeemCode: async (code: string) => ({
    status: "success",
    message: "pending",
  }),
  fetchBillingProfile: async () => undefined,
});

interface Props {
  children: React.ReactNode;
}
export const BillingProvider: React.FC<Props> = ({ children }) => {
  const [autoRechargeEnabled, setAutoRechargeEnabled] = useState(false);
  const [balanceThreshold, setBalanceThreshold] = useState("10");
  const [currentBalance, setCurrentBalance] = useState(0);
  const [rechargeAmount, setRechargeAmount] = useState("0");

  const [billingProfileLoading, setBillingProfileLoading] = useState(false); // TODO: use this to show a spinner while loading
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [isTopUpLoading, setIsTopUpLoading] = useState(false);
  const [isNotifSettingLoading, setIsNotifSettingLoading] = useState(false);
  const [addFundsLoading, setAddFundsLoading] = useState(false);
  const [changeThresholdLoading, setChangeThresholdLoading] = useState(false);
  const [launchablePoints, setLaunchablePoints] = useState<number>(0);

  const orgContext = useContext(OrgContext);
  const [billingProfile, setBillingProfile] = useState<BillingProfile | null>(
    null
  );

  const fetchBillingProfile = async () => {
    setBillingProfileLoading(true);
    setIsBalanceLoading(true);
    setIsTopUpLoading(true);
    setChangeThresholdLoading(true);

    if (orgContext.activeOrgId) {
      const res = await agent.BillingCredits.getBillingProfile(
        orgContext.activeOrgId
      );
      if (res.data) {
        setBillingProfile(res.data);
        if (res.data?.credit_details) {
          setAutoRechargeEnabled(
            res.data.credit_details.top_up_amount
              ? res.data.credit_details.top_up_amount > 0
              : false
          );
          setBalanceThreshold(
            res.data.credit_details.top_up_threshold
              ? `${res.data.credit_details.top_up_threshold / 100}`
              : `10`
          );
          setCurrentBalance(
            res.data.credit_details.remaining_credits
              ? res.data.credit_details.remaining_credits / 100
              : 0
          );
          setRechargeAmount(
            res.data.credit_details.top_up_amount
              ? `${res.data.credit_details.top_up_amount / 100}`
              : `0`
          );
        }
        if (res.data.cloud_point_details?.cloud_points_redeemable) {
          setLaunchablePoints(
            res.data.cloud_point_details.cloud_points_redeemable / 100
          );
        }
        setBillingProfileLoading(false);
        setIsBalanceLoading(false);
        setIsTopUpLoading(false);
        setChangeThresholdLoading(false);
        return res.data;
      }
    }
    setBillingProfileLoading(false);
    setIsBalanceLoading(false);
    setIsTopUpLoading(false);
    setChangeThresholdLoading(false);
  };
  useEffect(() => {
    if (orgContext.activeOrgId) {
      fetchBillingProfile();
    }
  }, [orgContext.activeOrgId]);

  const handleToggleTopUpChange = async (
    rechargeAmount: string
  ): Promise<{ status: "success" | "error"; message: string }> => {
    if (orgContext.activeOrgId) {
      setIsTopUpLoading(true); // show the spinner

      let updatedtop_up_amount = autoRechargeEnabled
        ? 0
        : parseInt(rechargeAmount, 10) * 100;

      try {
        const res = await agent.BillingCredits.updateBillingProfile(
          orgContext.activeOrgId,
          {
            top_up_amount: `${updatedtop_up_amount}`,
            top_up_threshold: `${parseInt(balanceThreshold, 10) * 100}`, // convert to cents
          }
        );

        if (res.success && res.data?.billing_profile) {
          setAutoRechargeEnabled(updatedtop_up_amount > 0);
          setRechargeAmount(
            res.data?.billing_profile?.credit_details?.top_up_amount
              ? `${
                  res.data?.billing_profile?.credit_details.top_up_amount / 100
                }`
              : `0`
          );
          return {
            status: "success",
            message: "Successfully updated top up amount.",
          };
        } else {
          console.log(res);
          return {
            status: "error",
            message: "Failed to update top up amount.",
          };
        }
      } catch (err) {
        console.log(err);
        return { status: "error", message: "Failed to update top up amount." };
      } finally {
        setIsTopUpLoading(false); // hide the spinner
      }
    }
    return { status: "error", message: "No active organization ID" };
  };
  const changeThreshold = async (
    newRechargeAmount: string,
    newBalanceThreshold: string
  ): Promise<{ status: "success" | "error" | "warning"; message: string }> => {
    setChangeThresholdLoading(true);
    if (orgContext.activeOrgId) {
      try {
        const rechargeInCents = parseInt(newRechargeAmount, 10) * 100;
        const balanceThresholdInCents = parseInt(newBalanceThreshold, 10) * 100;
        const res = await agent.BillingCredits.updateBillingProfile(
          orgContext.activeOrgId,
          {
            top_up_amount: `${rechargeInCents}`, // convert to cents
            top_up_threshold: `${balanceThresholdInCents}`, // convert to cents
          }
        );
        setChangeThresholdLoading(false);
        if (res.success) {
          setAutoRechargeEnabled(rechargeInCents > 0);
          setRechargeAmount(
            res.data?.billing_profile?.credit_details?.top_up_amount
              ? `${
                  res.data?.billing_profile?.credit_details.top_up_amount / 100
                }`
              : `0`
          );
          setBalanceThreshold(
            res.data?.billing_profile?.credit_details?.top_up_threshold
              ? `${
                  res.data?.billing_profile?.credit_details.top_up_threshold /
                  100
                }`
              : `0`
          );
          // setInlineAlertMessage("Successfully updated billing thresholds");
          // setInlineAlertSeverity("success");
          return {
            status: "success",
            message: "Successfully updated billing thresholds",
          };
        } else {
          // setInlineAlertMessage("Failed to update billing thresholds");
          // setInlineAlertSeverity("error");
          console.log(res);
          return {
            status: "error",
            message: "Failed to update billing thresholds",
          };
        }
      } catch (err) {
        console.log(err);
        // setInlineAlertMessage("Failed to update billing thresholds");
        // setInlineAlertSeverity("error");
        setChangeThresholdLoading(false);
        return {
          status: "error",
          message: "Failed to update billing thresholds",
        };
      }
    }
    setChangeThresholdLoading(false);
    return { status: "error", message: "No active organization ID" };
  };
  const addFunds = async (
    oneTimeTopUp: string
  ): Promise<{ status: "success" | "error" | "warning"; message: string }> => {
    if (orgContext.activeOrgId) {
      setAddFundsLoading(true);
      try {
        const res = await agent.BillingCredits.purchaseCredits(
          orgContext.activeOrgId,
          {
            amount: `${parseInt(oneTimeTopUp, 10) * 100}`,
          }
        );
        setAddFundsLoading(false);
        if (res.success) {
          fetchBillingProfile();
          return { status: "success", message: "Successfully added funds." };
        } else {
          console.log(res);
          return { status: "error", message: "Failed to add funds" };
        }
      } catch (err) {
        console.log(err);
        setAddFundsLoading(false);
        return { status: "error", message: "Failed to add funds" };
      }
    }
    setAddFundsLoading(false);
    return { status: "error", message: "No active organization ID" };
  };

  const redeeemCode = async (
    code: string
  ): Promise<{ status: "success" | "error"; message: string }> => {
    setAddFundsLoading(true);
    if (orgContext.activeOrgId) {
      const res = await server.Organizations.redeemCode(
        orgContext.activeOrgId,
        code
      );
      if (res.success) {
        setAddFundsLoading(false);
        return {
          status: "success",
          message: `Successfully added coupon: ${code}`,
        };
      } else {
        setAddFundsLoading(false);
        return { status: "error", message: "Failed to add coupon" };
      }
    }
    setAddFundsLoading(false);
    return { status: "error", message: "No active organization ID" };
  };

  return (
    <BillingContext.Provider
      value={{
        billingProfile,
        billingProfileLoading,
        isBalanceLoading,
        isTopUpLoading,
        autoRechargeEnabled,
        balanceThreshold,
        currentBalance,
        rechargeAmount,
        launchablePoints,
        changeThreshold,
        changeThresholdLoading,
        addFunds,
        addFundsLoading,
        handleToggleTopUpChange,
        redeeemCode,
        fetchBillingProfile,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};
