import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  InputField,
  Slider,
  Toggle,
  Checkbox,
  FlatCard,
  Spinner,
} from "components/UI-lib";
import { classNames } from "components/utils";
import { EndpointCard, EndpointCardProps } from "./Card";
import { useHistory } from "react-router";
import {
  allPublicCloudsName,
  handleIsPaymentSetup,
} from "components/Environment/Create/utils";
import { OrgContext } from "contexts/OrgContext";
import { BillingContext } from "contexts/BillingContext";
import ConnectCloudsOrCardModal from "components/ConnectCloudsOrCardModal";
import { AddCreditsModal } from "components/ConnectCloudsOrCardModal/AddCreditsModal";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

interface PlaygroundComponentProps {
  model: EndpointCard;
  apiKey: string;
  isAuthed: boolean;
}

const PlaygroundComponent: React.FC<PlaygroundComponentProps> = ({
  model,
  apiKey,
  isAuthed,
}) => {
  const orgContext = useContext(OrgContext);
  const billingContext = useContext(BillingContext);
  const history = useHistory();
  const [selectedModel, setSelectedModel] = useState("");
  const [temperature, setTemperature] = useState(0.7);
  const [maxLength, setMaxLength] = useState(256);
  const [topP, setTopP] = useState(1);
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [useStreaming, setUseStreaming] = useState(true);
  const [chatResponse, setChatResponse] = useState("");

  //Billing Modal
  const [noCardOnFile, setNoCardOnFile] = useState<boolean>(false);
  const [showConnectCloudsOrCardModal, setShowConnectCloudsOrCardModal] =
    useState<boolean>(false);
  const [selectedCloudName, setSelectedCloudName] =
    useState(allPublicCloudsName);

  useEffect(() => {
    handleIsPaymentSetup(selectedCloudName, orgContext.activeOrgId).then(
      (isPaymentSetUp) => setNoCardOnFile(!isPaymentSetUp)
    );
  }, [selectedCloudName]);

  const modelOptions = [
    { value: "text-davinci-003", label: "GPT-3: text-davinci-003" },
    { value: "text-curie-001", label: "GPT-3: text-curie-001" },
    { value: "text-babbage-001", label: "GPT-3: text-babbage-001" },
    { value: "text-ada-001", label: "GPT-3: text-ada-001" },
    { value: "code-davinci-002", label: "Codex: code-davinci-002" },
    { value: "code-cushman-001", label: "Codex: code-cushman-001" },
  ];

  const handleSubmit = async () => {
    if (!isAuthed) {
      window.open("/org/endpoints", "_blank");
    }

    if (
      (billingContext.billingProfile?.billing_type === "usage-arrears" &&
        noCardOnFile) ||
      (billingContext.billingProfile?.billing_type === "credit" &&
        billingContext.currentBalance <= 0)
    ) {
      setShowConnectCloudsOrCardModal(true);
      return;
    }

    setIsLoading(true);
    setChatResponse("");

    // change this to use Native eventsource apis:
    try {
      const response = await fetch("https://api.brev.dev/v1/chat/completions", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: [
            {
              role: "user",
              content: prompt,
            },
          ],
          stream: useStreaming,
          model: model.id,
          max_tokens: 1024,
          presence_penalty: 0,
          frequency_penalty: 0,
          top_p: 0.7,
          temperature: 0.2,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (!response.body) return null;
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        let chunk = decoder.decode(value);
        // Split the chunk into individual events
        const events = chunk.split("\n\n").filter(Boolean);
        for (let event of events) {
          console.log("EVENT:", event);
          const prefix = "event:data\ndata:";
          if (event.startsWith(prefix)) {
            event = event.slice(prefix.length);
          }
          if (event.startsWith("data: ")) {
            const data = event.slice(6).trim();
            if (data === "[DONE]") {
              return;
            }
            try {
              const removedData = data.replace(/^data:\s*/, "");
              const parsedData = JSON.parse(removedData);
              if (parsedData.choices && parsedData.choices[0].delta) {
                const { content } = parsedData.choices[0].delta;
                if (content) {
                  setChatResponse((prevResponse) => prevResponse + content);
                  console.log(content);
                }
              }
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      {billingContext.billingProfile?.billing_type === "credit" ? (
        <AddCreditsModal
          show={showConnectCloudsOrCardModal}
          setShow={setShowConnectCloudsOrCardModal}
          onCancel={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setNoCardOnFile={setNoCardOnFile}
          noCardOnFile={noCardOnFile}
          onSuccess={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          label="Add Credits to use endpoints"
        />
      ) : (
        <ConnectCloudsOrCardModal
          show={showConnectCloudsOrCardModal}
          setShow={setShowConnectCloudsOrCardModal}
          onCancel={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setNoCardOnFile={setNoCardOnFile}
          onSuccess={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setSelectedCloudName={setSelectedCloudName}
          selectedCloudName={selectedCloudName}
        />
      )}
      <div className="flex flex-row justify-between">
        <div className="flex flex-col mb-6">
          <h1 className="text-2xl font-bold ">NVIDIA Brev Playground</h1>
          <p className="">
            Selected NIM:{" "}
            <span className="text-emerald-500 font-bold">
              {model.displayName}
            </span>{" "}
          </p>
        </div>
        <div className="flex flex-col">
          {model.launchableUrl && (
            <div>
              <Button
                onClick={() => {
                  window.open(model.launchableUrl, "_blank");
                }}
                label={"Finetune Model"}
                className={""}
                iconRight={<ArrowUpRightIcon className="w-4 h-4 ml-1" />}
              />
            </div>
          )}
        </div>
      </div>

      {/* Uncomment this outer div if you want less of a Prompt space */}
      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6"> */}
      <div className="">
        <InputField
          label="Prompt"
          placeholder="Enter your prompt here..."
          value={prompt}
          onChange={setPrompt}
          multiline
          className=""
          errorMessage=""
        />

        <div className="my-6">
          <div className="flex flex-row">
            <label className="mb-1 block text-sm font-medium text-gray-700 dark:text-secondary">
              {"Response"}
            </label>
          </div>
          <FlatCard>{chatResponse}</FlatCard>
        </div>
        {/* {chatResponse.length > 0 && (
          <div className="my-6">
            <div className="flex flex-row">
              <label className="mb-1 block text-sm font-medium text-gray-700 dark:text-secondary">
                {"Response"}
              </label>
            </div>
            <FlatCard>{chatResponse}</FlatCard>
          </div>
        )} */}
      </div>
      {/* </div> */}

      <div className="flex justify-between items-center mt-2">
        <div className="flex items-center space-x-4">
          <Checkbox
            label="Stream response"
            checked={true}
            onChange={() => {}}
          />
          <UntogglableToggle />
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex justify-end">
            <Button
              onClick={handleSubmit}
              disabled={isLoading}
              label={isAuthed ? "Generate" : "Login and Generate"}
              className={classNames(
                "px-6 py-2",
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaygroundComponent;

const UntogglableToggle: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [togglePosition, setTogglePosition] = useState({ x: 0, y: 0 });
  const toggleRef = useRef<HTMLDivElement | null>(null);

  const handleAnimationEnd = () => {
    console.log("Animation ended");
    setIsAnimating(false);
  };

  const updateTogglePosition = () => {
    if (toggleRef.current) {
      const rect = toggleRef.current.getBoundingClientRect();
      setTogglePosition({
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      });
    }
  };

  useEffect(() => {
    console.log("checked changed:", checked);
    console.log("isAnimating:", isAnimating);

    if (checked) {
      console.log("Starting animation");
      setIsAnimating(true);
      updateTogglePosition();

      const timer = setTimeout(() => {
        console.log("Timer finished, setting checked to false");
        setChecked(false);
      }, 1450);

      return () => {
        console.log("Clearing timer");
        clearTimeout(timer);
      };
    }
  }, [checked]);

  useEffect(() => {
    console.log("isAnimating changed:", isAnimating);
  }, [isAnimating]);

  useEffect(() => {
    const handleScroll = () => {
      if (isAnimating) {
        updateTogglePosition();
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [isAnimating]);

  return (
    <div className="relative">
      <div ref={toggleRef}>
        <Toggle
          label="Safe mode"
          checked={checked}
          onChange={(newChecked) => {
            console.log("Toggle changed:", newChecked);
            if (!isAnimating) {
              setChecked(newChecked);
            } else {
              console.log("Ignoring toggle change due to animation");
            }
          }}
        />
      </div>
      {isAnimating && (
        <div className="fixed inset-0 pointer-events-none">
          <div
            className="absolute bottom-0 right-0 animate-emoji"
            style={
              {
                fontSize: "2rem",
                transform: "rotate(-45deg)",
                "--toggle-x": `${togglePosition.x}px`,
                "--toggle-y": `${togglePosition.y}px`,
              } as React.CSSProperties
            }
            onAnimationEnd={handleAnimationEnd}
          >
            👈
          </div>
        </div>
      )}
    </div>
  );
};
