// react component with a header that says hi
import { classNames, isEmoji } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useState } from "react";
import "./GetCode.scss";
import { useParams } from "react-router-dom";
import Button from "components/UI-lib/Button";
import { useWindupString } from "windups";
import { useHistory } from "react-router-dom";
import { DarkModeContext } from "contexts/DarkModeContext";
import ReactPlayer from "react-player";
import new1 from "assets/img/png/new-1.png";
import new2 from "assets/img/png/new-2.png";
import new3 from "assets/img/png/new-3.png";
import FlatCard from "components/UI-lib/FlatCard";
import { UserContext } from "contexts/UserContext";
import agent from "server";
import { createDefaultAnalyticsObject } from "components/Analytics";
import useQueryParam from "useQueryParams";

const Frame2: React.FC<{ onNext: () => void }> = ({ onNext }) => {
  const { turnOnDarkMode } = useContext(DarkModeContext);
  const [haveWeToggledDarkMode, setHaveWeToggledDarkMode] = useState(false);
  const [haveWeToggledNewUI, setHaveWeToggledNewUI] = useState(false);
  const [headerTypedOut, setHeaderTypedOut] = useState(false);
  const [header] = useWindupString(`Setting up your account...`, {
    pace: (char) => (isEmoji(char) ? 5 : 35),
    onFinished: () => setHeaderTypedOut(true),
  });

  useEffect(() => {
    if (headerTypedOut) {
      setTimeout(() => {
        turnOnDarkMode();
        setHaveWeToggledDarkMode(true);
      }, 1500);
    }
  }, [headerTypedOut]);

  return (
    <>
      <h3 className="text-3xl font-medium leading-6 text-gray-900 dark:text-white">
        {header}
      </h3>

      <div className="min-w-[500px] w-[60%]">
        <div className={headerTypedOut ? "visible" : "invisible"}>
          <FlatCard className="mt-10" isLoading={!haveWeToggledDarkMode}>
            <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono">
              {haveWeToggledDarkMode && "✅ "} Enabling dark mode
            </p>
          </FlatCard>
        </div>

        <div className={haveWeToggledDarkMode ? "visible" : "invisible"}>
          <FlatCard className="mt-10" isLoading={!haveWeToggledNewUI}>
            <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono">
              {haveWeToggledNewUI && "✅ "} Installing Latest Release
            </p>
          </FlatCard>
        </div>
      </div>

      <p
        className={classNames(
          "text-md mt-10 text-gray-700 dark:text-cyan-100 text-center",
          headerTypedOut && haveWeToggledDarkMode && haveWeToggledNewUI
            ? "visible"
            : "invisible"
        )}
      >
        You can always toggle back light mode and the old Brev version in your
        profile.
      </p>

      <Button
        label="See what's new →"
        type="secondary"
        className={classNames(
          "mt-10",
          headerTypedOut && haveWeToggledDarkMode && haveWeToggledNewUI
            ? "visible"
            : "invisible"
        )}
        onClick={onNext}
      />
    </>
  );
};

const Frame3: React.FC<{ onStart: () => void }> = ({ onStart }) => {
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const [headerTypedOut, setHeaderTypedOut] = useState(false);
  const [header] = useWindupString(`Changelog for the Brev Beta`, {
    pace: (char) => (isEmoji(char) ? 5 : 35),
    onFinished: () => setHeaderTypedOut(true),
  });
  const [currentFeature, setCurrentFeature] = useState(-1);
  const [lastPlayedSecond, setLastPlayedSecond] = useState<number>(0);
  const [userOverridden, setUserOverridden] = useState(false);
  const [interactionTimeout, setInteractionTimeout] =
    useState<NodeJS.Timeout | null>(null);

  // Add a helper function to reset userOverridden after a delay
  const startUserOverrideResetTimer = () => {
    // Clear any existing timer
    if (interactionTimeout) {
      clearTimeout(interactionTimeout);
    }

    // Start a new timer
    const timer = setTimeout(() => {
      setUserOverridden(false);
    }, 5000); // 5 seconds, adjust this value as needed

    setInteractionTimeout(timer);
  };

  const incrementFeature = () => {
    if (currentFeature === 2) return;
    setCurrentFeature((prev) => prev + 1);
    setUserOverridden(true);
    startUserOverrideResetTimer(); // Start/reset the timer
  };
  const decrementFeature = () => {
    if (currentFeature === 0) return;
    setCurrentFeature((prev) => prev - 1);
    setUserOverridden(true);
    startUserOverrideResetTimer(); // Start/reset the timer
    agent.Brevent.track({
      eventName: "#4 INVITE - frame 3 - feature what's new: Clicked 'next'",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
      },
    });
  };

  const handleProgress = ({ playedSeconds }: { playedSeconds: number }) => {
    if (userOverridden) return; // If user has overridden, do not apply video logic

    const currentSecond = Math.round(playedSeconds);

    if (Math.abs(currentSecond - lastPlayedSecond) > 1) {
      // Loop through missed seconds due to seeking
      for (
        let second = lastPlayedSecond + 1;
        second <= currentSecond;
        second++
      ) {
        applyVideoLogicForSecond(second);
      }
    } else {
      applyVideoLogicForSecond(currentSecond);
    }

    setLastPlayedSecond(currentSecond);
  };

  const handlePlayPause = (state: { played: number; paused: boolean }) => {
    if (state.paused) {
      setUserOverridden(false); // Reset when the video is paused
    }
  };

  const applyVideoLogicForSecond = (second: number) => {
    if (second < 19 && second > 3) {
      setCurrentFeature(0);
      if (second === 11) {
        agent.Brevent.track({
          eventName: `INVITE - feature tour - watched through 11s`,
          userId: userContext.me?.id,
          properties: {
            orgId: orgContext.activeOrgId,
          },
        });
      }
    } else if (second >= 19 && second < 48) {
      setCurrentFeature(1);
      if (second === 19) {
        agent.Brevent.track({
          eventName: `INVITE - feature tour - watched through 19s`,
          userId: userContext.me?.id,
          properties: {
            orgId: orgContext.activeOrgId,
          },
        });
      }
    } else if (second >= 48) {
      setCurrentFeature(2);
      if (second === 48) {
        agent.Brevent.track({
          eventName: `INVITE - feature tour - watched through 48s`,
          userId: userContext.me?.id,
          properties: {
            orgId: orgContext.activeOrgId,
          },
        });
      }
    }
  };

  // clear any existing timer
  useEffect(() => {
    return () => {
      if (interactionTimeout) {
        clearTimeout(interactionTimeout);
      }
    };
  }, [interactionTimeout]);

  return (
    <>
      <div className="w-full bg-black flex flex-row justify-center items-center">
        <ReactPlayer
          className="w-full"
          controls={true}
          playing={true}
          width={"100%"}
          height={"500px"}
          url="https://youtu.be/LS736WPO1q4"
          onProgress={handleProgress}
          onPlayPause={handlePlayPause} // Add this to reset userOverridden when video is paused
        />
      </div>

      {currentFeature >= 0 && (
        <FeatureExplainer feature={currentFeature}>
          <div>
            {currentFeature > 0 && (
              <Button
                label={"Back"}
                type={"secondary"}
                className="mt-10 mr-2"
                onClick={() => {
                  agent.Brevent.track({
                    eventName: `INVITE - feature tour - go BACK to feature ${
                      currentFeature - 1
                    }`,
                    userId: userContext.me?.id,
                    properties: {
                      orgId: orgContext.activeOrgId,
                    },
                  });
                  decrementFeature();
                }}
              />
            )}
            <Button
              label={currentFeature === 2 ? "Let's Go!" : "Next"}
              type={currentFeature === 2 ? "primary" : "secondary"}
              className="mt-10"
              onClick={() => {
                if (currentFeature === 2) {
                  agent.Brevent.track({
                    eventName: "INVITE - feature tour complete",
                    userId: userContext.me?.id,
                    properties: {
                      orgId: orgContext.activeOrgId,
                    },
                  });
                  onStart();
                } else {
                  agent.Brevent.track({
                    eventName: `INVITE - feature tour - go NEXT to feature ${
                      currentFeature + 1
                    }`,
                    userId: userContext.me?.id,
                    properties: {
                      orgId: orgContext.activeOrgId,
                    },
                  });
                  incrementFeature();
                }
              }}
              // onClick={currentFeature === 2 ? onStart : incrementFeature}
            />
          </div>
        </FeatureExplainer>
      )}
    </>
  );
};

interface ParamTypes {
  token: string;
}

const TheReleaseInvite = () => {
  console.log("Route: /the-release-invitation");

  const [frame, setFrame] = useQueryParam("frame", "");

  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const [currentFrame, setCurrentFrame] = useState(1);
  const [headerTypedOut, setHeaderTypedOut] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [header] = useWindupString(`Welcome to the latest release 🤙`, {
    pace: (char) => (isEmoji(char) ? 5 : 35),
    onFinished: () => setHeaderTypedOut(true),
  });

  const history = useHistory();

  useEffect(() => {
    if (frame === "3") {
      setCurrentFrame(3);
    }
  }, [frame]);

  const updateUserOnboarding = async (accepted: boolean) => {
    userContext.setOnboardingObjectFunc({
      inviteAccepted: `${accepted}`,
    });
  };

  const goToFrame2 = async () => {
    agent.Brevent.track({
      eventName: "#2 INVITE ACCEPTED",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
      },
    });
    setCurrentFrame(2);
    await updateUserOnboarding(true);
  };
  const inviteRejected = async () => {
    agent.Brevent.track({
      eventName: "#2 INVITE REJECTED",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
      },
    });
    await updateUserOnboarding(false);
    history.push("/");
  };
  const goToFrame3 = async () => {
    agent.Brevent.track({
      eventName: "#3 INVITE -- enabled new brev, going to 'see whats new'",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
      },
    });

    setCurrentFrame(3);
  };
  const goToStart = () => history.push("/");

  return (
    <>
      {currentFrame < 3 && (
        <div className="flex flex-col justify-start items-center mt-[30vh] Get-Code">
          <div className="flex flex-col items-center w-full justify-center box-list">
            {currentFrame === 1 && (
              <>
                <h3 className="text-3xl font-medium leading-6 text-gray-900 dark:text-white">
                  {header}
                </h3>
                {headerTypedOut && <Invitation setIsFinished={setIsFinished} />}
                {isFinished && (
                  <>
                    <Button
                      label="Yes, I want to try it! →"
                      type="secondary"
                      className="mt-10"
                      onClick={goToFrame2}
                    />
                    <p
                      className="text-md mt-10 text-gray-500 dark:text-cyan-100 whitespace-pre-line underline cursor-pointer"
                      onClick={inviteRejected}
                    >
                      no thanks
                    </p>
                  </>
                )}
              </>
            )}

            {currentFrame === 2 && <Frame2 onNext={goToFrame3} />}
          </div>
        </div>
      )}
      {currentFrame === 3 && <Frame3 onStart={goToStart} />}
    </>
  );
};

export default TheReleaseInvite;

interface InvitationProps {
  setIsFinished: (b: boolean) => void;
}

const Invitation: React.FC<InvitationProps> = (props) => {
  const [invitationText] = useWindupString(
    "Thank you for being an early user of Brev. We'd love to invite you to the latest release which just shipped. Wanna try it out?",
    {
      pace: (char) => 27,
      onFinished: () => props.setIsFinished(true),
    }
  );

  return (
    <div className="w-[500px] text-center">
      <p className="text-md mt-4 text-gray-700 dark:text-cyan-100 whitespace-pre-line">
        {invitationText}
      </p>
    </div>
  );
};

const features = [
  {
    miniheader: "Cheap costs. High availability. (1/3)",
    header: "More GPU supply at 60% cheaper",
    description:
      "We're working with epic data centers and marketplaces to access cheaper GPU supply. You can still pick where the GPU you get comes from, a major cloud, a datacenter, or your own cloud account.",
    image: new3,
  },
  {
    miniheader: "Deploy faster (2/3)",
    header: "The simplest way to get a GPU",
    description:
      "We separated configuring GPUs from provisioning them. This new UI is the simplest way to get a GPU in the cloud, with one interface to browse availability across any cloud.",
    image: new1,
  },
  {
    miniheader: "Configure faster (3/3)",
    header: "The simplest way to install CUDA and python versions",
    description:
      "Verb is an open source tool to install CUDA and python versions in an ML-optimized docker container. Use the UI to easily install and build the container. If you use Verb, Brev will automatically SSH you into the container.",
    image: new2,
  },
];

interface FeatureExplainerProps {
  children: React.ReactNode;
  feature: number;
}

const FeatureExplainer: React.FC<FeatureExplainerProps> = (props) => {
  const activeFeature = features[props.feature];
  const [header] = useWindupString(activeFeature.header);
  return (
    <div className="overflow-hidden bg-gray-900 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-cyan-400">
                {activeFeature.miniheader}
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {header}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                {activeFeature.description}
              </p>

              {props.children}
            </div>
          </div>
          <img
            src={activeFeature.image}
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
};
