import React, { useState, useRef, useEffect, useContext } from "react";
import Button from "components/UI-lib/Button";
import "./styles.scss";
import { BillingContext } from "contexts/BillingContext";
import { OrgContext } from "contexts/OrgContext";
import agent, { PermissionsRes } from "server";
import RedeemableFlow from "./RedeemableFlow";

const RedeemableCode: React.FC = () => {
  console.log("Route: /redeem-code");
  const billingContext = useContext(BillingContext);
  const orgContext = useContext(OrgContext);
  const [enterCode, setEnterCode] = useState("");

  const handleRedeem = async () => {
    return await agent.Organizations.redeemCode(
      orgContext.activeOrgId,
      enterCode
    );
  };

  return (
    <RedeemableFlow
      title="Redeem your Code"
      description="Enter your Code below to redeem your credits."
      redeemableLabel=""
      redeemableValue={0}
      redeemFunction={handleRedeem}
      fetchUpdatedData={() => {
        billingContext.fetchBillingProfile();
      }}
      backPath={`/org/${orgContext.activeOrgId}/launchables`}
      backLabel="Go Back"
      successMessage="Credits have been successfully redeemed!"
      errorMessage="Error redeeming credits. Please try again or contact support."
      enterCode={enterCode}
      setEnterCode={setEnterCode}
    />
  );
};

export default RedeemableCode;
