import React, { useEffect, useState } from "react";
import Spinner from "components/UI-lib/Spinner";
import agent from "server";
import IUser from "models/User.model";
import Button from "components/UI-lib/Button";

interface UserBlockManagerProps {
  users: IUser[];
  onActionComplete?: (
    user: IUser,
    status: "done" | "error",
    message?: string
  ) => void;
}

const UserBlockManager: React.FC<UserBlockManagerProps> = ({
  users,
  onActionComplete,
}) => {
  const [action, setAction] = useState<"block" | "unblock" | "idle">("idle");
  const [countdown, setCountdown] = useState<number | null>(null);
  const [userStatus, setUserStatus] = useState<
    Record<string, { status: "done" | "error"; message?: string }>
  >({});

  useEffect(() => {
    if (action === "idle") return;

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === null || prevCountdown === 1) {
          clearInterval(interval);
          if (prevCountdown === 1) {
            initiateAction();
          }
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [action]);

  const initiateAction = async () => {
    for (const user of users) {
      try {
        if (action === "block") {
          const response = await agent.Admin.blockUser(user.id);
          if (response.success) {
            setUserStatus((prevState) => ({
              ...prevState,
              [user.id]: { status: "done" },
            }));
            onActionComplete?.(user, "done");
          } else {
            setUserStatus((prevState) => ({
              ...prevState,
              [user.id]: {
                status: "error",
                message: response.message || "Failed to block user.",
              },
            }));
            onActionComplete?.(user, "error", response.message);
          }
        } else if (action === "unblock") {
          const response = await agent.Admin.unblockUser(user.id);
          if (response.success) {
            setUserStatus((prevState) => ({
              ...prevState,
              [user.id]: { status: "done" },
            }));
            onActionComplete?.(user, "done");
          } else {
            setUserStatus((prevState) => ({
              ...prevState,
              [user.id]: {
                status: "error",
                message: response.message || "Failed to unblock user.",
              },
            }));
            onActionComplete?.(user, "error", response.message);
          }
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "An unknown error occurred.";
        setUserStatus((prevState) => ({
          ...prevState,
          [user.id]: { status: "error", message: errorMessage },
        }));
        onActionComplete?.(user, "error", errorMessage);
      }
    }
    setAction("idle");
    setCountdown(null);
  };

  const startActionWithCountdown = (desiredAction: "block" | "unblock") => {
    setAction(desiredAction);
    setCountdown(5);
  };

  const cancelAction = () => {
    setCountdown(null);
    setAction("idle");
  };

  return (
    <div>
      {countdown !== null ? (
        <div>
          {action.charAt(0).toUpperCase() + action.slice(1)}ing in {countdown}{" "}
          seconds
          <Button label="Nevermind" type="secondary" onClick={cancelAction} />
        </div>
      ) : (
        <div className="flex flex-row space-x-4">
          <Button
            label={users.length > 1 ? `Block ${users.length} Users` : "Block"}
            type="danger"
            onClick={() => startActionWithCountdown("block")}
          />
          <Button
            label={
              users.length > 1 ? `Unblock ${users.length} Users` : "Unblock"
            }
            type="warning"
            onClick={() => startActionWithCountdown("unblock")}
          />
        </div>
      )}

      {users.map((user) => (
        <div key={user.id}>
          {userStatus[user.id]?.status === "done" &&
            `✅ ${action}ed ${user.id}`}
          {userStatus[user.id]?.status === "error" &&
            `❌ error: ${userStatus[user.id]?.message}`}
        </div>
      ))}
    </div>
  );
};

export default UserBlockManager;
