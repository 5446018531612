export type Theme = {
  primaryTextColor: string;
  secondaryTextColor: string;
  highlightColor: string;
  highlightDarkerColor: string;
  highlightLighterColor: string;
  bgColor: string;
  borderColor: string;
  logo: string;
  logoWidth: string;
  logoHeight: string;
};

export type Themes = {
  base: Theme;
  nvidia: Theme;
  accenture: Theme;
  superMicro: Theme;
  deloitte: Theme;
};

export const themes = {
  // brev
  base: {
    // colors
    primaryTextColor: "#ffffff",
    secondaryTextColor: "#94a3b8",
    highlightColor: "#06b6d4",
    highlightDarkerColor: "#0891b2",
    highlightLighterColor: "#22d3ee",
    bgColor: "#18181b",
    borderColor: "#1e293b",
    // logo
    logo: "/logos/svg/brev-logo.svg",
    logoWidth: "32px",
    logoHeight: "32px",
  },
  nvidia: {
    // colors
    primaryTextColor: "#ffffff",
    secondaryTextColor: "#94a3b8",
    highlightColor: "#76B900",
    highlightDarkerColor: "#59A700",
    highlightLighterColor: "#BBDC80",
    bgColor: "#18181b",
    borderColor: "#1e293b",
    //logo
    logo: "/logos/svg/brev-logo.svg",
    logoWidth: "32px",
    logoHeight: "32px",
  },
  accenture: {
    // colors
    primaryTextColor: "#ffffff",
    secondaryTextColor: "#94a3b8",
    highlightColor: "#A100FF",
    highlightDarkerColor: "#9233ff",
    highlightLighterColor: "#c084fc",
    bgColor: "#18181b",
    borderColor: "#1e293b",
    logo: "/logos/svg/accenture-logo.svg",
    logoWidth: "32px",
    logoHeight: "32px",
  },
  deloitte: {
    // colors
    primaryTextColor: "#ffffff",
    secondaryTextColor: "#94a3b8",
    highlightColor: "#86BC25",
    highlightDarkerColor: "#43B02A",
    highlightLighterColor: "#C4D600",
    bgColor: "#18181b",
    borderColor: "#1e293b",
    logo: "/logos/svg/deloitte-logo.svg",
    logoWidth: "70px",
    logoHeight: "70px",
  },
  superMicro: {
    // colors
    primaryTextColor: "#ffffff",
    secondaryTextColor: "#94a3b8",
    highlightColor: "#003A70",
    highlightDarkerColor: "#9233ff",
    highlightLighterColor: "#c084fc",
    bgColor: "#18181b",
    borderColor: "#1e293b",
    logo: "/logos/svg/super-micro-logo.svg",
    logoWidth: "60px",
    logoHeight: "32px",
  },
};
