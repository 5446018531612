import React, { useContext, useMemo, useState } from "react";
import {
  Capability,
  changeInstanceTypeCapability,
  changeSpotInstanceTypeCapability,
  modifyFirewallCapability,
  resizeCapability,
} from "contexts/OrgContext";
import InstanceChanger from "./InstanceChanger";
import VolumeChanger from "./VolumeChanger";
import Workspace from "entities/Workspace.entity";
import { FlatCard } from "components/UI-lib";

interface ComputeSettingsProps {
  workspace: Workspace;
  workspaceCapabilities: Capability[];
}

const ComputeSettings: React.FC<ComputeSettingsProps> = ({
  workspace,
  workspaceCapabilities,
}) => {
  // Memoize the instance modification check
  const instanceCanBeModified = useMemo(
    () =>
      workspaceCapabilities.includes(changeInstanceTypeCapability) &&
      (!workspace?.spot ||
        workspaceCapabilities.includes(changeSpotInstanceTypeCapability)),
    [workspaceCapabilities, workspace?.spot]
  );

  // Memoize the scaling capability check
  const instanceCanScale = useMemo(
    () => workspaceCapabilities.includes(resizeCapability),
    [workspaceCapabilities]
  );

  // Memoize the scale message
  const noScaleMessage = useMemo(
    () =>
      workspace.spot
        ? "This workspace is running on a spot instance and cannot be modified."
        : "This workspace is not running on a scalable instance so you can't modify its hardware",
    [workspace.spot]
  );

  return (
    <>
      <div className="flex flex-row items-end ">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Change Hardware
          </h1>
        </div>
      </div>
      <hr className="w-full my-3 dark:border dark:border-zinc-800" />
      <div className="mt-8">
        {instanceCanBeModified ? (
          <InstanceChanger
            workspace={workspace}
            instanceCantBeModified={!instanceCanBeModified}
            cantBeModifiedMessage={noScaleMessage}
          />
        ) : (
          <FlatCard>
            <span className={"text-rose-500 text-sm font-mono"}>
              {noScaleMessage}
            </span>
          </FlatCard>
        )}
      </div>
      <div className="flex flex-row items-end mt-5">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Scalable Storage
          </h1>
        </div>
      </div>
      <hr className="w-full my-3 dark:border dark:border-zinc-800" />
      <div className="mt-8">
        {instanceCanScale ? (
          <VolumeChanger
            workspace={workspace}
            capabilities={workspaceCapabilities}
          />
        ) : (
          <FlatCard>
            <span className={"text-rose-500 text-sm font-mono"}>
              This cloud provider doesn't allow this instance to resize its
              storage
            </span>
          </FlatCard>
        )}
      </div>
    </>
  );
};

export default ComputeSettings;
