import { useQuery } from "@tanstack/react-query";
import { prependKasUrl, KAS_HOST } from "../utils";

type OrganizationResponse = {
  organizations: Organization;
  requestStatus: {
    statusCode: string;
  };
};

type Organization = {
  isSecureCredentialSharingServiceEnabled: boolean;
  isInternal: boolean;
  idpId: string;
  usersInfo: {
    totalUsers: number;
  };
  id: number;
  isRegistrySSEEnabled: boolean;
  alternateContact: Record<string, unknown>;
  canAddOn: boolean;
  infinityManagerSettings: {
    infinityManagerEnabled: boolean;
    infinityManagerEnableTeamOverride: boolean;
  };
  billingAccountId: string;
  type: string;
  isProto: boolean;
  repoScanSettings: RepoScanSettings;
  ncaAccountNumber: string;
  description: string;
  productEnablements: ProductEnablement[];
  name: string;
  orgOwner: OrgOwner;
  displayName: string;
  productSubscriptions: ProductSubscription[];
  isSeparateInfluxDbUsed: boolean;
  isDatasetServiceEnabled: boolean;
  isSecretsManagerServiceEnabled: boolean;
  isQuickStartEnabled: boolean;
  orgOwners: OrgOwner[];
};

type RepoScanSettings = {
  repoScanAllowOverride: boolean;
  repoScanShowResults: boolean;
  repoScanEnabled: boolean;
  repoScanEnableNotifications: boolean;
  repoScanByDefault: boolean;
  repoScanEnableTeamOverride: boolean;
};

type ProductEnablement = {
  type: string;
  expirationDate?: string;
  productName: string;
};

type OrgOwner = {
  fullName: string;
  email: string;
  lastLoginDate: string;
};

type ProductSubscription = {
  productName: string;
  expirationDate: string;
  type: string;
  id: string;
};

export const useGetOrg = ({
  org,
  isKasAuthFlow,
}: {
  org: string;
  isKasAuthFlow: boolean;
}) => {
  const queryKey = `/v2/orgs/${org}`;
  return useQuery({
    queryFn: async (): Promise<OrganizationResponse> =>
      fetch(prependKasUrl(queryKey), {
        headers: { host: KAS_HOST },
        credentials: "include",
        method: "GET",
      }).then(async (response) => await response.json()),
    queryKey: [queryKey],
    refetchInterval: false,
    enabled: !!org && isKasAuthFlow,
  });
};
