/* eslint-disable react/no-unused-prop-types */
// they are being used idk they aren't beinf detected by eslint
import React, { useContext, useEffect, useMemo, useState } from "react";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import {
  Capability,
  OrgContext,
  stopStartCapability,
  terminateCapability,
} from "contexts/OrgContext";
import { ChevronLeftIcon } from "lucide-react";
import { AreaChart } from "components/Graphs/AreaGraph";
import { useHistory, useLocation } from "react-router";
import Workspace from "entities/Workspace.entity";
import { Button, FlatCard } from "components/UI-lib";
import { DeploymentMetrics, MetricDataPointInt } from "server";
import { Spinner } from "components/UI-lib";
import { SparkAreaChart } from "components/Graphs/SparkChart";
import {
  formatDeploymentTimeSeries,
  mergeDeploymentSeries,
  placeholderData,
} from "components/Deployments/utils";

/*
                               _  
              _        ,-.    / ) 
             ( `.     // /-._/ / 
              `\ \   /(_/ / / /  
                ; `-`  (_/ / /  
                |       (_/ /     
                \          /      
                 )        /      
      Brev.Dev   /       /  
*/

interface MetricProps {
  deploymentMetrics?: DeploymentMetrics;
  isLoading: boolean;
}

const Metrics: React.FC<MetricProps> = ({ deploymentMetrics, isLoading }) => {
  const invocationsData = useMemo(
    () =>
      deploymentMetrics?.invocation_per_sec_time_series
        ? formatDeploymentTimeSeries(
            deploymentMetrics.invocation_per_sec_time_series,
            "Invocations"
          )
        : placeholderData.invocations,
    [deploymentMetrics?.invocation_per_sec_time_series]
  );

  const queueDepthData = useMemo(
    () =>
      deploymentMetrics?.queue_depth_time_series
        ? formatDeploymentTimeSeries(
            deploymentMetrics.queue_depth_time_series,
            "Queue Depth"
          )
        : placeholderData.queueDepth,
    [deploymentMetrics?.queue_depth_time_series]
  );

  const inferenceTimeData = useMemo(
    () =>
      deploymentMetrics?.avg_inference_time_series
        ? formatDeploymentTimeSeries(
            deploymentMetrics.avg_inference_time_series,
            "Inference Time"
          )
        : placeholderData.inferenceTime,
    [deploymentMetrics?.avg_inference_time_series]
  );

  const instancesData = useMemo(
    () =>
      deploymentMetrics?.instances_time_series
        ? formatDeploymentTimeSeries(
            deploymentMetrics.instances_time_series,
            "Instances"
          )
        : placeholderData.instances,
    [deploymentMetrics?.instances_time_series]
  );

  const successRateData = useMemo(
    () =>
      deploymentMetrics?.invocation_success_rate_time_series
        ? formatDeploymentTimeSeries(
            deploymentMetrics.invocation_success_rate_time_series,
            "Success Rate"
          )
        : placeholderData.successRate,
    [deploymentMetrics?.invocation_success_rate_time_series]
  );

  return (
    <>
      <h2 className="text-xl font-semibold text-white mt-5 mb-3">Metrics</h2>
      <FlatCard className="mb-5">
        <div className="flex flex-row justify-evenly">
          <div className="flex flex-row text-blue-500">
            <span className="mr-2">Total Active Invocations:</span>
            <span>{deploymentMetrics?.total_invocations || 0}</span>
          </div>
          <div className="flex flex-row text-emerald-500">
            <span className="mr-2">Total Average Inference Time:</span>
            <span>
              {(
                (deploymentMetrics?.average_inference_time || 0) * 1000
              ).toFixed(2)}
              /ms
            </span>
          </div>
          <div className="flex flex-row text-violet-500">
            <span className="mr-2">Total Active Instances:</span>
            <span>{deploymentMetrics?.total_active_instances || 0}</span>
          </div>
          <div className="flex flex-row text-highlight">
            <span className="mr-2">Total Invocation Failures:</span>
            <span>{deploymentMetrics?.total_invocation_failures || 0}</span>
          </div>
        </div>
      </FlatCard>
      <FlatCard className="mb-5">
        <div className="flex flex-col w-full">
          <GraphWrapper
            title="Invocation Activity"
            titleClassnames="font-semibold"
            data={invocationsData}
            category={["Invocations"]}
            colors={["blue"]}
            valueFormatter={(num) => `${num}/sec`}
            isLoading={isLoading}
            isEmpty={!deploymentMetrics?.invocation_per_sec_time_series}
          />
        </div>
        <div className="flex flex-row mt-5">
          <div className="flex flex-col w-full">
            <GraphWrapper
              title="Queue Depth"
              titleClassnames="font-semibold"
              data={queueDepthData}
              category={["Queue Depth"]}
              colors={["amber"]}
              valueFormatter={(num) => `${num}`}
              isLoading={isLoading}
              isEmpty={!deploymentMetrics?.queue_depth_time_series}
            />
          </div>
          <div className="ml-5 flex flex-col w-full">
            <GraphWrapper
              title="Inference Time"
              titleClassnames="font-semibold"
              data={inferenceTimeData}
              category={["Inference Time"]}
              colors={["emerald"]}
              valueFormatter={(num) => `${(num * 1000).toFixed(0)}ms`}
              isLoading={isLoading}
              isEmpty={!deploymentMetrics?.avg_inference_time_series}
            />
          </div>
        </div>
        <div className="flex flex-row mt-8">
          <div className="flex flex-col w-full">
            <GraphWrapper
              title="Instances"
              titleClassnames="font-semibold"
              data={instancesData}
              category={["Instances"]}
              colors={["violet"]}
              valueFormatter={(num) => num.toString()}
              isLoading={isLoading}
              isEmpty={!deploymentMetrics?.instances_time_series}
            />
          </div>
          <div className="ml-5 flex flex-col w-full">
            <GraphWrapper
              title="Success Rate"
              titleClassnames="font-semibold"
              data={successRateData}
              category={["Success Rate"]}
              colors={["cyan"]}
              valueFormatter={(num) => `${num * 100}%`}
              isLoading={isLoading}
              isEmpty={!deploymentMetrics?.invocation_success_rate_time_series}
            />
          </div>
        </div>
      </FlatCard>
    </>
  );
};

export default Metrics;
interface GraphWrapperProps {
  title: string;
  titleClassnames?: string;
  data: any[];
  category: string[];
  colors:
    | ("blue" | "emerald" | "violet" | "amber" | "gray" | "cyan" | "pink")[]
    | undefined;
  valueFormatter: (value: number) => string;
  isLoading: boolean;
  isEmpty: boolean;
  isSparkChart?: boolean;
}

export const GraphWrapper: React.FC<GraphWrapperProps> = ({
  title,
  titleClassnames,
  data,
  category,
  colors,
  valueFormatter,
  isLoading,
  isEmpty,
  isSparkChart = false,
}) => {
  const wrapperStyle = {
    position: "relative" as const,
    opacity: isLoading || isEmpty ? 0.5 : 1,
  };

  const overlayStyle = {
    position: "absolute" as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div style={overlayStyle}>
          <Spinner type="primary" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <div style={overlayStyle}>
          <p className="text-lg font-semibold text-gray-600">No data found</p>
        </div>
      );
    }

    return null;
  };

  const renderChart = () => {
    if (isSparkChart) {
      return (
        <SparkAreaChart
          data={data}
          index="date"
          categories={category}
          fill="gradient"
          colors={colors}
        />
      );
    } else {
      return (
        <AreaChart
          className="h-80"
          data={data}
          showLegend={false}
          index="date"
          categories={category}
          onValueChange={(v) => console.log(v)}
          intervalType="preserveStartEnd"
          colors={colors}
          valueFormatter={valueFormatter}
        />
      );
    }
  };

  return (
    <div className="flex flex-col w-full" style={wrapperStyle}>
      <span className={"flex mb-3 " + titleClassnames}>{title}</span>
      {renderChart()}
      {renderContent()}
    </div>
  );
};
