import Modal from "components/Modals/Modal";
import React, { useState } from "react";
import NumberOfGPUsFilter from "./NumberOfGPUsFilter";
import MinimumCPUsFilter from "./MinimumCPUsFilter";
import MinimumRamFilter from "./MinimumRamFilter";
import PriceRangeFilter from "./PriceRangeFilter";
import Button from "components/UI-lib/Button";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/outline";

export interface FilterModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedNumberOfGPUs: string;
  setSelectedNumberOfGPUs: (gpuNum: string) => void;
  selectedMinimumNumberOfCPUs: number;
  setSelectedMinimumNumberOfCPUs: (number: number) => void;
  selectMinRam: string;
  setSelectMinRam: (ram: string) => void;
  priceRange: number[];
  setPriceRange: (number: number[]) => void;
}

export const FilterModal: React.FC<FilterModalProps> = ({
  show,
  setShow,
  selectedNumberOfGPUs,
  setSelectedNumberOfGPUs,
  selectedMinimumNumberOfCPUs,
  setSelectedMinimumNumberOfCPUs,
  selectMinRam,
  setSelectMinRam,
  priceRange,
  setPriceRange,
}) => {
  const resetFilters = () => {
    setSelectedNumberOfGPUs("any");
    setSelectedMinimumNumberOfCPUs(0);
    setSelectMinRam("any");
    setPriceRange([0, 100]);
  };

  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => setShow(false)}
      title={""}
      width={"max-w-4xl"}
      body={
        <div className="flex flex-col">
          <div className="flex flex-row relative">
            <div
              className="flex absolute top-[0px] left-0 cursor-pointer text-white"
              onClick={() => {
                setShow(false);
              }}
            >
              <XMarkIcon className="w-6 h-6" />
            </div>
            <div className="flex flex-row justify-center w-full mb-3">
              <h1 className="text-white font-bold text-lg">Filters</h1>
            </div>
            <div className="flex absolute top-[-4px] right-0">
              <Button
                onClick={() => resetFilters()}
                label="Reset Filters"
                iconLeft={<ArrowPathIcon className="w-4 h-4 mr-2" />}
              />
            </div>
          </div>
          <hr className="w-[100%] my-1 dark:border dark:border-zinc-800" />
          <div className="flex flex-col mt-3">
            <div className="flex flex-col gap-3 mb-3">
              <div className="flex flex-col">
                <div className="flex flex-row items-center">
                  <h2 className="text-md text-gray-900 dark:text-white font-semibold">
                    Compute Attributes
                  </h2>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row items-center mb-3">
                  <h3 className="text-sm text-gray-900 dark:text-white">
                    Number of GPUs
                  </h3>
                </div>
                <NumberOfGPUsFilter
                  selectedNumberOfGPUs={selectedNumberOfGPUs}
                  setSelectedNumberOfGPUs={setSelectedNumberOfGPUs}
                />
              </div>
              <div className="flex flex-col mb-5">
                <div className="flex flex-row items-center mb-3">
                  <h3 className="text-sm text-gray-900 dark:text-white">
                    Minimum Ram{selectMinRam === "any" ? "" : ":"}
                  </h3>
                  {selectMinRam !== "any" && (
                    <h3 className="text-sm text-highlight ml-1">
                      at least {selectMinRam}
                    </h3>
                  )}
                </div>
                <MinimumRamFilter
                  selectedMinimumRam={selectMinRam}
                  setSelectedMinimumRam={setSelectMinRam}
                />
              </div>
              <div className="flex flex-col mb-5">
                <div className="flex flex-row items-center mb-3">
                  <h3 className="text-sm text-gray-900 dark:text-white">
                    Minimum CPUs{selectedMinimumNumberOfCPUs === 0 ? "" : ":"}
                  </h3>
                  {selectedMinimumNumberOfCPUs !== 0 && (
                    <h3 className="text-sm text-highlight ml-1">
                      {selectedMinimumNumberOfCPUs}
                    </h3>
                  )}
                </div>
                <MinimumCPUsFilter
                  selectedMinimumNumberOfCPUs={selectedMinimumNumberOfCPUs}
                  setSelectedMinimumNumberOfCPUs={
                    setSelectedMinimumNumberOfCPUs
                  }
                />
              </div>
            </div>
            <hr className="w-[100%] my-1 dark:border dark:border-zinc-800" />
            <div className="flex flex-col mt-3 mb-5">
              <div className="flex flex-row items-center mb-3">
                <h2 className="text-md text-gray-900 dark:text-white font-semibold">
                  Hourly Price Range
                </h2>
                <h3 className="text-sm text-highlight ml-1 mt-[1px]">
                  ${priceRange[0]} - ${priceRange[1]}
                </h3>
              </div>
              <PriceRangeFilter
                priceRange={priceRange}
                setPriceRange={setPriceRange}
              />
            </div>
          </div>
        </div>
      }
      cancelLabel={"Close"}
    />
  );
};
