import React, { useState } from "react";
import {
  ArrowUpRightIcon,
  ChevronDownIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import Button from "components/UI-lib/Button";
import { FlatCard } from "components/UI-lib";
import { Nemo } from ".";
import LaunchableConfigurationViewFromParams from "components/Environment/Launchables/Deploy/shared/LaunchableConfigurationViewFromParams";
import { getLaunchableQueryParams } from "components/Environment/Launchables/Deploy/shared/utils";
import { useLaunchable } from "components/Environment/Launchables/utils";
import { Collapse } from "@mui/material";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

interface NemoProps {
  data: Nemo;
  onDeployLaunchable: () => void;
}

const NemoCard: React.FC<NemoProps> = ({ data, onDeployLaunchable }) => {
  const { queryLaunchableID } = getLaunchableQueryParams(data.launchableLink);
  const { launchableData, error, loading } = useLaunchable(
    queryLaunchableID || ""
  );
  const [collapsible, setCollapsible] = useState(false);
  return (
    <FlatCard>
      <div className="flex flex-col">
        <div className="mb-1">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="flex flex-row items-end">
                <h1 className="text-md font-semibold text-gray-900 dark:text-white">
                  {data.title}
                </h1>
              </div>
              <div className="flex flex-row">
                <span className="text-xs text-gray-900 dark:text-secondary mr-2">
                  {data.description}
                </span>
              </div>
            </div>
            <div className="flex flex-row">
              <Button
                onClick={onDeployLaunchable}
                type="primary"
                className="mr-3"
                label="Deploy Launchable"
                iconRight={<ArrowUpRightIcon className="ml-1 w-4 h-4" />}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-end mt-3">
          <div
            className="flex flex-row items-center cursor-pointer text-highlight"
            onClick={() => {
              setCollapsible(!collapsible);
            }}
          >
            <span className="text-sm cursor-pointer flex flex-row">
              {collapsible ? "Hide" : "Show"} Configuration
            </span>
            {collapsible ? (
              <ChevronDownIcon className="h-5 w-5 ml-1" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 ml-1" />
            )}
          </div>
        </div>
        <Collapse
          in={collapsible}
          sx={{
            marginTop: "",
            maxHeight: "500px",
          }}
        >
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          <LaunchableConfigurationViewFromParams
            baseImage={launchableData?.containerSelected.container || ""}
            cuda={launchableData?.cuda || ""}
            diskStorage={launchableData?.diskStorage || ""}
            file={launchableData?.file || ""}
            instanceType={launchableData?.instanceType || ""}
            setInstance={() => {}}
            ports={launchableData?.ports || null}
            python={launchableData?.python || ""}
            workspaceGroupId={launchableData?.workspaceGroupId || ""}
            disabled={false}
          />
        </Collapse>
      </div>
    </FlatCard>
  );
};

export default NemoCard;
