import React, { useContext, useEffect, useState } from "react";
import confetti from "canvas-confetti"; // Make sure to install this package
import LightModeLogo from "assets/img/svg/logo_light_mode.svg?react"; // Import the light mode logo
import BrevCoin from "assets/img/png/coin.png";
import { useWindupString } from "windups";
import { FreeCreditsButton } from "components/Environment/Launchables/List";
import ShimmerButton from "components/UI-lib/Shimmer/ShimmerButton";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router";
import { OrgContext } from "contexts/OrgContext";

const WindupText: React.FC<{ text: string; onFinished?: () => void }> = ({
  text,
  onFinished,
}) => {
  const [windupText] = useWindupString(text, { onFinished });
  return <>{windupText}</>;
};

const WelcomeNvidians = () => {
  console.log("Route: /welcome-nvidians");

  const [showText, setShowText] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [showFrame2, setShowFrame2] = useState(false);

  useEffect(() => {
    // const timer1 = setTimeout(() => {
    //   setShowLogo(true);
    // }, 3000);

    const timer2 = setTimeout(() => {
      var end = Date.now() + 2 * 1000;
      var colors = ["#76B702", "#FFFFFF"];

      (function frame() {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: colors,
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors,
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        } else {
          setShowFrame2(true);
        }
      })();

      setShowText(true);
    }, 1111);

    return () => {
      //   clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <div className="flex flex-col items-center h-screen mt-[15%]">
      <h1 className="text-md text-gray-700 dark:text-secondary font-mono">
        <WindupText text="welcome to brev" />
      </h1>

      <LightModeLogo className="block h-36 w-36" />

      <h1 className="text-md text-gray-700 dark:text-secondary font-mono">
        {showText && <WindupText text="Now part of the NVIDIA family" />}
      </h1>

      {showFrame2 && (
        <div className="mt-10">
          <FreeGpuCreditsMessage />
        </div>
      )}
    </div>
  );
};

export default WelcomeNvidians;

const FreeGpuCreditsMessage: React.FC = () => {
  const [showCreditsButton, setShowCreditsButton] = useState(false);
  const history = useHistory();
  const orgContext = useContext(OrgContext);

  const [coins, setCoins] = useState<
    { id: number; style: React.CSSProperties }[]
  >([]);

  useEffect(() => {
    // Function to create and animate a coin
    const animateCoin = (id: number) => {
      const initialStyle: React.CSSProperties = {
        left: `${Math.random() * 80 + 10}%`, // Random horizontal position
        bottom: "-24px", // Start from below the screen
        position: "fixed",
        width: "24px",
        height: "24px",
        transition: "all 3s ease-out",
        zIndex: 1000,
      };

      setCoins((prevCoins) => [...prevCoins, { id, style: initialStyle }]);

      // Animate the coin upwards
      setTimeout(() => {
        setCoins((prevCoins) =>
          prevCoins.map((coin) =>
            coin.id === id
              ? {
                  ...coin,
                  style: {
                    ...coin.style,
                    bottom: "120%", // Move beyond the top of the screen
                  },
                }
              : coin
          )
        );
      }, 100);

      // Remove the coin after animation
      setTimeout(() => {
        setCoins((prevCoins) => prevCoins.filter((coin) => coin.id !== id));
      }, 3100);
    };

    if (showCreditsButton) {
      // Animate 100 coins with slight delays
      for (let i = 0; i < 100; i++) {
        setTimeout(() => animateCoin(i), i * 30); // 30ms delay between each coin
      }
      setTimeout(() => {
        history.push(`/org/${orgContext.activeOrgId}/environments`);
      }, 6000);
    }
  }, [showCreditsButton]); // Empty dependency array ensures this runs only on mount

  return (
    <div className="flex flex-col items-center justify-center mt-5 max-w-[500px] text-center mt-10">
      <h1 className="text-md text-gray-700 dark:text-secondary font-mono">
        <WindupText
          text="Justin Boitano is currently paying for credits and we have a healthy supply of H100s, A100s, etc."
          onFinished={() => {
            setShowCreditsButton(true);
          }}
        />
      </h1>
      <div className="mt-3"></div>
      {coins.map((coin) => (
        <img key={coin.id} src={BrevCoin} style={coin.style} alt="BrevCoin" />
      ))}
    </div>
  );
};
