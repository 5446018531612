import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import DeployProgress from "components/DashboardContainer/DeployProgress";
import { CaretRightIcon } from "components/Icons";

import { OneClickDeployContext } from "contexts/OneClickDeployContext";
import Workspace from "entities/Workspace.entity";

interface OneClickDeployProps {
  showCaret: boolean;
}

const OneClickDeploy: React.FC<OneClickDeployProps> = ({ showCaret }) => {
  const oneClickDeployContext = useContext(OneClickDeployContext);

  return (
    <div className="inline-flex items-center px-1 pt-1 text-sm font-medium cursor-pointer">
      <DeployProgress
        activeStep={oneClickDeployContext.activeStep}
        setActiveStep={oneClickDeployContext.setActiveStep}
        steps={oneClickDeployContext.steps}
        readyAction={() => {
          oneClickDeployContext.readyAction();
          oneClickDeployContext.setShowDeploying(false);
        }}
        workspace={oneClickDeployContext.workspace as Workspace}
      />
      {showCaret && (
        <div
          onClick={() => {
            oneClickDeployContext.setShowDeploying(false);
          }}
        >
          <CaretRightIcon className="h-6 w-6 text-gray-900 dark:text-white" />
        </div>
      )}
    </div>
  );
};

export default OneClickDeploy;
