/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import {
  Capability,
  modifyFirewallCapability,
  OrgContext,
} from "contexts/OrgContext";
import Workspace from "../../../../../../../entities/Workspace.entity";
import agent from "server";
import FlatCard from "components/UI-lib/FlatCard";
import InputField from "components/UI-lib/InputField";
import Button from "components/UI-lib/Button";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import Modal from "components/Modals/Modal";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import {
  CreateApplicationRequest,
  RemoveApplicationRequest,
  SupportedServiceType,
} from "server/applications";
import UrlRowItem from "./UrlRowItem";
import DevToggle from "components/DevToggle";
import { getTunnelApplicationHealthCheck } from "components/Environment/utils";
import { Tooltip } from "@mui/material";

interface SetupScriptsSectionProps {
  workspace: Workspace;
  capabilities: Capability[];
}

const TunnelSection: React.FC<SetupScriptsSectionProps> = (props) => {
  const wsContext = useContext(WorkspaceContext);
  const orgContext = useContext(OrgContext);

  //Need a state to hold current editing Auth Users
  const [currentEditingApplication, setCurrentEditingApplication] = useState(
    {}
  );

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [creatingApplication, setCreatingApplication] = useState(false);
  const [workspace, setWorkspace] = useState<Workspace>(props.workspace);
  const [name, setName] = useState("");
  const [namePlaceholder, setNamePlaceholer] = useState("");
  const [port, setPort] = useState("");
  const [serviceType, setServiceType] = useState<SupportedServiceType>(
    SupportedServiceType.HTTP
  );

  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  useEffect(() => {
    if (name === "") {
      setNamePlaceholer(port);
    }
  }, [port]);

  // always reset the workspace from the context
  useEffect(() => {
    setWorkspace(props.workspace);
  }, [props.workspace]);

  // useEffect hook to close the notification after 7 seconds
  useEffect(() => {
    if (inlineAlertMessage && inlineAlertSeverity === "success") {
      const timer = setTimeout(() => {
        setInlineAlertMessage("");
      }, 7000);

      // Clear timer on unmount
      return () => clearTimeout(timer);
    }
  }, [inlineAlertMessage]);

  const handleAddNewApplication = async () => {
    const payload: CreateApplicationRequest = {
      name,
      port: parseInt(port, 10),
      serviceType: serviceType,
    };
    try {
      console.log(payload);
      const response = await agent.Applications.create(workspace.id, payload);

      // check response status
      if (response.success) {
        // success - show success notification
        setInlineAlertMessage(`Application ${name} created successfully.`);
        setInlineAlertSeverity("success");

        setName("");
        setPort("");

        // refresh the applications list
        wsContext.reloadWorkspaces(orgContext.activeOrgId);
        let tempWorkspace = workspace;
        tempWorkspace.tunnel = response.data;
        setWorkspace(tempWorkspace);
      } else {
        ("");
        // handle 404 error
        setInlineAlertMessage(
          `Failed to create application. ${response.message}`
        );
        setInlineAlertSeverity("error");
      }
    } catch (error) {
      console.error(error);
      // error - show error notification
      setInlineAlertMessage(
        "Failed to create application due to a network error."
      );
      setInlineAlertSeverity("error");
    }
  };

  const deleteApplication = async (applicationName: string) => {
    const payload: RemoveApplicationRequest = {
      applicationName: applicationName,
    };

    try {
      const response = await agent.Applications.remove(workspace.id, payload);
      if (response.success) {
        setInlineAlertMessage(
          `Application ${applicationName} removed successfully.`
        );
        setInlineAlertSeverity("success");

        // refresh the applications list
        wsContext.reloadWorkspaces(orgContext.activeOrgId);
        let tempWorkspace = workspace;
        tempWorkspace.tunnel = response.data;
        setWorkspace(tempWorkspace);
      } else {
        setInlineAlertMessage(
          `Failed to delete application. ${response.message}`
        );
        setInlineAlertSeverity("error");
      }
    } catch (error) {
      console.error(error);
      setInlineAlertMessage("Failed to delete application.");
      setInlineAlertSeverity("error");
    }
  };

  return (
    <div className="flex flex-col justify-start items-start w-full mt-5">
      <Modal
        isOpen={showCreateModal}
        setOpen={setShowCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setName("");
          setPort("");
        }}
        onSuccess={async () => {
          setCreatingApplication(true);
          await handleAddNewApplication();
          setCreatingApplication(false);
          setShowCreateModal(false);
        }}
        title={`Share a Service`}
        body={
          <div className="w-[450px] pb-10 dark:bg-zinc-900">
            <p className="text-sm text-gray-500 dark:text-secondary mb-2">
              Expose a service running on a port without having to open a port
              on your firewall.
            </p>
            <div className="flex flex-row mb-2">
              <DevToggle>
                <Dropdown
                  label="Type"
                  required={true}
                  className="mr-3"
                  displayFunction={(dv) => `${dv}`}
                  value={serviceType}
                  onChange={(dv) => {
                    setServiceType(dv);
                  }}
                >
                  {Object.values(SupportedServiceType).map((type, index) => (
                    <DropdownItem
                      displayValue={type}
                      value={type}
                      key={index}
                    />
                  ))}
                </Dropdown>
              </DevToggle>{" "}
              <InputField
                label="Port"
                required={true}
                // helperText="Enter the full URL of the Github repo"
                value={port}
                errorMessage=""
                placeholder="port"
                className="mr-3 w-[60%]"
                onChange={(val) => {
                  if (/^[0-9]*$/.test(val)) {
                    // this regex checks if val is composed only of digits
                    setPort(val);
                    if (name === "" || name === port) setName(val);
                  }
                }}
              />
              <InputField
                label="Name"
                required={true}
                // helperText="Enter the full URL of the Github repo"
                value={name}
                errorMessage=""
                placeholder={namePlaceholder}
                className="mr-3 w-[60%]"
                onChange={(val) => setName(val)}
              />
            </div>
            {/* BANANA: to get this working, we're gonna need  */}
            {/* <Access
              label="Allow Access"
              workspaceId={props.workspace.id}
              applicationName={name}
            /> */}
          </div>
        }
        disableSuccess={name === "" || port === ""}
        confirmLabel="Create"
      />

      <h1 className="text-xl mb-3 font-medium text-primary dark:text-white">
        Deployments
      </h1>
      <FlatCard className="pt-0 mb-3" noBorder={true}>
        <>
          <InlineNotification
            show={!!inlineAlertMessage}
            severity={inlineAlertSeverity}
            text={inlineAlertMessage}
            autoClose={false}
            onClose={() => setInlineAlertMessage("")}
            className="mb-3 mt-3"
          />

          <div className="flex flex-row justify-between items-center w-full mb-4 mt-4">
            <p className="text-md text-primary">
              Share any service or application running on your instance and use
              Brev auth to manage access.{" "}
              <a
                className="underline"
                href="https://brev.dev/docs/how-to/deployments"
                target="_blank"
                rel="noreferrer"
              >
                Docs here.
              </a>
            </p>
            <div>
              <Button
                label="Share a Service"
                type="primary"
                loading={creatingApplication}
                onClick={() => setShowCreateModal(true)}
                disabled={creatingApplication}
                className=""
              />
            </div>
          </div>
          <div className="w-full mb-1">
            <div className="-my-2 -mx-4 overflow-y-visible sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-zinc-800">
                    <thead className="bg-gray-50 dark:bg-zinc-800">
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                        >
                          Port
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                        >
                          Shareable URL
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                        >
                          Health
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300"
                        >
                          {/* Actions */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-600 bg-white dark:bg-zinc-900">
                      {workspace.exposedPorts &&
                        workspace.tunnel?.applications && (
                          <>
                            {workspace.tunnel?.applications.map(
                              (app, index) => (
                                <UrlRowItem
                                  key={index}
                                  isLoading={workspace.status === "DEPLOYING"}
                                  status={
                                    workspace.healthCheck === null ||
                                    workspace.healthCheck === undefined ||
                                    workspace.healthCheck.length === 0 ||
                                    workspace.status !== "RUNNING"
                                      ? "unknown"
                                      : getTunnelApplicationHealthCheck(
                                          workspace.healthCheck,
                                          app
                                        )
                                  }
                                  workspace={workspace}
                                  index={index + "-http"}
                                  // editModal={setShowEditModal}
                                  application={app}
                                  port={app.port}
                                  url={app.hostname}
                                  onDelete={async () => {
                                    // setDeletingApplication(true);
                                    await deleteApplication(app.name);
                                    // setDeletingApplication(false);
                                  }}
                                />
                              )
                            )}
                          </>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      </FlatCard>
    </div>
  );
};

// healthCheckStatus: "healthy", "unhealthy", "unknown"
// tunnelStatus: HEALTHY, UNHEALTHY, UNAVAILABLE
const makeUnifiedStatus = (tunnelStatus: string, healthCheckStatus: string) => {
  console.log(
    `tunnelStatus: ${tunnelStatus}, healthCheckStatus: ${healthCheckStatus}`
  );
  if (healthCheckStatus === "healthy") {
    return "healthy";
  } else if (
    tunnelStatus === "UNAVAILABLE" &&
    healthCheckStatus === "unknown"
  ) {
    return "unknown";
  } else {
    return "unhealthy";
  }
};
export default TunnelSection;
