import React, { useContext, useState } from "react";
import { NotificationContext } from "contexts/NotificationContext";
import InputField from "components/UI-lib/InputField";
import Button from "components/UI-lib/Button";
import { classNames } from "components/utils";

interface PortEntriesProps {
  namePortMapping: Record<string, string>;
  onAddEntry: (name: string, port: string) => void;
  onRemoveEntry: (name: string) => void;
}

const PortEntries: React.FC<PortEntriesProps> = ({
  namePortMapping,
  onAddEntry,
  onRemoveEntry,
}) => {
  const notificationContext = useContext(NotificationContext);
  const [entries, setEntries] = useState<{ name: string; port: string }[]>([]);

  const validatePort = (portString: string) => {
    const port = parseInt(portString, 10);
    const excludedPorts = [8888, 2222, 22];
    const portNames = { 8888: "Jupyter", 2222: "SSH", 22: "SSH" };

    if (isNaN(port)) {
      return { isValid: false, error: "Port number must be a number." };
    }
    if (port < 0 || port > 65534) {
      return {
        isValid: false,
        error: "Port number must be between 0 and 65534.",
      };
    }
    if (excludedPorts.includes(port)) {
      return {
        isValid: false,
        error: `Port ${port} (${portNames[port]}) is not allowed.`,
      };
    }
    return { isValid: true, error: "" };
  };

  const addEntry = (name: string, port: string) => {
    const validation = validatePort(port);
    if (validation.isValid && name && port && !namePortMapping[name]) {
      onAddEntry(name, port);
      // Remove the entry from entries state
      setEntries(entries.filter((entry) => entry.name !== name));
    } else {
      notificationContext.showNotification(
        "Instance Failure",
        validation.error || "Duplicate or missing name/port",
        "error"
      );
    }
  };

  const addEmptyEntry = () => {
    setEntries([...entries, { name: "", port: "" }]);
  };

  return (
    <div>
      <div>
        {Object.entries(namePortMapping).map(([name, port]) => (
          <div key={name} className="flex gap-2 mb-2">
            <InputField
              label="Name"
              errorMessage=""
              hideLabel={true}
              type="text"
              value={name}
              onChange={() => {}}
              placeholder="Enter name"
              className={classNames("CodeCard-GitUrlInputField opacity-50")}
              disabled={true}
            />
            <InputField
              label="Port"
              type="text"
              errorMessage=""
              hideLabel={true}
              value={port}
              onChange={() => {}}
              placeholder="Enter port"
              className={classNames("CodeCard-GitUrlInputField opacity-50")}
              disabled={true}
            />
            <button
              type="button"
              onClick={() => onRemoveEntry(name)}
              className="text-red-700 dark:text-red-700 focus:ring-2 focus:ring-highlightLighter"
            >
              ×
            </button>
          </div>
        ))}
        {entries.map((entry, index) => (
          <div key={index} className="flex gap-2 mb-2 ml-0.5">
            <InputField
              label="Name"
              errorMessage=""
              hideLabel={true}
              type="text"
              value={entry.name}
              onChange={(e) => {
                const newEntries = [...entries];
                newEntries[index].name = e;
                setEntries(newEntries);
              }}
              placeholder="Enter name"
              className="CodeCard-GitUrlInputField"
            />
            <InputField
              label="Port"
              hideLabel={true}
              type="text"
              errorMessage=""
              value={entry.port}
              onChange={(e) => {
                const newEntries = [...entries];
                newEntries[index].port = e;
                setEntries(newEntries);
              }}
              placeholder="Enter port"
              className="CodeCard-GitUrlInputField"
            />
            <button
              type="button"
              onClick={() => addEntry(entry.name, entry.port)}
              className="text-green-500 dark:text-green-500 focus:ring-2 focus:ring-highlightLighter"
            >
              ✓
            </button>
          </div>
        ))}
      </div>
      <Button
        label="Add"
        className={classNames("ml- mt-12, text-xs px-2 py-1")}
        type="primary"
        onClick={addEmptyEntry}
        size="small"
      />
    </div>
  );
};

export default PortEntries;
