import Role from "../entities/Role.entity";
import IPermission, { PermissionHierarchyType } from "models/Permission.model";
import { v4 as uuid } from "uuid";

export default class Permission implements IPermission {
  public id: string;

  public subject: string;

  public get subjectId() {
    return this.subject;
  }

  public object: string;

  public get objectId() {
    return this.object;
  }

  public role: Role;

  public hierarchyType: PermissionHierarchyType;

  public constructor(p: IPermission, hierarchyType: PermissionHierarchyType) {
    this.id = uuid();
    this.subject = p.subject;
    this.object = p.object;
    this.role = new Role(p.role);
    this.hierarchyType = hierarchyType;
  }
}
