import { LOCALSTORAGE_UI_MODE_KEY } from "constants/index";
import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import { UIMode } from "models/UIMode.model";
import React, { useContext, useEffect, useState } from "react";
import { appContextDefaultValues } from "tests/defaultValues";

interface IAppContext {
  uiMode: UIMode;
  isLoading: boolean;
  setupUIMode: () => void;
  updateUIMode: (mode: UIMode) => void;
}

export const AppContext = React.createContext<IAppContext>(
  appContextDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const AppContextProvider: React.FC<Props> = ({ children }) => {
  const [uiMode, setUiMode] = useState(UIMode.Dark);
  const [isLoading, setIsLoading] = useState(true);

  const { isLoading: userContextIsLoading } = useContext(UserContext);
  const { isLoading: orgContextIsLoading } = useContext(OrgContext);

  console.log("AppContext");
  console.log("userContextIsLoading", userContextIsLoading);
  console.log("orgContextIsLoading", orgContextIsLoading);

  const setupUIMode = () => {
    const mode = localStorage.getItem(LOCALSTORAGE_UI_MODE_KEY) || UIMode.Dark;
    if (mode === UIMode.Dark) {
      setUiMode(UIMode.Dark);
      document.body.classList.add(UIMode.Dark);
      document.body.classList.remove(UIMode.Light);
    } else {
      setUiMode(UIMode.Light);
      document.body.classList.add(UIMode.Light);
      document.body.classList.remove(UIMode.Dark);
    }
  };

  const updateUIMode = (mode: UIMode) => {
    localStorage.setItem(LOCALSTORAGE_UI_MODE_KEY, mode);
    if (mode === UIMode.Dark) {
      setUiMode(UIMode.Dark);
      document.body.classList.add(UIMode.Dark);
      document.body.classList.remove(UIMode.Light);
    } else {
      setUiMode(UIMode.Light);
      document.body.classList.add(UIMode.Light);
      document.body.classList.remove(UIMode.Dark);
    }
  };

  // useEffect(() => {
  //   setupUIMode();
  // }, []);

  // const isPublicRoute = useCallback((): boolean => {
  //   return window.location.pathname === "/login" ||
  //     window.location.pathname === "/logout" ||
  //     window.location.pathname === "/invite"
  // }, [window.location.pathname])

  useEffect(() => {
    // if any context is loading
    if (userContextIsLoading || orgContextIsLoading) {
      setIsLoading(true);
    }
    // if all are not loading
    if (!userContextIsLoading && !orgContextIsLoading) {
      setIsLoading(false);
    }
  }, [userContextIsLoading, orgContextIsLoading]);

  const providerValues = {
    uiMode,
    isLoading,
    setupUIMode,
    updateUIMode,
  };

  return (
    <AppContext.Provider value={providerValues}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;
