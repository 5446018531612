/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

import Button from "components/UI-lib/Button";

interface NotificationProps {
  title: string;
  message: string;
  severity: string; // error, warning, info, success
  action?: any;
}

const Notification: React.FC<NotificationProps> = (props) => {
  const [show, setShow] = useState(true);

  const getIcon = (severity: string) => {
    if (severity === "error") {
      return (
        <XCircleIcon className="h-6 w-6 text-rose-400" aria-hidden="true" />
      );
    }
    if (severity === "warning") {
      return (
        <ExclamationCircleIcon
          className="h-6 w-6 text-amber-400"
          aria-hidden="true"
        />
      );
    }
    if (severity === "info") {
      return (
        <InformationCircleIcon
          className="h-6 w-6 text-blue-400"
          aria-hidden="true"
        />
      );
    }
    if (severity === "success") {
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      );
    }
    return (
      <InformationCircleIcon
        className="h-6 w-6 text-blue-400"
        aria-hidden="true"
      />
    );
  };

  return (
    <div
      aria-live="polite"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[999]"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">{getIcon(props.severity)}</div>
                <div className="ml-3 w-0 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    {props.title}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">{props.message}</p>
                  {props.action && (
                    <div className="mt-4 flex">{props.action}</div>
                  )}
                </div>

                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlightLighter"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Notification;
