import React from "react";
import BrevSlider from "../Slider";

interface MinimumCPUsFilterProps {
  selectedMinimumNumberOfCPUs: number;
  setSelectedMinimumNumberOfCPUs: (number: number) => void;
}

const marks = [
  {
    value: 0,
    label: "Any",
  },
  {
    value: 300,
    label: "300",
  },
];

const MinimumCPUsFilter: React.FC<MinimumCPUsFilterProps> = ({
  selectedMinimumNumberOfCPUs,
  setSelectedMinimumNumberOfCPUs,
}) => {
  const onChange = (value: number) => {
    setSelectedMinimumNumberOfCPUs(value);
  };

  return (
    <>
      <div className="flex flex-col px-3">
        <BrevSlider
          step={4}
          value={selectedMinimumNumberOfCPUs}
          onChange={onChange}
          max={300}
          marks={marks}
        />
      </div>
    </>
  );
};

export default MinimumCPUsFilter;
