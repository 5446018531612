// This works because App.tsx has the unauthenticated route outside of the Dash, which requires auth.
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, Bars3Icon, XCircleIcon } from "@heroicons/react/24/outline";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { OrgContext } from "contexts/OrgContext";
import LightModeLogo from "assets/img/svg/logo_light_mode.svg?react";
import DarkModeLogo from "assets/img/svg/logo_dark_mode.svg?react";
import { useHistory, useLocation } from "react-router-dom";
import shaka from "assets/img/png/shaka.png";
import pina1 from "assets/img/png/pina1.png";
import pina2 from "assets/img/png/pina2.png";
import pina3 from "assets/img/png/pina3.png";
import pina4 from "assets/img/png/pina3.png";
import { NotificationContext } from "contexts/NotificationContext";
import { UserContext } from "contexts/UserContext";
import User from "../../entities/User.entity";
import DevToggle from "components/DevToggle";
import OrgDropdown from "../OrgPages/OrgDropdown";
import OrgSettingsSlideOver from "./OrgSlideOver";
import Button from "components/UI-lib/Button";
import { DarkModeContext } from "../../contexts/DarkModeContext";
import { GlobalUserType } from "models/User.model";
import "./index.scss";
import axios from "axios";
import agent from "server";
import { useAuth0 } from "@auth0/auth0-react";
import Dash from "./Dash";
import { useKas } from "contexts/KasContext";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";

const userNavigation = [
  { name: "Create an account!", href: "/" },
  { name: "Support / Help Center", href: "/help" },
];

const adminNavigation = [
  {
    name: "📆 Content Calendar",
    href: "https://docs.google.com/spreadsheets/d/1ds4n4G2YCgt-7QTndLBHNSk-IMg5Jr-WKqbC5YZ_n-4/edit?usp=sharing",
  },
  {
    name: "📈 Mixpanel Daily Board",
    href: "https://mixpanel.com/project/2604005/view/3142793/app/boards#id=1652147",
  },
  {
    name: "✒️ Figma Planning",
    href: "https://www.figma.com/file/gmECGaSCLadCJBYXWJUc4s/Planning?type=whiteboard&node-id=0%3A1&t=OA1DduRdUmQUh9P3-1",
  },
  {
    name: "🗺️ Figma Map",
    href: "https://www.figma.com/file/OMVmr6xiq7VlqwjJTlND2V/Mapping-it-out?type=whiteboard&node-id=0%3A1&t=uDngcVQaYbTNBdTb-1",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
}
interface OptionalCallbackProps {
  children: React.ReactNode;
  callbackUrl?: string;
  notebookNotFound?: boolean;
  isGiftCard?: boolean;
}

export const DashWrapper: React.FC<OptionalCallbackProps> = (props) => {
  const { isAuthenticated: _auth0IsAuthenticated, user: _auth0User } =
    useAuth0?.();

  const { isUserLoggedIn: kasIsAuthenticated, user: kasUser } = useKas();

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0user = isKasAuthFlow ? kasUser : _auth0User;

  if (auth0IsAuthenticated && auth0user) {
    return <>{props.children}</>;
  } else {
    return (
      <UnauthenticatedDash
        callbackUrl={props.callbackUrl}
        notebookNotFound={props.notebookNotFound}
        isGiftCard={props.isGiftCard}
      >
        {props.children}
      </UnauthenticatedDash>
    );
  }
};

const GetActionLabel = (notebookNotFound, isGiftCard) => {
  if (location.pathname.includes("notebook") && !notebookNotFound) {
    return "Deploy Notebook 🚀";
  }
  if (isGiftCard) {
    return "Go to Console";
  }
  return "Create an Account";
};

const UnauthenticatedDash: React.FC<OptionalCallbackProps> = (props) => {
  const orgContext = useContext(OrgContext);
  const [me, setMe] = useState<User | null>(null);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("Instances");
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);

  // we need to adjust tabs when the org context loads
  const tabs = [];
  const [navigation, setNavigation] = useState<NavigationItem[]>(tabs);
  const location = useLocation();
  // TODO: manually check if the user is authenticated. Something from usercontext

  const showTopButton = () => {
    if (
      location.pathname.includes("/launchable/deploy") &&
      !location.pathname.includes("/launchable/deploy/now")
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-primaryBg">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <GetLogo
                      onClick={() => {
                        // TODO: make this something better
                        history.push(`/login`);
                      }}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-highlight border-highlight text-highlight"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200 dark:border-zinc-800">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={me?.profilePhotoUrl || shaka}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {me?.name}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {me?.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="">
        <main>
          <div className="">{props.children}</div>
        </main>
      </div>
    </div>
  );
};

export default UnauthenticatedDash;

function SunIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      {...props}
    >
      <path d="M8 12.25A4.25 4.25 0 0 1 12.25 8v0a4.25 4.25 0 0 1 4.25 4.25v0a4.25 4.25 0 0 1-4.25 4.25v0A4.25 4.25 0 0 1 8 12.25v0Z" />
      <path
        d="M12.25 3v1.5M21.5 12.25H20M18.791 18.791l-1.06-1.06M18.791 5.709l-1.06 1.06M12.25 20v1.5M4.5 12.25H3M6.77 6.77 5.709 5.709M6.77 17.73l-1.061 1.061"
        fill="none"
      />
    </svg>
  );
}

function MoonIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M17.25 16.22a6.937 6.937 0 0 1-9.47-9.47 7.451 7.451 0 1 0 9.47 9.47ZM12.75 7C17 7 17 2.75 17 2.75S17 7 21.25 7C17 7 17 11.25 17 11.25S17 7 12.75 7Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

interface ModeToggleProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
}

const ModeToggle = React.forwardRef<HTMLAnchorElement, ModeToggleProps>(
  ({ darkMode, toggleDarkMode }, ref) => {
    document.documentElement.classList.add("[&_*]:!transition-none");
    window.setTimeout(() => {
      document.documentElement.classList.remove("[&_*]:!transition-none");
    }, 0);

    return (
      <a
        aria-label="Toggle dark mode"
        // className="group rounded-full bg-white/90 px-3 py-2 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20"
        className="block px-4 py-2 text-sm text-gray-700 dark:text-secondary cursor-pointer flex flex-row justify-start items-center  "
        onClick={toggleDarkMode}
      >
        <SunIcon className="h-6 w-6 fill-zinc-100 stroke-zinc-500 transition group-hover:fill-zinc-200 group-hover:stroke-zinc-700 dark:hidden [@media(prefers-color-scheme:dark)]:fill-teal-50 [@media(prefers-color-scheme:dark)]:stroke-teal-500 [@media(prefers-color-scheme:dark)]:group-hover:fill-teal-50 [@media(prefers-color-scheme:dark)]:group-hover:stroke-teal-600" />
        <MoonIcon className="hidden h-6 w-6 fill-zinc-900 stroke-cyan-300 transition dark:block [@media(prefers-color-scheme:dark)]:group-hover:stroke-zinc-400 [@media_not_(prefers-color-scheme:dark)]:fill-teal-400/10 [@media_not_(prefers-color-scheme:dark)]:stroke-teal-500" />
        {`Toggle ${darkMode === true ? "light" : "dark"} mode`}
      </a>
    );
  }
);

function GetLogo(props) {
  function toggleMode() {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const isSystemDarkMode = darkModeMediaQuery.matches;
    const isDarkMode = document.documentElement.classList.toggle("dark");

    if (isDarkMode === isSystemDarkMode) {
      delete window.localStorage.isDarkMode;
    } else {
      window.localStorage.isDarkMode = isDarkMode;
    }
  }

  return (
    <>
      <LightModeLogo
        className="block h-8 w-8 dark:hidden"
        style={{ cursor: "pointer" }}
        onClick={props.onClick}
      />
      <DarkModeLogo
        className="block h-8 w-8 hidden dark:block"
        style={{ cursor: "pointer" }}
        onClick={props.onClick}
      />
    </>
  );
  // return (
  //   <button
  //     type="button"
  //     aria-label="Toggle dark mode"
  //     className="group rounded-full bg-white/90 px-3 py-2 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20"
  //     onClick={toggleMode}
  //   >
  //     <SunIcon className="h-6 w-6 fill-zinc-100 stroke-zinc-500 transition group-hover:fill-zinc-200 group-hover:stroke-zinc-700 dark:hidden [@media(prefers-color-scheme:dark)]:fill-teal-50 [@media(prefers-color-scheme:dark)]:stroke-teal-500 [@media(prefers-color-scheme:dark)]:group-hover:fill-teal-50 [@media(prefers-color-scheme:dark)]:group-hover:stroke-teal-600" />
  //     <MoonIcon className="hidden h-6 w-6 fill-zinc-700 stroke-zinc-500 transition dark:block [@media(prefers-color-scheme:dark)]:group-hover:stroke-zinc-400 [@media_not_(prefers-color-scheme:dark)]:fill-teal-400/10 [@media_not_(prefers-color-scheme:dark)]:stroke-teal-500" />
  //   </button>
  // );
}
