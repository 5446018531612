import React, { useContext, useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { DarkModeContext } from "contexts/DarkModeContext";
import { dump, load } from "js-yaml";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import {
  VerbConfigType,
  WorkspaceCreateContext,
} from "contexts/WorkspaceCreateContext";
import { yamlRawDump } from "components/utils";
import Workspace from "entities/Workspace.entity";
import VerbYamlWriter from "./VerbYamlWriter";
import { buildDefaultVerbYamlConfig } from "./utils";
import { VERB_FAILED_PHRASE } from "components/Environment/Settings/utils";
import { VerbBuildStatus, WorkspaceStatus } from "models/Workspace.model";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import { create } from "lodash";

interface VerbYamlWriterProps {
  workspace: Workspace;
}

const CustomizeVerb: React.FC<VerbYamlWriterProps> = ({ workspace }) => {
  const createContext = useContext(WorkspaceCreateContext);
  const [buildButtonDisabled, setBuildButtonDisabled] = useState(false);

  const buildVerbContainer = async (customYaml = "") => {
    createContext.setBuildButtonDisabled(true);
    createContext.setLogFileContent("");
    let res = await createContext.BuildVerbContainerOnWorkspace(
      workspace.id,
      customYaml || createContext.verbYaml
    );
    if (res.success && res.data) {
      // setBuildTriggeredNoErrors(true);
      createContext.setHitBuildButton(true);
    } else {
      // handle this error
      console.log(res);
    }
  };

  useEffect(() => {
    if (
      !(
        createContext.logFileContent.includes(VERB_FAILED_PHRASE) ||
        workspace?.verbBuildStatus === VerbBuildStatus.CreateFailed
      ) &&
      (createContext.verbYaml === "" ||
        createContext.isVerbBuilding === true ||
        workspace?.status === WorkspaceStatus.Deleting ||
        workspace?.status === WorkspaceStatus.Stopping ||
        workspace?.status === WorkspaceStatus.Stopped)
    ) {
      setBuildButtonDisabled(true);
    } else {
      setBuildButtonDisabled(false);
    }
  }, [createContext.verbYaml, createContext.isVerbBuilding, workspace?.status]);

  if (workspace && workspace.status !== WorkspaceStatus.Running) {
    return (
      <div className="flex mt-5 w-1/2">
        <FlatCard isLoading={false}>
          <p className="text-sm text-gray-700 dark:text-secondary font-mono">
            Instance needs to be running to change the container.
          </p>
        </FlatCard>
      </div>
    );
  }
  if (workspace && workspace.workspaceGroupId === "akash-brev-wg") {
    return (
      <div className="flex mt-5 w-1/2">
        <FlatCard isLoading={false}>
          <p className="text-sm text-gray-700 dark:text-secondary font-mono">
            Unfortunately, container customization is not available for this
            provider (Akash).
          </p>
        </FlatCard>
      </div>
    );
  }

  return (
    <div className="w-1/2">
      {workspace && workspace?.verbYaml && (
        <>
          <div className="mt-5">
            <VerbYamlWriter
              verbYaml={createContext.verbYaml}
              setVerbYaml={createContext.setVerbYaml}
            />
          </div>
          <Button
            className="mt-4 justify-center"
            type={buildButtonDisabled ? "secondary" : "primary"}
            label="Build"
            disabled={buildButtonDisabled}
            loading={createContext.isBuilding}
            onClick={() => {
              buildVerbContainer(createContext.verbYaml);
            }}
          />
        </>
      )}
    </div>
  );
};

export default CustomizeVerb;
