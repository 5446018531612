import React, { useState, useEffect } from "react";
import "./styles.scss";

interface BigOlButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  className: string;
}

const DeployButton: React.FC<BigOlButtonProps> = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <button
      //   disableRipple={true}
      className={`${props.className} ${isClicked ? "clicked" : ""}`}
      onKeyPress={() => {
        setIsClicked(true);
      }}
      onTouchStart={() => {
        setIsClicked(true);
      }}
      onMouseDown={() => {
        setIsClicked(true);
      }}
      onPointerDown={() => {
        setIsClicked(true);
      }}
      onMouseUp={() => {
        setTimeout(() => {
          setIsClicked(false);
        }, 200);
      }}
      onPointerUp={() => {
        setTimeout(() => {
          setIsClicked(false);
        }, 200);
      }}
      onTouchEnd={() => {
        setTimeout(() => {
          setIsClicked(false);
        }, 200);
      }}
      onClick={() => {
        setTimeout(() => {
          props.onClick();
        }, 400);
      }}
    >
      {props.children}
    </button>
  );
};

export default DeployButton;
