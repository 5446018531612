import Workspace from "entities/Workspace.entity";
import { useMemo } from "react";
import yaml from "js-yaml";
import { dump, load } from "js-yaml";
import { yamlRawDump } from "components/utils";

export const validPythonVersions = ["3.10", "3.11"];

export const validCudaVersions = [
  "12.2.2",
  "12.1.1",
  "12.1.0",
  "12.0.1",
  "12.0.0",
];

export function getVerbBuildConfigFromWorkspace(
  stringifiedVerbYaml: string | undefined | null
) {
  const verbBuild = useMemo(() => {
    if (stringifiedVerbYaml && stringifiedVerbYaml) {
      const verbYaml = yaml.load(stringifiedVerbYaml);
      console.log(verbYaml);
      return verbYaml?.build || null;
    }
    return null;
  }, [stringifiedVerbYaml]);

  return verbBuild;
}

type Config = {
  build?: Build;
  user?: User;
  ports?: string[];
  services?: Services[];
};

type Build = {
  system_packages: string[];
  python_version: string;
  cuda: string;
  base_image?: string;
  python_packages: string[];
  run: string[];
};

type User = {
  name?: string;
  shell: string;
  home?: string;
  authorized_keys_path: string;
};

type Services = {
  name: string;
  entrypoint?: string;
  workingDirectory?: string;
  ports: string[];
};

export const buildDefaultVerbYamlConfig = (
  baseImage: string = "",
  portMappings: Record<string, string> | null,
  cudaVersion: string = "12.0.1",
  pythonVersion: string = "3.10",
  jupyterPort: number = 8888,
  isLaunchable: boolean = false
) => {
  let config: Config = {};
  console.log("jupyterPort second", jupyterPort);
  if (baseImage !== "") {
    config = {
      build: {
        system_packages: [],
        python_version: pythonVersion,
        cuda: cudaVersion,
        base_image: baseImage,
        python_packages: ["jupyterlab"],
        run: [
          'sh -c "$(curl -fsSL https://raw.githubusercontent.com/ohmyzsh/ohmyzsh/master/tools/install.sh)" "" --unattended',
        ],
      },
      user: {
        name: "root",
        shell: "zsh",
        home: "/root",
        authorized_keys_path: "/home/ubuntu/.ssh/authorized_keys",
      },
      ports: ["2222:22"],
      services: [
        {
          name: "jupyter",
          entrypoint:
            "jupyter-lab --allow-root --ip=0.0.0.0 --no-browser --NotebookApp.token='' --NotebookApp.password=''",
          workingDirectory: "/root/verb-workspace",
          ports: [`127.0.0.1:${jupyterPort}:${jupyterPort}`],
        },
      ],
    };
  } else {
    config = {
      build: {
        system_packages: [],
        python_version: pythonVersion,
        cuda: cudaVersion,
        python_packages: ["jupyterlab"],
        run: [
          'sh -c "$(curl -fsSL https://raw.githubusercontent.com/ohmyzsh/ohmyzsh/master/tools/install.sh)" "" --unattended',
        ],
      },
      user: {
        shell: "zsh",
        authorized_keys_path: "/home/ubuntu/.ssh/authorized_keys",
      },
      ports: ["2222:22"],
      services: [
        {
          name: "jupyter",
          entrypoint:
            "jupyter-lab --ip=0.0.0.0 --no-browser --NotebookApp.token='' --NotebookApp.password=''",
          ports: [`127.0.0.1:${jupyterPort}:${jupyterPort}`],
        },
      ],
    };
  }
  config = customVerbYamlConfigLogic(config, isLaunchable, jupyterPort || 8888);
  if (portMappings) {
    Object.keys(portMappings).forEach((portName) => {
      const newService = {
        name: portName,
        ports: [
          `127.0.0.1:${portMappings[portName]}:${portMappings[portName]}`,
        ],
      };

      if (config.services) {
        config.services.push(newService);
      } else {
        config.services = [newService];
      }
    });
  }

  console.log(yamlRawDump(config));
  return yamlRawDump(config);
};

// TO DO: environments
const customVerbYamlConfigLogic = (
  config: Config,
  isLaunchable: boolean,
  jupyterPort: number
): Config => {
  console.log("jupyterPort third", jupyterPort);
  if (
    config.build?.base_image ==
      "nvcr.io/nvidia/rapidsai/notebooks:24.04-cuda12.0-py3.10" &&
    !isLaunchable
  ) {
    config.services = [
      {
        name: "jupyter",
        entrypoint:
          "jupyter-lab --allow-root --notebook-dir=/home/rapids/notebooks --ip=0.0.0.0 --no-browser --NotebookApp.token='' --NotebookApp.password=''",
        ports: [`127.0.0.1:${jupyterPort}:${jupyterPort}`],
        workingDirectory: "/root/verb-workspace",
      },
    ];
  } else if (
    config.build?.base_image ==
    "nvcr.io/mphexwv2ysej/meta-llama3-8b-instruct:24.05.rc7"
  ) {
    config.build.system_packages = ["python3-pip"];
  } else if (
    config.build?.base_image == "nvcr.io/nvidia/tensorflow:24.05-tf2-py3"
  ) {
    config.build.run.push("pip install -U jupyterlab");
  }

  if (jupyterPort != 8888) {
    const jupyterLabString = `jupyter-lab --allow-root --ip=0.0.0.0 --port ${jupyterPort} --no-browser --NotebookApp.token='' --NotebookApp.password=''`;
    config.services = [
      {
        name: "jupyter",
        entrypoint: jupyterLabString,
        ports: [`127.0.0.1:${jupyterPort}:${jupyterPort}`],
      },
    ];
  }
  console.log(yamlRawDump(config));
  return config;
};
