import React, { useState } from "react";
import { FileType, getFileType } from "../../utils";
import HtmlRenderer from "components/Notebooks/HtmlRenderer";
import { BookOpenIcon } from "@heroicons/react/24/solid";
import { classNames } from "components/utils";
import GitHubRepoRenderer from "components/Notebooks/GithubRepoRenderer";
import { useHistory } from "react-router";
import MarkdownRenderer from "./MarkdownViewer";
import { Spinner } from "@kui-react/spinner";

interface FileViewerProps {
  file?: string | null;
}

function getMarkdownReadmeUrl(repoUrl) {
  // Remove trailing slash and .git if present
  repoUrl = repoUrl.replace(/\/+$/, "");
  if (repoUrl.endsWith(".git")) {
    repoUrl = repoUrl.slice(0, -4);
  }

  // Extract username and repo name
  const parts = repoUrl.split("/");
  const username = parts[parts.length - 2];
  const repoName = parts[parts.length - 1];

  // Construct markdown URL format (points directly to the README in the GitHub UI)
  //raw.githubusercontent.com/${username}/${repoName}/main/
  const baseUrl = `https://raw.githubusercontent.com/${username}/${repoName}/main/`;
  return baseUrl + "README.md";
}

const FileViewer: React.FC<FileViewerProps> = ({ file }) => {
  const fileType = getFileType(file);
  const [loadingNotebook, setLoadingNotebook] = useState(false);
  const [callBackUrl, setCallBackUrl] = useState("");
  const [noteBookNotFound, setNoteBookNotFound] = useState(false);
  const history = useHistory();

  const handleNavigation = (url: string) => {
    history.push(url);
  };

  return (
    <div>
      <div className="mt-5">
        <h1 className="text-xl font-semibold mt-5 text-white">
          Content Preview:
        </h1>
        {file && (
          <>
            {(fileType === FileType.Colab ||
              fileType === FileType.Notebook) && (
              <p className="text-xs text-secondary">
                This jupyter notebook will be present in the instance after
                deployment
              </p>
            )}
            {(fileType === FileType.GithubRepo ||
              fileType === FileType.GitlabRepo) && (
              <p className="text-xs text-secondary">
                This repository will be present in the instance after deployment
              </p>
            )}
          </>
        )}
      </div>
      {!file && (
        <>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          <div className="flex flex-col mt-4 text-gray-900 dark:text-secondary">
            <p className="text-sm">
              This is a basic environment configuration with only hardware and
              container settings.
            </p>
            <p className="text-sm">No files or content were included.</p>
          </div>
        </>
      )}
      {file &&
        (fileType === FileType.Colab || fileType === FileType.Notebook) && (
          <>
            <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
            {loadingNotebook && (
              <div className="flex flex-col justify-center">
                <div className="flex flex-col justify-center items-center">
                  <Spinner className="w-24 h-24" />
                  <p className="text-sm text-secondary mt-2">
                    Loading Notebook...
                  </p>
                </div>
              </div>
            )}
            <HtmlRenderer
              file={file}
              setCallbackUrl={setCallBackUrl}
              setNoteBookNotFound={setNoteBookNotFound}
              onNavigate={handleNavigation}
              hideRecommendedNotebooks={true}
              setLoading={setLoadingNotebook}
            />
          </>
        )}
      {file && fileType === FileType.GithubRepo && (
        <>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          <div className="flex flex-row items-end mt-4 mb-2 text-gray-900 dark:text-secondary">
            <div className="mr-1">
              <h1 className="text-lg font-semibold">Repository Info</h1>
            </div>
          </div>
          {loadingNotebook && (
            <div className="flex flex-col justify-center">
              <div className="flex flex-col justify-center items-center">
                <Spinner className="w-24 h-24" />
                <p className="text-sm text-secondary mt-2">
                  Loading Repository Information...
                </p>
              </div>
            </div>
          )}
          <GitHubRepoRenderer repoUrl={file} />
        </>
      )}
      {file &&
        (fileType === FileType.GithubRepo ||
          fileType === FileType.GitlabRepo) && (
          <>
            <div className="flex flex-row mb-3">
              <h1 className="text-lg font-semibold text-secondary">
                README.md
              </h1>
            </div>
            <MarkdownRenderer markdownUrl={getMarkdownReadmeUrl(file)} />
          </>
        )}
    </div>
  );
};

export default FileViewer;
