import { useLocation } from "react-router";

export const getLaunchableQueryParams = (searchParams: string) => {
  const query = new URLSearchParams(searchParams);
  const queryInstance = query.get("instance");
  const queryWorkspaceGroupID = query.get("cloudID");
  const queryBaseImage = query.get("baseImage");
  const queryCuda = query.get("cuda");
  const queryPython = query.get("python");
  const queryDiskStorage = query.get("diskStorage");
  const queryFile = query.get("file");
  const queryPorts = query.get("ports");
  const queryUserID = query.get("userID");
  const queryOrgID = query.get("orgID");
  const queryName = query.get("name");
  const queryLaunchableID = query.get("launchableID");

  return {
    queryInstance,
    queryWorkspaceGroupID,
    queryBaseImage,
    queryCuda,
    queryPython,
    queryDiskStorage,
    queryFile,
    queryPorts,
    queryUserID,
    queryOrgID,
    queryName,
    queryLaunchableID,
  };
};

export const computeLaunchableHashId = async (
  query: URLSearchParams
): Promise<string> => {
  const params = {};
  for (let [key, value] of query.entries()) {
    params[key] = value;
  }
  const jsonString = JSON.stringify(params);
  let hash;
  try {
    hash = await calculateSHA1(jsonString);
  } catch (e) {
    console.error(e);
    return "unknown";
  }
  return hash;
};

async function calculateSHA1(text) {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);

  const digest = await crypto.subtle.digest("SHA-1", data);

  const hashArray = Array.from(new Uint8Array(digest));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
}
