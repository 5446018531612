import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import Credits from "./Credits";
import ConnectCard from "./ConnectCard";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import "./style.scss";

interface PaymentAndCreditsProps {
  withCoupon: string;
}
const PaymentAndCredits: React.FC<PaymentAndCreditsProps> = (props) => {
  const orgContext = useContext(OrgContext);

  if (orgContext.activeOrg) {
    return (
      <FlatCard noBorder={true} className="w-full">
        <div className="flex flex-col w-full">
          <h1 className="text-xl font-medium dark:text-white mb-1">
            Pay For Compute
          </h1>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          <div className="flex flex-row">
            <div className="flex flex-col min-w-[400px] max-w-[400px]">
              <ConnectCard />
            </div>
            <div className="flex flex-col mr-3 ml-3"></div>
            <div className="flex flex-col">
              <Credits withCoupon={props.withCoupon} />
            </div>
          </div>
        </div>
      </FlatCard>
    );
  }
  // when clicking the settings button, reponse time is so fast the seeing loading bars feels off
  return <div />;
};

export default PaymentAndCredits;
