import React, { useContext, useEffect, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import {
  ChevronRightIcon,
  XCircleIcon,
  PlusIcon,
  CpuChipIcon,
  ArrowUpRightIcon,
  XMarkIcon,
  ArrowRightIcon,
  BookOpenIcon,
} from "@heroicons/react/24/solid";
import OtherGitRepoIcon from "assets/img/svg/other-git-repo.svg?react";

import { WorkspaceStatus } from "models/Workspace.model";
import { IRepo } from "models/Repo.model";
import { useHistory } from "react-router-dom";
import Workspace from "../../../entities/Workspace.entity";
import { PermissionsContext } from "contexts/PermissionsContext";
import {
  classNames,
  fetchLegacyNotebookList,
  fetchNotebookList,
  isCPU_techdebt,
  sanitizeNotebookUrl,
} from "components/utils";
import DevToggle, { FeatureToggle } from "components/DevToggle";
import UsernameDisplayField from "components/UsernameDisplayField";
import { Listbox } from "@headlessui/react";
import { supportedCPUInstances } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/CPUSelectorTable";
import Onboardings from "Onboardings";
import NewInstanceMetadata, {
  InstanceMetadataSkeleton,
} from "./InstanceMetadata";
import InstanceMetadata from "./InstanceMetadata";
import { useWindupString } from "windups";
import DynamicBadge from "components/UI-lib/DynamicBadge";
import { set } from "lodash";
import NotebookListItem from "components/Notebooks/NotebookListItem";
import { DiscoverCardData } from "components/Discover";
import { getLaunchableQueryParams } from "../Launchables/Deploy/shared/utils";
import { NVIDIA_SOLUTIONS_ARCHITECT_ORG } from "../utils/orgProfiler";
import { ACCENTURE_ORG } from "../utils/orgProfiler";

const GettingStarted: React.FC = (props) => {
  const orgContext = useContext(OrgContext);
  const [text] = useWindupString("Need help getting started?");
  const [showOptions, setShowOptions] = useState<boolean | null>(null);
  return (
    <>
      {showOptions === null && (
        <>
          <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono mt-10">
            {text}
          </p>
          <div className="flex flex-row mt-3">
            <DynamicBadge
              text="yes"
              isActive={false} // isActive is not relevant anymore, but if it's required by Badge props, set a default
              setIsActive={() => {
                setShowOptions(true);
              }}
              hideIcon={true}
              className="ml-1"
            />
            <DynamicBadge
              text="no"
              isActive={false} // isActive is not relevant anymore, but if it's required by Badge props, set a default
              setIsActive={() => {
                setShowOptions(false);
              }}
              hideIcon={true}
              className="ml-1"
            />
          </div>
        </>
      )}
      {showOptions === true &&
        orgContext.activeOrgId !== NVIDIA_SOLUTIONS_ARCHITECT_ORG && (
          <GettingStartedOverview setShowOptions={setShowOptions} />
        )}
      {showOptions === true &&
        orgContext.activeOrgId === NVIDIA_SOLUTIONS_ARCHITECT_ORG && (
          <GettingStartedForSAS setShowOptions={setShowOptions} />
        )}
    </>
  );
};

export default GettingStarted;

interface GettingStartedOverviewProps {
  setShowOptions: (showOptions: boolean | null) => void;
}

const GettingStartedForSAS: React.FC<GettingStartedOverviewProps> = (props) => {
  const history = useHistory();
  const [questionText, { isFinished }] = useWindupString(
    "What are you looking to do?"
  );
  const [answer, setAnswer] = useState<string>("");

  return (
    <>
      {answer === "" && (
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex flex-row mb-3">
                <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono mt-10 mr-3">
                  {questionText}
                </p>
              </div>
              {isFinished && (
                <div className="flex flex-col bg-white dark:bg-zinc-900 dark:text-secondary rounded-md px-4 py-4 border border-gray-500 dark:border-zinc-800">
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => history.push("/environment/new")}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    I want to a GPU Sandbox for POC development
                  </span>
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => history.push("/launchables/create")}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    I want to create a Launchable for an external
                    partners/client
                  </span>

                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => {
                      history.push("/org/discover");
                    }}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    I want to check out existing blueprints, workflows, and
                    examples
                  </span>

                  <div className="mt-3 flex justify-between">
                    <div></div>
                    <DynamicBadge
                      text="Close"
                      isActive={false} // isActive is not relevant anymore, but if it's required by Badge props, set a default
                      setIsActive={() => {
                        props.setShowOptions(false);
                      }}
                      hideIcon={true}
                      className="ml-1"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface GettingStartedOverviewProps {
  setShowOptions: (showOptions: boolean | null) => void;
}

const GettingStartedOverview: React.FC<GettingStartedOverviewProps> = (
  props
) => {
  const history = useHistory();
  const [questionText, { isFinished }] = useWindupString(
    "What are you looking to do?"
  );
  const [answer, setAnswer] = useState<string>("");
  const [notebookList, setNotebookList] = useState([]);

  useEffect(() => {
    const fetchNotebooks = async () => {
      try {
        const notebookList = await fetchNotebookList();
        setNotebookList(notebookList);
      } catch (e) {
        console.log(e);
      }
    };
    fetchNotebooks();
  }, []);

  return (
    <>
      {answer === "" && (
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex flex-row mb-3">
                <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono mt-10 mr-3">
                  {questionText}
                </p>
              </div>
              {isFinished && (
                <div className="flex flex-col bg-white dark:bg-zinc-900 dark:text-secondary rounded-md px-4 py-4 border border-gray-500 dark:border-zinc-800">
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => history.push("/environment/new")}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    I want to use a GPU from scratch
                  </span>
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => setAnswer("finetune")}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    I want to fine-tune a model
                  </span>

                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => {
                      setAnswer("model");
                    }}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    I want to use a specific model
                  </span>

                  <div className="mt-3 flex justify-between">
                    <div></div>
                    <DynamicBadge
                      text="Close"
                      isActive={false} // isActive is not relevant anymore, but if it's required by Badge props, set a default
                      setIsActive={() => {
                        props.setShowOptions(false);
                      }}
                      hideIcon={true}
                      className="ml-1"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {answer === "finetune" && (
        <>
          <GettingStartedNoteBookView
            setShowOptions={props.setShowOptions}
            notebookList={notebookList.filter((item: any) =>
              item.tags.includes("finetune")
            )}
            goBack={() => setAnswer("")}
            title="Here are some fine-tuning notebooks:"
          />
        </>
      )}
      {answer === "model" && (
        <>
          <GettingStartedModelOverview
            setShowOptions={props.setShowOptions}
            notebookList={notebookList}
            goBack={() => setAnswer("")}
          />
        </>
      )}
    </>
  );
};

interface GettingStartedNoteBookViewProps {
  setShowOptions: (showOptions: boolean | null) => void;
  notebookList: any[];
  goBack: () => void;
  title: string;
}

const GettingStartedNoteBookView: React.FC<GettingStartedNoteBookViewProps> = (
  props
) => {
  const history = useHistory();
  const orgContext = useContext(OrgContext);
  const [questionText, { isFinished }] = useWindupString(props.title);

  return (
    <>
      <div className="flex">
        <div className="flex flex-col min-w-[320px]">
          <div className="flex flex-row mb-3">
            <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono mt-10 mr-3">
              {questionText}
            </p>
          </div>
          {isFinished && (
            <div className="flex flex-col bg-white dark:bg-zinc-900 dark:text-secondary rounded-md px-4 py-4 border border-gray-500 dark:border-zinc-800 mr-4">
              <span
                className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                onClick={() => history.push(`/org/discover`)}
              >
                <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                Show me all Launchables
              </span>
              <div className="mt-3 flex justify-between">
                <DynamicBadge
                  text="Back"
                  isActive={false} // isActive is not relevant anymore, but if it's required by Badge props, set a default
                  setIsActive={() => {
                    props.goBack();
                  }}
                  hideIcon={true}
                  className="ml-1"
                />
                <DynamicBadge
                  text="Close"
                  isActive={false} // isActive is not relevant anymore, but if it's required by Badge props, set a default
                  setIsActive={() => {
                    props.setShowOptions(false);
                  }}
                  hideIcon={true}
                  className="ml-1"
                />
              </div>
            </div>
          )}
        </div>
        {isFinished && (
          <div className="mt-6">
            <div className="mt-4 grid grid-cols-1 gap-2 sm:grid-cols-2">
              {props.notebookList.map(
                (notebookConfig: DiscoverCardData, index) => {
                  let queryParams = "";
                  try {
                    queryParams = new URL(notebookConfig.launchable_link)
                      .search;
                  } catch (error) {
                    console.error("Error:", error);
                  }

                  const { queryInstance, queryName } =
                    getLaunchableQueryParams(queryParams);
                  let gpu = "";
                  if (queryInstance) {
                    const [chipTypeOrInstanceType, instanceType] =
                      queryInstance.split("@");
                    gpu = chipTypeOrInstanceType;
                  }

                  return (
                    <div
                      className="flex flex-col w-full justify-between rounded-2xl border border-gray-300 dark:border-zinc-800 p-2 text-left shadow-sm focus:outline-none focus:ring-2 focus:ring-highlightLighter dark:focus:ring-highlightLighter focus:ring-offset-2 cursor-pointer bg-white dark:bg-zinc-900 mb-2"
                      onClick={() => {
                        window.location.replace(notebookConfig.launchable_link);
                      }}
                    >
                      <span className="flex flex-col w-full">
                        <span className="inline-flex items-center justify-end mr-5"></span>
                        <div className="flex items-center overflow-hidden">
                          <div className="flex flex-row min-w-0 text-gray-900 dark:text-white items-center">
                            <BookOpenIcon className="h-6 w-6 mr-2" />
                            <span className="truncate text-md font-medium text-gray-900 dark:text-white group-hover:text-gray-500 dark:group-hover:text-slate-300 mr-2">
                              {queryName}
                            </span>
                            <ArrowUpRightIcon className="h-4 w-4" />
                          </div>
                        </div>
                      </span>
                      <span className="w-full mr-1 flex flex-row mt-2">
                        {notebookConfig.company && (
                          <span className="text-xs text-white">
                            {notebookConfig.company}
                          </span>
                        )}
                        {gpu && notebookConfig.company && (
                          <span className="text-xs text-white ml-1 mr-1">
                            {" "}
                            •{" "}
                          </span>
                        )}
                        {gpu && (
                          <span className="text-xs text-white">{gpu}</span>
                        )}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
interface GettingStartedModelOverviewProps {
  setShowOptions: (showOptions: boolean | null) => void;
  notebookList: any[];
  goBack: () => void;
}

const GettingStartedModelOverview: React.FC<
  GettingStartedModelOverviewProps
> = (props) => {
  const history = useHistory();
  const [questionText, { isFinished }] = useWindupString(
    "What model are you looking for?"
  );
  const [answer, setAnswer] = useState<string>("");

  return (
    <>
      {answer === "" && (
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex flex-row mb-3">
                <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono mt-10 mr-3">
                  {questionText}
                </p>
              </div>
              {isFinished && (
                <div className="flex flex-col bg-white dark:bg-zinc-900 dark:text-secondary rounded-md px-4 py-4 border border-gray-500 dark:border-zinc-800">
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => {
                      setAnswer("llama3");
                    }}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    Llama3
                  </span>
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => {
                      setAnswer("mistral");
                    }}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    Mistral
                  </span>
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => {
                      setAnswer("stable-diffusion");
                    }}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    Stable Diffusion
                  </span>
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => {
                      setAnswer("llama2");
                    }}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    Llama2
                  </span>
                  <span
                    className="text-sm text-sm text-gray-700 dark:text-secondary font-mono flex flex items-center hover:dark:text-highlight hover:text-cyan-400 cursor-pointer py-1"
                    onClick={() => {
                      setAnswer("mixtral");
                    }}
                  >
                    <ArrowRightIcon className="h-4 w-4 text-gray-700 dark:text-secondary mr-3" />
                    Mixtral
                  </span>

                  <div className="mt-3 flex justify-between">
                    <DynamicBadge
                      text="Back"
                      isActive={false} // isActive is not relevant anymore, but if it's required by Badge props, set a default
                      setIsActive={() => {
                        props.goBack();
                      }}
                      hideIcon={true}
                      className="ml-1"
                    />
                    <DynamicBadge
                      text="Close"
                      isActive={false} // isActive is not relevant anymore, but if it's required by Badge props, set a default
                      setIsActive={() => {
                        props.setShowOptions(false);
                      }}
                      hideIcon={true}
                      className="ml-1"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {answer === "llama3" && (
        <>
          <GettingStartedNoteBookView
            setShowOptions={props.setShowOptions}
            notebookList={props.notebookList.filter((item: any) =>
              item.internal_name.includes("llama3")
            )}
            goBack={() => setAnswer("")}
            title="Here are some Llama3 notebooks:"
          />
        </>
      )}
      {answer === "mistral" && (
        <>
          <GettingStartedNoteBookView
            setShowOptions={props.setShowOptions}
            notebookList={props.notebookList.filter((item: any) =>
              item.internal_name.includes("mistral")
            )}
            goBack={() => setAnswer("")}
            title="Here are some Mistral notebooks:"
          />
        </>
      )}
      {answer === "stable-diffusion" && (
        <>
          <GettingStartedNoteBookView
            setShowOptions={props.setShowOptions}
            notebookList={props.notebookList.filter((item: any) =>
              item.type.includes("diffusion-image-generation")
            )}
            goBack={() => setAnswer("")}
            title="Here are some Stable Diffusion notebooks:"
          />
        </>
      )}
      {answer === "llama2" && (
        <>
          <GettingStartedNoteBookView
            setShowOptions={props.setShowOptions}
            notebookList={props.notebookList.filter((item: any) =>
              item.internal_name.includes("llama2")
            )}
            goBack={() => setAnswer("")}
            title="Here are some Llama2 notebooks:"
          />
        </>
      )}
      {answer === "mixtral" && (
        <>
          <GettingStartedNoteBookView
            setShowOptions={props.setShowOptions}
            notebookList={props.notebookList.filter((item: any) =>
              item.internal_name.includes("mixtral")
            )}
            goBack={() => setAnswer("")}
            title="Here are some Mixtral notebooks:"
          />
        </>
      )}
    </>
  );
};
