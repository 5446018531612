import { UserContext } from "contexts/UserContext";
import React, { useCallback, useContext } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { isPublicRoute } from "./isPublicRoute";
import { isLocalHost } from "server/kas/utils";

const SecuredRoute: React.FC<RouteProps> = (props) => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const { isAuthenticated, me } = userContext;

  if (isPublicRoute(location.pathname)) {
    return <Route {...props} />;
  }

  // TODO - for some reason on localhost
  // this code path gets triggered maybe 1 out
  // of 3 or 4 times when loading the app???
  // investigate after... but seems like it doesn't happen in prod
  if (!isAuthenticated && !isLocalHost) {
    return <Redirect to="/discover" />;
  }
  if (isAuthenticated && me) {
    const callBack = localStorage.getItem("brev-redirect-url");
    if (callBack && callBack.includes("/login")) {
      localStorage.setItem("brev-redirect-url", "/org/environments");
    }
    return <Route {...props} />;
  }
  return <Redirect to="/discover" />;
};

export default SecuredRoute;
