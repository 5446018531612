import Modal from "components/Modals/Modal";
import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import { useHistory } from "react-router";
import { PreviewData } from ".";
import LaunchableConfigurationView from "components/Environment/Launchables/Deploy/shared/LaunchableConfigurationView";
import { shortenFileName } from "components/Environment/Launchables/utils";
import { NotebookRenderContext } from "contexts/NotebookRenderContext";
import FileViewer from "components/Environment/Launchables/Deploy/shared/FileViewer";

export interface PreviewModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  previewData: PreviewData | null;
}

export const PreviewModal: React.FC<PreviewModalProps> = ({
  show,
  setShow,
  previewData,
}) => {
  const history = useHistory();
  const [loadingNotebook, setLoadingNotebook] = useState(false);
  const [callBackUrl, setCallBackUrl] = useState("/instance/new");
  const [noteBookNotFound, setNoteBookNotFound] = useState(false);
  const notebookRenderContext = useContext(NotebookRenderContext);
  const handleNavigation = (url: string) => {
    history.push(url);
  };
  const [notebookHtml, setNotebookHtml] = useState<string>("");
  const [shortFileName, setShortFileName] = useState<string>("");

  useEffect(() => {
    async function updateShortFileName() {
      if (previewData?.launchableData?.file) {
        const shortName = await shortenFileName(
          previewData?.launchableData?.file
        );
        setShortFileName(shortName);
      } else {
        setShortFileName("");
      }
    }
    updateShortFileName();
  }, [previewData]);

  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => setShow(false)}
      title={""}
      width={"max-w-7xl"}
      body={
        <div className="relative">
          <div className="flex flex-col mb-12 mt-2">
            <div className="flex flex-row">
              <div className="flex w-full">
                <h1 className="text-white font-bold text-3xl">
                  {previewData?.launchableData?.name}
                </h1>
              </div>
              <div className="flex flex-row min-w-[200px]">
                <Button
                  className="mr-3"
                  label="Deploy Now"
                  onClick={() => {
                    window.open(
                      previewData?.launchableData?.url || "",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                />
                <Button
                  type="secondary"
                  label="Close"
                  onClick={() => {
                    setShow(false);
                  }}
                />
              </div>
            </div>
          </div>
          <FlatCard className="mb-8">
            <div className="flex flex-col w-full">
              <LaunchableConfigurationView
                instanceObject={previewData?.instance || null}
                loadingInstance={false}
                diskStorage={previewData?.launchableData?.diskStorage || ""}
                file={previewData?.launchableData?.file || ""}
                shortFileName={shortFileName}
                python={previewData?.launchableData?.python || ""}
                cuda={previewData?.launchableData?.cuda || ""}
                baseImage={
                  previewData?.launchableData?.containerSelected.container || ""
                }
                ports={previewData?.launchableData?.ports || null}
                disabled={false}
              />
            </div>
          </FlatCard>
          <FileViewer file={previewData?.launchableData?.file || ""} />
        </div>
      }
      cancelLabel={
        previewData?.launchableData?.file || "" ? "Close" : undefined
      }
    />
  );
};
