import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { classNames, memoryToGiB } from "components/utils";
import React, { useContext, useEffect, useMemo } from "react";
import Dropdown, { DropdownItem } from "./../Dropdown";
import InputField from "./../InputField";
import { StyledRangeSlider } from "./../Slider";
import DevToggle from "components/DevToggle";
import { OrgContext } from "contexts/OrgContext";
import "./AnimatedBorderDiv.scss";
import { FlatCardToggleOption } from "./FlatCardToggleOption";
import FlatCard from ".";

// ok so we can probably manipulate this to be like key value pairs
interface FlatCardToggleProps {
  options: {
    value: GPUInstanceType;
    price: any;
  }[];
  selectedOption?: GPUInstanceType;
  setSelectedOption: (option: GPUInstanceType) => void;
  isCompressed: boolean;
  setIsCompressed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FlatCardToggleButtons: React.FC<FlatCardToggleProps> = (props) => {
  // Group by GPU count and sort by price within each group
  const groupedOptions = useMemo(() => {
    const groups = props.options.reduce<Record<string, typeof props.options>>(
      (acc, current) => {
        // Guard against instances without supported_gpus
        if (!current.value.supported_gpus) return acc;

        const gpuCount = current.value.supported_gpus[0].count.toString();
        if (!acc[gpuCount]) {
          acc[gpuCount] = [];
        }
        acc[gpuCount].push(current);
        return acc;
      },
      {}
    );

    // Sort each group by price
    for (const gpuCount in groups) {
      groups[gpuCount].sort(
        (a, b) => parseFloat(a.price) - parseFloat(b.price)
      );
    }

    return groups;
  }, [props.options]);

  return (
    <>
      {!props.isCompressed && (
        <hr className="mt-3 dark:border dark:border-zinc-800" />
      )}
      {props.options.length === 0 && (
        <FlatCard className="mt-10" isLoading={false}>
          <p className="text-sm text-amber-700 font-mono">
            0 available configurations with the current filters
          </p>
        </FlatCard>
      )}
      <div className="max-h-[600px] overflow-y-auto">
        {groupedOptions &&
          Object.entries(groupedOptions).map(([gpuCount, options]) => {
            const chipName = (options as typeof props.options)[0].value
              .supported_gpus[0].name;

            const manufacturerName = (options as typeof props.options)[0].value
              .supported_gpus[0].manufacturer;

            return (
              <div key={gpuCount} className="my-4">
                {!props.isCompressed && (
                  <h2 className="mt-5 mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">
                    {`${gpuCount} x ${manufacturerName} ${chipName} GPU${
                      parseInt(gpuCount) > 1 ? "s:" : ":"
                    }`}
                  </h2>
                )}
                {(options as typeof props.options).map((elem) =>
                  props.isCompressed &&
                  elem.value.type !== props.selectedOption?.type ? null : (
                    <FlatCardToggleOption
                      key={elem.value.type}
                      value={elem.value}
                      isSelected={
                        elem.value.type === props.selectedOption?.type
                      }
                      onChange={props.setSelectedOption}
                      price={elem.price}
                    />
                  )
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};
