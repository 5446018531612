import React, { useEffect, useState } from "react";
import "./ShimmerButton.css";
import { classNames } from "components/utils";

interface ShimmerButtonProps {
  children: React.ReactNode;
}

const ShimmerButton: React.FC<ShimmerButtonProps> = ({ children }) => {
  const [shimmering, setShimmering] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShimmering(false);
    }, 1000);
  }, []);

  return (
    <button
      className={classNames(
        "shimmer-button flex flex-row text-sm bg-yellow-400 h-[30px] items-center justify-center rounded-md p-4",
        shimmering ? "animate-on-load" : ""
      )}
    >
      <span className="shimmer-text relative z-10">{children}</span>
      <span className="shimmer-effect absolute inset-0"></span>
    </button>
  );
};

export default ShimmerButton;
