/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "contexts/UserContext";
import {
  InformationCircleIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import Workspace from "../../../../../../entities/Workspace.entity";
import agent from "server";
import CopyField from "components/UI-lib/CopyField";
import FlatCard from "components/UI-lib/FlatCard";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { useAuth0 } from "@auth0/auth0-react";
import {
  classNames,
  displayGitRepo,
  fallbackCopyTextToClipboard,
} from "components/utils";
import DevToggle from "components/DevToggle";
import TunnelSection from "../Tunnels/TunnelSection";
import { Capability } from "contexts/OrgContext";
import { isKasAuthFlow } from "server/kas/utils";
import { useKas } from "contexts/KasContext";

interface MacCliInstallInstructionsProps {
  workspace: Workspace;
  enableTokenLogin: boolean;
}
export const MacCliInstallInstructions: React.FC<
  MacCliInstallInstructionsProps
> = (props) => {
  const [inlineAlertMessage, setInlineAlertMessage] = useState(
    "You'll need to install the Brev CLI to open your instance"
  );
  const { getAccessTokenSilently } = useAuth0?.();
  const { starfleetIdToken } = useKas(); // Add this line to get the KAS context
  const [cmd, setCmd] = useState(
    "brew install brevdev/homebrew-brev/brev && brev login"
  );
  const [revealToken, setRevealToken] = useState(false);
  const [visibleCmd, setVisibleCmd] = useState(
    "brew install brevdev/homebrew-brev/brev && brev login --token *****"
  );

  useEffect(() => {
    if (!isKasAuthFlow) {
      getAccessTokenSilently().then((t) => {
        // KAS auth flow won't support token login atm
        if (props.enableTokenLogin) {
          setCmd(
            `brew install brevdev/homebrew-brev/brev && brev login --token ${t}`
          );
        }
      });
    } else {
      if (props.enableTokenLogin) {
        setCmd(
          `brew install brevdev/homebrew-brev/brev && brev login --token ${starfleetIdToken}`
        );
      }
    }
  }, [getAccessTokenSilently, isKasAuthFlow, starfleetIdToken]);

  useEffect(() => {
    if (revealToken) {
      setVisibleCmd(cmd);
    } else {
      setVisibleCmd(
        "brew install brevdev/homebrew-brev/brev && brev login --token *****"
      );
    }
  }, [revealToken, cmd, isKasAuthFlow, starfleetIdToken]);

  const toggleTokenVisibility = () => {
    setRevealToken(!revealToken);
  };
  return (
    <CopyField
      style={{ width: "95%" }}
      value={cmd}
      displayValue={visibleCmd}
      analytics={{
        userid: props.workspace.createdByUserId,
        eventLabel: "clicked CLI install steps",
      }}
    />
  );
};

interface LinuxCliInstallInstructionsProps {
  workspace: Workspace;
}
export const LinuxCliInstallInstructions: React.FC<
  LinuxCliInstallInstructionsProps
> = (props) => {
  const [inlineAlertMessage, setInlineAlertMessage] = useState(
    "You'll need to install the Brev CLI to open your instance"
  );
  const [visibleCmd, setVisibleCmd] = useState(
    `sudo bash -c "$(curl -fsSL https://raw.githubusercontent.com/brevdev/brev-cli/main/bin/install-latest.sh)" && brev login`
  );

  return (
    <InlineNotification
      show={!!inlineAlertMessage}
      severity="info"
      className="w-full dark:bg-zinc-900 dark:text-white"
      text={inlineAlertMessage}
      text2={
        <div className="mt-2">
          <div className="text-sm text-gray-500 dark:text-secondary mb-[0.5px]">
            Run this command in your <strong>Linux</strong> shell
          </div>
          <CopyField
            style={{ width: "95%" }}
            value={visibleCmd}
            displayValue={visibleCmd}
            analytics={{
              userid: props.workspace.createdByUserId,
              eventLabel: "clicked CLI install steps",
            }}
          />

          {/* <p className="text-sm text-gray-400">
              Run this comyour mand in <strong>Linux</strong> shell
            </p> */}
        </div>
      }
      autoClose={false}
      onClose={() => setInlineAlertMessage("")}
    />
  );
};

interface WindowsCliInstallInstructionsProps {
  workspace: Workspace;
}
export const WindowsCliInstallInstructions: React.FC<
  WindowsCliInstallInstructionsProps
> = (props) => {
  const [inlineAlertMessage, setInlineAlertMessage] = useState(
    "You'll need to install the Brev CLI to open your instance"
  );
  const [visibleCmd, setVisibleCmd] = useState(
    `sudo bash -c "$(curl -fsSL https://raw.githubusercontent.com/brevdev/brev-cli/main/bin/install-latest.sh)" && brev login`
  );

  return (
    <InlineNotification
      show={!!inlineAlertMessage}
      severity="info"
      className="w-full dark:bg-zinc-900 dark:text-white"
      text={inlineAlertMessage}
      text2={
        <div className="mt-2">
          <div className="text-sm text-gray-500 dark:text-secondary mb-[0.5px]">
            Run this command in your <strong>Windows (WSL)</strong> shell
          </div>
          <CopyField
            style={{ width: "95%" }}
            value={visibleCmd}
            displayValue={visibleCmd}
            analytics={{
              userid: props.workspace.createdByUserId,
              eventLabel: "clicked CLI install steps",
            }}
          />
          <div className="text-sm text-gray-400 mt-1">
            Make sure you have WSL installed and configured, virtualization
            enabled in your BIOS, and Ubuntu 20.04 installed from the Microsoft
            Store.
          </div>

          {/* <p className="text-sm text-gray-400">
              These instructions are for <strong>Linux</strong>
            </p> */}
        </div>
      }
      autoClose={false}
      onClose={() => setInlineAlertMessage("")}
    />
  );
};
