import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DarkModeContext } from "contexts/DarkModeContext";
import parse, { domToReact, HTMLReactParserOptions } from "html-react-parser";
import { WorkspaceCreateContext } from "contexts/WorkspaceCreateContext";
import JupyterDarkCSS from "./JupyterDarkCSS";
import JupyterLightCSS from "./JupyterLightCSS";
import badnotebook from "./badnotebook.png";
import { useWindupString } from "windups";
import agent from "server";

interface RouteParams {
  notebook?: string;
  file?: string;
  raw?: string;
  setCallbackUrl: (url: string) => void;
  setNoteBookNotFound: (value: boolean) => void;
  onNavigate: (url: string) => void;
  hideRecommendedNotebooks?: boolean;
  setLoading?: (loading: boolean) => void;
}

const HtmlRenderer: React.FC<RouteParams> = (props) => {
  const [htmlContent, setHtmlContent] = useState("");
  const [godKnows, setGodKnows] = useState<any>(<></>);
  const history = useHistory();
  const { darkMode } = useContext(DarkModeContext);
  const { setQueryParams, notebookNotFound, setNotebookNotFound } = useContext(
    WorkspaceCreateContext
  );
  const notebookRenderContext = useContext(NotebookRenderContext);
  const buttonRef = useRef<HTMLAnchorElement>(null);
  const [callbackUrl, setCallbackUrl] = useState("/environment/new");

  useEffect(() => {
    if (props.setNoteBookNotFound) {
      props.setNoteBookNotFound(notebookNotFound);
    }
  }, [notebookNotFound]);

  useEffect(() => {
    if (props.setCallbackUrl) {
      props.setCallbackUrl(callbackUrl);
      console.log("Setting callback url:", callbackUrl);
    }
  }, [callbackUrl]);

  useEffect(() => {
    const loadHtml = async () => {
      try {
        let response: any;
        let doc: Document;
        let text: string;
        const parser = new DOMParser();
        if (props.raw) {
          if (props.raw.includes("Not Found")) {
            setHtmlContent("Not found");
            setNotebookNotFound(true);
            return;
          } else {
            text = props.raw;
          }
        } else if (props.file) {
          if (props.setLoading) props.setLoading(true);
          response = await notebookRenderContext.getNotebookRenderHtml(
            props.file
          );
          if (props.setLoading) props.setLoading(false);
          if (response) {
            text = response;
          } else {
            setHtmlContent("Not found");
            setNotebookNotFound(true);
            return;
          }
        } else if (props.notebook) {
          if (props.setLoading) props.setLoading(false);
          response = await fetch(`/Local-Notebooks/${props.notebook}.html`);
          if (props.setLoading) props.setLoading(false);
          if (response && response.ok) {
            text = await response.text();
          } else {
            setHtmlContent("Not Found");
            setNotebookNotFound(true);
            return;
          }
        } else {
          return;
        }
        doc = parser.parseFromString(text, "text/html");
        const body = doc.body;
        if (
          props.notebook &&
          (!body || !body.classList.contains("jp-Notebook"))
        ) {
          console.log("Fallback page detected, notebook does not exist");
          setHtmlContent("Not found");
          setGodKnows("Not found");
          setNotebookNotFound(true);
        } else {
          setNotebookNotFound(false);
        }
        const options: HTMLReactParserOptions = {
          replace: (domNode) => {
            let anyDomNode: any = domNode;
            const notebookUrlPattern =
              /^https:\/\/console\.brev\.dev\/notebooks\/([^\/\?]+)/;
            const match = notebookUrlPattern.exec(anyDomNode.attribs?.href);

            if (
              match &&
              domNode.type === "tag" // Check if domNode is an Element
            ) {
              const notebookName = match[1]; // Dynamically captured notebook name

              const children = Array.from(domNode.children).filter(
                (child): child is any =>
                  child.type === "tag" || child.type === "text"
              );

              const url = new URL(anyDomNode.attribs.href);
              console.log(url);
              const params = new URLSearchParams(url.search);
              const paramsDict = {};
              for (let [key, value] of params.entries()) {
                paramsDict[key] = value;
              }

              console.log(paramsDict);
              setQueryParams(paramsDict);

              // Construct the callback URL dynamically with the captured notebook name
              const callbackUrl = new URL(
                `${
                  isKasAuthFlow
                    ? CONSOLE_1_KAS_DEPLOYMENT_URL
                    : CONSOLE_1_AUTH0_DEPLOYMENT_URL
                }/notebooks/${notebookName}`
              );
              for (const [key, value] of Object.entries(paramsDict)) {
                if (typeof value === "string") {
                  callbackUrl.searchParams.set(key, value);
                }
              }
              setCallbackUrl(callbackUrl.toString());
              console.log(callbackUrl.toString());

              return (
                <a
                  ref={buttonRef}
                  className="cursor-pointer hidden"
                  onClick={(event) => {
                    event.preventDefault();
                    console.log("helloo ", anyDomNode.attribs.href);

                    const url = new URL(anyDomNode.attribs.href);
                    const params = new URLSearchParams(url.search);
                    const paramsDict = {};
                    for (let [key, value] of params.entries()) {
                      paramsDict[key] = value;
                    }

                    setQueryParams(paramsDict);

                    props.onNavigate(
                      callbackUrl
                        .toString()
                        .replace(
                          `${
                            isKasAuthFlow
                              ? CONSOLE_1_KAS_DEPLOYMENT_URL
                              : CONSOLE_1_AUTH0_DEPLOYMENT_URL
                          }`,
                          ""
                        )
                    );
                  }}
                >
                  {domToReact(children)}
                </a>
              );
            }
          },
        };

        if (darkMode) {
          const style = document.createElement("style");
          style.type = "text/css";
          style.innerHTML = JupyterDarkCSS;
          doc.head.appendChild(style);
        } else {
          const style = document.createElement("style");
          style.type = "text/css";
          style.innerHTML = JupyterLightCSS;
          doc.head.appendChild(style);
        }

        const html = doc.documentElement.outerHTML;
        const parsedHtml = parse(html, options);
        setHtmlContent(parsedHtml.toString());
        setGodKnows(parsedHtml);
      } catch (error) {
        console.error("Error loading notebook:", error);
        setHtmlContent("<p>Error loading notebook.</p>");
      }
    };

    loadHtml();
  }, [props.notebook, props.file, darkMode]);

  return (
    <>
      <div className="notebook-content">
        {notebookNotFound ? (
          <div className="flex flex-col  justify-center items-center mt-6">
            <img src={badnotebook} alt="Notebook not found" />
            {!props.hideRecommendedNotebooks && <NotFoundText />}
          </div>
        ) : (
          <>{godKnows}</>
        )}
      </div>
    </>
  );
};

export default HtmlRenderer;

import { Transition } from "@headlessui/react";
import { fetchLegacyNotebookList } from "components/utils";
import { NotebookRenderContext } from "contexts/NotebookRenderContext";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";

const NotFoundText = () => {
  const [text, { isFinished }] = useWindupString(
    "That wasn't a valid url... but fear not! Here are some other great notebooks"
  );
  // const notebookList = ["Notebook One", "Notebook Two", "Notebook Three"];

  const [notebookList, setNotebookList] = useState([]);

  useEffect(() => {
    fetchLegacyNotebookList()
      .then((data) => setNotebookList(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <>
      <div id=""></div>
      <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono mt-10">
        {text}
      </p>
      <Transition
        show={isFinished}
        enter="transition-opacity duration-700"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-700"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {isFinished && (
          <ul className="list-disc ml-5 mt-4 text-sm text-gray-700 dark:text-cyan-100 font-mono">
            {notebookList.map((notebook: any, index) => (
              <li key={index}>
                <a
                  href={`/notebook/${notebook.name.replace(".html", "")}`}
                  className="underline cursor-pointer"
                >
                  {notebook.name.replace(".html", "")}
                </a>
              </li>
            ))}
          </ul>
        )}
      </Transition>
    </>
  );
};
