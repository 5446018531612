import React, { useState, useEffect, useRef } from 'react';
import { usePostHog, useActiveFeatureFlags } from 'posthog-js/react';


interface BetaFeature {
  name: string;
  description: string;
  flagKey: string;
}

export const BetaFeaturesModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const posthog = usePostHog()
  const activeFlags = useActiveFeatureFlags()
  const [betaFeatures, setBetaFeatures] = useState<BetaFeature[]>([]);
  const [optedInFeatures, setOptedInFeatures] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    posthog.getEarlyAccessFeatures((features) => {
      const formattedFeatures = features.map((feature: any) => ({
        name: feature.name,
        description: feature.description,
        flagKey: feature.flagKey,
      }));
      setLoading(false);
      setBetaFeatures(formattedFeatures);
    }, true);
  }, []);

  useEffect(() => {
    // Get flag strings for beta features that are in active flags:
    if (!activeFlags) return;
    const flags = betaFeatures.filter((feature) => activeFlags.includes(feature.flagKey)).map((feature) => feature.flagKey);
    setOptedInFeatures(flags);
  }, [betaFeatures]);

  const handleOptIn = (flagKey: string) => {
    posthog.updateEarlyAccessFeatureEnrollment(flagKey, true);
    setOptedInFeatures((prev) => [...prev, flagKey]);
  };

  const handleOptOut = (flagKey: string) => {
    posthog.updateEarlyAccessFeatureEnrollment(flagKey, false);
    setOptedInFeatures((prev) => prev.filter((key) => key !== flagKey));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    >
      <div ref={modalRef} className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-11/12 md:w-1/2 transition-colors duration-300">
        <div className="flex justify-between items-center border-b p-4 border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100">Manage Beta Features</h2>
          <button className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="p-4">
          {betaFeatures.length === 0 ? (
            <div className="text-gray-600 dark:text-gray-400">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div>No beta features available</div>
            )}
            </div>
          ) : (
            betaFeatures.map((feature) => (
              <BetaFeatureItem
                key={feature.flagKey}
                feature={feature}
                optedIn={optedInFeatures.includes(feature.flagKey)}
                onOptIn={handleOptIn}
                onOptOut={handleOptOut}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const BetaFeatureItem: React.FC<{
  feature: BetaFeature;
  optedIn: boolean;
  onOptIn: (flagKey: string) => void;
  onOptOut: (flagKey: string) => void;
}> = ({ feature, optedIn, onOptIn, onOptOut }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onOptIn(feature.flagKey);
    } else {
      onOptOut(feature.flagKey);
    }
  };

  return (
    <div className="flex items-center justify-between p-2 border-b border-gray-200 dark:border-gray-700">
      <div>
        <div className="font-semibold text-gray-900 dark:text-gray-100">{feature.name}</div>
        <div className="text-gray-600 dark:text-gray-400 text-sm">{feature.description}</div>
      </div>
      <label className="inline-flex items-center">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 text-blue-600 dark:text-blue-400"
          checked={optedIn}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default BetaFeaturesModal;
