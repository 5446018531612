import IRole, { Actions, RoleIds } from "models/Role.model";

export default class Role implements IRole {
  public id: RoleIds;

  public actions: Array<Actions>;

  public constructor(r: IRole) {
    this.id = r.id;
    this.actions = r.actions;
  }
}
